import { cloneElement, forwardRef } from 'react';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { useUIDSeed } from 'react-uid';
import classNames from 'classnames';
import { highlightSearchedText } from 'utils/search';

const VirtualListItem = forwardRef(
  (
    {
      uid,
      virtualRow,
      titleLabel,
      metaLabel,
      statusLabel,
      item,
      checkbox,
      active,
      clickable,
      onClick,
      onChange,
      meta,
      visible,
      lastItem,
      outfaded,
      disabled, // not selectable
      filtered,
      filteringTerm,
      icon,
      mediumIcon,
      checked = false,
      indeterminate,
      iconStrokeColor,
      supertitle,
      extraStyle,
      refactoring = false,
      absolutePositionedListItem = false,
      dataQa,
      dataIndex
    },
    ref
  ) => {
    const uidSeed = useUIDSeed();
    return (
      <ListItem
        active={active}
        checkbox={checkbox}
        onClick={
          (typeof filtered === 'undefined' || filtered) && clickable
            ? onClick
            : () => {}
        }
        clickable={clickable}
        disabled={disabled}
        outfaded={outfaded}
        inProgress={item?.inProgress}
        done={!item?.recurring && item?.isDone}
        group={icon === 'group'}
        style={
          extraStyle
            ? extraStyle
            : virtualRow && absolutePositionedListItem
              ? {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: 48,
                  transform: `translateY(${virtualRow.start}px)`
                }
              : {}
        }
        ref={ref}
        extraClassNames={classNames({ 'u-margin-bottom-small': lastItem })}
        refactoring={refactoring}
        dataQa={dataQa}
        dataIndex={dataIndex}
        // {...rest}
      >
        {icon !== null && (
          <ListItemStatus
            itemId={uid}
            visible={checked || visible || indeterminate}
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            onChange={onChange}
            mediumIcon={mediumIcon}
            statusIcon={icon}
            strokeColor={iconStrokeColor && iconStrokeColor}
          >
            {statusLabel && statusLabel}
          </ListItemStatus>
        )}
        <ListItemLabelWrapper>
          <ListItemLabel
            labelNotePreview={!item?.recurring}
            supertitle={supertitle}
          >
            {/* This will give back an span, with the
                highlighted filteringTerm, and the attrs
                added as an object */}
            {highlightSearchedText(
              item[titleLabel],
              filteringTerm?.search ?? '',
              {
                className: 'c-list__item-title c-list__item-title-ellipsed',
                title: item[titleLabel]
              }
            )}
          </ListItemLabel>
          {meta &&
            cloneElement(meta, {
              ...meta.props,
              key: uidSeed(meta),
              item,
              metaLabel
            })}
        </ListItemLabelWrapper>
      </ListItem>
    );
  }
);

export default VirtualListItem;
