import React from 'react';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { MUTATION_REMOVE_EXERCISES_FROM_TEMPLATES } from 'services/aws/templates-query';
import { useNotificationQueue } from 'components/notification';

const RemoveExercisesFromTemplateButton = ({
  entityId,
  exerciseIds,
  templateId,
  type = 'template',
  updated,
  disabledMessage,
  ...props
}) => {
  const intl = useIntl();
  const notification = useNotificationQueue();

  return (
    <ConfirmMutationButton
      menu
      {...props}
      mutation={MUTATION_REMOVE_EXERCISES_FROM_TEMPLATES}
      confirmTitle={messages.modalConfirmRemoveExerciseFromTemplateTitle}
      confirmMessage={messages.modalConfirmRemoveExerciseFromTemplateMessage}
      messageValues={{ count: exerciseIds.length, type }}
      variables={{
        programTemplateIds: [templateId],
        exerciseIds
      }}
      update={(cache, { data: { removeExercisesFromProgramTemplates } }) => {
        if (removeExercisesFromProgramTemplates) {
          notification.add(templateId, {
            message: intl.formatMessage(messages.messageEditTemplateSuccess)
          });
          updated && updated();
        }
      }}
    >
      {disabledMessage && props.disabled ? (
        <>
          <Icon id="delete" style={{ top: 'auto' }} />
          <span>{disabledMessage}</span>
        </>
      ) : (
        <>
          <Icon
            id="delete"
            style={{ top: 'auto' }}
            strokeColor={'color-neutral-dark'}
          />

          <FormattedMessage
            {...messages.exercisesRemoveExerciseFromTemplateButton}
            values={{ count: exerciseIds.length, type }}
          />
        </>
      )}
    </ConfirmMutationButton>
  );
};

export default RemoveExercisesFromTemplateButton;
