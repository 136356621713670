import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES } from 'routes/RouteList';
import { generatePath, useHistory } from 'react-router-dom';
import { TemplateType } from 'constants.js';
import TrainingForm from 'containers/pages/exercises/trainings/TrainingForm';

function AddTrainingModal({ entityId, training = {}, onClose }) {
  const { push } = useHistory();
  const intl = useIntl();

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.trainingsAddTitle} />
        </CardHeaderTitle>
      </CardHeader>
      <TrainingForm
        entityId={entityId}
        training={{ ...training }}
        type={TemplateType.TRAINING}
        onComplete={async templateId => {
          setTimeout(() => {
            push(
              generatePath(
                ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
                {
                  entityId,
                  templateId
                }
              )
            );
          }, 100);
          onClose && onClose();
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <>
            <CardBody modalWithOverflow>
              <FieldInput
                id="title"
                name="title"
                placeholder={intl.formatMessage(messages.trainingNameLabel)}
                errors={errors}
                touched={touched}
                required
              >
                <FormattedMessage {...messages.trainingNameLabel} />
              </FieldInput>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </TrainingForm>
    </Modal>
  );
}

export default AddTrainingModal;
