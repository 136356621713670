import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { PanelHeader } from 'components/panel';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_USERS_USER
} from 'routes/RouteList';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import CardBody from 'components/card/CardBody';
import Panel from 'components/panel/Panel';
import { useNotificationQueue } from 'components/notification';
import { generatePath, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  MUTATION_UPDATE_USER,
  QUERY_GET_ME,
  QUERY_GET_USER_BY_ID,
  QUERY_GET_USERS_BY_ENTITY_ID,
  updateUserLanguage
} from 'services/aws/user-query';
import UserModel from 'models/User';
import { StoreContext } from 'index';
import useEdit from 'hooks/utils/useEdit';
import * as Yup from 'yup';
import { QUERY_GET_ENTITY_BY_ID } from 'services/aws/entity-query';
import { getFullLocation, navigateToUrl } from 'utils/browser';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderButtons
} from 'components/card/card-header';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import ResetLoginButton from 'containers/partials/buttons/ResetLoginButton';
import LanguageSelect from 'components/input/LanguageSelect';
import InputButtonWrapper from 'components/input/InputButtonWrapper';
import FieldInput from 'components/input/FieldInput';
import { PersonStatus } from 'constants.js';
import PersonStatusPopper from 'containers/partials/misc/PersonStatusPopper';
import Loader from 'components/loader/Loader';
import usePersonStatus from 'hooks/utils/usePersonStatus';
import RemoveLoginButton from 'containers/partials/buttons/RemoveLoginButton';

const Profile = ({ entityId, ...props }) => {
  const intl = useIntl();
  const ability = useAbility(AbilityContext);
  const notification = useNotificationQueue();
  const { push } = useHistory();
  const {
    authStore,
    authStore: {
      user: { id },
      entity
    }
  } = useContext(StoreContext);
  const [edit, setEdit] = useEdit(props.edit, true);
  const { personStatusHandler } = usePersonStatus(entityId);
  const [user, setUser] = useState(new UserModel({}, entityId));
  const { loading, data } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { id: id },
    skip: !id
  });

  const [updateUser] = useMutation(MUTATION_UPDATE_USER);

  useEffect(() => {
    if (data?.getPerson) {
      const user = new UserModel(data.getPerson, entityId);
      setUser(user);
    }
  }, [data, entityId]);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(
      intl.formatMessage(messages.firstnameRequired)
    ),
    lastname: Yup.string().required(
      intl.formatMessage(messages.lastnameRequired)
    ),
    email: Yup.string()
      .email(intl.formatMessage(messages.emailNotValid))
      .required(intl.formatMessage(messages.emailRequired))
  });

  const onSubmitHandler = async userData => {
    const userObj = {
      entityId,
      email: userData.email.toLowerCase(),
      lastname: userData.lastname,
      firstname: userData.firstname
    };

    await updateUser({
      variables: { ...userObj, id },
      refetchQueries: [
        {
          query: QUERY_GET_ENTITY_BY_ID,
          variables: { id: entityId }
        },
        {
          query: QUERY_GET_ME
        }
      ]
    }).then(async ({ data }) => {
      if (data?.editPerson?.id) {
        let updatedLanguage = false;
        if (
          userData.baseLanguage &&
          userData.baseLanguage !== user.getLanguage(entityId)
        ) {
          updatedLanguage = true;
          await updateUserLanguage(
            {
              baseLanguage: userData.baseLanguage
            },
            [
              {
                query: QUERY_GET_USER_BY_ID,
                variables: { id }
              }
            ]
          );
        }
        notification.add(data.editPerson.id, {
          message: intl.formatMessage(messages.userIsUpdated)
        });
        const path = generatePath(ROUTE_SETTINGS_PROFILE, {
          userId: data.editPerson.id
        });

        if (id === authStore.user.id && updatedLanguage) {
          const url = `${getFullLocation()}${path}`;
          navigateToUrl(url);
        } else {
          push(path);
        }
      }
    });
  };

  return (
    <Panel>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbProfile} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.settingsProfileTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.settingsProfileSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        {loading ? (
          <Loader />
        ) : (
          <Card secondary>
            <Formik
              initialValues={user}
              validationSchema={validationSchema}
              enableReinitialize={true}
              validateOnChange={false}
              validateOnBlur={true}
              onSubmit={onSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
                resetForm,
                setFieldValue,
                isValid,
                isSubmitting
              }) => (
                <Form noValidate>
                  <CardHeader secondary>
                    <CardHeaderTitle>
                      <FormattedMessage
                        {...messages.titleUserPersonalDetails}
                      />
                    </CardHeaderTitle>

                    <CardHeaderButtons>
                      {ability.can('update', 'User') && (
                        <FormEditHeaderButtons
                          edit={edit}
                          setEdit={setEdit}
                          onSubmit={handleSubmit}
                          cancel={!!id}
                          disabledSubmit={!isValid || isSubmitting}
                          onCancel={() => {
                            resetForm();
                          }}
                        />
                      )}
                    </CardHeaderButtons>
                  </CardHeader>
                  <CardBody secondary>
                    <div className="o-layout o-layout--tiny">
                      <div className="o-layout__item u-1-of-2-at-small">
                        <FieldInput
                          id="firstname"
                          name="firstname"
                          errors={errors}
                          touched={touched}
                          readOnly={!edit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <FormattedMessage {...messages.labelUserFirstname} />*
                        </FieldInput>
                      </div>
                      <div className="o-layout__item u-1-of-2-at-small">
                        <FieldInput
                          id="lastname"
                          name="lastname"
                          errors={errors}
                          touched={touched}
                          readOnly={!edit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <FormattedMessage {...messages.labelUserLastname} />*
                        </FieldInput>
                      </div>
                    </div>
                    <LanguageSelect
                      id="language"
                      name="language"
                      readOnly={!edit}
                      label={intl.formatMessage(messages.labelUserLanguage)}
                      possibleLanguages={entity.possibleLanguages}
                      value={values.baseLanguage || user.getLanguage(entityId)}
                      onSelect={language => {
                        setFieldValue('baseLanguage', language.value);
                      }}
                    />
                    <div className="o-layout o-layout--tiny">
                      <div className="o-layout__item">
                        <FieldInput
                          id="email"
                          name="email"
                          type="email"
                          errors={errors}
                          touched={touched}
                          readOnly={!edit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <FormattedMessage {...messages.labelUserEmail} />*
                        </FieldInput>
                      </div>
                    </div>

                    {user?.id && ability.can('create', 'Users') && (
                      <div className="o-layout o-layout--tiny">
                        <div className="o-layout__item u-1-of-2-at-large">
                          <InputButtonWrapper
                            extraClassNamesWrapper="o-flex o-flex--align-center"
                            id="resetpassword-id"
                            label={
                              <FormattedMessage
                                {...messages.labelUserResetPassword}
                              />
                            }
                          >
                            <ResetLoginButton
                              entityId={entityId}
                              person={user}
                              isPerson
                              target={generatePath(ROUTE_SETTINGS_USERS_USER, {
                                userId: id
                              })}
                            >
                              <FormattedMessage
                                {...messages.buttonUserResetPassword}
                              />
                            </ResetLoginButton>

                            {[
                              PersonStatus.PENDING,
                              PersonStatus.UNCONFIRMED
                            ].includes(user.status) && (
                              <PersonStatusPopper
                                person={user}
                                extraClassNames="u-margin-left-small"
                                onClickStatus={personStatusHandler}
                              />
                            )}
                          </InputButtonWrapper>
                        </div>
                        {[PersonStatus.UNCONFIRMED, PersonStatus.USER].includes(
                          user.status
                        ) && (
                          <div className="o-layout__item u-1-of-2-at-large">
                            <InputButtonWrapper
                              id="remove-login-id"
                              label={
                                <FormattedMessage
                                  {...messages.labelUserRemoveLogin}
                                />
                              }
                            >
                              <RemoveLoginButton
                                personId={id}
                                entityId={entityId}
                                target={generatePath(
                                  ROUTE_SETTINGS_USERS_USER,
                                  {
                                    userId: id
                                  }
                                )}
                                extraClassNames={'c-button--ellipsed'}
                                title={intl.formatMessage(
                                  messages.buttonUserRemoveLogin
                                )}
                                refetchQueries={[
                                  {
                                    query: QUERY_GET_USERS_BY_ENTITY_ID,
                                    variables: { id: entityId },
                                    fetchPolicy: 'network-only'
                                  },
                                  {
                                    query: QUERY_GET_USER_BY_ID,
                                    variables: {
                                      id: id
                                    },
                                    fetchPolicy: 'network-only'
                                  }
                                ]}
                              >
                                <FormattedMessage
                                  {...messages.buttonUserRemoveLogin}
                                />
                              </RemoveLoginButton>
                            </InputButtonWrapper>
                          </div>
                        )}
                      </div>
                    )}
                  </CardBody>
                  <FormEditFooterButtons
                    edit={edit}
                    setEdit={setEdit}
                    hasCancel={!!id}
                    onCancel={() => {
                      resetForm();
                    }}
                    type="submit"
                    disabledSubmit={isSubmitting}
                    topMargin
                  />
                </Form>
              )}
            </Formik>
          </Card>
        )}
      </PanelBody>
    </Panel>
  );
};

export default Profile;
