import { create } from 'zustand';
import { createExerciseSlice } from 'containers/pages/exercises/store/ExerciseStore';
import { createProgramSlice } from 'containers/pages/exercises/store/ProgramStore';
import { createTemplateSlice } from 'containers/pages/exercises/store/TemplateStore';
import { createPersonSlice } from 'containers/pages/persons/store/PersonStore';
import { createTrainingSlice } from 'containers/pages/exercises/store/TrainingsStore';
import { createCollectionSlice } from 'containers/pages/exercises/store/CollectionsStore';
import { createResultSlice } from 'containers/pages/results/store/ResultStore';
import { createGrowthSlice } from 'containers/pages/growthtracker/store/GrowthStore';

export const useStore = create((...a) => ({
  loading: false,
  authorized: false,
  setLoading: value => a[0](() => ({ loading: value })),
  ...createPersonSlice(...a),
  ...createExerciseSlice(...a),
  ...createProgramSlice(...a),
  ...createTemplateSlice(...a),
  ...createTrainingSlice(...a),
  ...createCollectionSlice(...a),
  ...createResultSlice(...a),
  ...createGrowthSlice(...a)
}));
