import { CardBody } from 'components/card';
import Icon from 'components/icon/Icon';
import { ButtonsGroup, Button } from 'components/button';
import messages from 'messages';

const GrowthAlertCardBody = props => {
  const {
    intl,
    alertData: {
      message = intl.formatMessage(messages.errorSomethingWentWrong),
      dismissLabel = intl.formatMessage(messages.cardButtonCancel),
      dismissButton = true,
      okLabel = intl.formatMessage(messages.buttonOK),
      okHandler,
      dismissHandler
    }
  } = props;
  return (
    <CardBody benchmarks>
      <Icon id="database-error" mediumIcon />
      <p>{message}</p>
      <ButtonsGroup>
        {dismissButton && (
          <Button small secondary onClick={dismissHandler}>
            {dismissLabel}
          </Button>
        )}
        {okHandler && (
          <Button small primary onClick={okHandler}>
            {okLabel}
          </Button>
        )}
      </ButtonsGroup>
    </CardBody>
  );
};

export default GrowthAlertCardBody;
