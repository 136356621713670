import { ROUTE_EXERCISES_ENTITY_ALL_EXERCISES } from 'routes/RouteList';
import {
  MUTATION_ARCHIVE_EXERCISE,
  QUERY_GET_EXERCISES,
  QUERY_GET_EXERCISES_BY_PERSON
} from 'services/aws/exercises-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages.js';
import { FormattedMessage } from 'react-intl';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import { generatePath, useHistory } from 'react-router-dom';
import { useStore } from 'stores/Store';

const RemoveExerciseButton = ({ entityId, exerciseId, children, ...props }) => {
  const { push } = useHistory();
  const removeExercise = useStore(state => state.removeExercise);
  return (
    <ConfirmMutationButton
      menu
      {...props}
      mutation={MUTATION_ARCHIVE_EXERCISE}
      confirmTitle={messages.modalConfirmRemoveExerciseTitle}
      confirmMessage={messages.modalConfirmRemoveExerciseMessage}
      variables={{
        exerciseId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_EXERCISES,
          variables: { entityId }
        },
        {
          query: QUERY_GET_TEMPLATES,
          variables: { entityId }
        },
        {
          query: QUERY_GET_EXERCISES_BY_PERSON,
          variables: { entityId }
        }
      ]}
      update={(cache, { data: { archiveExercise } }) => {
        removeExercise(exerciseId);
        if (archiveExercise) {
          push(
            generatePath(ROUTE_EXERCISES_ENTITY_ALL_EXERCISES, { entityId })
          );
        }
      }}
    >
      <Icon id="delete" />
      <FormattedMessage {...messages.exercisesRemoveExerciseButton} />
    </ConfirmMutationButton>
  );
};

export default RemoveExerciseButton;
