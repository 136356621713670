import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import {
  MUTATION_ADD_TEMPLATE,
  MUTATION_EDIT_TEMPLATE,
  QUERY_GET_TEMPLATE
} from 'services/aws/templates-query';
import { cleanFalsy } from 'utils/object';
import { NoteTypes, TemplateType } from 'constants.js';
import {
  MUTATION_ADD_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import { QUERY_GET_EXERCISE_NOTES } from 'services/aws/exercises-query';
import useTraining from 'hooks/queries/useTraining';
import useTrainings from 'hooks/queries/useTrainings';
import Loader from 'components/loader/Loader';

const TrainingForm = ({
  entityId,
  training,
  children,
  extraClassNames,
  onComplete
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();
  const { fetchTraining } = useTraining(entityId);
  const { fetchTrainings } = useTrainings(entityId);

  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE);
  const [addTemplate] = useMutation(MUTATION_ADD_TEMPLATE, {
    awaitRefetchQueries: true
  });
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    note: Yup.string()
  });

  const onSubmitHandler = async training => {
    setLoading(true);
    const trainingObj = cleanFalsy({
      entityId,
      title: training.title,
      exerciseIds: training.exerciseIds,
      tagIds: training.tags.map(tag => tag.id),
      duration: training.duration,
      type: training.type ?? TemplateType.TRAINING
    });

    if (!training.id) {
      const { data, errors } = await addTemplate({
        variables: {
          ...trainingObj
        }
      });

      if (data?.addExerciseProgramTemplate?.id) {
        await submitNote({
          id: data.addExerciseProgramTemplate.id,
          note: '',
          templateId: data.addExerciseProgramTemplate.id
        });

        setLoading(false);

        fetchTrainings({
          variables: { entityId, type: training.type },
          fetchPolicy: 'network-only'
        });

        notification.add(data.addExerciseProgramTemplate.id, {
          message: intl.formatMessage(messages.messageAddTrainingSuccess)
        });

        if (onComplete) onComplete(data.addExerciseProgramTemplate.id);
      }

      if (errors) {
        console.log(errors);
        notification.add('edit_error', {
          message: intl.formatMessage(messages.messageAddTemplateSaveError),
          level: 'error'
        });
      }
    } else {
      await submitNote(training);
      const { data, errors } = await editTemplate({
        variables: {
          id: training.id,
          ...trainingObj
        }
      });

      if (data?.editExerciseProgramTemplate?.id) {
        fetchTraining({
          variables: { templateId: training.id },
          fetchPolicy: 'network-only'
        });

        fetchTrainings({
          variables: { entityId, type: training.type },
          fetchPolicy: 'network-only'
        });

        setLoading(false);

        notification.add(data.editExerciseProgramTemplate.id, {
          message: intl.formatMessage(messages.messageEditTrainingSuccess)
        });

        onComplete && onComplete(data.editExerciseProgramTemplate.id);
      }

      if (errors) {
        console.log(errors);
        notification.add('edit_error', {
          message: intl.formatMessage(messages.messageAddTemplateSaveError),
          level: 'error'
        });
      }
    }
  };

  const submitNote = async training => {
    const refetchQueries = [
      {
        query: QUERY_GET_EXERCISE_NOTES,
        variables: { templateId: training.id }
      },
      {
        query: QUERY_GET_TEMPLATE,
        variables: { templateId: training.id, type: training.type }
      }
    ];

    if (training.trainingNote) {
      if (training.note === training.trainingNote) {
        return;
      }

      return await editNote({
        variables: {
          id: training.trainingNote.id,
          linkId: training.id,
          note: training.note,
          noteType: NoteTypes.TEMPLATE
        },
        refetchQueries
      });
    } else {
      return await addNote({
        variables: {
          linkId: training.id,
          note: training.note,
          noteType: NoteTypes.TEMPLATE
        },
        refetchQueries
      });
    }
  };

  return (
    <Formik
      initialValues={training}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {loading && <Loader />}
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
};

export default TrainingForm;
