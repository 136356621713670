import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, Formik } from 'formik';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import messages from 'messages';
import { useHistory } from 'react-router-dom';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderText,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { CardBody } from 'components/card';
import {
  getCurrentAuthenticatedUser,
  getQRCode,
  verifyTotpToken,
  removeMFA
} from 'services/aws/cognito';
import { QRCodeSVG } from 'qrcode.react';
import { StoreContext } from 'index';
import { Button, ButtonsGroup } from 'components/button';
import FieldInput from 'components/input/FieldInput';
import * as Yup from 'yup';
import InputErrorMessage from 'components/input/InputErrorMessage';
import { defaultConfig, PUBLIC_URL } from 'constants.js';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import copy from 'copy-to-clipboard';
import MessageText from 'components/message/MessageText';
import { MODAL_TYPES } from 'models/ModalData';
import { useNotificationQueue } from 'components/notification';
import TooltipPopper from 'components/tooltip/TooltipPopper';
import Loader from 'components/loader/Loader';
import ReadOnlyInput from 'components/input/ReadOnlyInput';

const MfaSetup = () => {
  const {
    authStore: {
      user: { email }
    },
    uiState
  } = useContext(StoreContext);
  const { push } = useHistory();
  const intl = useIntl();
  const notification = useNotificationQueue();

  const [secret, setSecret] = useState('');
  const [qrString, setQRString] = useState(null);
  const [totpActive, setTotpActive] = useState({
    isActive: false,
    firstRender: true
  });
  const [mfaError, setMfaError] = useState(false);
  const [noScan, setNoScan] = useState(false);

  useEffect(() => {
    const getQRString = async () => {
      const authUser = await getCurrentAuthenticatedUser();
      const secretCode = await getQRCode(authUser);
      setTotpActive({ isActive: false, firstRender: false });

      if (authUser?.preferredMFA === 'SOFTWARE_TOKEN_MFA') {
        setTotpActive({ isActive: true, firstRender: false });
      }
      setSecret(secretCode);
      const issuer = defaultConfig.name;
      setQRString(
        `otpauth://totp/${PUBLIC_URL.replace('https://', '')}:` +
          email +
          '?secret=' +
          secretCode +
          '&issuer=' +
          issuer
      );
    };

    getQRString();
  }, []);

  const onSubmitHandler = async ({ code }) => {
    const totpResponse = await verifyTotpToken(code);
    if (totpResponse?.Status === 'SUCCESS') {
      //   push(ROUTE_SETTINGS);
      setTotpActive({ isActive: true, firstRender: false });
    } else {
      setMfaError(true);
    }
  };

  const onResetHandler = async () => {
    // @ts-ignore
    uiState.showModal({
      title: intl.formatMessage(
        messages.settingsProfileMfaDeactivateModalTitle
      ),
      message: messages.settingsProfileMfaDeactivateModalBody,
      okLabel: intl.formatMessage(messages.buttonRemove),
      okHandler: async () => {
        const response = await removeMFA();
        setTotpActive({ isActive: false, firstRender: false });
      },
      type: MODAL_TYPES.ALERT
    });
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .max(6, intl.formatMessage(messages.totpSetupErrorMaxSix))
      .matches(/[0-9]+/, intl.formatMessage(messages.totpSetupErrorDigits))
    //   .required('The input field ')
  });

  const secretRef = useRef();

  const onCopyToClipboard = e => {
    copy(secretRef?.current.innerText);
    const position = {
      y: secretRef?.current.getBoundingClientRect().top + 40,
      x: secretRef?.current.getBoundingClientRect().right
    };

    notification.add('secret-copied', {
      message: intl.formatMessage(messages.settingsProfileMfaStep1CopiedToken),
      position: position,
      closeable: true
    });
  };

  const onChangeHandler = () => {
    setMfaError(false);
  };

  return (
    <Panel>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbMfa} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.settingsProfileMfa} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.settingsProfileMfaSubTitle} />.
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        {totpActive.firstRender && !totpActive.isActive ? (
          <Loader />
        ) : (
          <Card secondary multiBody>
            {!totpActive.isActive ? (
              <>
                <CardHeader secondary>
                  <CardHeaderText>
                    <CardHeaderTitle>
                      <FormattedMessage
                        {...messages.settingsProfileMfaIntroTitle}
                      />
                      .
                    </CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary separatorBottom>
                  <CardHeaderSubtitle extraClassNames="u-padding-bottom-medium">
                    <FormattedMessage
                      {...messages.settingsProfileMfaIntroText}
                    />
                  </CardHeaderSubtitle>
                </CardBody>

                <CardHeader secondary>
                  <CardHeaderText>
                    <CardHeaderTitle>
                      <FormattedMessage
                        {...messages.settingsProfileMfaIntroTextBridge}
                      />
                    </CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary separatorBottom>
                  <div className="c-input__group">
                    <label className={classNames('c-input__label', {})}>
                      <FormattedMessage
                        {...messages.settingsProfileMfaTitleStep1}
                      />
                    </label>
                    <div className="u-margin-bottom-small">
                      <FormattedMessage
                        {...messages.settingsProfileMfaMessageStep1}
                        values={{
                          button: chunks => (
                            <TooltipPopper
                              id="no-warning-date-03"
                              top
                              label={chunks}
                              onMouseEnter={() => true}
                              onFocus={() => true}
                              onMouseLeave={() => false}
                              onBlur={() => false}
                              inlineText
                            >
                              <FormattedMessage
                                {...messages.settingsProfileMfaListApps}
                              />
                            </TooltipPopper>
                          )
                        }}
                      />
                    </div>
                    {qrString && <QRCodeSVG value={qrString} />}
                  </div>
                  <p className="u-margin-top-medium u-margin-bottom-small">
                    <FormattedMessage
                      {...messages.settingsProfileMfaStep1Extra}
                      values={{
                        button: chunks => (
                          <Button
                            extraClassNames="u-margin-horizontal-tiny"
                            tiny
                            onClick={() => setNoScan(!noScan)}
                          >
                            {chunks}
                          </Button>
                        )
                      }}
                    />
                  </p>

                  {secret && noScan && (
                    <ReadOnlyInput
                      value={secret}
                      ref={secretRef}
                      style={{
                        wordBreak: 'break-all',
                        fontFamily: 'monospace'
                      }}
                    >
                      <Button tiny onClick={onCopyToClipboard}>
                        <Icon
                          id="copy"
                          strokeColor={'color-neutral-dark'}
                        ></Icon>
                      </Button>
                    </ReadOnlyInput>
                  )}

                  <Formik
                    initialValues={{ code: '' }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitHandler}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      touched,
                      errors,
                      isValid,
                      dirty
                    }) => {
                      return (
                        <Form noValidate className="u-margin-top-large">
                          <label
                            htmlFor="code"
                            className={classNames('c-input__label', {})}
                          >
                            <FormattedMessage
                              {...messages.settingsProfileMfaTitleStep2}
                            />
                          </label>
                          <p className="u-margin-bottom-small">
                            <FormattedMessage
                              {...messages.settingsProfileMfaMessageStep2}
                            />
                          </p>
                          <FieldInput
                            id="code"
                            name="code"
                            type={'text'}
                            errorOnChange
                            errors={errors}
                            touched={touched}
                            onChange={() => onChangeHandler()}
                            onBlur={handleBlur}
                            externalError={
                              mfaError && (
                                <InputErrorMessage>
                                  <FormattedMessage
                                    {...messages.totpSetupErrorMessage}
                                  />
                                </InputErrorMessage>
                              )
                            }
                          ></FieldInput>

                          <ButtonsGroup extraClassNames="u-margin-vertical-small o-flex o-flex--justify-end">
                            <Button
                              primary
                              type={'submit'}
                              //   disabled={!(isValid && dirty)}
                            >
                              <FormattedMessage
                                {...messages.settingsProfileMfaSubmitButton}
                              />
                            </Button>
                          </ButtonsGroup>
                        </Form>
                      );
                    }}
                  </Formik>
                </CardBody>
              </>
            ) : (
              <>
                <CardBody
                  empty
                  extraClassNames="u-padding-bottom-huge"
                  separatorBottom
                >
                  <Icon id="success" xHugeIcon strokeColor="color-success" />
                  <MessageText success>
                    <FormattedMessage
                      {...messages.settingsProfileMfaActiveMessage}
                    />
                  </MessageText>
                </CardBody>
                <CardBody empty>
                  <Button alert iconRight onClick={onResetHandler}>
                    <FormattedMessage
                      {...messages.settingsProfileMfaDeactivateButton}
                    />
                  </Button>
                </CardBody>
              </>
            )}
          </Card>
        )}
      </PanelBody>
    </Panel>
  );
};

export default MfaSetup;
