import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { DefaultBenchmark } from 'constants.js';
import { useIntl } from 'react-intl';
import messages from 'messages';
import TestsetSelect from 'containers/partials/inputs/TestsetSelect';
import BenchmarksSelect from 'containers/partials/inputs/BenchmarksSelect';
import LanguageSelect from 'components/input/LanguageSelect';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';

function SessionDetailExtras({ edit, entityId }) {
  const {
    authStore: { user, entity }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { session } = useSessionContext();
  const [isILike, setIsILike] = useState(false);

  useEffect(() => {
    if (session?.id) {
      setIsILike(session.isILike);
    }
  }, [session]);

  return (
    <>
      <div className="o-layout o-layout--small">
        <div className="o-layout__item u-1-of-2-at-small">
          <TestsetSelect
            name={'testSetId'}
            entityId={entityId}
            value={values.testSetId}
            testSet={session?.testSets?.[0]}
            readOnly={!edit || values.finishedPercentage !== 0}
            disabled={!edit || values.finishedPercentage !== 0}
            onChange={selectedItem => {
              if (selectedItem) {
                setFieldValue('testSetId', selectedItem ? selectedItem.id : '');
                setIsILike(selectedItem.isILike);
                if (selectedItem?.defaultBenchmark) {
                  setFieldValue(
                    'benchmarkId',
                    selectedItem.defaultBenchmark.id
                  );
                  setFieldValue('benchmark', selectedItem.defaultBenchmark);
                } else {
                  setFieldValue('benchmarkId', DefaultBenchmark);
                  setFieldValue('benchmark', null);
                }
              }
            }}
            errors={errors}
            touched={touched}
          />
        </div>
        {user.isHylyghtOrg && !isILike && (
          <div className="o-layout__item u-1-of-2-at-small">
            <BenchmarksSelect
              entityId={entityId}
              value={values?.benchmark?.id || DefaultBenchmark}
              readOnly={!edit}
              disabled={!edit}
              onChange={selectedItem => {
                setFieldValue(
                  'benchmarkId',
                  selectedItem ? selectedItem.id : ''
                );
              }}
            />
          </div>
        )}
        {(isILike || session?.isILike) && (
          <div className="o-layout__item u-1-of-2-at-small">
            <LanguageSelect
              id="language"
              name="language"
              readOnly={!edit}
              label={intl.formatMessage(messages.labelUserLanguage)}
              possibleLanguages={entity.possibleLanguages}
              value={values.language}
              onSelect={language => {
                setFieldValue('language', language.value);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default SessionDetailExtras;
