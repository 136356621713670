import React, { useContext } from 'react';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import TabLink from 'components/tabs/TabLink';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import {
  ROUTE_USER_SESSIONS_ENTITY_SESSION,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import ReportCard from 'containers/partials/cards/ReportCard';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useTestDataContext } from 'contexts/TestDataContext';
import EditTestResultCard from 'containers/partials/cards/EditTestResultCard';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import Loader from 'components/loader/Loader';
import useEdit from 'hooks/utils/useEdit';

const UserTabs = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId, sessionId, testDataId } = useParams();
  const { session } = useSessionContext();
  const { testDataLoading, hasResults } = useTestDataContext();
  const [edit, setEdit] = useEdit(
    !!useRouteMatch([ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT]),
    true
  );
  const tabRoutes = [
    [
      ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
      ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA
    ],
    [
      ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
      ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT,
      ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA
    ]
  ];
  const [tabIndex, setTabIndex] = useTabIndex(tabRoutes);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <TabLink
          key={'session.sporter.report'}
          to={
            testDataId
              ? generatePath(
                  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA,
                  {
                    entityId,
                    sessionId,
                    testDataId
                  }
                )
              : generatePath(ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT, {
                  entityId,
                  sessionId
                })
          }
        >
          <FormattedMessage {...messages.tabSessionSporterReport} />
        </TabLink>
        {session.isRecurring && (
          <TabLink
            key={'session.sporter.data'}
            to={
              testDataId
                ? generatePath(
                    ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA,
                    {
                      entityId,
                      sessionId,
                      testDataId
                    }
                  )
                : generatePath(ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS, {
                    entityId,
                    sessionId
                  })
            }
          >
            <FormattedMessage {...messages.tabSessionSporterTests} />
          </TabLink>
        )}
      </TabList>
      <TabPanel>
        <TabPanelBody withScroller>
          {testDataLoading ? (
            <Loader />
          ) : (
            <>
              {hasResults() ? (
                <ReportCard
                  compressed
                  rootPath={generatePath(ROUTE_USER_SESSIONS_ENTITY_SESSION, {
                    entityId,
                    sessionId
                  })}
                />
              ) : (
                <Card secondary>
                  <CardBody extraClassNames="u-margin-bottom-none u-padding-vertical-huge">
                    <Message emptyState icon="test">
                      <MessageText>
                        <FormattedMessage
                          {...messages.emptySessionForAthlete}
                        />
                      </MessageText>
                    </Message>
                  </CardBody>
                </Card>
              )}
            </>
          )}
        </TabPanelBody>
      </TabPanel>

      {session.isRecurring && (
        <TabPanel>
          <TabPanelBody withScroller>
            {testDataLoading ? (
              <Loader />
            ) : (
              <>
                <EditTestResultCard
                  entityId={entityId}
                  sporterId={user.id}
                  edit={edit}
                  rootPath={generatePath(ROUTE_USER_SESSIONS_ENTITY_SESSION, {
                    entityId,
                    sessionId
                  })}
                />
              </>
            )}
          </TabPanelBody>
        </TabPanel>
      )}
    </Tabs>
  );
};

export default UserTabs;
