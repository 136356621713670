import React from 'react';
import classNames from 'classnames';

const TableHeader = ({ children, extraClassNames }) => {
  return (
    <thead className={classNames('c-table__header', extraClassNames)}>
      {children}
    </thead>
  );
};

export default TableHeader;
