import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { DefaultBenchmark, RecurringInterval } from 'constants.js';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import TestsetSelect from 'containers/partials/inputs/TestsetSelect';
import BenchmarksSelect from 'containers/partials/inputs/BenchmarksSelect';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import FieldSelect from 'components/input/FieldSelect';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { camelCaseify } from 'utils/string';

function RecurringSessionDetailExtras({ edit, entityId }) {
  const {
    authStore: { user },
    uiState: { locale }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { session } = useSessionContext();
  const [isILike, setIsILike] = useState(false);

  useEffect(() => {
    if (session?.id) {
      setIsILike(session.isILike);
    }
  }, [session]);

  const onSelectRecurringType = selectedItem => {
    setFieldValue('interval', selectedItem);
  };

  const recurringTypeOptions = Object.values(RecurringInterval).map(i => {
    return {
      value: i,
      label: intl.formatMessage(messages[`recurringInterval${camelCaseify(i)}`])
    };
  });

  return (
    <>
      <div className="o-layout o-layout--small">
        <div className="o-layout__item u-1-of-2-at-small">
          <TestsetSelect
            name={'testSetId'}
            entityId={entityId}
            value={values.testSetId}
            testSet={session?.testSets?.[0]}
            readOnly={!edit || values.finishedPercentage !== 0}
            disabled={!edit || values.finishedPercentage !== 0}
            onChange={selectedItem => {
              if (selectedItem) {
                setFieldValue('testSetId', selectedItem ? selectedItem.id : '');
                setIsILike(selectedItem.isILike);
                if (selectedItem?.defaultBenchmark) {
                  setFieldValue(
                    'benchmarkId',
                    selectedItem.defaultBenchmark.id
                  );
                  setFieldValue('benchmark', selectedItem.defaultBenchmark);
                } else {
                  setFieldValue('benchmarkId', DefaultBenchmark);
                  setFieldValue('benchmark', null);
                }
              }
            }}
            errors={errors}
            touched={touched}
            required
          />
        </div>
        {user.isHylyghtOrg && !isILike && (
          <div className="o-layout__item u-1-of-2-at-small">
            <BenchmarksSelect
              entityId={entityId}
              value={values?.benchmark?.id || DefaultBenchmark}
              readOnly={!edit}
              disabled={!edit}
              onChange={selectedItem => {
                setFieldValue(
                  'benchmarkId',
                  selectedItem ? selectedItem.id : ''
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="o-layout o-layout--small">
        <div className="o-layout__item u-1-of-2-at-small">
          <FieldSelect
            id={'interval'}
            name={'interval'}
            onChange={select => onSelectRecurringType(select.value)}
            value={recurringTypeOptions.find(t => t.value === values.interval)}
            readOnly={!edit}
            options={recurringTypeOptions}
            label={`${intl.formatMessage(
              messages.labelSessionRecurringType
            )} *`}
            errors={errors}
            touched={touched}
            required
          />
        </div>
        <div className="o-layout__item u-1-of-4-at-small">
          <FieldDatePicker
            id="recurring_startdate"
            name="startdate"
            icon="calendar"
            placeholder={'dd/mm/yyyy'}
            errors={errors}
            touched={touched}
            readOnly={!edit}
            disabled={!edit}
            locale={locale}
            required
          >
            <FormattedMessage {...messages.labelSessionStartDate} /> *
          </FieldDatePicker>
        </div>
        <div className="o-layout__item u-1-of-4-at-small">
          <FieldDatePicker
            id="recurring_enddate"
            name="enddate"
            icon="calendar"
            placeholder={'dd/mm/yyyy'}
            errors={errors}
            touched={touched}
            readOnly={!edit}
            disabled={!edit}
            locale={locale}
            required
          >
            <FormattedMessage {...messages.labelSessionEndDate} /> *
          </FieldDatePicker>
        </div>
      </div>
    </>
  );
}

export default RecurringSessionDetailExtras;
