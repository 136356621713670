import { Link } from 'react-router-dom';
import { Tab } from 'react-tabs';
import { useEditableContext } from 'contexts/EditableContext';
import { useEffect, useState } from 'react';

function TabLink({ to, disabled = false, children, dataQa, ...otherProps }) {
  const { isEditing } = useEditableContext();
  const [disabledState, setDisabledState] = useState(false);

  useEffect(() => {
    setDisabledState(disabled || isEditing !== undefined);
  }, [isEditing, disabled]);

  return (
    <Tab
      {...otherProps}
      tabIndex="-1"
      style={{
        padding: disabledState ? '16px 0 12px' : 0,
        pointerEvents: 'none'
      }}
      disabled={disabledState}
      data-qa={`${dataQa}-tab`}
    >
      {disabledState || !to ? (
        <>{children}</>
      ) : (
        <Link
          to={to}
          style={{
            padding: '16px 0 12px',
            pointerEvents: 'auto',
            outlineOffset: -2
          }}
          data-qa={`${dataQa}-tab-link`}
        >
          {children}
        </Link>
      )}
    </Tab>
  );
}

TabLink.tabsRole = 'Tab';

export default TabLink;
