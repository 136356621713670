import React, { Component } from 'react';
import classNames from 'classnames';

const TableHeaderCell = ({
  children,
  extraClassNames,
  nowrap,
  mainCell,
  sortedBy,
  ...rest
}) => {
  return (
    <th
      className={classNames('c-table-cell c-table-cell--header', {
        'c-table-cell--main': mainCell,
        'c-table-cell--nowrap': nowrap,
        'c-table-cell--sorted': sortedBy
      })}
      {...rest}
    >
      <div className={classNames('o-flex', extraClassNames)}>{children}</div>
    </th>
  );
};

export default TableHeaderCell;
