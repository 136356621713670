import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import ProgramsList from 'containers/partials/lists/ProgramsList';
import { useIntl } from 'react-intl';
import { ROUTE_USER_ENTITY_PROGRAMS_PROGRAM } from 'routes/RouteList';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import usePrograms from 'hooks/queries/usePrograms';
import { useStore } from 'stores/Store';

const ProgramsPanel = ({ entityId }) => {
  const { push } = useHistory();
  const intl = useIntl();

  const { fetchPrograms, filterPrograms, loading } = usePrograms(entityId);
  const programs = useStore(state => state.programs);

  useEffect(() => {
    fetchPrograms();
  }, []);

  const onSearch = e => {
    filterPrograms({ search: e.target.value });
  };

  const onClickHandler = program => {
    push(
      generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, {
        entityId,
        programId: program.id
      })
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <ProgramsList
      entityId={entityId}
      programs={programs}
      onClick={program => onClickHandler(program)}
      type={`user`}
      onSearch={onSearch}
      emptyMessage={intl.formatMessage(messages.userProgramsEmptyMessage)}
    />
  );
};

export default ProgramsPanel;
