import { useContext } from 'react';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Card, CardBody } from 'components/card';
import { Table, TableBody, TableRow } from 'components/table';
import TableCell from 'components/table/TableCell';
import { checkLateralityIsHidden } from 'utils/tests';
import classNames from 'classnames';
import TestItemResultRow from 'containers/partials/cards/ReportCards/TestItemResultRow';
import { StoreContext } from 'index';
import SportsTableRow from 'containers/partials/cards/ReportCards/SportsTableRow';
import { FormattedMessage } from 'react-intl';
import messages from 'messages.js';
import { camelCaseify } from 'utils/string';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const PreventionCategoryCard = ({
  category,
  results,
  compressedTable,
  isAthlete,
  sides,
  options,
  isPrev
}) => {
  const {
    uiState: { locale },
    authStore: { user }
  } = useContext(StoreContext);
  const person = useEntityContext(s => s.person);

  //   CHECK THIS?
  //   Gebruiken we dit? en hoe dan?
  const categoryHasNotValues = false;

  /*const categoryHasNotValues = tests
    .map(test =>
      test.testItems.filter(
        ti =>
          !ti.hidden &&
          ti.hasValues &&
          !(!ti.hasBenchmarkedValues && !ti.showScore)
      )
    )
    .flat();*/

  return (
    <Card
      secondary
      extraClassNames={
        categoryHasNotValues.length === 0 &&
        options.hideEmpty &&
        !user.isAthlete() // Admin sees no empty categories when hide emnpty is checked
          ? 'u-hidden-visually'
          : ''
      }
    >
      <CardHeader secondary extraClassNames="u-margin-bottom-small">
        <CardHeaderText>
          <CardHeaderTitle>
            {messages?.[`testItemCategory${camelCaseify(category)}`] ? (
              <FormattedMessage
                {...messages[`testItemCategory${camelCaseify(category)}`]}
              />
            ) : (
              category
            )}
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>

      {/* Volgens mij komt hier nooit binnen */}
      {categoryHasNotValues.length === 0 && user.isAthlete() ? ( // Athlete sees empty categories with empty message
        <CardBody secondary empty>
          <p>
            <FormattedMessage {...messages.emptyCategoryLabel} />
          </p>
        </CardBody>
      ) : (
        <CardBody secondary>
          <div
            style={{
              maxWidth: '100%',
              overflow: 'auto'
            }}
          >
            {results.map(resultTestItem => {
              // if testitem is an IDO test, show the list of sports
              if (
                resultTestItem.testId === 'd061f19d-0c48-4355-9720-8b4bad9c730a'
              ) {
                return (
                  <SportsTableRow
                    key={resultTestItem.id}
                    testItem={resultTestItem}
                  />
                );
              }
              const noScoresNoBenchmark =
                !resultTestItem.hasBenchmarkedValues &&
                !resultTestItem.showScore;

              const testItemsWithoutBenchmarksHidden = options.showUnBenchmarked
                ? false
                : resultTestItem.hasBenchmarked &&
                  !resultTestItem.hasBenchmarkedValues;

              const testItemHasNotValues =
                !resultTestItem.hasValues ||
                noScoresNoBenchmark ||
                testItemsWithoutBenchmarksHidden;

              if (!resultTestItem.hidden) {
                if (!isAthlete || (isAthlete && resultTestItem.hasValues)) {
                  return (
                    <Table
                      key={resultTestItem.id}
                      tableReport
                      extraClassNames={classNames(
                        'c-table--results-left-right',
                        {
                          'u-margin-bottom-none': compressedTable,
                          'u-margin-bottom-small ': !compressedTable,
                          'u-hidden-visually':
                            options.hideEmpty && testItemHasNotValues
                        }
                      )}
                      compressedTable={compressedTable}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell
                            evenHalf
                            colSpan={2}
                            empty={testItemHasNotValues}
                          >
                            {resultTestItem.copy?.[locale]?.title ??
                              resultTestItem.title}
                            {/* Debug/ type laterality */}
                            {/* <small
                              style={{
                                position: 'absolute',
                                color: 'red',
                                top: 0
                              }}
                            > 
                             {resultTestItem.laterality} 
                             </small> */}
                          </TableCell>
                        </TableRow>

                        {testItemHasNotValues && (
                          <TableRow>
                            <TableCell evenHalf colSpan={2} empty></TableCell>
                          </TableRow>
                        )}

                        <TestItemResultRow
                          testItem={resultTestItem}
                          options={options}
                          sidesDisabled={checkLateralityIsHidden(
                            resultTestItem,
                            sides,
                            person
                          )}
                          isPreventionTest={isPrev}
                          relevantSides={sides}
                          noValues={noScoresNoBenchmark || testItemHasNotValues}
                          colSpan={
                            Object.keys(resultTestItem.sides)[0] === '2'
                              ? 2
                              : null
                          }
                        />
                      </TableBody>
                    </Table>
                  );
                }
              }

              return null;
            })}
          </div>
        </CardBody>
      )}
    </Card>
  );
};

export default PreventionCategoryCard;
