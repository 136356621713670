import { useNotificationQueue } from './index';
import Notification from './Notification';

export function NotificationList() {
  const queue = useNotificationQueue();

  return (
    <>
      {queue.entries.map(({ id, data }) => (
        <Notification
          key={id}
          id={id}
          message={data.message}
          level={data.level}
          duration={data.duration}
          position={data.position}
          closeable={data.closeable}
        />
      ))}
    </>
  );
}
