import React from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import GroupForm from 'containers/pages/persons/GroupForm';
import GroupFormDetails from 'containers/pages/persons/GroupFormDetails';

function GroupModal({
  entityId,
  type,
  group,
  parentGroup,
  inGroup,
  onClose,
  onDone
}) {
  const { push } = useHistory();

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onAddedHandler = async groupId => {
    push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId }));
    if (onDone) onDone();
  };

  const onCompleteHandler = async () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {type === 'add' ? (
            <FormattedMessage
              {...messages.titleSportersAddGroup}
              values={{
                inGroup,
                group: parentGroup.name,
                span: chunks => (
                  <span className="c-card__header-highlight">{chunks}</span>
                )
              }}
            />
          ) : (
            <FormattedMessage
              {...messages.titleSportersEditGroup}
              values={{
                inGroup,
                group: group.name,
                span: chunks => (
                  <span className="c-card__header-highlight">{chunks}</span>
                )
              }}
            />
          )}
        </CardHeaderTitle>
      </CardHeader>
      <GroupForm
        entityId={entityId}
        parentGroup={parentGroup}
        group={group}
        mode={type}
        onCancel={onClose}
        onAdded={onAddedHandler}
        onComplete={onCompleteHandler}
      >
        {({ isSubmitting }) => (
          <>
            <CardBody modalWithOverflow extraClassNames="u-padding-bottom-huge">
              <GroupFormDetails />
            </CardBody>

            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button
                  type="button"
                  secondary
                  onClick={() => onCloseHandler()}
                >
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </GroupForm>
    </Modal>
  );
}

export default GroupModal;
