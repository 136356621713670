import { Fragment, useEffect, useState } from 'react';
import TagsSelect from 'components/input/TagsSelect';
import Checkbox from 'components/input/Checkbox';
import slugify from 'slugify';
import useTags from 'hooks/queries/useTags';
import { useStore } from 'stores/Store';
import { useUIDSeed } from 'react-uid';
import useSelectable from 'hooks/utils/useSelectable';
import Loader from 'components/loader/Loader';
import classNames from 'classnames';

const TagMultiSelect = ({
  entityId,
  onChange,
  selectedTags,
  extraClassNames,
  menuPlacement
}) => {
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectable();

  const { fetchTags, loading } = useTags({ entityId });
  const { tags, getTagTree } = useStore(state => state);
  const [tagTree, setTagTree] = useState([]);

  useEffect(() => {
    const getTags = async () => {
      await fetchTags();
      setTagTree(getTagTree());
    };

    getTags();
  }, []);

  useEffect(() => {
    if (selectedTags) {
      setSelected(selectedTags.map(t => t.id));
    }
  }, [selectedTags]);

  const onTagSelect = actions => {
    let selectedIds = [];
    switch (actions.action) {
      case 'remove-value':
      case 'pop-value':
        selectedIds = select(actions.removedValue.id);
        break;
      case 'select-option':
        selectedIds = select(actions.option.id);
        break;
      // no default
    }

    onChange && onChange(tags.filter(t => selectedIds.includes(t.id)));
  };

  const onTagCheck = (e, tag) => {
    let selectedIds = select(tag);

    onChange && onChange(tags.filter(t => selectedIds.includes(t.id)));
  };

  return (
    <div className={classNames('c-input-tags-wrapper', extraClassNames)}>
      {loading && <Loader />}
      {tagTree.map(tag => {
        return (
          <Fragment key={tag.id}>
            {tag.children.length > 12 ? (
              <TagsSelect
                menuPlacement={menuPlacement}
                label={tag.label}
                options={tag.children}
                value={tag.children
                  .filter(t => selected.includes(t.id))
                  .map(tag => tag && { ...tag, value: tag.label })}
                isMulti
                isClearable={false}
                onChange={(value, actions) => onTagSelect(actions)}
              />
            ) : tag.children.length === 0 && !tag.isGroup ? (
              <fieldset className="c-input-group">
                <Checkbox
                  id={uidSeed(tag.id)}
                  name={
                    tag?.label
                      ? `checkbox-${slugify(tag.label.toLowerCase())}`
                      : `checkbox-${uidSeed(tag.id)}`
                  }
                  secondary
                  checked={selected.includes(tag.id)}
                  onChange={e => onTagCheck(e, tag.id)}
                >
                  {tag.label}
                </Checkbox>
              </fieldset>
            ) : tag.isGroup && tag.children.length > 0 ? (
              <fieldset className="c-input-group o-flex--justify-start">
                <legend className="c-input__label">{tag.label}</legend>
                {tag.children.map(tag => (
                  <Fragment key={tag.id}>
                    <Checkbox
                      id={uidSeed(tag.id)}
                      name={
                        tag?.label
                          ? `checkbox-${slugify(tag.label.toLowerCase())}`
                          : `checkbox-${uidSeed(tag.id)}`
                      }
                      secondary
                      checked={selected.includes(tag.id)}
                      onChange={e => onTagCheck(e, tag.id)}
                    >
                      {tag.label}
                    </Checkbox>
                  </Fragment>
                ))}
              </fieldset>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export default TagMultiSelect;
