import { create } from 'zustand';

export const useTestSetStore = create((set, get) => ({
  testSets: [],
  setTestSets: values => set({ testSets: values }),
  hasTestSet: id => {
    const testSets = get().testSets;
    return !!testSets.find(testSet => testSet.id === id);
  }
}));
