import React, { useContext } from 'react';
import { downloadSessionFormQuery } from 'services/aws/session-query';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import messages from 'messages';
import { MODAL_TYPES } from 'models/ModalData';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';
import { StoreContext } from 'index';
import slugify from 'slugify';

const DownloadEmptyFormButton = ({ session }) => {
  const intl = useIntl();
  const enIntl = createIntl({
    locale: 'en'
  });
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);

  const downloadSessionForm = async newWindow => {
    const openWindow = newWindow;
    try {
      uiState.increasePendingRequest();

      const filenameQuery = await downloadSessionFormQuery(session.id);

      if (filenameQuery) {
        const url = filenameQuery.data.printTestSessionForm;

        const response = await pollIsValidUrl(url, 25, 1000);
        if (response) {
          openWindow.location = url;
          openWindow.focus();
          //   setTimeout(() => {
          //     openWindow.close();
          //   }, 500);
        } else {
          throw Error('no pdf found');
        }
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionFormDownloadFailed),
        message: messages.messageSessionFormDownloadFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  if (!user.isHylyghtOrg) return <></>;
  if (session.isILike) return <></>;

  return (
    <Button
      menu
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadSessionForm(newWindow);
      }}
      dataQa={`${slugify(
        enIntl.formatMessage(messages.menuItemSessionDownloadForm).toLowerCase()
      )}-menu`}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadForm} />
    </Button>
  );
};

export default DownloadEmptyFormButton;
