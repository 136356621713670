import { useContext, useState } from 'react';
import Panel from 'components/panel/Panel';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { getLocalDateFormat } from 'utils/date';
import { PanelHeader } from 'components/panel';
import { StoreContext } from 'index';
import { useHistory } from 'react-router-dom';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import { CardHeaderText, CardHeaderTitle } from 'components/card/card-header';
import CardHeader from 'components/card/card-header/CardHeader';
import CardBody from 'components/card/CardBody';
import Button from 'components/button/Button';
import { useLazyQuery } from '@apollo/client';
import {
  QUERY_GET_USAGE,
  QUERY_GET_USAGE_OF_SUBORG
} from 'services/aws/account-query';

import Loader from 'components/loader/Loader';
import * as Yup from 'yup';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { Form, Formik } from 'formik';
import MessageText from 'components/message/MessageText';
import Icon from 'components/icon/Icon';
import Badge from 'components/badge/Badge';
import { isNumber } from 'utils/math';
import classNames from 'classnames';
import { DATE_LOCALE } from 'constants.js';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { format } from 'date-fns';
import FieldCheckbox from 'components/input/FieldCheckbox';

Usage.propTypes = {};

function Usage() {
  const {
    authStore: {
      user: { rootEntityId, baseLanguage }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const intl = useIntl();
  const [numberOfAthletes, setNumberOfAthletes] = useState('-');
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [usageSubOrgsMessage, setUsageSubOrgsMessage] = useState(false);

  const [getUsage, { loading: loadingUsage }] = useLazyQuery(QUERY_GET_USAGE);
  const [getUsageSubOrg, { loading: loadingUsageSubOrg }] = useLazyQuery(
    QUERY_GET_USAGE_OF_SUBORG
  );

  const limitDateInPast = new Date(1930, 0, 1);
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const validationSchema = Yup.object().shape({
    startdate: Yup.date().required(
      intl.formatMessage(messages.startdateRequired)
    ),
    //   .min(limitDateInPast, 'min day 1930')
    //   .max(yesterday, 'max day today'),
    enddate: Yup.date().required(intl.formatMessage(messages.enddateRequired))
    //   .min(limitDateInPast, 'min day 1930')
    //   .max(today, 'max day today')
    //   .when(
    //     'startdate',
    //     (startdate, schema) =>
    //       startdate && schema.min(startdate, 'start date must before end date')
    //   )
  });

  const countSportersHandler = async formData => {
    setDateRange({
      startDate: formData.startdate,
      endDate: formData.enddate
    });

    if (formData.countSubOrgs) {
      const { data } = await getUsageSubOrg({
        variables: {
          startdate: format(formData.startdate, 'yyyy-LL-dd'),
          enddate: format(formData.enddate, 'yyyy-LL-dd'),
          entityId: rootEntityId
        }
      });

      if (data?.getUsageOfSubOrganisations) {
        setUsageSubOrgsMessage(true);
        setNumberOfAthletes(null);
      }
    } else {
      const { data } = await getUsage({
        variables: {
          startdate: format(formData.startdate, 'yyyy-LL-dd'),
          enddate: format(formData.enddate, 'yyyy-LL-dd'),
          entityId: rootEntityId
        }
      });

      if (data?.getUsage) {
        const usageData = JSON.parse(data.getUsage);
        setNumberOfAthletes(usageData.athletesUsed);
        setUsageSubOrgsMessage(false);
      }
    }
  };

  return (
    <Panel>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbAccountUsage} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.accountUsageTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.accountUsageSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary>
          <CardHeader secondary>
            <CardHeaderText extraClassNames={'u-margin-bottom-none'}>
              <CardHeaderTitle>
                <FormattedMessage {...messages.accountUsageSportersTitle} />
              </CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary>
            {(loadingUsage || loadingUsageSubOrg) && <Loader />}
            <p>
              <FormattedMessage {...messages.accountUsageSportersInfo} />
            </p>
            <Formik
              initialValues={{
                startdate: oneMonthAgo,
                enddate: today,
                countSubOrgs: false
              }}
              validationSchema={validationSchema}
              onSubmit={countSportersHandler}
            >
              {({ errors, handleChange }) => (
                <Form noValidate>
                  <div className="o-layout o-layout--tiny">
                    <div
                      className={classNames('o-layout__item u-1-of-2-at-small')}
                    >
                      <FieldDatePicker
                        id={'startdate'}
                        name="startdate"
                        min={limitDateInPast}
                        max={yesterday}
                        onChange={handleChange}
                        required
                        icon={'calendar'}
                        errors={errors}
                      >
                        <FormattedMessage {...messages.globalStartdateLabel} />
                      </FieldDatePicker>
                    </div>
                    <div
                      className={classNames('o-layout__item u-1-of-2-at-small')}
                    >
                      <FieldDatePicker
                        id="enddate"
                        name="enddate"
                        min={limitDateInPast}
                        max={today}
                        onChange={handleChange}
                        required
                        icon={'calendar'}
                        errors={errors}
                      >
                        <FormattedMessage {...messages.globalEnddateLabel} />
                      </FieldDatePicker>
                    </div>
                    <div className="o-layout__item c-input-checkbox--with-paragraph u-margin-bottom-medium">
                      <FieldCheckbox
                        id={`countSubOrgs`}
                        name={`countSubOrgs`}
                        secondary
                        inputExtraClassNames={'u-hidden-visually'}
                      >
                        <FormattedMessage
                          {...messages.accounUsageCountSportersInSubOrgs}
                        />
                      </FieldCheckbox>
                    </div>
                    <div className="o-layout__item">
                      <div className="o-flex o-flex--justify-start">
                        <Button type="submit" primary small>
                          <FormattedMessage
                            {...messages.accounUsageCountSporters}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>

        {isNumber(numberOfAthletes) && (
          <Card
            secondary
            extraClassNames="c-card--remarks u-padding-vertical-medium"
          >
            <Icon id="sporters" />
            <div className="c-card__content u-margin-left-small">
              <CardBody secondary remarks>
                <MessageText extraClassNames="o-flex o-flex--wrap o-flex--align-center">
                  <FormattedMessage
                    {...messages.accountUsageAnswer}
                    values={{
                      startdate: getLocalDateFormat(
                        dateRange.startDate,
                        'yyyy-LL-dd',
                        'dd MMMM yyyy',
                        { locale: DATE_LOCALE[baseLanguage] }
                      ),
                      enddate: getLocalDateFormat(
                        dateRange.endDate,
                        'yyyy-LL-dd',
                        'dd MMMM yyyy',
                        { locale: DATE_LOCALE[baseLanguage] }
                      ),
                      activeUsers: numberOfAthletes,
                      b: chunks => (
                        <b style={{ display: 'inline-block', margin: '0 4px' }}>
                          {chunks}
                        </b>
                      ),
                      span: chunks => (
                        <Badge
                          larger
                          extraClassNames="u-margin-horizontal-tiny u-bgcolor-primary u-color-neutral-xlight"
                        >
                          {chunks}
                        </Badge>
                      )
                    }}
                  />
                </MessageText>
              </CardBody>
            </div>
          </Card>
        )}

        {usageSubOrgsMessage && (
          <Card secondary extraClassNames="c-card--remarks u-padding-medium">
            <CardBody
              empty
              secondary
              //   remarks
              extraClassNames="u-bgcolor-neutral-xlight"
            >
              <Icon id="big-send" xHugeIcon />

              <MessageText
                success
                extraClassNames="u-margin-horizontal-auto u-margin-bottom-huge"
                style={{ maxWidth: '24rem' }}
              >
                <FormattedMessage
                  {...messages.accountUsageSubOrgsAnswer}
                  values={{
                    startdate: getLocalDateFormat(
                      dateRange.startDate,
                      'yyyy-LL-dd',
                      'dd MMMM yyyy',
                      { locale: DATE_LOCALE[baseLanguage] }
                    ),
                    enddate: getLocalDateFormat(
                      dateRange.endDate,
                      'yyyy-LL-dd',
                      'dd MMMM yyyy',
                      { locale: DATE_LOCALE[baseLanguage] }
                    ),
                    b: chunks => (
                      <b style={{ display: 'inline-block', margin: '0 4px' }}>
                        {chunks}
                      </b>
                    )
                  }}
                />
              </MessageText>
            </CardBody>
          </Card>
        )}
        {/* </Message> */}
      </PanelBody>
    </Panel>
  );
}

//
export default Usage;
