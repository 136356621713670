import { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { PersonEntityRoles, PersonStatus } from 'constants';
import {
  ROUTE_SETTINGS_USERS_USER,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL
} from 'routes/RouteList';
import {
  createLogin,
  MUTATE_RESET_LOGIN,
  MUTATE_UPDATE_USER_ROLES,
  QUERY_GET_PERSONS_OF_ENTITY,
  QUERY_GET_USER_BY_ID
} from 'services/aws/user-query';
import messages from 'messages';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { StoreContext } from 'index';
import { useIntl } from 'react-intl';
import { useNotificationQueue } from 'components/notification';

const usePersonStatus = (entityId, groupId = null, refetch) => {
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { push } = useHistory();
  const { uiState } = useContext(StoreContext);

  const personStatusHandler = person => {
    let mutation;
    let variables;

    switch (person.status) {
      case PersonStatus.UNCONFIRMED:
        if (person.email) {
          mutation = MUTATE_RESET_LOGIN;
          variables = {
            personId: person.id
          };
        } else {
          mutation = MUTATE_UPDATE_USER_ROLES;
          variables = {
            personId: person.id,
            entityId,
            roles: [PersonEntityRoles.ROLE_ATHLETE]
          };
        }
        uiState.showModal({
          title: intl.formatMessage(messages.modalConfirmInviteSporterTitle),
          message: messages.modalConfirmReInviteSporterMessage,
          okLabel: intl.formatMessage(messages.modalConfirmInviteAcceptButton),
          dismissLabel: intl.formatMessage(
            messages.modalConfirmRemoveCancelButton
          ),
          mutationData: {
            mutation,
            variables,
            refetchQueries: [
              {
                query: S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
                variables: {
                  id: entityId
                }
              },
              {
                query: QUERY_GET_PERSONS_OF_ENTITY,
                variables: {
                  entityId
                }
              },
              {
                query: QUERY_GET_USER_BY_ID,
                variables: {
                  id: person.id
                }
              }
            ],
            update: async (cache, { data: { updatePersonEntityRoles } }) => {
              if (updatePersonEntityRoles) {
                if (!person.email) {
                  await createLogin(person.id);
                }
                notification.add(`personInvite_${person.id}`, {
                  message: intl.formatMessage(messages.messageUserInvited)
                });
                if (refetch) await refetch(entityId);
              }
            }
          }
        });
        break;
      case PersonStatus.EMAIL:
      case PersonStatus.USER:
      case PersonStatus.PENDING:
      default:
        // navigate to user
        if (groupId) {
          push(
            generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL, {
              entityId,
              groupId,
              sporterId: person.id
            })
          );
        } else {
          push(
            generatePath(ROUTE_SETTINGS_USERS_USER, {
              userId: person.id
            })
          );
        }
        break;
    }
  };

  return { personStatusHandler };
};

export default usePersonStatus;
