import React from 'react';
import { CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import useEdit from 'hooks/utils/useEdit';
import FieldInput from 'components/input/FieldInput';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import ProgramForm from 'containers/pages/exercises/programs/ProgramForm';
import CardFooter from 'components/card/CardFooter';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT } from 'routes/RouteList';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useStore } from 'stores/Store';

const ProgramDetailsTab = ({ onCancel }) => {
  const intl = useIntl();
  const { entityId } = useParams();
  const program = useStore(state => state.program);

  const [edit, setEdit] = useEdit(
    !!useRouteMatch([ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT])
  );
  return (
    <ProgramForm
      entityId={entityId}
      program={program}
      extraClassNames={'c-card c-card--secondary'}
      onComplete={() => setEdit(false)}
    >
      {({ resetForm, submitForm, errors, touched, handleChange }) => (
        <>
          <CardHeader secondary>
            <CardHeaderTitle>
              <FormattedMessage {...messages.programDetailTitle} />
            </CardHeaderTitle>
            <FormEditHeaderButtons
              edit={edit}
              setEdit={setEdit}
              onCancel={resetForm}
              onSubmit={submitForm}
            />
          </CardHeader>
          <CardBody secondary>
            <FieldInput
              id="title"
              name="title"
              placeholder={intl.formatMessage(messages.programLabelName)}
              readOnly={!edit}
              errors={errors}
              touched={touched}
            >
              <FormattedMessage {...messages.programLabelName} />
            </FieldInput>
            <div className="o-layout o-layout--small">
              <div className="o-layout__item u-1-of-2">
                <FieldDatePicker
                  id="startdate"
                  name="startdate"
                  icon="calendar"
                  placeholder={intl.formatMessage(
                    messages.programLabelStartDate
                  )}
                  readOnly={!edit}
                  disabled={!edit}
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  required
                >
                  <FormattedMessage {...messages.programLabelStartDate} />
                </FieldDatePicker>
              </div>
              <div className="o-layout__item u-1-of-2">
                <FieldInput
                  id="duration"
                  name="duration"
                  placeholder={intl.formatMessage(
                    messages.programPlaceholderWeeks
                  )}
                  readOnly={!edit}
                  errors={errors}
                  touched={touched}
                >
                  <FormattedMessage {...messages.programLabelWeeks} />
                </FieldInput>
              </div>
            </div>
          </CardBody>
          {edit && (
            <CardFooter secondary>
              <FormEditFooterButtons
                edit={edit}
                setEdit={edit => setEdit(edit)}
                onCancel={() => {
                  resetForm();
                  onCancel && onCancel();
                }}
                onSubmit={submitForm}
                type={'button'}
              />
            </CardFooter>
          )}
        </>
      )}
    </ProgramForm>
  );
};

export default ProgramDetailsTab;
