import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  MUTATE_RESET_LOGIN,
  updateUserMutation
} from 'services/aws/user-query';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import useFetchAndFilterPersonsPool from 'hooks/queries/useFetchAndFilterPersonsPool';
import usePerson from 'hooks/queries/usePerson';
import { StoreContext } from 'index';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import { PersonEntityRoles } from 'constants';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import useFetchAndFilterUsers from 'hooks/queries/useFetchAndFilterUsers';

const ResetLoginButton = ({
  entityId,
  isUser = false,
  target,
  children,
  extraClassNames,
  redirect,
  title = null
}) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { push } = useHistory();
  const timeout = useRef();

  const person = useEntityContext(s => s.person);
  const { fetchPool } = useFetchAndFilterPersonsPool({ entityId });
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });
  const { fetchUsers } = useFetchAndFilterUsers({ entityId });
  const group = useEntityContext(s => s.group);
  const { fetchPerson } = usePerson({ personId: person.id });

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
      uiState.decreasePendingRequest();
    };
  }, []);

  return (
    <ConfirmMutationButton
      title={title}
      small
      light
      extraClassNames={extraClassNames}
      confirmTitle={messages.modalConfirmResetPasswordTitle}
      confirmMessage={messages.modalConfirmResetPasswordMessage}
      confirmAcceptLabel={messages.modalConfirmResetPasswordAcceptButton}
      mutation={MUTATE_RESET_LOGIN}
      variables={{ personId: person.id }}
      update={(cache, { data: { resetLogin } }) => {
        if (resetLogin) {
          uiState.increasePendingRequest();
          timeout.current = setTimeout(async () => {
            if (!isUser) {
              await fetchPool();
              if (group.id) {
                await fetchEntities(group.id);
              }
            } else {
              await fetchUsers(entityId);
            }
            const personData = await fetchPerson(person.id);
            if (!personData.hasRole(PersonEntityRoles.ROLE_ATHLETE)) {
              await updateUserMutation({
                variables: {
                  personId: personData.id,
                  entityId,
                  roles: [PersonEntityRoles.ROLE_ATHLETE]
                },
                refetchQueries: ['getPerson']
              });
            }
            uiState.decreasePendingRequest();
            notification.add(`emailReset_${person.id}`, {
              message: intl.formatMessage(messages.messageUserEmailReset)
            });
            if (redirect) {
              push(redirect);
              return;
            }
            push(target);
          }, 100); // Delay from the backend
        }
      }}
      onError={error => {
        uiState.decreasePendingRequest();
        notification.add(`emailReset_error_${person.id}`, {
          message: error.message,
          level: 'error'
        });
      }}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default ResetLoginButton;
