import { useState, useEffect, useContext, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_ORGANISATION_TREE } from 'services/aws/entity-query';
import Loader from 'components/loader/Loader';
import { CardBody } from 'components/card';
import AccordionLayer from 'components/accordion/AccordionLayer';
import { StoreContext } from 'index';
import { useIntl } from 'react-intl';
import messages from 'messages';

function OrganisationTree({ entity }) {
  const intl = useIntl();
  const {
    authStore,
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const [activeTreeId, setActiveTreeId] = useState(false);
  const [tree, setTree] = useState([]);
  const [crumbs, setCrumbs] = useState([]);

  const { loading, data } = useQuery(QUERY_GET_ORGANISATION_TREE, {
    variables: { id: rootEntityId },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (data?.getOrganisationTree) {
      const tree = JSON.parse(data.getOrganisationTree);
      setTree(tree);
      const crumbPath = buildOrgCrumb(tree, entity, []);
      setCrumbs(crumbPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useLayoutEffect(() => {
    if (crumbs && crumbs.length > 0) {
      const selectedEl = document.getElementById(`radio_${entity.id}`);
      selectedEl.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crumbs]);

  return (
    <CardBody modal>
      {loading && (
        <div
          className="o-flex"
          style={{
            minHeight: 350,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loader />
        </div>
      )}

      {tree.map(org => (
        <AccordionLayer
          key={org.id}
          org={org}
          defaultChecked={entity.id}
          onActiveTree={id => {
            setActiveTreeId(id);
          }}
          crumbs={crumbs}
          onChange={({ id }) => authStore.switchEntityId(id)}
          labelUp={intl.formatMessage(messages.headerTreeUpLabel)}
          labelArrow={intl.formatMessage(messages.headerTreeOrgsLabel)}
          extraClassNames={
            !activeTreeId || activeTreeId === org.id
              ? 'open-layer'
              : 'another-layer'
          }
        />
      ))}
    </CardBody>
  );
}

export default OrganisationTree;

const buildOrgCrumb = (branch, entity, path) => {
  const orgInBranch = branch.find(org => org.id === entity.id);
  if (orgInBranch) {
    return [...path, orgInBranch];
  }
  for (let i = 0; i < branch.length; i++) {
    if (branch[i].subEntities.length > 0) {
      const subPath = buildOrgCrumb(branch[i].subEntities, entity, [
        ...path,
        branch[i]
      ]);
      if (subPath) {
        return subPath;
      }
    }
  }
};
