import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_PERSON_INJURY,
  MUTATION_EDIT_PERSON_INJURY,
  QUERY_GET_PERSON_MEDICALS
} from 'services/aws/injury-query';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL } from 'routes/RouteList';
import { StoreContext } from 'index';
import { useMedicalContext } from 'contexts/MedicalContext';
import messages from 'messages';
import { parseDateString } from 'utils/date';
import { format } from 'date-fns';
import { PersonInjuryType } from 'constants.js';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function IllnessForm({ personIllness, extraClassNames, onComplete, children }) {
  const { entityId, groupId } = useParams();
  const notification = useNotificationQueue();
  const intl = useIntl();
  const { push } = useHistory();
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const person = useEntityContext(s => s.person);
  const { medical } = useMedicalContext();

  const [addIllness] = useMutation(MUTATION_ADD_PERSON_INJURY);

  const [editIllness] = useMutation(MUTATION_EDIT_PERSON_INJURY);

  const onSubmitHandler = async personIllness => {
    const illnessObject = {
      title: personIllness.title,
      medicalId: medical.id,
      injuryDate: format(personIllness.injuryDate, 'yyyy-LL-dd'),
      injuryEndDate: personIllness.injuryEndDate
        ? format(personIllness.injuryEndDate, 'yyyy-LL-dd')
        : null,
      personInjuryType: PersonInjuryType.ILLNESS,
      meta: JSON.stringify({
        description: personIllness.description,
        extraInfo: personIllness.extraInfo
      })
    };

    if (personIllness.id) {
      await editIllness({
        variables: { ...personIllness, ...illnessObject },
        refetchQueries: [
          {
            query: QUERY_GET_PERSON_MEDICALS,
            variables: { entityId: rootEntityId, personId: person.id }
          }
        ]
      }).then(res => {
        notification.add(res.data.editPersonInjury.id, {
          message: intl.formatMessage(messages.rehabIllnessSaved)
        });
        if (onComplete) onComplete();
        push(
          generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL, {
            entityId,
            groupId,
            sporterId: person.id,
            medicalId: medical.id,
            illnessId: res.data.editPersonInjury.id
          })
        );
      });
    } else {
      await addIllness({
        variables: illnessObject,
        refetchQueries: [
          {
            query: QUERY_GET_PERSON_MEDICALS,
            variables: { entityId: rootEntityId, personId: person.id }
          }
        ]
      }).then(res => {
        notification.add(res.data.addPersonInjury.id, {
          message: intl.formatMessage(messages.rehabIllnessSaved)
        });
        if (onComplete) onComplete();
        push(
          generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL, {
            entityId,
            groupId,
            sporterId: person.id,
            medicalId: medical.id,
            illnessId: res.data.addPersonInjury.id
          })
        );
      });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    injuryDate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.dateRequired)),
    injuryEndDate: Yup.date()
      .transform(parseDateString)
      .nullable()
      .default(null)
  });

  return (
    <Formik
      initialValues={personIllness}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default IllnessForm;
