import { useEffect } from 'react';
import messages from 'messages';
import VirtualizedSessionList from 'containers/pages/sessions/VirtualizedSessionList';
import useSelectableObject from 'hooks/utils/useSelectableObject';
import { useParams } from 'react-router-dom';
import { SORT_DATA_TYPES } from 'utils/sort';
import { useSessionsContext } from 'contexts/SessionsContext';
import useSessionSearch from 'hooks/utils/useSessionSearch';

const columns = [
  {
    key: 'finishedPercentage',
    label: '%',
    sortable: true,
    dataType: SORT_DATA_TYPES.NUMBER,
    desc: false,
    extraProps: { id: 'listItemStatus' } // this prop is only necessary for styles. Most of the times we don't need it
  },
  {
    key: 'name',
    label: 'Session name',
    sortable: true,
    desc: false,
    dataType: SORT_DATA_TYPES.STRING,
    extraProps: { id: 'listItemMainLabel' } // this prop is only necessary for styles. Most of the times we don't need it
  },
  {
    key: 'type',
    label: 'Type',
    sortable: false,
    extraProps: { id: '' } // Empty id === hidden column
  },
  {
    key: 'startdate',
    label: 'Date',
    default: true,
    sortable: true,
    desc: true,
    dataType: SORT_DATA_TYPES.DATE_STRING,
    extraProps: { id: 'listItemLastLabel' } // this prop is only necessary for styles. Most of the times we don't need it
  }
];

function SessionsList({ onSelected = null, onClick, checkable = false }) {
  const { entityId, sessionId } = useParams();
  const { selected, setSelected } = useSelectableObject();
  const { sessions } = useSessionsContext();
  const [state, setState] = useSessionSearch({
    key: 'name',
    defaultSort: columns.find(c => c.default)
  });

  useEffect(() => {
    setState({ items: sessions });
  }, [sessions]);

  const onSelectSessionsHandler = selected => {
    setSelected(selected);
    onSelected && onSelected(selected);
  };

  return (
    <>
      <VirtualizedSessionList
        refactoring
        activeId={sessionId}
        sortColumns={columns}
        variableHeight
        entityId={entityId}
        items={[...state.filteredItems]}
        selectedItems={[...selected]}
        checkableGroups
        checkable={checkable}
        emptyMessage={messages.listEmpty}
        onClick={onClick}
        onFilter={filter => setState(filter)}
        onSort={sort => setState({ sort })}
        onSelect={selected => {
          onSelectSessionsHandler(selected);
        }}
        filteringTerm={state}
      />
    </>
  );
}

export default SessionsList;
