import { useContext, useEffect, useState } from 'react';
import { withRouter, generatePath, useHistory } from 'react-router-dom';

import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_TESTSETS,
  ROUTE_SETTINGS_TESTSETS_TESTSET
} from 'routes/RouteList';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelTitleButton from 'components/panel/panel-title/PanelTitleButton';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import { MenuWrapper } from 'components/menu';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import TestSetModal, {
  TESTSET_UPDATE_TYPE
} from 'containers/partials/modals/TestSetModal';
import RemoveTestSetButton from 'containers/partials/buttons/RemoveTestSetButton';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import messages from 'messages';
import { StoreContext } from 'index';
import { useTestSetContext } from 'contexts/TestSetContext';
import { useTestsContext } from 'contexts/TestsContext';
import TestsList from 'containers/partials/lists/TestsList';
import useSelectable from 'hooks/utils/useSelectable';

const TestSet = ({ entityId, testSetId, type, tabIndex }) => {
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const { push } = useHistory();
  const { testsState, testActions, error: errorTests } = useTestsContext();
  const { actions, state, loading } = useTestSetContext();
  const { selected, setSelected } = useSelectable();

  const [testSet, setTestSet] = useState({});
  const [modalData, setModalData] = useState(null);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setEdit(false);
    actions.getTestSet(testSetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSetId]);

  useEffect(() => {
    testActions.setTests([]);
    testActions.updateTests([]);

    if (state.testSet) {
      setTestSet(state.testSet);

      testActions.setTests(state.testSet.tests);
      if (testsState.tests.length > 0) {
        testActions.updateTests(state.testSet.tests);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSetId, state.testSet]);

  useEffect(() => {
    if (testsState.selectedTests) {
      setSelected(testsState.selectedTests.map(t => t.id));
    } else {
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testsState.selectedTests]);

  const onSubmitHandler = async (e, testSet) => {
    e.preventDefault();

    await actions.editTestSet({
      id: testSet.id,
      title: testSet.title,
      note: testSet.note || undefined,
      defaultBenchmarkId: testSet?.defaultBenchmark?.id || undefined,
      testIds: selected
    });

    setEdit(false);
  };

  const onCancel = e => {
    e.preventDefault();

    setEdit(false);
  };

  return (
    <Panel>
      {modalData && (
        <TestSetModal
          entityId={entityId}
          {...modalData}
          onClose={() => setModalData(null)}
        />
      )}
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettings} />
          </Breadcrumb>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS_TESTSETS)}>
            <FormattedMessage {...messages.breadcrumbTestsets} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {testSet.title}
            {!edit && testSet.editable && user.canEditTestSets && (
              <MenuWrapper trigger={<PanelTitleButton />}>
                <Button
                  menu
                  onClick={() =>
                    setModalData({
                      testSet,
                      testSetType: testSet.type,
                      type: TESTSET_UPDATE_TYPE.EDIT
                    })
                  }
                >
                  <Icon id="edit" />
                  <FormattedMessage
                    {...messages.testSetsTestsetMenuItemEditName}
                  />
                </Button>
                <Button
                  menu
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  <Icon id="edit" />
                  <FormattedMessage
                    {...messages.testSetsTestsetMenuItemEditTests}
                  />
                </Button>
                <Button
                  menu
                  onClick={() =>
                    setModalData({
                      testSet,
                      testIds: testsState.selectedTests.map(t => t.id),
                      type: TESTSET_UPDATE_TYPE.COPY
                    })
                  }
                >
                  <FormattedMessage {...messages.testSetsTestsetMenuItemCopy} />
                  <Icon id="copy" />
                </Button>
                <RemoveTestSetButton
                  menu
                  testSetId={testSet.id}
                  entityId={rootEntityId}
                  onComplete={() => {
                    actions.getTestSets([type]);
                  }}
                >
                  <FormattedMessage
                    {...messages.testSetsTestsetMenuItemRemove}
                  />
                </RemoveTestSetButton>
              </MenuWrapper>
            )}
          </PanelTitle>
          <PanelSubtitle>
            {testSet.note || (
              <FormattedMessage
                {...messages.testSetsTestsetSubtitle}
                values={{ name: testSet.title }}
              />
            )}
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        {errorTests && <RetryPanel />}

        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab
              onClick={() =>
                push(
                  generatePath(ROUTE_SETTINGS_TESTSETS_TESTSET, {
                    testSetId,
                    type
                  })
                )
              }
            >
              <FormattedMessage {...messages.testSetsTestsetTabTests} />
            </Tab>
          </TabList>
          <TabPanel>
            <div
              className="o-flex o-flex--column u-padding-vertical-medium"
              style={{ overflowY: 'scroll', flex: 1 }}
            >
              <TestsList
                type={type}
                checkable
                edit={edit}
                selectedTests={selected}
                onSelect={exerciseIds => setSelected(exerciseIds)}
              />
              {edit && (
                <ButtonsGroup extraClassNames="u-padding-top-small u-padding-bottom-medium">
                  <Button
                    type="button"
                    secondary
                    disabled={loading}
                    onClick={e => onCancel(e, testSet)}
                  >
                    <FormattedMessage {...messages.testSetsTestsButtonCancel} />
                  </Button>
                  <Button
                    primary
                    disabled={loading}
                    onClick={e => onSubmitHandler(e, testSet)}
                  >
                    <FormattedMessage {...messages.testSetsTestsButtonSave} />
                  </Button>
                </ButtonsGroup>
              )}
            </div>
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default withRouter(TestSet);
