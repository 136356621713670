// import * as urlExists from 'url-exists';
import {
  ROUTE_TESTS_PACKAGE_SESSION,
  ROUTE_SURVEY_PACKAGE_SESSION_LIST,
  ROUTE_SURVEY_PACKAGE_SESSION_SPORTER
} from 'routes/RouteList';
import { PackageTypes } from 'constants.js';
import { getFullLocation } from './browser';
import slugify from 'slugify';
import { generatePath } from 'react-router-dom';

export const getTestSessionURL = ({
  entityId,
  sessionId,
  packageType,
  sporterId,
  session,
  copyAble,
  locale
}) => {
  const relativePath =
    packageType === PackageTypes.SAP && sporterId
      ? ROUTE_SURVEY_PACKAGE_SESSION_SPORTER
      : packageType === PackageTypes.SAP
        ? ROUTE_SURVEY_PACKAGE_SESSION_LIST
        : ROUTE_TESTS_PACKAGE_SESSION;

  const path = generatePath(relativePath, {
    packageId: packageType,
    entityId: entityId,
    sessionId: sessionId,
    sporterId: sporterId
  });
  const sessionUrl = new URL(path, getFullLocation());

  if (locale) sessionUrl.searchParams.append('locale', locale);
  if (copyAble)
    sessionUrl.searchParams.append('session', slugify(session.name));

  return sessionUrl.toString();
};

export const pollIsValidUrl = async (url, times = 3, delay = 1000) => {
  let response;
  try {
    response = await pollerIsValidUrl(url, times, delay);
  } catch (er) {}

  return !!response;
};

const pollerIsValidUrl = (target, times, delay) => {
  return new Promise(res => {
    (function rec() {
      fetch(target).then(function (response) {
        if (response.status === 200) {
          res(true);
        } else {
          if (times === 0) return res(false);
          setTimeout(() => rec(--times), delay);
        }
      });
    })();
  });
};

export const getEntityFromUrl = url => {
  const segments = url.pathname.split('/');
  const entityId = segments[2];
  const regex =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

  if (entityId) {
    if (entityId.match(regex) || entityId === '00001') return entityId;
  }

  return null;
};
