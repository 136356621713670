import React from 'react';
import classNames from 'classnames';

interface RefactoredTableHeaderCellProps {
  mainCell?: boolean; // TODO @Fabian: Ik heb deze erbij gezet zodat het visueel klopte
  sortedBy?: boolean; // TODO @Fabian: Ik heb deze erbij gezet zodat het visueel klopte
  style?: React.CSSProperties; // TODO @Fabian: Ik heb deze erbij gezet om sizing te testen
  children?: React.ReactNode;
}

const RefactoredTableHeaderCell = ({
  mainCell,
  sortedBy,
  style,
  children
}: RefactoredTableHeaderCellProps) => {
  return (
    <th
      className={classNames('r-table-cell', {
        'c-table-cell c-table-cell--main c-table-cell--header': mainCell,
        'c-table-cell--sorted': sortedBy
      })}
      style={style}
    >
      <div className={classNames('o-flex')}>{children}</div>
    </th>
  );
};

export default RefactoredTableHeaderCell;
