import { useLazyQuery } from '@apollo/client';
import { useStore } from 'stores/Store';
import Program from 'models/Program';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { QUERY_GET_PROGRAMS } from 'services/aws/programs-query';
import { getFilteredEntities } from 'utils/search';

const orderBy = [
  { key: 'order', dataType: SORT_DATA_TYPES.NUMBER },
  { key: 'sortDate', dataType: SORT_DATA_TYPES.NUMBER }
];

const usePrograms = entityId => {
  const setPrograms = useStore(state => state.setPrograms);
  const programsRef = useStore(state => state.programsRef);
  const setProgramsRef = useStore(state => state.setProgramsRef);
  const setProgramsFilter = useStore(state => state.setProgramsFilter);

  const [getPrograms, { loading, error }] = useLazyQuery(QUERY_GET_PROGRAMS);

  const fetchPrograms = async options => {
    const { data } = await getPrograms({
      variables: { entityId },
      ...options
    });
    if (data?.getExercisePrograms) {
      const programs = data.getExercisePrograms.map(
        program => new Program({ ...program })
      );
      setProgramsRef(programs);
      setPrograms(sort(programs, { keys: orderBy }));
    }
  };

  const filterPrograms = async filter => {
    setProgramsFilter(filter);
    const programs = [...programsRef];

    setPrograms(
      sort(getFilteredEntities(programs, filter.search), { keys: orderBy })
    );
  };

  return {
    loading,
    error,
    fetchPrograms,
    filterPrograms
  };
};

export default usePrograms;
