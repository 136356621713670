import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { createIntl, FormattedMessage } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { exportRawSession } from 'services/aws/session-query';
import { navigateToUrl } from 'utils/browser';
import { StoreContext } from 'index';
import slugify from 'slugify';

const ExportReportButton = ({ session }) => {
  const { uiState } = useContext(StoreContext);
  const enIntl = createIntl({
    locale: 'en'
  });
  const exportSessionHandler = async () => {
    uiState.increasePendingRequest();
    const filename = await exportRawSession(session.id);
    if (filename) {
      navigateToUrl(filename);
    }
    uiState.decreasePendingRequest();
  };

  if (session.isIAm || session.isDjas) return <></>;

  return (
    <Button
      menu
      onClick={exportSessionHandler}
      dataQa={`${slugify(
        enIntl
          .formatMessage(messages.menuItemSessionExportSession)
          .toLowerCase()
      )}-menu`}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionExportSession} />
    </Button>
  );
};

export default ExportReportButton;
