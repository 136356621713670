import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import {
  MUTATION_ADD_PERSONS_TO_TESTSESSION,
  QUERY_GET_SESSION_BY_ID
} from 'services/aws/session-query';
import { useSessionContext } from 'contexts/SessionContext';
import { useNotificationQueue } from 'components/notification';
import Person from 'models/Person';
import PersonsTabs from 'containers/pages/persons/PersonsTabs';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function AssignPersonsToSessionModal({ entityId, onClose, onDone }) {
  const intl = useIntl();
  const { session } = useSessionContext();
  const notification = useNotificationQueue();

  const selectedPersons = useEntityContext(s => s.selectedPersons);
  const excludedPersons = useEntityContext(s => s.excludedPersons);
  const setExcludedPersons = useEntityContext(s => s.setExcludedPersons);
  const clearSelected = useEntityContext(s => s.clearSelected);

  const [assignPersons] = useMutation(MUTATION_ADD_PERSONS_TO_TESTSESSION);

  useEffect(() => {
    return () => {
      clearSelected();
    };
  }, []);

  useEffect(() => {
    if (session) {
      setExcludedPersons(session.personEntities.map(p => new Person(p.person)));
    }
  }, [session]);

  const onAddPersonsHandler = async () => {
    if (session.id) {
      try {
        const { data } = await assignPersons({
          variables: {
            testSessionId: session.id,
            personIds: selectedPersons.map(person => person.id)
          },
          refetchQueries: [
            {
              query: QUERY_GET_SESSION_BY_ID,
              variables: { entityId, testSessionId: session.id }
            }
          ]
        });
        notification.add(data.addPersonsToTestSession.id, {
          message: intl.formatMessage(
            messages.messageAthletesAddedToTestSession
          )
        });
      } catch (e) {
        Sentry.captureException(e);
      }
      if (onDone) onDone();
    }
  };

  const onCloseHandler = () => {
    clearSelected();
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.titleSessionAssignUsers} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal bodyWithTabs>
        <PersonsTabs
          onModal
          entityId={entityId}
          excludedPersons={excludedPersons}
          showPersonStatus={false}
          checkableGroups={false}
        />
      </CardBody>
      <CardFooter modal extraClassNames="c-card__footer--modal-larger">
        <ButtonsGroup>
          <Button secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            type="button"
            primary
            disabled={selectedPersons.length === 0}
            onClick={() => onAddPersonsHandler()}
          >
            <FormattedMessage
              {...messages.addSelectedPersonsButton}
              values={{ count: selectedPersons.length }}
            />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
}

export default AssignPersonsToSessionModal;
