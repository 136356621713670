import { useContext, useEffect, useRef } from 'react';
import { StoreContext } from 'index';
import { FormattedMessage, useIntl } from 'react-intl';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderText
} from 'components/card/card-header';
import Select, { Option } from 'components/input/Select';
import Cues from 'containers/partials/inputs/Cues';
import { Editor } from '@tinymce/tinymce-react';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useFormikContext } from 'formik';
import FieldInput from 'components/input/FieldInput';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { Field } from 'formik';
import FileUppyWrapper from 'containers/partials/file-upload/FileUppyWrapper';
import { FILE_TYPES } from 'constants.js';
import ThumbList from 'containers/partials/media/ThumbList';
import File from 'models/File';
import { sortByArray } from 'utils/sort';
import { useParams } from 'react-router-dom';
import TagMultiSelect from 'containers/partials/inputs/TagMultiSelect';

const ExerciseFormInput = ({
  exercise,
  edit,
  readOnly,
  isAdd,
  setEdit,
  onCancel,
  entityId
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { exerciseId } = useParams();
  const { values, errors, touched, submitForm, setFieldValue, resetForm } =
    useFormikContext();
  const exerciseMedia = useRef([]);

  const intl = useIntl();

  useEffect(() => {
    if (exercise?.id) {
      exerciseMedia.current = exercise.media;
    }
  }, [exercise?.id]);

  const onFilesUploaded = async (files, existingFiles) => {
    if (files.length === 0) return;

    if (files.length > 0) {
      const newMedia = files.map(f => {
        if (f.modelName === 'File') {
          return f;
        } else {
          return new File({ ...f, status: 'waiting', type: '' });
        }
      });
      // merge newMedia and existingMedia without duplicates
      const mergedArray = [...existingFiles, ...newMedia].reduce(
        (acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        },
        []
      );

      setFieldValue('media', mergedArray);
      setFieldValue('meta', {
        order: mergedArray.map(m => m.id)
      });
      exerciseMedia.current = mergedArray;
    }
  };

  const onFileDeleted = async fileId => {
    const media = exerciseMedia.current.filter(m => m.id !== fileId);
    exerciseMedia.current = media;
    setFieldValue('meta', {
      order: media.map(m => m.id)
    });
  };

  const onSortHandler = ids => {
    setFieldValue('meta', {
      order: [...ids]
    });
  };

  return (
    <Card secondary multiBody>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardTitleExercise} />
          </CardHeaderTitle>
        </CardHeaderText>
        {!readOnly && (values.editable || isAdd) && (
          <FormEditHeaderButtons
            edit={edit}
            setEdit={setEdit}
            onSubmit={submitForm}
            onCancel={() => {
              resetForm();
              if (onCancel) onCancel();
            }}
          />
        )}
      </CardHeader>
      <CardBody secondary separatorBottom>
        <div className="o-layout o-layout--small">
          {user.isGSVOrg ? (
            <div className="o-layout__item u-3-of-3">
              {/* EXERCISE TITLE */}
              <FieldInput id="title" name="title" readOnly={readOnly}>
                <FormattedMessage {...messages.exercisesLabelTitle} />*
              </FieldInput>
            </div>
          ) : (
            <>
              <div className="o-layout__item u-2-of-3">
                {/* EXERCISE TITLE */}
                <FieldInput id="title" name="title" readOnly={readOnly}>
                  <FormattedMessage {...messages.exercisesLabelTitle} />*
                </FieldInput>
              </div>
              <div className="o-layout__item u-1-of-3">
                {/* LEVEL */}
                {/* TODO level field is deprecated */}
                <Select
                  extraClassNames="u-growth-left"
                  label={`${intl.formatMessage(messages.exercisesLabelLevel)}*`}
                  name="level"
                  left
                  readOnly={!edit}
                  value={values.level}
                  onChange={option => {
                    setFieldValue('level', option.value);
                  }}
                >
                  {[1, 2, 3, 4, 5].map(lvl => (
                    <Option
                      key={`level_${lvl}`}
                      value={lvl}
                      label={`Level ${lvl}`}
                    />
                  ))}
                </Select>
              </div>
            </>
          )}
        </div>
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.cardExercisePracticalTitle} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary separatorBottom>
        {/* Notes / INTRO */}
        <FieldTextarea
          id="note"
          name="note"
          readOnly={!edit}
          errors={errors}
          touched={touched}
          resizeVertical
        >
          <FormattedMessage {...messages.exercisesLabelNotes} />
        </FieldTextarea>

        {/* Remarks */}
        <FieldTextarea
          id="remarks"
          name="remarks"
          readOnly={!edit}
          errors={errors}
          touched={touched}
          resizeVertical
        >
          <FormattedMessage {...messages.exercisesLabelRemarks} />
        </FieldTextarea>

        <Cues
          entityId={entityId}
          label={`${intl.formatMessage(messages.exercisesLabelCues)}`}
          cues={values.cues}
          readOnly={!edit}
          onChange={newCues => setFieldValue('cues', newCues)}
        />
      </CardBody>

      <CardHeader secondary extraClassNames="u-margin-bottom-none">
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelFotoAndVideo} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody
        secondary
        separatorBottom
        extraClassNames="u-padding-bottom-medium"
      >
        {/* MEDIA */}
        <div className={'c-file-upload--state c-file-upload--state-small'}>
          <div className="c-media">
            {!isAdd ? (
              <FileUppyWrapper
                key={exerciseId}
                entityId={entityId}
                linkId={exerciseId}
                type={'exercise'}
                accept={[...FILE_TYPES.VIDEO, ...FILE_TYPES.IMAGE]}
                onFilesUploaded={files =>
                  onFilesUploaded(files, exerciseMedia.current)
                }
                numFiles={exerciseMedia.current.length}
                isPublic
              >
                <ThumbList
                  entityId={entityId}
                  media={
                    values.meta?.order
                      ? sortByArray(
                          [...exerciseMedia.current],
                          values.meta.order
                        )
                      : [...exerciseMedia.current]
                  }
                  onSort={onSortHandler}
                  onFileDeleted={file => onFileDeleted(file)}
                  editable={true}
                />
              </FileUppyWrapper>
            ) : (
              <p>
                <FormattedMessage {...messages.exercisesWarningFotoAndVideo} />
              </p>
            )}
          </div>
        </div>
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelProtocol} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody
        secondary
        separatorBottom
        extraClassNames="u-padding-bottom-large"
      >
        {/* PROTOCOL */}
        <Field
          as={Editor}
          name="protocol"
          init={{
            branding: false,
            height: 300,
            menubar: false,
            plugins: [
              'advlist autolink lists link image',
              'charmap print preview anchor help',
              'searchreplace visualblocks code',
              'insertdatetime media table paste'
            ],
            toolbar:
              'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
          }}
          onEditorChange={(content, editor) => {
            setFieldValue('protocol', editor.getContent());
          }}
        />
      </CardBody>

      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.exercisesLabelTags} />
          </CardHeaderTitle>
        </CardHeaderText>
      </CardHeader>
      <CardBody secondary>
        {/* TAGS */}
        <TagMultiSelect
          entityId={entityId}
          onChange={tags => {
            setFieldValue(
              'tagIds',
              tags.map(t => t.id)
            );
          }}
          selectedTags={values.tags}
        />
      </CardBody>
      {!readOnly && (values.editable || isAdd) && (
        <FormEditFooterButtons
          edit={edit}
          setEdit={setEdit}
          onCancel={() => {
            resetForm();
            if (onCancel) onCancel();
          }}
          onSubmit={submitForm}
          topMargin
        />
      )}
    </Card>
  );
};

export default ExerciseFormInput;
