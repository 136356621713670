import React from 'react';
import ConfirmMutationButton from 'containers/partials/buttons/ConfirmMutationButton';
import { MUTATION_ADD_MIRROR } from 'services/aws/mirror-query';
import messages from 'messages';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import usePerson from 'hooks/queries/usePerson';

const SendMirrorInviteButton = ({
  entityId,
  title,
  person,
  disabled,
  extraClassNames,
  children
}) => {
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { fetchPerson } = usePerson({ personId: person.id });

  return (
    <ConfirmMutationButton
      title={title}
      small
      light
      disabled={disabled}
      extraClassNames={extraClassNames}
      mutation={MUTATION_ADD_MIRROR}
      variables={{ personId: person.id, entityId }}
      confirmTitle={messages.sendMirrorInviteTitle}
      confirmMessage={messages.sendMirrorInviteMessage}
      confirmAcceptLabel={messages.sendButton}
      update={async (cache, { data: { addMirrorIntegration } }) => {
        if (addMirrorIntegration) {
          await fetchPerson(person.id);
          notification.add(`mirrorInvite_${person.id}`, {
            message: intl.formatMessage(messages.messageMirrorInviteSent)
          });
        }
      }}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default SendMirrorInviteButton;
