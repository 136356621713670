import { CardHeaderButtons } from 'components/card/card-header';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';

function FormEditHeaderButtons({
  edit,
  setEdit,
  editable = true,
  disabled = false,
  disabledSubmit = false,
  onCancel,
  onSubmit,
  save = true,
  cancel = true,
  type = 'button',
  saveLabel = 'saveButton',
  cancelLabel = 'cancelButton',
  children = null,
  extraClassNames
}) {
  if (!editable) return null;

  return (
    <CardHeaderButtons extraClassNames={extraClassNames}>
      {children}
      {edit && (
        <>
          {cancel && (
            <Button
              tiny
              secondary
              disabled={disabled}
              onClick={() => {
                if (onCancel) onCancel();
                if (setEdit) setEdit(false);
              }}
            >
              <FormattedMessage {...messages[`${cancelLabel}`]} />
            </Button>
          )}
          {save && (
            <Button
              tiny
              type={type}
              onClick={() => {
                if (onSubmit) onSubmit();
              }}
              disabled={disabledSubmit || disabled}
            >
              <FormattedMessage {...messages[`${saveLabel}`]} />
            </Button>
          )}
        </>
      )}
      {!edit && (
        <Button
          secondary
          tiny
          onClick={() => {
            if (setEdit) setEdit(true);
          }}
        >
          <Icon id="edit" />
        </Button>
      )}
    </CardHeaderButtons>
  );
}

export default FormEditHeaderButtons;
