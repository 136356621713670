import React from 'react';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { createIntl, FormattedMessage } from 'react-intl';
import slugify from 'slugify';

const GenerateMagicLinkButton = ({ onClick, children, person, ...props }) => {
  const enIntl = createIntl({ locale: 'en' });
  if (!person.email && !person.athleteEmail)
    return (
      <Button {...props} disabled>
        <Icon id="link" />
        <FormattedMessage {...messages.generateMagicLinkButtonDisabled} />
      </Button>
    );
  return (
    <Button
      {...props}
      onClick={onClick}
      dataQa={`${slugify(enIntl.formatMessage(messages.generateMagicLinkButton).toLowerCase())}-menu`}
    >
      <Icon id="link" />
      <FormattedMessage {...messages.generateMagicLinkButton} />
    </Button>
  );
};

export default GenerateMagicLinkButton;
