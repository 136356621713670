import { forwardRef } from 'react';
import classNames from 'classnames';

const PanelBody = forwardRef((props, ref) => {
  const {
    extraClassNames,
    home,
    scrollHorizontal,
    fullWidth,
    withFixedButtonsBottom,
    style,
    children,
    refactor,
    ...rest
  } = props;
  return (
    <div
      ref={ref}
      className={classNames('c-panel__body', extraClassNames, {
        'c-panel__body--refactor': refactor,
        'c-panel__body--home': home,
        'c-panel__body--home c-panel__body--horizontal': scrollHorizontal,
        'c-panel__body--full-width': fullWidth,
        'c-panel__body--buttons-fixed-bottom': withFixedButtonsBottom
      })}
      style={style}
      {...rest}
    >
      {children}
    </div>
  );
});

export default PanelBody;
