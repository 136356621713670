import React, { useContext } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import {
  MUTATION_ADD_EXERCISE,
  MUTATION_ADDEDIT_TRANSLATION_EXERCISE,
  MUTATION_EDIT_EXERCISE
} from 'services/aws/exercises-query';
import messages from 'messages.js';
import { useStore } from 'stores/Store';

function ExerciseForm({
  entityId,
  exercise,
  children,
  extraClassNames,
  onComplete
}) {
  const {
    authStore: {
      user,
      user: { baseLanguage }
    }
  } = useContext(StoreContext);
  const notification = useNotificationQueue();
  const intl = useIntl();
  const addExercise = useStore(state => state.addExercise);
  const updateExercise = useStore(state => state.updateExercise);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    level:
      !user.isGSVOrg &&
      Yup.number().required(intl.formatMessage(messages.levelRequired))
  });

  const [editExercise] = useMutation(MUTATION_EDIT_EXERCISE);
  const [editCopyExercise] = useMutation(MUTATION_ADDEDIT_TRANSLATION_EXERCISE);
  const [createExercise] = useMutation(MUTATION_ADD_EXERCISE);

  const onSubmitHandler = async exercise => {
    const exerciseObj = {
      ...exercise,
      entityId,
      level: parseInt(exercise.level),
      meta: JSON.stringify(exercise.meta)
    };

    const exerciseCopy = {
      language: baseLanguage,
      title: exercise.title,
      note: exercise.note,
      cues: exercise.cues,
      protocol: exercise.protocol,
      remarks: exercise.remarks
    };

    if (!exercise.id) {
      const addExerciseData = await createExercise({
        variables: { ...exerciseObj }
      });
      const exerciseId = addExerciseData.data.addExercise.id;
      addExercise(exerciseObj);
      notification.add(exerciseId, {
        message: intl.formatMessage(messages.messageAddExerciseSuccess)
      });
      onComplete && onComplete(exerciseId);
    } else {
      const editExerciseData = await editExercise({
        variables: { ...exerciseObj }
      });
      const exerciseId = editExerciseData.data.editExercise.id;

      updateExercise(exerciseId, exerciseObj);

      await editCopyExercise({
        variables: { ...exerciseCopy, exerciseId }
      });
      notification.add(exerciseId, {
        message: intl.formatMessage(messages.messageEditExerciseSuccess)
      });
      onComplete && onComplete(exerciseId);
    }
  };

  return (
    <Formik
      initialValues={exercise}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ExerciseForm;
