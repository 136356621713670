import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useEdit from 'hooks/utils/useEdit';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT } from 'routes/RouteList';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { CardBody } from 'components/card';
import CardFooter from 'components/card/CardFooter';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import FieldInput from 'components/input/FieldInput';
import { FieldTextarea } from 'components/input/FieldTextarea';
import TrainingForm from 'containers/pages/exercises/trainings/TrainingForm';

const TrainingDetailsTab = ({ training }) => {
  const intl = useIntl();
  const { entityId } = useParams();

  const [edit, setEdit] = useEdit(
    !!useRouteMatch([ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT])
  );

  return (
    <TrainingForm
      entityId={entityId}
      training={{ ...training }}
      extraClassNames={'c-card c-card--secondary'}
      onComplete={() => setEdit(false)}
    >
      {({ errors, touched, resetForm, submitForm, isValid, isSubmitting }) => {
        return (
          <>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.collectionDetailTitle} />
              </CardHeaderTitle>
              <FormEditHeaderButtons
                edit={edit}
                setEdit={setEdit}
                onCancel={resetForm}
                onSubmit={submitForm}
                disabledSubmit={!isValid || isSubmitting}
              />
            </CardHeader>
            <CardBody secondary>
              <FieldInput
                id="title"
                name="title"
                placeholder={
                  edit
                    ? intl.formatMessage(messages.trainingNamePlaceholder)
                    : ''
                }
                errors={errors}
                touched={touched}
                required
                readOnly={!edit}
              >
                <FormattedMessage {...messages.trainingNameLabel} />
              </FieldInput>
              <FieldTextarea
                id="note"
                name="note"
                errors={errors}
                touched={touched}
                resizeVertical={edit}
                readOnly={!edit}
              >
                <FormattedMessage {...messages.trainingsDetailsNoteLabel} />
              </FieldTextarea>
            </CardBody>
            {edit && (
              <CardFooter secondary>
                <FormEditFooterButtons
                  edit={edit}
                  setEdit={edit => setEdit(edit)}
                  onCancel={() => {
                    resetForm();
                  }}
                  disabledSubmit={!isValid || isSubmitting}
                  onSubmit={submitForm}
                  type={'button'}
                />
              </CardFooter>
            )}
          </>
        );
      }}
    </TrainingForm>
  );
};

export default TrainingDetailsTab;
