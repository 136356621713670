import {
  ROUTE_SPORTERS,
  ROUTE_SESSIONS,
  ROUTE_EXERCISES,
  ROUTE_EXERCISES_TEMPLATES,
  ROUTE_EXERCISES_PROGRAMS,
  ROUTE_PREVENTION,
  ROUTE_SCANNERS,
  ROUTE_GROWTH_PREDICTION,
  ROUTE_USER_PROGRAMS,
  ROUTE_EXERCISES_TRAININGS,
  ROUTE_EXERCISES_COLLECTIONS,
  ROUTE_LESSONS,
  ROUTE_VIDEOS,
  ROUTE_RESULTS,
  ROUTE_TEST,
  ROUTE_USER_DOCUMENTS,
  ROUTE_HOME,
  ROUTE_GROWTH
} from 'routes/RouteList';

import { enGB, nlBE, fr } from 'date-fns/locale';
import messages from 'messages';
import { capitalize } from 'utils/string';

export const DEBUG = process.env.REACT_APP_ENV === 'local';
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

export const DEFAULT_LOCALE = 'en';

export const DATE_LOCALE = {
  en: enGB,
  nl: nlBE,
  fr: fr
};

export const NL_TRANSLATION_FILE =
  process.env.REACT_APP_NL_TRANSLATIONS ?? 'nl.json';

// None auth test user
export const GLOBAL_TESTER_LOGIN = process.env.REACT_APP_GLOBAL_TESTER_LOGIN;
export const GLOBAL_TESTER_PASSWORD =
  process.env.REACT_APP_GLOBAL_TESTER_PASSWORD;

// Reports, exports & files
export const FILES_URL = process.env.REACT_APP_FILES_URL;
export const EXPORT_FILES_URL = process.env.REACT_APP_EXPORT_FILES_URL;
export const IMPORT_SPORTERS_EXAMPLE_URL =
  FILES_URL + process.env.REACT_APP_FILES_IMPORT_SPORTERS_EXAMPLE_PATH;
export const IMPORT_BENCHMARK_EXAMPLE_URL =
  FILES_URL + process.env.REACT_APP_FILES_BENCHMARK_SPORTERS_EXAMPLE_PATH;
export const FILE_TYPES = Object.freeze({
  ALL: [],
  VIDEO: ['video/*', 'video/mp4', 'video/mov'],
  IMAGE: ['image/*'],
  DICOM: ['application/dicom'],
  SDAT: ['application/sdat'],
  PDF: ['application/x-pdf', 'application/pdf'],
  DOCUMENTS: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain'
  ]
});

// API endpoints
export const API_URL = process.env.REACT_APP_API_URL;
export const RESET_PASSWORD_URL =
  API_URL + process.env.REACT_APP_API_RESET_PASSWORD_PATH;
export const DECRYPT_TOKEN_URL =
  API_URL + process.env.REACT_APP_API_DECRYPT_TOKEN_PATH;

export const PersonEntityRoles = Object.freeze({
  ENTITY_ADD_USER: 'ENTITY_ADD_USER',
  ENTITY_REMOVE_USER: 'ENTITY_REMOVE_USER',
  ENTITY_CREATE_SUBENTITY: 'ENTITY_CREATE_SUBENTITY',
  ENTITY_EDIT_SUBENTITY: 'ENTITY_EDIT_SUBENTITY',
  ENTITY_ARCHIVE_SUBENTITY: 'ENTITY_ARCHIVE_SUBENTITY',
  ENTITY_READ_CLIENTS: 'ENTITY_READ_CLIENTS',
  PERSON_EDIT: 'PERSON_EDIT',
  PERSON_ADD_LOGIN: 'PERSON_ADD_LOGIN',
  // global roles
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_SUB_ADMIN: 'ROLE_SUBADMIN',
  ROLE_TESTER: 'ROLE_TESTER',
  ROLE_NONE: 'ROLE_NONE',
  ROLE_ATHLETE: 'ROLE_ATHLETE'
});

export const PersonStatus = Object.freeze({
  NONE: 'NONE',
  EMAIL: 'EMAIL',
  USER: 'USER',
  PENDING: 'PENDING',
  UNCONFIRMED: 'UNCONFIRMED'
});

export const PackageTypes = Object.freeze({
  ILIKE: 'ilike',
  IDO: 'ido',
  MTS: 'mts',
  SAP: 'sap',
  PREVENTION: 'prevention',
  REHAB: 'rehab'
});

export const PackageSubTypes = Object.freeze({
  HAN: 'han',
  BADMINTON: 'badminton',
  ILIKE: 'ilike',
  IDO: 'ido',
  SISP: 'sisp',
  MTS: 'mts',
  IAM: 'iam',
  DJAS: 'djas',
  KLUSCE: 'KLUSCE',
  SGW: 'sgw',
  VGB: 'vgb'
});

export const OrganisationTypes = Object.freeze({
  MTS: 'mts',
  HYLYGHT: 'hylyght',
  GSV: 'gsv',
  VITALE: 'vitale',
  HAN: 'han'
});

export const EntityTypes = Object.freeze({
  GROUP: 'group',
  ORGANISATION: 'entity'
});

export const LeftRight = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right'
});

export const CHART_COLORS = Object.freeze({
  GREEN: 'hsl(164, 100%, 38%)',
  BLUE: 'hsl(216, 86%, 59%)',
  YELLOW: 'hsl(54, 99%, 62%)',
  ORANGE: 'hsl(36, 100%, 50%)'
});
export const CHART_COLORS_BORDER = Object.freeze({
  GREEN: 'hsl(164, 80%, 23%)',
  BLUE: 'hsl(216, 66%, 44%)',
  YELLOW: 'hsl(54, 79%, 47%)',
  ORANGE: 'hsl(36, 80%, 35%)'
});

export const CHART_TYPE = Object.freeze({
  GROWTHTRACKER: 'growthtracker'
});

export const defaultConfig = {
  menu: [ROUTE_SESSIONS, ROUTE_SPORTERS, ROUTE_RESULTS], //, ROUTE_EXERCISES, ROUTE_GROWTH_PREDICTION],
  logo: process.env.REACT_APP_LOGO_URL,
  name: process.env.REACT_APP_TITLE
};

export const hylyghtConfig = {
  menu: [
    ROUTE_SESSIONS,
    ROUTE_SPORTERS,
    ROUTE_RESULTS,
    ROUTE_GROWTH_PREDICTION,
    ROUTE_GROWTH,
    ROUTE_EXERCISES
  ], // ,
  logo: process.env.REACT_APP_LOGO_URL,
  name: process.env.REACT_APP_TITLE
};

export const gsvConfig = {
  menu: [
    ROUTE_EXERCISES,
    ROUTE_EXERCISES_COLLECTIONS,
    ROUTE_EXERCISES_TRAININGS,
    ROUTE_SPORTERS
  ],
  logo: process.env.REACT_APP_LOGO_URL,
  name: process.env.REACT_APP_TITLE
};

export const vitaleConfig = {
  menu: [ROUTE_HOME, ROUTE_LESSONS, ROUTE_VIDEOS, ROUTE_SPORTERS]
};

export const BRAND_NAME = capitalize(process.env.REACT_APP_NAME);

export const BaseLanguage = Object.freeze({
  NL: 'nl',
  EN: 'en'
});

export const RegioCode = Object.freeze({
  BE: 'be',
  NL: 'nl',
  SG: 'sg'
});

export const DROP_STATE = Object.freeze({
  DEFAULT: 'default',
  DRAGGING: 'dragging',
  REJECT: 'reject',
  UPLOADING: 'uploading'
});

export const BenchmarkTypes = Object.freeze({
  DEFAULT: 'default',
  AVERAGE: 'average',
  STANDARD: 'standard',
  RANGE: 'range'
});

export const BenchmarkSubTypes = Object.freeze({
  STANDARD: 'stdv',
  RANGE: 'range'
});

export const BenchmarkFilters = Object.freeze({
  LAST: 'last',
  BEST: 'best',
  AVERAGE: 'average'
});

export const GrowthPredictionTypes = Object.freeze({
  DEFAULT: 'default',
  AVERAGE: 'average'
});

export const GrowthTrackerEstimationMethods = Object.freeze({
  KHAMIS_ROCHE: 'khamis_roche'
});

export const ShareTypes = Object.freeze({
  EXERCISE: 'exercise'
});

export const OTHER_VALUE = 'other-value';
export const NO_SPECIFIC = 'no-specific';

export const ContactPersonTypes = [
  { value: 'teacher', label: 'Teacher' },
  { value: 'sportservice', label: 'SportService' },
  { value: OTHER_VALUE, label: 'OtherValue' }
];

export const SettingsOrganisationTypes = [
  { value: 'country', label: 'Country' },
  { value: 'city', label: 'City' },
  { value: 'elementary', label: 'Elementary' },
  { value: 'high', label: 'High' },
  { value: 'college', label: 'College' },
  { value: OTHER_VALUE, label: 'OtherValue' }
];

export const NoteTypes = Object.freeze({
  TEMPLATE: 'exerciseTemplate',
  PROGRAM: 'exerciseProgram',
  FEEDBACK: 'exerciseFeedback',
  DONE: 'exerciseDone',
  TESTDATA: 'testdata',
  ANNOTATION: 'annotation'
});

export const SessionTypes = Object.freeze({
  SESSION: 'session',
  RECURRING: 'recurring',
  PREVENTION: 'prevention'
});

export const TestSetV2Type = Object.freeze({
  TALENT: 'talent',
  REHAB: 'rehab',
  PREVENTION: 'prevention',
  TALENT_TEMPLATE: 'talent_template',
  REHAB_TEMPLATE: 'rehab_template',
  PREVENTION_TEMPLATE: 'prevention_template',
  ANNOTATION: 'annotation'
});

export const RecurringInterval = Object.freeze({
  DAILY: 'daily',
  WEEKLY: 'weekly'
});

export const PreventionType = Object.freeze({
  BASIC: 'basic',
  FULL: 'full',
  CUSTOM: 'custom'
});

export const PersonInjuryType = Object.freeze({
  INJURY: 'injury',
  ILLNESS: 'illness',
  ISSUE: 'issue'
});

export const InjuryType = Object.freeze({
  ACUTE: 'acute',
  OVERUSE: 'overuse',
  OTHER: 'other'
});

export const InjuryTypeOptions = [
  {
    value: InjuryType.ACUTE,
    label: messages.rehabInjuryTypeAcuteOption
  },
  {
    value: InjuryType.OVERUSE,
    label: messages.rehabInjuryTypeOveruseOption
  },
  {
    value: InjuryType.OTHER,
    label: messages.rehabInjuryTypeOtherOption
  }
];

export const InjuryCause = Object.freeze({
  CONTACT: 'contact',
  NON_CONTACT: 'non-contact'
});

export const InjuryCauseOptions = [
  {
    value: InjuryCause.CONTACT,
    label: messages.rehabInjuryCauseContactOption
  },
  {
    value: InjuryCause.NON_CONTACT,
    label: messages.rehabInjuryCauseNonContactOption
  }
];

export const Lateralities = Object.freeze({
  0: 'left',
  1: 'right',
  2: 'unilateral'
});

export const Laterality = Object.freeze({
  LEFT: 0,
  RIGHT: 1,
  UNILATERAL: 2
});

export const AnnotationType = Object.freeze({
  EXERCISE: 'exercise_annotation'
});

export const TemplateType = Object.freeze({
  TEMPLATE: 'template',
  COLLECTION: 'collection',
  TRAINING: 'training'
});

export const SPORT_REHAB = process.env.REACT_APP_SPORT_ID_REHAB;

export const ProgressBarRange = [0, 10, 30, 70, 90];
export const ProgressBarRangeHan = [0, 20, 40, 60, 80];

export const VideoAnnotationStatus = Object.freeze({
  IN_PROGRESS: messages.videoAnnotationStatusInProgress,
  COMPLETED: messages.videoAnnotationStatusCompleted,
  SHARED: messages.videoAnnotationStatusShared
});

// NEVER CHANGE ORDER OF OBJECT
export const ProgressBarValueTypes = Object.freeze({
  RED: 'red',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  LIME: 'lime',
  GREEN: 'green',
  AVERAGE: 'average'
});

export const ColorNamesForGrowthValues = Object.freeze({
  RED: 'color-growth-red',
  ORANGE: 'color-growth-orange',
  YELLOW: 'color-growth-yellow',
  LIME: 'color-growth-lime',
  GREEN: 'color-growth-green'
});

export const idToNameTestItem = Object.freeze({
  '19688d86-a4e8-4cfb-966d-69bdf035f6ab': 'Body Height',
  'a2a243bc-8d3e-4eb8-9837-9a3bb814488d': 'Body Weight',
  '5737716d-7eb3-4724-85d2-7baa4652ae93': 'Adult Height Prediction', //'Adult Height Prediction (K-R)',
  'f7529cf9-ba05-4570-bedd-6213ee3b6ec8': 'Adult Height Prediction CI',
  'face72bd-d260-44a3-b55c-3bae9e3d18dc': '% Adult Height Prediction',
  '197bbf37-7db5-40c4-9675-0dec23339b8e': 'Growth Phase', //'Maturity Status Growth Phase',
  '0c58cad6-8a6f-402f-b30e-b00e06871f14': 'Maturation Timing', //'Development Maturation Timing',
  '105b2d29-1469-4d27-bdc0-9d9b6c44b915': 'Development Z-score', //'Development (K-R) Z-score',
  '5737716d-7eb3-4724-85d2-7baa4652XXXX': 'Bio Age', //'Bio Age (K-R)',
  '491e8645-9273-4ef4-be2d-5d09363ac088': 'Chrono vs Bio age',
  'b25b2bc3-f722-4eba-b754-a343a19496f4': 'Body Height Father',
  'f8a705cb-653b-46ea-be13-5a8e68386e34': 'Body Height Mother',
  '61a9f0f5-3f2d-4f3f-83ea-3d44186edf49': 'Jumping Sideways'
});

export const DefaultBenchmark = process.env.REACT_APP_DEFAULT_BENCHMARK;
export const DemoDataId = process.env.REACT_APP_DEMO_DATA_ENTITYID;

export const reportOptions = Object.freeze({
  showComments: {
    id: 'showComments',
    label: 'reportOptionShowComment',
    description: 'reportOptionShowCommentDesc',
    default: true,
    enabled: ['prevention', 'rehab']
  },
  hideEmpty: {
    id: 'hideEmpty',
    label: 'reportOptionHideEmpty',
    description: 'reportOptionHideEmptyDesc',
    default: false,
    enabled: ['prevention', 'rehab']
  },
  advancedOptions: {
    id: 'advancedOptions',
    label: 'reportOptionAdvancedOptions',
    description: 'reportOptionAdvancedOptionsDesc',
    default: false,
    enabled: ['prevention', 'rehab'],
    type: 'switch' // Check bij submit/form generate
  },
  showBothSides: {
    id: 'showBothSides',
    label: 'reportOptionShowBothSides',
    description: 'reportOptionShowBothSidesDesc',
    default: false,
    enabled: ['rehab'],
    type: 'advanced'
  },
  showUnBenchmarked: {
    // showUnbenchmarked
    id: 'showUnBenchmarked',
    label: 'reportOptionShowUnBenchmarked',
    description: 'reportOptionShowUnBenchmarkedDesc',
    title: 'reportOptionShowUnBenchmarkedHead',
    default: true,
    enabled: ['prevention', 'rehab'],
    type: 'advanced'
  },
  showPercentage: {
    // showScore
    id: 'showPercentage',
    label: 'reportOptionShowPercentage',
    description: 'reportOptionShowPercentageDesc',
    title: 'reportOptionShowPercentageHead',
    default: true,
    enabled: ['prevention', 'rehab'],
    type: 'advanced'
  },
  showScore: {
    // showRawScore
    id: 'showScore',
    label: 'reportOptionShowRawScore',
    description: 'reportOptionShowRawScoreDesc',
    default: false,
    enabled: ['prevention', 'rehab'],
    type: 'advanced'
  }

  //
});

export const FIXED_RESULT_COLS = 4;

export const drawerGroups = drawerType => {
  switch (drawerType) {
    case 'gsv':
      return [
        {
          items: [
            {
              route: ROUTE_EXERCISES,
              label: messages.drawerExercises,
              icon: 'exercise',
              ability: 'Exercises'
            },
            {
              route: ROUTE_EXERCISES_COLLECTIONS,
              label: messages.drawerCollections,
              icon: 'template',
              ability: 'Collections'
            },
            {
              route: ROUTE_EXERCISES_TRAININGS,
              label: messages.drawerTrainings,
              icon: 'program',
              ability: 'Trainings'
            }
          ]
        },
        {
          items: [
            {
              route: ROUTE_SPORTERS,
              label: messages.drawerSporters,
              icon: 'sporters',
              ability: 'Athletes'
            }
          ]
        }
      ];
    case 'vitale':
      return [
        {
          items: [
            {
              route: ROUTE_LESSONS,
              label: messages.drawerLessons,
              icon: 'lesson',
              ability: 'Lessons'
            },
            {
              route: ROUTE_VIDEOS,
              label: messages.drawerVideos,
              icon: 'video',
              ability: 'Videos'
            },
            {
              route: ROUTE_SPORTERS,
              label: messages.drawerSporters,
              icon: 'sporters',
              ability: 'Athletes'
            }
          ]
        }
      ];
    case 'athlete':
      return [
        {
          items: [
            {
              route: ROUTE_SESSIONS,
              subroutes: [],
              label: messages.drawerSessions,
              icon: 'sessions',
              ability: 'Sessions'
            },
            {
              route: ROUTE_USER_PROGRAMS,
              label: messages.drawerPrograms,
              icon: 'exercise',
              ability: 'Exercises'
            },
            {
              route: ROUTE_USER_DOCUMENTS,
              label: messages.drawerDocuments,
              icon: 'folder',
              ability: 'Documents'
            }
          ]
        }
      ];

    default:
      return [
        {
          items: [
            {
              route: ROUTE_SESSIONS,
              subroutes: [ROUTE_PREVENTION],
              label: messages.drawerSessions,
              icon: 'sessions',
              ability: 'Sessions'
            },
            {
              route: ROUTE_SPORTERS,
              label: messages.drawerSporters,
              icon: 'sporters',
              ability: 'Athletes'
            }
          ]
        },
        {
          items: [
            {
              route: ROUTE_RESULTS,
              label: messages.drawerBenchmarks,
              icon: 'graph',
              ability: 'Results'
            },
            {
              route: ROUTE_SCANNERS,
              label: messages.drawerScanners,
              icon: 'scanner',
              ability: 'Scanners'
            },
            {
              route: ROUTE_GROWTH_PREDICTION,
              label: messages.drawerGrowthPrediction,
              icon: 'growth',
              ability: 'Growthtracker'
            },
            {
              route: ROUTE_GROWTH,
              label: messages.drawerGrowthPrediction,
              icon: 'growth',
              ability: 'GrowthTracker_v2'
            }
          ]
        },
        {
          items: [
            {
              route: ROUTE_EXERCISES,
              subroutes: [ROUTE_EXERCISES_TEMPLATES, ROUTE_EXERCISES_PROGRAMS],
              label: messages.drawerExercises,
              icon: 'exercise',
              ability: 'Exercises'
            }
          ]
        },
        {
          items: [
            {
              route: ROUTE_TEST,
              label: messages.drawerTest,
              icon: 'test',
              ability: 'Test'
            }
          ]
        }
      ];
  }
};

export const athleteImportErrors = Object.freeze({
  no_valid_date: {
    problem: 'no_valid_date',
    message: messages.athleteImportErrorNoValidDate,
    type: 'error'
  },

  no_gender: {
    problem: 'no_gender',
    message: messages.athleteImportErrorNoGender,
    type: 'error'
  },

  no_firstname: {
    problem: 'no_firstname',
    message: messages.athleteImportErrorNoFirstname,
    type: 'error'
  },

  no_lastname: {
    problem: 'no_lastname',
    message: messages.athleteImportErrorNoLastname,
    type: 'error'
  },

  uid_already_in_import: {
    problem: 'uid_already_in_import',
    message: messages.athleteImportErrorUIDAlreadyInImport,
    type: 'error'
  },

  person_already_in_import: {
    problem: 'person_already_in_import',
    message: messages.athleteImportErrorPersonAlreadyInImport,
    type: 'error'
  },

  person_already_in_import_different_uid: {
    problem: 'person_already_in_import_different_uid',
    message: messages.athleteImportErrorPersonAlreadyInImportDifferentUID,
    type: 'warning'
  },

  uid_already_organisation: {
    problem: 'uid_already_organisation',
    message: messages.athleteImportErrorUIDAlreadyOrganisation,
    type: 'error'
  },

  uid_already_organisation_same_person: {
    problem: 'uid_already_organisation_same_person',
    message: messages.athleteImportErrorUIDAlreadyOrganisationSamePerson,
    type: 'error'
  },

  person_already_organisation: {
    problem: 'person_already_organisation',
    message: messages.athleteImportErrorPersonAlreadyOrganisation,
    type: 'warning'
  },

  person_already_organisation_with_other_uid: {
    problem: 'person_already_organisation_with_other_uid',
    message: messages.athleteImportErrorPersonAlreadyOrganisationWithOtherUID,
    type: 'warning'
  }
});

export const activeCountries = ['BE', 'NL', 'FR', 'ES', 'UK'];

export const features = Object.freeze({
  sessions: {
    label: messages.sessions,
    description: messages.sessionsDescription
  },
  prevention: {
    label: messages.prevention,
    description: messages.preventionDescription
  },
  rehab: {
    label: messages.rehab,
    description: messages.rehabDescription
  },
  recurringSessions: {
    label: messages.recurringSessions,
    description: messages.recurringSessionsDescription
  },
  athletes: {
    label: messages.athletes,
    description: messages.athletesDescription
  },
  exercises: {
    label: messages.exercises,
    description: messages.exercisesDescription
  },
  results: {
    label: messages.results,
    description: messages.resultsDescription
  },
  growthtracker: {
    label: messages.growthtracker,
    description: messages.growthtrackerDescription
  },
  growthTracker_v2: {
    label: messages.growthtracker_v2,
    description: messages.growthtracker_v2Description
  },
  benchmarks: {
    label: messages.benchmarks,
    description: messages.benchmarksDescription
  },
  testsets: {
    label: messages.testsets,
    description: messages.testsetsDescription
  },
  video: {
    label: messages.video,
    description: messages.videoDescription
  },
  personInvite: {
    label: messages.personInvite,
    description: messages.personInviteDescription
  },
  documents: {
    label: messages.documents,
    description: messages.documentsDescription
  },
  users: {
    label: messages.users,
    description: messages.usersDescription
  },
  organisations: {
    label: messages.organisations,
    description: messages.organisationsDescription
  },
  mirrorIntegration: {
    label: messages.mirrorIntegration,
    description: messages.mirrorIntegrationDescription
  }
});
