import { FormattedMessage, useIntl } from 'react-intl';
import Tags from 'containers/partials/inputs/Tags';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import classNames from 'classnames';
import messages from 'messages';

const TagsWrapperReadOnly = props => {
  const {
    filter,
    handleOnClick,
    handleOnChange,
    entityId,
    extraClassNamesWrapper,
    selectClearable = false,
    selectMenuPlacement = 'auto'
  } = props;
  const intl = useIntl();

  return (
    <div
      className={classNames('u-1-of-1 c-tags__wrapper', extraClassNamesWrapper)}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8
      }}
    >
      {filter?.myOrg && (
        <div
          style={{
            flex: '0 0 auto',
            alignSelf: 'flex-start',
            height: 24,
            background: '#3c84f0',
            margin: '2px 2px 2px 0',
            padding: '0 4px 0 8px',
            color: 'white',
            borderRadius: 32,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <FormattedMessage {...messages.exercisesTagMyOrg} />
          <Button
            extraClassNames={'c-button--filter-close'}
            mini
            style={{
              marginLeft: 2,
              padding: 5,
              borderRadius: '100%'
            }}
            onClick={handleOnClick}
          >
            <Icon
              id="close"
              strokeColor="color-neutral-x-light"
              style={{
                width: 12,
                height: 12
              }}
            />
          </Button>
        </div>
      )}
      {filter?.tags?.length > 0 && (
        <Tags
          inline
          readOnly
          extraClassNames="u-margin-bottom-none"
          entityId={entityId}
          label={intl.formatMessage(messages.exercisesLabelTags)}
          selectedTags={filter.tags}
          isMulti
          isClearable={selectClearable}
          dropdownDisabled
          onChange={handleOnChange}
          noOptionsMessage={intl.formatMessage(
            messages.exercisesProgramTagsNoOption
          )}
          rounded
          menuPlacement={selectMenuPlacement}
        />
      )}
    </div>
  );
};

export default TagsWrapperReadOnly;
