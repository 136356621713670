import { Fragment, useState } from 'react';
import { createIntl, FormattedMessage, useIntl } from 'react-intl';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import {
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SESSIONS_ENTITY_SESSION_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
  ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import Badge from 'components/badge/Badge';
import PanelBody from 'components/panel/PanelBody';

import { TestSetV2Type } from 'constants.js';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import EditSessionCard from 'containers/partials/cards/EditSessionCard';
import { useSessionContext } from 'contexts/SessionContext';
import SessionForm from 'containers/pages/prevention/SessionForm';
import Loader from 'components/loader/Loader';
import { TestsProvider } from 'contexts/TestsContext';
import messages from 'messages';
import useTabIndex from 'hooks/utils/useTabIndex';
import TabLink from 'components/tabs/TabLink';
import AddPreventionModal from 'containers/pages/prevention/AddPreventionModal';
import useEdit from 'hooks/utils/useEdit';
import SessionHeader from 'containers/pages/sessions/SessionHeader';
import useSelectable from 'hooks/utils/useSelectable';
import SessionDetailTab from 'containers/pages/sessions/SessionDetailTab';
import { getLocalDateFormat } from 'utils/date';
import SessionPersonsTab from 'containers/pages/sessions/SessionPersonsTab';
import slugify from 'slugify';

const Session = () => {
  const intl = useIntl();
  const enIntl = createIntl({ locale: 'en' });
  const { session, sessionLoading, error } = useSessionContext();
  const { selected, setSelected } = useSelectable();
  const { entityId, sessionsTab, sessionId } = useParams();
  const { push } = useHistory();
  const [edit, setEdit] = useEdit(
    !!useRouteMatch([ROUTE_SESSIONS_ENTITY_SESSION_EDIT]),
    true
  );
  const [copySessionModal, setCopySessionModal] = useState(false);
  const [tabIndex, setTabIndex] = useTabIndex([
    [ROUTE_SESSIONS_ENTITY_SESSION, ROUTE_SESSIONS_ENTITY_SESSION_EDIT],
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
    ]
  ]);

  const active = useRouteMatch([
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
    ROUTE_SESSIONS_ENTITY_SESSION
  ]);

  if (sessionLoading) {
    return (
      <Panel active={active.isExact}>
        <Loader />
      </Panel>
    );
  }

  return (
    <Fragment>
      <Panel active={active.isExact}>
        {error && <RetryPanel />}
        {session && (
          <div style={{ background: 'inherit' }}>
            <SessionHeader
              tabIndex={tabIndex}
              selectedSporters={selected}
              title={session.name}
              isExpired={session.isRecurring && session.enddate < new Date()}
              subtitle={
                session.isRecurring
                  ? intl.formatMessage(messages.recurringSessionSubtitle, {
                      type: session.interval,
                      startdate: getLocalDateFormat(session.startdate),
                      enddate: getLocalDateFormat(session.enddate)
                    })
                  : getLocalDateFormat(session.startdate)
              }
              onSessionCopy={session => setCopySessionModal(session)}
              onClearSelected={() => setSelected([])}
            />
            <PanelBody>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList>
                  <TabLink
                    dataQa={`session-${slugify(enIntl.formatMessage(messages.tabSessionDetail).toLowerCase())}`}
                    to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
                      sessionId,
                      sessionsTab,
                      entityId
                    })}
                  >
                    <FormattedMessage {...messages.tabSessionDetail} />
                  </TabLink>
                  <TabLink
                    dataQa={`session-${slugify(enIntl.formatMessage(messages.tabSessionSporters).toLowerCase())}`}
                    to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                      sessionId,
                      sessionsTab,
                      entityId
                    })}
                  >
                    <Badge>{session.personEntities.length}</Badge>
                    <FormattedMessage {...messages.tabSessionSporters} />
                  </TabLink>
                </TabList>
                <TabPanel>
                  <TabPanelBody>
                    {session.type === TestSetV2Type.PREVENTION ||
                    session.isRecurring ? (
                      <TestsProvider entityId={entityId}>
                        <SessionForm
                          entityId={entityId}
                          session={session}
                          onComplete={() => setEdit(false)}
                        >
                          <SessionDetailTab
                            entityId={entityId}
                            sessionId={sessionId}
                            session={session}
                            edit={edit}
                          />
                        </SessionForm>
                      </TestsProvider>
                    ) : (
                      <SessionForm
                        entityId={entityId}
                        session={session}
                        onComplete={() => setEdit(false)}
                      >
                        {/* Old Talent Session View, needs refactoring to the newer */}
                        <EditSessionCard
                          entityId={entityId}
                          sessionId={sessionId}
                          session={session}
                          edit={edit}
                        />
                      </SessionForm>
                    )}
                  </TabPanelBody>
                </TabPanel>
                <TabPanel>
                  <TabPanelBody>
                    <SessionPersonsTab
                      entityId={entityId}
                      sessionId={sessionId}
                      onSelectPersons={persons => setSelected(persons)}
                    />
                  </TabPanelBody>
                </TabPanel>
              </Tabs>
            </PanelBody>
          </div>
        )}
      </Panel>
      {copySessionModal && (
        <AddPreventionModal
          entityId={entityId}
          session={copySessionModal}
          isTemplate={false}
          onClose={() => {
            setCopySessionModal(false);
          }}
          onComplete={sessionId => {
            push(
              generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
                entityId,
                sessionId
              })
            );
          }}
        />
      )}
    </Fragment>
  );
};

export default Session;
