import { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import CardBodyTools from 'components/card/CardBodyTools';
import { Button, ButtonsGroup } from 'components/button';
import messages from 'messages';
import Loader from 'components/loader/Loader';
import Icon from 'components/icon/Icon';
import Tags from 'containers/partials/inputs/Tags';
import ExerciseViewCard from 'containers/pages/exercises/exercises/ExerciseViewCard';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import classNames from 'classnames';
import FieldInput from 'components/input/FieldInput';
import { useStore } from 'stores/Store';
import useSelectableObject from 'hooks/utils/useSelectableObject';
import TagMultiSelect from 'containers/partials/inputs/TagMultiSelect';
import { useDebounce } from '@uidotdev/usehooks';
import { useFormikContext } from 'formik';
import ExercisesFetcher from 'containers/pages/exercises/exercises/ExercisesFetcher';
import useExercise from 'hooks/queries/useExercise';
import Checkbox from 'components/input/Checkbox';
import { useUIDSeed } from 'react-uid';
import TagsWrapperReadOnly from 'containers/partials/inputs/TagsWrapperRemoveOnly';

const AddExercisesModal = ({
  entityId,
  template,
  onAdded,
  onClose,
  filterByOrg = true
}) => {
  const intl = useIntl();
  const { selected, select, setSelected } = useSelectableObject([]);
  const exercises = useStore(state => state.exercises);
  const { fetchExercise } = useExercise(entityId);
  const loading = useStore(state => state.loadingExercises);
  const [exercise, setExercise] = useState(null);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const setExercisesFilter = useStore(state => state.setExercisesFilter);
  const [filter, setFilter] = useState({ tags: [], myOrg: false });
  const [filtering, setFiltering] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);

  const [preview, setPreview] = useState(false);
  const [exerciseId, setExerciseId] = useState(false);
  const uidSeed = useUIDSeed();

  const { submitForm, setFieldValue } = useFormikContext();

  useEffect(() => {
    setExercisesFilter({
      search: debouncedSearch,
      tags: filter?.tags || [],
      myOrg: filter?.myOrg || false
    });
  }, [debouncedSearch, filter?.tags, filter?.myOrg]);

  useEffect(() => {
    const getExercise = async () => {
      const exercise = await fetchExercise(exerciseId);
      setExercise(exercise);
    };
    if (exerciseId) {
      getExercise();
    }
  }, [exerciseId]);

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const onTagChangeHandler = tags => {
    setFilterChanged(true);
    setFilter({ ...filter, tags });
  };

  const handleTagChange = tags => {
    setFilter({ ...filter, tags });
  };

  const onSubmitFilter = () => {
    setFiltering(false);
    setFilterChanged(false);
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onAddHandler = async () => {
    const exercises = [...template.exercises, ...selected];
    // remove duplicates
    const uniqueExercises = exercises.filter(
      (e, i) => exercises.findIndex(e2 => e2.id === e.id) === i
    );
    await setFieldValue('exerciseIds', [...uniqueExercises.map(e => e.id)]);
    await submitForm();

    if (onAdded) onAdded();
  };

  const handleRemoveMyOrg = () => {
    setExercisesFilter({
      ...filter,
      myOrg: false
    });
    setFilter({ ...filter, myOrg: false });
  };

  return (
    <Modal
      card
      isOpen
      onClose={onCloseHandler}
      extraClassNames="c-modal__content-bottom-auto"
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.addExtraExercisesModalTitle} />
          {filtering && (
            <CardHeaderSubtitle>
              <FormattedMessage
                {...messages.collectionsAddExerciseFilterInModalMessage}
              />
            </CardHeaderSubtitle>
          )}
        </CardHeaderTitle>
      </CardHeader>

      <></>

      <ExercisesFetcher entityId={entityId}>
        {({ ...fetcherProps }) => {
          /* Bij filtering is de Body van de modal anders */
          if (filtering) {
            return (
              <CardBody modal bodyWithHeader>
                <div className="c-card-modal__body-head c-card-modal__body-head--small">
                  <Button
                    rounded
                    light
                    onClick={() => {
                      setFiltering(false);
                      setFilterChanged(false);
                    }}
                  >
                    <Icon id="back-nav" mediumIcon />

                    <FormattedMessage {...messages.buttonBack} />
                  </Button>

                  <ButtonsGroup extraClassNames="o-flex--justify-end">
                    <Button
                      secondary
                      onClick={() => {
                        setFiltering(false);
                        setFilterChanged(false);
                        setSelected([]);
                      }}
                    >
                      <FormattedMessage {...messages.cancelButton} />
                    </Button>

                    <Button
                      primary
                      disabled={!filterChanged}
                      onClick={onSubmitFilter}
                    >
                      <FormattedMessage {...messages.labelApplyFilter} />
                    </Button>
                  </ButtonsGroup>
                </div>
                <div style={{ padding: '16px 16px 24px 24px' }}>
                  {filterByOrg && (
                    <div className="c-card__body-wrapper">
                      <fieldset className="c-input-group u-padding-top-none u-padding-bottom-medium">
                        <Checkbox
                          id={uidSeed('checkbox-only_my_org')}
                          name={`checkbox-only_my_org`}
                          secondary
                          checked={filter.myOrg}
                          onChange={e => {
                            setFilterChanged(true);
                            setFilter({ ...filter, myOrg: e.target.checked });
                          }}
                        >
                          <FormattedMessage
                            {...messages.exercisesFilterLabelMyOrg}
                          />
                        </Checkbox>
                      </fieldset>
                    </div>
                  )}
                  <TagMultiSelect
                    entityId={entityId}
                    onChange={onTagChangeHandler}
                    selectedTags={filter?.tags || []}
                    menuPlacement="bottom"
                  />
                </div>
              </CardBody>
            );
          }

          if (preview) {
            return (
              <CardBody modal bodyWithHeader>
                <div className="c-card-modal__body-head">
                  <div className="c-card-modal__body-head-breadcrumb">
                    <Button
                      rounded
                      light
                      onClick={() => {
                        setPreview(false);
                        setExercise(null);
                      }}
                    >
                      <Icon id="back-nav" mediumIcon />
                      <FormattedMessage {...messages.buttonBack} />
                    </Button>
                    {exercise?.title && (
                      <>
                        <Icon id="breadcrumb" fillColor="color-neutral-steel" />
                        <span className="c-organisation">{exercise.title}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="c-card-modal__body-content c-card-modal__body-content-exercise">
                  {loading ? (
                    <Loader />
                  ) : (
                    exercise?.id && (
                      <>
                        <CardHeader
                          extraClassNames="u-margin-bottom-none"
                          secondary
                        >
                          <CardHeaderText>
                            <CardHeaderTitle>
                              <FormattedMessage
                                {...messages.exercisesLabelTags}
                              />
                            </CardHeaderTitle>
                          </CardHeaderText>
                        </CardHeader>
                        {exercise.tags && exercise.tags.length > 0 && (
                          <Tags
                            entityId={entityId}
                            placeholder={``}
                            selectedTags={exercise.tags}
                            isMulti
                            isClearable={false}
                            edit={false}
                          />
                        )}
                        <ExerciseViewCard
                          entityId={entityId}
                          exercise={{ ...exercise }}
                          setEdit={false}
                          editable={false}
                        />
                      </>
                    )
                  )}
                </div>
              </CardBody>
            );
          }

          // Als filtering is off, worden de (gefiltreerde) list items getoond
          if (!filtering && !preview) {
            return (
              <CardBody modal bodyWithHeader>
                {loading && <Loader />}
                <div
                  className={classNames('c-card-modal__body-head', {
                    'u-padding-bottom-none':
                      filter.tags?.length > 0 || filter?.myOrg
                  })}
                >
                  <CardBodyTools extraClassNames="c-panel__tools-separated u-padding-none">
                    <FieldInput
                      id="title"
                      value={search}
                      rounded
                      placeholder={intl.formatMessage(
                        messages.exercisesProgramFilterNameLabel
                      )}
                      onChange={onSearch}
                      icon="search-grey"
                      iconLeft
                      useNative
                    />
                    <Button rounded light onClick={() => setFiltering(true)}>
                      <Icon id="filter" strokeColor="color-neutral-dark" />
                      <FormattedMessage {...messages.filterButton} />
                    </Button>
                    {(filter?.tags?.length > 0 || filter?.myOrg) && (
                      <TagsWrapperReadOnly
                        extraClassNamesWrapper="u-padding-vertical-small"
                        filter={filter}
                        handleOnClick={handleRemoveMyOrg}
                        handleOnChange={handleTagChange}
                        entityId={entityId}
                        selectMenuPlacement="bottom"
                      />
                    )}
                  </CardBodyTools>
                </div>
                <div className="c-card-modal__body-content">
                  <ExercisesListVirtualized
                    entityId={entityId}
                    onModal
                    exercises={exercises.filter(
                      e => !template.exercises.map(e => e.id).includes(e.id)
                    )}
                    checkable
                    showLevel
                    enablePreviewModal
                    onPreview={exercise => {
                      setExerciseId(exercise.id);
                      setPreview(true);
                    }}
                    onSelect={exercise => {
                      select(exercise);
                    }}
                    selectedExercises={selected}
                    {...fetcherProps}
                  />
                </div>
              </CardBody>
            );
          }
        }}
      </ExercisesFetcher>

      <CardFooter modal>
        {!filtering && !preview && (
          <ButtonsGroup>
            <Button secondary onClick={() => onCloseHandler()}>
              <FormattedMessage {...messages.cancelButton} />
            </Button>
            <Button
              disabled={selected.length === 0}
              primary
              onClick={() => onAddHandler()}
            >
              <FormattedMessage {...messages.addButton} />
            </Button>
          </ButtonsGroup>
        )}
      </CardFooter>
    </Modal>
  );
};

export default AddExercisesModal;
