import gql from 'graphql-tag';

export const QUERY_GET_MIRROR = gql`
  query getMirrorIntegration($personId: ID!, $entityId: ID!) {
    getMirrorIntegration(personId: $personId, entityId: $entityId)
  }
`;

export const MUTATION_ADD_MIRROR = gql`
  mutation addMirrorIntegration($personId: ID!, $entityId: ID!) {
    addMirrorIntegration(personId: $personId, entityId: $entityId)
  }
`;
