import React, { useEffect } from 'react';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_USER_SESSIONS_ENTITY_SESSION } from 'routes/RouteList';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { SessionListItemUser } from 'containers/partials/list-items/SessionListItemUser';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useUserContext } from 'contexts/UserContext';
import { TabPanelBody } from 'components/tabs';
import FieldInput from 'components/input/FieldInput';
import useSessionSearch from 'hooks/utils/useSessionSearch';
import { SORT_DATA_TYPES } from 'utils/sort';
import SortableListHeader from 'components/list/SortableListHeader';

const columns = [
  {
    key: 'finishedPercentage',
    label: '',
    sortable: false,
    desc: false,
    dataType: SORT_DATA_TYPES.NUMBER,
    extraProps: { id: 'listItemStatus' } // this prop is only necessary for styles. Most of the times we don't need it
  },
  {
    key: 'name',
    label: 'Session name',
    sortable: true,
    desc: false,
    dataType: SORT_DATA_TYPES.STRING,
    extraProps: { id: 'listItemMainLabel' } // this prop is only necessary for styles. Most of the times we don't need it
  },
  {
    key: 'type',
    label: 'Type',
    sortable: false,
    extraProps: { id: '' } // Empty id === hidden column
  },
  {
    key: 'startdate',
    label: 'Date',
    default: true,
    sortable: true,
    desc: true,
    dataType: SORT_DATA_TYPES.DATE_STRING,
    extraProps: { id: 'listItemLastLabel' } // this prop is only necessary for styles. Most of the times we don't need it
  }
];

function UserSessions({ entityId }) {
  const [{ filteredItems }, setState] = useSessionSearch({
    key: 'name',
    defaultSort: columns.find(c => c.default)
  });
  const { sessions, loading, error } = useUserContext();
  const { push } = useHistory();
  const { sessionId } = useParams();

  useEffect(() => {
    if (sessions.length > 0 && loading === false) {
      setState({
        items: sessions,
        sort: {
          key: 'startdate',
          desc: true,
          dataType: SORT_DATA_TYPES.DATE_STRING
        }
      });
    }
  }, [sessions, loading]);

  if (loading) return <Loader />;
  if (error) return <RetryPanel />;

  return (
    <TabPanelBody>
      <div className="p-tabs__tab-header p-tabs__tab-header-and-list-header">
        <FieldInput
          rounded
          type="text"
          icon="search-grey"
          iconLeft
          onChange={e => setState({ search: e.target.value })}
        />
      </div>

      {filteredItems?.length > 0 ? (
        <>
          <SortableListHeader
            refactoring
            columns={columns}
            onSort={activeSort => {
              setState({ sort: activeSort });
            }}
          />
          <List>
            <ListBody>
              {filteredItems.map(session => {
                return (
                  <SessionListItemUser
                    noMaxHeight
                    id={session.id}
                    key={session.id}
                    session={session}
                    active={sessionId === session.id}
                    onClick={() => {
                      push(
                        generatePath(ROUTE_USER_SESSIONS_ENTITY_SESSION, {
                          entityId,
                          sessionId: session.id
                        })
                      );
                    }}
                  />
                );
              })}
            </ListBody>
          </List>
        </>
      ) : (
        <Card secondary>
          <CardBody extraClassNames="u-margin-bottom-none u-padding-vertical-huge">
            <Message emptyState icon="test">
              <MessageText>
                <FormattedMessage {...messages.emptySessionsDataAthlete} />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </TabPanelBody>
  );
}

export default UserSessions;
