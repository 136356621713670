import React, { useEffect, useState } from 'react';
// import './Annotations.scss';
import { groupBy } from 'utils/array';
import SingleMarker from 'components/video/SingleMarker';
import GroupedMarker from 'components/video/GroupedMarker';
import { uid } from 'react-uid';

const renderChildren = (children, ...props) => {
  return React.Children.map(children, child => {
    if (child) {
      return React.cloneElement(child, ...props);
    }
  });
};

export const timeToPercentage = (time, duration) => {
  if (duration) {
    return (time / duration) * 100;
  }
};

function AnnotationMarkers({
  annotations,
  activeAnnotation,
  onClickMarker,
  children,
  ownershipVideo,
  ...props
}) {
  const { player } = props;
  const [activeOpen, setActiveOpen] = useState(null);
  const [groupedAnnotations, setGroupedAnnotations] = useState([]);

  useEffect(() => {
    if (annotations.length > 0) {
      setGroupedAnnotations(groupBy(annotations, ['starttime']));
    }
  }, [annotations]);

  useEffect(() => {
    if (activeAnnotation) {
      setActiveOpen(activeAnnotation.id);
    }
  }, [activeAnnotation]);

  return (
    <div className="p-video-react-annotations-markers video-react-annotations-control video-react-control">
      {renderChildren(children, props)}
      <div className="video-react-annotations-markers">
        {Object.values(groupedAnnotations).map(annotations => {
          if (annotations.length === 1) {
            return (
              <SingleMarker
                key={uid(annotations[0].id)}
                annotation={annotations[0]}
                onClick={onClickMarker}
                isActive={activeAnnotation?.id === annotations[0].id}
                position={timeToPercentage(
                  annotations[0].starttime,
                  player.duration
                )}
              />
            );
          } else {
            return (
              <GroupedMarker
                key={uid(annotations[0].id)}
                annotations={annotations}
                onClickMarker={annotation => {
                  onClickMarker(annotation);
                  setActiveOpen(annotation.id);
                }}
                onClickGroup={annotationId => setActiveOpen(annotationId)}
                groupIsActive={!!annotations.find(a => a.id === activeOpen)}
                activeAnnotation={activeAnnotation}
                position={timeToPercentage(
                  annotations[0].starttime,
                  player.duration
                )}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default AnnotationMarkers;
