import React, { useContext, useEffect, useRef, useState } from 'react';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import YearSelect, { yearsList } from 'containers/partials/inputs/YearSelect';
import { MenuWrapper } from 'components/menu';
import { FormattedMessage, useIntl } from 'react-intl';
import FieldInput from 'components/input/FieldInput';
import classNames from 'classnames';
import MessageText from 'components/message/MessageText';
import { StoreContext } from 'index';

const SelectState = {
  NONE: 'none',
  SOME: 'some',
  ALL: 'all'
};

const SelectAndFilterEntities = ({
  id,
  onModal,
  enableSelectAll = true,
  onSearchCallback
}) => {
  const intl = useIntl();

  const {
    authStore: { user }
  } = useContext(StoreContext);

  const groups = useEntityContext(s => s.groups);
  const persons = useEntityContext(s => s.persons);
  const selectedPersons = useEntityContext(s => s.selectedPersons);
  const setSelectedPersons = useEntityContext(s => s.setSelectedPersons);
  const excludedPersons = useEntityContext(s => s.excludedPersons);
  const removeSelectedPersons = useEntityContext(s => s.removeSelectedPersons);
  const setFilter = useEntityContext(s => s.setFilter);
  const clearSelected = useEntityContext(s => s.clearSelected);
  const filter = useEntityContext(s => s.filter);

  const [hasPersons, setHasPersons] = useState(false);
  const [selectedState, setSelectedState] = useState(SelectState.NONE);
  const [filterActive, setFilterActive] = useState(false);
  const [filterYearActive, setFilterYearActive] = useState(!!filter.year);
  const [searchInputFilled, setSearchInputFilled] = useState(
    filter?.search?.length > 0
  );

  const inputSearchRef = useRef();

  useEffect(() => {
    setHasPersons(persons.length > 0);
  }, [persons]);

  useEffect(() => {
    const entities = [...persons, ...groups];
    setFilterActive(
      filter.search.length > 0 &&
        entities.filter(
          el => el.filtered && !excludedPersons.find(exI => exI.id === el.id)
        ).length > 0
    );
    setFilterYearActive(!!filter.year);
    setSearchInputFilled(filter?.search?.length > 0);
  }, [filter, persons, groups]);

  useEffect(() => {
    if (selectedPersons.length > 0) {
      if (persons.some(item => selectedPersons.find(s => s.id === item.id))) {
        setSelectedState(SelectState.SOME);
      }
      if (persons.every(item => selectedPersons.find(s => s.id === item.id))) {
        setSelectedState(SelectState.ALL);
      }
    } else {
      setSelectedState(SelectState.NONE);
    }
  }, [selectedPersons, persons]);

  const onSearch = async e => {
    // filtering is done in the hook useFetchAndFilterEntities
    await setFilter({ search: e.target.value });

    onSearchCallback();
  };

  const selectAll = () => {
    switch (selectedState) {
      case SelectState.ALL:
        clearSelected();
        removeSelectedPersons(persons);
        setSelectedState(SelectState.NONE);
        break;
      case SelectState.NONE:
      case SelectState.SOME:
      default:
        setSelectedPersons(
          persons.filter(p => !excludedPersons.find(exp => exp.id === p.id))
        );
        setSelectedState(SelectState.ALL);
    }
  };

  const handleSelectFilteredItemsFromMenu = () => {
    const filtered = persons.filter(
      el => el.filtered && !excludedPersons.find(exI => exI.id === el.id)
    );
    if (selectedState === SelectState.ALL) {
      removeSelectedPersons(persons);
      // setSelectAllState(!selectAllState);
      setSelectedState(SelectState.NONE);
    }

    if (selectedState === SelectState.SOME) {
      removeSelectedPersons(filtered);
      setSelectedState(SelectState.NONE);
    } else {
      setSelectedPersons(
        filtered.filter(p => !excludedPersons.find(exp => exp.id === p.id))
      );
      setSelectedState(SelectState.SOME);
    }
  };

  const handleSelectAllItemsFromMenu = () => {
    selectAll();
  };

  const handleMainButton = () => {
    if (selectedState === SelectState.ALL) {
      selectAll();
    } else if (selectedState === SelectState.SOME) {
      handleSelectFilteredItemsFromMenu();
    } else {
      filterActive ? handleSelectFilteredItemsFromMenu() : selectAll();
    }
  };

  const onYearFilter = year => {
    setFilter({ year });
  };

  return (
    <>
      <div className="p-tabs__tab-header">
        {(user.isSportamundiOrg || user.isHanOrg) &&
          groups.length > 0 &&
          !onModal && (
            <YearSelect
              extraClassNames={'p-tabs__tab-header--element u-1-of-1'}
              onChange={onYearFilter}
              value={filter.year}
              label={intl.formatMessage(messages.yearPerGroupSelect)}
              disabled={searchInputFilled}
            />
          )}

        {enableSelectAll && hasPersons && (
          <div className="p-tabs__tab-header--element u-margin-top-auto">
            <ButtonsGroup halfButtonsMerged>
              <SelectPersonsCheckbox
                onClick={handleMainButton}
                selectedState={selectedState}
              />
              <MenuWrapper
                trigger={
                  <Button
                    halfRounded
                    ghost
                    disabled={
                      filterYearActive ||
                      (!filterActive && filter.search.length > 0)
                    }
                  >
                    <Icon id="dropdown-open" fillColor="color-neutral" />
                  </Button>
                }
              >
                <SelectFilteredPersonsMenuButton
                  filterActive={filterActive}
                  selectedState={selectedState}
                  onClick={handleSelectFilteredItemsFromMenu}
                />

                <SelectAllPersonsMenuButton
                  selectedState={selectedState}
                  onClick={handleSelectAllItemsFromMenu}
                />
              </MenuWrapper>
            </ButtonsGroup>
          </div>
        )}
        <div
          className={
            onModal && !(enableSelectAll && hasPersons)
              ? 'u-1-of-1'
              : 'p-tabs__tab-header--element u-margin-left-auto u-margin-top-auto'
          }
        >
          <FieldInput
            id={`${id}_search`}
            placeholder={intl.formatMessage(
              messages.searchUsersAndGroupsPlaceholder
            )}
            title={intl.formatMessage(messages.searchUsersAndGroupsPlaceholder)}
            value={filter.search}
            onChange={onSearch}
            rounded
            type="text"
            icon="search-grey"
            iconLeft
            useNative
            extraClassNames={classNames(
              'u-margin-bottom-none c-input--expandable c-input--search',
              {
                'c-input--expanded': searchInputFilled
              }
            )}
            ref={inputSearchRef}
            disabled={filterYearActive}
          />
          <Button
            mini
            light
            type="reset"
            onClick={() => {
              inputSearchRef.current.value = '';
              setFilter({ search: inputSearchRef.current.value });
              inputSearchRef.current.focus();
            }}
          >
            <Icon id="close" strokeColor="color-neutral-dark"></Icon>
            <span className="u-hidden-visually">Clear input</span>
          </Button>
        </div>
      </div>

      {filter.search.length > 0 && (
        <MessageText extraClassNames="c-list__filtering-message">
          {persons.length > 0 && groups.length === 0 && (
            <>
              <FormattedMessage
                {...messages.searchInputPersonsFeedback}
                values={{
                  count: persons.filter(p => p.filtered).length,
                  total: persons.length,
                  searchTerm: filter.search,
                  b: chunks => <b>{chunks}</b>
                }}
              />
              {groups.length > 0 && <>{', '}</>}
            </>
          )}
          {groups.length > 0 && persons.length === 0 && (
            <FormattedMessage
              {...messages.searchInputGroupsFeedback}
              values={{
                count: groups.filter(p => p.filtered).length,
                total: groups.length,
                searchTerm: filter.search,
                b: chunks => <b>{chunks}</b>
              }}
            />
          )}
          {groups.length > 0 && persons.length > 0 && (
            <FormattedMessage
              {...messages.searchInputPersonsAndGroupsFeedback}
              values={{
                countPersons: persons.filter(p => p.filtered).length,
                countGroups: groups.filter(p => p.filtered).length,
                searchTerm: filter.search,
                b: chunks => <b>{chunks}</b>
              }}
            />
          )}
        </MessageText>
      )}

      {filterYearActive && (
        <MessageText extraClassNames="c-list__filtering-message">
          <FormattedMessage
            {...messages.filterByYearFeedback}
            values={{
              count: groups.filter(el => {
                return el.filtered;
              }).length,
              total: groups.length,
              year: yearsList?.find(y => y.value === filter.year)?.label,
              b: chunks => <b>{chunks}</b>
            }}
          />
        </MessageText>
      )}
    </>
  );
};

export default SelectAndFilterEntities;

const SelectPersonsCheckbox = ({ selectedState, onClick }) => {
  const intl = useIntl();
  const persons = useEntityContext(s => s.persons);
  const selectedPersons = useEntityContext(s => s.selectedPersons);
  const excludedPersons = useEntityContext(s => s.excludedPersons);
  const filter = useEntityContext(s => s.filter);
  let label;
  let icon = 'checkbox-no-neutral';

  const [filterActive, setFilterActive] = useState(false);
  const [filterYearActive, setFilterYearActive] = useState(!!filter.year);

  useEffect(() => {
    setFilterActive(
      filter.search.length > 0 && persons.filter(el => el.filtered).length > 0
    );
    setFilterYearActive(!!filter.year);
  }, [filter, persons]);

  // make a list of all items that are not in the excludedItems list
  const activeItems = persons.filter(
    p => !excludedPersons.find(exp => exp.id === p.id)
  );

  if (selectedState !== SelectState.NONE) {
    label = intl.formatMessage(messages.clearSelection, {
      count: selectedPersons.length
    });
    icon = 'checkbox-yes-blue';
  } else if (filterActive) {
    label = intl.formatMessage(messages.selectSomePersonsButton, {
      count: activeItems.filter(el => el.filtered).length
    });
  } else {
    label = intl.formatMessage(messages.selectAllButton, {
      count: activeItems.filter(el => el.filtered).length
    });
  }

  return (
    <Button
      halfRounded
      ghost
      onClick={onClick}
      title={label}
      disabled={
        activeItems.filter(el => el.filtered).length <= 0 ||
        filterYearActive ||
        (!filterActive && filter.search.length > 0)
      }
    >
      <Icon
        id={icon}
        mediumIcon
        fillColor={
          filterYearActive || (!filterActive && filter.search.length > 0)
            ? 'color-neutral-light'
            : 'color-neutral-x-light'
        }
        strokeColor={
          filterYearActive || (!filterActive && filter.search.length > 0)
            ? 'color-neutral-dark'
            : 'color-neutral-dark'
        }
        style={
          filterYearActive || (!filterActive && filter.search.length > 0)
            ? { opacity: 0.5 }
            : {}
        }
      />
      <span className="c-button--ellipsed">{label}</span>
    </Button>
  );
};

const SelectFilteredPersonsMenuButton = ({
  filterActive,
  selectedState,
  onClick
}) => {
  const persons = useEntityContext(s => s.persons);
  const selectedPersons = useEntityContext(s => s.selectedPersons);
  return (
    <Button disabled={!filterActive} menu onClick={onClick}>
      {selectedState === SelectState.SOME ? (
        <FormattedMessage
          {...messages.clearSelection}
          values={{
            count: selectedPersons.length
          }}
        />
      ) : (
        <FormattedMessage
          {...messages.selectSomePersonsButton}
          values={{
            count: filterActive ? persons.filter(p => p.filtered).length : ''
          }}
        />
      )}

      {selectedState === SelectState.SOME && <Icon id="checked-all" />}
    </Button>
  );
};

const SelectAllPersonsMenuButton = ({ selectedState, onClick }) => {
  const persons = useEntityContext(s => s.persons);
  const excludedPersons = useEntityContext(s => s.excludedPersons);
  // find all persons who are not in the excludedPersons list
  const remainingPersons = persons.filter(
    p => !excludedPersons.find(exp => exp.id === p.id)
  );

  return (
    <Button menu onClick={onClick}>
      {selectedState === SelectState.ALL ? (
        <FormattedMessage
          {...messages.clearSelection}
          values={{
            count: remainingPersons.length
          }}
        />
      ) : (
        <FormattedMessage
          {...messages.selectAllButton}
          values={{
            count: remainingPersons.length
          }}
        />
      )}
      {selectedState === SelectState.ALL && <Icon id="checked-all" />}
    </Button>
  );
};
