import { createStore } from 'zustand';
import { createContext, useState } from 'react';
import Group from 'models/Group';
import Person from 'models/Person';

export const EntityStoreContext = createContext(null);

export const EntityStoreProvider = ({ children, initialState }) => {
  const [store] = useState(() => createEntityStore(initialState));
  return (
    <EntityStoreContext.Provider value={store}>
      {children}
    </EntityStoreContext.Provider>
  );
};

export const createEntityStore = () => {
  return createStore(set => ({
    person: new Person({}),
    group: new Group({}),
    groups: [], // only groups
    persons: [], // only persons
    excludedPersons: [],
    excludedGroups: [],
    entities: [], // all entities, Groups + Persons
    selectedPersons: [],
    selectedGroups: [],
    filter: {
      search: '',
      year: '',
      type: ''
    },
    setGroup: group => set(() => ({ group })),
    setGroups: groups => set(() => ({ groups })),
    setExcludedGroups: groups => set(() => ({ excludedGroups: groups })),

    setPerson: person => set(() => ({ person })),
    setPersons: persons => set(() => ({ persons })),
    setExcludedPersons: persons => set(() => ({ excludedPersons: persons })),

    clearSelectedPersons: () => set(() => ({ selectedPersons: [] })),
    clearSelectedGroups: () => set(() => ({ selectedGroups: [] })),
    clearSelected: () =>
      set(() => ({ selectedPersons: [], selectedGroups: [] })),
    clearExcludedPersons: () => set(() => ({ excludedPersons: [] })),
    clearExcludedGroups: () => set(() => ({ excludedGroups: [] })),
    setSelectedPersons: values =>
      set(() => ({
        selectedPersons: values
      })),
    setSelectedGroups: values =>
      set(() => ({
        selectedGroups: values
      })),

    selectPersons: values =>
      set(state => {
        const persons = [...values];
        const selectedPersons = [...state.selectedPersons];
        persons.forEach(entity => {
          if (!selectedPersons.find(e => e.id === entity.id)) {
            selectedPersons.push(entity);
          } else {
            const index = selectedPersons.findIndex(e => e.id === entity.id);
            if (index >= 0) {
              selectedPersons.splice(index, 1);
            }
          }
        });
        return { selectedPersons };
      }),

    selectGroups: values =>
      set(state => {
        const groups = [...values];
        const selectedGroups = [...state.selectedGroups];
        groups.forEach(entity => {
          if (!selectedGroups.find(e => e.id === entity.id)) {
            selectedGroups.push(entity);
          } else {
            const index = selectedGroups.findIndex(e => e.id === entity.id);
            if (index >= 0) {
              selectedGroups.splice(index, 1);
            }
          }
        });
        return { selectedGroups };
      }),
    removeSelectedPersons: values =>
      set(state => ({
        selectedPersons: state.selectedPersons.filter(
          s => !values.find(o => o.id === s.id)
        )
      })),
    removeSelectedGroups: values =>
      set(state => ({
        selectedGroups: state.selectedGroups.filter(
          s => !values.find(o => o.id === s.id)
        )
      })),
    setFilter: values =>
      set(state => ({
        filter: { ...state.filter, ...values }
      })),
    resetFilter: () =>
      set(() => ({ filter: { search: '', year: '', type: '' } }))
  }));
};
