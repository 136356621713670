import React, { forwardRef, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import {
  MUTATION_ADD_TEMPLATE,
  MUTATION_EDIT_TEMPLATE
} from 'services/aws/templates-query';
import { cleanFalsy } from 'utils/object';
import { TemplateType } from 'constants.js';
import useTemplates from 'hooks/queries/useTemplates';
import useTemplate from 'hooks/queries/useTemplate';
import Loader from 'components/loader/Loader';

const TemplateForm = (
  { entityId, template, type, children, extraClassNames, onComplete },
  ref
) => {
  const notification = useNotificationQueue();
  const intl = useIntl();
  const { fetchTemplate } = useTemplate();
  const { fetchTemplates } = useTemplates(entityId);

  const [loading, setLoading] = useState(false);

  const [editTemplate] = useMutation(MUTATION_EDIT_TEMPLATE);
  const [addTemplate] = useMutation(MUTATION_ADD_TEMPLATE, {
    awaitRefetchQueries: true
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired))
  });

  const onSubmitHandler = async template => {
    setLoading(true);
    const templateObj = cleanFalsy({
      entityId,
      title: template.title,
      exerciseIds: template.exerciseIds,
      tagIds: template.tags.map(tag => tag.id),
      duration: template.duration,
      type: template.type ?? TemplateType.TEMPLATE
    });

    if (!template.id) {
      const { data } = await addTemplate({
        variables: {
          ...templateObj
        }
      });

      if (data?.addExerciseProgramTemplate) {
        fetchTemplates({
          variables: { entityId, type: template.type },
          fetchPolicy: 'network-only'
        });

        setLoading(false);

        notification.add(data.addExerciseProgramTemplate.id, {
          message: intl.formatMessage(messages.messageAddTemplateSuccess)
        });

        if (onComplete) onComplete(data.addExerciseProgramTemplate.id);
      }
    } else {
      const { data } = await editTemplate({
        variables: {
          id: template.id,
          ...templateObj
        }
      });

      if (data?.editExerciseProgramTemplate) {
        fetchTemplate({
          variables: { templateId: template.id },
          fetchPolicy: 'network-only'
        });

        fetchTemplates({
          variables: { entityId, type },
          fetchPolicy: 'network-only'
        });

        setLoading(false);

        notification.add(data.editExerciseProgramTemplate.id, {
          message: intl.formatMessage(messages.messageEditTemplateSuccess)
        });

        onComplete && onComplete(data.editExerciseProgramTemplate.id);
      }
    }
  };

  return (
    <Formik
      innerRef={ref}
      initialValues={template}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {loading && <Loader />}
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
};

export default forwardRef(TemplateForm);
