import React from 'react';
import MTSCard from './MTSCard';
import { PackageTypes, PackageSubTypes } from 'constants.js';
import RetryPanel from '../error-boundary/RetryPanel';
import Loader from 'components/loader/Loader';
import { useSessionContext } from 'contexts/SessionContext';
import IAmReportCard from 'containers/partials/cards/ReportCards/IAmReportCard';
import SapReportCard from 'containers/partials/cards/ReportCards/SapReportCard';
import ILikeReportCard from 'containers/partials/cards/ReportCards/ILikeReportCard';
import { useTestDataContext } from 'contexts/TestDataContext';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const LegacyReportCard = ({ type, subtype, reportBenchmarkTab, ...props }) => {
  switch (type) {
    case PackageTypes.ILIKE:
      return <ILikeReportCard {...props} />;
    case PackageTypes.MTS:
      return <MTSCard {...props} />;
    case PackageTypes.SAP:
      switch (subtype) {
        case PackageSubTypes.IAM:
        case PackageSubTypes.DJAS:
        case PackageSubTypes.SGW:
        case PackageSubTypes.VGB:
          return <IAmReportCard {...props} />;
        default:
          return <SapReportCard {...props} />;
      }
    default:
      return <ILikeReportCard {...props} />;
  }
};

const SporterReportCard = ({ entityId, rootEntityId, reportBenchmarkTab }) => {
  const person = useEntityContext(s => s.person);
  const {
    session,
    tests,
    loading: sessionLoading,
    error: sessionError,
    options
  } = useSessionContext();

  const { testData } = useTestDataContext();

  if (sessionLoading || !person) return <Loader />;
  if (sessionError) return <RetryPanel />;

  return (
    <>
      {session.testData && (
        <LegacyReportCard
          testDataId={testData?.id}
          entityId={entityId}
          rootEntityId={rootEntityId}
          type={session.config.type}
          subtype={session.config.subtype}
          reportBenchmarkTab={reportBenchmarkTab}
          // result={dataResult}
          testData={testData}
          session={session}
          tests={tests}
          sporter={person}
          getDataEntityId={entityId}
          startdate={session.startdate}
          options={options}
          compressed
        />
      )}
    </>
  );
};

export default SporterReportCard;
