import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import Select, { Option } from 'components/input/Select';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { QUERY_GET_BENCHMARKS } from 'services/aws/benchmark-query';
import { sort, SORT_DATA_TYPES } from 'utils/sort';

function BenchmarksSelect({
  entityId,
  value,
  onChange,
  withEmptyOption,
  inline = false,
  style = {},
  readOnly
}) {
  const intl = useIntl();
  const [benchmarks, setBenchmarks] = useState([]);
  const [selectedBenchmark, setSelectedBenchmark] = useState(value);

  const [getBenchmarks, { loading, error, data }] = useLazyQuery(
    QUERY_GET_BENCHMARKS,
    {
      variables: { entityId },
      skip: !entityId
    }
  );

  useEffect(() => {
    if (entityId) {
      getBenchmarks();
    }
  }, [entityId]);

  useEffect(() => {
    setSelectedBenchmark(value);
  }, [value]);

  useEffect(() => {
    if (data?.getBenchmarks && data.getBenchmarks.length > 0) {
      let benchmarks = data.getBenchmarks.filter(benchmark => !benchmark.draft);
      //benchmarks = sort(benchmarks, { keys: [{ key: 'title' }] });

      benchmarks = sort(benchmarks, {
        keys: [
          {
            key: 'default',
            dataType: SORT_DATA_TYPES.BOOL,
            desc: true
          },
          { key: 'originalId' },
          {
            key: 'version',
            desc: true,
            dataType: SORT_DATA_TYPES.NUMBER
          }
        ]
      });

      setBenchmarks(benchmarks);
    }
  }, [data]);

  const onSelectHandler = select => {
    setSelectedBenchmark(select.value);
    onChange(benchmarks.find(benchmark => benchmark.id === select.value));
  };

  if (error) return intl.formatMessage(messages.errorMessageBenchmark);

  if (loading) {
    return (
      <Select
        label={intl.formatMessage(messages.labelSessionBenchmark)}
        readOnly={readOnly}
        disabled={loading || readOnly}
        value=""
      >
        <Option value="" label={intl.formatMessage(messages.globalLoading)} />
      </Select>
    );
  }

  return (
    <Select
      extraClassNames="o-flex--justify-space-between"
      inline={inline}
      label={intl.formatMessage(messages.labelSessionBenchmark)}
      onChange={select => onSelectHandler(select)}
      value={selectedBenchmark ?? ''}
      disabled={loading}
      style={style}
      readOnly={readOnly}
    >
      {withEmptyOption && (
        <Option value="" label={intl.formatMessage(messages.noResultsOption)} />
      )}
      {benchmarks &&
        benchmarks.map(({ id, title }) => (
          <Option key={id} value={id} label={title} />
        ))}
    </Select>
  );
}

export default BenchmarksSelect;
