import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';
import Overview from './Overview';

import {
  ROUTE_USER_SESSIONS_ENTITY,
  ROUTE_USER_SESSIONS_ENTITY_MEDICAL,
  ROUTE_USER_SESSIONS_ENTITY_SESSION,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT
} from 'routes/RouteList';

import Panel from 'components/panel/Panel';
import messages from 'messages';
import { SessionContextProvider } from 'contexts/SessionContext';
import { StoreContext } from 'index';
import Session from 'containers/pages/person/sessions/Session';
import { UserContextProvider } from 'contexts/UserContext';
import { TestDataProvider } from 'contexts/TestDataContext';
import { EditableContextProvider } from 'contexts/EditableContext';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';
import { MenuContextProvider } from 'contexts/MenuContext';

const UserSessions = () => {
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const entityStore = useRef(createEntityStore()).current;

  return (
    <EditableContextProvider>
      <Switch>
        <Route
          path={[
            ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA,
            ROUTE_USER_SESSIONS_ENTITY_SESSION,
            ROUTE_USER_SESSIONS_ENTITY
          ]}
        >
          <UserContextProvider>
            <Overview />
          </UserContextProvider>
        </Route>
        <Redirect
          to={ROUTE_USER_SESSIONS_ENTITY.replace(
            ':entityId',
            user.rootEntityId
          )}
        />
      </Switch>
      <Route
        exact
        path={[ROUTE_USER_SESSIONS_ENTITY, ROUTE_USER_SESSIONS_ENTITY_MEDICAL]}
      >
        <Panel
          emptyState
          emptyLabel={intl.formatMessage(messages.sessionsEmptyPanel)}
        />
      </Route>
      <Switch>
        <Route
          exact
          path={[
            ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT,
            ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA,
            ROUTE_USER_SESSIONS_ENTITY_SESSION
          ]}
        >
          <SessionContextProvider>
            <EntityStoreProvider value={entityStore}>
              <MenuContextProvider>
                <TestDataProvider>
                  <Session />
                </TestDataProvider>
              </MenuContextProvider>
            </EntityStoreProvider>
          </SessionContextProvider>
        </Route>
      </Switch>
    </EditableContextProvider>
  );
};

export default withRouter(observer(UserSessions));
