import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { Button } from 'components/button';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import { StoreContext } from 'index';
import { useMutation } from '@apollo/client';
import ScreeningForm from 'containers/pages/rehab/screening/ScreeningForm';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import { MUTATION_EDIT_TESTSET } from 'services/aws/testsets-query';
import { reorder } from 'utils/array';
import { parseTestSetTests } from 'containers/pages/rehab/screening/Screening';
import AddExtraTestsModal from 'containers/pages/prevention/AddExtraTestsModal';
import Icon from 'components/icon/Icon';
import { TestListItem } from 'containers/partials/list-items/TestListItem';
import { useTestsContext } from 'contexts/TestsContext';
import { TestSetV2Type } from 'constants.js';
import Loader from 'components/loader/Loader';
import { QUERY_GET_SESSION_BY_ID } from 'services/aws/session-query';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { groupTests } from 'utils/tests';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useUIDSeed } from 'react-uid';
import ScreeningFormDetails from 'containers/pages/rehab/screening/ScreeningFormDetails';
import { useTestSetContext } from 'contexts/TestSetContext';
import { useSessionContext } from 'contexts/SessionContext';

function ScreeningDetailCard({ personInjury, edit: initialEdit }) {
  const {
    authStore: { entityId }
  } = useContext(StoreContext);
  const { setModalData } = usePreviewModalContext();
  const { session: screening } = useSessionContext();
  const uidSeed = useUIDSeed();
  const { actions, state } = useTestSetContext();
  const { testsState, testActions, loading } = useTestsContext();
  const [tests, setTests] = useState([]);
  const [testSet, setTestSet] = useState({});
  const [edit, setEdit] = useState(initialEdit);
  const [addExtraTestsModal, setAddExtraTestsModal] = useState(null);

  useEffect(() => {
    testActions.getTests(TestSetV2Type.REHAB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    testActions.setTests([]);
    testActions.updateTests([]);

    if (state.testSet) {
      setTestSet(state.testSet);

      testActions.setTests(state.testSet.tests);
      if (testsState.tests.length > 0) {
        testActions.updateTests(state.testSet.tests);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screening.id, state.testSet]);

  useEffect(() => {
    if (screening?.testSetIds?.[0]) {
      actions.getTestSet(screening?.testSetIds?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screening?.testSetIds]);

  const [editTestSet] = useMutation(MUTATION_EDIT_TESTSET, {
    refetchQueries: [
      {
        query: QUERY_GET_SESSION_BY_ID,
        variables: { testSessionId: screening.id, entityId }
      }
    ]
  });

  useEffect(() => {
    if (!loading && state?.testSet?.tests) {
      if (state?.testSet?.tests.length > 0) {
        testActions.setSelectedTests(parseTestSetTests(state?.testSet?.tests));
      } else {
        testActions.setSelectedTests([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.testSet?.tests, loading, testActions.tests, screening.id]);

  useEffect(() => {
    if (testsState.selectedTests) {
      setTests(groupTests([...testsState.selectedTests]));
    }
  }, [testsState.selectedTests]);

  const onSubmitted = () => {
    if (testsState.selectedTests?.length > 0 && edit) {
      editTestSet({
        variables: {
          id: testSet.id,
          title: testSet.title,
          testIds: testsState.selectedTests.map(ts => ts.id),
          forceUpdate: true
        }
      });
    }
  };

  const onTestAddCheckHandler = test => {
    // get the dependencies
    const dependencies = testsState.tests.filter(ts =>
      test.dependedTestIds?.includes(ts.id)
    );
    testActions.selectTests([test, ...dependencies]);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    const reorderdTestSet = reorder(
      testsState.selectedTests,
      source.index,
      destination.index
    );
    //const testSetIds = reorderdTestSet.map(test => test.id);

    testActions.setSelectedTests(reorderdTestSet);
  };

  const onCancel = () => {
    testActions.setSelectedTests(state.testSet.tests);
  };

  const onTestRemoveCheckHandler = test => {
    testActions.unselectTests([test]);
  };

  const onAddExtraTestsHandler = tests => {
    testActions.addExtraTest(tests);
    setAddExtraTestsModal(false);
  };

  return (
    <>
      {loading && <Loader />}
      <ScreeningForm
        entityId={entityId}
        screening={screening}
        personInjury={personInjury}
        onSubmitted={onSubmitted}
      >
        {({ isSubmitting }) => (
          <>
            <Card secondary>
              <CardHeader secondary>
                <CardHeaderTitle>
                  <FormattedMessage {...messages.titleScreening} />
                </CardHeaderTitle>
                <FormEditHeaderButtons
                  edit={edit}
                  setEdit={setEdit}
                  type={'submit'}
                  disabledSubmit={isSubmitting}
                  onCancel={onCancel}
                />
              </CardHeader>
              <CardBody secondary extraClassNames={'u-margin-top-small'}>
                <ScreeningFormDetails
                  entityId={entityId}
                  personInjury={personInjury}
                  edit={edit}
                  customizable
                />
              </CardBody>
            </Card>

            <Card secondary>
              <CardHeader secondary>
                <CardHeaderTitle>
                  <FormattedMessage {...messages.titleScreeningTests} />
                </CardHeaderTitle>
                <FormEditHeaderButtons
                  edit={edit}
                  setEdit={setEdit}
                  type={'submit'}
                  disabledSubmit={isSubmitting}
                  onCancel={onCancel}
                />
              </CardHeader>

              <CardBody secondary>
                {Object.keys(tests).length > 0 ? (
                  <>
                    {Object.keys(tests).map(category => (
                      <List
                        key={category}
                        preview
                        style={{
                          minWidth: '0'
                        }}
                      >
                        <ListHeader oneTitle>
                          <ListColumnTitle>{category}</ListColumnTitle>
                        </ListHeader>
                        <ListBody>
                          {tests[category].length > 0 &&
                            tests[category].map(test => (
                              <TestListItem
                                uid={uidSeed(test.id)}
                                key={test.id}
                                test={test}
                                checkbox={edit}
                                edit={edit}
                                checked={true}
                                onClick={() => {
                                  setModalData({
                                    type: 'testItem',
                                    data: test
                                  });
                                }}
                                clickable
                                onChange={e =>
                                  onTestRemoveCheckHandler(test, e)
                                }
                                dependentEditable={edit}
                              />
                            ))}
                        </ListBody>
                      </List>
                    ))}

                    {/*// List the unselected tests in edit mode */}
                    {edit && testsState.unselectedTests.length > 0 && (
                      <>
                        <List
                          extraClassNames={'u-margin-top-medium'}
                          preview
                          style={{ minWidth: '0' }}
                        >
                          <ListHeader oneTitle>
                            <ListColumnTitle>
                              <FormattedMessage
                                {...messages.titleScreeningUnselectedTests}
                              />
                            </ListColumnTitle>
                          </ListHeader>
                          <ListBody>
                            {testsState.unselectedTests.map(test => (
                              <TestListItem
                                uid={uidSeed(test.id)}
                                key={test.id}
                                test={test}
                                checkbox={edit}
                                edit={edit}
                                checked={false}
                                onChange={() => onTestAddCheckHandler(test)}
                                dependentEditable={edit}
                              />
                            ))}
                          </ListBody>
                        </List>
                      </>
                    )}
                  </>
                ) : (
                  <p
                    className={
                      'o-flex o-flex--column o-flex--align-center u-margin-bottom-none'
                    }
                  >
                    <FormattedMessage {...messages.labelScreeningNoTests} />
                  </p>
                )}
                <div className="o-flex o-flex--justify-end">
                  <Button
                    small
                    ghost
                    onClick={() => {
                      setEdit(true);
                      setAddExtraTestsModal(screening);
                    }}
                  >
                    <Icon id="add" />
                    <FormattedMessage {...messages.buttonScreeningAddTests} />
                  </Button>
                </div>
              </CardBody>
            </Card>
          </>
        )}
      </ScreeningForm>
      {addExtraTestsModal && (
        <AddExtraTestsModal
          type={TestSetV2Type.REHAB}
          entityId={entityId}
          session={screening}
          onClose={() => {
            setAddExtraTestsModal(false);
          }}
          onAdd={tests => onAddExtraTestsHandler(tests)}
        />
      )}
    </>
  );
}

export default withRouter(ScreeningDetailCard);
