import React from 'react';
import classNames from 'classnames';

const Checkbox = props => {
  return (
    <div
      className={classNames(
        'c-input__group c-input__group--checkbox',
        props.extraClassNames,
        {
          'c-input--checkbox--in-list': props.inListStatus,
          'c-input--checkbox--checked': props.defaultChecked,
          'c-input--checkbox--visible': props.visible,
          'c-input__checkbox--full': props.extraLabel,
          'c-input--checkbox--secondary': props.secondary,
          'c-input--checkbox--simple': props.simple,
          'c-input--checkbox--indeterminate': props.indeterminate,
          'c-input--checkbox--rounded': props.rounded
        }
      )}
    >
      <input
        id={props.id}
        name={props.name}
        type="checkbox"
        className={classNames('c-input c-input--checkbox', {
          'c-input--checkbox-disabled': props.readOnly
        })}
        checked={props.checked && !props.indeterminate}
        defaultChecked={props.defaultChecked && !props.indeterminate}
        onChange={props.onChange ? props.onChange : e => e.stopPropagation()}
        //onClick={props.onChange ? props.onChange : e => e.stopPropagation()}
        disabled={props.readOnly}
        data-qa={`${props.dataQa}-option`}
      />

      <label
        htmlFor={props.id}
        className={classNames('c-input__label c-input__label--checkbox')}
      >
        {props.children && !props.simple ? (
          <div className="c-input__text-group">
            <span className="c-input__text">{props.children}</span>
            {props.extraLabel && (
              <span className="c-input__extra-label">{props.extraLabel}</span>
            )}
          </div>
        ) : props.children && props.simple ? (
          props.children
        ) : (
          ''
        )}
      </label>
    </div>
  );
};

export default Checkbox;
