import React, { useContext } from 'react';
import FieldInput from 'components/input/FieldInput';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import YearSelect from 'containers/partials/inputs/YearSelect';
import { useFormikContext } from 'formik';
import { StoreContext } from 'index';

const GroupFormDetails = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { values, errors, touched, setFieldValue } = useFormikContext();

  return (
    <>
      <FieldInput id="name" name="name">
        <FormattedMessage {...messages.labelSportersGroupName} />*
      </FieldInput>
      {(user.isSportamundiOrg || user.isHanOrg) && (
        <>
          <FieldInput id="grade" name="grade" errors={errors} touched={touched}>
            <FormattedMessage {...messages.labelSportersGroupGrade} />
          </FieldInput>
          <YearSelect
            id="year"
            name="year"
            onChange={year => setFieldValue('year', year)}
            value={values.year}
          >
            <FormattedMessage {...messages.labelSportersGroupYear} />
          </YearSelect>
        </>
      )}
    </>
  );
};

GroupFormDetails.propTypes = {};

export default GroupFormDetails;
