import React from 'react';
import classNames from 'classnames';

const PageHeaderUser = props => {
  const { langMobile, children } = props;
  return (
    <div
      className={classNames('c-page__right-item', {
        'c-page__right-item-language': langMobile
      })}
    >
      {children}
    </div>
  );
};

export default PageHeaderUser;
