import { useLazyQuery } from '@apollo/client';
import Template from 'models/Template';
import { QUERY_GET_TEMPLATE } from 'services/aws/templates-query';
import { useStore } from 'stores/Store';

const useTemplate = () => {
  const setTemplate = useStore(state => state.setTemplate);

  const [getTemplate, { loading, error }] = useLazyQuery(QUERY_GET_TEMPLATE);

  const fetchTemplate = async options => {
    if (!options?.variables?.templateId) return;
    const { data } = await getTemplate({
      ...options
    });
    if (data?.getExerciseProgramTemplate) {
      const templateData = data.getExerciseProgramTemplate;
      const template = new Template({ ...templateData });
      setTemplate(template);
      return template;
    }
  };

  return {
    loading,
    error,
    fetchTemplate
  };
};

export default useTemplate;
