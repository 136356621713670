import React, { Fragment } from 'react';
import { useSessionContext } from 'contexts/SessionContext';
import { CardBody } from 'components/card';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import TestItemListItem from 'containers/partials/list-items/TestItemListItem';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useTestDataContext } from 'contexts/TestDataContext';
import Loader from 'components/loader/Loader';
import classNames from 'classnames';
import { RefactoredList } from 'components/list';
import RefactoredListHeaderItem from 'components/list/RefactoredListHeaderItem';
import RefactoredListHeader from 'components/list/RefactoredListHeader';
import RefactoredListBody from 'components/list/RefactoredListBody';

function TestItemResultsList({ entityId, personInjury, edit }) {
  const { session } = useSessionContext();
  const { testDataId, testDataLoading } = useTestDataContext();
  const { setModalData } = usePreviewModalContext();

  if (Object.keys(session.groupedTests).length > 0) {
    return (
      <>
        {testDataLoading && <Loader />}
        <CardBody secondary>
          {Object.keys(session.groupedTests).map((category, i, arr) => (
            <Fragment key={category}>
              {session.groupedTests[category].length > 0 &&
                session.groupedTests[category].some(
                  test => test.testItems.length > 0 // Hide tests without testitems
                ) &&
                session.groupedTests[category].some(
                  test => test.testItems.some(ti => ti.input_type !== 'derived') // Hide test categories with only derived items
                ) && (
                  <RefactoredList
                    style={{
                      zIndex: edit ? arr.length - i : 'auto'
                    }}
                    extraClassNames={classNames(
                      'r-list--suplementary-with-range-and-select',
                      {}
                    )}
                  >
                    <RefactoredListHeader>
                      <RefactoredListHeaderItem>
                        {category}
                      </RefactoredListHeaderItem>
                    </RefactoredListHeader>
                    <RefactoredListBody>
                      {session.groupedTests[category].map(
                        (test, index, array) => {
                          const testItems = [
                            ...test.testItems.filter(
                              testItem => testItem.input_type !== 'derived'
                            )
                          ];
                          if (!testItems.length) return null;

                          return (
                            <TestItemListItem
                              entityId={entityId}
                              testDataId={testDataId}
                              key={`${test.id}`}
                              edit={edit}
                              test={test}
                              testItems={testItems}
                              version={session.version}
                              sides={personInjury ? personInjury.sides : []}
                              // Grouped is a list Item with SubList and Items within
                              grouped={testItems.length > 1}
                              onClick={() => {
                                setModalData({
                                  type: 'testItem',
                                  data: test
                                });
                              }}
                              indexListItem={array.length - index}
                            />
                          );
                        }
                      )}
                    </RefactoredListBody>
                  </RefactoredList>
                )}
            </Fragment>
          ))}
        </CardBody>
      </>
    );
  }

  return (
    <CardBody empty>
      <p
        className={
          'o-flex o-flex--column o-flex--align-center u-margin-bottom-none'
        }
      >
        <FormattedMessage {...messages.cardLabelNoTests} />
      </p>
    </CardBody>
  );
}

export default TestItemResultsList;
