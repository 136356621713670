import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import {
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { PanelBody, PanelHeader, PanelTitleButton } from 'components/panel';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import Loader from 'components/loader/Loader';
import { TabPanelBody, Tabs } from 'components/tabs';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { getLocalDateFormat } from 'utils/date';
import ExerciseFeedbackPanel from 'containers/pages/person/programs/ExerciseFeedbackPanel';
import ExercisePanel from 'containers/pages/person/programs/ExercisePanel';
import useTabIndex from 'hooks/utils/useTabIndex';
import MenuWrapper from 'components/menu/MenuWrapper';
import DownloadExerciseProgramButton from 'containers/partials/buttons/DownloadExerciseProgramButton';
import React, { useEffect } from 'react';
import RemoveProgramButton from 'containers/partials/buttons/RemoveProgramButton';
import { useMenuContext } from 'contexts/MenuContext';
import Icon from 'components/icon/Icon';
import { Button } from 'components/button';
import useProgram from 'hooks/queries/useProgram';
import { useStore } from 'stores/Store';

const PersonProgram = () => {
  const { push } = useHistory();
  const { fetchProgram, loading } = useProgram();
  const { entityId, groupId, sporterId, programId, exerciseId } = useParams();
  const program = useStore(state => state.program);

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
  ]);
  const { addMenuItems, removeAllMenuItems, menuItems } = useMenuContext();
  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM
  ]);

  useEffect(() => {
    addMenuItems([
      editProgramMenuItem(),
      deleteProgramMenuItem(),
      <DownloadExerciseProgramButton
        key={`download-exercise-program`}
        programId={programId}
      />
    ]);

    return () => {
      removeAllMenuItems();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (programId) {
      fetchProgram({ variables: { id: programId } });
    }
  }, [programId]);

  const deleteProgramMenuItem = () => {
    return (
      <RemoveProgramButton
        key={`delete-program`}
        menu
        entityId={entityId}
        programId={programId}
      >
        <FormattedMessage {...messages.exercisesRemoveProgramButton} />
      </RemoveProgramButton>
    );
  };

  const editProgramMenuItem = () => {
    return (
      <Button
        key={`edit-program`}
        menu
        onClick={() =>
          push(
            generatePath(
              ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
              {
                entityId,
                programId
              }
            )
          )
        }
      >
        <FormattedMessage {...messages.exercisesEditProgramButton} />
        <Icon id="edit" />
      </Button>
    );
  };

  const handleExerciseClick = exerciseId => {
    push(
      generatePath(
        ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
        {
          entityId,
          groupId,
          sporterId,
          programId,
          exerciseId: exerciseId
        }
      )
    );
  };

  const exercisesTab = () => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM, {
        entityId,
        groupId,
        sporterId,
        programId
      })
    );
  };

  const feedbackTab = () => {
    push(
      generatePath(
        ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
        {
          entityId,
          groupId,
          sporterId,
          programId
        }
      )
    );
  };

  if (loading || !program?.id) {
    return (
      <Panel active={active.isExact}>
        <Loader />
      </Panel>
    );
  }

  return (
    <Panel active={active.isExact}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY, {
              entityId,
              groupId
            })}
          >
            <FormattedMessage {...messages.breadcrumbSporters} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS, {
              entityId,
              groupId,
              sporterId
            })}
          >
            <FormattedMessage {...messages.breadcrumbPrograms} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {program.title}
            <MenuWrapper trigger={<PanelTitleButton />}>
              {menuItems}
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesProgramSubTitle}
              values={{
                startdate: getLocalDateFormat(program.startdate),
                duration: program.duration
              }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        {loading && <Loader />}

        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab onClick={exercisesTab}>
              <FormattedMessage {...messages.tabExercises} />
            </Tab>
            <Tab onClick={feedbackTab}>
              <FormattedMessage {...messages.tabFeedback} />
            </Tab>
          </TabList>
          <TabPanel>
            <ExercisePanel
              exercises={program.exercises}
              exerciseId={exerciseId}
              onClick={handleExerciseClick}
              exerciseGroup={program}
            />
          </TabPanel>
          <TabPanel>
            {program?.id && (
              <TabPanelBody withScroller>
                <ExerciseFeedbackPanel program={program} personId={sporterId} />
              </TabPanelBody>
            )}
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default PersonProgram;
