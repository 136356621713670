import { Fragment, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { generatePath, useRouteMatch, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ROUTE_USER_SESSIONS_ENTITY } from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { PanelBody } from 'components/panel';
import messages from 'messages';
import { TabList, TabPanel, Tabs } from 'components/tabs';
import TabLink from 'components/tabs/TabLink';
import UserSessions from 'containers/pages/person/sessions/UserSessions';
import { useUserContext } from 'contexts/UserContext';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';

const Overview = () => {
  const { entityId } = useParams();
  const { loading, error, getData, sessions } = useUserContext();

  const active = useRouteMatch([ROUTE_USER_SESSIONS_ENTITY]);

  useEffect(() => {
    const fetchData = async () => {
      await getData();
    };
    fetchData().catch(error => {
      Sentry.captureException(error);
    });
  }, []);

  if (loading) return <Loader />;
  if (error) return <RetryPanel />;

  return (
    <Panel active={active?.isExact}>
      <Fragment>
        <PanelHeader>
          <PanelTitleWrapper>
            <PanelTitle>
              <FormattedMessage {...messages.titleSessions} />
            </PanelTitle>
            <PanelSubtitle>
              <FormattedMessage {...messages.subtitleAthleteSessions} />
            </PanelSubtitle>
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          <Tabs>
            <TabList>
              <TabLink
                key="session"
                to={generatePath(ROUTE_USER_SESSIONS_ENTITY, {
                  entityId
                })}
              >
                <FormattedMessage {...messages.tabSessionsSessions} />
              </TabLink>
            </TabList>
            <TabPanel key="session">
              {sessions === null ? (
                <Loader />
              ) : (
                <UserSessions entityId={entityId} />
              )}
            </TabPanel>
          </Tabs>
        </PanelBody>
      </Fragment>
    </Panel>
  );
};

export default Overview;
