import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Panel, PanelBody } from 'components/panel';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import FieldInput from 'components/input/FieldInput';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { useSessionContext } from 'contexts/SessionContext';
import TrainingSelectTests from './TrainingSelectTests';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import ProgramForm from 'containers/pages/exercises/programs/ProgramForm';
import Program from 'models/Program';
import SelectedExercisesCard from 'containers/partials/cards/SelectedExercisesCard';
import { NoteTypes } from 'constants.js';
import { useQuery } from '@apollo/client';
import { QUERY_GET_PROGRAM } from 'services/aws/programs-query';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { StoreContext } from 'index';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useStore } from 'stores/Store';

function TrainingAdvice({ sporterId }) {
  const {
    authStore: { entityId }
  } = useContext(StoreContext);
  const { programId } = useParams();
  const intl = useIntl();
  const { session } = useSessionContext();

  const selectedProgramExercises = useStore(
    state => state.selectedProgramExercises
  );
  const setSelectedProgramExercises = useStore(
    state => state.setSelectedProgramExercises
  );
  const selectProgramExercises = useStore(
    state => state.selectProgramExercises
  );

  const { setModalData } = usePreviewModalContext();
  const [program, setProgram] = useState(new Program({}));
  const [edit, setEdit] = useState(true);

  const { data } = useQuery(QUERY_GET_PROGRAM, {
    variables: { id: programId }
  });

  useEffect(() => {
    if (data?.getExerciseProgram) {
      const programData = new Program({ ...data.getExerciseProgram });
      setProgram(programData);
      setSelectedProgramExercises([...programData.exercises]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onCancel = () => {
    setSelectedProgramExercises([...program.exercises]);
    setEdit(false);
  };

  return (
    <>
      <Panel>
        <PanelBody style={{ height: '100%' }}>
          <TrainingSelectTests
            session={session}
            entityId={entityId}
            sporterId={sporterId}
            edit={edit}
          />
        </PanelBody>
      </Panel>

      <Panel>
        <ProgramForm
          entityId={entityId}
          program={program}
          onComplete={() => setEdit(false)}
          style={{ height: '100%' }}
        >
          {({ submitForm, resetForm, handleChange }) => (
            <PanelBody style={{ position: 'sticky', top: -48 }}>
              <Card secondary style={{ flex: 0 }}>
                <CardHeader secondary>
                  {/*
                      // This (.o-flex) wrapper is only needed if we are in a double-panel tab view:
                      // Tabs fullWidth
                   */}
                  <div className="o-flex o-flex--justify-space-between u-margin-bottom-none">
                    <CardHeaderTitle>
                      <FormattedMessage
                        {...messages.preventionTrainingProgramTitle}
                      />
                    </CardHeaderTitle>
                    <FormEditHeaderButtons
                      edit={edit}
                      setEdit={edit => setEdit(edit)}
                      type={'button'}
                      onSubmit={submitForm}
                      onCancel={resetForm}
                    />
                  </div>
                </CardHeader>
                <CardBody secondary>
                  <div className="o-layout">
                    <div className="o-layout__item">
                      <FieldInput
                        id="title"
                        name="title"
                        placeholder={intl.formatMessage(
                          messages.programLabelName
                        )}
                        readOnly={!edit}
                      >
                        <FormattedMessage {...messages.programLabelName} />
                      </FieldInput>
                    </div>
                    <div className="o-layout__item u-2-of-3">
                      <FieldDatePicker
                        id="startdate"
                        name="startdate"
                        icon="calendar"
                        placeholder={intl.formatMessage(
                          messages.programLabelStartDate
                        )}
                        readOnly={!edit}
                        disabled={!edit}
                        onChange={handleChange}
                        required
                      >
                        <FormattedMessage {...messages.programLabelStartDate} />
                      </FieldDatePicker>
                    </div>
                    <div className="o-layout__item u-1-of-3 o-flex--align-end o-flex">
                      <FieldInput
                        id="duration"
                        name="duration"
                        placeholder={intl.formatMessage(
                          messages.programLabelWeeks
                        )}
                        readOnly={!edit}
                      >
                        <FormattedMessage {...messages.programLabelWeeks} />
                      </FieldInput>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <SelectedExercisesCard
                entityId={entityId}
                edit={edit}
                onEdit={edit => setEdit(edit)}
                onPreview={exercise =>
                  setModalData({ type: 'exercise', data: exercise })
                }
                noteType={NoteTypes.PROGRAM}
                editableNotes={false}
                onCancel={onCancel}
                selectedExercises={selectedProgramExercises}
                onSelectedExercisesChange={exercise => {
                  selectProgramExercises(exercise);
                }}
              />
            </PanelBody>
          )}
        </ProgramForm>
      </Panel>
    </>
  );
}

export default TrainingAdvice;
