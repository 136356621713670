import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TAGS } from 'services/aws/tags-query';
import { useStore } from 'stores/Store';

const useTags = ({ entityId }) => {
  const { setTags } = useStore(state => state);
  const [getTags, { loading, error }] = useLazyQuery(QUERY_GET_TAGS, {
    variables: { entityId }
  });

  const fetchTags = async () => {
    const { data } = await getTags();

    if (data?.getTagsByEntity) {
      const tagsData = data.getTagsByEntity.map(tag => ({
        id: tag.id,
        label: tag.label,
        value: tag.label,
        parentId: tag.parentId,
        description: tag.description,
        isGroup: tag.isGroup || false
      }));
      setTags(tagsData);
    }
  };

  return { loading, error, fetchTags };
};

export default useTags;
