import * as Sentry from '@sentry/react';
import { isNumber } from 'utils/math';
import parseJSON from 'date-fns/parseJSON';

export default class TestItemResult {
  id;
  title;
  category;
  copy;
  unit;
  calculation;
  input_type;
  input_type_parameters;
  order;
  showDecimals;
  showScore;
  showPercentage;
  best_value;
  minimum;
  maximum;
  laterality;
  dominant_only;
  isBilateral = false;
  sides;
  trials;
  values;
  result;
  benchmarked;
  hidden;
  tags;
  testId;
  testTitle;
  sessionDate;
  testDate;
  constructor({
    id,
    testId,
    title,
    category,
    copy,
    unit,
    calculation,
    input_type,
    input_type_parameters,
    order,
    showDecimals,
    showScore,
    showPercentage,
    best_value,
    minimum,
    maximum,
    laterality,
    dominant_only,
    trials,
    values,
    result,
    benchmarked,
    tags,
    hidden,
    sessionDate,
    testDate,
    test
  }) {
    this.id = id;
    this.testId = testId;
    this.title = title;
    this.category = category;
    this.copy = copy;
    this.unit = unit && unit.toLowerCase().includes('none') ? '' : unit;
    this.calculation = calculation;
    this.input_type =
      input_type === 'Select with Options' ? 'select_with_options' : input_type;
    try {
      this.input_type_parameters = input_type_parameters
        ? JSON.parse(input_type_parameters)
        : {};
    } catch (e) {
      this.input_type_parameters = input_type_parameters || {};
    }
    this.order = order;
    this.showDecimals = showDecimals;
    this.showScore = showScore;
    this.showPercentage = showPercentage;
    this.best_value = best_value; // 0: less is better, 1: more is better
    this.minimum = minimum;
    this.maximum = maximum;
    this.laterality = laterality;
    this.dominant_only = dominant_only;
    this.trials = trials;
    this.hidden = hidden ?? false;
    this.values = values; // RAW input value
    this.result = result; // Calculated score
    this.benchmarked = benchmarked;
    this.tags = tags;
    this.sessionDate = sessionDate ? parseJSON(sessionDate) : null;
    this.testDate = testDate;

    this.testTitle = test?.title ?? '';

    this.isOption = this.input_type === 'select_with_options';

    // check if we have benchmark object
    this.hasBenchmarked = this.benchmarked
      ? Object.keys(this.benchmarked).length > 0
      : false;

    // check if we have benchmark object with values
    this.hasBenchmarkedValues = this.benchmarked
      ? Object.values(this.benchmarked).some(val => isNumber(val?.percentage))
      : false;

    const hasRawValues = this.values && Object.keys(this.values).length > 0;
    const hasSelectOption =
      this.input_type === 'select_with_options' &&
      this.result &&
      Object.keys(this.result).length > 0;

    this.hasValues =
      this.hasBenchmarkedValues || hasRawValues || hasSelectOption;

    try {
      if (this.result) {
        Object.keys(this.result).forEach(side => {
          if (this.input_type === 'select_with_options') {
            // assign the correct option to the result param
            this.result[side] = [
              this.input_type_parameters.options.find(
                o => o.value === this.result[side][0]
              )
            ];
          } else {
            if (!Array.isArray(this.result)) {
              if (isNumber(this.result[side][0])) {
                this.result[side] = [
                  Number(this.result[side][0]).toFixed(this.showDecimals)
                ];
              } else if (this.result[side].length > 1) {
                this.result[side] = [...this.result[side]];
              } else {
                this.result[side] = [this.result[side][0]];
              }
            } else {
              this.result[side] = [];
            }
          }
        });
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    if (this.laterality && this.laterality.includes('bilateral_')) {
      this.isBilateral = true;
      this.sides = {
        0: Array(this.trials).fill(''),
        1: Array(this.trials).fill('')
      };
    } else {
      this.sides = { 2: Array(this.trials).fill('') };
    }
  }

  static get modelName() {
    return 'TestItemResult';
  }
}
