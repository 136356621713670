export const capitalize = str => {
  const prefix = str.substring(0, 1);
  const rest = str.substring(1);
  return `${prefix.toUpperCase()}${rest}`;
};

// A function for the construction of the translation
export const camelCaseify = word => {
  const sentence = word.replace('_', ' ');
  return sentence
    .split(' ')
    .map(e => capitalize(e))
    .join('');
};
