import React, { useContext, useState } from 'react';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { Card, CardBody, CardFooter } from 'components/card';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { format } from 'date-fns';
import AddIllnessModal from 'containers/pages/rehab/illness/AddIllnessModal';
import { calculateInjuriesSubtitle } from 'utils/date';
import { useMedicalContext } from 'contexts/MedicalContext';
import { StoreContext } from 'index';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL } from 'routes/RouteList';
import { DATE_LOCALE, PersonInjuryType } from 'constants.js';
import formatDistance from 'date-fns/formatDistance';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const SporterMedicalIllnesses = () => {
  const { entityId, groupId, illnessId } = useParams();
  const intl = useIntl();
  const { push } = useHistory();
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const person = useEntityContext(s => s.person);
  const { medical } = useMedicalContext();
  const defaultListLength = 10;
  const [illnessesListCount, setIllnessesListCount] =
    useState(defaultListLength);
  const [illnessModal, setIllnessModal] = useState(false);

  const onClickIllnessHandler = illness => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL, {
        entityId,
        groupId,
        sporterId: person.id,
        medicalId: medical.id,
        illnessId: illness.id
      })
    );
  };

  return (
    <Card reports>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleIllnesses} />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            {calculateInjuriesSubtitle(
              medical?.illnesses,
              locale,
              intl,
              PersonInjuryType.ILLNESS
            )}
          </CardHeaderSubtitle>
        </CardHeaderText>
        <Button rounded primary onClick={() => setIllnessModal(true)}>
          <Icon id="add-white" />
          <FormattedMessage {...messages.buttonAddIllness} />
        </Button>
      </CardHeader>
      <CardBody secondary>
        <List>
          <ListBody>
            {medical?.illnesses &&
              medical.illnesses.length > 0 &&
              medical.illnesses
                .slice(0, illnessesListCount)
                .map(personIllness => (
                  <ListItem
                    small
                    key={personIllness.id}
                    active={personIllness.id === illnessId}
                    onClick={() => onClickIllnessHandler(personIllness)}
                  >
                    <ListItemLabelWrapper>
                      <ListItemLabel label>{personIllness.title}</ListItemLabel>
                      {personIllness.injuryEndDate ? (
                        <ListItemLabel label>
                          {formatDistance(
                            personIllness.injuryDate,
                            personIllness.injuryEndDate,
                            { locale: DATE_LOCALE[locale] }
                          )}{' '}
                          - {format(personIllness.injuryDate, 'yyyy')}
                        </ListItemLabel>
                      ) : (
                        <ListItemLabel label>
                          {format(personIllness.injuryDate, 'yyyy')}
                        </ListItemLabel>
                      )}
                    </ListItemLabelWrapper>
                  </ListItem>
                ))}
          </ListBody>
        </List>
      </CardBody>
      <CardFooter>
        {medical?.illnesses && medical.illnesses.length > defaultListLength && (
          <Button
            rounded
            grey
            onClick={() => {
              setIllnessesListCount(
                illnessesListCount ? undefined : defaultListLength
              );
            }}
          >
            <Icon id="dropdown-closed" />
            <FormattedMessage
              {...messages.buttonShowIllnesses}
              values={{
                showAll: Boolean(illnessesListCount)
              }}
            />
          </Button>
        )}
      </CardFooter>

      {illnessModal && (
        <AddIllnessModal
          entityId={entityId}
          sporterId={person.id}
          medicalId={medical.id}
          onClose={() => {
            setIllnessModal(false);
          }}
        />
      )}
    </Card>
  );
};

export default SporterMedicalIllnesses;
