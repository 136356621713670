import { useLazyQuery } from '@apollo/client';
import Template from 'models/Template';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import { getFilteredEntities } from 'utils/search';
import { useStore } from 'stores/Store';

const useTrainings = entityId => {
  const setTrainings = useStore(state => state.setTrainings);
  const trainingsRef = useStore(state => state.trainingsRef);
  const setTrainingsRef = useStore(state => state.setTrainingsRef);
  const setTrainingsFilter = useStore(state => state.setTrainingsFilter);

  const [getTrainings, { loading, error }] = useLazyQuery(QUERY_GET_TEMPLATES);

  const fetchTrainings = async options => {
    const { data } = await getTrainings({
      variables: { entityId },
      ...options
    });
    if (data?.getExerciseProgramTemplates) {
      const trainings = data.getExerciseProgramTemplates.map(
        template => new Template({ ...template })
      );
      setTrainingsRef(trainings);
      setTrainings(trainings);

      return trainings;
    }
  };

  const filterTrainings = async filter => {
    setTrainingsFilter(filter);
    const trainings = [...trainingsRef];

    setTrainings(
      getFilteredEntities(trainings, filter?.search || '', filter.tags || [])
    );
  };

  return {
    loading,
    error,
    fetchTrainings,
    filterTrainings
  };
};

export default useTrainings;
