import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import ListItem from 'components/list/list-item/ListItem';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import { format, formatDistanceToNow, isFuture, isPast } from 'date-fns';
import { DATE_LOCALE } from 'constants.js';

const ProgramsListItem = ({
  onClick,
  program: { title, persons, startdate, endDate, status, daysLeft },
  type,
  active,
  locale
}) => {
  const intl = useIntl();

  const getDateString = () => {
    let dateString = '';
    if (isFuture(startdate)) {
      dateString = `${format(startdate, 'dd/LL/yyyy')} > ${format(
        endDate,
        'dd/LL/yyyy'
      )}`;
    }
    if (isPast(startdate) && isFuture(endDate)) {
      if (daysLeft <= 14) {
        status = 'inProgress';
      }
      dateString = intl.formatMessage(
        messages.exercisesProgramStatusDateInProgress,
        {
          time: formatDistanceToNow(endDate, {
            locale: DATE_LOCALE[locale]
          })
        }
      );
    }
    if (isPast(endDate)) {
      status = 'done';
      dateString = intl.formatMessage(messages.exercisesProgramStatusDateEnded);
    }

    return dateString;
  };

  return (
    <ListItem
      active={active}
      programs
      done={status === 'done'}
      inProgress={status === 'inProgress'}
      onClick={onClick}
    >
      <ListItemLabelWrapper>
        <ListItemLabel label>{title}</ListItemLabel>
        {persons.length > 0 && type !== 'user' && (
          <ListItemLabel info middleColumn>
            {`${persons.length} `}
            <FormattedMessage {...messages.exercisesAssignSportersLabel} />
          </ListItemLabel>
        )}
        {startdate && getDateString() && (
          <ListItemLabel info>{getDateString()}</ListItemLabel>
        )}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default ProgramsListItem;
