import { useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import {
  PanelBody,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel';
import { PanelTitle } from 'components/panel/panel-title';
import { Button } from 'components/button';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
  ROUTE_EXERCISES_TEMPLATES_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS_EDIT
} from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tabs, TabPanelBody } from 'components/tabs';
import { TabList, TabPanel } from 'react-tabs';
import Loader from 'components/loader/Loader';
import { MenuWrapper } from 'components/menu';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import useTemplate from 'hooks/queries/useTemplate';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import { useStore } from 'stores/Store';
import TabLink from 'components/tabs/TabLink';
import TemplateExercisesTab from 'containers/pages/exercises/templates/TemplateExercisesTab';
import TemplateDetailsTab from 'containers/pages/exercises/templates/TemplateDetailsTab';
import useTabIndex from 'hooks/utils/useTabIndex';
import { TemplateType } from 'constants';
import RemoveExerciseTemplateButton from 'containers/partials/buttons/RemoveExerciseTemplateButton';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import AddExercisesModal from 'containers/pages/exercises/exercises/AddExercisesModal';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import { useNotificationQueue } from 'components/notification';
import useTemplates from 'hooks/queries/useTemplates';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';

const Template = () => {
  const { entityId, templateId } = useParams();
  const { push } = useHistory();
  const notification = useNotificationQueue();
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const [programModalData, setProgramModalData] = useState(null);
  const { fetchTemplate, loading, error } = useTemplate();
  const { fetchTemplates } = useTemplates(entityId);
  const template = useStore(state => state.template);
  const selectedTemplateExercises = useStore(
    state => state.selectedTemplateExercises
  );
  const setSelectedTemplateExercises = useStore(
    state => state.setSelectedTemplateExercises
  );
  const templateSortableActive = useStore(
    state => state.templateSortableActive
  );
  const setTemplateSortableActive = useStore(
    state => state.setTemplateSortableActive
  );
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
    [
      ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS,
      ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS_EDIT
    ]
  ]);
  const active = useRouteMatch([
    ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
    ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS,
    ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS_EDIT
  ]);

  const [addExercisesModal, setAddExercisesModal] = useState(null);

  useEffect(() => {
    if (templateId) {
      fetchTemplate({ variables: { templateId } });
    }
    return () => {
      setTemplateSortableActive(false);
    };
  }, [templateId]);

  const onUseHandler = template => {
    // show use template modal
    setProgramModalData({
      entityId,
      title: '',
      startdate: '',
      duration: '',
      description: '',
      exerciseIds: template.exercises.map(e => e.id),
      templateId: template.id
    });
  };

  const onDeletedHandler = async () => {
    notification.add(templateId, {
      message: intl.formatMessage(messages.messageRemovedTemplateSuccess)
    });
    await fetchTemplates();
    push(
      generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY, {
        entityId
      })
    );
  };

  const onAddedHandler = () => {
    setAddExercisesModal(false);
  };

  if (loading || !template)
    return (
      <Panel active={active.isExact}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <>
      <Panel active={active.isExact}>
        <RefactoredPanelHeader>
          <RefactoredPanelTitleWrapper>
            <Breadcrumbs>
              <Breadcrumb
                to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
              >
                <FormattedMessage {...messages.breadcrumbOverview} />
              </Breadcrumb>
              <Breadcrumb
                to={ROUTE_EXERCISES_TEMPLATES_ENTITY.replace(
                  ':entityId',
                  entityId
                )}
              >
                <FormattedMessage {...messages.breadcrumbTemplates} />
              </Breadcrumb>
            </Breadcrumbs>
            <PanelTitle noSubtitle>{template.title}</PanelTitle>
          </RefactoredPanelTitleWrapper>
          <PanelTitleButtonsGroup>
            <MenuWrapper trigger={<PanelTitleButton />}>
              {ability.can('update', template) && (
                <Button menu onClick={() => setAddExercisesModal(template)}>
                  <Icon id="add" />
                  <FormattedMessage {...messages.exercisesAddExercisesButton} />
                </Button>
              )}
              <Button menu onClick={() => onUseHandler(template)}>
                <FormattedMessage
                  {...messages.exercisesSaveTemplateAsProgramButton}
                />
                <Icon id="use-template" />
              </Button>
              {ability.can('update', template) && (
                <RemoveExercisesFromTemplateButton
                  disabledMessage={
                    templateSortableActive
                      ? intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledDragging
                        )
                      : intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledNotSelected
                        )
                  }
                  entityId={entityId}
                  type="template"
                  exerciseIds={selectedTemplateExercises.map(e => e.id)}
                  templateId={templateId}
                  updated={async () => {
                    fetchTemplate({
                      variables: { templateId },
                      fetchPolicy: 'network-only'
                    });
                    fetchTemplates({
                      variables: { entityId, type: TemplateType.TEMPLATE },
                      fetchPolicy: 'network-only'
                    });
                    setSelectedTemplateExercises([]);
                  }}
                  disabled={selectedTemplateExercises.length === 0}
                />
              )}
              {ability.can('update', template) && (
                <Button
                  menu
                  onClick={() => setTemplateSortableActive(true)}
                  disabled={
                    selectedTemplateExercises.length > 0 ||
                    templateSortableActive
                  }
                >
                  {templateSortableActive ? (
                    <FormattedMessage
                      {...messages.templatesSortExercisesDisabledDragging}
                    />
                  ) : (
                    <FormattedMessage {...messages.sortTemplateButton} />
                  )}
                  <Icon id="arrange" strokeColor="color-neutral-dark" />
                </Button>
              )}
              {ability.can('delete', template) && (
                <RemoveExerciseTemplateButton
                  menu
                  templateId={template.id}
                  collectionType={TemplateType.TEMPLATE}
                  entityId={entityId}
                  onDeleted={onDeletedHandler}
                >
                  <FormattedMessage
                    {...messages.exercisesRemoveTemplateButton}
                  />
                </RemoveExerciseTemplateButton>
              )}
            </MenuWrapper>
          </PanelTitleButtonsGroup>
        </RefactoredPanelHeader>
        <PanelBody>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
              <TabLink
                to={generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE, {
                  entityId,
                  templateId
                })}
              >
                <FormattedMessage {...messages.exercisesTitle} />
              </TabLink>
              <TabLink
                to={generatePath(
                  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS,
                  {
                    entityId,
                    templateId
                  }
                )}
              >
                <FormattedMessage {...messages.exerciseDetails} />
              </TabLink>
            </TabList>

            <TabPanel>
              <TabPanelBody
                withHeader
                style={{
                  //   overflow: 'visible',
                  padding: 0,
                  margin: '0 -16px',
                  maxHeight: '100%'
                }}
              >
                <TemplateExercisesTab
                  onAddExercises={template => setAddExercisesModal(template)}
                />
              </TabPanelBody>
            </TabPanel>
            <TabPanel>
              <TabPanelBody>
                <TemplateDetailsTab />
              </TabPanelBody>
            </TabPanel>
          </Tabs>
        </PanelBody>
        {programModalData && (
          <AddProgramModal
            entityId={entityId}
            program={programModalData}
            destiny={{
              path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
              variables: {}
            }}
            onClose={() => setProgramModalData(null)}
            fromTemplate
          />
        )}
      </Panel>
      {ability.can('update', template) && addExercisesModal && (
        <TemplateForm
          entityId={entityId}
          template={template}
          type={TemplateType.TEMPLATE}
          onComplete={() => setAddExercisesModal(false)}
        >
          {() => (
            <AddExercisesModal
              entityId={entityId}
              template={template}
              onClose={() => {
                setAddExercisesModal(false);
              }}
              onAdded={() => onAddedHandler()}
            />
          )}
        </TemplateForm>
      )}
    </>
  );
};

export default observer(Template);
