import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import { MUTATION_ADD_PERSONS_TO_PROGRAM } from 'services/aws/programs-query';
import PersonsTabs from 'containers/pages/persons/PersonsTabs';
import { useNotificationQueue } from 'components/notification';
import useProgram from 'hooks/queries/useProgram';
import usePrograms from 'hooks/queries/usePrograms';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function AssignSportersToProgramModal({ entityId, program, onClose, onDone }) {
  const intl = useIntl();
  const notification = useNotificationQueue();

  const { fetchProgram } = useProgram();
  const { fetchPrograms } = usePrograms(entityId);

  const selectedPersons = useEntityContext(s => s.selectedPersons);
  const clearSelected = useEntityContext(s => s.clearSelected);
  const setExcludedPersons = useEntityContext(s => s.setExcludedPersons);

  const [assignPersons, { loading }] = useMutation(
    MUTATION_ADD_PERSONS_TO_PROGRAM
  );

  useEffect(() => {
    return () => {
      clearSelected();
    };
  }, []);

  useEffect(() => {
    if (program) {
      setExcludedPersons(program.persons);
    }
  }, [program]);

  const onAddPersonsHandler = async () => {
    if (program.id) {
      try {
        const { data } = await assignPersons({
          variables: {
            exerciseProgramId: program.id,
            personIds: selectedPersons.map(person => person.id)
          }
        });

        fetchProgram({
          variables: { id: program.id },
          fetchPolicy: 'network-only'
        });
        fetchPrograms({
          variables: { entityId },
          fetchPolicy: 'network-only'
        });

        notification.add(data.addPersonsToExerciseProgram.id, {
          message: intl.formatMessage(messages.messagePersonsAddedToProgram)
        });
      } catch (e) {
        Sentry.captureException(e);
      }
      clearSelected();
      if (onDone) onDone();
    }
  };

  const onCloseHandler = () => {
    clearSelected();
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.programDetailSelectAthletes} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal bodyWithTabs>
        <PersonsTabs onModal showPersonStatus={false} checkableGroups={false} />
      </CardBody>
      <CardFooter modal extraClassNames="c-card__footer--modal-larger">
        <ButtonsGroup>
          <Button secondary onClick={() => onCloseHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            primary
            disabled={selectedPersons.length === 0 || loading}
            onClick={() => onAddPersonsHandler()}
          >
            <FormattedMessage
              {...messages.addSelectedPersonsButton}
              values={{ count: selectedPersons.length }}
            />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
}

export default AssignSportersToProgramModal;
