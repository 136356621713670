import classNames from 'classnames';

const ListItemLabel = props => {
  const {
    label,
    labelNotePreview,
    info,
    danger,
    middleColumn,
    alignRight,
    progress,
    supertitle,
    children,
    extraClassNames,
    style,
    virtualizedWithSubList,
    ...rest
  } = props;

  return (
    <div
      className={classNames('c-list__item-text', extraClassNames, {
        'c-list__item-main-text': label,
        'c-list__item-main-text c-list__item-main-text--note-preview':
          labelNotePreview,
        'c-list__item-secondary-text': info,
        'c-list__item-danger-text': danger,
        'c-list__item-middle': middleColumn,
        'o-flex--justify-end u-padding-right-medium': alignRight,
        'c-list__item-progress': progress,
        'c-list__item-label-with-supertitle':
          supertitle && supertitle.length > 0,
        'c-list__item-label-virtualized': virtualizedWithSubList
      })}
      style={style}
      {...rest}
    >
      {supertitle && (
        <span className="c-list__item-supertitle">{supertitle}</span>
      )}
      {children}
    </div>
  );
};

export default ListItemLabel;
