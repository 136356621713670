import React, { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import { REMOVE_PERSON_FROM_ENTITY } from 'services/aws/user-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { StoreContext } from 'index';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import usePerson from 'hooks/queries/usePerson';

const RemoveSporterFromGroupButton = ({
  entityId,
  groupId,
  personId,
  children,
  ...props
}) => {
  const {
    authStore: { uiState }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { fetchPerson } = usePerson({ personId });
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });

  return (
    <ConfirmMutationButton
      {...props}
      mutation={REMOVE_PERSON_FROM_ENTITY}
      confirmTitle={messages.modalConfirmRemoveSporterTitle}
      confirmMessage={messages.modalConfirmRemoveSporterMessage}
      variables={{
        personId,
        entityId: groupId
      }}
      update={async (cache, { data: { removePersonFromEntity } }) => {
        if (removePersonFromEntity) {
          uiState.increasePendingRequest();
          await fetchPerson(personId);
          await fetchEntities(groupId);
          uiState.decreasePendingRequest();
          push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId }));
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveSporterFromGroupButton;
