import { makeAutoObservable } from 'mobx';
import ModalData, { MODAL_TYPES } from '../models/ModalData';

class UiState {
  locale = 'en';
  translations = 'en';
  pendingRequestCount = 0;
  isMenuOpen = false;
  modalData = null;
  errorModalData = null;
  isLocaleReady = false;
  homeRoute = '/';
  redirectRoute = null;

  constructor() {
    let storage = localStorage.uiState;
    if (storage) {
      storage = JSON.parse(storage);
      this.locale = storage.locale ? storage.locale : 'en';

      this.translations = storage.translations
        ? storage.translations
        : this.locale;
    }

    makeAutoObservable(this);
  }

  setLocale(locale) {
    this.locale = locale ?? this.locale;
    this.persistState({ locale: this.locale });
    this.isLocaleReady = true;

    if (document.documentElement.lang !== this.locale) {
      document.documentElement.lang = this.locale;
    }
  }

  setTranslations(translations) {
    this.translations = translations ?? this.translations;
    this.persistState({ translations: this.translations });
  }

  setRedirectRoute(route) {
    this.redirectRoute = route;
  }

  setHomeRoute(route) {
    this.homeRoute = route;
  }

  get appIsInSync() {
    return this.pendingRequestCount === 0;
  }

  showModal({
    title,
    message,
    values,
    okLabel,
    okHandler,
    dismissHandler,
    dismissLabel,
    dismissButton,
    mutationData,
    type = MODAL_TYPES.ALERT
  }) {
    const modalData = new ModalData({
      title,
      message,
      values,

      okLabel,
      okHandler: () => {
        if (okHandler && typeof okHandler === 'function') okHandler();
        this.hideModal();
      },
      dismissLabel,
      dismissButton,
      dismissHandler: () => {
        if (dismissHandler && typeof dismissHandler === 'function')
          dismissHandler();
        this.hideModal();
      },
      mutationData,
      type
    });
    this.setModalData(modalData);
  }

  setModalData(modalData) {
    this.modalData = modalData;
  }

  hideModal() {
    this.modalData = null;
  }

  showErrorModal({ title, message, values, dismissHandler, dismissLabel }) {
    const modalData = new ModalData({
      title,
      message,
      values,
      dismissLabel,
      dismissHandler: () => {
        if (dismissHandler && typeof dismissHandler === 'function')
          dismissHandler();
        this.hideModal();
      }
    });
    this.setErrorModalData(modalData);
  }

  setErrorModalData(modalData) {
    this.errorModalData = modalData;
  }

  hideErrorModal() {
    this.errorModalData = null;
  }

  increasePendingRequest() {
    this.pendingRequestCount += 1;
  }

  decreasePendingRequest() {
    if (this.pendingRequestCount > 0) {
      this.pendingRequestCount -= 1;
    }
  }

  persistState(data) {
    let storage = localStorage.getItem('uiState');
    storage = storage ? JSON.parse(storage) : {};
    storage = { ...storage, ...data };
    try {
      localStorage.setItem('uiState', JSON.stringify(storage));
    } catch (e) {}
  }
}

export default UiState;
