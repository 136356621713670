import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  generatePath,
  matchPath,
  useHistory,
  withRouter
} from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';
import Overview from './Overview';

import {
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY_SESSION_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_TEMPLATES,
  ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION,
  ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
  ROUTE_SESSIONS
} from 'routes/RouteList';
import Session from './Session';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { SessionContextProvider } from 'contexts/SessionContext';
import { StoreContext } from 'index';
import EditPrevention from 'containers/pages/prevention/EditPrevention';
import EditPreventionTemplate from 'containers/pages/prevention/EditPreventionTemplate';
import { TestSetContextProvider } from 'contexts/TestSetContext';
import { TestsProvider } from 'contexts/TestsContext';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import { EditableContextProvider } from 'contexts/EditableContext';
import { MenuContextProvider } from 'contexts/MenuContext';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import { SessionsContextProvider } from 'contexts/SessionsContext';
import SessionPerson from 'containers/pages/sessions/SessionPerson';
import { TestDataProvider } from 'contexts/TestDataContext';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';

const Sessions = () => {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);
  let location = useHistory();
  const entityStore = useRef(createEntityStore()).current;

  if (ability.cannot('read', 'Sessions')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  if (
    matchPath(location.pathname, {
      path: [ROUTE_SESSIONS_ENTITY_TEMPLATES]
    }) &&
    ability.cannot('read', 'Prevention')
  ) {
    return <Redirect to="/sessions" />;
  }

  return (
    <PreviewModalProvider entityId={user.rootEntityId}>
      <EditableContextProvider>
        <Switch>
          {ability.can('read', 'Prevention') && (
            <Route exact path={ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION}>
              <TestsProvider>
                <SessionContextProvider>
                  <EditPrevention />
                </SessionContextProvider>
              </TestsProvider>
            </Route>
          )}
          {ability.can('read', 'Prevention') && (
            <Route exact path={ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT}>
              <TestsProvider>
                <TestSetContextProvider>
                  <EditPreventionTemplate edit={true} />
                </TestSetContextProvider>
              </TestsProvider>
            </Route>
          )}
          <Route
            exact
            path={[
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
              ROUTE_SESSIONS_ENTITY_SESSION_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION,
              ROUTE_SESSIONS_ENTITY
            ]}
          >
            <SessionsContextProvider entityId={user.rootEntityId}>
              <Overview />
            </SessionsContextProvider>
          </Route>
          <Route
            path={ROUTE_SESSIONS}
            render={() => (
              <Redirect
                to={generatePath(ROUTE_SESSIONS_ENTITY, {
                  entityId: user.rootEntityId,
                  sessionsTab: 'sessions'
                })}
              />
            )}
          />
        </Switch>
        <Route exact path={[ROUTE_SESSIONS_ENTITY]}>
          <Panel
            emptyState
            emptyLabel={intl.formatMessage(messages.sessionsEmptyPanel)}
          />
        </Route>
        <Switch>
          <Route
            exact
            path={[
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
              ROUTE_SESSIONS_ENTITY_SESSION_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION
            ]}
          >
            <SessionContextProvider>
              <TestsProvider>
                <Session />
              </TestsProvider>
            </SessionContextProvider>
          </Route>
        </Switch>
        <Switch>
          {/*
          <Route
            exact
            path={ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER}
            render={props => (
              <MenuContextProvider>
                <SessionContextProvider>
                  <EntityStoreProvider value={entityStore}>
                    <AssignPersonsToSessionModal
                      entityId={props.match.params.entityId}
                      onClose={() => {
                        push(
                          generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                            entityId: props.match.params.entityId,
                            sessionsTab: props.match.params.sessionsTab,
                            sessionId: props.match.params.sessionId
                          })
                        );
                      }}
                      onDone={() => {
                        push(
                          generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS, {
                            entityId: props.match.params.entityId,
                            sessionsTab: props.match.params.sessionsTab,
                            sessionId: props.match.params.sessionId
                          })
                        );
                      }}
                    />
                  </EntityStoreProvider>
                </SessionContextProvider>
              </MenuContextProvider>
            )}
          />
          */}
          <Route
            exact
            path={[
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
              ROUTE_SESSIONS_ENTITY_SESSION_SPORTER
            ]}
          >
            <SessionContextProvider>
              <EntityStoreProvider value={entityStore}>
                <TestDataProvider>
                  <MenuContextProvider>
                    <SessionPerson />
                  </MenuContextProvider>
                </TestDataProvider>
              </EntityStoreProvider>
            </SessionContextProvider>
          </Route>
        </Switch>
      </EditableContextProvider>
    </PreviewModalProvider>
  );
};

export default withRouter(observer(Sessions));
