import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  withRouter
} from 'react-router-dom';
import {
  ROUTE_EXERCISES,
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_ENTITY_ALL_EXERCISES,
  ROUTE_EXERCISES_ENTITY_EXERCISE,
  ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT,
  ROUTE_EXERCISES_ENTITY_EXERCISES
} from 'routes/RouteList';
import Exercises from 'containers/pages/exercises/exercises/Exercises';
import Exercise from 'containers/pages/exercises/exercises/Exercise';
import { StoreContext } from 'index';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { useIntl } from 'react-intl';

const GSVExercises = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  return (
    <PreviewModalProvider entityId={user.rootEntityId}>
      <Switch>
        <Route
          exact
          path={[
            ROUTE_EXERCISES_ENTITY_EXERCISE,
            ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT,
            ROUTE_EXERCISES_ENTITY_EXERCISES
          ]}
        >
          <Exercises />
          <Switch>
            <Route
              path={[
                ROUTE_EXERCISES_ENTITY_EXERCISE,
                ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT
              ]}
            >
              <Exercise
                parentPath={ROUTE_EXERCISES_ENTITY_EXERCISE}
                crumbs={[
                  {
                    path: ROUTE_EXERCISES_ENTITY_EXERCISES,
                    label: intl.formatMessage(messages.breadcrumbExercises)
                  },
                  {
                    path: ROUTE_EXERCISES_ENTITY_EXERCISE,
                    label: intl.formatMessage(messages.breadcrumbExercise)
                  }
                ]}
              />
            </Route>
            <Route path={ROUTE_EXERCISES_ENTITY_EXERCISES}>
              <Panel
                emptyState
                emptyLabel={intl.formatMessage(messages.exercisesEmptyPanel)}
              />
            </Route>
          </Switch>
        </Route>
        <Route
          path={[ROUTE_EXERCISES_ENTITY]}
          exact
          render={() => {
            return (
              <Redirect
                to={generatePath(ROUTE_EXERCISES_ENTITY_ALL_EXERCISES, {
                  entityId: user.rootEntityId
                })}
              />
            );
          }}
        />
        <Route
          path={ROUTE_EXERCISES}
          exact
          render={() => {
            return <Redirect to={ROUTE_EXERCISES_ENTITY} />;
          }}
        />
      </Switch>
    </PreviewModalProvider>
  );
};

export default withRouter(GSVExercises);
