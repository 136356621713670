import classNames from 'classnames';

const MenuItemsGroup = props => {
  const { children, extraClassNames } = props;
  return (
    <ul
      className={classNames('c-menu-group o-list o-list-bare', extraClassNames)}
    >
      {children}
    </ul>
  );
};

export default MenuItemsGroup;
