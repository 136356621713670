import { useState, useEffect, Fragment } from 'react';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { Card, CardBody } from 'components/card';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { useTestsContext } from 'contexts/TestsContext';
import { TestListItem } from 'containers/partials/list-items/TestListItem';
import Loader from 'components/loader/Loader';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { groupTests } from 'utils/tests';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useUIDSeed } from 'react-uid';

function SessionTestsCard({ edit, style, extraClassNames, editPanel = false }) {
  const { testsState, testActions, loading } = useTestsContext();
  const { setModalData } = usePreviewModalContext();
  const [tests, setTests] = useState([]);
  const uidSeed = useUIDSeed();

  useEffect(() => {
    testActions.getTests();
  }, []);

  useEffect(() => {
    if (testsState.selectedTests) {
      setTests(groupTests([...testsState.selectedTests]));
    }
  }, [testsState.selectedTests]);

  if (loading) return <Loader />;

  return (
    <Card secondary style={style} extraClassNames={extraClassNames}>
      <CardHeader secondary extraClassNames={'o-flex--wrap'}>
        <CardHeaderTitle>
          <FormattedMessage {...messages.preventionSelectedTestsTitle} />
        </CardHeaderTitle>
      </CardHeader>
      <CardBody secondary>
        <Fragment>
          {Object.keys(tests).map((category, index) => (
            <List
              // dragNDropWithPreview
              key={category}
              preview
              style={{
                minWidth: '0',
                marginBottom: 0
              }}
            >
              <ListHeader oneTitle>
                <ListColumnTitle>{category}</ListColumnTitle>
              </ListHeader>
              <ListBody>
                {tests[category].length > 0 &&
                  tests[category].map(test => (
                    <TestListItem
                      uid={uidSeed(test.id)}
                      draggableDisabled
                      index={index}
                      key={test.id}
                      test={test}
                      checkbox={edit}
                      edit={edit}
                      checked
                      dependentEditable
                      onClick={() =>
                        setModalData({ type: 'testItem', data: test })
                      }
                      hideStatus
                      clickable
                    />
                  ))}
              </ListBody>
            </List>
          ))}
        </Fragment>

        {tests.length === 0 && (
          <Card reports={!editPanel}>
            <CardBody
              secondary
              extraClassNames="u-margin-bottom-none u-padding-vertical-huge"
            >
              <Message emptyState icon="test">
                <MessageText>
                  <FormattedMessage {...messages.labelScreeningNoTests} />
                </MessageText>
              </Message>
            </CardBody>
          </Card>
        )}
      </CardBody>
    </Card>
  );
}

export default SessionTestsCard;
