import { useEffect } from 'react';
import TagsSelect from 'components/input/TagsSelect';
import { useStore } from 'stores/Store';
import useTags from 'hooks/queries/useTags';

const Tags = ({
  entityId,
  label,
  selectedTags,
  edit = true,
  readOnly,
  disabled,
  inline,
  rounded,
  placeholder,
  noOptionsMessage,
  isClearable = false,
  creatable,
  isMulti,
  onChange,
  onInputChange,
  extraClassNames,
  dropdownDisabled,
  isSearchable,
  menuPlacement
}) => {
  const { fetchTags, loading } = useTags({ entityId });

  useEffect(() => {
    fetchTags();
  }, []);

  const { tags } = useStore(state => state);

  return (
    <TagsSelect
      extraClassNames={extraClassNames}
      label={label}
      options={!dropdownDisabled ? tags : []}
      value={
        selectedTags &&
        selectedTags.map(tag => tag && { ...tag, value: tag.label })
      }
      isMulti={isMulti}
      isClearable={isClearable}
      isLoading={loading}
      isSearchable={isSearchable}
      creatable={creatable}
      readOnly={!edit || readOnly}
      disabled={!edit || disabled}
      onChange={onChange}
      onInputChange={onInputChange}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      inline={inline}
      rounded={rounded}
      dropdownDisabled={dropdownDisabled}
      menuPlacement={menuPlacement}
    />
  );
};

export default Tags;
