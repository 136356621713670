import { forwardRef } from 'react';
import classNames from 'classnames';

const RefactoredListBody = props => {
  const { children, innerRef, style, extraClassNames, ...rest } = props;
  return (
    <ul className={classNames('r-list__body', extraClassNames)} style={style}>
      {children}
    </ul>
  );
};
export default RefactoredListBody;
