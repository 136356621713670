import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ROUTE_EXERCISES_COLLECTIONS_ENTITY,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES
} from 'routes/RouteList';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { TabList, TabPanel, Tabs } from 'components/tabs';
import useTabIndex from 'hooks/utils/useTabIndex';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import AddExercisesModal from 'containers/pages/exercises/exercises/AddExercisesModal';
import { useNotificationQueue } from 'components/notification';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import {
  PanelSubtitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import { MenuWrapper } from 'components/menu';
import RemoveExerciseTemplateButton from 'containers/partials/buttons/RemoveExerciseTemplateButton';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import { TemplateType } from 'constants.js';
import { cleanFalsy } from 'utils/object';
import Training from 'models/Training';
import TabPanelBody from 'components/tabs/TabPanelBody';

import { useStore } from 'stores/Store';
import TabLink from 'components/tabs/TabLink';
import CollectionExercisesTab from 'containers/pages/exercises/collections/CollectionExercisesTab';
import CollectionDetailsTab from 'containers/pages/exercises/collections/CollectionDetailsTab';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import useCollection from 'hooks/queries/useCollection';
import useCollections from 'hooks/queries/useCollections';
import CollectionForm from 'containers/pages/exercises/collections/CollectionForm';
import AddTrainingModal from 'containers/pages/exercises/trainings/AddTrainingModal';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';

const Collection = () => {
  const { entityId, tab, templateId, exerciseId } = useParams();
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const notification = useNotificationQueue();

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
    [
      ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
      ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT
    ]
  ]);
  const active = useRouteMatch([
    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT
  ]);

  const isExercisesTab = useRouteMatch({
    path: ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
    strict: true,
    exact: true
  });
  const isDetailsTab = useRouteMatch({
    path: [
      ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
      ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT
    ],
    strict: true,
    exact: true
  });

  const [addExercisesModal, setAddExercisesModal] = useState(null);
  const [addModalData, setAddModalData] = useState(null);
  const { fetchCollection, loading, error } = useCollection();
  const { fetchCollections } = useCollections(entityId);

  const collection = useStore(state => state.collection);

  const selectedCollectionExercises = useStore(
    state => state.selectedTemplateExercises
  );
  const setSelectedCollectionExercises = useStore(
    state => state.setSelectedTemplateExercises
  );
  const templateSortableActive = useStore(
    state => state.templateSortableActive
  );
  const setTemplateSortableActive = useStore(
    state => state.setTemplateSortableActive
  );

  const crumbs = [
    {
      path: ROUTE_EXERCISES_COLLECTIONS_ENTITY,
      label: intl.formatMessage(messages.breadcrumbCollections)
    }
  ];

  useEffect(() => {
    if (templateId) {
      fetchCollection({ variables: { templateId } });
    }
    return () => {
      setTemplateSortableActive(false);
    };
  }, [templateId]);

  useLayoutEffect(() => {
    // Redirect to the exercises tab if the user is on the training root url
    if (!isExercisesTab && !isDetailsTab && !exerciseId) {
      push(
        generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES, {
          entityId,
          tab,
          templateId
        })
      );
    }
  }, []);

  const onAddedHandler = () => {
    setAddExercisesModal(false);
  };

  const onCollectionDeleted = async templateId => {
    notification.add(`removed_${templateId}`, {
      message: intl.formatMessage(messages.messageRemovedcollectionSuccess)
    });
    await fetchCollections({
      variables: { entityId, type: TemplateType.COLLECTION },
      fetchPolicy: 'network-only'
    });
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY, {
        entityId,
        tab
      })
    );
  };

  const createTrainingFromCollection = async collection => {
    const trainingObj = cleanFalsy({
      entityId,
      title: collection.title,
      exerciseIds: collection.exercises.map(e => e.id),
      type: TemplateType.TRAINING
    });

    setAddModalData(trainingObj);
  };

  if (loading || !collection)
    return (
      <Panel active={active?.isExact}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <>
      <Panel active={active?.isExact}>
        <RefactoredPanelHeader>
          <RefactoredPanelTitleWrapper>
            {crumbs && <BreadcrumbPath crumbs={crumbs} />}
            <PanelTitle>{collection.title}</PanelTitle>
            <PanelSubtitle>
              <FormattedMessage
                {...messages.exercisesLengthInIt}
                values={{ this: collection.exercises.length }}
              />
            </PanelSubtitle>
          </RefactoredPanelTitleWrapper>
          <PanelTitleButtonsGroup>
            <MenuWrapper trigger={<PanelTitleButton />}>
              {ability.can('update', collection) && (
                <Button menu onClick={() => setAddExercisesModal(collection)}>
                  <Icon id="add" />
                  <FormattedMessage {...messages.exercisesAddExercisesButton} />
                </Button>
              )}

              <Button
                menu
                onClick={() => createTrainingFromCollection(collection)}
              >
                <Icon id="use-template" />
                <FormattedMessage
                  {...messages.copyCollectionToTemplateButton}
                />
              </Button>
              {ability.can('update', collection) && (
                <RemoveExercisesFromTemplateButton
                  disabledMessage={
                    templateSortableActive
                      ? intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledDragging
                        )
                      : intl.formatMessage(
                          messages.collectionRemoveExerciseDisabledNotSelected
                        )
                  }
                  entityId={entityId}
                  type="collection"
                  exerciseIds={selectedCollectionExercises.map(e => e.id)}
                  templateId={templateId}
                  updated={async () => {
                    await fetchCollection({
                      variables: { templateId },
                      fetchPolicy: 'network-only'
                    });
                    await fetchCollections({
                      variables: { entityId, type: TemplateType.COLLECTION },
                      fetchPolicy: 'network-only'
                    });
                    setSelectedCollectionExercises([]);
                  }}
                  disabled={selectedCollectionExercises.length === 0}
                />
              )}
              {ability.can('update', collection) &&
                collection.exercises.length > 0 && (
                  <Button
                    menu
                    onClick={() => setTemplateSortableActive(true)}
                    disabled={
                      selectedCollectionExercises.length > 0 ||
                      templateSortableActive
                    }
                  >
                    {templateSortableActive ? (
                      <FormattedMessage
                        {...messages.collectionSortExercisesDisabledDragging}
                      />
                    ) : (
                      <FormattedMessage {...messages.sortCollectionButton} />
                    )}
                    <Icon id="arrange" strokeColor="color-neutral-dark" />
                  </Button>
                )}
              {ability.can('delete', collection) && (
                <RemoveExerciseTemplateButton
                  entityId={entityId}
                  templateId={templateId}
                  collectionType={TemplateType.COLLECTION}
                  menu
                  onDeleted={templateId => onCollectionDeleted(templateId)}
                >
                  <FormattedMessage {...messages.removeCollectionButton} />
                </RemoveExerciseTemplateButton>
              )}
            </MenuWrapper>
          </PanelTitleButtonsGroup>
        </RefactoredPanelHeader>
        <PanelBody>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
              <TabLink
                to={generatePath(
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
                  {
                    entityId,
                    tab,
                    templateId
                  }
                )}
              >
                <FormattedMessage {...messages.collectionsExercises} />
              </TabLink>
              {ability.can('update', collection) && (
                <TabLink
                  to={generatePath(
                    ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
                    {
                      entityId,
                      tab,
                      templateId
                    }
                  )}
                >
                  <FormattedMessage {...messages.collectionsDetails} />
                </TabLink>
              )}
            </TabList>
            <TabPanel>
              <TabPanelBody
                withHeader
                style={{
                  overflow: 'visible',
                  padding: 0,
                  margin: '0 -16px',
                  maxHeight: '100%'
                }}
              >
                <CollectionExercisesTab
                  onAddExercises={collection =>
                    setAddExercisesModal(collection)
                  }
                />
              </TabPanelBody>
            </TabPanel>
            {ability.can('update', collection) && (
              <TabPanel>
                <TabPanelBody>
                  <CollectionDetailsTab />
                </TabPanelBody>
              </TabPanel>
            )}
          </Tabs>
        </PanelBody>
        {addModalData && (
          <AddTrainingModal
            entityId={entityId}
            training={new Training({ ...addModalData })}
            onClose={() => setAddModalData(false)}
          />
        )}
      </Panel>
      {ability.can('update', collection) && addExercisesModal && (
        <CollectionForm
          entityId={entityId}
          template={collection}
          type={TemplateType.COLLECTION}
          onComplete={() => setAddExercisesModal(false)}
        >
          {() => (
            <AddExercisesModal
              entityId={entityId}
              template={collection}
              onClose={() => {
                setAddExercisesModal(false);
              }}
              onAdded={() => onAddedHandler()}
            />
          )}
        </CollectionForm>
      )}
    </>
  );
};

export default observer(Collection);
