import React, { createContext, useContext, useState } from 'react';

const ContextMenuContext = createContext();

const ContextMenuProvider = ({ children }) => {
  const [contextMenuItems, setContextMenuItems] = useState([]);
  const [actionButton, setActionButton] = useState(null);

  const setMenuItems = components => {
    setContextMenuItems(components);
  };
  const addActionButton = components => {
    setActionButton(components);
  };

  const updateMenuItemProps = (index, newProps) => {
    setContextMenuItems(prevComponents => {
      const updatedComponents = [...prevComponents];
      const componentInstance = updatedComponents[index].ref; // Access the component instance
      if (componentInstance) {
        componentInstance.updateProps(newProps); // Assume the component has an updateProps method
      }
      return updatedComponents;
    });
  };

  return (
    <ContextMenuContext.Provider
      value={{
        contextMenuItems,
        setMenuItems,
        updateMenuItemProps,
        actionButton,
        addActionButton
      }}
    >
      {children}
    </ContextMenuContext.Provider>
  );
};

export const useContextMenu = () => {
  return useContext(ContextMenuContext);
};

export const useSetMenuItems = () => {
  const { setMenuItems } = useContext(ContextMenuContext);
  if (!setMenuItems) {
    throw new Error(
      'useSetMenuItems must be used within a ContextMenuProvider'
    );
  }
  return setMenuItems;
};

export const useAddActionButton = () => {
  const { addActionButton } = useContext(ContextMenuContext);
  if (!addActionButton) {
    throw new Error(
      'useAddActionButton must be used within a ContextMenuProvider'
    );
  }
  return addActionButton;
};

export const useUpdateMenuItemProps = () => {
  const { updateMenuItemProps } = useContext(ContextMenuContext);
  if (!updateMenuItemProps) {
    throw new Error(
      'useUpdateMenuItemProps must be used within a ContextMenuProvider'
    );
  }
  return updateMenuItemProps;
};

export default ContextMenuProvider;
