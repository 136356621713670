import { PersonEntityRoles } from 'constants.js';
import Session from './Session';
export default class Group {
  id = null;
  parentId = null;
  roles = [];
  name = null;
  type = '';
  subEntities = [];
  clients = [];
  users = [];
  testSessions = [];
  filtered = true;

  constructor({
    parentId = null,
    id,
    name = '',
    type,
    subEntities = [],
    clients = [],
    users = [],
    roles = [],
    testSessions = [],
    info,
    regioCode = 'nl',
    baseLanguage = 'nl',
    meta,
    filtered = true
  }) {
    // root ids
    this.id = id;
    this.parentId = parentId;

    // basis info entity
    this.name = name;
    this.type = type;
    try {
      this.info = JSON.parse(info);
    } catch (er) {
      this.info = {};
    }

    try {
      this.meta = JSON.parse(meta);
    } catch (er) {
      this.meta = {};
    }

    // sub data
    this.subEntities = subEntities || [];
    this.clients = clients || [];
    this.users = users || [];

    this.testSessions = parseTestSessionDataToModel(testSessions);

    // roles
    this.roles = roles || [];

    this.baseLanguage = baseLanguage;
    this.regioCode = regioCode;

    this.filtered = filtered;
  }

  get canAddUsers() {
    if (this.roles && this.roles.length > 0) {
      return this.roles.find(PersonEntityRoles.ENTITY_ADD_USER);
    }
    return false;
  }

  static get modelName() {
    return 'Group';
  }
}

export const parseTestSessionDataToModel = data => {
  let sessions = [];
  if (data && data.length > 0) {
    sessions = data.map(session => new Session(session));
  }
  return sessions;
};
