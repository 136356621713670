import gql from 'graphql-tag';

export const QUERY_GET_TAGS = gql`
  query getTagsByEntity($entityId: ID!) {
    getTagsByEntity(entityId: $entityId) {
      id
      label
      parentId
      order
      description
      isGroup
    }
  }
`;
