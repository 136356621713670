import awsconfig from 'services/aws/config/aws-exports';
import * as Sentry from '@sentry/react';

import { Amplify, Auth } from 'aws-amplify';

export const connect = async () => {
  Amplify.configure(awsconfig);
};

export const loginWithEmailAndPassword = async (email, password) => {
  return await Auth.signIn(email, password);
};

export const loginWithEmail = async email => {
  return await Auth.signIn(email);
};

export const customChallengeAnswer = async (user, token) => {
  return await Auth.sendCustomChallengeAnswer(user, token);
};

export const loginWithTotpCode = async (user, code) => {
  if (
    user?.challengeName === 'SMS_MFA' ||
    user.challengeName === 'SOFTWARE_TOKEN_MFA'
  ) {
    return await Auth.confirmSignIn(
      user, // Return object from Auth.signIn()
      code, // Confirmation code
      'SOFTWARE_TOKEN_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    );
  }
};

export const getCurrentSession = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const currentAccessToken = await currentSession.accessToken;
    const currentJwtToken = await currentAccessToken.jwtToken;
    return {
      authenticated: true,
      jwtToken: currentJwtToken
    };
  } catch (error) {
    Sentry.captureException(error);
    return { authenticated: false };
  }
};

export const completeNewPassword = async (cognitoUser, password) => {
  return await Auth.completeNewPassword(cognitoUser, password);
};

export const signOut = async () => {
  await Auth.signOut();
};

export const getCurrentAuthenticatedUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    throw error;
  }
};

export const getQRCode = async user => {
  try {
    return await Auth.setupTOTP(user);
  } catch (error) {
    throw error;
  }
};

export const verifyTotpToken = async code => {
  const user = await getCurrentAuthenticatedUser();
  try {
    const cognitoUserSession = await Auth.verifyTotpToken(user, code);
    await Auth.setPreferredMFA(user, 'TOTP');
    return cognitoUserSession;
  } catch (error) {
    return error;
  }
};

export const removeMFA = async () => {
  const user = await getCurrentAuthenticatedUser();
  try {
    return await Auth.setPreferredMFA(user, 'NOMFA');
  } catch (error) {
    throw error;
  }
};
