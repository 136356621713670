import { useContext, useEffect, useState } from 'react';
import { TabList, TabPanel, Tabs } from 'components/tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Panel, PanelSubtitle } from 'components/panel';
import messages from 'messages';
import {
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import './Exercise.scss';
import { MenuWrapper } from 'components/menu';
import RemoveExerciseButton from 'containers/partials/buttons/RemoveExerciseButton';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import ExercisePanel from './ExercisePanel';
import useExercise from 'hooks/queries/useExercise';
import { AbilityContext, Can } from 'Can';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import AddExercisesToCollectionModal from 'containers/pages/exercises/collections/AddExercisesToCollectionModal';
import Tags from 'containers/partials/inputs/Tags';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { useAbility } from '@casl/react';
import RemoveExercisesFromTemplateButton from 'containers/partials/buttons/RemoveExercisesFromTemplateButton';
import { ROUTE_EXERCISES_ENTITY_EXERCISE } from 'routes/RouteList';
import Organisation from 'components/organisation/Organisation';
import useEdit from 'hooks/utils/useEdit';
import useTemplate from 'hooks/queries/useTemplate';
import TrainingExerciseDetailsPanel from 'containers/pages/exercises/exercises/TrainingExerciseDetailsPanel';
import ExerciseEditLanguagesButton from 'containers/pages/exercises/exercises/ExerciseEditLanguagesButton';
import TabPanelBody from 'components/tabs/TabPanelBody';
import useTabIndex from 'hooks/utils/useTabIndex';
import TabLink from 'components/tabs/TabLink';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import AddButton from 'containers/partials/buttons/AddButton';

const Exercise = ({ inTraining = false, parentPath, crumbs }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { push } = useHistory();
  const params = useParams();
  const { entityId, exerciseId, templateId } = params;
  const ability = useAbility(AbilityContext);
  const active = useRouteMatch([
    `${parentPath}/edit`,
    `${parentPath}/details`,
    parentPath
  ]);

  const [edit, setEdit] = useEdit(
    !!useRouteMatch([`${parentPath}/edit`]),
    true
  );
  const [addToCollectionModal, setAddToCollectionModal] = useState(null);
  const { loading, error, fetchExercise } = useExercise(entityId);

  const { template, fetchTemplate } = useTemplate(entityId, templateId, user);
  const [tabIndex, setTabIndex] = useTabIndex(
    [[parentPath, `${parentPath}/edit`], `${parentPath}/details`],
    false
  );
  const [exercise, setExercise] = useState(null);

  useEffect(() => {
    const getExercise = async () => {
      const exercise = await fetchExercise(exerciseId);
      setExercise(exercise);
    };
    getExercise();
  }, [exerciseId]);

  const refetchExercise = async id => {
    const exercise = await fetchExercise(id, true);
    setExercise(exercise);
  };

  const addToCollectionHandler = () => {
    setAddToCollectionModal(true);
  };
  /*
  const onAddExerciseToCollection = collections => {
    addExercise({
      variables: { exerciseId: exercise.id, programTemplateIds: collections },
      refetchQueries: [
        {
          query: QUERY_GET_TEMPLATES,
          variables: { entityId }
        }
      ]
    }).then(
      () => {
        notification.add('add_exercises', {
          message: intl.formatMessage(messages.messageExercisesAddedToTemplate)
        });
        setAddToCollectionModal(false);
      },
      err => {
        notification.add('edit_error', {
          message: intl.formatMessage(
            messages.messageExercisesAddedToTemplateError
          ),
          level: 'error'
        });
      }
    );
  };
*/
  const onUpdated = () => {
    push(generatePath(parentPath, params));
  };

  if (loading || !exercise)
    return (
      <Panel active={active?.isExact}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active?.isExact}>
      <RefactoredPanelHeader>
        <RefactoredPanelTitleWrapper>
          {crumbs && <BreadcrumbPath crumbs={crumbs} />}

          <PanelTitle noSubtitle={user.isGSVOrg}>
            <span title={exercise.title}>{exercise.title}</span>
          </PanelTitle>

          {!user.isGSVOrg && (
            <PanelSubtitle>
              <div className="u-margin-bottom-small">
                <FormattedMessage
                  {...messages.exercisesLabelLevelSubtitle}
                  values={{ level: exercise.level }}
                />
              </div>
            </PanelSubtitle>
          )}
        </RefactoredPanelTitleWrapper>

        <PanelTitleButtonsGroup>
          {user.isGSVOrg && !inTraining && (
            <AddButton
              expandable
              iconId="bookmark-outline"
              onClick={() => addToCollectionHandler()}
              label={intl.formatMessage(
                messages.addExercisesToCollectionModalTitle
              )}
            ></AddButton>
          )}

          <MenuWrapper trigger={<PanelTitleButton />}>
            {!user.isGSVOrg && (
              <>
                <Button menu onClick={() => addToCollectionHandler()}>
                  <Icon
                    id="template"
                    strokeColor="color-neutral-dark"
                    style={{ top: 'auto' }}
                  />
                  <FormattedMessage
                    {...messages.collectionAddExercisesToTemplateButton}
                    values={{ thisMany: 0, type: 'template' }}
                  />
                </Button>
                {/* TODO: maarten add exercise to program 
                // mss ook voor training, in GSV?
                */}
                {/* <Button menu onClick={() => {}}>
                  <Icon
                    id="program"
                    strokeColor="color-neutral-dark"
                    style={{ top: 'auto' }}
                  />
                  <FormattedMessage
                    {...messages.collectionAddExercisesToTemplateButton}
                    values={{ thisMany: 0, type: 'program' }}
                  />
                </Button> */}
              </>
            )}
            {ability.can('delete', exercise) && (
              <RemoveExerciseButton
                entityId={entityId}
                exerciseId={exerciseId}
              />
            )}
            {/*
            Is dit nog nodig?
            {template &&
                  ability.can('update', template) &&
                  inCollection &&
                  collectionId && (
                    <RemoveExercisesFromTemplateButton
                      entityId={entityId}
                      type="collection"
                      exerciseIds={[exerciseId]}
                      templateId={collectionId}
                      updated={() => onUpdated()}
                    />
                  )}*/}
            {ability.can('update', template) && templateId && (
              <RemoveExercisesFromTemplateButton
                entityId={entityId}
                type="template"
                exerciseIds={[exerciseId]}
                templateId={templateId}
                updated={() => onUpdated()}
              />
            )}
          </MenuWrapper>
        </PanelTitleButtonsGroup>
      </RefactoredPanelHeader>

      {exercise.tags && exercise.tags.length > 0 && (
        <Tags
          extraClassNames="c-input__group-select-tags"
          entityId={entityId}
          placeholder={``}
          selectedTags={exercise.tags}
          isMulti
          isClearable={false}
          edit={false}
        />
      )}
      {user.isGSVOrg &&
        exercise?.entity?.id &&
        exercise.entity.id !== entityId && (
          <Organisation
            to={generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
              entityId: exercise.entity.id,
              tab: 'all',
              exerciseId
            })}
            logo={exercise.entity.info && exercise.entity.info.logo}
            name={exercise.entity.name}
          />
        )}
      <PanelBody>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <TabLink to={generatePath(parentPath, params)}>
              <FormattedMessage {...messages.exerciseTab} />
            </TabLink>
            {inTraining && (
              <TabLink to={`${generatePath(parentPath, params)}/details`}>
                <FormattedMessage {...messages.exerciseDetailsTab} />
              </TabLink>
            )}
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              {!user.isGSVOrg && (
                <div className="o-flex o-flex--column u-1-of-1 u-margin-top-medium">
                  <Can I={'translate'} an={exercise}>
                    <ExerciseEditLanguagesButton
                      entityId={entityId}
                      exerciseId={exerciseId}
                    />
                  </Can>
                </div>
              )}
              <ExercisePanel
                entityId={entityId}
                exercise={exercise}
                edit={edit}
                editable={ability.can('update', exercise) && exercise.editable}
                setEdit={edit => setEdit(edit)}
                onComplete={() => {
                  if (templateId) {
                    fetchTemplate({
                      variables: { templateId },
                      fetchPolicy: 'network-only'
                    });
                  }
                  refetchExercise(exerciseId);
                  setEdit(false);
                }}
              />
            </TabPanelBody>
          </TabPanel>

          {inTraining && (
            <TabPanel>
              <TabPanelBody withScroller>
                <TrainingExerciseDetailsPanel
                  entityId={entityId}
                  exercise={exercise}
                />
              </TabPanelBody>
            </TabPanel>
          )}
        </Tabs>
      </PanelBody>
      {addToCollectionModal && (
        <AddExercisesToCollectionModal
          entityId={entityId}
          exercises={[exercise]}
          collectionType={user.isGSVOrg ? 'Collection' : 'Template'}
          onAdded={() => setAddToCollectionModal(false)}
          onClose={() => {
            setAddToCollectionModal(false);
          }}
        />
      )}
      {/*{addToProgramModal && (
        <AddExercisesToCollectionModal
          entityId={entityId}
          onClose={() => {
            setAddToProgramModal(false);
          }}
        />
      )}*/}
    </Panel>
  );
};

export default Exercise;
