import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { generatePath, useParams } from 'react-router-dom';

const BreadcrumbPath = ({ crumbs }) => {
  const params = useParams();

  if (!crumbs || crumbs.length === 0) {
    return <></>;
  }
  return (
    <Breadcrumbs>
      {crumbs.map((crumb, i) => (
        <Breadcrumb to={generatePath(crumb.path, params)} key={i}>
          {crumb.label}
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbPath;
