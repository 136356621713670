import { TableHeaderCell, TableRow, TableHeader } from 'components/table';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { SORT_TYPES } from 'utils/sort';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';
import FieldSelect from 'components/input/FieldSelect';
import messages from 'messages.js';
import { useIntl } from 'react-intl';
import { useStore } from 'stores/Store';
import { useEffect } from 'react';
import useUrlQuery from 'hooks/utils/useUrlQuery';

const ResultsTableHeader = () => {
  let query = useUrlQuery();
  const intl = useIntl();
  const {
    testItems,
    usersArray,
    onChangeAthleteCol,
    onSort,
    onSortAthletes,
    sortIndex,
    athletesSortDirection
  } = useResultsPanelContext();

  const selectedHeaders = useStore(state => state.selectedHeaders);
  const { setSelectedHeaders, setSelectedHeader } = useStore(state => state);

  useEffect(() => {
    const tests = query.get('tests');
    if (tests) {
      const testsArray = tests.split(',');
      const testIds = [];
      testsArray.forEach((test, index) => {
        testIds.push({ id: test, sortDirection: SORT_TYPES.DOWN });
      });
      setSelectedHeaders([...testIds]);
    }
  }, [query]);

  const onSelectTestHandler = (index, value) => {
    setSelectedHeader({ index, value });

    const testIds = selectedHeaders.map(item => item.id);
    const testsQuery = testIds.join(',');
    query.set('tests', testsQuery);
    window.history.replaceState(null, null, `?${query.toString()}`);
  };

  return (
    <TableHeader>
      <TableRow>
        <TableHeaderCell>
          <FieldSelect
            name={`athleteFilter`}
            placeholder={intl.formatMessage(
              messages.resultsTableHeaderSelectAthletes
            )}
            onChange={(option, action) => {
              onChangeAthleteCol(option, action);
            }}
            label={intl.formatMessage(
              messages.resultsTableHeaderFilterByAthlete
            )}
            options={usersArray.current.map(u => ({
              value: u.id,
              label: u.label
            }))}
            isClearable
            isMulti
            styles={{
              container: { width: '100%' },
              option: { textAlign: 'left' }
            }}
            labelHiddenVisually
            // customMultiValue
          />
          <Button
            tiny
            inactive={true}
            onClick={() => {
              onSortAthletes();
            }}
            disabled={false}
          >
            <Icon
              id={
                athletesSortDirection
                  ? `sort-${athletesSortDirection ? 'down' : 'up'}`
                  : 'sort'
              }
            />
          </Button>
        </TableHeaderCell>
        {selectedHeaders.map((col, i) => {
          const isSortIndex = i === sortIndex;
          const selectedValue = testItems.find(
            item => item.value === selectedHeaders[i]?.id
          );
          return (
            <TableHeaderCell key={i}>
              <FieldSelect
                name={`test-set-${i}`}
                value={selectedValue}
                onChange={option => {
                  onSelectTestHandler(i, option?.value ?? null);
                }}
                placeholder={
                  !testItems.length
                    ? intl.formatMessage(messages.resultsTableHeaderNoTestItems)
                    : intl.formatMessage(
                        messages.resultsTableHeaderSelectTestItem
                      )
                }
                disabled={!testItems.length}
                options={testItems}
                isClearable
                styles={{
                  container: { width: '100%' },
                  option: { textAlign: 'left' }
                }}
              />
              <Button
                tiny
                inactive={!isSortIndex}
                onClick={() => {
                  const newDirection = !isSortIndex
                    ? SORT_TYPES.DOWN
                    : col.sortDirection === SORT_TYPES.DOWN
                      ? SORT_TYPES.UP
                      : SORT_TYPES.DOWN;
                  onSort(i, newDirection);
                }}
                disabled={!col.id}
              >
                <Icon id={isSortIndex ? `sort-${col.sortDirection}` : 'sort'} />
              </Button>
            </TableHeaderCell>
          );
        })}
      </TableRow>
    </TableHeader>
  );
};

export default ResultsTableHeader;
