import React from 'react';
import classNames from 'classnames';
import '../../_scss/06-utilities/_utilities.plugins.scss';
import './Tooltip.scss';
import Button from '../button/Button';
import Icon from '../icon/Icon';

const Tooltip = props => {
  const {
    id,
    children,
    pseudoDisabled,
    extraClassNames,
    icon,
    info,
    mediumIcon,
    alert,
    video,
    videoMessage,
    label,
    right,
    left,
    top,
    bottom,
    styles,
    onClick,
    onMouseEnter,
    onMouseLeave,
    iconStrokeColor,
    iconFillColor,
    tabIndex,
    style
  } = props;
  return (
    <div
      className={classNames('c-tooltip', extraClassNames, {
        'c-tooltip--right': right,
        'c-tooltip--left': left,
        'c-tooltip--top': top,
        'c-tooltip--bottom': bottom,
        'c-tooltip--alert': alert,
        'c-tooltip--info': info,
        'c-tooltip--video': video,
        'c-tooltip--label': label?.length > 0
      })}
      style={style}
    >
      <Button
        tiny
        extraClassNames={'c-tooltip__trigger'}
        ariaLabelledby={id.length > 0 && id}
        pseudoDisabled={pseudoDisabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        tabIndex={tabIndex}
      >
        {icon && (
          <Icon
            id={icon}
            mediumIcon={mediumIcon}
            strokeColor={
              iconStrokeColor ? iconStrokeColor : alert ? 'color-error' : false
            }
            fillColor={
              iconFillColor && !alert
                ? iconFillColor
                : iconFillColor && alert
                  ? 'color-error'
                  : false
            }
          />
        )}
        {label && <span style={styles}>{label}</span>}
      </Button>
      {video && (
        <div className={'c-tooltip__message'} role="tooltip">
          {children}
          {videoMessage && (
            <section className="c-tooltip__info">{videoMessage}</section>
          )}
        </div>
      )}
      {!video && (
        <p id={id} className={'c-tooltip__message'} role="tooltip">
          {children}
        </p>
      )}
    </div>
  );
};

export default Tooltip;
