import Loader from 'components/loader/Loader';

export const LoaderListItem = ({ style }) => (
  <li
    className="r-list-item--wrapping"
    style={{ backgroundColor: '#0000', boxShadow: 'none', ...style }}
  >
    <Loader />
  </li>
);
