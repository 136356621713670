import { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useLazyQuery } from '@apollo/client';
import { useParams, useRouteMatch } from 'react-router-dom';
import Session from '../models/Session';
import {
  QUERY_GET_SESSION_BY_ID,
  QUERY_GET_TESTDATA_BY_SESSION
} from 'services/aws/session-query';
import { DefaultBenchmark, reportOptions, TestSetV2Type } from 'constants.js';
import { ROUTE_TESTS } from 'routes/RouteList';
import TestData from 'models/TestData';
import { groupTests } from 'utils/tests';
import { useTestSetStore } from 'containers/pages/sessions/store/TestSetsStore';
import useTestSets from 'hooks/queries/useTestSets';
import { StoreContext } from 'index';

export const SessionContext = createContext({});

const SessionContextProvider = ({ children }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId, sessionId } = useParams();
  const { fetchTestSets } = useTestSets({ entityId });
  const setTestSets = useTestSetStore(state => state.setTestSets);

  const testRoute = useRouteMatch(ROUTE_TESTS) ?? null;

  const [session, setSession] = useState(new Session({}));
  const [options, setOptions] = useState({
    ...Object.assign(
      {},
      ...Object.values(reportOptions).map(option => {
        return (
          option.id !== 'advancedOptions' && {
            [option.id]: option.default
          }
        );
      })
    )
  });
  const [sessionTestData, setSessionTestData] = useState(undefined);

  const [
    getSession,
    { loading: sessionLoading, error, data: sessionData, refetch }
  ] = useLazyQuery(QUERY_GET_SESSION_BY_ID);

  const [getTestDataBySession] = useLazyQuery(QUERY_GET_TESTDATA_BY_SESSION);

  useEffect(() => {
    // setSession(new Session({}));
    const fetchSessionData = async () => {
      await getSession({
        variables: { entityId, testSessionId: sessionId }
      });

      // Don't query for testsets if the user is an athlete
      if (user && !user.isAthlete() && !user.hasNoRoles()) {
        const testSets = await fetchTestSets({
          types: [TestSetV2Type.TALENT, TestSetV2Type.PREVENTION]
        });
        if (testSets) {
          setTestSets(testSets);
        }
      }
    };

    if (entityId && sessionId && sessionId.match(/[a-z0-9-]{36}/i)) {
      fetchSessionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, sessionId, user]);

  useEffect(() => {
    if (sessionData?.getTestSession) {
      const testSession = new Session(sessionData.getTestSession);

      testSession.sport = null;

      let parsedTests = [
        ...parseTestSetTests(
          testSession.testSets
            .map(testSet => testSet.tests.map(test => test))
            .flat()
        )
      ];

      testSession.sport =
        testSession?.testSets && testSession.testSets.length > 0
          ? testSession?.testSets[0].sport
          : null;

      testSession.tests = parsedTests;
      const testsList = filterGrowthPredictionData(parsedTests);
      testSession.groupedTests = { ...groupTests(testsList) };

      setOptions({
        ...Object.assign(
          {},
          ...Object.values(reportOptions).map(option => {
            return (
              option.id !== 'advancedOptions' && {
                [option.id]: option.default
              }
            );
          })
        ),
        showScore: !(testSession.isPrevention || testSession.isRehab),
        benchmark: testSession.benchmark?.id ?? DefaultBenchmark
      });
      setSession(testSession);
    }
  }, [sessionData]);

  const fetchTestDataBySession = async testSession => {
    if (!testRoute) {
      return {};
    }
    if (testSession.id) {
      const { data } = await getTestDataBySession({
        variables: {
          testSessionId: testSession.id
        }
      });

      if (data?.getTestDataV2OfSession && testSession.tests) {
        const testDatas = [];
        data.getTestDataV2OfSession.forEach(td => {
          const testDataResult = new TestData(td);

          testDatas.push(testDataResult);
        });
        setSessionTestData(testDatas);

        return {};
      }
    }
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        refetch,
        sessionLoading,
        error,
        options,
        setOptions,
        sessionTestData,
        fetchTestDataBySession
      }}
    >
      {typeof children === 'function' ? children({}) : children}
    </SessionContext.Provider>
  );
};

function useSessionContext() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error(
      'The SessionContext hook must be used within a SessionContext.Provider'
    );
  }
  return context;
}

export { SessionContextProvider, useSessionContext };

const parseTestItem = testItem => {
  let sides;
  if (testItem?.laterality && testItem.laterality.includes('bilateral_')) {
    sides = {
      0: Array(testItem.trials).fill(''),
      1: Array(testItem.trials).fill('')
    };
  } else {
    sides = { 2: Array(testItem.trials).fill('') };
  }
  let inputParameters = {};
  try {
    if (testItem.input_type_parameters) {
      inputParameters = JSON.parse(testItem.input_type_parameters);
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log('Error parsing input_type_parameters', err.message);
  }

  return {
    ...testItem,
    sides,
    input_type_parameters: inputParameters
  };
};

const parseTestSetTests = tests => {
  if (!tests) return [];

  return tests.map(test => {
    // find if it's a dependency
    const isDependency = tests.some(ts => {
      try {
        return ts.dependedTestIds?.includes(test.id);
      } catch (e) {
        return [];
      }
    });

    // TODO testen of dit werkt als een test geen testitems heeft
    const testItems = test.testItems
      ? test.testItems
          .filter(testItem => testItem.input_type !== 'derived')
          .map(testItem => parseTestItem(testItem))
      : [];

    return {
      ...test,
      name: test.title,
      isDependency,
      status: true,
      testItems
    };
  });
};

const filterGrowthPredictionData = tests => {
  const growIds = [
    'face72bd-d260-44a3-b55c-3bae9e3d18dc', // 'Adult Height Prediction',
    '496d355a-4667-4253-8701-6a26bc7a3f59', // 'APHV',
    '7497420b-4a4f-4958-971e-f68aa41cbbe9', // 'Years from APHV (Maturity Offset)',
    '05c16b3e-0f75-4f17-a837-0d6163b0fd22', // 'Adult Height Prediction (M)',
    '5737716d-7eb3-4724-85d2-7baa4652ae93', // 'Adult Height Prediction (K-R)',
    '105b2d29-1469-4d27-bdc0-9d9b6c44b915', // 'Development (K-R) Z-score',
    '0c58cad6-8a6f-402f-b30e-b00e06871f14' // 'Development Maturation Timing',
  ];
  //const excludeIds = []; //'32c6900c-8eea-4be4-a5f3-3ff558308afa'

  return tests.map(test => {
    if (test.testItems.find(t => growIds.indexOf(t.id) !== -1)) {
      test.category = 'B_growthprediction';
    }
    return test;
  });
  //.filter(test => excludeIds.indexOf(test.id) === -1);
};
