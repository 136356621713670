import React, { useContext, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import Button from 'components/button/Button';

import {
  ROUTE_SETTINGS_USERS_ADD,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_USERS_USER
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Icon from 'components/icon/Icon';
import PanelTools from 'components/panel/PanelTools';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';
import VirtualizedEntityList from 'containers/pages/persons/VirtualizedEntityList';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import usePersonStatus from 'hooks/utils/usePersonStatus';
import useFetchAndFilterUsers from 'hooks/queries/useFetchAndFilterUsers';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const Users = ({ userId }) => {
  const ability = useAbility(AbilityContext);
  const { push } = useHistory();
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { personStatusHandler } = usePersonStatus(rootEntityId);

  const { loading, error, fetchUsers } = useFetchAndFilterUsers({
    entityId: rootEntityId
  });
  const users = useEntityContext(s => s.persons);

  useEffect(() => {
    if (rootEntityId) {
      fetchUsers(rootEntityId);
    }
  }, [rootEntityId]);

  return (
    <Panel>
      <PanelHeader borderBottom extraClassNames="u-margin-bottom-none">
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettings} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.titleUsers} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.subtitleUsers} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools extraClassNames="u-margin-bottom-none">
          {ability.can('create', 'Users') && (
            <Button
              rounded
              primary
              onClick={() => push(ROUTE_SETTINGS_USERS_ADD)}
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.buttonUsersAddUser} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      {loading && <Loader />}
      {error && <p>Error! {error.message}</p>}
      {!loading && (
        <>
          {users.length > 0 ? (
            <VirtualizedEntityList
              id={'settings'}
              items={users}
              activeId={userId}
              enableSelectAll={false}
              extraClassNames="u-padding-top-medium"
              showPersonStatus
              onClick={person => {
                push(
                  generatePath(ROUTE_SETTINGS_USERS_USER, {
                    userId: person.id
                  })
                );
              }}
              onClickPersonStatus={personStatusHandler}
            />
          ) : (
            <Message emptyState icon="sporters">
              <MessageText>
                <FormattedMessage {...messages.noUsersAddedMessage} />
              </MessageText>
            </Message>
          )}
        </>
      )}
    </Panel>
  );
};

export default Users;
