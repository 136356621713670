import React, { useEffect, useState } from 'react';
import Icon from 'components/icon/Icon';
import { Button, ButtonsGroup } from 'components/button';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { useFormikContext } from 'formik';
import FormEditFooterButtons from '../buttons/FormEditFooterButtons';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import SortableList from 'components/dndkit/SortableList';
import ExercisesListSortable from 'containers/partials/lists/ExercisesListSortable';
import Template from 'models/Template';

const SelectedExercisesCard = ({
  edit,
  onEdit,
  onPreview,
  onUse,
  onCancel,
  noteType,
  selectedExercises,
  onSelectedExercisesChange
}) => {
  const [draggable, setDraggable] = useState(false);
  const [exercisesSortOrder, setExercisesSortOrder] = useState([]);
  const {
    submitForm,
    values: exerciseGroup,
    resetForm,
    setFieldValue
  } = useFormikContext();

  useEffect(() => {
    if (edit) {
      setDraggable(false);
    }
  }, [edit]);

  const submitTemplateHandler = async () => {
    await setFieldValue('exerciseIds', [...selectedExercises.map(e => e.id)]);

    await submitForm();
  };

  const submitExercisesSorting = async () => {
    if (exercisesSortOrder.length === 0) {
      setDraggable(false);
      return;
    }

    await setFieldValue('exerciseIds', [...exercisesSortOrder]);

    // setSelected(sortByArray(selectedExercises, exercisesSortOrder));

    await submitForm();
    setDraggable(false);
  };

  const onCancelHandler = () => {
    resetForm();
    setDraggable(false);

    onCancel && onCancel();
  };

  return (
    <Card
      secondary
      empty={selectedExercises.length <= 0}
      style={selectedExercises.length <= 0 ? { flex: 1 } : {}}
    >
      <CardHeader secondary>
        <div className="o-flex o-flex--wrap o-flex--justify-space-between u-margin-bottom-none">
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleScreeningExercises} />
          </CardHeaderTitle>
          <ButtonsGroup extraClassNames="o-flex--wrap o-flex--justify-end u-margin-top-none u-margin-left-small">
            {draggable ? (
              <Button tiny disabled>
                <FormattedMessage
                  {...messages.enableAddRemoveExercisesButton}
                />
              </Button>
            ) : (
              <Button tiny onClick={() => onEdit(!edit)}>
                <FormattedMessage
                  {...messages.enableAddRemoveExercisesButton}
                />
              </Button>
            )}
            {edit ? (
              <Button tiny extraClassNames="u-margin-left-medium" disabled>
                <FormattedMessage {...messages.enableSortExercisesButton} />
              </Button>
            ) : (
              <Button
                tiny
                extraClassNames="u-margin-left-medium"
                onClick={() => setDraggable(!draggable)}
              >
                <FormattedMessage {...messages.enableSortExercisesButton} />
              </Button>
            )}
          </ButtonsGroup>
        </div>
      </CardHeader>

      {selectedExercises.length > 0 ? (
        <>
          <CardBody secondary>
            <SortableList
              items={selectedExercises}
              onSort={exercises => setExercisesSortOrder(exercises)}
            >
              {({ items }) => (
                <ExercisesListSortable
                  exercises={items}
                  exerciseGroup={exerciseGroup}
                  draggable={draggable}
                  checkable={edit}
                  enablePreviewModal={true}
                  showTemplateCount={false}
                  onPreview={onPreview}
                  showNote
                  noteType={noteType}
                  editableNotes={!edit}
                  onSelect={exercise => {
                    if (!edit) return;
                    onSelectedExercisesChange(exercise);
                  }}
                  selectedExercises={selectedExercises}
                />
              )}
            </SortableList>
            {draggable && !edit && (
              <ButtonsGroup extraClassNames="u-margin-left-auto u-margin-top-medium">
                <Button secondary onClick={() => onCancelHandler()}>
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button
                  type="button"
                  primary
                  disabled={false} //|| processing
                  onClick={() => submitExercisesSorting()}
                >
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            )}
          </CardBody>
          {edit && !draggable && (
            <FormEditFooterButtons
              edit={edit}
              setEdit={onEdit}
              onCancel={() => {
                onCancelHandler();
              }}
              onSubmit={() => submitTemplateHandler()}
            />
          )}
          {onUse && !edit && !draggable && (
            <div className="o-flex o-flex--justify-end">
              <Button
                small
                ghost
                onClick={onUse}
                // disabled={draggable || edit}
              >
                <Icon id="use-template" />
                {exerciseGroup instanceof Template ? (
                  <>
                    <FormattedMessage {...messages.buttonSaveAsProgram} />
                  </>
                ) : (
                  <FormattedMessage
                    {...messages.buttonScreeningSaveAsTemplate}
                  />
                )}
              </Button>
            </div>
          )}
        </>
      ) : (
        <CardBody empty>
          <Message emptyState={true} icon="cursor">
            <MessageText>
              <FormattedMessage
                {...messages.exercisesEmptyPanelInThis}
                values={{ this: exerciseGroup.title }}
              />
            </MessageText>
          </Message>
        </CardBody>
      )}
    </Card>
  );
};

export default SelectedExercisesCard;
