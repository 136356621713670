import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TOTAL_SESSIONS_OF_SPORTER } from 'services/aws/session-query';
import { useState } from 'react';

const useSessionCount = () => {
  const [totalSessions, setTotalSessions] = useState(0);
  const [getTotalSessionsOfSPorter] = useLazyQuery(
    QUERY_GET_TOTAL_SESSIONS_OF_SPORTER
  );

  const getSessionCount = async (entityId, personId) => {
    const response = await getTotalSessionsOfSPorter({
      variables: { entityId, personId }
    });
    if (response?.data?.getTestSessionsOfPerson) {
      setTotalSessions(response.data.getTestSessionsOfPerson.length);
    }
  };

  return { totalSessions, getSessionCount };
};

export default useSessionCount;
