import React from 'react';
import {
  MUTATION_REMOVE_SCANNER,
  QUERY_GET_SCANNERS_BY_ENTITY_ID
} from 'services/aws/scanner-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';

const RemoveScannerButton = ({
  entityId,
  scannerId,
  children,
  onComplete,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_REMOVE_SCANNER}
      variables={{
        scannerId
      }}
      confirmTitle={messages.modalConfirmRemoveScannerTitle}
      confirmMessage={messages.modalConfirmRemoveScannerMessage}
      refetchQueries={[
        {
          query: QUERY_GET_SCANNERS_BY_ENTITY_ID,
          variables: { entityId }
        }
      ]}
      update={(cache, { data: { archiveMtsScanner } }) => {
        if (archiveMtsScanner) {
          if (onComplete) onComplete();
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveScannerButton;
