import React, { useContext } from 'react';
import * as Sentry from '@sentry/react';
import Panel from 'components/panel/Panel';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { PanelHeader } from 'components/panel';
import { StoreContext } from 'index';
import { Redirect, useHistory } from 'react-router-dom';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import { CardHeaderText, CardHeaderTitle } from 'components/card/card-header';
import CardHeader from 'components/card/card-header/CardHeader';
import CardBody from 'components/card/CardBody';
import Button from 'components/button/Button';
import { useMutation } from '@apollo/client';
import { QUERY_COPY_DATA } from 'services/aws/account-query';
import { DemoDataId } from 'constants.js';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { useNotificationQueue } from 'components/notification';

DemoData.propTypes = {};

function DemoData() {
  const {
    uiState,
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { push } = useHistory();

  const [copyData] = useMutation(QUERY_COPY_DATA, {
    variables: { sourceEntityId: DemoDataId, destinationEntityId: rootEntityId }
  });

  const installDemoDataHandler = async () => {
    uiState.increasePendingRequest();
    try {
      await copyData();
      notification.add('demodata', {
        message: intl.formatMessage(messages.accountDemoDataIsReady),
        duration: 5000
      });
    } catch (e) {
      Sentry.captureException(e);
    }
    uiState.decreasePendingRequest();
  };

  if (!user.superadmin) {
    return <Redirect to={ROUTE_SETTINGS} />;
  }

  return (
    <Panel>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbAccountDemoData} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.accountDemoDataTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.accountDemoDataSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary>
          <CardHeader secondary>
            <CardHeaderText>
              <CardHeaderTitle>
                <FormattedMessage {...messages.accountDemoDataInstallTitle} />
              </CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary>
            <p>
              <FormattedMessage {...messages.accountDemoDataInstallInfo} />
            </p>
            <Button primary onClick={() => installDemoDataHandler()}>
              <FormattedMessage {...messages.accountDemoDataInstallButton} />
            </Button>
          </CardBody>
        </Card>
        {/* </Message> */}
      </PanelBody>
    </Panel>
  );
}

//
export default DemoData;
