import React, { useContext, useEffect, useState } from 'react';
import { ROUTE_VIDEOS_ENTITY_VIDEO } from 'routes/RouteList';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Video from 'models/Video';
import { QUERY_GET_VIDEOS_BY_PERSON } from 'services/aws/videos-query';
import { StoreContext } from 'index';

PersonVideos.propTypes = {};

function PersonVideos({ entityId }) {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { sporterId, videoId } = useParams();
  const { push } = useHistory();
  const [videos, setVideos] = useState([]);

  const { data } = useQuery(QUERY_GET_VIDEOS_BY_PERSON, {
    variables: { entityId: rootEntityId, personId: sporterId }
  });

  useEffect(() => {
    if (data?.getExercisesByPersonId) {
      const exercises = data.getExercisesByPersonId.map(
        exercise => new Video({ ...exercise })
      );
      setVideos(exercises);
    }
  }, [data]);

  return (
    <ExercisesListVirtualized
      entityId={entityId}
      statusIcon="play"
      exercises={videos}
      activeId={videoId}
      enablePreviewModal={false}
      onClick={id => {
        if (id !== videoId) {
          push(
            generatePath(ROUTE_VIDEOS_ENTITY_VIDEO, {
              entityId,
              videoId: id
            })
          );
        }
      }}
      showSharesAndAnnotations={true}
    />
  );
}

export default PersonVideos;
