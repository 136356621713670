import { Component, Fragment } from 'react';
import * as Sentry from '@sentry/react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Breadcrumb } from 'components/breadcrumbs';
import GrowthPredictionTable, {
  FIXED_COLS
} from './table/GrowthPredictionTable';
import GrowthPredictionPanel from './GrowthPredictionPanel';
import messages from 'messages';
import GrowthPredictionsMainModal from './modals/GrowthPredictionMainModal';
import { GrowthPredictionModalView } from './modals/growth-prediction-modal-view';
import {
  chartData,
  getGrowthPredictionDataSet,
  parseGroupGrowthPrediction,
  getGroupGrowthPredictionChartData
} from 'utils/growth-prediction';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_GROWTH_PREDICTION_ENTITY_GROUP
} from 'routes/RouteList';
import { QUERY_GET_GROUP_GROWTHPREDICTION } from 'services/aws/growthprediction-query';
import { GrowthPredictionTypes } from 'constants.js';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { Query } from '@apollo/client/react/components';
import Loader from 'components/loader/Loader';

class Group extends Component {
  sortIndex = -1;
  sortDirection = null;

  state = {
    modal: null,
    submitHandler: null,
    averageEntityId: this.props.groupId,
    averageName: '',
    growthpredictionType: GrowthPredictionTypes.DEFAULT,
    testCols: Array(FIXED_COLS)
      .fill()
      .map(() => ({})),
    sidePanelData: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.groupId !== prevProps.groupId) {
      this.setState({
        testCols: Array(FIXED_COLS)
          .fill()
          .map(() => ({}))
      });
    }
  }

  toggleSelectGroupModal = (view, isMaster, submitHandler) => {
    this.setState({
      modal: this.state.modal ? null : view,
      modalMaster: isMaster,
      submitHandler: this.state.modal ? null : submitHandler
    });
  };

  onChangeCol = (colIndex, id) => {
    const { testCols } = this.state;
    testCols[colIndex] = { id };
    this.setState({ testCols });
  };

  onClickCell = (colIndex, row) => {
    if (colIndex !== 2) {
      let subTitle = '';
      let chartDataPanel = chartData;
      try {
        const colitem = this.groupBenchmarkData.dataSet.colFilterItems.find(
          i => i.id === colIndex
        );
        subTitle = colitem.label;

        chartDataPanel = getGroupGrowthPredictionChartData(row, colIndex);
      } catch (er) {
        Sentry.captureException(er);
      }

      this.setState({
        sidePanelData: {
          title: row.label,
          subTitle,
          chartData: chartDataPanel
        }
      });
    }
  };

  onChangeGrowthPredictionType = growthpredictionType => {
    this.setState({ growthpredictionType });
  };

  onChangeGrowthPrediction = benchmarkId => {
    const { testSetId } = this.props;
    this.updateGrowthPredictionRoute(testSetId, benchmarkId);
  };

  updateGrowthPredictionRoute = (testSetId, benchmarkId) => {
    const {
      authStore: {
        user: { rootEntityId }
      },
      routing: { push },
      groupId
    } = this.props;
    const route = ROUTE_GROWTH_PREDICTION_ENTITY_GROUP.replace(
      ':groupId',
      groupId
    )
      .replace(':popup', 'default')
      .replace(':testSetId', testSetId)
      .replace(':entityId', rootEntityId)
      .replace(':benchmarkId', benchmarkId);
    push(route);

    this.sortIndex = -1;
    this.sortDirection = null;
    this.setState({
      testCols: Array(FIXED_COLS)
        .fill()
        .map(() => ({}))
    });
  };

  onCompleted = data => {
    const growthDataOfGroup = JSON.parse(data.getGrowthDataOfGroup);
    if (!growthDataOfGroup || growthDataOfGroup.length === 0) {
      const {
        groupId,
        routing: { push },
        intl
      } = this.props;
      this.setState({
        alertData: {
          dismissHandler: () => this.onCloseAlert(),
          okHandler: () =>
            push(ROUTE_SPORTERS_ENTITY.replace(':entityId', groupId)),
          okLabel: intl.formatMessage(
            messages.benchmarksAlertButtonGoToSporters
          ),
          dismissLabel: intl.formatMessage(
            messages.benchmarksAlertButtonGoBack
          ),
          message: intl.formatMessage(messages.benchmarksAlertMessageEmptyGroup)
        }
      });
      return;
    }
    const groupGrowthPredictionData =
      parseGroupGrowthPrediction(growthDataOfGroup);

    const sportersCount = groupGrowthPredictionData.dataSet.rows.length;
    if (!sportersCount) {
      const {
        groupId,
        routing: { push },
        intl
      } = this.props;
      this.setState({
        alertData: {
          dismissHandler: () => this.onCloseAlert(),
          okHandler: () =>
            push(ROUTE_SPORTERS_ENTITY.replace(':entityId', groupId)),
          okLabel: intl.formatMessage(
            messages.growthPredictionAlertButtonGoToSporters
          ),
          dismissLabel: intl.formatMessage(
            messages.growthPredictionAlertButtonGoBack
          ),
          message: intl.formatMessage(
            messages.growthPredictionAlertMessageEmptyGroup
          )
        }
      });
    }
  };

  onChangeTestSet = testSetId => {
    const { growthpredictionId } = this.props;
    this.updateGrowthPredictionRoute(testSetId, growthpredictionId);
  };

  onSorted = ({ sortIndex, sortDirection }) => {
    this.sortIndex = sortIndex;
    this.sortDirection = sortDirection;
  };

  onExportGrowthPrediction = async () => {
    const {
      uiState,
      testSetId,
      authStore: {
        user: { testSets },
        user,
        entity
      }
    } = this.props;
    uiState.increasePendingRequest();
    const printData = JSON.parse(JSON.stringify(this.dataSet));
    printData.cols.forEach((r, i) => {
      if (i !== this.sortIndex) {
        r.sort = 'sort';
      } else {
        r.sort = `sort-${this.sortDirection}`;
      }
    });
    printData.cols = printData.cols.filter(c => c.id !== undefined);
    printData.type = this.state.growthpredictionType;
    printData.group = this.groupGrowthPredictionData.groupEntity.name;
    const currentTs = testSets.find(ts => ts.id === testSetId);
    printData.testSet = currentTs.subtype;
    printData.organisation = user.getEntityName(entity);
    uiState.decreasePendingRequest();
  };

  onClosePanel = () => this.setState({ sidePanelData: null });

  onCloseAlert = () => {
    this.setState({ alertData: null });
    this.toggleSelectGroupModal(
      GrowthPredictionModalView.GROWTH_PREDICTION_GROUP,
      true
    );
  };

  render() {
    const { state, props, ...methods } = this;
    const {
      groupId,
      authStore: { user },
      testSetId
    } = props;
    const {
      modal,
      testCols,
      submitHandler,
      modalMaster,
      sidePanelData,
      alertData,
      growthPredictionType
    } = state;

    return (
      <Fragment>
        {modal && (
          <GrowthPredictionsMainModal
            entityId={user.rootEntityId}
            onCloseHandler={() => this.toggleSelectGroupModal()}
            view={modal}
            master={modalMaster}
            onSubmitHandler={submitHandler}
          />
        )}
        <Query
          query={QUERY_GET_GROUP_GROWTHPREDICTION}
          variables={{
            entityId: groupId
          }}
          fetchPolicy="cache-and-network"
          onCompleted={this.onCompleted}
        >
          {result => {
            const { loading, error, data } = result;
            if (loading) return <Loader />;
            if (error) return <RetryPanel />;

            const growthDataOfGroup = JSON.parse(data.getGrowthDataOfGroup);
            if (!growthDataOfGroup) {
              return <RetryPanel />;
            }

            if (growthDataOfGroup.length > 0) {
              const groupBenchmarkData =
                parseGroupGrowthPrediction(growthDataOfGroup) || [];

              const sportersCount = groupBenchmarkData.dataSet.rows.length;
              const hasSporters = sportersCount !== 0;
              const dataSet = getGrowthPredictionDataSet(
                testCols,
                groupBenchmarkData.dataSet
              );
              this.groupBenchmarkData = groupBenchmarkData;
              this.groupGrowthPredictionData = groupBenchmarkData;
              this.dataSet = dataSet;

              return (
                <GrowthPredictionPanel
                  testCols={testCols}
                  growthPredictionType={growthPredictionType}
                  testSetId={testSetId}
                  alertData={alertData}
                  sidePanelData={sidePanelData}
                  breadcrumbs={getBreadCrumbs(
                    this.toggleSelectGroupModal,
                    groupBenchmarkData.groupEntity,
                    user
                  )}
                  panelTitle={groupBenchmarkData.groupEntity.name}
                  panelSubtitle={
                    <FormattedMessage
                      {...messages.growthPredictionGroupPageSubtitle}
                      values={{
                        name: groupBenchmarkData.groupEntity.name
                      }}
                    />
                  }
                  growthPredictionTable={
                    hasSporters ? (
                      <GrowthPredictionTable
                        dataSet={dataSet}
                        growthPredictionType={growthPredictionType}
                        onSorted={this.onSorted}
                      />
                    ) : null
                  }
                  {...methods}
                />
              );
            } else {
              return (
                <GrowthPredictionPanel
                  panelTitle=""
                  testCols={testCols}
                  growthPredictionType={growthPredictionType}
                  testSetId={testSetId}
                  alertData={alertData}
                  sidePanelData={sidePanelData}
                  growthPredictionTable={null}
                  {...methods}
                />
              );
            }
          }}
        </Query>
      </Fragment>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore', 'uiState')(observer(Group)))
);

const getBreadCrumbs = (gotoHandler, entity, user) => (
  <Fragment>
    <Breadcrumb noLink>
      <FormattedMessage {...messages.growthPredictionBreadcrumbGroups} />
    </Breadcrumb>
    <Breadcrumb
      onClick={() =>
        gotoHandler(GrowthPredictionModalView.GROWTH_PREDICTION_GROUP, true)
      }
    >
      {user.getEntityName(entity)}
    </Breadcrumb>
    <Breadcrumb
      onClick={() =>
        gotoHandler(GrowthPredictionModalView.GROWTH_PREDICTION_GROUP, true)
      }
    >
      {entity.name}
    </Breadcrumb>
  </Fragment>
);
