import classNames from 'classnames';

const InputErrorMessage = props => {
  return (
    <p
      className={classNames('c-input__error')}
      style={props.style}
      data-qa={`error-message`}
    >
      {props.children}
    </p>
  );
};

export default InputErrorMessage;
