import React, { useContext } from 'react';
import messages from 'messages';
import useMirror from 'hooks/queries/useMirror';
import { StoreContext } from 'index';
import ConfirmButton from 'containers/partials/buttons/ConfirmButton';

const OpenMirrorButton = ({ entityId, person, children, ...props }) => {
  const { uiState } = useContext(StoreContext);

  const { getMirror } = useMirror();

  const mirrorButtonClickHandler = async () => {
    uiState.increasePendingRequest();
    const magicLink = await getMirror({ personId: person.id, entityId });
    if (magicLink) {
      uiState.decreasePendingRequest();
      window.open(magicLink, '_blank');
    }
  };

  return (
    <ConfirmButton
      {...props}
      confirmTitle={messages.modalConfirmOpenMirrotTitle}
      confirmMessage={messages.modalConfirmOpenMirrorMessage}
      confirmAcceptLabel={messages.modalConfirmOpenMirrorAcceptButton}
      confirmCancelLabel={messages.cancelButton}
      onOk={mirrorButtonClickHandler}
    >
      {children}
    </ConfirmButton>
  );
};

export default OpenMirrorButton;
