import { useContext } from 'react';
import { Redirect, Route, Switch, generatePath } from 'react-router-dom';
import {
  ROUTE_GROWTH_ENTITY,
  ROUTE_GROWTH_ENTITY_GROUP,
  ROUTE_GROWTH_ENTITY_POPUP,
  ROUTE_SPORTERS_ENTITY
} from 'routes/RouteList';
import GrowthMainModal from 'containers/pages/growthtracker/modals/GrowthMainModal';
import { StoreContext } from 'index';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import messages from 'messages';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useIntl } from 'react-intl';
import { EntityStoreProvider } from 'containers/pages/persons/store/EntityStoreContext';
import GroupGrowth from 'containers/pages/growthtracker/GroupGrowth';
import { GrowthPanelContextProvider } from 'contexts/GrowthPanelContext';

const GrowthTracker = () => {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);

  if (ability.cannot('read', 'GrowthTracker_v2')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <>
      <Route
        path={ROUTE_GROWTH_ENTITY_POPUP}
        render={props => (
          <GrowthMainModal master entityId={props.match.params.entityId} />
        )}
      />
      <Switch>
        <Route
          path={ROUTE_GROWTH_ENTITY_GROUP}
          render={props => (
            <EntityStoreProvider>
              <GrowthPanelContextProvider
                entityId={props.match.params.entityId}
                groupId={props.match.params.groupId}
              >
                <GroupGrowth groupId={props.match.params.groupId} />
              </GrowthPanelContextProvider>
            </EntityStoreProvider>
          )}
        />
        <Redirect
          to={generatePath(ROUTE_GROWTH_ENTITY, {
            entityId: user.rootEntityId,
            popup: 'popup'
          })}
        />
      </Switch>
    </>
  );
};

export default GrowthTracker;
