export const createTrainingSlice = set => ({
  training: null,
  setTraining: value => set(() => ({ training: value })),

  trainings: [],
  setTrainings: values => set(() => ({ trainings: values })),

  trainingsRef: [],
  setTrainingsRef: values => set(() => ({ trainingsRef: values })),

  trainingsFilter: { search: '', tags: [] },
  setTrainingsFilter: value =>
    set(state => ({ trainingsFilter: { ...state.trainingsFilter, ...value } })),
  resetTrainingsFilter: () =>
    set(() => ({ trainingsFilter: { search: '', tags: [] } })),

  selectedTrainingExercises: [],
  selectTrainingExercises: value =>
    set(state => {
      const selectedTrainingExercises = [...state.selectedTrainingExercises];
      if (!selectedTrainingExercises.find(s => s.id === value.id)) {
        selectedTrainingExercises.push(value);
      } else {
        const index = selectedTrainingExercises.findIndex(
          e => e.id === value.id
        );
        if (index >= 0) {
          selectedTrainingExercises.splice(index, 1);
        }
      }
      return {
        selectedTrainingExercises
      };
    }),
  setSelectedTrainingExercises: values =>
    set(() => ({
      selectedTrainingExercises: values || []
    })),
  unsetSelectedTrainingExercises: exercises =>
    set(state => {
      const selectedTrainingExercises = [...state.selectedTrainingExercises];
      exercises.forEach(exercise => {
        const index = selectedTrainingExercises.findIndex(
          e => e.id === exercise.id
        );
        if (index >= 0) {
          selectedTrainingExercises.splice(index, 1);
        }
      });
      return {
        selectedTrainingExercises
      };
    }),
  resetSelectedTrainingExercises: () =>
    set(() => ({ selectedTrainingExercises: [] })),

  trainingDraggable: false,
  setTrainingDraggable: value => set(() => ({ trainingDraggable: value }))
});
