import { cloneElement, useEffect } from 'react';
import { useContextMenu } from 'contexts/ContextMenuProvider';

const ActionButton = () => {
  const { actionButton } = useContextMenu();

  useEffect(() => {
    return () => {
      // cleanup
      // remove all menu items
    };
  }, [actionButton]);

  if (!actionButton) return null;

  return cloneElement(actionButton);
};

export default ActionButton;
