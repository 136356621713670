import { useState, useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import { useMedicalContext } from 'contexts/MedicalContext';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER
} from 'routes/RouteList';
import ScreeningCustomSelectTestCard from 'containers/partials/cards/ScreeningCustomSelectTestCard';
import ScreeningCustomDetailCard from 'containers/partials/cards/ScreeningCustomDetailCard';
import { getLocalDateFormat } from 'utils/date';
import { useSessionContext } from 'contexts/SessionContext';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function ScheduleReTestScreening() {
  const { entityId, groupId, sporterId, injuryId } = useParams();
  const person = useEntityContext(s => s.person);
  const { medical } = useMedicalContext();
  const { session: screening } = useSessionContext();

  const [personInjury, setPersonInjury] = useState({});

  useEffect(() => {
    if (medical?.injuries) {
      setPersonInjury(medical.injuries.find(i => i.id === injuryId));
    }
  }, [medical, injuryId]);

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId })}
          >
            <FormattedMessage {...messages.breadcrumbSporters} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
              entityId,
              groupId,
              sporterId
            })}
          >
            {`${person.firstname} ${person.lastname}`}
          </Breadcrumb>
          {/* TODO: @maarten > Link to Medical */}
          <Breadcrumb>
            <FormattedMessage {...messages.breadcrumbInjuries} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {/* TODO: @fabian of @maarten translation for custom? */}
            [Custom] {screening.name}
            <PanelTitleButtonsGroup>
              <PanelTitleButton />
            </PanelTitleButtonsGroup>
          </PanelTitle>
          <PanelSubtitle>
            {getLocalDateFormat(screening.startdate)}
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody fullWidth>
        <Tabs fullWidth>
          <TabList>
            <Tab key="screening.detail">
              <FormattedMessage {...messages.rehabScreeningDetailsTab} />
            </Tab>
          </TabList>
          <TabPanel key="screening.detail">
            <Panel>
              <PanelBody>
                <ScreeningCustomSelectTestCard entityId={entityId} />
              </PanelBody>
            </Panel>
            <Panel>
              <PanelBody>
                <ScreeningCustomDetailCard
                  edit={true}
                  personInjury={personInjury}
                  screening={screening}
                />
              </PanelBody>
            </Panel>
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
}

export default ScheduleReTestScreening;
