import TestItemResult from 'models/TestItemResult';

export default class TestData {
  id;
  personId;
  result;
  data;
  formData = {};
  tests;
  sports;
  finished;
  finishedPercentage;
  testDataDate;
  date_updated;
  date_created;
  note;
  testSetVersion;
  testSet;
  resultBenchmarkId;
  constructor({
    id,
    personId,
    result = '[]',
    data = '',
    sports = [],
    date_updated,
    date_created,
    finished,
    finishedPercentage,
    testDataDate,
    notes,
    testSetVersion,
    testSet,
    resultBenchmarkId
  }) {
    this.id = id;
    this.personId = personId;
    try {
      this.result = result ? JSON.parse(result) : [];
    } catch (e) {
      this.result = result ? result : [];
    }
    if (Array.isArray(this.result)) {
      this.result =
        Array.isArray(this.result) &&
        this.result.map(r => {
          return new TestItemResult({ ...r });
        });
    } else if (this.result?.tests && Array.isArray(this.result?.tests)) {
      this.result = { ...this.result };
    }

    try {
      this.data = data ? JSON.parse(data) : [];
    } catch (e) {
      this.data = data ? data : [];
    }

    if (Object.keys(this.data).length !== 0) {
      this.formData = Object.values(this.data).reduce((acc, cur) => {
        acc[Object.keys(cur)[0]] = Object.values(cur)[0];
        return acc;
      }, {});
    } else {
      this.formData = this.result.reduce((acc, cur) => {
        acc[cur.testId] = cur.sides;
        return acc;
      }, {});
    }

    try {
      this.sports = sports ? JSON.parse(sports) : [];
    } catch (e) {
      this.sports = sports ? sports : [];
    }
    this.testDataDate = testDataDate ? new Date(testDataDate) : null;
    this.date_updated = date_updated;
    this.date_created = date_created;
    this.finished = finished;
    this.finishedPercentage = finishedPercentage;
    this.note = notes?.length > 0 ? notes[0] : null;
    this.testSetVersion = testSetVersion;
    this.isTestSetV2 = testSetVersion && testSetVersion === 2;
    this.testSet = testSet;
    this.resultBenchmarkId = resultBenchmarkId;
  }
}
