import { useContext } from 'react';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import { useStore } from 'stores/Store';
import useMyExercises from 'hooks/queries/useMyExercises';
import { useParams } from 'react-router-dom';

const ExercisesForAthletePanel = ({ exerciseId, onClick, onPreview }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId } = useParams();

  const { exercises: myExercises, loading } = useMyExercises(entityId, user);

  const selectExercises = useStore(state => state.selectExercises);
  const selectedExercises = useStore(state => state.selectedExercises);

  return (
    <>
      {loading && <Loader />}
      <ExercisesListVirtualized
        entityId={entityId}
        loading={loading}
        checkable
        exercises={myExercises}
        activeId={exerciseId}
        onPreview={exercise => onPreview && onPreview(exercise)}
        enablePreviewModal={false}
        showTemplateCount={user.isGSVOrg}
        showLevel={!user.isGSVOrg}
        selectedExercises={selectedExercises}
        onClick={id => {
          onClick && onClick(id);
        }}
        onSelect={exercise => {
          selectExercises(exercise);
        }}
      />
    </>
  );
};

export default ExercisesForAthletePanel;
