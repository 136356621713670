import gql from 'graphql-tag';

export const QUERY_GET_TESTSETS = gql`
  query getTestSetsV2(
    $entityId: ID!
    $type: TestSetV2Type
    $types: [TestSetV2Type]
    $injuryId: ID
  ) {
    getTestSetsV2(
      entityId: $entityId
      type: $type
      types: $types
      injuryId: $injuryId
    ) {
      id
      title
      note
      version
      originalId
      editable
      type
      preventionType
      meta
      testSetVersion
      sport {
        id
        title
      }
      injury {
        id
        title
      }
    }
  }
`;

export const QUERY_GET_TESTSETS_LIST = gql`
  query getTestSetsV2(
    $entityId: ID!
    $type: TestSetV2Type
    $types: [TestSetV2Type]
    $injuryId: ID
  ) {
    getTestSetsV2(
      entityId: $entityId
      type: $type
      types: $types
      injuryId: $injuryId
    ) {
      id
      title
      type
      version
      testSetVersion
      originalId
      config
      meta
      defaultBenchmark {
        id
      }
    }
  }
`;

export const QUERY_GET_TESTSETS_LIST_WITH_TESTS = gql`
  query getTestSetsV2(
    $entityId: ID!
    $type: TestSetV2Type
    $types: [TestSetV2Type]
    $injuryId: ID
  ) {
    getTestSetsV2(
      entityId: $entityId
      type: $type
      types: $types
      injuryId: $injuryId
    ) {
      id
      title
      type
      version
      testSetVersion
      tests {
        id
      }
    }
  }
`;

export const QUERY_GET_TESTSETS_WITH_SPORT = gql`
  query getTestSetsV2(
    $entityId: ID!
    $type: TestSetV2Type
    $types: [TestSetV2Type]
    $injuryId: ID
  ) {
    getTestSetsV2(
      entityId: $entityId
      type: $type
      types: $types
      injuryId: $injuryId
    ) {
      id
      title
      note
      version
      originalId
      editable
      type
      preventionType
      sport {
        id
        title
      }
      meta
      testSetVersion
      #      tests {
      #        id
      #        dependedTestIds
      #      }
    }
  }
`;

export const QUERY_GET_TESTSET = gql`
  query getTestSetV2($testSetId: ID!, $entityId: ID) {
    getTestSetV2(testSetId: $testSetId, entityId: $entityId) {
      id
      title
      note
      version
      originalId
      type
      tests {
        id
        title
        category
        dependedTestIds
        laterality
        duration
        protocol
        video_link
        mux_link
        dominant_only
        testItems {
          id
          duration
          unit
          laterality
          input_type
          input_type_parameters
          order
          showDecimals
          showScore
          showPercentage
        }
      }
      editable
      preventionType
      sport {
        id
        title
      }
      injury {
        id
        title
      }
      defaultBenchmark {
        id
        title
      }
      meta
    }
  }
`;

export const QUERY_GET_TESTSET_TESTS = gql`
  query getTestSetV2($testSetId: ID!, $entityId: ID) {
    getTestSetV2(testSetId: $testSetId, entityId: $entityId) {
      id
      title
      tests {
        id
      }
    }
  }
`;

export const QUERY_GET_TESTS = gql`
  query getTests($type: TestSetV2Type, $entityId: ID) {
    getTests(type: $type, entityId: $entityId) {
      id
      category
      title
      dependedTestIds
      laterality
      testItems {
        id
        title
        duration
        laterality
        hidden
        order
      }
      video_link
      mux_link
      duration
      protocol
      dominant_only
    }
  }
`;

export const QUERY_GET_ENTITY_TESTSETS = gql`
  query getEntity($id: String!) {
    getEntity(id: $id) {
      id
      testSets {
        id
        name
        type
        subtype
        config
        version
        benchmarkFolder
      }
    }
  }
`;

export const MUTATION_ADD_TESTSET = gql`
  mutation addTestSetV2(
    $entityId: ID!
    $title: String!
    $note: String
    $testIds: [String]
    $defaultBenchmarkId: ID
    $customBenchmarkId: ID
    $sportId: ID
    $injuryId: ID
    $type: TestSetV2Type
    $preventionType: PreventionType
    $meta: AWSJSON
  ) {
    addTestSetV2(
      input: {
        entityId: $entityId
        title: $title
        note: $note
        testIds: $testIds
        defaultBenchmarkId: $defaultBenchmarkId
        customBenchmarkId: $customBenchmarkId
        sportId: $sportId
        injuryId: $injuryId
        type: $type
        preventionType: $preventionType
        meta: $meta
      }
    ) {
      id
      title
      note
      version
      type
    }
  }
`;

export const MUTATION_EDIT_TESTSET = gql`
  mutation editTestSetV2(
    $id: ID!
    $title: String!
    $note: String
    $testIds: [String]
    $defaultBenchmarkId: ID
    $customBenchmarkId: ID
    $sportId: ID
    $injuryId: ID
    $type: TestSetV2Type
    $preventionType: PreventionType
    $forceUpdate: Boolean = false
    $meta: AWSJSON
  ) {
    editTestSetV2(
      input: {
        id: $id
        title: $title
        note: $note
        testIds: $testIds
        defaultBenchmarkId: $defaultBenchmarkId
        customBenchmarkId: $customBenchmarkId
        sportId: $sportId
        injuryId: $injuryId
        type: $type
        preventionType: $preventionType
        meta: $meta
      }
      forceUpdate: $forceUpdate
    ) {
      id
    }
  }
`;

export const MUTATION_ARCHIVE_TESTSET = gql`
  mutation archiveTestSetV2($testSetId: ID!) {
    archiveTestSetV2(testSetId: $testSetId)
  }
`;

export const MUTATION_COPY_TESTSET = gql`
  mutation addTestSetV2(
    $entityId: ID!
    $title: String!
    $note: String
    $testIds: [String]
    $originalId: ID
    $defaultBenchmarkId: ID
    $customBenchmarkId: ID
    $sportId: ID
    $injuryId: ID
    $type: TestSetV2Type
    $preventionType: PreventionType
    $meta: AWSJSON
  ) {
    addTestSetV2(
      input: {
        entityId: $entityId
        title: $title
        note: $note
        testIds: $testIds
        originalId: $originalId
        defaultBenchmarkId: $defaultBenchmarkId
        customBenchmarkId: $customBenchmarkId
        sportId: $sportId
        injuryId: $injuryId
        type: $type
        preventionType: $preventionType
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const QUERY_GET_TESTS_BY_SPORT_AND_INJURIES = gql`
  query getTestsBySportAndInjuries(
    $sportId: ID!
    $injuryIds: [ID]
    $type: TestSetV2Type!
    $preventionType: PreventionType
  ) {
    getTestsBySportAndInjuries(
      sportId: $sportId
      injuryIds: $injuryIds
      type: $type
      preventionType: $preventionType
    ) {
      id
      category
      title
      testItems {
        id
        duration
        unit
        laterality
        order
      }
      duration
      dependedTestIds
      laterality
      trials
      video_link
      mux_link
      protocol
      milestones
    }
  }
`;

export const QUERY_GET_TESTS_BY_TAGS = gql`
  query getTestsByTags($tagIds: [ID]) {
    getTestsByTags(tagIds: $tagIds) {
      id
      category
      title
      testItems {
        id
        duration
        unit
        laterality
      }
      duration
      dependedTestIds
      laterality
      trials
      video_link
      mux_link
      milestones
    }
  }
`;
