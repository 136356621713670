import React from 'react';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { MUTATION_REMOVE_UPLOAD_FILE } from 'services/aws/file-upload';

const RemovePersonFileButton = ({
  entityId,
  item,
  children,
  onDeleted,
  refetchQueries = [],
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_REMOVE_UPLOAD_FILE}
      confirmTitle={messages.modalConfirmRemoveFileTitle}
      confirmMessage={messages.modalConfirmRemoveThisFileMessage}
      variables={{
        fileId: item.id,
        entityId
      }}
      refetchQueries={refetchQueries}
      update={(cache, { data: { archiveFile } }) => {
        if (archiveFile) {
          onDeleted && onDeleted(item.id);
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemovePersonFileButton;
