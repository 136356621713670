import { useEffect, useState } from 'react';
import ListHeader from 'components/list/ListHeader';
import { SORT_DIRECTION } from 'utils/sort';
import ListColumnTitle from 'components/list/ListColumnTitle';
import RefactoredListHeader from './RefactoredListHeader';

const SortableListHeader = ({
  sticky,
  topPosition,
  columns,
  onSort,
  refactoring
}) => {
  const [activeSort, setActiveSort] = useState(null);

  useEffect(() => {
    if (onSort && activeSort) onSort(activeSort);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSort]);

  const getStyleListItemHeader = extraProps => {
    let result;

    switch (extraProps?.id) {
      case 'listItemStatus':
        result = 'c-list__col--status';
        break;
      case 'listItemMainLabel':
        result = 'c-list__col--main-label';
        break;
      case 'listItemLastLabel':
        result = 'c-list__col--last-label';
        break;

      default:
        result = 'u-hidden';
        break;
    }
    return result;
  };

  return refactoring ? (
    <RefactoredListHeader sticky>
      {columns.map(col => {
        const active = activeSort?.key === col.key;
        let desc = active ? activeSort.desc : col.dir === SORT_DIRECTION.DESC;

        return (
          <ListColumnTitle
            extraClassNames={
              col.extraProps && getStyleListItemHeader(col.extraProps)
            }
            key={col.key}
            ascendent={!desc && active}
            descendent={desc && active}
            unClickable={!col.sortable}
            onClick={() => {
              col.sortable &&
                setActiveSort({
                  key: col.key,
                  desc: active ? !desc : desc,
                  dataType: col.dataType
                });
            }}
          >
            {col.label}
          </ListColumnTitle>
        );
      })}
    </RefactoredListHeader>
  ) : (
    <ListHeader
      sticky={sticky}
      style={
        sticky &&
        topPosition &&
        !isNaN(topPosition) && { top: `${topPosition}px` }
      }
    >
      {columns.map(col => {
        const active = activeSort?.key === col.key;
        let desc = active ? activeSort.desc : col.dir === SORT_DIRECTION.DESC;

        return (
          <ListColumnTitle
            extraClassNames={
              col.extraProps && getStyleListItemHeader(col.extraProps)
            }
            key={col.key}
            ascendent={!desc && active}
            descendent={desc && active}
            unClickable={!col.sortable}
            onClick={() => {
              col.sortable &&
                setActiveSort({
                  key: col.key,
                  desc: active ? !desc : desc,
                  dataType: col.dataType
                });
            }}
          >
            {col.label}
          </ListColumnTitle>
        );
      })}
    </ListHeader>
  );
};

export default SortableListHeader;
