import Icon from 'components/icon/Icon';
import { Button, ButtonExpandable } from 'components/button';

const AddButton = ({ iconId, label, expandable, onClick }) => {
  return expandable ? (
    <ButtonExpandable
      label={label}
      onClick={onClick}
      icon={iconId && <Icon id={iconId} />}
    />
  ) : (
    <Button rounded primary onClick={() => onClick()}>
      <Icon id={iconId ? iconId : 'add-white'} />
      {label}
    </Button>
  );
};

export default AddButton;
