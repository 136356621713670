import React, { Fragment, useState } from 'react';
import classNames from 'classnames';

const AccordionButtonHooks = props => {
  const [checked, setChecked] = useState(props.defaultChecked);

  return (
    <Fragment>
      <input
        className={classNames('c-accordion__button', props.extraClassNames)}
        type={props.checkbox ? 'checkbox' : props.radio ? 'radio' : ''}
        name={props.name}
        id={props.id}
        defaultChecked={(props.checkbox || props.radio) && props.defaultChecked}
        checked={(props.checkbox || props.radio) && props.checked}
        onChange={
          props.isControlled
            ? () => props.onChange(!props.checked)
            : () => setChecked(!checked)
        }
        data-qa={`${props.dataQa}-accordion-trigger`}
      />
      <label
        className={classNames('c-accordion__label', props.extraClassNames, {
          'c-accordion__label-empty': props.selectableButton,
          'c-accordion__label-selfclosing': props.checkbox || props.radio,
          'c-accordion__label-table': props.tableTrigger
          // 'is-open': checked
        })}
        htmlFor={props.id}
        // style={props.checked && { border: '1px solid red' }}
      >
        {!props.selectableButton && !props.customClickableElement && (
          <span>{props.children}</span>
        )}
      </label>

      {props.selectableButton && (
        <button
          disabled={props.disabled}
          className={classNames('c-button c-button--menu c-accordion__label', {
            'c-button--disabled': props.disabled
          })}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      )}
    </Fragment>
  );
};

export default AccordionButtonHooks;
