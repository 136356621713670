import gql from 'graphql-tag';

export const QUERY_GET_TEMPLATES = gql`
  query getExerciseProgramTemplates(
    $entityId: ID!
    $type: ExerciseProgramTemplateType
  ) {
    getExerciseProgramTemplates(entityId: $entityId, type: $type) {
      id
      entityId
      title
      type
      exercises {
        id
      }
      tags {
        id
        label
      }
      duration
      editable
      personId
      meta
    }
  }
`;

export const QUERY_GET_TEMPLATE = gql`
  query getExerciseProgramTemplate($templateId: ID!) {
    getExerciseProgramTemplate(templateId: $templateId) {
      id
      entityId
      personId
      title
      type
      exercises {
        id
        parentId
        title
        note
        cues
        protocol
        tags {
          id
          label
        }
        media {
          id
          filename
          signedDownloadUrl
          linkId
          status
          meta
          type
        }
      }
      tags {
        id
        label
      }
      duration
      editable
      notes {
        id
        linkId
        linkId2
        linkId3
        note
        noteType
        meta
        date_created
      }
      meta
    }
  }
`;

export const MUTATION_ADD_TEMPLATE = gql`
  mutation addExerciseProgramTemplate(
    $entityId: ID!
    $title: String!
    $exerciseIds: [ID]
    $tagIds: [ID]
    $duration: Int
    $meta: AWSJSON
    $type: ExerciseProgramTemplateType
  ) {
    addExerciseProgramTemplate(
      input: {
        entityId: $entityId
        title: $title
        exerciseIds: $exerciseIds
        tagIds: $tagIds
        duration: $duration
        meta: $meta
        type: $type
      }
    ) {
      id
    }
  }
`;

export const MUTATION_EDIT_TEMPLATE = gql`
  mutation editExerciseProgramTemplate(
    $id: ID!
    $entityId: ID!
    $title: String!
    $exerciseIds: [ID]
    $tagIds: [ID]
    $duration: Int
    $meta: AWSJSON
    $type: ExerciseProgramTemplateType
  ) {
    editExerciseProgramTemplate(
      input: {
        id: $id
        entityId: $entityId
        title: $title
        exerciseIds: $exerciseIds
        tagIds: $tagIds
        duration: $duration
        meta: $meta
        type: $type
      }
    ) {
      id
      title
    }
  }
`;

export const MUTATION_ARCHIVE_TEMPLATE = gql`
  mutation archiveExerciseProgramTemplate($templateId: ID!) {
    archiveExerciseProgramTemplate(exerciseTemplateId: $templateId)
  }
`;

export const MUTATION_ADD_EXERCISE_TO_TEMPLATES = gql`
  mutation addExerciseToProgramTemplates(
    $exerciseId: ID!
    $programTemplateIds: [ID]
  ) {
    addExerciseToProgramTemplates(
      exerciseId: $exerciseId
      programTemplateIds: $programTemplateIds
    )
  }
`;

export const MUTATION_ADD_EXERCISES_TO_TEMPLATES = gql`
  mutation addExercisesToProgramTemplates(
    $exerciseIds: [ID]!
    $programTemplateIds: [ID]
  ) {
    addExercisesToProgramTemplates(
      exerciseIds: $exerciseIds
      programTemplateIds: $programTemplateIds
    )
  }
`;

export const MUTATION_REMOVE_EXERCISES_FROM_TEMPLATES = gql`
  mutation removeExercisesFromProgramTemplates(
    $exerciseIds: [ID]!
    $programTemplateIds: [ID]
  ) {
    removeExercisesFromProgramTemplates(
      exerciseIds: $exerciseIds
      programTemplateIds: $programTemplateIds
    )
  }
`;
