import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import SporterReportCard from 'containers/partials/cards/SporterReportCard';
import useTabIndex from 'hooks/utils/useTabIndex';
import PersonCard from 'containers/partials/cards/PersonCard';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const SporterTabsLegacy = () => {
  const { push } = useHistory();
  const { entityId, sessionsTab, sessionId, sporterId } = useParams();
  const [tabIndex, setTabIndex] = useTabIndex([
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT
    ],
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT
    ]
  ]);
  const person = useEntityContext(s => s.person);

  const onCancel = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL, {
        sessionId,
        sessionsTab,
        entityId,
        sporterId
      })
    );
  };

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <Tab
          key="session.sporter.data"
          onClick={() =>
            push(
              generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS, {
                sessionId,
                sessionsTab,
                entityId,
                sporterId
              })
            )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </Tab>

        <Tab
          key="session.sporter.global"
          onClick={() =>
            push(
              generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL, {
                sessionId,
                sessionsTab,
                entityId,
                sporterId
              })
            )
          }
        >
          <FormattedMessage {...messages.tabSessionGlobal} />
        </Tab>
      </TabList>

      <TabPanel key="session.sporter.data">
        <TabPanelBody withScroller>
          <SporterReportCard entityId={entityId} />
        </TabPanelBody>
      </TabPanel>

      <TabPanel key="session.sporter.global">
        <TabPanelBody withScroller>
          <PersonCard entityId={entityId} person={person} onCancel={onCancel} />
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

export default SporterTabsLegacy;
