import React, { useEffect, useState } from 'react';
import PreviewButton from '../buttons/PreviewButton';
import { Button, ButtonsGroup } from 'components/button';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import useExercise from 'hooks/queries/useExercise';
import { useDebounce } from '@uidotdev/usehooks';
import { useStore } from 'stores/Store';
import Exercise from 'models/Exercise';

const getNote = (exercise, notes) => {
  if (!notes) return;
  if (notes.length > 0) {
    return notes[0];
  }
  if (exercise.note) {
    return { note: exercise.note };
  }
  return '';
};

const ExerciseListItem = ({
  entityId,
  uid,
  exercise,
  notes,
  checkbox,
  active,
  clickable,
  onClick,
  onChange,
  visible,
  onShowPreview,
  showNote,
  showLevel,
  showTemplateCount,
  showSharesAndAnnotations,
  showOwner,
  statusIcon,
  done,
  inProgress,
  style,
  checked,
  showWarning,
  showMessage,
  virtualRow,
  exerciseGroup
}) => {
  const [exerciseItem, setExercise] = useState(exercise);
  const [note, setNote] = useState({ note: exercise.note });
  const intl = useIntl();
  const [isOver, setIsOver] = useState(false);
  const debouncedHover = useDebounce(isOver, 300);
  const updateExercise = useStore(state => state.updateExercise);

  const { fetchExercise } = useExercise(entityId);

  useEffect(() => {
    if (exercise) {
      setExercise(exercise);
    }
  }, [exercise]);

  useEffect(() => {
    const getExercise = async () => {
      const exerciseData = await fetchExercise(exercise.id);
      if (exerciseData?.id) {
        updateExercise(exerciseData.id, exerciseData);
        setExercise(new Exercise({ ...exerciseData }));
      }
    };
    if (debouncedHover && exercise?.id) {
      getExercise();
    }
  }, [debouncedHover]);

  const onHover = async () => {
    setIsOver(true);
  };

  const onOut = () => {
    setIsOver(false);
  };

  useEffect(() => {
    if (notes) {
      setNote(getNote(exerciseItem, notes));
    }
  }, [notes]);

  return (
    <ListItem
      active={active}
      checkbox={checkbox}
      onClick={onClick}
      onHover={onHover}
      onOut={onOut}
      clickable={clickable}
      style={style}
      draggable
      done={done}
      inProgress={inProgress}
      outfaded={showWarning || showMessage}
    >
      <ListItemStatus
        itemId={uid}
        visible={visible}
        checked={checked}
        onChange={onChange}
        statusIcon={statusIcon ? statusIcon : 'exercise'}
        mediumIcon={!statusIcon || statusIcon === 'exercise'}
      />

      <ListItemLabelWrapper
        extraClassNames={
          exerciseItem.level
            ? 'c-list__item-label-with-supertitle'
            : 'u-padding-vertical-small'
        }
      >
        <ListItemLabel labelNotePreview>
          <span className="c-list__item-title c-list__item-title-ellipsed">
            {exerciseItem.title}
          </span>
        </ListItemLabel>

        {((note && showNote) || (exerciseItem.level > 0 && showLevel)) && (
          <div className="c-list__item-small-editing-area c-list__item-wrapping-note-preview">
            <p>{showNote ? note.note : `Level ${exerciseItem.level}`}</p>
            {exerciseItem?.media?.length > 0 && onShowPreview && (
              <ButtonsGroup verticalCenter>
                <PreviewButton
                  media={exerciseItem.media}
                  showPreview={onShowPreview}
                />
              </ButtonsGroup>
            )}
          </div>
        )}

        {showTemplateCount && (
          <ListItemLabel info>
            {exerciseItem.templateCount ? (
              <>
                {exerciseItem.templateCount}
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="bookmark"
                  fillColor="color-primary"
                  strokeColor="color-primary"
                />
              </>
            ) : (
              <>
                0
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="bookmark"
                  fillColor="transparent"
                  strokeColor="color-neutral-dark"
                />
              </>
            )}
          </ListItemLabel>
        )}

        {showOwner && exerciseItem?.person && !showWarning && !showMessage && (
          <ListItemLabel info style={{ width: 'auto' }}>
            <FormattedMessage
              {...messages.videoSharedBy}
              values={{
                person: `${exerciseItem?.person.firstname} ${exerciseItem?.person.lastname}`
              }}
            />
          </ListItemLabel>
        )}

        {(showWarning || showMessage) && (
          <ListItemLabel info style={{ width: 'auto', marginRight: -8 }}>
            {showWarning ? (
              <Button small light onClick={onClick}>
                <FormattedMessage {...messages.videoNotInLesson} />
              </Button>
            ) : (
              showMessage && <FormattedMessage {...messages.videoUpload} />
            )}
          </ListItemLabel>
        )}

        {showSharesAndAnnotations &&
          !showOwner &&
          !showWarning &&
          !showMessage && (
            <div className="c-list__item-double-icon-info">
              <ListItemLabel info>
                <span className="c-list__item-secondary-text-value">
                  {exerciseItem?.shares?.length}
                </span>
                <span
                  className="c-list__item-secondary-text-value-unit"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      intl.formatMessage(messages.annotationSharedTimes)
                    )
                  }}
                ></span>
                <Icon extraClassNames="u-margin-left-tiny" id="profile" />
              </ListItemLabel>
              <ListItemLabel info>
                <span className="c-list__item-secondary-text-value">
                  {exerciseItem?.annotations?.length}
                </span>
                <span className="c-list__item-secondary-text-value-unit u-margin-left-tiny">
                  <FormattedMessage
                    {...messages.annotationLength}
                    values={{ length: exerciseItem?.annotations?.length }}
                  />
                </span>
                <Icon
                  extraClassNames="u-margin-left-tiny"
                  id="comment"
                  fillColor="transparent"
                  strokeColor="color-neutral-dark"
                />
              </ListItemLabel>
            </div>
          )}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default ExerciseListItem;

ExerciseListItem.propTypes = {
  uid: PropTypes.string.isRequired
};
