export const createProgramSlice = set => ({
  program: null,
  setProgram: value => set(() => ({ program: value })),

  programs: [],
  setPrograms: values => set(() => ({ programs: values })),

  programsRef: [],
  setProgramsRef: values => set(() => ({ programsRef: values })),

  programsFilter: [],
  setProgramsFilter: values =>
    set(state => ({ programsFilter: { ...state.programsFilter, ...values } })),
  resetProgramsFilter: () => set(() => ({ programsFilter: [] })),

  selectedProgramExercises: [],
  selectProgramExercises: value =>
    set(state => {
      const selectedProgramExercises = [...state.selectedProgramExercises];
      if (!selectedProgramExercises.find(s => s.id === value.id)) {
        selectedProgramExercises.push(value);
      } else {
        const index = selectedProgramExercises.findIndex(
          e => e.id === value.id
        );
        if (index >= 0) {
          selectedProgramExercises.splice(index, 1);
        }
      }
      return {
        selectedProgramExercises
      };
    }),
  setSelectedProgramExercises: values =>
    set(() => ({
      selectedProgramExercises: values
    })),
  unsetSelectedProgramExercises: exercises =>
    set(state => {
      const selectedProgramExercises = [...state.selectedProgramExercises];
      exercises.forEach(exercise => {
        const index = selectedProgramExercises.findIndex(
          e => e.id === exercise.id
        );
        if (index >= 0) {
          selectedProgramExercises.splice(index, 1);
        }
      });
      return {
        selectedProgramExercises
      };
    }),
  resetSelectedProgramExercises: () =>
    set(() => ({ selectedProgramExercises: [] })),
  programSortableActive: false,
  setProgramSortableActive: value =>
    set(() => ({ programSortableActive: value })),

  selectedProgramPersons: [],
  selectProgramPersons: value =>
    set(state => {
      const selectedProgramPersons = [...state.selectedProgramPersons];
      if (!selectedProgramPersons.find(s => s.id === value.id)) {
        selectedProgramPersons.push(value);
      } else {
        const index = selectedProgramPersons.findIndex(e => e.id === value.id);
        if (index >= 0) {
          selectedProgramPersons.splice(index, 1);
        }
      }
      return {
        selectedProgramPersons
      };
    }),
  setSelectedProgramPersons: values =>
    set(() => ({
      selectedProgramPersons: values
    }))
});
