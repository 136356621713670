import ExerciseModel from 'models/Exercise';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_EXERCISE } from 'services/aws/exercises-query';
import { useStore } from 'stores/Store';

const useExercise = entityId => {
  const updateExercise = useStore(state => state.updateExercise);
  const [getExercise, { loading, error }] = useLazyQuery(QUERY_GET_EXERCISE);

  const fetchExercise = async (id, update) => {
    const fetchPolicy = update ? 'network-only' : 'cache-first';
    const { data } = await getExercise({
      variables: { entityId, id },
      fetchPolicy,
      skip: !id || !entityId
    });

    if (data?.getExercise) {
      const exerciseData = data.getExercise;
      const exercise = new ExerciseModel({
        ...exerciseData,
        thumbFileId: exerciseData?.thumb?.id,
        videoFileId: exerciseData?.video?.id,
        entityId
      });
      updateExercise(exercise.id, exercise);
      return exercise;
    }
  };

  return { loading, error, fetchExercise, getExercise };
};

export default useExercise;
