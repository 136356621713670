import React, { useContext, useEffect, useRef } from 'react';
import { useStore } from 'stores/Store';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE } from 'routes/RouteList';
import { Button } from 'components/button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import Icon from 'components/icon/Icon';
import ProgramForm from 'containers/pages/exercises/programs/ProgramForm';
import ProgramExercisesListSortable from 'containers/pages/exercises/programs/ProgramExercisesListSortable';
import {
  useAddActionButton,
  useSetMenuItems
} from 'contexts/ContextMenuProvider';
import RemoveExercisesFromProgramButton from 'containers/partials/buttons/RemoveExercisesFromProgramButton';
import SortProgramExercisesButton from 'containers/partials/buttons/SortProgramExercisesButton';
import AddButton from 'containers/partials/buttons/AddButton';
import { sortByArray } from 'utils/sort';
import { useClickAway } from '@uidotdev/usehooks';
import { StoreContext } from 'index';
import { MODAL_TYPES } from 'models/ModalData';
import Program from 'models/Program';
import { isMzhUserAgent } from 'utils/browser';

const ProgramExercisesTab = ({ onAdd, onUse, editableNotes }) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const { entityId, programId } = useParams();
  const { push } = useHistory();
  const setMenuItems = useSetMenuItems();
  const addActionButton = useAddActionButton();

  const programFormRef = useRef();
  const defaultSort = useRef([]);

  const program = useStore(state => state.program);
  const setProgram = useStore(state => state.setProgram);
  const selectedProgramExercises = useStore(
    state => state.selectedProgramExercises
  );
  const setSelectedProgramExercises = useStore(
    state => state.setSelectedProgramExercises
  );
  const selectProgramExercises = useStore(
    state => state.selectProgramExercises
  );
  const programSortableActive = useStore(state => state.programSortableActive);
  const setProgramSortableActive = useStore(
    state => state.setProgramSortableActive
  );
  const sortableContainerRef = useClickAway(() => {
    if (programSortableActive) {
      uiState.showModal({
        title: intl.formatMessage(
          messages.exercisesSortExercisesModalWarningTitle
        ),
        message: intl.formatMessage(
          messages.exercisesSortExercisesModalWarningMessage
        ),
        okLabel: intl.formatMessage(messages.saveButton),
        dismissLabel: intl.formatMessage(messages.buttonBack),
        type: MODAL_TYPES.WARNING,
        okHandler: () => {
          programFormRef.current.submitForm();
        }
      });
    }
  });

  useEffect(() => {
    defaultSort.current = program.exercises.map(exercise => exercise.id);
    setSelectedProgramExercises([]);
  }, [program]);

  const AddExerciseButton = ({ onClick }) => {
    return (
      <Button
        key={'programAddExercises'}
        menu
        onClick={() => {
          onAdd(program);
          onClick && onClick();
        }}
      >
        <Icon id="add" />
        <FormattedMessage {...messages.exercisesAddExercisesButton} />
      </Button>
    );
  };

  const UseProgramButton = ({ onClick }) => {
    return (
      <Button
        menu
        onClick={() => {
          onUse(program);
          onClick && onClick();
        }}
      >
        <Icon
          id="use-template"
          style={{
            width: 20,
            height: 20,
            left: 12,
            top: 6
          }}
        />
        <FormattedMessage {...messages.exercisesCreateTemplateButton} />
      </Button>
    );
  };

  useEffect(() => {
    const contextButtons = [
      <AddExerciseButton />,
      <UseProgramButton />,
      <RemoveExercisesFromProgramButton
        entityId={entityId}
        program={program}
      />,
      <SortProgramExercisesButton menu />
    ];
    setMenuItems(contextButtons);

    addActionButton(
      <AddButton
        expandable
        onClick={() => onAdd(program)}
        label={<FormattedMessage {...messages.exercisesAddExercisesButton} />}
      />
    );

    return () => {
      setMenuItems([]);
      addActionButton(null);
    };
  }, [program]);

  const onClickHandler = exerciseId => {
    push(
      generatePath(ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE, {
        entityId,
        programId,
        exerciseId
      })
    );
  };

  const onCancel = () => {
    setProgramSortableActive(false);
    setProgram(
      new Program({
        ...program,
        exercises: sortByArray(program.exercises, defaultSort.current)
      })
    );
  };

  return (
    <div
      ref={sortableContainerRef}
      className="o-flex o-flex--column"
      style={{
        flex: 1,
        marginBottom: programSortableActive ? 72 : 0,
        overflowY: 'auto',
        overflowX: 'visible',
        marginLeft: -16,
        marginRight: -16
      }}
    >
      {program.exercises.length > 0 ? (
        <ProgramForm
          ref={programFormRef}
          entityId={entityId}
          program={program}
          onComplete={() => setProgramSortableActive(false)}
        >
          <ProgramExercisesListSortable
            exercises={program.exercises}
            checkable={!programSortableActive && !isMzhUserAgent()}
            edit={!programSortableActive}
            editableNotes={editableNotes}
            onClick={onClickHandler}
            onCancel={onCancel}
            selectedExercises={selectedProgramExercises}
            onSelectedExercisesChange={selectProgramExercises}
          />
        </ProgramForm>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="browse">
              <MessageText>
                <FormattedMessage {...messages.programEmptyExercises} />
              </MessageText>
              {onAdd && (
                <AddButton
                  onClick={() => onAdd(program)}
                  label={
                    <FormattedMessage
                      {...messages.exercisesAddExercisesButton}
                    />
                  }
                />
              )}
            </Message>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default ProgramExercisesTab;
