import classNames from 'classnames';
import Icon from '../icon/Icon';
import { getIn, useFormikContext, useField } from 'formik';
import InputErrorMessage from './InputErrorMessage';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es, nl, fr, pt } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import './FieldDatePicker.scss';

import { isDate } from 'date-fns';
import { StoreContext } from 'index';
import { useContext } from 'react';

registerLocale('es', es);
registerLocale('nl', nl);
registerLocale('fr', fr);
registerLocale('pt', pt);

export const FieldDatePicker = ({ children, ...props }) => {
  const { setFieldValue, validateField, handleChange, touched } =
    useFormikContext();
  const [field] = useField(props);
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  return (
    <div
      className={classNames('c-input__group', {
        'c-input--read-only c-input--disabled': props.readOnly,
        'c-input--disabled': props.disabled,
        'c-input--icon-left': props.iconLeft,
        'c-input--inline': props.inline
      })}
    >
      {props.id && children && (
        <label htmlFor={props.id} className={classNames('c-input__label', {})}>
          {children}
        </label>
      )}
      <div className="c-input__wrapper">
        <DatePicker
          {...field}
          {...props}
          selected={
            (field.value && isDate(field.value) && new Date(field.value)) ||
            null
          }
          locale={props.locale ?? locale}
          dateFormat="dd/MM/yyyy"
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={
            props.yearDropdownItemNumber ? props.yearDropdownItemNumber : 2
          }
          scrollableYearDropdown={props.scrollableYearDropdown}
          dateFormatCalendar=" "
          wrapperClassName="p-react-datepicker"
          className={classNames('c-input', props.extraClassNames, {
            'c-input--large': props.large,
            'c-input--rounded': props.rounded,
            'c-input--url': props.url,
            'c-input--in-table': props.inTable,
            'has-error':
              props.errors &&
              props.errors[props.name] &&
              touched &&
              touched[props.name]
          })}
          minDate={props.min}
          maxDate={props.max}
          required={props.required}
          autoFocus={props.autoFocus}
          placeholderText={props.placeholder}
          readOnly={props.readOnly}
          disabled={props.disabled || props.readOnly}
          onChange={val => {
            setFieldValue(field.name, val);
          }}
          onBlur={() => {
            validateField(props.name);
          }}
          onCalendarClose={() => {
            validateField(props.name);
          }}
          onChangeRaw={e => {
            handleChange(e);
          }}
          data-qa={props.dataQa ? `${props.dataQa}-input` : `${props.id}-input`}
        />
        {props.icon && !props.readOnly && props.type !== 'range' && (
          // props.type !== 'date' &&
          <Icon
            id={props.icon}
            fillColor={props.iconColor}
            extraClassNames={classNames('c-icon--input', {
              'c-icon--input-left': props.iconLeft
            })}
          />
        )}
      </div>

      {props.errors &&
        props.errors[props.name] &&
        touched &&
        touched[props.name] &&
        typeof props.errors[props.name] === 'string' &&
        !props.readOnly && (
          <>
            {/* <ErrorMessage name={props.name} component={InputErrorMessage} /> */}
            <InputErrorMessage>{props.errors[props.name]}</InputErrorMessage>
          </>
        )}

      {/* TODO @fabian error message position in TestItemForm */}
      {props.errors &&
        typeof props.errors[props.name] !== 'string' &&
        getIn(props.errors, props.name) && (
          <InputErrorMessage>
            {getIn(props.errors, props.name)}
          </InputErrorMessage>
        )}
    </div>
  );
};
