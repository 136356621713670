import React from 'react';
import Modal from 'components/modal/Modal';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { CardBody } from 'components/card';
import { List, ListBody } from 'components/list';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { flexRender } from '@tanstack/react-table';

const ColumnVisibilityModal = ({ table, onClose }) => {
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.growthtrackerColumnVisibilityModalTitle}
          />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage
            {...messages.growthtrackerColumnVisibilityModalSubTitle}
          />
        </CardHeaderSubtitle>
      </CardHeader>
      <CardBody modal bodyWithHeader>
        <List onModal>
          <ListBody>
            {table.getAllLeafColumns().map(column => {
              if (!column.getCanHide()) return;
              return (
                <ListItem key={column.id} draggable>
                  <ListItemStatus
                    itemId={column.id}
                    visible={column.getCanHide()}
                    disabled={!column.getCanHide()}
                    checked={column.getIsVisible()}
                    onChange={() => column.toggleVisibility()}
                  />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>
                      {flexRender(column.columnDef.header())}
                    </ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
              );
            })}
          </ListBody>
        </List>
      </CardBody>
    </Modal>
  );
};

export default ColumnVisibilityModal;
