export const createTemplateSlice = set => ({
  template: null,
  setTemplate: value => set(() => ({ template: value })),

  templates: [],
  setTemplates: values => set(() => ({ templates: values })),

  templatesRef: [],
  setTemplatesRef: values => set(() => ({ templatesRef: values })),

  templatesFilter: { search: '', tags: [] },
  setTemplatesFilter: value =>
    set(state => ({ templatesFilter: { ...state.templatesFilter, ...value } })),
  resetTemplatesFilter: () =>
    set(() => ({ templatesFilter: { search: '', tags: [] } })),

  selectedTemplateExercises: [],
  selectTemplateExercises: value =>
    set(state => {
      const selectedTemplateExercises = [...state.selectedTemplateExercises];
      if (!selectedTemplateExercises.find(s => s.id === value.id)) {
        selectedTemplateExercises.push(value);
      } else {
        const index = selectedTemplateExercises.findIndex(
          e => e.id === value.id
        );
        if (index >= 0) {
          selectedTemplateExercises.splice(index, 1);
        }
      }
      return {
        selectedTemplateExercises
      };
    }),
  setSelectedTemplateExercises: values =>
    set(() => ({
      selectedTemplateExercises: values || []
    })),
  unsetSelectedTemplateExercises: exercises =>
    set(state => {
      const selectedTemplateExercises = [...state.selectedTemplateExercises];
      exercises.forEach(exercise => {
        const index = selectedTemplateExercises.findIndex(
          e => e.id === exercise.id
        );
        if (index >= 0) {
          selectedTemplateExercises.splice(index, 1);
        }
      });
      return {
        selectedTemplateExercises
      };
    }),
  resetSelectedTemplateExercises: () =>
    set(() => ({ selectedTemplateExercises: [] })),

  templateSortableActive: false,
  setTemplateSortableActive: value =>
    set(() => ({ templateSortableActive: value }))
});
