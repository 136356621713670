import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { QUERY_GET_EXERCISE_NOTES } from 'services/aws/exercises-query';
import { NoteTypes } from 'constants.js';
import {
  MUTATION_ADD_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import messages from 'messages';
import { useState } from 'react';
import Loader from 'components/loader/Loader';

function ExerciseNoteForm({
  templateId,
  exerciseId,
  note,
  children,
  extraClassNames,
  onComplete
}) {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    'meta.frequency': Yup.string(),
    note: Yup.string()
  });

  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);

  const onSubmitHandler = async (exerciseNote, { resetForm }) => {
    setLoading(true);
    const refetchQueries = [
      {
        query: QUERY_GET_EXERCISE_NOTES,
        variables: { templateId, exerciseId }
      }
    ];

    if (exerciseNote.id) {
      if (
        exerciseNote.note === note.note &&
        JSON.stringify(exerciseNote.meta) === JSON.stringify(note.meta)
      ) {
        return;
      }

      const { data } = await editNote({
        variables: {
          id: exerciseNote.id,
          linkId: templateId,
          linkId2: exerciseId,
          note: exerciseNote.note,
          noteType: NoteTypes.TEMPLATE,
          meta: JSON.stringify(exerciseNote.meta)
        },
        refetchQueries
      });

      if (data?.editNote) {
        setLoading(false);
        notification.add(data.editNote.id, {
          message: intl.formatMessage(messages.messageEditExerciseSuccess)
        });
        resetForm();
        onComplete && onComplete();
      }
    } else {
      const { data } = await addNote({
        variables: {
          linkId: templateId,
          linkId2: exerciseId,
          note: exerciseNote.note,
          noteType: NoteTypes.TEMPLATE,
          meta: JSON.stringify(exerciseNote.meta)
        },
        refetchQueries
      });

      if (data?.addNote) {
        setLoading(false);
        notification.add(data.addNote.id, {
          message: intl.formatMessage(messages.messageEditExerciseSuccess)
        });
        resetForm();
        onComplete && onComplete();
      }
    }
  };

  return (
    <Formik
      initialValues={note}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {loading && <Loader />}
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ExerciseNoteForm;
