import React from 'react';
import classNames from 'classnames';

const Radiobutton = props => {
  return (
    <div
      className={classNames(
        'c-input__group c-input__group--radiobutton',
        props.extraClassNames,
        { 'c-input__group-radiobutton-org': props.organisation }
      )}
    >
      <input
        id={props.id}
        type="radio"
        name={props.name}
        disabled={props.readOnly || props.disabled}
        defaultChecked={props.defaultChecked}
        checked={props.checked}
        onChange={props.onChange}
        className={classNames('c-input c-input--radiobutton')}
        data-qa={props.dataQa}
      />

      <label
        htmlFor={props.id}
        className={classNames('c-input__label c-input__label--radiobutton')}
      >
        {props.subLabel ? (
          <span className="c-input__label-wrapper">
            <span className="c-input__main-label">{props.children}</span>
            <span className="c-input__extra-label">{props.subLabel}</span>
          </span>
        ) : (
          props.children
        )}
      </label>
    </div>
  );
};

export default Radiobutton;
