import { useContext, useEffect, useRef, useState } from 'react';
import AnnotationPlayer from 'components/video/AnnotationPlayer';
import useAnnotations from 'hooks/queries/useAnnotations';
import AnnotationList from './AnnotationList';
import { MenuWrapper } from 'components/menu';
import { PanelTitleButton } from 'components/panel';
import LegendCard from './HelperLegend';
import Loader from 'components/loader/Loader';
import AnnotateVideoButtonBar from 'containers/pages/videos/AnnotateVideoButtonBar';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Icon from 'components/icon/Icon';
import AlertRemarks from './AlertRemarks';
import { StoreContext } from 'index';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { Button } from 'components/button';
import { useHistory } from 'react-router-dom';
import AnnotationVideoWrapper from 'components/video/AnnotationVideoWrapper';
import { TabPanelBody } from 'components/tabs';

const VideoViewCard = ({ entityId, video, videoId, parentPath }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { push } = useHistory();
  const { annotations, loading: loadingAnnotations } = useAnnotations(
    video,
    user
  );
  const [activeAnnotation, setActiveAnnotation] = useState(null);
  const videoWrapperRef = useRef();
  const overflowParentRef = useRef();

  useEffect(() => {
    return () => {
      setActiveAnnotation(null);
    };
  }, [annotations]);

  if (loadingAnnotations || !annotations) return <Loader />;

  if (video.media.length === 0) {
    return (
      <Card empty extraClassNames="u-margin-auto">
        <CardBody empty>
          <Message emptyState={true} icon="browse">
            <MessageText>
              <FormattedMessage {...messages.videoMissingVideo} />
            </MessageText>
            <Button primary rounded onClick={() => push(`${parentPath}/edit`)}>
              <Icon id="add-white" />
              <FormattedMessage {...messages.videosAddVideoButton} />
            </Button>
          </Message>
        </CardBody>
      </Card>
    );
  }

  return (
    <TabPanelBody
      extraClassNames="u-padding-bottom-small"
      ref={overflowParentRef}
    >
      {video.templateCount === 0 && (
        <AlertRemarks
          message={intl.formatMessage(messages.videosMessageNotInLesson)}
        />
      )}

      <AnnotateVideoButtonBar
        extraClassNames={'u-margin-top-medium u-margin-bottom-tiny'}
        entityId={entityId}
        video={video}
        annotations={annotations}
        annotationBtnLabel={intl.formatMessage(
          messages.videosViewAnnotationBtn
        )}
        annotatePath={`${parentPath}/annotate`}
      >
        <MenuWrapper
          extraClassNames={'c-menu__wrapper-helper u-margin-left-small'}
          asModal
          trigger={<PanelTitleButton small icon="question" />}
        >
          <LegendCard helper />
        </MenuWrapper>
      </AnnotateVideoButtonBar>
      <AnnotationVideoWrapper>
        {/* http://localhost:3001/videos/f2860a98-3ea0-4651-b14b-9b15bac238b3/28fd7238-2f7f-463c-80de-eee0fde3e252 */}
        {video.media && video.media.length > 0 && (
          <div className="c-media-wrapper " ref={videoWrapperRef}>
            <AnnotationPlayer
              id={video.id}
              ownershipVideo={video.person.id}
              source={video.media[0].signedDownloadUrl}
              annotations={annotations}
              activeAnnotation={activeAnnotation}
              onClickMarker={annotation => {
                setActiveAnnotation(annotation);
              }}
              resizedOnScroll
            />
          </div>
        )}
      </AnnotationVideoWrapper>

      <AnnotationList
        ownershipVideo={video.person.id}
        videoId={videoId}
        video={video}
        annotations={annotations}
        parentPath={parentPath}
        activeAnnotation={activeAnnotation}
        onClickItem={annotation => setActiveAnnotation(annotation)}
        videoSticky={videoWrapperRef}
        overflowParent={overflowParentRef}
        editable={false}
      />
    </TabPanelBody>
  );
};

export default VideoViewCard;
