import { useEffect, useState } from 'react';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import FieldInput from 'components/input/FieldInput';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { useParams, useRouteMatch } from 'react-router-dom';
import ExerciseNoteForm from 'containers/pages/exercises/exercises/ExerciseNoteForm';
import useExerciseNotes from 'hooks/queries/useExerciseNotes';
import Note from 'models/Note';
import useEdit from 'hooks/utils/useEdit';
import { ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS_EDIT } from 'routes/RouteList';

const TrainingExerciseDetailsPanel = ({ entityId, exercise }) => {
  const { templateId } = useParams();
  const [edit, setEdit] = useEdit(
    !!useRouteMatch([
      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS_EDIT
    ]),
    true
  );
  const [note, setNote] = useState(
    new Note({
      note: '',
      meta: {
        frequency: ''
      }
    })
  );
  const { getNotes, notes } = useExerciseNotes();

  useEffect(() => {
    if (exercise?.id && templateId) {
      getNotes({
        exerciseId: exercise.id,
        templateId
      });
    }
  }, [exercise, templateId]);

  useEffect(() => {
    if (notes.length > 0) {
      setNote(notes[0]);
    }
  }, [notes]);

  const handleSubmit = async () => {
    setEdit(false);
  };

  const onFormReset = () => {
    setEdit(false);
    // push(generatePath(ROUTE_SESSIONS_ENTITY_SESSION, { entityId, sessionId }));
  };

  return (
    <ExerciseNoteForm
      entityId={entityId}
      templateId={templateId}
      exerciseId={exercise.id}
      note={note}
      onComplete={handleSubmit}
      onReset={onFormReset}
    >
      {({
        errors,
        touched,
        isSubmitting,
        isValid,
        handleSubmit,
        handleReset
      }) => (
        <Card secondary>
          <CardHeader extraClassNames="u-margin-bottom-none" secondary>
            <CardHeaderText>
              <CardHeaderTitle>
                <FormattedMessage {...messages.exerciseDetails} />
              </CardHeaderTitle>
            </CardHeaderText>
            <FormEditHeaderButtons
              edit={edit}
              setEdit={edit => setEdit(edit)}
              onSubmit={handleSubmit}
              onCancel={handleReset}
            />
          </CardHeader>
          <CardBody secondary>
            <FieldInput
              id="meta.frequency"
              name="meta.frequency"
              errors={errors}
              touched={touched}
              readOnly={!edit}
            >
              <FormattedMessage {...messages.exerciseDetailsFrequencyLabel} />
            </FieldInput>
            <FieldTextarea
              id="note"
              name="note"
              readOnly={!edit}
              errors={errors}
              touched={touched}
              resizeVertical={edit}
            >
              <FormattedMessage {...messages.trainingsDetailsNoteLabel} />
            </FieldTextarea>
          </CardBody>
          <FormEditFooterButtons
            edit={edit}
            setEdit={setEdit}
            disabledSubmit={!isValid || isSubmitting}
            onCancel={handleReset}
            onSubmit={handleSubmit}
          />
        </Card>
      )}
    </ExerciseNoteForm>
  );
};

TrainingExerciseDetailsPanel.propTypes = {};

export default TrainingExerciseDetailsPanel;
