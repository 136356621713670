import classNames from 'classnames';
import { forwardRef } from 'react';

const PanelHeader = forwardRef(({ ...props }, ref) => {
  const { extraClassNames, home, borderBottom, children, ...rest } = props;
  return (
    <div
      className={classNames('c-panel__header', extraClassNames, {
        'c-panel__header--home': home,
        'c-panel__header--border-bottom': borderBottom
      })}
      ref={ref}
    >
      {children}
    </div>
  );
});

export default PanelHeader;
