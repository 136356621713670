import { forwardRef } from 'react';
import './TimeNavigator.scss';
import Icon from 'components/icon/Icon';
import { Link } from 'react-router-dom';
import { HyDatePicker } from 'components/input/HyDatePicker';
import { Button } from 'components/button';
import { getLocalDateFormat } from 'utils/date';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages.js';
import { format } from 'date-fns';

const TimeNavigator = ({
  selectedDate,
  highlightDates = [],
  previousDate,
  nextDate,
  onChange,
  path,
  minDate,
  maxDate
}) => {
  const intl = useIntl();
  const CustomInput = forwardRef((props, ref) => {
    const { children, onClick, ...rest } = props;
    return (
      <Button
        extraClassNames={'u-1-of-1 o-flex--justify-center'}
        small
        ghost
        iconRight
        onClick={onClick}
        ref={ref}
      >
        {children}
        <Icon id="dropdown-open" fillColor="color-neutral" />
      </Button>
    );
  });

  /*
  # De regels van een kalender:
  ## Daily:
  De selected date (dus de informatie dat je voor de eerste keer
  ziet wanneer je op "Data" terechtkomt) is:
    1. Als TODAY binnen de daterange valt (> minDate && < maxDate)
        selected: TODAY
    2. Als TODAY > maxDate is, wilt zeggen dat de periode van afname voorbij is.
        selected: maxDate
    3. Als TODAY < minDate is, wilt zeggen dat de periode van afname nog niet begonnen is.
        selected: minDate
  */
  /*const getSelected = () => {
    const today = new Date();
    if (today < minDate) {
      return minDate;
    } else if (today > maxDate) {
      return maxDate;
    } else {
      return today;
    }
  };*/

  const onChangeDate = date => {
    let testDataId = Object.keys(highlightDates.finished).find(
      id => highlightDates.finished[id].getTime() === date.getTime()
    );
    if (!testDataId) {
      testDataId = Object.keys(highlightDates.unfinished).find(
        id => highlightDates.unfinished[id].getTime() === date.getTime()
      );
    }

    onChange(date, testDataId);
  };

  return (
    <div className="c-time-navigator">
      <div className="c-time-navigator__prev">
        {previousDate && (
          <Link
            className="c-button c-button--rounded c-button--grey c-button--icon-expandable"
            to={`${path}/${previousDate.id}`}
          >
            <Icon id="arrow-right" fillColor="color-neutral-dark" />
            <span className="u-hidden-visually">
              <FormattedMessage {...messages.timeNavigatorPreviousDate} />
            </span>
            <span className="c-button__label">
              {format(new Date(previousDate.testDataDate), 'dd/LL/yyyy')}
            </span>
          </Link>
        )}
      </div>

      <div className="c-time-navigator__actual o-flex o-flex--column o-flex--align-center">
        <HyDatePicker
          label={intl.formatMessage(messages.timeNavigatorCurrentDate)}
          selectedDate={selectedDate}
          onChange={onChangeDate}
          highlightDates={[
            {
              'react-datepicker__day--highlighted-finished':
                highlightDates?.finished
                  ? Object.values(highlightDates.finished)
                  : []
            },
            {
              'react-datepicker__day--highlighted-unfinished':
                highlightDates?.unfinished
                  ? Object.values(highlightDates.unfinished)
                  : []
            }
          ]}
          popperPlacement="bottom"
          customInput={(value, onClick) => (
            <CustomInput onClick={onClick}>
              {getLocalDateFormat(value)}
            </CustomInput>
          )}
          min={minDate}
          max={maxDate}
        />
      </div>

      <div className="c-time-navigator__next">
        {nextDate && (
          <Link
            className="c-button c-button--rounded c-button--grey c-button--icon-expandable c-button--icon-right"
            to={`${path}/${nextDate.id}`}
          >
            <span className="u-hidden-visually">
              <FormattedMessage {...messages.timeNavigatorNextDate} />
            </span>
            <span className="c-button__label">
              {format(new Date(nextDate.testDataDate), 'dd/LL/yyyy')}
            </span>
            <Icon id="arrow-right" fillColor="color-neutral-dark" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default TimeNavigator;
