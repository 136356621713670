import { generatePath, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_LESSONS_ENTITY_LESSON_VIDEOS } from 'routes/RouteList';
import { useNotificationQueue } from 'components/notification';
import Lesson from 'models/Lesson';
import LessonFormInput from 'containers/pages/lessons/LessonFormInput';
import LessonForm from 'containers/pages/lessons/LessonForm';

function AddLessonModal({ entityId, onClose }) {
  const { push } = useHistory();
  const intl = useIntl();
  const notification = useNotificationQueue();

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.lessonsAddTitle} />
        </CardHeaderTitle>
      </CardHeader>
      <LessonForm
        entityId={entityId}
        lesson={new Lesson({})}
        onComplete={async templateId => {
          notification.add(templateId, {
            message: intl.formatMessage(messages.templateSaved)
          });
          onClose && onClose();
          push(
            generatePath(ROUTE_LESSONS_ENTITY_LESSON_VIDEOS, {
              entityId,
              lessonId: templateId
            })
          );
        }}
      >
        {({ isSubmitting }) => (
          <>
            <CardBody modalWithOverflow>
              <LessonFormInput />
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </LessonForm>
    </Modal>
  );
}

export default AddLessonModal;
