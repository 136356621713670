import FieldSelect from 'components/input/FieldSelect';

function LanguageSelect({
  id,
  name,
  value,
  label,
  readOnly,
  possibleLanguages,
  onSelect
}) {
  const languages = possibleLanguages.map(l => ({
    value: l.key,
    label: l.language
  }));
  const selectedValue = languages.find(l => l.value === value);

  return (
    <FieldSelect
      extraClassNames={'u-margin-bottom-medium'}
      id={id}
      name={name}
      onChange={select => onSelect(select)}
      value={selectedValue ?? ''}
      readOnly={readOnly}
      options={languages ?? []}
      label={label}
    />
  );
}

export default LanguageSelect;
