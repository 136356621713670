import { useLayoutEffect, useRef, useState } from 'react';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { Button, ButtonsGroup } from 'components/button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import Template from 'models/Template';
import FieldInput from 'components/input/FieldInput';
import { useNotificationQueue } from 'components/notification';
import { TemplateType } from 'constants';

const TemplatesListItemForm = ({
  entityId,
  collectionType,
  onComplete,
  extraClassNames
}) => {
  const intl = useIntl();
  const notification = useNotificationQueue();
  const [editState, setEditState] = useState(false);

  const onCompleteHandler = templateId => {
    setEditState(false);
    notification.add(templateId, {
      message: intl.formatMessage(
        messages[`messageAdd${collectionType}Success`]
      )
    });
    onComplete && onComplete(templateId);
  };

  const inputRef = useRef();
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.paddingRight = `${
        inputRef.current.closest('.c-input__group').nextElementSibling
          .offsetWidth + 24
      }px`;
    }
  }, []);
  return (
    <ListItem
      clickable
      active={editState}
      extraClassNames={extraClassNames}
      onClick={() => !editState && setEditState(true)}
    >
      <ListItemStatus statusIcon="add-white" mediumIcon iconPropagateOnClick />
      <ListItemLabelWrapper>
        <ListItemLabel label="true">
          {editState ? (
            <TemplateForm
              entityId={entityId}
              template={new Template({ type: collectionType.toLowerCase() })}
              type={TemplateType.TEMPLATE}
              onComplete={onCompleteHandler}
            >
              {({ errors, touched, handleSubmit }) => (
                <>
                  <FieldInput
                    id="title"
                    name="title"
                    type="text"
                    errors={errors}
                    touched={touched}
                    required
                    inline
                    innerRef={inputRef}
                  />
                  <ButtonsGroup>
                    <Button
                      tiny
                      extraClassNames="u-margin-right-small"
                      onClick={handleSubmit}
                    >
                      <FormattedMessage {...messages.buttonSave} />
                    </Button>

                    <Button tiny secondary onClick={() => setEditState(false)}>
                      <FormattedMessage {...messages.buttonCancel} />
                    </Button>
                  </ButtonsGroup>
                </>
              )}
            </TemplateForm>
          ) : (
            <div className="c-list__item-text-switchable">
              <FormattedMessage {...messages[`add${collectionType}Input`]} />
            </div>
          )}
        </ListItemLabel>
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default TemplatesListItemForm;
