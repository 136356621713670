import { useEffect, useState } from 'react';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import Loader from 'components/loader/Loader';
import { useStore } from 'stores/Store';
import useExercises from 'hooks/queries/useExercises';

const ExercisesListFiltered = ({ entityId, edit, filter, onPreview }) => {
  const { fetchExercises } = useExercises(entityId);
  const selectProgramExercises = useStore(
    state => state.selectProgramExercises
  );
  const selectedProgramExercises = useStore(
    state => state.selectedProgramExercises
  );
  const [loading, setLoading] = useState(false);

  const [exercises, setExercises] = useState([]);

  useEffect(() => {
    const getExercises = async () => {
      setLoading(true);
      const exercises = await fetchExercises({ ...filter });
      setExercises(exercises);
      setLoading(false);
    };

    getExercises();
  }, []);

  return (
    <div className="c-accordion__virtualized-list-wrapper">
      {loading ? (
        <Loader />
      ) : (
        <ExercisesListVirtualized
          entityId={entityId}
          wrappedList
          onPreview={onPreview}
          visible={edit}
          checkable={edit}
          showLevel
          enablePreviewModal
          exercises={exercises}
          onSelect={exercise => {
            if (!edit) return;
            selectProgramExercises(exercise);
          }}
          selectedExercises={selectedProgramExercises}
        />
      )}
    </div>
  );
};

ExercisesListFiltered.propTypes = {};

export default ExercisesListFiltered;
