import React, { cloneElement, Fragment } from 'react';
import classNames from 'classnames';
import MenuBody from './MenuBody';
import Menu from './Menu';

import Downshift from 'downshift';
import { cleanFalsy } from 'utils/object';

const MenuWrapper = props => {
  const {
    trigger,
    header,
    isSelect,
    asModal,
    organisation,
    extraClassNames,
    onStateChange,
    children
  } = props;

  if (!children) return null;

  const falsyChildren = cleanFalsy(children);

  if (Object.keys(falsyChildren).length === 0) {
    return null;
  }

  return (
    <Dropdown
      organisation={organisation}
      asModal={asModal}
      isSelect={isSelect}
      items={children}
      trigger={trigger}
      extraClassNames={extraClassNames}
      header={header}
      onStateChange={onStateChange}
    />
  );
};

const renderChildren = (children, closeMenu) => {
  return React.Children.map(children, child => {
    if (child) {
      return cloneElement(child, {
        onClick: () => {
          if (child.props.onClick) {
            child.props.onClick();
          }
          closeMenu();
        }
      });
    }
  });
};

const Dropdown = ({
  items,
  trigger,
  header,
  isSelect,
  asModal,
  extraClassNames,
  organisation,
  onStateChange
}) => (
  <Fragment>
    <Downshift
      onStateChange={onStateChange}
      itemToString={item => (item ? item.key : '')}
    >
      {({
        isOpen,
        closeMenu,
        getToggleButtonProps,
        getMenuProps,
        selectedItem,
        ...rest
      }) => (
        <div
          className={classNames(
            'c-menu__wrapper c-panel__title-button',
            extraClassNames,
            {
              'c-menu__wrapper--select': isSelect,
              'c-menu__wrapper--as-modal': asModal,
              'c-menu__wrapper--organisation': organisation
            }
          )}
        >
          {trigger && cloneElement(trigger, { ...getToggleButtonProps() })}

          {isOpen && items && (
            <Menu organisation={organisation} {...getMenuProps({ isOpen })}>
              {header && cloneElement(header)}
              <MenuBody>{renderChildren(items, closeMenu)}</MenuBody>
            </Menu>
          )}
        </div>
      )}
    </Downshift>
    {asModal && <div className="c-menu__wrapper-overlay" />}
  </Fragment>
);

export default MenuWrapper;
