import { useContext, useEffect, useState } from 'react';
import FieldInput from 'components/input/FieldInput';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Modal from 'components/modal/Modal';
import FilterPanel from 'containers/pages/exercises/exercises/FilterPanel';
import { StoreContext } from 'index';
import { useDebounce } from '@uidotdev/usehooks';
import { useStore } from 'stores/Store';
import classNames from 'classnames';
import TagsWrapperReadOnly from 'containers/partials/inputs/TagsWrapperRemoveOnly';

function ExerciseFilterHeader({ filterByOrg = true }) {
  const {
    authStore: { entityId }
  } = useContext(StoreContext);
  const intl = useIntl();

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const setExercisesFilter = useStore(state => state.setExercisesFilter);

  const [filter, setFilter] = useState({ tags: [], myOrg: false });
  const [filterPanel, setFilterPanel] = useState(false);

  useEffect(() => {
    setExercisesFilter({
      search: debouncedSearch,
      tags: filter?.tags || [],
      myOrg: filter?.myOrg || false
    });
  }, [debouncedSearch, filter?.tags, filter?.myOrg]);

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const handleTagChange = tags => {
    setFilter({ ...filter, tags });
  };

  const onSubmitFilter = filter => {
    setFilter(filter);
    setFilterPanel(false);
  };

  const handleRemoveMyOrg = () => {
    setExercisesFilter({
      ...filter,
      myOrg: false
    });
    setFilter({ ...filter, myOrg: false });
  };
  const onClosePanel = () => setFilterPanel(false);

  return (
    <>
      <div className="p-tabs__tab-header u-padding-bottom-none">
        <FieldInput
          rounded
          extraClassNames={classNames('', {
            'u-margin-bottom-none': filter?.tags?.length > 0 || filter?.myOrg,
            'u-margin-bottom-small':
              filter?.tags?.length === 0 || !filter?.myOrg
          })}
          icon="search-grey"
          iconLeft
          onChange={onSearch}
        />
        <Button rounded light onClick={() => setFilterPanel(true)}>
          <Icon id="filter" strokeColor="color-neutral-dark" />
          <FormattedMessage {...messages.filterButton} />
        </Button>

        {(filter?.tags?.length > 0 || filter?.myOrg) && (
          <TagsWrapperReadOnly
            selectClearable
            filter={filter}
            handleOnClick={handleRemoveMyOrg}
            handleOnChange={handleTagChange}
            entityId={entityId}
          />
        )}
      </div>
      {filterPanel && (
        <Modal filter isOpen={true} onClose={onClosePanel}>
          <FilterPanel
            entityId={entityId}
            onCancel={onClosePanel}
            filter={filter}
            onSubmit={onSubmitFilter}
            filterByOrg={filterByOrg}
            {...filterPanel}
          />
        </Modal>
      )}
    </>
  );
}

export default ExerciseFilterHeader;
