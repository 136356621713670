import { useEffect, useRef, useState } from 'react';
import { QUERY_GET_PERSONS_OF_ENTITY } from 'services/aws/user-query';
import { useLazyQuery } from '@apollo/client';
import { sort, sortUsers } from 'utils/sort';
import Person from 'models/Person';
import Group from 'models/Group';
import { EntityTypes } from 'constants';
import { getFilteredUsers } from 'utils/entities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import { S_QUERY_GET_ENTITY_BY_ID } from 'services/aws/entity-query';

function useFetchAndFilterPersonsPool({ entityId }) {
  const triggerFilter = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const setGroup = useEntityContext(s => s.setGroup);
  const persons = useEntityContext(s => s.persons);
  const setPersons = useEntityContext(s => s.setPersons);
  const filter = useEntityContext(s => s.filter);

  const [fetchEntity] = useLazyQuery(S_QUERY_GET_ENTITY_BY_ID, {
    fetchPolicy: 'network-only',
    skip: !entityId
  });
  const [fetchPersonsPool, { loading, error }] = useLazyQuery(
    QUERY_GET_PERSONS_OF_ENTITY,
    {
      skip: !entityId
    }
  );

  useEffect(() => {
    if (persons.length && triggerFilter.current) {
      filterPool(filter);
    }
  }, [filter, triggerFilter.current]);

  const fetchPool = async ({ withEntity = true } = {}) => {
    setIsLoading(true);
    if (withEntity) {
      const { data: entityData } = await fetchEntity({
        variables: { id: entityId }
      });
      if (entityData?.getEntity) {
        const group = new Group({ ...entityData.getEntity });
        setGroup(group);
      }
    }

    const { data: poolData } = await fetchPersonsPool({
      variables: { entityId },
      fetchPolicy: 'network-only'
    });
    if (poolData?.getPersonsOfEntity) {
      const persons = sort(
        poolData.getPersonsOfEntity.map(
          person => new Person({ ...person, filtered: true })
        ),
        {
          keys: [{ key: 'firstname' }, { key: 'lastName' }]
        }
      );
      triggerFilter.current = Date.now();

      setPersons(persons);
    }
    setIsLoading(false);
  };

  const filterPool = filter => {
    const filteredUsers =
      filter.type !== EntityTypes.GROUP
        ? getFilteredUsers(persons, filter.search ?? '').map(
            user => new Person({ ...user, filtered: true })
          )
        : [];

    // get a list of all users but not the ones that are in filteredUsers
    const usersNotInFilteredUsers = persons
      .filter(
        client =>
          !filteredUsers.some(filteredUser => filteredUser.id === client.id)
      )
      .map(user => new Person({ ...user, filtered: false }));

    setPersons([
      ...sortUsers(filteredUsers),
      ...sortUsers(usersNotInFilteredUsers)
    ]);
  };

  return {
    loading: loading || isLoading,
    error,
    fetchPool,
    fetchPersonsPool,
    filterPool
  };
}

export default useFetchAndFilterPersonsPool;
