import React from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardFooter, CardBody } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Mutation } from '@apollo/client/react/components';
import { MODAL_TYPES } from 'models/ModalData';

const BasicModal = props => {
  const {
    modalData: {
      title,
      message,
      values,
      okLabel,
      okHandler,
      dismissButton,
      dismissHandler,
      dismissLabel,
      mutationData,
      type
    }
  } = props;
  return (
    <Modal card alert autoHeight isOpen={true} onClose={dismissHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {title && typeof title === 'object' ? (
            <FormattedMessage {...title} />
          ) : title && typeof title === 'string' ? (
            <>{title}</>
          ) : (
            ''
          )}
        </CardHeaderTitle>
      </CardHeader>
      <CardBody modal>
        {message && typeof message === 'object' ? (
          <p>
            <FormattedMessage {...message} values={values} />
          </p>
        ) : message && typeof message === 'string' ? (
          <p>{message}</p>
        ) : (
          ''
        )}
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          {mutationData ? (
            <Mutation {...mutationData}>
              {mutation => (
                <Button
                  alert={type === MODAL_TYPES.ALERT}
                  primary={type === MODAL_TYPES.WARNING}
                  onClick={() => {
                    okHandler();
                    mutation(mutationData.variables);
                  }}
                >
                  {okLabel}
                </Button>
              )}
            </Mutation>
          ) : (
            <Button
              alert={type === MODAL_TYPES.ALERT}
              primary={type === MODAL_TYPES.WARNING}
              onClick={() => {
                okHandler();
              }}
            >
              {okLabel}
            </Button>
          )}
          {dismissButton && (
            <Button secondary onClick={dismissHandler}>
              {dismissLabel ? (
                dismissLabel
              ) : (
                <FormattedMessage {...messages.buttonCancel} />
              )}
            </Button>
          )}
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default BasicModal;
