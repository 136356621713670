import React from 'react';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNotificationQueue } from 'components/notification';
import { MUTATION_EDIT_PROGRAM } from 'services/aws/programs-query';
import useProgram from 'hooks/queries/useProgram';
import usePrograms from 'hooks/queries/usePrograms';
import { format } from 'date-fns';
import { useStore } from 'stores/Store';

const RemoveExercisesFromProgramButton = ({ entityId, program, ...props }) => {
  const intl = useIntl();
  const programSortableActive = useStore(state => state.programSortableActive);
  const selectedProgramExercises = useStore(
    state => state.selectedProgramExercises
  );
  const setSelectedProgramExercises = useStore(
    state => state.setSelectedProgramExercises
  );
  const notification = useNotificationQueue();
  const { fetchProgram } = useProgram();
  const { fetchPrograms } = usePrograms(entityId);

  return (
    <ConfirmMutationButton
      menu
      disabled={selectedProgramExercises.length === 0}
      mutation={MUTATION_EDIT_PROGRAM}
      confirmTitle={messages.modalConfirmRemoveExerciseFromProgramTitle}
      confirmMessage={messages.modalConfirmRemoveExerciseFromProgramMessage}
      messageValues={{ count: selectedProgramExercises.length }}
      variables={{
        ...program,
        startdate: format(program.startdate, 'yyyy-LL-dd'),
        exerciseIds: program.exercises
          .filter(e => !selectedProgramExercises.find(se => se.id === e.id))
          .map(e => e.id)
      }}
      update={(cache, { data: { editExerciseProgram } }) => {
        if (editExerciseProgram) {
          notification.add(program.id, {
            message: intl.formatMessage(messages.messageEditProgramSuccess)
          });
          fetchProgram({
            variables: { id: program.id },
            fetchPolicy: 'network-only'
          });
          fetchPrograms({
            variables: { entityId },
            fetchPolicy: 'network-only'
          });

          setSelectedProgramExercises([]);
        }
      }}
      {...props}
    >
      {selectedProgramExercises.length === 0 ? (
        <>
          <Icon id="delete" style={{ top: 'auto' }} />
          {programSortableActive
            ? intl.formatMessage(
                messages.collectionRemoveExerciseDisabledDragging
              )
            : intl.formatMessage(
                messages.collectionRemoveExerciseDisabledNotSelected
              )}
        </>
      ) : (
        <>
          <Icon
            id="delete"
            style={{ top: 'auto' }}
            strokeColor={'color-neutral-dark'}
          />

          <FormattedMessage
            {...messages.exercisesRemoveExerciseFromProgramButton}
            values={{ count: selectedProgramExercises.length }}
          />
        </>
      )}
    </ConfirmMutationButton>
  );
};

export default RemoveExercisesFromProgramButton;
