import { useContext } from 'react';
import { StoreContext } from 'index';
import { ROUTE_USER_ENTITY_DOCUMENTS } from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import { generatePath, Redirect, Route } from 'react-router-dom';
import Documents from 'containers/pages/person/documents/Documents';
import { Switch } from 'react-router-dom';
import messages from 'messages';
import { useIntl } from 'react-intl';

const UserDocuments = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();

  return (
    <>
      <Switch>
        <Route exact path={[ROUTE_USER_ENTITY_DOCUMENTS]}>
          <Documents />
        </Route>
        <Redirect
          to={generatePath(ROUTE_USER_ENTITY_DOCUMENTS, {
            entityId: user.rootEntityId
          })}
        />
      </Switch>

      <Route exact path={[ROUTE_USER_ENTITY_DOCUMENTS]}>
        <Panel
          emptyState
          emptyLabel={intl.formatMessage(messages.documentsEmptyPanel)}
        />
      </Route>
    </>
  );
};

export default UserDocuments;
