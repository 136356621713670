import React, { useContext, useEffect, useState } from 'react';
import FileThumb from 'containers/partials/media/FileThumb';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import messages from 'messages';
import { deleteFileMutation } from 'services/aws/file-upload';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove
} from '@dnd-kit/sortable';
import { StoreContext } from 'index';
import { useIntl } from 'react-intl';
import Loader from 'components/loader/Loader';
import classNames from 'classnames';

const ThumbList = ({
  entityId,
  media,
  selectedMedia,
  editable = false,
  onFileDeleted,
  onSort,
  onClick
}) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();

  const [sortedMedia, setSortedMedia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [drag, setDrag] = useState(false);

  useEffect(() => {
    if (media && media.length > 0) {
      setSortedMedia(media.filter(m => !!m));
    } else {
      setSortedMedia([]);
    }
  }, [media]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const onDeleteHandler = async file => {
    // @ts-ignore
    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmRemoveFileTitle),
      message: messages.modalConfirmRemoveFileMessage,
      values: { file: file.filename },
      okLabel: intl.formatMessage(messages.modalConfirmRemoveFileAcceptButton),
      dismissLabel: intl.formatMessage(
        messages.modalConfirmRemoveFileDismissButton
      ),
      okHandler: async () => {
        setLoading(true);
        await deleteFileMutation({ fileId: file.id, entityId });
        setLoading(false);
        setSortedMedia(media.filter(m => m.id !== file.id));
        if (onFileDeleted) onFileDeleted(file.id);
      }
    });
  };

  const handleDragEnd = e => {
    drag ? setDrag(false) : setDrag(true);

    const { active, over } = e;
    if (active.id !== over.id) {
      const oldIndex = sortedMedia.findIndex(f => f.id === active.id);
      const newIndex = sortedMedia.findIndex(f => f.id === over.id);
      const sorted = arrayMove(sortedMedia, oldIndex, newIndex);
      setSortedMedia(sorted);
      onSort && onSort(sorted.map(f => f.id));
    }
  };

  const handleDragStart = () => {
    !drag ? setDrag(true) : setDrag(false);
  };

  return (
    <div
      className={classNames('c-media__gallery', {
        'c-media__gallery--dragging': drag,
        'c-media__gallery--editable': editable
      })}
    >
      {loading && <Loader />}
      {media && (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext items={sortedMedia.map(i => i.id)}>
            {sortedMedia.map(file => (
              <FileThumb
                selectedMedia={selectedMedia}
                key={file.id}
                file={file}
                onClick={onClick}
                onDelete={onDeleteHandler}
                editable={editable}
              />
            ))}
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
};

export default ThumbList;
