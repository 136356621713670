import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import ExerciseListItem from 'containers/partials/list-items/ExerciseListItem';
import { useUIDSeed } from 'react-uid';
import { useVirtualizer } from '@tanstack/react-virtual';
import { LoaderListItem } from 'containers/partials/list-items/LoaderListItem';

const ExercisesListVirtualized = ({
  entityId,
  activeId,
  checkable,
  visible,
  exercises = [],
  exerciseGroup,
  onClick,
  onHover,
  enablePreviewModal,
  onPreview,
  onSelect,
  selectedExercises,
  extraClassNames = {},
  showTemplateCount = false,
  showLevel = false,
  showNote = false,
  showSharesAndAnnotations = false,
  showOwner = false,
  statusIcon,
  onModal,
  wrappedList,
  withFixedFooter,
  loading,
  hasNextPage,
  isFetchingNextPage,
  fetch,
  fetchMore
}) => {
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const [minHeight, setMinHeight] = useState(null);

  useEffect(() => {
    if (fetch && exercises.length === 0) {
      fetch();
    }
  }, []);

  const rowVirtualizer = useVirtualizer({
    count: exercises.length,
    getScrollElement: () => parentRef.current,
    estimateSize: React.useCallback(() => 56, []),
    overscan: 5
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= exercises.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchMore && fetchMore();
    }
  }, [
    hasNextPage,
    fetchMore,
    exercises.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems()
  ]);

  const onSelectHandler = (exercise, checked) => {
    onSelect && onSelect(exercise, checked);
  };

  useLayoutEffect(() => {
    if (parentRef.current && !wrappedList) {
      const roundedTop = parentRef.current
        .getBoundingClientRect()
        .top.toFixed(0);
      const paddingSpace = withFixedFooter ? 64 + 18 : 18;

      setMinHeight(`calc(100dvh - ${paddingSpace}px - ${roundedTop}px)`);
    }
  }, [wrappedList]);

  return (
    <>
      {exercises.length > 0 ? (
        <List
          ref={parentRef}
          virtualizedList
          style={{
            height: withFixedFooter ? 'calc(100% - 64px)' : '100%'
          }}
          extraClassNames={extraClassNames}
        >
          <ListBody
            style={{
              minHeight: !onModal && minHeight,
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative'
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const isLoaderRow =
                isFetchingNextPage && virtualRow.index >= exercises.length - 1;
              const exercise = exercises[virtualRow.index];
              // TODO not the best fix, but it works for now
              // When adding a new exercise, the exercise.id is null
              if (!exercise?.id) return null;

              return isLoaderRow ? (
                <LoaderListItem
                  key={`loader_${virtualRow.key}`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '48px',
                    transform: `translateY(${virtualRow.start}px)`
                  }}
                />
              ) : (
                <ExerciseListItem
                  key={virtualRow.key}
                  uid={uidSeed(exercise?.id)}
                  entityId={entityId}
                  done={exercise.finished}
                  inProgress={
                    exercise?.annotations?.length > 0 && !exercise.finished
                  }
                  statusIcon={statusIcon}
                  virtualRow={virtualRow}
                  exercise={exercise}
                  exerciseGroup={exerciseGroup}
                  notes={exerciseGroup && exerciseGroup.notes?.[exercise.id]}
                  active={exercise.id === activeId}
                  showTemplateCount={showTemplateCount}
                  showWarning={
                    false
                  } /*{exercise.templateCount === 0 && user.isVitaleOrg}*/
                  showMessage={
                    false
                  } /*{exercise.media.length === 0 && user.isVitaleOrg}*/
                  showNote={showNote}
                  showLevel={showLevel}
                  showSharesAndAnnotations={showSharesAndAnnotations}
                  showOwner={showOwner}
                  visible={
                    visible || (checkable && selectedExercises.length > 0)
                  }
                  checkbox={checkable}
                  clickable
                  checked={
                    checkable &&
                    selectedExercises.find(e => e.id === exercise.id)
                  }
                  onShowPreview={() =>
                    enablePreviewModal && onPreview(exercise)
                  }
                  onChange={e => onSelectHandler(exercise, e)}
                  onClick={() =>
                    enablePreviewModal
                      ? onPreview(exercise)
                      : onClick && onClick(exercise.id)
                  }
                  onHover={() => {
                    onHover && onHover(exercise.id);
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '48px',
                    transform: `translateY(${virtualRow.start}px)`
                  }}
                />
              );
            })}
          </ListBody>
        </List>
      ) : (
        <Card centered>
          <CardBody empty>
            {!loading && (
              <Message iconFillColor="color-neutral-dark" empty icon="cursor">
                <MessageText>
                  <FormattedMessage {...messages.exercisesListEmpty} />
                </MessageText>
              </Message>
            )}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ExercisesListVirtualized;
