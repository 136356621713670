import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useEdit from 'hooks/utils/useEdit';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS_EDIT } from 'routes/RouteList';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { CardBody } from 'components/card';
import CardFooter from 'components/card/CardFooter';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import FieldInput from 'components/input/FieldInput';
import Tags from 'containers/partials/inputs/Tags';
import TagMultiSelect from 'containers/partials/inputs/TagMultiSelect';
import classNames from 'classnames';
import { TemplateType } from 'constants';
import { useStore } from 'stores/Store';

const TemplateDetailsTab = ({ onCancel }) => {
  const intl = useIntl();
  const { entityId } = useParams();
  const template = useStore(state => state.template);

  const [edit, setEdit] = useEdit(
    !!useRouteMatch([ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS_EDIT])
  );

  return (
    <TemplateForm
      entityId={entityId}
      template={template}
      type={TemplateType.TEMPLATE}
      extraClassNames={
        'c-card c-card--secondary c-card--multiple-body c-card--multiple-body-with-tags'
      }
      onComplete={() => setEdit(false)}
    >
      {({
        values: template,
        resetForm,
        submitForm,
        errors,
        touched,
        setFieldValue
      }) => {
        return (
          <>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.programDetailTitle} />
              </CardHeaderTitle>
              <FormEditHeaderButtons
                edit={edit}
                setEdit={setEdit}
                onCancel={resetForm}
                onSubmit={submitForm}
              />
            </CardHeader>
            <CardBody secondary separatorBottom>
              <FieldInput
                id="title"
                name="title"
                placeholder={intl.formatMessage(
                  messages.templateNamePlaceholder
                )}
                errors={errors}
                touched={touched}
                readOnly={!edit}
              >
                <FormattedMessage {...messages.templateNameLabel} />
              </FieldInput>
            </CardBody>

            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.exercisesLabelTags} />
              </CardHeaderTitle>
            </CardHeader>

            {edit ? (
              <CardBody secondary separatorBottom>
                <TagMultiSelect
                  entityId={entityId}
                  onChange={tags => {
                    setFieldValue('tags', tags);
                  }}
                  selectedTags={template.tags}
                />
              </CardBody>
            ) : (
              <CardBody secondary separatorBottom>
                <Tags
                  entityId={entityId}
                  selectedTags={template.tags}
                  isMulti
                  isClearable={false}
                  edit={false}
                />
              </CardBody>
            )}
            {edit && (
              <FormEditFooterButtons
                extraClassNames={'c-card__footer-bottom-fixed'}
                edit={edit}
                setEdit={edit => setEdit(edit)}
                onCancel={() => {
                  resetForm();
                  onCancel && onCancel();
                }}
                onSubmit={submitForm}
                type={'button'}
              />
            )}
          </>
        );
      }}
    </TemplateForm>
  );
};

export default TemplateDetailsTab;
