import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TEMPLATE } from 'services/aws/templates-query';
import { useStore } from 'stores/Store';
import Training from 'models/Training';

const useTraining = () => {
  const setTraining = useStore(state => state.setTraining);

  const [getTraining, { loading, error }] = useLazyQuery(QUERY_GET_TEMPLATE);

  const fetchTraining = async options => {
    const { data } = await getTraining({
      ...options
    });
    if (data?.getExerciseProgramTemplate) {
      const trainingData = data.getExerciseProgramTemplate;
      const training = new Training({ ...trainingData });
      setTraining(training);
      return training;
    }
  };

  return {
    loading,
    error,
    fetchTraining
  };
};

export default useTraining;
