import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Modal.scss';
import ReactModal from 'react-modal';
import Button from '../../components/button/Button';
import Icon from '../icon/Icon';

ReactModal.setAppElement('#root');

const Modal = props => {
  const {
    children,
    card,
    cardSmall,
    cardPanel,
    filter,
    extraClassNames,
    autoHeight,
    largeBody,
    alert,
    noCloseButton,
    benchmarksTableAlert,
    importSporters,
    bodyOverflowVisible,
    bodyOverflowScroll
  } = props;

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(() => {
      if (props.onClose) props.onClose();
    }, 600);
  };

  useEffect(() => {
    // console.log('useeffect', props.isOpen);
    setShowModal(props.isOpen);
  }, [props.isOpen]);

  return (
    <ReactModal
      closeTimeoutMS={600}
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      className={classNames('c-modal__content', extraClassNames, {
        'c-card c-card--shadow': card,
        'c-card c-card--modal-small': cardSmall,
        'c-card c-card--panel': cardPanel,
        'c-card c-card--panel c-card--filter': filter,
        'c-card-modal__content-alert': alert,
        'c-card-modal__content-auto-height': autoHeight,
        'c-card-modal__content-body-max-height': largeBody,
        'c-card c-card-modal__import-sporters': importSporters,
        'c-card-modal__content-alert c-card-modal__content-benchmark-table-alert':
          benchmarksTableAlert,
        'ReactModal__Overlay--before-close': !showModal,
        'c-card-modal__overflow-visible': bodyOverflowVisible,
        'c-card-modal__overflow-scroll': bodyOverflowScroll
      })}
      overlayClassName={classNames('c-modal__overlay', {
        'c-modal__overlay-benchmark-table-alert': benchmarksTableAlert
      })}
    >
      {!noCloseButton && (
        <Button
          tiny
          extraClassNames="c-button--absolute-top-right"
          onClick={e => handleCloseModal(e)}
        >
          <Icon id="close" strokeColor="color-primary-dark" />
        </Button>
      )}
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  emptyState: PropTypes.bool
};

export default Modal;
