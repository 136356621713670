import appSyncConfig from './config/AppSync';
import { Auth } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import * as Sentry from '@sentry/react';
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { PersonStatus } from 'constants.js';
import { useStore } from 'stores/Store';

const url = appSyncConfig.graphqlEndpoint;
const region = appSyncConfig.region;
const auth = {
  type: appSyncConfig.authenticationType,
  credentials: () => Auth.currentCredentials(),
  jwtToken: async () =>
    (await Auth.currentSession()).getAccessToken().getJwtToken()
};

const authLink = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  new HttpLink({ uri: url })
]);

const skipErrors = [
  'person_has_unconfirmed_login',
  'person_has_confirmed_login'
];

const getOperationData = ({ includeVariables, includeBody, operation }) => {
  const query = operation?.query;

  const definition = query?.definitions?.[0];

  const operationData = {
    name: operation?.operationName,
    type: definition?.['operation']
  };

  if (includeBody) {
    operationData.body = query?.loc?.source?.body || '';
  }

  if (includeVariables) {
    operationData.variables = JSON.stringify(operation?.variables || {});
  }

  return operationData;
};

const getResponseData = ({ response }) => {
  if (!response) {
    return null;
  }

  let responseData;

  try {
    responseData = JSON.stringify(response);
  } catch {
    responseData = response;
  }

  return responseData;
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(graphQLError => {
        if (skipErrors.some(error => graphQLError.message.includes(error))) {
          return;
        }
        // If the user is not authorised redirect to root
        if (graphQLError.message === 'not_authorised') {
          useStore.setState({ authorized: false });
        }
        console.error(
          `[GraphQL error]: Message:`,
          graphQLError.message,
          graphQLError.path
        );

        Sentry.withScope(scope => {
          if (operation) {
            const operationData = getOperationData({
              includeVariables: true,
              includeBody: true,
              operation
            });
            console.error(`[GraphQL error]: Operation data:`, operationData);
            scope.setExtra('operation', operationData);
          }

          if (response) {
            const responseData = getResponseData({
              response
            });
            scope.setExtra('response', responseData);
          }

          scope.setExtra('graphQLError', graphQLError);
          const errorTitle = 'GraphQL Error';

          Sentry.captureException(new Error(errorTitle));
        });
      });
    }
    if (networkError) {
      Sentry.captureException(networkError);
    }
  }
);

export let appSyncClient;

const personFieldsAccountMeta = {
  read(account_meta = {}) {
    return typeof account_meta === 'string'
      ? JSON.parse(account_meta)
      : account_meta;
  }
};

const personFieldsIntegrations = {
  read(integrations = {}) {
    return typeof integrations === 'string'
      ? JSON.parse(integrations)
      : integrations;
  }
};

const personFieldsStatus = {
  read(status, { readField }) {
    let accountMeta = readField('account_meta');
    const email = readField('email');
    let meta = readField('meta');
    let athleteEmail = null;
    if (accountMeta && typeof accountMeta === 'string') {
      accountMeta = JSON.parse(accountMeta);
    }
    if (meta && typeof meta === 'string') {
      meta = JSON.parse(meta);
      athleteEmail = meta?.email ? (meta?.email).toString().trim() : '';
    }

    let accountStatus = PersonStatus.NONE;
    if (email && accountMeta?.status === 'CONFIRMED') {
      accountStatus = PersonStatus.USER;
    } else if (
      email &&
      (accountMeta?.status === 'FORCE_CHANGE_PASSWORD' ||
        accountMeta?.status === 'DELETED') &&
      accountMeta?.expires &&
      new Date(accountMeta.expires) < new Date()
    ) {
      accountStatus = PersonStatus.UNCONFIRMED;
    } else if (
      email &&
      accountMeta?.status === 'FORCE_CHANGE_PASSWORD' &&
      accountMeta?.expires &&
      new Date(accountMeta.expires) > new Date()
    ) {
      accountStatus = PersonStatus.PENDING;
    } else if (athleteEmail && !email) {
      accountStatus = PersonStatus.EMAIL;
    }
    return accountStatus;
  }
};

export const setClient = async () => {
  appSyncClient = new ApolloClient({
    link: from([errorLink, authLink]),
    shouldBatch: true,
    cache: new InMemoryCache({
      typePolicies: {
        Person: {
          fields: {
            account_meta: personFieldsAccountMeta,
            integrations: personFieldsIntegrations,
            status: personFieldsStatus
          }
        },
        PersonV2: {
          fields: {
            account_meta: personFieldsAccountMeta,
            integrations: personFieldsIntegrations,
            status: personFieldsStatus
          }
        },
        TestDataV2: {
          keyFields: ['id', 'resultBenchmarkId']
        },
        TestSetV2: {
          fields: {
            config: {
              // Merge the config fields together
              merge(existing = {}, incoming, { mergeObjects }) {
                if (incoming) {
                  return mergeObjects(
                    existing,
                    typeof incoming === 'string'
                      ? JSON.parse(incoming)
                      : incoming
                  );
                }
                return existing;
              }
            },
            meta: {
              read(meta = {}) {
                return typeof incoming === 'string' ? JSON.parse(meta) : meta;
              }
            }
          }
        },
        GrowthData: {
          fields: {
            testItemValues: {
              read(testItemValues = {}) {
                return typeof testItemValues === 'string'
                  ? JSON.parse(testItemValues)
                  : testItemValues;
              }
            },
            heightEvolutionData: {
              read(heightEvolutionData = {}) {
                return typeof heightEvolutionData === 'string'
                  ? JSON.parse(heightEvolutionData)
                  : heightEvolutionData;
              }
            },
            weightEvolutionData: {
              read(weightEvolutionData = {}) {
                return typeof weightEvolutionData === 'string'
                  ? JSON.parse(weightEvolutionData)
                  : weightEvolutionData;
              }
            }
          }
        }
      }
    })
  });

  // await sleep(1000);
  return appSyncClient;
};

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
