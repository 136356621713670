import { SORT_TYPES } from 'utils/sort';
import { FIXED_RESULT_COLS } from 'constants.js';

const defaultState = {
  selectedHeaders: Array(FIXED_RESULT_COLS)
    .fill()
    .map(() => ({ id: null, sortDirection: SORT_TYPES.DOWN }))
};

export const createGrowthSlice = set => ({
  selectedHeaders: defaultState.selectedHeaders,
  setSelectedHeader: ({ index, value }) =>
    set(state => {
      state.selectedHeaders[index] = {
        id: value ?? null,
        sortDirection: SORT_TYPES.DOWN
      };
      return {
        selectedHeaders: state.selectedHeaders
      };
    }),
  setSelectedHeaders: value => set(() => ({ selectedHeaders: [...value] })),
  resetSelectedHeaders: () =>
    set(() => ({ selectedHeaders: defaultState.selectedHeaders })),
  result: null
});
