import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import AnnotationForm from 'containers/pages/videos/AnnotationForm';
import 'components/video/Annotations.scss';
import classNames from 'classnames';
import useTags from 'hooks/queries/useTags';
import RemoveAnnotationButton from 'containers/partials/buttons/RemoveAnnotationButton';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import Badge from 'components/badge/Badge';
import { secondsToHHMMSS } from 'utils/time';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Textarea from 'components/input/Textarea';
import { StoreContext } from 'index';
import CheckAnnotationButton from 'containers/pages/videos/CheckAnnotationButton';
import { useMutation } from '@apollo/client';
import { MUTATION_ADD_NOTE } from 'services/aws/notes-query';
import { NoteTypes } from 'constants.js';
import { QUERY_GET_ANNOTATIONS } from 'services/aws/annotations-query';
import { useNotificationQueue } from 'components/notification';
import AnnotationInput from 'containers/pages/videos/AnnotationInput';
import { useEditableContext } from 'contexts/EditableContext';
import { Card, CardBody } from 'components/card';
import RemoveNoteButton from 'containers/partials/buttons/RemoveNoteButton';
import { sanitize } from 'dompurify';

AnnotationListItem.propTypes = {};

function AnnotationListItem({
  annotation,
  videoId,
  activeAnnotation,
  parentPath,
  onClick,
  editable,
  refetch,
  ownershipVideo,
  videoSticky,
  overflowParent
}) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { isEditing, setIsEditing } = useEditableContext();
  const notification = useNotificationQueue();
  const annotateTextRef = useRef(null);
  const { push } = useHistory();
  const { entityId } = useParams();
  const [annotate, setAnnotate] = useState(false);
  const { tags } = useTags({ entityId });
  const [annotationTags, setAnnotationTags] = useState({
    dimension: '',
    behavior: ''
  });
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [note, setNote] = useState('');
  const parentElement = useRef();

  useEffect(() => {
    if (Array.isArray(annotation.tags)) {
      const dimensionTag = tags.find(t => t.id === annotation.tags[0]);
      const behaviourTag =
        dimensionTag &&
        dimensionTag.children.find(t => t.id === annotation.tags[1]);
      setAnnotationTags({
        dimension: dimensionTag?.label,
        behavior: behaviourTag?.label
      });
    }
  }, [annotation.tags, tags]);

  useEffect(() => {
    if (parentElement.current && activeAnnotation?.id === annotation.id) {
      scrollToElement(parentElement.current, overflowParent);
    }
  }, [activeAnnotation]);

  useEffect(() => {
    if (annotate && annotateTextRef?.current) {
      annotateTextRef.current.focus();
    }
  }, [annotate, annotateTextRef]);

  const onCompleteHandler = () => {
    //push(parentPath);
  };

  const onCompleteRemoveNoteHandler = ({ id }) => {
    notification.add(id, {
      message: intl.formatMessage(messages.messageAddNoteSuccess)
    });
  };

  const onCancelHandler = () => {
    //push(parentPath);
    setIsEditing(false);
  };

  const onRemoveCompleteHandler = () => {
    push(generatePath(parentPath));
  };

  const scrollToElement = (element, overflowParent = null) => {
    const offsetTopElement = element.offsetTop;
    const topPosition = videoSticky
      ? offsetTopElement -
        (videoSticky.current.getBoundingClientRect().height + 40)
      : offsetTopElement - 32;

    overflowParent &&
      overflowParent.current.scrollTo({ top: topPosition, behavior: 'smooth' });
  };

  const formatAnnotation = annotationText => {
    return annotationText
      ? annotationText.replace(/(?:\r\n|\r|\n)/g, '<br />')
      : '';
  };

  const submitAnnotationNoteHandler = async () => {
    const addNoteResult = await addNote({
      variables: {
        linkId: annotation.id,
        note: note,
        noteType: NoteTypes.ANNOTATION
      },
      refetchQueries: [
        {
          query: QUERY_GET_ANNOTATIONS,
          variables: { linkId: videoId }
        }
      ]
    });

    if (addNoteResult.data.addNote) {
      notification.add(addNoteResult.data.addNote.id, {
        message: intl.formatMessage(messages.messageAddNoteSuccess)
      });
    }
  };

  const userIsSupervisor = user.hasAdminRole();
  const userIsSuperAdmin = user.isAdmin();
  const annotationBelongsToMe = annotation.isMine(user);
  const annotationBelongsToStudent = annotation.isByAthlete(user);
  const annotationBelongsToSupervisor = annotation.isByAdmin(user);

  const notesInAnnotation = annotation?.notes?.length;

  // Annotation is Checked by Supervisor/Admin
  const isChecked = annotation.isChecked();

  // Only Admins on Students' annotation are able to Checked
  const isCheckable = userIsSupervisor && annotation.isByAthlete();

  // If the annotation is submitted and checked, the student can't remove it anymore
  const isRemovable =
    (user.isAthlete() && annotationBelongsToMe && !isChecked) ||
    (userIsSupervisor && annotationBelongsToMe) ||
    userIsSuperAdmin;

  const isEditable =
    (user.isAthlete() && annotationBelongsToMe && !isChecked) ||
    (userIsSupervisor && annotationBelongsToMe) ||
    userIsSuperAdmin;

  return isEditing === annotation.id ? (
    <Card secondary>
      <CardBody>
        <AnnotationForm
          entityId={entityId}
          annotation={annotation}
          onComplete={id => onCompleteHandler(id)}
          onCancel={onCancelHandler}
          refetch={refetch}
        >
          <AnnotationInput entityId={entityId} edit={true} />
        </AnnotationForm>
      </CardBody>
    </Card>
  ) : (
    <>
      <AnnotationForm
        entityId={entityId}
        annotation={annotation}
        extraClassNames={classNames('c-annotation u-padding-medium', {
          'c-annotation--active': annotation.id === activeAnnotation?.id,
          'c-annotation--own':
            annotationBelongsToStudent &&
            ownershipVideo === annotation.person.id,
          'c-annotation--other':
            annotationBelongsToStudent &&
            ownershipVideo !== annotation.person.id,
          'c-annotation--supervisor': annotationBelongsToSupervisor,
          'c-annotation--own-supervised':
            (annotationBelongsToStudent &&
              ownershipVideo === annotation.person.id &&
              notesInAnnotation > 0) ||
            (annotationBelongsToStudent &&
              ownershipVideo === annotation.person.id &&
              isChecked)
        })}
        onComplete={id => onCompleteHandler(id)}
        onCancel={onCancelHandler}
        refetch={refetch}
      >
        <>
          <div className="o-flex o-flex--wrap" ref={parentElement}>
            <Button
              tiny
              extraClassNames="c-annotation__time u-margin-bottom-medium"
              onClick={() => {
                scrollToElement(parentElement.current, overflowParent);
                onClick(annotation);
              }}
            >
              <div
                className={classNames(
                  'c-annotation__ownership c-annotation__time-duration'
                )}
              >
                {/* TODO: Add the duration */}
                {/* <time className="u-hidden-visually" dateTime="PT2M0S">
                      Lengte van de annotatie: 2 minuten 0 seconden
                    </time> */}
              </div>
              <time>{secondsToHHMMSS(annotation.starttime)}</time>

              {/* In comment till we work with endtimes
            // TODO: uncomment if works
                <Icon id="arrow-right" strokeColor="color-neutral-dark" />
                <time>{secondsToHHMMSS(annotation.endtime)}</time>
            */}

              {(annotationBelongsToStudent || annotationBelongsToSupervisor) &&
                !annotationBelongsToMe && (
                  <Badge
                    style={{
                      padding: '2px 12px',
                      fontFamily: "'lato', sans-serif",
                      color: '#34373e',
                      fontSize: 13,
                      lineHeight: 1.5
                    }}
                    extraClassNames="u-margin-left-medium"
                  >
                    <FormattedMessage
                      {...messages.annotationMadeByStudentName}
                      values={{
                        isOwner: ownershipVideo === annotation.person.id,
                        student: `${annotation.person.firstname} ${annotation.person.lastname}`
                      }}
                    />
                  </Badge>
                )}
            </Button>

            {/* Dit is om de wrapper van de knoppen te zetten zodra dat er één van de knoppen aanwezig moeten zijn */}
            {((isChecked &&
              !annotationBelongsToSupervisor &&
              !userIsSupervisor) ||
              isCheckable ||
              isRemovable ||
              isEditable) && (
              <div className="u-margin-left-auto u-margin-bottom-medium">
                <ButtonsGroup verticalCenter>
                  {isChecked &&
                    !annotationBelongsToSupervisor &&
                    !userIsSupervisor && <Icon id="validation-check"></Icon>}

                  {isCheckable && (
                    <>
                      {!isChecked ? (
                        <CheckAnnotationButton
                          testData={annotation.testData}
                          linkId={videoId}
                          rounded
                          ghost
                        >
                          <Icon
                            id="checkbox-inactive"
                            fillColor="color-neutral-x-light"
                          />
                          <FormattedMessage {...messages.annotationChecked} />
                        </CheckAnnotationButton>
                      ) : (
                        <Button rounded ghost readOnly>
                          {isChecked && <Icon id="validation-check" />}
                          <FormattedMessage {...messages.annotationChecked} />
                        </Button>
                      )}

                      {!notesInAnnotation && (
                        <Button
                          rounded
                          accented
                          pseudoDisabled={annotate}
                          onClick={() => {
                            setAnnotate(true);
                            if (annotate) {
                              annotateTextRef.current.focus();
                            }
                          }}
                        >
                          <Icon
                            id="comment"
                            strokeColor={
                              annotate
                                ? 'color-neutral'
                                : 'color-neutral-x-light'
                            }
                            fillColor={'transparent'}
                          />
                          <FormattedMessage {...messages.videosAnnotate} />
                        </Button>
                      )}
                    </>
                  )}

                  {isRemovable && (
                    <RemoveAnnotationButton
                      tiny
                      entityId={entityId}
                      annotationId={annotation.id}
                      linkId={videoId}
                      onComplete={onRemoveCompleteHandler}
                    />
                  )}

                  {isEditable && (
                    <Button
                      tiny
                      onClick={() => setIsEditing(annotation.id)}
                      disabled={!editable}
                    >
                      <Icon id="edit" strokeColor="color-neutral-dark" />
                    </Button>
                  )}
                </ButtonsGroup>
              </div>
            )}
          </div>

          <div className="o-layout o-layout--small">
            <div className="o-layout__item u-1-of-3-at-medium">
              <p className="c-input__label">
                <FormattedMessage {...messages.labelAnnotationTagsDimension} />
              </p>
              <p className="c-annotation__value">
                {annotationTags?.dimension && annotationTags.dimension}
              </p>
            </div>

            <div className="o-layout__item u-2-of-3-at-medium">
              <p className="c-input__label">
                <FormattedMessage {...messages.labelAnnotationTagsBehavior} />
              </p>
              <p className="c-annotation__value">
                {annotationTags?.behavior &&
                  annotationTags.behavior.replace(/^\d+\.\s/, '')}
              </p>
            </div>

            <div className="o-layout__item u-margin-top-small u-margin-bottom-medium o-flex o-flex--align-center">
              <p className="c-input__label u-margin-none">
                <FormattedMessage {...messages.labelAnnotationScore} />
              </p>
              <Badge
                style={{
                  color: '#7a8198',
                  backgroundColor: '#f4f5f7',
                  minWidth: 24,
                  height: 24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {annotation.score}
              </Badge>
            </div>
          </div>
          <div className="c-annotation__tekst">
            <p className="c-input__label">
              <FormattedMessage {...messages.labelAnnotationText} />
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  formatAnnotation(annotation.note).replace(
                    /(?:\r\n|\r|\n)/g,
                    '<br />'
                  ),
                  {
                    ALLOWED_TAGS: ['b', 'i', 'br']
                  }
                )
              }}
            />
          </div>

          {notesInAnnotation > 0 && (
            <div className="c-annotation c-annotation--supervisor">
              <div className="c-annotation__tekst">
                <div className="o-flex o-flex--align-center u-margin-bottom-small">
                  <p className="c-input__label u-margin-none">
                    <FormattedMessage {...messages.labelAnnotationText} />
                  </p>
                  <Badge
                    style={{
                      padding: '2px 12px',
                      fontFamily: "'lato', sans-serif",
                      color: '#34373e',
                      fontSize: 13,
                      lineHeight: 1.5
                    }}
                    extraClassNames="u-margin-left-medium"
                  >
                    <FormattedMessage
                      {...messages.annotationMadeByStudentName}
                      values={{
                        isOwner: false,
                        student: `${annotation.notes[0].person.firstname} ${annotation.notes[0].person.lastname}`
                      }}
                    />
                    {` (${intl.formatMessage(
                      messages.annotationOwnershipSupervisor
                    )})`}
                  </Badge>

                  {userIsSupervisor &&
                    annotation.notes[0].person.id === user.id && (
                      <div className="u-margin-left-auto u-margin-bottom-medium">
                        <ButtonsGroup verticalCenter>
                          <RemoveNoteButton
                            tiny
                            entityId={entityId}
                            noteId={annotation.notes[0].id}
                            linkId={videoId}
                            onComplete={onCompleteRemoveNoteHandler}
                          />
                        </ButtonsGroup>
                      </div>
                    )}
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      formatAnnotation(annotation.notes[0].note).replace(
                        /(?:\r\n|\r|\n)/g,
                        '<br />'
                      ),
                      {
                        ALLOWED_TAGS: ['b', 'i', 'br']
                      }
                    )
                  }}
                />
              </div>
            </div>
          )}

          {annotate &&
            !annotationBelongsToMe &&
            userIsSupervisor &&
            !notesInAnnotation && (
              <div className="c-annotation c-annotation--supervisor">
                <Textarea
                  ref={annotateTextRef}
                  id="note"
                  name="note"
                  resizeVertical
                  value={note}
                  characterLimit={1500}
                  rows={'5'}
                  onChange={e => {
                    setNote(e.target.value);
                  }}
                >
                  <FormattedMessage {...messages.labelAnnotationText} />
                </Textarea>
                <ButtonsGroup>
                  <Button
                    extraClassNames="u-margin-left-auto"
                    secondary
                    onClick={() => {
                      if (annotation.notes.length > 0) {
                        setNote(annotation.notes[0].note);
                      } else {
                        setNote('');
                      }
                      setAnnotate(false);
                    }}
                  >
                    <FormattedMessage {...messages.cancelButton} />
                  </Button>
                  <Button
                    primary
                    onClick={() => {
                      submitAnnotationNoteHandler();
                    }}
                  >
                    <FormattedMessage {...messages.saveButton} />
                  </Button>
                </ButtonsGroup>
              </div>
            )}
        </>
      </AnnotationForm>
    </>
  );
}

export default AnnotationListItem;
