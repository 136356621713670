import { RefactoredTable, TableBody, TableRow } from 'components/table';
import { useGrowthPanelContext } from 'contexts/GrowthPanelContext';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import RefactoredTableHeader from 'components/table/RefactoredTableHeader';
import GrowthTableHeaderCell from 'containers/pages/growthtracker/table/GrowthTableHeaderCell';
import GrowthTableCell from 'containers/pages/growthtracker/table/GrowthTableCell';
import ColumnVisibilityModal from 'containers/pages/growthtracker/modals/ColumnVisibilityModal';

const GrowthTable = () => {
  const {
    rows,
    cols,
    columnVisibility,
    setColumnVisibility,
    sorting,
    setSorting,
    showColumnVisibilityModal,
    setShowColumnVisibilityModal
  } = useGrowthPanelContext();

  const growthTable = useReactTable({
    data: rows,
    columns: cols,
    state: {
      sorting,
      columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSortingRemoval: false
  });

  return (
    <>
      <RefactoredTable variant={cols.length > 5 ? 'full-width' : 'fixed-width'}>
        <RefactoredTableHeader sticky>
          {growthTable.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => (
                <GrowthTableHeaderCell
                  key={header.id}
                  header={header}
                  index={i}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </GrowthTableHeaderCell>
              ))}
            </TableRow>
          ))}
        </RefactoredTableHeader>
        <TableBody>
          {growthTable.getRowModel().rows.map(row => {
            return (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell, i, arr) => (
                  <GrowthTableCell
                    key={cell.id}
                    cell={cell}
                    index={i}
                    style={{
                      ...(arr.length > 4 && i >= 1 ? { padding: '0 6px' } : {})
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </GrowthTableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </RefactoredTable>

      {showColumnVisibilityModal && (
        <ColumnVisibilityModal
          table={growthTable}
          onClose={() => setShowColumnVisibilityModal(false)}
        />
      )}
    </>
  );
};

export default GrowthTable;
