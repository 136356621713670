import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from 'components/table';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import {
  hasRowOverlapping,
  hasRowValidAge
} from 'containers/pages/settings/benchmarks/table.utils';
import { getEmptyRow, useDataTable } from 'hooks/utils/useDataTable';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import InputErrorMessage from 'components/input/InputErrorMessage';
import FieldInput from 'components/input/FieldInput';

function StandardDataTable({ edit, dataTable, dataKeys, loading, ...props }) {
  const intl = useIntl();
  const [state, dispatch] = useDataTable({ dataKeys, dataTable });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (dataTable.length > 0) {
      dispatch({
        type: 'setDataTable',
        payload: {
          dataTable: edit ? [...dataTable, getEmptyRow(dataKeys)] : dataTable
        }
      });
    }
  }, [dataTable, edit]);

  const onClickCell = (row, col) => {
    dispatch({
      type: 'onClick',
      payload: { row, col }
    });
  };

  const onChange = (e, rowData, key) => {
    dispatch({
      type: 'onChange',
      payload: { value: e.target.value, rowData, key }
    });
  };

  const onBlur = (e, rowData, key) => {
    dispatch({
      type: 'onBlur',
      payload: { value: e.target.value, rowData, key }
    });
  };

  const onFocus = (e, row, col) => {
    dispatch({
      type: 'onFocus',
      payload: { row, col }
    });
  };

  const onRowDelete = rowData => {
    dispatch({
      type: 'onDelete',
      payload: { rowData }
    });
  };

  const onSubmit = () => {
    const errors = [];
    state.dataTable.forEach((row, i) => {
      if (row.empty) return;

      if (!hasRowValidAge(row)) {
        errors.push({
          row: i,
          error: intl.formatMessage(
            messages.benchmarksBenchmarkTestErrorInValidAge
          )
        });
        return;
      }

      if (hasRowOverlapping(state.dataTable, row)) {
        errors.push({
          row: i,
          error: intl.formatMessage(
            messages.benchmarksBenchmarkTestErrorOverlappingAge
          )
        });
      }
    });

    if (errors.length) {
      setErrors({ errors });
      return;
    }

    setErrors(null);
    const jsonToSave = state.dataTable.filter(r => !r.empty);
    const sortedArray = sort(jsonToSave, {
      keys: [{ key: 'age_min', dataType: SORT_DATA_TYPES.NUMBER }]
    });
    props.onSubmit && props.onSubmit(sortedArray);
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <>
      <div
        style={{
          overflow: 'auto',
          width: 'calc(100% + 32px)',
          padding: '0 16px 16px',
          margin: '0 -16px 0 -16px'
        }}
      >
        <Table editable fixed isEdit={edit}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell colSpan="2">
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableAgeGroup}
                />
              </TableHeaderCell>
              <TableHeaderCell colSpan="2">
                <FormattedMessage {...messages.sexMale} />
              </TableHeaderCell>
              <TableHeaderCell colSpan="2">
                <FormattedMessage {...messages.sexFemale} />
              </TableHeaderCell>
              {edit && <TableHeaderCell rowSpan={2} />}
            </TableRow>
            <TableRow>
              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableMin}
                />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableMax}
                />
              </TableHeaderCell>

              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableAv}
                />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableSd}
                />
              </TableHeaderCell>

              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableAv}
                />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage
                  {...messages.benchmarksBenchmarkTestTableSd}
                />
              </TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {state.dataTable.map((rowData, iRow) => {
              if (!edit && rowData.empty) return null;
              const hasError =
                !hasRowValidAge(rowData) ||
                hasRowOverlapping(state.dataTable, rowData);
              return (
                <TableRow hasError={hasError} key={iRow} edit={rowData.empty}>
                  {dataKeys.map((key, iCol) => {
                    const activeCell =
                      iCol === state.activeCol && iRow === state.activeRow;
                    return (
                      <TableCell
                        key={key + iRow + iCol}
                        extraClassNames={activeCell ? 'is-edit' : ''}
                        onClick={() => edit && onClickCell(iRow, iCol)}
                      >
                        {!edit && rowData[key]}
                        {edit && (
                          <FieldInput
                            id={`input-${iRow}-${iCol}`}
                            inTable
                            autoFocus={activeCell}
                            defaultValue={rowData[key] ? `${rowData[key]}` : ``}
                            readOnly={!activeCell}
                            onBlur={e => onBlur(e, rowData, key)}
                            onChange={e => onChange(e, rowData, key)}
                            onFocus={e => onFocus(e, iRow, iCol)}
                            hasError={!rowData.empty && hasError && edit}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  {!rowData.empty && edit && (
                    <TableCell>
                      <Button
                        tiny
                        tabIndex={-1}
                        onClick={() => onRowDelete(rowData)}
                      >
                        <Icon id="delete" />
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {errors &&
          !!errors.length &&
          errors.map((e, i) => (
            <InputErrorMessage key={i}>
              <FormattedMessage
                {...messages.errorOnRow}
                values={{
                  message: `${e.row + 1}: ${e.error}`
                }}
              />
            </InputErrorMessage>
          ))}
        {edit && (
          <ButtonsGroup extraClassNames="u-padding-vertical-medium">
            <Button
              extraClassNames="u-margin-left-auto"
              secondary
              disabled={loading}
              onClick={onCancel}
            >
              <FormattedMessage {...messages.benchmarksBenchmarkButtonCancel} />
            </Button>
            <Button
              type="submit"
              primary
              disabled={loading}
              onClick={e => onSubmit(e, state.dataTable)}
            >
              <FormattedMessage {...messages.benchmarksBenchmarkButtonSave} />
            </Button>
          </ButtonsGroup>
        )}
      </div>
    </>
  );
}

export default StandardDataTable;
