import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardFooter } from 'components/card';
import { ROUTE_LOGIN } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup } from 'components/button';
import { Link, useHistory } from 'react-router-dom';
import { StoreContext } from 'index';

const PasswordResetSuccess = () => {
  const { push } = useHistory();
  const {
    authStore: { passwordResetEmail }
  } = useContext(StoreContext);

  useEffect(() => {
    if (!passwordResetEmail) {
      push(ROUTE_LOGIN);
    }
  }, [passwordResetEmail]);

  return (
    <Card extraClassNames="c-card--primary c-card--icon-message-cta">
      <CardHeader primary>
        <CardHeaderTitle>
          <FormattedMessage {...messages.loginForgotPasswordTitle} />
        </CardHeaderTitle>
        <CardHeaderSubtitle extraClassNames="u-margin-horizontal-auto">
          <FormattedMessage {...messages.loginForgotPasswordSubTitle} />
        </CardHeaderSubtitle>
      </CardHeader>
      <CardBody primary empty>
        <Icon id="big-send" xHugeIcon />
        <p className="c-card__main-message">
          <FormattedMessage
            {...messages.loginForgotPasswordSendEmail}
            values={{
              email: passwordResetEmail
            }}
          />
        </p>
        <p>
          <FormattedMessage {...messages.loginForgotPasswordMessage} />
        </p>
      </CardBody>
      <CardFooter primary>
        <ButtonsGroup>
          <Link
            to={`${ROUTE_LOGIN}?email=${encodeURIComponent(btoa(passwordResetEmail))}`}
            className="c-button c-button--primary"
          >
            <FormattedMessage {...messages.loginSubmitButton} />
          </Link>
        </ButtonsGroup>
      </CardFooter>
    </Card>
  );
};

export default PasswordResetSuccess;
