import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { observer } from 'mobx-react';
import { IntlProvider } from 'react-intl';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { LastLocationProvider } from 'react-router-last-location';
import { translationMessages } from 'i18n';
import App from './App';
import { NotificationProvider } from 'components/notification';
import { NotificationList } from 'components/notification/NotificationList';
import { DEFAULT_LOCALE } from 'constants.js';
import { AbilityContext } from 'Can';
import { withRouter } from 'react-router-dom';

const AppContainer = ({ client, stores }) => {
  const { locale, translations } = stores.uiState;

  return (
    <AbilityContext.Provider value={stores.authStore.abilities}>
      <NotificationProvider>
        <ApolloProvider client={client}>
          <IntlProvider
            locale={locale}
            defaultLocale={DEFAULT_LOCALE}
            messages={translationMessages[translations]}
            onError={err => {
              if (err.code === 'MISSING_TRANSLATION') {
                console.warn('Missing translation', err.message);
                return;
              }
              throw err;
            }}
          >
            <CompatRouter>
              <LastLocationProvider>
                <App />
              </LastLocationProvider>
            </CompatRouter>
          </IntlProvider>
        </ApolloProvider>
        <NotificationList />
      </NotificationProvider>
    </AbilityContext.Provider>
  );
};

export default withRouter(observer(AppContainer));
