import { useLazyQuery } from '@apollo/client';
import Template from 'models/Template';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import { getFilteredEntities } from 'utils/search';
import { useStore } from 'stores/Store';

const useTemplates = entityId => {
  const setTemplates = useStore(state => state.setTemplates);
  const templatesRef = useStore(state => state.templatesRef);
  const setTemplatesRef = useStore(state => state.setTemplatesRef);
  const setTemplatesFilter = useStore(state => state.setTemplatesFilter);

  const [getTemplates, { loading, error }] = useLazyQuery(QUERY_GET_TEMPLATES);

  const fetchTemplates = async options => {
    const { data } = await getTemplates({
      variables: { entityId },
      ...options
    });
    if (data?.getExerciseProgramTemplates) {
      const templates = data.getExerciseProgramTemplates.map(
        template => new Template({ ...template })
      );
      setTemplatesRef(templates);
      setTemplates(templates);

      return templates;
    }
  };

  const filterTemplates = async filter => {
    setTemplatesFilter(filter);
    const templates = [...templatesRef];

    setTemplates(
      getFilteredEntities(templates, filter?.search || '', filter.tags || [])
    );
  };

  return {
    loading,
    error,
    fetchTemplates,
    filterTemplates
  };
};

export default useTemplates;
