import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';

import Overview from 'containers/pages/person/programs/Overview';

import {
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM,
  ROUTE_USER_ENTITY_PROGRAMS,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK,
  ROUTE_USER_PROGRAMS,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { StoreContext } from 'index';
import ProgramExercises from 'containers/pages/person/programs/ProgramExercises';
import ProgramExercise from 'containers/pages/person/programs/ProgramExercise';
import ContextMenuProvider from 'contexts/ContextMenuProvider';

const UserPrograms = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();

  return (
    <Switch>
      <Route
        path={[ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, ROUTE_USER_ENTITY_PROGRAMS]}
      >
        <Overview />
        <Switch>
          <Route
            path={[
              ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE,
              ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK,
              ROUTE_USER_ENTITY_PROGRAMS_PROGRAM
            ]}
          >
            <ContextMenuProvider>
              <ProgramExercises />
            </ContextMenuProvider>
            <Switch>
              <Route
                path={
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE
                }
              >
                <ContextMenuProvider>
                  <ProgramExercise />
                </ContextMenuProvider>
              </Route>
            </Switch>
          </Route>
          <Route path={ROUTE_USER_ENTITY_PROGRAMS}>
            <Panel
              emptyState
              emptyLabel={intl.formatMessage(messages.exercisesEmptyPanel)}
            />
          </Route>
        </Switch>
      </Route>
      <Route
        path={[ROUTE_USER_PROGRAMS]}
        exact
        render={() => {
          return (
            <Redirect
              to={generatePath(ROUTE_USER_ENTITY_PROGRAMS, {
                entityId: user.rootEntityId
              })}
            />
          );
        }}
      />
    </Switch>
  );
};

export default UserPrograms;
