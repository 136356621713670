import classNames from 'classnames';

const CardHeaderTitle = props => {
  const { children, small, extraClassNames, label, style, ...rest } = props;

  return (
    <h3
      className={classNames('c-card__header-title', extraClassNames, {
        'c-card__header-title--small': small,
        'c-card__header-title--label': label
      })}
      style={style}
      {...rest}
    >
      {children}
    </h3>
  );
};

export default CardHeaderTitle;
