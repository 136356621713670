import { useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { EntityTypes } from 'constants.js';
import Group from 'models/Group';
import { sort } from 'utils/sort';
import { getFilteredGroups } from 'utils/entities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function useGroups({ entityId }) {
  const groupData = useRef();

  const group = useEntityContext(s => s.group);
  const setGroup = useEntityContext(s => s.setGroup);
  const setGroups = useEntityContext(s => s.setGroups);
  const filter = useEntityContext(s => s.filter);

  const [getGroups, { loading, error, data }] = useLazyQuery(
    S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
    {
      variables: { id: entityId }
    }
  );

  useEffect(() => {
    if (data?.getEntity) {
      const group = new Group(data.getEntity);
      groupData.current = group;
      if (group?.subEntities) {
        const groups = group.subEntities
          .filter(se => se.type !== EntityTypes.ORGANISATION)
          .map(g => new Group(g));
        setGroups(sort(groups, { keys: [{ key: 'name' }] }));
      }
      setGroup(group);
    }
  }, [data]);

  useEffect(() => {
    if (group && groupData?.current?.id) {
      const filteredGroups = getFilteredGroups(
        groupData.current.subEntities.map(g => new Group({ ...g })),
        filter.search ?? ''
      );
      // get the list of unfiltered groups
      const groupsNotInFilteredGroups = groupData.current.subEntities
        .filter(
          group =>
            !filteredGroups.some(filteredGroup => filteredGroup.id === group.id)
        )
        .map(g => new Group({ ...g, filtered: false }));
      setGroups([
        ...sort(filteredGroups, {
          keys: [{ key: 'name' }]
        }),
        ...sort(groupsNotInFilteredGroups, {
          keys: [{ key: 'name' }]
        })
      ]);
    }
  }, [filter, group]);

  const fetchGroups = async entityId => {
    await getGroups({
      variables: { id: entityId },
      fetchPolicy: 'network-only'
    });
  };

  return {
    loading,
    error,
    fetchGroups
  };
}

export default useGroups;
