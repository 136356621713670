import classnames from 'classnames';
import { forwardRef } from 'react';

const TabPanelBody = forwardRef((props, ref) => {
  const {
    withScroller,
    withHeader,
    withContentAndCards,
    extraClassNames,
    children,
    style,

    ...rest
  } = props;

  return (
    <div
      className={classnames(
        'p-tabs__active-tab-content-wrapper',
        extraClassNames,
        {
          'p-tabs__active-tab-content-wrapper--scroll': withScroller,
          'p-tabs__with-header': withHeader,
          'p-tabs__with-content-and-cards': withContentAndCards
        }
      )}
      style={style}
    >
      {children}
    </div>
  );
});

export default TabPanelBody;
