import { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID } from 'services/aws/client-query';

const buildGroupCrumb = (branch, entityId, path) => {
  const groupInBranch = branch.find(group => group.id === entityId);
  if (groupInBranch) {
    return [...path, groupInBranch];
  }
  for (let i = 0; i < branch.length; i++) {
    if (branch[i].subEntities.length > 0) {
      const subPath = buildGroupCrumb(branch[i].subEntities, entityId, [
        ...path,
        branch[i]
      ]);
      if (subPath) {
        return subPath;
      }
    }
  }
};

function useGroupsBreadCrumb({ entityId, groupId }) {
  const groupTree = useRef(null);
  const [groupBreadcrumb, setGroupBreadcrumb] = useState([]);

  const [getGroupTree, { data, refetch, loading }] = useLazyQuery(
    QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID,
    {
      variables: { entityId: entityId }
    }
  );

  useEffect(() => {
    const getAndBuildGroupTree = async () => {
      const { data } = await getGroupTree();
      groupTree.current = JSON.parse(data?.getEntityTree).tree.subEntities;
      setGroupBreadcrumb(buildGroupCrumb(groupTree.current, groupId, []));
    };

    getAndBuildGroupTree().catch(err => console.error(err));
  }, [groupId]);

  useEffect(() => {
    if (groupTree.current) {
      setGroupBreadcrumb(buildGroupCrumb(groupTree.current, groupId, []));
    }
  }, [groupId, groupTree.current, data]);

  return {
    groupBreadcrumb,
    loading,
    refetch
  };
}

export default useGroupsBreadCrumb;
