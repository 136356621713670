import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Field, useFormikContext } from 'formik';

const TinyEditor = ({ note }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Field
      as={Editor}
      name="note"
      initialValue={note}
      init={{
        branding: false,
        height: 300,
        menubar: false,
        plugins: ['autolink lists link help', 'wordcount paste'],
        invalid_elements: 'div',
        help_tabs: [
          'shortcuts', // the default shortcuts tab
          'keyboardnav' // the default keyboard navigation tab
        ],

        toolbar:
          'undo redo | formatselect | bold italic | bullist outdent indent | help'
      }}
      onEditorChange={content => {
        setFieldValue('note', content);
      }}
    />
  );
};

export default TinyEditor;
