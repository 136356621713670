import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import InputErrorMessage from './InputErrorMessage';

const Textarea = forwardRef((props, ref) => {
  const {
    disabled,
    readOnly,
    id,
    children,
    name,
    resizeVertical,
    characterLimit,
    extraClassNames,
    minLength,
    maxLength,
    required,
    autoFocus,
    placeholder,
    icon,
    iconColor,
    iconLeft,
    onChange,
    onBlur,
    rows = '5',
    value,
    errorMessage,
    style
  } = props;

  const [charactersRemaining, setCharactersRemaining] = useState(
    characterLimit ?? 0
  );

  const onChangeHandler = event => {
    if (characterLimit) {
      setCharactersRemaining(characterLimit - event.target.value.length);
    }

    onChange && onChange(event);
  };

  return (
    <div
      className={classNames('c-input__group', extraClassNames, {
        'c-input--closed': disabled,
        'c-input--read-only': readOnly
      })}
    >
      {id && children && (
        <label
          htmlFor={id}
          className={classNames('c-input__label', {
            'c-input__label-flex': characterLimit
          })}
        >
          {children}

          {characterLimit && (
            <div className="c-input__label-extra-info">
              <span>
                <FormattedMessage
                  {...messages.textareaCharacterLimit}
                  values={{
                    limit: characterLimit
                  }}
                />{' '}
                {charactersRemaining < characterLimit &&
                  charactersRemaining >= 0 && (
                    <FormattedMessage
                      {...messages.textareaCharacterCount}
                      values={{
                        count: charactersRemaining
                      }}
                    />
                  )}
              </span>
              {charactersRemaining < 0 && (
                <span
                  className={classNames('', {
                    'u-color-error': characterLimit && charactersRemaining < 0
                  })}
                >
                  <FormattedMessage
                    {...messages.textareaCharacterCountWarning}
                    values={{
                      count: charactersRemaining * -1
                    }}
                  />
                </span>
              )}
            </div>
          )}
        </label>
      )}
      <div className="c-input__wrapper">
        <textarea
          id={id}
          ref={ref}
          name={name}
          minLength={minLength}
          maxLength={maxLength}
          rows={rows}
          className={classNames('c-input', {
            'c-input--textarea-resize-vertical': resizeVertical,
            'c-input--textarea': !resizeVertical,
            'has-error': charactersRemaining < 0
          })}
          disabled={disabled}
          readOnly={readOnly || !onChange}
          required={required}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={event => {
            onChangeHandler(event);
          }}
          value={value}
          style={style}
        />

        {icon && !readOnly && (
          // type !== 'date' &&
          <Icon
            id={icon}
            fillColor={iconColor}
            extraClassNames={classNames('c-icon--input', {
              'c-icon--input-left': iconLeft
            })}
          />
        )}
      </div>
      {errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
    </div>
  );
});

export default Textarea;

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  resizeVertical: PropTypes.bool,
  characterLimit: PropTypes.number,
  extraClassNames: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconLeft: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  rows: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string
};
