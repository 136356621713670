import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import useSelectable from 'hooks/utils/useSelectable';
import { useUIDSeed } from 'react-uid';
import VideoListItemSortable from 'containers/partials/list-items/VideoListItemSortable';
import { StoreContext } from 'index';

const VideoListSortable = ({
  activeId,
  exercises = [],
  onClick,
  draggable,
  enablePreviewModal,
  onPreview,
  onSelect,
  selectedExercises,
  extraClassNames = {},
  showTemplateCount
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectable([]);

  useEffect(() => {
    if (selectedExercises) {
      setSelected([...selectedExercises]);
    }
  }, [selectedExercises]);

  const onSelectHandler = exerciseId => {
    const selectIds = select(exerciseId);
    onSelect && onSelect(selectIds);
  };

  return (
    <>
      {exercises.length > 0 ? (
        <List
          ref={parentRef}
          extraClassNames={extraClassNames}
          dragNDrop={draggable}
        >
          <ListBody>
            {exercises.map(exercise => {
              return (
                <VideoListItemSortable
                  showSharesAndAnnotations
                  id={exercise.id}
                  key={exercise.id}
                  uid={uidSeed(exercise.id)}
                  exercise={exercise}
                  active={exercise.id === activeId}
                  done={exercise.finished}
                  inProgress={
                    exercise.annotations.length > 0 && !exercise.finished
                  }
                  showTemplateCount={showTemplateCount}
                  showMessage={exercise.media?.length === 0 && user.isVitaleOrg}
                  visible={selected.length > 0}
                  checkbox
                  clickable
                  draggable={draggable}
                  checked={selected.includes(exercise.id)}
                  onShowPreview={() =>
                    enablePreviewModal && onPreview(exercise)
                  }
                  onChange={() => onSelectHandler(exercise.id)}
                  onClick={() =>
                    enablePreviewModal
                      ? onPreview(exercise)
                      : onClick && onClick(exercise.id)
                  }
                />
              );
            })}
          </ListBody>
        </List>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="cursor">
              <MessageText>
                <FormattedMessage {...messages.exercisesListEmpty} />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default VideoListSortable;
