import { useContext, useEffect, useState } from 'react';
import { generatePath, useHistory, withRouter } from 'react-router-dom';
import {
  ROUTE_SETTINGS_USERS_USER,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_USERS_USER_ROLES,
  ROUTE_SETTINGS_USERS_USER_EDIT
} from 'routes/RouteList';
import UserModel from 'models/User';
import {
  QUERY_GET_USER_BY_ID,
  MUTATION_UPDATE_USER,
  updateUserLanguage,
  MUTATION_ADD_USER,
  QUERY_GET_USERS_BY_ENTITY_ID,
  getPersonEntityWithEntities
} from 'services/aws/user-query';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelTitleButton from 'components/panel/panel-title/PanelTitleButton';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import InputButtonWrapper from 'components/input/InputButtonWrapper';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import { MenuWrapper } from 'components/menu';
import RemoveUserButton from 'containers/partials/buttons/RemoveUserButton';
import { QUERY_GET_ENTITY_BY_ID } from 'services/aws/entity-query';
import ResetLoginButton from 'containers/partials/buttons/ResetLoginButton';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderButtons
} from 'components/card/card-header';
import { getFullLocation, navigateToUrl } from 'utils/browser';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import PermissionsList from './PermissionsList';
import Loader from 'components/loader/Loader';
import FieldInput from 'components/input/FieldInput';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';
import useEdit from 'hooks/utils/useEdit';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import LanguageSelect from 'components/input/LanguageSelect';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { PersonEntityRoles, PersonStatus } from 'constants.js';
import PersonStatusPopper from 'containers/partials/misc/PersonStatusPopper';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import usePersonStatus from 'hooks/utils/usePersonStatus';
import RemoveLoginButton from 'containers/partials/buttons/RemoveLoginButton';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const User = ({ userId = null, tabIndex = 0, entityId, active, ...props }) => {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const { push } = useHistory();
  const {
    userStore,
    authStore,
    authStore: {
      user: { rootEntityId },
      entity
    }
  } = useContext(StoreContext);
  const notification = useNotificationQueue();
  const [edit, setEdit] = useEdit(props.edit, true);
  const { personStatusHandler } = usePersonStatus(entityId);
  const [user, setUser] = useState(new UserModel({}, entityId));
  const [personEntity, setPersonEntity] = useState();
  const setPerson = useEntityContext(s => s.setPerson);

  const { loading, error, data } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { id: userId },
    skip: !userId
  });

  useEffect(() => {
    const getPersonEntityFromUser = async user => {
      const personEntitiesResult = await getPersonEntityWithEntities(
        user.id,
        entityId
      );
      if (personEntitiesResult?.data?.getPersonEntity) {
        setPersonEntity(personEntitiesResult.data.getPersonEntity);
      }
    };
    if (data?.getPerson) {
      const user = new UserModel(data.getPerson, entityId);
      setUser(user);
      setPerson(user);
      getPersonEntityFromUser(user);
    }
  }, [data, entityId]);

  const [updateUser] = useMutation(MUTATION_UPDATE_USER);
  const [addUser] = useMutation(MUTATION_ADD_USER);

  const onSubmitHandler = async userData => {
    return new Promise(async resolve => {
      const userObj = {
        entityId,
        email: userData.email.toLowerCase(),
        lastname: userData.lastname,
        firstname: userData.firstname
      };

      if (!userId) {
        const { data } = await addUser({
          variables: {
            ...userObj,
            topLevelEntityId: rootEntityId,
            entityId: rootEntityId,
            roles: [
              PersonEntityRoles.ROLE_SUB_ADMIN,
              PersonEntityRoles.ROLE_TESTER
            ],
            baseLanguage: userData.baseLanguage,
            uid: ''
          },
          refetchQueries: [
            {
              query: QUERY_GET_USERS_BY_ENTITY_ID,
              variables: { id: rootEntityId }
            }
          ]
        });

        if (data) {
          const id = data.addPersonToEntityReturnPerson.id;
          const { errors } = await userStore.createLogin(id);
          if (errors) {
            errors.forEach(error => {
              if (error.message === 'person_has_unconfirmed_login') {
                // 1. The person already exist in the platform
                // 2. The person has not confirmed their e-mail yet
                // 3. Therefore, the person has not login
                notification.add(id, {
                  message: intl.formatMessage(messages.userIsAdded)
                  //   message: intl.formatMessage(messages.userHasUnconfirmedLogin)
                });
              }
              if (error.message === 'person_has_confirmed_login') {
                // 1. The person already exist in the platform
                // 2. The person has a confirmed e-mail address
                // 3. Therefore, the person can login
                notification.add(id, {
                  message: intl.formatMessage(messages.userIsAdded)
                  //   message: intl.formatMessage(messages.userHasConfirmedLogin)
                });
              }
            });
          } else {
            notification.add(id, {
              message: intl.formatMessage(messages.userIsAdded)
            });
          }
          resolve();
          push(
            generatePath(ROUTE_SETTINGS_USERS_USER_EDIT, {
              userId: id
            })
          );
        }
      } else {
        const { data } = await updateUser({
          variables: { ...userObj, id: userId },
          refetchQueries: [
            {
              query: QUERY_GET_ENTITY_BY_ID,
              variables: { id: entityId }
            }
          ]
        });

        if (data) {
          if (data?.editPerson?.id) {
            let updatedLanguage = false;
            if (
              userData.baseLanguage &&
              userData.baseLanguage !== user.getLanguage(entityId)
            ) {
              updatedLanguage = true;
              await updateUserLanguage(
                {
                  baseLanguage: userData.baseLanguage
                },
                [
                  {
                    query: QUERY_GET_USER_BY_ID,
                    variables: { id: userId }
                  }
                ]
              );
            }
            notification.add(data.editPerson.id, {
              message: intl.formatMessage(messages.userIsUpdated)
            });
            const path = generatePath(ROUTE_SETTINGS_USERS_USER, {
              userId: data.editPerson.id
            });
            resolve();
            if (userId === authStore.user.id && updatedLanguage) {
              const url = `${getFullLocation()}${path}`;
              navigateToUrl(url);
            } else {
              push(path);
            }
          }
        }
      }
    });
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(
      intl.formatMessage(messages.firstnameRequired)
    ),
    lastname: Yup.string().required(
      intl.formatMessage(messages.lastnameRequired)
    ),
    email: Yup.string()
      .email(intl.formatMessage(messages.emailNotValid))
      .required(intl.formatMessage(messages.emailRequired))
  });

  return (
    <Panel active={active}>
      {loading && <Loader />}
      {error && <RetryPanel />}

      <>
        <PanelHeader>
          <Breadcrumbs>
            <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
              <FormattedMessage {...messages.breadcrumbSettings} />
            </Breadcrumb>
            <Breadcrumb onClick={() => push(ROUTE_SETTINGS_USERS)}>
              <FormattedMessage {...messages.breadcrumbUsers} />
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {user?.firstname} {user?.lastname}
              {userId && (
                <MenuWrapper trigger={<PanelTitleButton />}>
                  {ability.can('delete', 'Users') && (
                    <RemoveUserButton menu entityId={entityId} userId={userId}>
                      <FormattedMessage {...messages.menuItemUserRemoveUser} />
                    </RemoveUserButton>
                  )}
                </MenuWrapper>
              )}
            </PanelTitle>
            <PanelSubtitle>{user?.email}</PanelSubtitle>
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          <Tabs defaultIndex={tabIndex}>
            <TabList>
              <Tab
                onClick={() =>
                  userId &&
                  push(generatePath(ROUTE_SETTINGS_USERS_USER, { userId }))
                }
              >
                <FormattedMessage {...messages.tabUserGlobal} />
              </Tab>
              <Tab
                disabled={!userId}
                onClick={() =>
                  userId &&
                  push(
                    generatePath(ROUTE_SETTINGS_USERS_USER_ROLES, { userId })
                  )
                }
              >
                <FormattedMessage {...messages.tabUserPermissions} />
              </Tab>
            </TabList>
            <TabPanel>
              <TabPanelBody withScroller>
                <Card secondary>
                  <Formik
                    initialValues={user}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                    validateOnBlur={true}
                    onSubmit={onSubmitHandler}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      resetForm,
                      setFieldValue,
                      isValid,
                      isSubmitting
                    }) => (
                      <Form noValidate>
                        <CardHeader secondary>
                          <CardHeaderTitle>
                            <FormattedMessage
                              {...messages.titleUserPersonalDetails}
                            />
                          </CardHeaderTitle>

                          <CardHeaderButtons>
                            {ability.can('update', 'Users') && (
                              <FormEditHeaderButtons
                                edit={edit}
                                setEdit={setEdit}
                                onSubmit={handleSubmit}
                                cancel={!!userId}
                                disabledSubmit={!isValid || isSubmitting}
                                onCancel={() => {
                                  resetForm();
                                }}
                              />
                            )}
                          </CardHeaderButtons>
                        </CardHeader>
                        <CardBody secondary>
                          <div className="o-layout o-layout--tiny">
                            <div className="o-layout__item u-1-of-2-at-small">
                              <FieldInput
                                id="firstname"
                                name="firstname"
                                errors={errors}
                                touched={touched}
                                readOnly={!edit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <FormattedMessage
                                  {...messages.labelUserFirstname}
                                />
                                *
                              </FieldInput>
                            </div>
                            <div className="o-layout__item u-1-of-2-at-small">
                              <FieldInput
                                id="lastname"
                                name="lastname"
                                errors={errors}
                                touched={touched}
                                readOnly={!edit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <FormattedMessage
                                  {...messages.labelUserLastname}
                                />
                                *
                              </FieldInput>
                            </div>
                          </div>
                          <div className="o-layout o-layout--tiny">
                            <div className="o-layout__item">
                              <LanguageSelect
                                id="language"
                                name="language"
                                readOnly={!edit}
                                label={intl.formatMessage(
                                  messages.labelUserLanguage
                                )}
                                possibleLanguages={entity.possibleLanguages}
                                value={
                                  values.baseLanguage ||
                                  user.getLanguage(entityId)
                                }
                                onSelect={language => {
                                  setFieldValue('baseLanguage', language.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="o-layout o-layout--tiny">
                            <div className="o-layout__item">
                              <FieldInput
                                id="email"
                                name="email"
                                type="email"
                                errors={errors}
                                touched={touched}
                                readOnly={!edit}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              >
                                <FormattedMessage
                                  {...messages.labelUserEmail}
                                />
                                *
                              </FieldInput>
                            </div>
                          </div>

                          {user?.id && ability.can('create', 'Users') && (
                            <div className="o-layout o-layout--tiny">
                              <div className="o-layout__item u-1-of-2-at-large">
                                <InputButtonWrapper
                                  extraClassNamesWrapper="o-flex o-flex--align-center"
                                  id="resetpassword-id"
                                  label={
                                    <FormattedMessage
                                      {...messages.labelUserResetPassword}
                                    />
                                  }
                                >
                                  <ResetLoginButton
                                    entityId={entityId}
                                    person={user}
                                    isUser
                                    target={generatePath(
                                      ROUTE_SETTINGS_USERS_USER,
                                      { userId }
                                    )}
                                  >
                                    <FormattedMessage
                                      {...messages.buttonUserResetPassword}
                                    />
                                  </ResetLoginButton>

                                  {[
                                    PersonStatus.PENDING,
                                    PersonStatus.UNCONFIRMED
                                  ].includes(user.status) && (
                                    <PersonStatusPopper
                                      person={user}
                                      extraClassNames="u-margin-left-small"
                                      onClickStatus={personStatusHandler}
                                    />
                                  )}
                                </InputButtonWrapper>
                              </div>
                              {[
                                PersonStatus.UNCONFIRMED,
                                PersonStatus.USER
                              ].includes(user.status) && (
                                <div className="o-layout__item u-1-of-2-at-large">
                                  <InputButtonWrapper
                                    id="remove-login-id"
                                    label={
                                      <FormattedMessage
                                        {...messages.labelUserRemoveLogin}
                                      />
                                    }
                                  >
                                    <RemoveLoginButton
                                      personId={userId}
                                      isUser
                                      entityId={entityId}
                                      target={generatePath(
                                        ROUTE_SETTINGS_USERS
                                      )}
                                      extraClassNames={'c-button--ellipsed'}
                                      title={intl.formatMessage(
                                        messages.buttonUserRemoveLogin
                                      )}
                                      refetchQueries={[
                                        {
                                          query: QUERY_GET_USERS_BY_ENTITY_ID,
                                          variables: { id: entityId },
                                          fetchPolicy: 'network-only'
                                        },
                                        {
                                          query: QUERY_GET_USER_BY_ID,
                                          variables: {
                                            id: userId
                                          },
                                          fetchPolicy: 'network-only'
                                        }
                                      ]}
                                    >
                                      <FormattedMessage
                                        {...messages.buttonUserRemoveLogin}
                                      />
                                    </RemoveLoginButton>
                                  </InputButtonWrapper>
                                </div>
                              )}
                            </div>
                          )}
                        </CardBody>
                        <FormEditFooterButtons
                          edit={edit}
                          setEdit={setEdit}
                          hasCancel={!!userId}
                          onCancel={() => {
                            resetForm();
                          }}
                          type="submit"
                          disabledSubmit={isSubmitting}
                          topMargin
                        />
                      </Form>
                    )}
                  </Formik>
                </Card>
              </TabPanelBody>
            </TabPanel>
            <TabPanel>
              <TabPanelBody withScroller>
                {personEntity?.person?.entities && (
                  <PermissionsList
                    user={user}
                    personEntities={personEntity.person.entities}
                  />
                )}
              </TabPanelBody>
            </TabPanel>
          </Tabs>
        </PanelBody>
      </>
    </Panel>
  );
};

export default withRouter(User);
