import classNames from 'classnames';

const InputWrapper = props => {
  return (
    <div
      className={classNames(props.extraClassNames, {
        'c-input__group-benchmarks': !props.refactoring,
        'c-input__group-range-date': props.dateRangeCol,
        'r-input-test-item-wrapper': props.refactoring
      })}
      style={props.style}
    >
      {/* // TODO REMOVE after REFACTORING */}
      {props.refactoring &&
        props.label?.trim().length > 0 &&
        !props.trialLabel && (
          <span className={classNames('c-input__label')}>{props.label}</span>
        )}

      {!props.refactoring &&
        props.label?.trim().length > 0 &&
        !props.trialLabel && (
          <span className={classNames('c-input__group-label')}>
            {props.label}
          </span>
        )}

      {props.trialLabel && props.trialLabel}
      {props.tooltip && props.tooltip}
      {props.children}
    </div>
  );
};

export default InputWrapper;
