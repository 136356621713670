import React, { useContext, useEffect, useRef } from 'react';
import { Button } from 'components/button';
import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';
import { Card, CardBody } from 'components/card';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import { useStore } from 'stores/Store';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import TemplateExercisesListSortable from 'containers/pages/exercises/templates/TemplateExercisesListSortable';
import { sortByArray } from 'utils/sort';
import { TemplateType } from 'constants';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import Template from 'models/Template';
import { useClickAway } from '@uidotdev/usehooks';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';

const TrainingExercisesTab = ({ onAddExercises }) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const { entityId, templateId } = useParams();
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);

  const templateFormRef = useRef();
  const defaultSort = useRef([]);

  const training = useStore(state => state.training);
  const setTraining = useStore(state => state.setTraining);

  const selectedTemplateExercises = useStore(
    state => state.selectedTemplateExercises
  );
  const setSelectedTemplateExercises = useStore(
    state => state.setSelectedTemplateExercises
  );
  const selectTemplateExercises = useStore(
    state => state.selectTemplateExercises
  );
  const templateSortableActive = useStore(
    state => state.templateSortableActive
  );
  const setTemplateSortableActive = useStore(
    state => state.setTemplateSortableActive
  );

  const sortableContainerRef = useClickAway(() => {
    if (templateSortableActive) {
      uiState.showModal({
        title: intl.formatMessage(
          messages.exercisesSortExercisesModalWarningTitle
        ),
        message: intl.formatMessage(
          messages.exercisesSortExercisesModalWarningMessage
        ),
        okLabel: intl.formatMessage(messages.saveButton),
        dismissLabel: intl.formatMessage(messages.buttonBack),
        type: MODAL_TYPES.WARNING,
        okHandler: () => {
          templateFormRef.current.submitForm();
        }
      });
    }
  });

  useEffect(() => {
    defaultSort.current = training.exercises.map(exercise => exercise.id);
    setSelectedTemplateExercises([]);
  }, [training]);

  const onClickHandler = exerciseId => {
    push(
      generatePath(
        ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
        {
          entityId,
          templateId,
          exerciseId
        }
      )
    );
  };

  const onCancel = () => {
    setTemplateSortableActive(false);
    setTraining(
      new Template({
        ...training,
        exercises: sortByArray(training.exercises, defaultSort.current)
      })
    );
  };

  return (
    <div
      ref={sortableContainerRef}
      className="o-flex o-flex--column"
      style={{
        flex: 1,
        marginBottom: templateSortableActive ? 72 : 0,
        overflowY: 'auto',
        overflowX: 'visible'
      }}
    >
      {training.exercises.length > 0 ? (
        <TemplateForm
          ref={templateFormRef}
          entityId={entityId}
          template={training}
          type={TemplateType.TRAINING}
          onComplete={() => setTemplateSortableActive(false)}
        >
          <TemplateExercisesListSortable
            exercises={training.exercises}
            checkable={!templateSortableActive}
            showNote={false}
            edit={!templateSortableActive}
            onClick={onClickHandler}
            onCancel={onCancel}
            selectedExercises={selectedTemplateExercises}
            onSelectedExercisesChange={selectTemplateExercises}
          />
        </TemplateForm>
      ) : (
        <Card centered>
          <CardBody empty>
            {ability.can('update', training) ? (
              <Message emptyState={true} icon="browse">
                {training.exercises.length ? (
                  <MessageText>
                    <FormattedMessage {...messages.exercisesListEmptyResult} />
                  </MessageText>
                ) : (
                  <MessageText>
                    <FormattedMessage {...messages.templateEmptyExercises} />
                  </MessageText>
                )}
                <Button
                  primary
                  rounded
                  onClick={() => onAddExercises(training)}
                >
                  <Icon id="add-white" />
                  <FormattedMessage {...messages.exercisesAddExercisesButton} />
                </Button>
              </Message>
            ) : (
              <Message emptyState={true} icon="browse">
                {training.exercises.length ? (
                  <MessageText>
                    <FormattedMessage {...messages.exercisesListEmptyResult} />
                  </MessageText>
                ) : (
                  <MessageText>
                    <FormattedMessage
                      {...messages.templateEmptyExercisesAndCantAdd}
                    />
                  </MessageText>
                )}
              </Message>
            )}
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default TrainingExercisesTab;
