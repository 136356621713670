import classNames from 'classnames';
import { Carousel } from 'components/carousel/Carousel';
import Icon from 'components/icon/Icon';
import { List, ListBody } from 'components/list';
import { ListItem } from 'components/list/list-item';
import { Link } from 'react-router-dom';
import { getLocalDateFormat, getToday } from 'utils/date';

const data = {
  0: {
    date: '25/6/2023',
    done: false
  },
  1: {
    date: '26/6/2023',
    done: true
  },
  2: {
    date: '26/6/2023',
    done: true
  },
  3: {
    date: '27/6/2023',
    done: true
  },
  4: {
    date: '28/6/2023',
    done: false
  },
  5: {
    date: '29/6/2023',
    done: true
  },
  6: {
    date: '30/6/2023',
    done: true
  },
  7: {
    date: '1/7/2023',
    done: true
  },
  8: {
    date: '3/7/2023',
    done: false
  },
  9: {
    date: '4/7/2023',
    done: true
  },
  10: {
    date: '5/7/2023',
    done: true
  },
  11: {
    date: '6/7/2023',
    done: true
  },
  12: {
    date: '7/7/2023',
    done: false
  },
  13: {
    date: '8/7/2023',
    done: true
  },
  14: {
    date: '9/7/2023',
    done: false
  },
  15: {
    date: '10/7/2023',
    done: null
  },
  16: {
    date: '11/7/2023',
    done: null
  },
  17: {
    date: '12/7/2023',
    done: null
  },
  18: {
    date: '13/7/2023',
    done: null
  },
  19: {
    date: '14/7/2023',
    done: null
  },
  20: {
    date: '15/7/2023',
    done: null
  },
  21: {
    date: '16/7/2023',
    done: null
  },
  22: {
    date: '17/7/2023',
    done: null
  },
  23: {
    date: '18/7/2023',
    done: null
  }
};

const RecurringTestDataRow = () => {
  const lastIndex = Object.entries(data).length - 1;
  const indexOfToday = Object.entries(data).findIndex(el => {
    return (
      getLocalDateFormat(el[1]['date'], 'dd/LL/yyyy') ===
      getLocalDateFormat(getToday())
    );
  });

  const getInitialSlideIndex = slidesToShow => {
    let initialSlide = 0;
    if (indexOfToday > lastIndex - (slidesToShow - 1))
      initialSlide = lastIndex - (slidesToShow - 1);
    if (indexOfToday > 3 && !(indexOfToday > lastIndex - (slidesToShow - 1)))
      initialSlide = indexOfToday - (slidesToShow - 1);

    return initialSlide;
  };

  const sliderSettings = {
    slidesToShow: 7,
    initialSlide: getInitialSlideIndex(7),
    slidesToScroll: 4,
    infinite: false,
    dots: false,
    speed: 500,
    // centerPadding: '60px',
    //   slidesToShow: 5,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          initialSlide: getInitialSlideIndex(6)
        }
      },
      {
        breakpoint: 1049,
        settings: {
          slidesToShow: 5,
          initialSlide: getInitialSlideIndex(5)
        }
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 7,
          initialSlide: getInitialSlideIndex(7)
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 5,
          initialSlide: getInitialSlideIndex(5)
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 7,
          initialSlide: getInitialSlideIndex(7)
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 4,
          initialSlide: getInitialSlideIndex(4)
        }
      }
    ]
  };

  return (
    <List subList extraClassNames="c-list--sublist-with-carousel">
      <ListBody>
        <ListItem smallEditableOpen>
          {/* <Table growthPredictionTable>
            <TableHeader>
              <TableRow>
                {Object.entries(data).map(([key, value]) => {
                  return (
                    <TableHeaderCell>
                      Day {Number(key) + 1}
                      <br />
                      {value.date}
                    </TableHeaderCell>
                  );
                })}
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                {Object.entries(data).map(([key, value]) => {
                  return (
                    <TableCell>
                      {value.done === null ? (
                        <Icon id="checkbox-inactive" />
                      ) : value.done ? (
                        <Icon id="validation-check" />
                      ) : (
                        <Icon id="validation-error" />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table> */}
          <Carousel settings={sliderSettings}>
            {Object.entries(data).map(([key, value]) => {
              return (
                <div key={key}>
                  <div
                    className={classNames('c-mini-table', {
                      'c-mini-table--highlight': indexOfToday === Number(key)
                    })}
                  >
                    <div className="c-mini-table__head">
                      Day {Number(key) + 1}
                      <br />
                      {value.date}
                    </div>
                    <div className="c-mini-table__body">
                      {value.done === null ? (
                        <Icon id="checkbox-inactive" />
                      ) : value.done ? (
                        <Link
                          // className="c-button c-button--rounded c-button--grey c-button--icon-expandable"
                          to="#!"
                        >
                          <Icon id="validation-check" />
                        </Link>
                      ) : (
                        <Link
                          // className="c-button c-button--rounded c-button--grey c-button--icon-expandable"
                          to="#!"
                        >
                          <Icon id="validation-error" />
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </ListItem>
      </ListBody>
    </List>
  );
};

export default RecurringTestDataRow;
