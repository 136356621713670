import classNames from 'classnames';

const MenuItemsGroupItem = props => {
  const { children, isActive } = props;
  return (
    <li
      className={classNames('c-menu-group__item', {
        'is-active': isActive
      })}
    >
      {children}
    </li>
  );
};

export default MenuItemsGroupItem;
