/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
const { DEFAULT_LOCALE, NL_TRANSLATION_FILE } = require('./constants');

const enTranslationMessages = require('./translations/compiled/en.json');
const frTranslationMessages = require('./translations/compiled/fr.json');
const esTranslationMessages = require('./translations/compiled/es.json');
const ptTranslationMessages = require('./translations/compiled/pt.json');
const nlTranslationMessages = require(
  `./translations/compiled/${NL_TRANSLATION_FILE}`
);
const nlGSVTranslationMessages = require(`./translations/compiled/nl_gsv.json`);
const nlVitaleTranslationMessages = require(
  `./translations/compiled/nl_vitale.json`
);

export const appLocales = [
  'nl-be',
  'nl-nl',
  'en-be',
  'en-en',
  'en-nl',
  'en-sg',
  'nl-sg',
  'pt-pt',
  'fr-fr',
  'es-es',
  'fi-fi',
  'el-gr',
  'it-it',
  'pl-pl',
  'de-de',
  'ms-my',
];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, nlTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  nl: formatTranslationMessages('nl', nlTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
  es: formatTranslationMessages('es', esTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
  'nl-gsv': formatTranslationMessages('nl', nlGSVTranslationMessages),
  'nl-vitale': formatTranslationMessages('nl', nlVitaleTranslationMessages)
};
