import React, { useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { useMedicalContext } from 'contexts/MedicalContext';
import { MenuWrapper } from 'components/menu';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import RemovePersonIllnessButton from 'containers/partials/buttons/RemoveIllnessButton';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT
} from 'routes/RouteList';
import useTabIndex from 'hooks/utils/useTabIndex';
import { getLocalDateFormat } from 'utils/date';
import PersonIllness from 'models/PersonIllness';
import IllnessDetailCard from 'containers/partials/cards/IllnessDetailCard';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import usePerson from 'hooks/queries/usePerson';

function Illness() {
  const { push } = useHistory();

  const { entityId, groupId, sporterId, illnessId } = useParams();

  const { fetchPerson } = usePerson({ personId: sporterId });
  const person = useEntityContext(s => s.person);
  const { medical } = useMedicalContext();
  const [personIllness, setPersonIllness] = useState(new PersonIllness({}));
  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL
  ]);
  const [tabIndex, setTabIndex] = useTabIndex([
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT
    ]
  ]);
  const edit = useRouteMatch(
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT
  )?.isExact;

  useEffect(() => {
    const fetchPersonData = async () => {
      await fetchPerson(sporterId);
    };
    if(!person.id) {
      fetchPersonData();
    }
  }, [entityId, sporterId]);

  useEffect(() => {
    if (medical?.injuries) {
      const personIllness = medical.illnesses.find(i => i.id === illnessId);
      if (personIllness) {
        setPersonIllness(
          new PersonIllness({
            ...personIllness
          })
        );
      } else {
        push(
          generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL, {
            entityId,
            groupId,
            sporterId
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medical, illnessId]);

  return (
    <Panel active={active.isExact}>
      {person && personIllness && (
        <>
          <PanelHeader>
            <Breadcrumbs>
              <Breadcrumb
                to={generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId })}
              >
                <FormattedMessage {...messages.breadcrumbSporters} />
              </Breadcrumb>
              <Breadcrumb
                to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                  entityId,
                  groupId,
                  sporterId
                })}
              >
                {`${person.firstname} ${person.lastname}`}
              </Breadcrumb>
            </Breadcrumbs>
            <PanelTitleWrapper>
              <PanelTitle>
                {personIllness.title}
                {tabIndex === 0 && (
                  <MenuWrapper trigger={<PanelTitleButton />}>
                    <RemovePersonIllnessButton
                      menu
                      entityId={entityId}
                      groupId={groupId}
                      personId={person.id}
                      personIllnessId={personIllness.id}
                    >
                      <FormattedMessage
                        {...messages.rehabMenuItemRemovePersonIllness}
                      />
                    </RemovePersonIllnessButton>
                  </MenuWrapper>
                )}
              </PanelTitle>
              <PanelSubtitle>
                {getLocalDateFormat(personIllness.injuryDate)}
                {personIllness.injuryEndDate && (
                  <>
                    {' - '}
                    {getLocalDateFormat(personIllness.injuryEndDate)}
                  </>
                )}
              </PanelSubtitle>
            </PanelTitleWrapper>
          </PanelHeader>
          <PanelBody>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={index => setTabIndex(index)}
            >
              <TabList>
                <Tab key="illness.detail">
                  <FormattedMessage {...messages.rehabIllnessDetailsTab} />
                </Tab>
              </TabList>
              <TabPanel key="illness.detail">
                <TabPanelBody withScroller>
                  <IllnessDetailCard
                    entityId={entityId}
                    personIllness={personIllness}
                    edit={edit}
                    medical={medical}
                    sporterId={sporterId}
                  />
                </TabPanelBody>
              </TabPanel>
            </Tabs>
          </PanelBody>
        </>
      )}
    </Panel>
  );
}

export default Illness;
