import { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const HLSSource = ({ src, video, type, isVideoChild }) => {
  const hls = useRef(null);

  useEffect(() => {
    if (hls.current) {
      if (Hls.isSupported()) {
        hls.current.loadSource(src);
        hls.current.attachMedia(video);

        hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
          video.pause();
        });
      }
    }
  }, [hls.current, src, video]);

  useEffect(() => {
    hls.current = new Hls({ maxBufferSize: 5 });
    return () => {
      hls.current.destroy();
    };
  }, []);

  return <source src={src} type={type || 'application/x-mpegURL'} />;
};

export default HLSSource;
