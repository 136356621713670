import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  withRouter
} from 'react-router-dom';
import {
  ROUTE_EXERCISES_COLLECTIONS,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE_EDIT,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTIONS,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_MY_COLLECTIONS
} from 'routes/RouteList';
import { StoreContext } from 'index';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { useIntl } from 'react-intl';
import Collections from 'containers/pages/exercises/collections/Collections';
import Collection from 'containers/pages/exercises/collections/Collection';
import Exercise from 'containers/pages/exercises/exercises/Exercise';

const GSVCollections = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  return (
    <>
      <PreviewModalProvider entityId={user.rootEntityId}>
        <Switch>
          <Route
            path={[
              ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
              ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
              ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTIONS
            ]}
          >
            <Collections />
            <Switch>
              <Route
                path={[
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS,
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION
                ]}
              >
                <Collection />
                <Switch>
                  <Route
                    exact
                    path={[
                      ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE,
                      ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE_EDIT
                    ]}
                  >
                    <Exercise
                      inCollection
                      parentPath={
                        ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE
                      }
                      crumbs={[
                        {
                          path: ROUTE_EXERCISES_COLLECTIONS_ENTITY,
                          label: intl.formatMessage(
                            messages.breadcrumbCollections
                          )
                        },
                        {
                          path: ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
                          label: intl.formatMessage(
                            messages.breadcrumbCollection
                          )
                        }
                      ]}
                    />
                  </Route>
                </Switch>
              </Route>
              <Route path={[ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTIONS]}>
                <Panel
                  emptyState
                  emptyLabel={intl.formatMessage(messages.templateEmptyPanel)}
                />
              </Route>
            </Switch>
          </Route>

          <Route
            path={[ROUTE_EXERCISES_COLLECTIONS]}
            exact
            render={() => (
              <Redirect
                to={generatePath(
                  ROUTE_EXERCISES_COLLECTIONS_ENTITY_MY_COLLECTIONS,
                  {
                    entityId: user.rootEntityId
                  }
                )}
              />
            )}
          />
        </Switch>
      </PreviewModalProvider>
    </>
  );
};

export default withRouter(GSVCollections);
