import classNames from 'classnames';

const RefactoredListItemStatus = props => {
  const { statusIcon } = props;

  return (
    <div className={classNames('r-list__item-icon', {})}>
      {statusIcon && statusIcon !== undefined && statusIcon}
    </div>
  );
};

export default RefactoredListItemStatus;
