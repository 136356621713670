export const athleteFeatures = ['sessions', 'exercises', 'documents'];
export const defaultOrgFeatures = {
  sessions: true,
  rehab: true,
  documents: true,
  athletes: true,
  video: false,
  growthtracker: true,
  growthTracker_v2: false,
  users: true,
  testsets: true,
  personInvite: true,
  exercises: true,
  organisations: true,
  benchmarks: true,
  results: true,
  prevention: true
};

export const gsvRules = [
  {
    subject: 'GSV',
    action: 'manage'
  },
  {
    subject: 'Exercises',
    action: 'manage'
  },
  {
    subject: 'Collections',
    action: 'manage'
  },
  {
    subject: 'Trainings',
    action: 'manage'
  },
  {
    subject: 'Exercise',
    action: 'create'
  },
  {
    subject: 'Exercise',
    action: 'read'
  },
  {
    inverted: true,
    subject: 'Exercise',
    action: 'update',
    conditions: { editable: false }
  },
  {
    inverted: true,
    subject: 'Exercise',
    action: 'delete',
    conditions: { editable: false }
  },
  {
    subject: 'Template',
    action: 'create'
  },
  {
    subject: 'Template',
    action: 'read'
  },
  {
    subject: 'Template',
    action: 'update',
    conditions: { editable: true }
  },
  {
    subject: 'Template',
    action: 'delete',
    conditions: { editable: true }
  },
  {
    subject: 'Collection',
    action: 'create'
  },
  {
    subject: 'Collection',
    action: 'read'
  },
  {
    subject: 'Collection',
    action: 'update',
    conditions: { editable: true }
  },
  {
    subject: 'Collection',
    action: 'delete',
    conditions: { editable: true }
  },
  {
    subject: 'Training',
    action: 'create'
  },
  {
    subject: 'Training',
    action: 'read'
  },
  {
    subject: 'Training',
    action: 'update',
    conditions: { editable: true }
  },
  {
    subject: 'Training',
    action: 'delete',
    conditions: { editable: true }
  },
  {
    subject: 'Sessions',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Rehab',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Prevention',
    action: 'manage',
    inverted: true
  }
];

export const gsvAthleteRules = [
  {
    subject: 'Settings',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Athletes',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Users',
    action: 'manage',
    inverted: true
  }
];

export const VitaleRules = [
  {
    subject: 'Vitale',
    action: 'manage'
  },
  {
    subject: 'Session',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Athletes',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Rehab',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Prevention',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Benchmark',
    action: 'manage',
    inverted: true
  }
];

export const VitaleAthleteRules = [
  {
    subject: 'Videos',
    action: 'manage'
  },
  {
    subject: 'Video',
    action: 'create'
  },

  {
    subject: 'Video',
    action: 'update',
    conditions: { editable: true, finished: false },
    reason: 'Video is already finished'
  },
  {
    subject: 'Video',
    action: 'delete',
    conditions: { editable: true, finished: false },
    reason: 'Video is already finished'
  },
  {
    subject: 'Lessons',
    action: 'manage'
  },
  {
    subject: 'Lesson',
    action: 'create'
  },
  {
    subject: 'Lesson',
    action: 'update',
    conditions: { editable: true }
  },
  {
    subject: 'Lesson',
    action: 'delete',
    conditions: { editable: true }
  }
];

export const VitaleSubAdminRules = [
  {
    subject: 'Videos',
    action: 'manage'
  },
  {
    subject: 'Lessons',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Users',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Settings',
    action: 'manage'
  },
  {
    subject: 'Athletes',
    action: [
      'read',
      'create',
      'update',
      'delete',
      'archive',
      'import',
      'copymove',
      'invite'
    ]
  }
];

export const VitaleAdminRules = [
  {
    subject: 'Videos',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Lessons',
    action: 'manage',
    inverted: true
  },
  {
    subject: 'Video',
    action: 'create',
    inverted: true
  },
  {
    subject: 'Athletes',
    action: [
      'read',
      'create',
      'update',
      'delete',
      'archive',
      'import',
      'copymove',
      'invite'
    ]
  },
  {
    subject: 'Organisation',
    action: 'manage'
  },
  {
    subject: 'Organisations',
    action: 'manage'
  },
  {
    subject: 'Groups',
    action: 'manage'
  },
  {
    subject: 'Settings',
    action: 'manage'
  },
  {
    subject: 'Users',
    action: 'manage'
  }
];
