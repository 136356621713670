import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import CardFooter from 'components/card/CardFooter';
import Button from 'components/button/Button';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';
import useProgramFeedback from 'hooks/queries/useProgramFeedback';
import Icon from 'components/icon/Icon';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES } from 'routes/RouteList';
import Badge from 'components/badge/Badge';

const ExercisePanel = ({ exercises, exerciseId, onClick, exerciseGroup }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const notification = useNotificationQueue();
  const intl = useIntl();
  const { push } = useHistory();
  const { entityId, programId } = useParams();
  const [alreadyDone, setAlreadyDone] = useState(false);

  const [{ done }, { markAsDone }] = useProgramFeedback({
    programId,
    userId: user.id
  });

  useEffect(() => {
    if (done.length > 0) {
      setAlreadyDone(done[0].date_created > new Date().setHours(0, 0, 0, 0));
    } else {
      setAlreadyDone(false);
    }
  }, [done]);

  const onMarkAsDoneHandler = async () => {
    const done = await markAsDone();

    notification.add(done.id, {
      message: intl.formatMessage(messages.programMarkedAsDone)
    });
  };

  const onEditHandler = () => {
    push(
      generatePath(ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES, {
        entityId,
        programId
      })
    );
  };

  return (
    <>
      {exercises.length > 0 ? (
        <>
          <ExercisesListVirtualized
            entityId={entityId}
            activeId={exerciseId}
            onClick={onClick}
            visible={false}
            checkable={false}
            exercises={exercises}
            exerciseGroup={exerciseGroup}
            enablePreviewModal={false}
            showNote
            withFixedFooter
          />
          <CardFooter secondary extraClassNames={'c-card__footer-bottom-fixed'}>
            <p className="c-message-highlight">
              <FormattedMessage
                {...messages.programLabelTimesDone}
                values={{
                  timesDone: done.length,
                  b: chunks => <b>{chunks}</b>
                }}
              />
            </p>
            {!alreadyDone ? (
              <Button primary type={'button'} onClick={onMarkAsDoneHandler}>
                <FormattedMessage {...messages.markAsDone} />
              </Button>
            ) : (
              <Button primary type={'button'} disabled>
                <FormattedMessage {...messages.doneToday} />
              </Button>
            )}
          </CardFooter>
        </>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="browse">
              <MessageText>
                <FormattedMessage {...messages.templateEmptyExercises} />
              </MessageText>
            </Message>
            <Button primary rounded onClick={() => onEditHandler()}>
              <Icon id="add-white" />
              <FormattedMessage {...messages.exercisesAddExercisesButton} />
            </Button>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ExercisePanel;
