import React, { useState } from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import SessionForm from './SessionForm';
import PreventionDetail from './PreventionDetail';
import PreventionDetailExtras from './PreventionDetailExtras';
import { TestsProvider } from 'contexts/TestsContext';
import Loader from 'components/loader/Loader';

function AddPreventionModal({
  entityId,
  onClose,
  onComplete,
  session,
  isTemplate
}) {
  const [edit, setEdit] = useState(true);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onCompleteHandler = id => {
    if (onComplete) onComplete(id);
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <TestsProvider entityId={entityId}>
        <SessionForm
          entityId={entityId}
          session={session}
          onComplete={onCompleteHandler}
        >
          {({ isSubmitting }) => (
            <>
              {isSubmitting && <Loader />}
              <CardHeader modal>
                <CardHeaderTitle>
                  {session.preventionType ? (
                    <FormattedMessage
                      {...messages.preventionModalAddTitle}
                      values={{
                        type: session.preventionType,
                        sessionExists: Boolean(session.name),
                        isTemplate,
                        session: session.name,
                        span: chunks => (
                          <span className="c-card__header-highlight">
                            {chunks}
                          </span>
                        )
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      {...messages.preventionModalAddNewTitle}
                    />
                  )}
                </CardHeaderTitle>
              </CardHeader>
              <CardBody modalWithOverflow>
                <PreventionDetail edit={edit} onEdit={() => setEdit(true)} />
              </CardBody>

              <CardBody modalWithOverflow>
                <PreventionDetailExtras
                  entityId={entityId}
                  edit={session.name ? !Boolean(session.name) : edit}
                  onEdit={() => setEdit(true)}
                />
              </CardBody>
              <CardFooter modal extraClassNames="c-card__footer--modal-larger">
                <ButtonsGroup>
                  <Button secondary onClick={() => onCloseHandler()}>
                    <FormattedMessage {...messages.cancelButton} />
                  </Button>
                  <Button type="submit" primary disabled={isSubmitting}>
                    <FormattedMessage {...messages.saveButton} />
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </>
          )}
        </SessionForm>
      </TestsProvider>
    </Modal>
  );
}

export default AddPreventionModal;
