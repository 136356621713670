import { useContext, useState } from 'react';
import {
  generatePath,
  Link,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Panel from 'components/panel/Panel';
import {
  ROUTE_EXERCISES,
  ROUTE_EXERCISES_ENTITY_ALL_EXERCISES,
  ROUTE_EXERCISES_ENTITY_EXERCISE,
  ROUTE_EXERCISES_ENTITY_EXERCISES,
  ROUTE_EXERCISES_ENTITY_MY_EXERCISES
} from 'routes/RouteList';
import messages from 'messages';
import {
  PanelBody,
  PanelSubtitle,
  PanelTitleButtonsGroup
} from 'components/panel';
import { PanelTitle, PanelTitleButton } from 'components/panel/panel-title';
import { StoreContext } from 'index';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import { MenuWrapper } from 'components/menu';
import AddExercisesToCollectionModal from 'containers/pages/exercises/collections/AddExercisesToCollectionModal';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import AddExerciseModal from 'containers/pages/exercises/exercises/AddExerciseModal';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import ExercisesPanel from 'containers/pages/exercises/exercises/ExercisesPanel';
import ExercisesForAthletePanel from 'containers/pages/exercises/exercises/ExercisesForAthletePanel';
import { useStore } from 'stores/Store';
import useTabIndex from 'hooks/utils/useTabIndex';
import AddExercisesToCollectionButton from 'containers/partials/buttons/AddExercisesToCollectionButton';
import AddButton from 'containers/partials/buttons/AddButton';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';

const Exercises = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { entityId, exerciseId } = useParams();
  const ability = useAbility(AbilityContext);
  const { push } = useHistory();
  const selectedExercises = useStore(state => state.selectedExercises);
  const active = useRouteMatch([ROUTE_EXERCISES_ENTITY_EXERCISES]);

  const crumbs = [
    {
      path: generatePath(ROUTE_EXERCISES, {
        entityId: user.rootEntityId
      }),
      label: intl.formatMessage(messages.breadcrumbOverview)
    }
  ];
  const { setModalData } = usePreviewModalContext();
  const [addModalData, setAddModalData] = useState(null);
  const [addToCollectionModal, setAddToCollectionModal] = useState(null);
  const exercisesTabs = [
    ROUTE_EXERCISES_ENTITY_ALL_EXERCISES,
    ROUTE_EXERCISES_ENTITY_MY_EXERCISES
  ];
  const [tabIndex, setTabIndex] = useTabIndex(exercisesTabs, false);

  return (
    <>
      <Panel active={active?.isExact}>
        <RefactoredPanelHeader>
          <RefactoredPanelTitleWrapper>
            <BreadcrumbPath crumbs={crumbs} />
            <PanelTitle>
              <FormattedMessage {...messages.exercisesTitle} />
            </PanelTitle>
            <PanelSubtitle>
              {user.isAthlete() && user.isGSVOrg && tabIndex === 1 ? (
                <FormattedMessage {...messages.exerciseSubtitleMine} />
              ) : (
                <FormattedMessage {...messages.exerciseSubtitleAll} />
              )}
            </PanelSubtitle>
          </RefactoredPanelTitleWrapper>
          <PanelTitleButtonsGroup>
            {ability.can('create', 'Exercise') && (
              <AddButton
                expandable
                label={intl.formatMessage(messages.exercisesAddExerciseButton)}
                onClick={() => setAddModalData(true)}
              />
            )}
            <MenuWrapper trigger={<PanelTitleButton />}>
              <AddExercisesToCollectionButton
                onAdd={() => setAddToCollectionModal(true)}
              />
              {/*
              TODO: maarten add selected exercises to program
              // mss ook voor training, in GSV?
              */}
              {/*
              <Button
                menu
                onClick={() => {}}
                disabled={selectedExercises.length <= 0}
              >
                {selectedExercises.length > 0 ? (
                  <>
                    <Icon
                      id={user.isGSVOrg ? 'bookmark-outline' : 'program'}
                      strokeColor="color-neutral-dark"
                      style={{ top: 'auto' }}
                    />

                    <FormattedMessage
                      {...messages.collectionAddExercisesToTemplateButton}
                      values={{
                        thisMany: selectedExercises.length,
                        type: user.isGSVOrg ? 'training' : 'program'
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Icon id="add" style={{ top: 'auto', opacity: 0.35 }} />

                    <FormattedMessage
                      {...messages.collectionAddExercisesToTemplateDisabled}
                      values={{
                        type: user.isGSVOrg ? 'training' : 'program'
                      }}
                    />
                  </>
                )}
              </Button> */}
            </MenuWrapper>
          </PanelTitleButtonsGroup>
        </RefactoredPanelHeader>

        <PanelBody>
          <Tabs
            marginNegativeTop={4}
            selectedIndex={tabIndex}
            onSelect={index => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <Link
                  to={generatePath(ROUTE_EXERCISES_ENTITY_ALL_EXERCISES, {
                    entityId
                  })}
                >
                  <FormattedMessage {...messages.exercisesTabAllExercises} />
                </Link>
              </Tab>
              {user.isAthlete() && user.isGSVOrg && (
                <Tab>
                  <Link
                    to={generatePath(ROUTE_EXERCISES_ENTITY_MY_EXERCISES, {
                      entityId
                    })}
                  >
                    <FormattedMessage {...messages.exercisesTabMyExercises} />
                  </Link>
                </Tab>
              )}
            </TabList>
            <TabPanel className="react-tabs__tab-panel u-padding-vertical-none">
              <TabPanelBody withHeader>
                <ExercisesPanel
                  entityId={entityId}
                  exerciseId={exerciseId}
                  onPreview={exercise =>
                    setModalData({ type: 'exercise', data: exercise })
                  }
                  onClick={id => {
                    if (id !== exerciseId) {
                      push(
                        generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                          entityId,
                          tab: 'all',
                          exerciseId: id
                        })
                      );
                    }
                  }}
                />
              </TabPanelBody>
            </TabPanel>
            {user.isAthlete() && (
              <TabPanel className="react-tabs__tab-panel u-padding-vertical-none">
                <TabPanelBody>
                  <ExercisesForAthletePanel
                    entityId={entityId}
                    activeId={exerciseId}
                    onPreview={exercise =>
                      setModalData({ type: 'exercise', data: exercise })
                    }
                    onClick={id => {
                      if (id !== exerciseId) {
                        push(
                          generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE, {
                            entityId,
                            tab: 'my',
                            exerciseId: id
                          })
                        );
                      }
                    }}
                  />
                </TabPanelBody>
              </TabPanel>
            )}
          </Tabs>
        </PanelBody>
      </Panel>
      {addToCollectionModal && (
        <AddExercisesToCollectionModal
          entityId={entityId}
          exercises={selectedExercises}
          onAdded={() => setAddToCollectionModal(false)}
          onClose={() => {
            setAddToCollectionModal(false);
          }}
          collectionType={user.isGSVOrg ? 'Collection' : 'Template'}
        />
      )}
      {addModalData && (
        <AddExerciseModal
          entityId={entityId}
          onClose={() => setAddModalData(false)}
        />
      )}
    </>
  );
};

export default Exercises;
