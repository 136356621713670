import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import RemoveSessionTemplateButton from '../buttons/RemoveSessionTemplateButton';
import { useIntl } from 'react-intl';
import messages from 'messages';
import { highlightSearchedText } from 'utils/search';

export const SessionTemplateListItem = ({
  entityId,
  active,
  template,
  onClick,
  id,
  checkbox,
  onChange,
  checked,
  visible,
  noMaxHeight,
  onEdit,
  onUse,
  outfaded,
  disabled,
  filtered,
  filteringTerm,
  statusIconListItem,
  mediumIcon
}) => {
  const intl = useIntl();
  return (
    <ListItem
      active={active}
      checkbox={checkbox}
      onClick={typeof filtered === 'undefined' || filtered ? onClick : () => {}}
      refactoring
      noMaxHeight={noMaxHeight}
      disabled={disabled}
      outfaded={outfaded}
    >
      <ListItemStatus
        statusIcon={statusIconListItem ? statusIconListItem : 'group'}
        mediumIcon={mediumIcon}
        visible={visible}
        onChange={onChange}
        checked={checked}
        itemId={id}
      />
      <ListItemLabelWrapper>
        <ListItemLabel label>
          {highlightSearchedText(template.title, filteringTerm ?? '', {
            className: 'c-list__item-title c-list__item-title-ellipsed',
            title: template.title
          })}
        </ListItemLabel>
        <ListItemLabel info></ListItemLabel>
        <div className="c-list__item-small-editing-area">
          <ButtonsGroup>
            <Button
              tiny
              onClick={() => onUse(id)}
              ariaLabel="Use this template to create a new Session"
              title="Use this template to create a new Session"
            >
              <Icon
                id="use-template"
                smallMediumIcon
                strokeColor="color-neutral-dark"
              />
            </Button>
            <Button
              tiny
              onClick={() => onEdit(id)}
              ariaLabel={intl.formatMessage(
                messages.exercisesEditTemplateButton
              )}
              title={intl.formatMessage(messages.exercisesEditTemplateButton)}
            >
              <Icon id="edit" strokeColor="color-neutral-dark" />
            </Button>
            <RemoveSessionTemplateButton
              tiny
              testSetId={id}
              entityId={entityId}
              ariaLabel={intl.formatMessage(
                messages.exercisesRemoveTemplateButton
              )}
              title={intl.formatMessage(messages.exercisesRemoveTemplateButton)}
            />
          </ButtonsGroup>
        </div>
      </ListItemLabelWrapper>
    </ListItem>
  );
};
