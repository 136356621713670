import React, { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import messages from 'messages';
import { BenchmarkSubTypes } from 'constants.js';
import StandardDataTable from 'containers/pages/settings/benchmarks/StandardDataTable';
import RangeDataTable from 'containers/pages/settings/benchmarks/RangeDataTable';
import { ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST } from 'routes/RouteList';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import {
  MUTATION_EDIT_BENCHMARK_TEST_ITEM,
  QUERY_GET_BENCHMARK_DATA
} from 'services/aws/benchmark-query';

export const BenchmarkDataKeys = Object.freeze({
  RANGE: ['age_min', 'age_max', 'm1', 'm2', 'm3', 'm4', 'f1', 'f2', 'f3', 'f4'],
  STANDARD: ['age_min', 'age_max', 'm_av', 'm_sd', 'f_av', 'f_sd']
});

function BenchmarkDataTable({
  benchmarkId,
  benchmarkTestId,
  type,
  edit,
  loading,
  ...props
}) {
  const { authStore } = useContext(StoreContext);
  const { push } = useHistory();
  const intl = useIntl();
  const notification = useNotificationQueue();

  const [editBenchmarkTestItem] = useMutation(
    MUTATION_EDIT_BENCHMARK_TEST_ITEM,
    {
      refetchQueries: [
        {
          query: QUERY_GET_BENCHMARK_DATA,
          variables: {
            benchmarkId,
            entityId: authStore.user.rootEntityId
          }
        }
      ]
    }
  );

  const onSubmit = async jsonToSave => {
    await editBenchmarkTestItem({
      variables: {
        benchmarkId,
        testItemId: benchmarkTestId,
        data: JSON.stringify(jsonToSave),
        type
      }
    }).then(({ data }) => {
      notification.add(data.addEditBenchmarkItem.id, {
        message: intl.formatMessage(messages.benchmarkSaved)
      });
      push(
        generatePath(ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST, {
          benchmarkId,
          benchmarkTestId
        })
      );
    });
  };

  const onCancel = () => {
    push(
      generatePath(ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST, {
        benchmarkId,
        benchmarkTestId
      })
    );
  };

  return (
    <>
      {props.dataTable && (
        <BenchmarkTable
          type={type}
          edit={edit}
          loading={loading}
          onSubmit={onSubmit}
          onCancel={onCancel}
          {...props}
        />
      )}
    </>
  );
}

const BenchmarkTable = ({ type, ...props }) => {
  switch (type) {
    case BenchmarkSubTypes.STANDARD:
      return (
        <StandardDataTable dataKeys={BenchmarkDataKeys.STANDARD} {...props} />
      );
    case BenchmarkSubTypes.RANGE:
      return <RangeDataTable dataKeys={BenchmarkDataKeys.RANGE} {...props} />;
    default:
      return <></>;
  }
};

export default BenchmarkDataTable;
