import { useState } from 'react';
import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import { usePopper } from 'react-popper';
import { Portal } from 'components/Portal/Portal';
import { useClickAway } from '@uidotdev/usehooks';

const TooltipPopper = props => {
  const {
    id,
    children,
    extraClassNames,
    icon,
    disabled,
    info,
    mediumIcon,
    graphic,
    video,
    videoMessage,
    label,
    labelObject,
    stylesLabel,
    inheritedStyles,
    onClick,
    openOnClick,
    iconStrokeColor,
    alert,
    iconFillColor,
    alertFill,
    tabIndex,
    inlineText,
    onMouseLeave,
    onBlur,
    onMouseEnter,
    onFocus
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();

  const elRef = useClickAway(e => {
    !popperElement?.contains(e.target) && setIsOpen(false);
    !popperElement?.contains(e.target) && setIsActive(false);
  });

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      },
      {
        name: 'flip',
        options: {
          flipVariations: false,
          padding: { bottom: 24, top: 24 },
          fallbackPlacements: ['top-end']
        }
      }
    ]
  });

  const handleOnEvent = (e, event) => {
    if (event) {
      event();

      if (e._reactName === 'onClick') {
        setIsOpen(!isOpen);
        setIsActive(!isOpen);
      }

      if (e._reactName === 'onMouseEnter' || e._reactName === 'onFocus') {
        setIsOpen(true);
      }

      if (e._reactName === 'onMouseLeave' || e._reactName === 'onBlur') {
        setIsOpen(false);
      }
    }
  };

  return (
    <div
      className={classNames('c-tooltip c-tooltip--popper', extraClassNames, {
        'c-tooltip--alert': alert,
        'c-tooltip--info': info,
        'c-tooltip--video': video,
        'c-tooltip--label': label?.length > 0,
        'c-tooltip--inline-text': inlineText,
        'c-tooltip--inherited-styles': inheritedStyles,
        'c-tooltip--inherited-styles-active': isActive
      })}
    >
      <button
        className={classNames('c-button', {
          'c-button--tiny c-tooltip__trigger': !inheritedStyles,
          'c-button--disabled': props.disabled || props.pseudoDisabled // ok
        })}
        type={'button'}
        aria-labelledby={id?.length && !video ? id : `tooltip-label-${id}`}
        onClick={
          onClick && !openOnClick
            ? onClick
            : onClick && openOnClick
              ? e => handleOnEvent(e, onClick)
              : false
        }
        ref={
          openOnClick
            ? el => {
                elRef.current = el;
                setReferenceElement(el);
              }
            : setReferenceElement
        }
        onMouseEnter={e => {
          onMouseEnter && handleOnEvent(e, onMouseEnter);
        }}
        onFocus={e => {
          onFocus && handleOnEvent(e, onFocus);
        }}
        onMouseLeave={e => {
          onMouseLeave && handleOnEvent(e, onMouseLeave);
        }}
        onBlur={e => {
          onBlur && handleOnEvent(e, onBlur);
        }}
        tabIndex={tabIndex}
        disabled={disabled}
      >
        {icon && (
          <Icon
            id={icon}
            mediumIcon={mediumIcon}
            strokeColor={
              iconStrokeColor ? iconStrokeColor : alert ? 'color-error' : false
            }
            fillColor={
              iconFillColor ? iconFillColor : alertFill ? 'color-error' : false
            }
          />
        )}
        {label && (
          <span id={`tooltip-label-${id}`} style={stylesLabel}>
            {label}
          </span>
        )}
        {labelObject && labelObject}
      </button>
      {isOpen && (
        <Portal>
          <>
            {video && (
              <div
                className={classNames(
                  'c-tooltip__message c-tooltip-popper__message-video',
                  {
                    'c-tooltip-popper__message--hidden': !isOpen
                  }
                )}
                role="tooltip"
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {children}
                {videoMessage && (
                  <section className="c-tooltip__info">{videoMessage}</section>
                )}
              </div>
            )}
            {!video &&
              (graphic ? (
                <div
                  id={id}
                  role="tooltip"
                  className={classNames(
                    'c-tooltip__message c-tooltip-popper__message',
                    {
                      'c-tooltip-popper__message--hidden': !isOpen,
                      'c-tooltip-popper__message-graphic': graphic
                    }
                  )}
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  {children}
                </div>
              ) : (
                <p
                  id={id}
                  role="tooltip"
                  className={classNames(
                    'c-tooltip__message c-tooltip-popper__message',
                    {
                      'c-tooltip-popper__message--hidden': !isOpen
                    }
                  )}
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <span>{children}</span>
                </p>
              ))}
          </>
        </Portal>
      )}
    </div>
  );
};

export default TooltipPopper;
