import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_ADD_MIRROR,
  QUERY_GET_MIRROR
} from 'services/aws/mirror-query';

function useMirror() {
  const [getMirrorIntegration] = useLazyQuery(QUERY_GET_MIRROR);

  const [addMirror] = useMutation(MUTATION_ADD_MIRROR);

  const getMirror = async ({ personId, entityId }) => {
    const { data } = await getMirrorIntegration({
      variables: { personId, entityId },
      fetchPolicy: 'network-only'
    });

    if (data?.getMirrorIntegration) {
      const mirrorData = JSON.parse(data.getMirrorIntegration);
      if (mirrorData?.magiclink) {
        return mirrorData.magiclink;
      }
      return false;
    }
  };

  return { getMirror, addMirror };
}

export default useMirror;
