import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import { getLocalDateFormat } from 'utils/date';

export const SessionListItemUser = ({
  active,
  session,
  onClick,
  cta,
  id,
  checkbox,
  onChange,
  checked,
  visible,
  noMaxHeight
}) => (
  <ListItem
    noMaxHeight={noMaxHeight}
    checkbox={checkbox}
    active={active}
    done={!session.recurring}
    outfaded={
      (session.recurring && session.enddate < new Date()) || !session.filtered
    }
    onClick={onClick}
    cta={!!cta}
    withSupertitle
  >
    <ListItemStatus
      visible={visible}
      onChange={onChange}
      checked={checked}
      itemId={id}
      statusIcon={
        !session.filtered || session.recurring ? null : 'validation-check'
      }
      mediumIcon
    >
      {session.recurring && session.interval.charAt(0)}
    </ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel
        supertitle={
          session.recurring ? session.interval : false
          // : session.isRehab
          // ? 'Rehab'
          // : session.type
        }
        label
      >
        {session.name}
      </ListItemLabel>
      <ListItemLabel info>
        {session.recurring ? (
          <>
            {getLocalDateFormat(session.startdate)}
            {session.enddate && (
              <>{` - ${getLocalDateFormat(session.enddate)}`}</>
            )}
          </>
        ) : (
          <>{getLocalDateFormat(session.startdate)}</>
        )}
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
