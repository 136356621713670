import { useRef, useState } from 'react';
import Exercise from 'models/Exercise';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_EXERCISES } from 'services/aws/exercises-query';
import { useStore } from 'stores/Store';

const PAGE_SIZE = 25;

const useExercises = entityId => {
  const exercises = useStore(state => state.exercises);
  const setExercises = useStore(state => state.setExercises);
  const setLoading = useStore(state => state.setLoadingExercises);
  const exercisePageToken = useStore(state => state.exercisePageToken);
  const setExercisePageToken = useStore(state => state.setExercisePageToken);
  const [getExercises, { loading, error, fetchMore }] =
    useLazyQuery(QUERY_GET_EXERCISES);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const loadingExercisesSate = useRef(null);

  const getVariables = ({ entityId, filter, limit }) => {
    const variables = {
      entityId,
      inheritFromParent: true,
      titleContains: '',
      tags: []
    };
    if (filter) {
      if (filter.search) {
        variables.titleContains = filter.search;
      }
      if (filter.tags?.length > 0) {
        variables.tags = filter.tags.map(tag => tag.id);
      }
      variables.inheritFromParent = !filter.myOrg;
    }
    if (limit) {
      variables.limit = limit;
    }
    if (loadingExercisesSate.current === null && !limit) {
      variables.limit = PAGE_SIZE;
    }

    return variables;
  };

  const fetchExercises = async options => {
    if (exercises.length === 0) {
      setLoading(true);
    }

    const { data } = await getExercises({
      variables: getVariables({ entityId, filter: { ...options } }),
      fetchPolicy: 'network-only',
      skip: !entityId
    });

    if (data?.getExercises) {
      const exercisesData = data.getExercises;
      exercisesData.map(exercise => new Exercise({ ...exercise }));
      const nextToken = exercisesData[exercisesData.length - 1]?.nextToken;
      setExercisePageToken(nextToken);
      setHasNextPage(!!nextToken);
      setExercises(exercisesData);
      setLoading(false);
      setIsFetchingNextPage(false);

      return exercisesData;
    }
  };

  const filterExercises = async options => {
    setLoading(true);
    return await fetchExercises(options);
  };

  const fetchMoreExercises = async options => {
    if (loading || !hasNextPage || isFetchingNextPage) {
      return;
    }

    setIsFetchingNextPage(true);

    try {
      if (!exercisePageToken) {
        setHasNextPage(false);
        setIsFetchingNextPage(false);
        return;
      }

      const { data: fetchMoreData } = await fetchMore({
        variables: {
          ...getVariables({ entityId, ...options }),
          nextToken: exercisePageToken
        }
      });

      if (fetchMoreData?.getExercises) {
        const exercisesData = fetchMoreData.getExercises;
        const nextToken = exercisesData[exercisesData.length - 1]?.nextToken;
        setExercisePageToken(nextToken);

        exercisesData.map(exercise => new Exercise({ ...exercise }));

        setExercises([...exercises, ...exercisesData]);
        setHasNextPage(!!nextToken);

        setIsFetchingNextPage(false);
      }
    } catch (error) {
      console.error('Error fetching more exercises:', error.message);
    }
  };

  return {
    loading,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchExercises,
    filterExercises,
    fetchMoreExercises
  };
};

export default useExercises;
