import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { PersonStatus } from 'constants.js';
import TooltipPopper from 'components/tooltip/TooltipPopper';

function PersonStatusPopper({ person, onClickStatus, extraClassNames = null }) {
  let icon;
  let label = {};
  // eslint-disable-next-line default-case
  switch (person.status) {
    case PersonStatus.EMAIL:
      icon = 'email';
      label = messages.userWithEmail;
      break;
    case PersonStatus.USER:
      icon = 'sporters';
      label = messages.userWithLogin;
      break;
    case PersonStatus.PENDING:
      icon = 'pending';
      label = messages.userPending;
      break;
    case PersonStatus.UNCONFIRMED:
      icon = 'alert-round';
      label = messages.emailIsNotConfirmed;
      break;
  }
  return (
    <TooltipPopper
      info
      extraClassNames={extraClassNames}
      id={`popper_tooltip_${person.id}`}
      icon={icon}
      alertFill={person.status === PersonStatus.UNCONFIRMED}
      onMouseEnter={() => true}
      onFocus={() => true}
      onMouseLeave={() => false}
      onBlur={() => false}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();

        onClickStatus && onClickStatus(person);
      }}
    >
      <>
        <FormattedMessage
          {...label}
          values={{
            b: chunks => <b>{chunks}</b>,
            br: <br />
          }}
        />
      </>
      {/* )} */}
    </TooltipPopper>
  );
}

export default PersonStatusPopper;
