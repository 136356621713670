import { useEffect, useState } from 'react';
import {
  QUERY_GET_INPUT_DATA_OF_SPORTER,
  removeDataPoint
} from 'services/aws/growthtracker-query';
import { useLazyQuery } from '@apollo/client';
import { idToNameTestItem } from 'constants.js';

const usePersonInputData = () => {
  const [personInputData, setPersonInputData] = useState(null);
  const [fetchInputDataOfSporter, { loading, error, data, refetch }] =
    useLazyQuery(QUERY_GET_INPUT_DATA_OF_SPORTER);

  useEffect(() => {
    if (data?.getInputData) {
      parseData(data.getInputData);
    }
  }, [data]);

  const parseData = data => {
    const bundled = data.reduce((acc, obj) => {
      if (!acc[obj.testDateTime]) {
        acc[obj.testDateTime] = {};
      }
      acc[obj.testDateTime][obj.testItemId] = {
        id: obj.id,
        testName: idToNameTestItem[obj.testItemId],
        testItemId: obj.testItemId,
        values: obj.values.replaceAll(/[[\]]/gi, '')
      };
      return acc;
    }, {});

    setPersonInputData(bundled);

    return bundled;
  };

  const fetchInputData = async ({ personId }) => {
    const { data } = await fetchInputDataOfSporter({
      variables: {
        personId
      },
      fetchPolicy: 'cache-and-network'
    });

    if (data?.getInputData) {
      return parseData(data.getInputData);
    }
  };

  const deleteDataPoint = async id => {
    return new Promise(async resolve => {
      const result = await removeDataPoint(id);
      if (result) {
        resolve(true);
      }
    });
  };

  return {
    fetchInputData,
    deleteDataPoint,
    personInputData,
    loading,
    error,
    refetch
  };
};

export default usePersonInputData;
