import Icon from 'components/icon/Icon';
import Button from './Button';
import { useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';

const ButtonExpandable = props => {
  const {
    tabIndex = 0,
    label,
    onClick,
    children,
    icon,
    extraClassNames,
    ...rest
  } = props;

  const [labelWidth, setLabelWidth] = useState(32);
  const [hoverOn, setHoverOn] = useState(false);
  const labelRef = useRef();

  useLayoutEffect(() => {
    if (!!labelRef?.current) {
      children
        ? setLabelWidth(
            Math.ceil(labelRef?.current?.getBoundingClientRect().width) + 64
          )
        : setLabelWidth(
            Math.ceil(labelRef?.current?.getBoundingClientRect().width) + 48
          );
    }
  }, [tabIndex]);

  const handleClick = e => {
    e.stopPropagation();

    if (onClick) onClick(e);
  };

  return (
    <div
      className={classNames('c-button-expandable', extraClassNames)}
      style={{
        width: hoverOn ? labelWidth : 32
      }}
    >
      <Button
        extraClassNames={'c-button--icon-expandable'}
        rounded
        primary
        onClick={e => handleClick(e)}
        onMouseEnter={() => setHoverOn(true)}
        onFocus={() => setHoverOn(true)}
        onMouseLeave={() => setHoverOn(false)}
        onBlur={() => setHoverOn(false)}
      >
        {icon ? icon : <Icon id="add-white" />}
        <span className="c-button__label" ref={labelRef}>
          {label}
        </span>
        {children}
      </Button>
    </div>
  );
};

export default ButtonExpandable;
