import { Fragment, useRef } from 'react';
import classNames from 'classnames';

const AccordionButton = props => {
  const ref = useRef();

  return !props.customClickableElement ? (
    <Fragment>
      <input
        className={classNames('c-accordion__button', props.extraClassNames)}
        type={props.selfClosing ? 'checkbox' : 'radio'}
        name={props.name}
        id={props.id}
        data-qa={props.dataQa}
      />
      <label
        className={classNames('c-accordion__label', props.extraClassNames, {
          'c-accordion__label-empty': props.selectableButton,
          'c-accordion__label-selfclosing': props.selfClosing
        })}
        htmlFor={props.id}
      >
        {!props.selectableButton && !props.customClickableElement && (
          <span>{props.children}</span>
        )}
      </label>

      {props.selectableButton && (
        <button
          disabled={props.disabled}
          className={classNames('c-button c-button--menu c-accordion__label', {
            'c-button--disabled': props.disabled
          })}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      )}
    </Fragment>
  ) : (
    <Fragment>
      {props.children}
      <input
        ref={ref}
        className={classNames(
          'c-accordion__button c-accordion__button-custom',
          props.extraClassNames
        )}
        type="checkbox"
        onChange={e => {
          props.onOpenClose &&
            props.onOpenClose(e.target.checked, () => {
              if (ref?.current) {
                ref.current.checked = e.target.checked;
              }
            });
        }}
        checked={props.isOpen ?? null}
        name={props.name}
        id={props.id}
        data-qa={props.dataQa}
      />
      <label
        className={classNames(
          'c-accordion__label c-accordion__label-custom',
          props.extraClassNames
        )}
        htmlFor={props.id}
      >
        {props.openLabel}
      </label>
    </Fragment>
  );
};

export default AccordionButton;
