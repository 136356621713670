import { useEffect, useState } from 'react';
import { CardBodyTools } from 'components/card';
import Loader from 'components/loader/Loader';
import VirtualizedEntityList from 'containers/pages/persons/VirtualizedEntityList';
import useGroups from 'hooks/queries/useGroups';
import GroupsBreadCrumbs from 'containers/partials/misc/GroupsBreadCrumbs';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const SelectGroupsCard = ({ entityId, excludedGroups, onModal = false }) => {
  const { fetchGroups, loading } = useGroups({
    entityId
  });
  const [activeGroup, setActiveGroup] = useState();

  const group = useEntityContext(s => s.group);
  const groups = useEntityContext(s => s.groups);
  const setExcludedGroups = useEntityContext(s => s.setExcludedGroups);
  const clearSelectedGroups = useEntityContext(s => s.clearSelectedGroups);

  useEffect(() => {
    return () => {
      clearSelectedGroups();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearSelectedGroups([]);
    setExcludedGroups(excludedGroups);
    fetchGroups(entityId);
  }, [entityId]);

  useEffect(() => {
    if (group?.id) {
      setActiveGroup(group);
    }
  }, [group]);

  const onBreadcrumbClickedHandler = async itemId => {
    await fetchGroups(itemId);
  };

  const onGroupClickedHandler = async item => {
    await fetchGroups(item.id);
  };

  if (loading || !activeGroup) return <Loader />;

  return (
    <>
      {activeGroup.id !== entityId && (
        <CardBodyTools
          style={{
            flexWrap: 'wrap',
            paddingTop: activeGroup.id !== entityId ? 0 : 12
          }}
        >
          <GroupsBreadCrumbs
            entityId={entityId}
            groupId={activeGroup.id}
            onClick={onBreadcrumbClickedHandler}
          />
        </CardBodyTools>
      )}
      <VirtualizedEntityList
        id={'groups'}
        onModal={onModal}
        activeId={activeGroup.id}
        items={[...groups]}
        checkable
        checkableGroups
        showOnlyGroups
        onClick={(item, type) => {
          if (type === 'group') {
            onGroupClickedHandler(item);
          }
        }}
      />
    </>
  );
};

export default SelectGroupsCard;
