import classNames from 'classnames';

const PanelTitle = props => {
  const { children, extraClassNames, noSubtitle, ...rest } = props;
  return (
    <h2
      className={classNames('c-panel__title', extraClassNames, {
        'u-margin-bottom-none u-padding-right-small': noSubtitle
      })}
    >
      {props.children}
    </h2>
  );
};

export default PanelTitle;
