import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Breadcrumbs, BreadcrumbsWrapper } from 'components/breadcrumbs';

import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle,
  PanelTitleButtonsGroup,
  PanelTitleButton,
  PanelHeaderSettings
} from 'components/panel';
import { MenuWrapper } from 'components/menu';
import Modal from 'components/modal/Modal';
import GrowthPredictionTableWarning from './table/GrowthPredictionTableWaiting';
import GrowthPredictionAlertCardBody from 'containers/partials/cards/GrowthPredictionAlertCardBody';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from 'messages';
import SidePanelChartCard from 'containers/partials/cards/SidePanelChartCard';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import ButtonsGroup from 'components/button/ButtonsGroup';

const GrowthPredictionPanelContext = React.createContext();

const onDefaultHandler = str =>
  console.log(
    `GrowthPredictionPanel: No handler for '${str ? str : 'function'}'.`
  );

class GrowthPredictionPanel extends Component {
  state = { helpPopup: false, start: true, end: true, isScroll: false };
  panelBodyRef = React.createRef();

  componentDidMount() {
    const element = this.panelBodyRef.current;
    element.scrollLeft = 0;
    element.addEventListener('scroll', this.onScrollHandler);
    window.addEventListener('resize', this.onResizeHandler);
    this.onScroll(element);
  }

  componentWillUnmount() {
    const element = this.panelBodyRef.current;
    element.removeEventListener('scroll', this.onScrollHandler);
    window.removeEventListener('resize', this.onResizeHandler);
  }

  onResizeHandler = () => {
    const element = this.panelBodyRef.current;
    this.onScroll(element);
  };
  onScrollHandler = e => {
    const element = e.currentTarget;
    this.onScroll(element);
  };

  onScroll = element => {
    const { start, end, isScroll } = this.state;
    if (element.scrollLeft === 0 && !start) {
      this.setState({ start: true });
    } else if (element.scrollLeft !== 0 && start) {
      this.setState({ start: false });
    }

    if (element.scrollWidth !== element.offsetWidth && !isScroll) {
      this.setState({ isScroll: true });
    } else if (element.scrollWidth === element.offsetWidth && isScroll) {
      this.setState({ isScroll: false });
    }

    if (
      element.offsetWidth + element.scrollLeft === element.scrollWidth &&
      !end
    ) {
      this.setState({ end: true });
    } else if (
      element.offsetWidth + element.scrollLeft !== element.scrollWidth &&
      end
    ) {
      this.setState({ end: false });
    }
  };

  moveLeftHandler = () => {
    const element = this.panelBodyRef.current;
    const colWidth = element
      .querySelector('th:nth-child(2)')
      .getBoundingClientRect().width;

    window.requestAnimationFrame(() => {
      element.scrollLeft = element.scrollLeft - colWidth;
    });
  };

  moveRightHandler = () => {
    const element = this.panelBodyRef.current;
    const colWidth = element
      .querySelector('th:nth-child(2)')
      .getBoundingClientRect().width;

    window.requestAnimationFrame(() => {
      element.scrollLeft = element.scrollLeft + colWidth;
    });
  };

  render() {
    const {
      breadcrumbs,
      panelTitle,
      panelChoices,
      panelSubtitle,
      growthPredictionTable,
      growthPredictionettings,
      sidePanelData,
      testSetId,
      growthPredictionId,
      alertData = null,
      onCloseAlert = () => onDefaultHandler('onCloseAlert'),
      onExportGrowthPrediction = () =>
        onDefaultHandler('onExportGrowthPrediction'),
      onClosePanel = () => onDefaultHandler('onClosePanel'),
      onChangeCol = () => onDefaultHandler('onChangeCol'),
      onClickCell = () => onDefaultHandler('onClickCell'),
      onChangeGrowthPredictionType = () =>
        onDefaultHandler('onChangeGrowthPredictionType'),
      onChangeGrowthPrediction = () =>
        onDefaultHandler('onChangeGrowthPrediction'),
      onChangeTestSet = () => onDefaultHandler('onChangeTestSet'),
      hasSubCols = false,
      growthPredictionType,
      testCols
    } = this.props;

    const { start, end, isScroll } = this.state;

    return (
      <GrowthPredictionPanelContext.Provider
        value={{
          onChangeCol,
          onClickCell,
          onChangeGrowthPrediction,
          onChangeGrowthPredictionType,
          onChangeTestSet,
          hasSubCols,
          testSetId,
          growthPredictionId,
          growthPredictionType
        }}
      >
        <Panel benchmark>
          <PanelHeader>
            <BreadcrumbsWrapper>
              <Breadcrumbs growthPrediction>{breadcrumbs}</Breadcrumbs>
              <PanelTitleButtonsGroup>
                <MenuWrapper trigger={<PanelTitleButton />}>
                  {testCols && testCols.find(tc => tc.id !== undefined) && (
                    <Button menu onClick={onExportGrowthPrediction}>
                      <Icon id="print" />
                      <FormattedMessage
                        {...messages.growthPredictionMenuItemExportGrowthPrediction}
                      />
                    </Button>
                  )}
                </MenuWrapper>
              </PanelTitleButtonsGroup>
            </BreadcrumbsWrapper>

            <PanelTitleWrapper>
              <PanelTitle>
                {panelChoices ? (
                  <MenuWrapper trigger={<span>{panelTitle}</span>}>
                    {panelChoices}
                  </MenuWrapper>
                ) : (
                  `${panelTitle}`
                )}
              </PanelTitle>
              <PanelSubtitle>{panelSubtitle}</PanelSubtitle>
            </PanelTitleWrapper>
            <PanelHeaderSettings>{growthPredictionettings}</PanelHeaderSettings>
          </PanelHeader>
          <PanelBody
            ref={this.panelBodyRef}
            scrollHorizontal
            style={{ height: 0 }}
          >
            {growthPredictionTable ? (
              growthPredictionTable
            ) : (
              <GrowthPredictionTableWarning />
            )}
            {growthPredictionTable && isScroll && (
              <ButtonsGroup>
                <Button
                  circularLarge
                  primaryDark
                  onClick={this.moveLeftHandler}
                  disabled={start}
                >
                  <Icon
                    id="play"
                    fillColor="color-neutral-x-light"
                    hugeIcon
                    mirrored
                  />
                </Button>
                <Button
                  circularLarge
                  primaryDark
                  onClick={this.moveRightHandler}
                  disabled={end}
                >
                  <Icon id="play" fillColor="color-neutral-x-light" hugeIcon />
                </Button>
              </ButtonsGroup>
            )}
          </PanelBody>
          {alertData && (
            <Modal
              card
              growthPredictionTableAlert
              benchmarksTableAlert
              noCloseButton
              isOpen={true}
              onClose={onCloseAlert}
            >
              <GrowthPredictionAlertCardBody alertData={alertData} />
            </Modal>
          )}
          {sidePanelData && (
            <Modal cardPanel isOpen={true} onClose={onClosePanel}>
              <SidePanelChartCard {...sidePanelData} />
            </Modal>
          )}
        </Panel>
      </GrowthPredictionPanelContext.Provider>
    );
  }
}

export default withRouter(
  injectIntl(inject('routing', 'authStore')(observer(GrowthPredictionPanel)))
);

export const GrowthPredictionPanelConsumer =
  GrowthPredictionPanelContext.Consumer;
