import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  QUERY_GET_TESTSET,
  QUERY_GET_TESTSET_TESTS
} from 'services/aws/testsets-query';
import { useLazyQuery } from '@apollo/client';

function useTestSet({ entityId }) {
  const [testSet, setTestSet] = useState();
  const [getTestSet, { loading, error, data }] =
    useLazyQuery(QUERY_GET_TESTSET);
  const [getTestSetTests] = useLazyQuery(QUERY_GET_TESTSET_TESTS);

  useEffect(() => {
    if (data?.getTestSetV2) {
      setTestSet(data.getTestSetV2);
    }
  }, [data]);

  const fetchTestSet = async ({ types }) => {
    try {
      const result = await getTestSet({
        variables: { types, entityId },
        fetchPolicy: 'cache-and-network'
      });
      if (result.data?.getTestSetV2) {
        setTestSet(result.data.getTestSetV2);
        return result.data.getTestSetV2;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const fetchTestSetTests = async ({ testSetId }) => {
    try {
      const result = await getTestSetTests({
        variables: { entityId, testSetId }
      });
      if (result.data?.getTestSetV2) {
        setTestSet(result.data.getTestSetV2);
        return result.data.getTestSetV2;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return { testSet, loading, error, fetchTestSet, fetchTestSetTests };
}

export default useTestSet;
