import { observable, computed, makeObservable } from 'mobx';
import {
  PersonEntityRoles,
  GLOBAL_TESTER_LOGIN,
  OrganisationTypes,
  TestSetV2Type
} from 'constants.js';
import Person from './Person';
import { capitalize } from 'utils/string';
import {
  athleteFeatures,
  defaultOrgFeatures,
  gsvAthleteRules,
  gsvRules,
  VitaleAdminRules,
  VitaleAthleteRules,
  VitaleRules,
  VitaleSubAdminRules
} from 'rules';
import Hotjar from '@hotjar/browser';

export default class User extends Person {
  entityRoles = '';
  language = 'en';
  dateCreated;
  testSetTypes = [];

  superadmin = false;

  constructor(
    {
      id,
      hmac,
      parentId,
      lastname,
      firstname,
      birthdate,
      email,
      superadmin = false,
      date_created,
      baseLanguage,
      meta,
      account_meta,
      status
    },
    entityId
  ) {
    super(
      {
        id,
        hmac,
        parentId,
        lastname,
        firstname,
        email,
        birthdate,
        baseLanguage,
        meta,
        account_meta,
        status
      },
      entityId
    );

    makeObservable(this, {
      entityRoles: observable,
      hasGroupsToShow: computed
    });
    this.superadmin = superadmin;
    this.dateCreated = date_created;
  }

  getUserLocale() {
    const language = this.getLanguage();

    if (this.isGSVOrg) {
      return `nl-gsv`;
    }
    if (this.isVitaleOrg) {
      return `nl-vitale`;
    }

    if (language) {
      return language;
    }
    return null;
  }

  getLanguage() {
    if (this.baseLanguage) return this.baseLanguage;
    return null;
  }

  get isGlobalTester() {
    return this.email === GLOBAL_TESTER_LOGIN;
  }

  hasNoRoles() {
    return (
      !this.hasRole(PersonEntityRoles.ROLE_ADMIN) &&
      !this.hasRole(PersonEntityRoles.ROLE_SUB_ADMIN) &&
      // !this.hasRole(PersonEntityRoles.ROLE_TESTER) &&
      !this.hasRole(PersonEntityRoles.ROLE_ATHLETE)
    );
  }

  get hasGroupsToShow() {
    const canShowGroup =
      this.entityRoles && this.entityRoles.find(er => this.canSeeGroup(er.id));
    return !!canShowGroup;
  }

  isAdmin() {
    return this.hasRole(PersonEntityRoles.ROLE_ADMIN);
  }

  isSubAdmin() {
    return this.hasRole(PersonEntityRoles.ROLE_SUB_ADMIN);
  }

  hasAdminRole() {
    return this.isAdmin() || this.isSubAdmin();
  }

  isAthlete() {
    return this.hasRole(PersonEntityRoles.ROLE_ATHLETE);
  }

  get isSportamundiOrg() {
    return !this.config.type;
  }

  get isMTSOrg() {
    return this.config.type === OrganisationTypes.MTS;
  }

  get isHylyghtOrg() {
    return this.config.type === OrganisationTypes.HYLYGHT;
  }

  get isGSVOrg() {
    return this.config.type === OrganisationTypes.GSV;
  }

  get isVitaleOrg() {
    return this.config.type === OrganisationTypes.VITALE;
  }

  get isVideoAnalyseOrg() {
    return (
      this.config.type === OrganisationTypes.HYLYGHT &&
      this.entityId === process.env.REACT_APP_VIDEO_ANALYSE_ENTITY_ID
    );
  }

  get isHanOrg() {
    return this.config.type === OrganisationTypes.HAN;
  }

  canEditUserRoles(entityId = this.rootEntityId) {
    return this.isAdmin(entityId);
  }

  canSeeGroup(groupId) {
    return (
      this.isAdmin(groupId) || this.isSubAdmin(groupId) // || this.isTester(groupId)
    );
  }

  canEditGroups(entityId = this.rootEntityId) {
    return (
      this.isAdmin(entityId) || this.isSubAdmin(entityId) // || this.isTester(entityId)
    );
  }

  canRemoveGroups(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canAddTestSets(entityId = this.rootEntityId) {
    return (
      (this.isHylyghtOrg && this.isAdmin(entityId)) ||
      (!this.isHylyghtOrg && this.config.testSetsEnabled === true)
    );
  }

  canEditTestSets(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canRemoveExercises(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canAddPrograms(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  canRemovePersonInjury(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }
  canRemoveScreenings(entityId = this.rootEntityId) {
    return this.isAdmin(entityId) || this.isSubAdmin(entityId);
  }

  setAbilities = entity => {
    const rules = [];

    if (this.isGSVOrg) {
      const gsvRulesSet = gsvRules;

      if (this.isAthlete()) {
        gsvRulesSet.push(...gsvAthleteRules);
        gsvRulesSet.push(
          ...[
            {
              subject: 'Exercise',
              action: 'create'
            },
            {
              subject: 'Exercise',
              action: 'update',
              conditions: { editable: true }
            },
            {
              subject: 'Exercise',
              action: 'delete',
              conditions: { editable: true }
            }
          ]
        );
      }

      if (this.isSubAdmin()) {
        gsvRulesSet.push({
          subject: 'Athletes',
          action: 'manage',
          inverted: !!this.entityTree?.parentId
        });

        gsvRulesSet.push({
          subject: 'Settings',
          action: 'manage',
          inverted: true
        });
      }
      if (this.hasAdminRole() || this.superadmin) {
        gsvRulesSet.push(
          ...[
            {
              subject: 'Exercise',
              action: 'create'
            },
            {
              subject: 'Exercise',
              action: 'update',
              conditions: { editable: true }
            },
            {
              subject: 'Exercise',
              action: 'delete',
              conditions: { editable: true }
            },
            {
              subject: 'Template',
              action: 'create'
            },
            {
              subject: 'Template',
              action: 'update',
              conditions: { editable: true }
            },
            {
              subject: 'Template',
              action: 'delete',
              conditions: { editable: true }
            },
            {
              subject: 'Trainings',
              action: 'manage'
            }
          ]
        );
      }
      if (this.isAdmin() || this.superadmin) {
        gsvRulesSet.push({
          subject: 'Athletes',
          action: 'manage',
          inverted: !!this.entityTree?.parentId
        });

        gsvRulesSet.push({
          subject: 'Settings',
          action: 'manage'
        });

        gsvRulesSet.push({
          subject: 'Groups',
          action: 'manage'
        });

        gsvRulesSet.push({
          subject: 'Users',
          action: 'manage'
        });

        gsvRulesSet.push({
          subject: 'Organisation',
          action: 'manage'
        });

        gsvRulesSet.push({
          subject: 'Organisations',
          action: 'manage'
        });
      }

      if (entity.apiKeys?.length > 0) {
        if (this.isAdmin() || this.superadmin) {
          gsvRulesSet.push({
            subject: 'Trainings',
            action: 'embed'
          });
        } else {
          gsvRulesSet.push({
            subject: 'Trainings',
            action: 'embed',
            inverted: true
          });
          gsvRulesSet.push({
            subject: 'Trainings',
            action: 'share'
          });
        }
      } else {
        gsvRulesSet.push({
          subject: 'Trainings',
          action: 'embed',
          inverted: true
        });
        gsvRulesSet.push({
          subject: 'Trainings',
          action: 'share',
          inverted: true
        });
      }

      if (this.superadmin) {
        gsvRulesSet.push({
          subject: 'Features',
          action: 'manage'
        });
      }

      rules.push(...gsvRulesSet);
    } else if (this.isVitaleOrg) {
      const vitaleRulesSet = VitaleRules;
      if (this.isAthlete()) {
        const vitaleRules = VitaleAthleteRules;
        vitaleRules.push({
          subject: 'Video',
          action: 'read',
          conditions: {
            'person.id': this.id
          }
        });
        vitaleRules.push({
          subject: 'Video',
          action: 'read',
          conditions: {
            'shares.sharedWith': { $elemMatch: { id: this.id } }
          }
        });
        vitaleRulesSet.push(...vitaleRules);
      }
      if (this.isSubAdmin()) {
        const vitaleRules = VitaleSubAdminRules;
        vitaleRules.push({
          subject: 'Video',
          action: 'read',
          conditions: {
            'shares.sharedWith': { $elemMatch: { id: this.id } }
          }
        });
        vitaleRulesSet.push(...vitaleRules);
      }
      if (this.isAdmin()) {
        vitaleRulesSet.push(...VitaleAdminRules);
      }
      rules.push(...vitaleRulesSet);
    } else {
      const featureFlags = entity?.featureFlags || defaultOrgFeatures;

      let menu;
      if (featureFlags) {
        menu = Object.keys(featureFlags)
          .map(ff => (featureFlags[ff] ? ff : null))
          .filter(Boolean);

        if (menu.includes('sessions')) {
          this.testSetTypes.push(TestSetV2Type.TALENT);
        }
        if (menu.includes('rehab')) {
          this.testSetTypes.push(TestSetV2Type.REHAB);
        }
        if (menu.includes('prevention')) {
          this.testSetTypes.push(TestSetV2Type.PREVENTION);
        }
      }

      if (this.isAdmin() || this.isSubAdmin()) {
        // Give ability from the menu items
        menu.forEach(item => {
          if (
            item === 'athletes' ||
            item === 'organisations' ||
            item === 'users'
          ) {
            rules.push({
              subject: capitalize(item),
              action: this.isSubAdmin() ? 'read' : 'manage'
            });
          } else {
            rules.push({
              subject: capitalize(item),
              action: 'manage'
            });
          }
        });
      } else if (this.isAthlete()) {
        menu.forEach(item => {
          if (athleteFeatures.includes(item)) {
            rules.push({
              subject: capitalize(item),
              action: 'read'
            });
          }
        });
      }

      if (this.isSubAdmin()) {
        if (rules.find(rule => rule.subject === 'Benchmarks')) {
          rules.push({
            subject: 'Benchmarks',
            action: 'read'
          });
        }
        if (rules.find(rule => rule.subject === 'Organisations')) {
          rules.push({
            subject: 'Organisations',
            action: 'read'
          });
        }
      }

      if (this.isAdmin()) {
        if (rules.find(rule => rule.subject === 'Benchmarks')) {
          rules.push({
            subject: 'Benchmarks',
            action: 'manage'
          });
        }
        if (rules.find(rule => rule.subject === 'Users')) {
          rules.push({
            subject: 'Users',
            action: 'manage'
          });
        }

        if (rules.find(rule => rule.subject === 'Organisations')) {
          rules.push({
            subject: 'Organisations',
            action: 'manage'
          });
        }
      }

      if (this.isAdmin() || this.isSubAdmin()) {
        if (rules.find(rule => rule.subject === 'Prevention')) {
          rules.push({
            subject: 'Prevention',
            action: 'manage'
          });
        }
        if (rules.find(rule => rule.subject === 'Rehab')) {
          rules.push({
            subject: 'Rehab',
            action: 'manage'
          });
        }
        if (rules.find(rule => rule.subject === 'Athletes')) {
          rules.push({
            subject: 'Athletes',
            action: 'manage'
          });
          rules.push({
            subject: 'Groups',
            action: 'manage'
          });
        }

        if (rules.find(rule => rule.subject === 'PersonInvite')) {
          rules.push({
            subject: 'Athletes',
            action: 'invite'
          });
        } else {
          rules.push({
            subject: 'Athletes',
            action: 'invite',
            inverted: true
          });
        }

        if (rules.find(rule => rule.subject === 'Exercises')) {
          rules.push(
            ...[
              {
                subject: 'Exercise',
                action: 'create'
              },
              {
                subject: 'Exercise',
                action: 'update',
                conditions: { editable: true }
              },
              {
                subject: 'Exercise',
                action: 'delete',
                conditions: { editable: true }
              },
              {
                subject: 'Template',
                action: 'create'
              },
              {
                subject: 'Template',
                action: 'update',
                conditions: { editable: true }
              },
              {
                subject: 'Template',
                action: 'delete',
                conditions: { editable: true }
              },
              {
                subject: 'Programs',
                action: 'manage'
              }
            ]
          );
        }
        if (rules.find(rule => rule.subject === 'Documents')) {
          rules.push({
            subject: 'Documents',
            action: 'manage'
          });
        }
        rules.push({
          subject: 'Organisation',
          action: 'read'
        });

        rules.push({
          subject: 'Settings',
          action: 'manage'
        });

        rules.push({
          subject: 'User',
          action: 'update',
          conditions: { id: { $eq: this.id } }
        });
      }
    }

    if (this.isAdmin()) {
      rules.push({
        subject: 'Organisation',
        action: 'manage'
      });
    }

    if (this.superadmin) {
      rules.push({
        subject: 'Organisation',
        action: 'manage'
      });
      rules.push({
        subject: 'Organisations',
        action: 'manage'
      });
      rules.push({
        subject: 'Features',
        action: 'manage'
      });
      /* rules.push({
        subject: 'RecurringSessions',
        action: 'manage'
      });*/
    }

    if (this.isVideoAnalyseOrg) {
      rules.push({
        subject: 'Test',
        action: 'manage'
      });
    }

    return rules;
  };

  initIntercom = () => {
    if (
      typeof window.Intercom === 'function' &&
      process.env.REACT_APP_INTERCOM_ENABLED === 'true'
    ) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        custom_launcher_selector: '#intercom-launcher',
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        name: this.fullName,
        user_id: this.id,
        email: this.email,
        user_hash: this.hmac,
        created_at: Math.round(this.dateCreated / 1000),
        birthdate: this.birthdate,
        language_override: this.baseLanguage,
        root_entity: this.rootEntityId,
        company: {
          id: this.entityTree?.id,
          name: this.entityTree?.name,
          created_at: this.entityTree?.date_created
            ? Math.round(this.entityTree.date_created / 1000)
            : null
        },
        role: this.hasAdminRole() ? 'admin' : 'athlete'
      });
    }
  };

  identifyHotjarUser = () => {
    if (process.env.REACT_APP_HOTJAR_ENABLED === 'true') {
      Hotjar.identify(this.id, {
        full_name: this.fullName,
        email: this.email,
        root_entity: this.rootEntityId,
        entity_id: this.entityTree?.id,
        name: this.entityTree?.name,
        created_at: Math.round(this.dateCreated / 1000),
        language: this.baseLanguage,
        role: this.hasAdminRole() ? 'admin' : 'athlete'
      });
    }
  };

  static get modelName() {
    return 'User';
  }
}
