import {
  Breadcrumb,
  Breadcrumbs,
  BreadcrumbsWrapper
} from 'components/breadcrumbs';
import { Button } from 'components/button';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import useGroupsBreadCrumb from 'hooks/queries/useGroupsBreadCrumb';

const GroupsBreadCrumbs = ({ entityId, groupId, onClick }) => {
  const { groupBreadcrumb } = useGroupsBreadCrumb({
    entityId,
    groupId
  });

  const onBreadcrumbClickedHandler = async item => {
    onClick && onClick(item.id || entityId);
  };

  const onBackHandler = async () => {
    const lastGroup =
      groupBreadcrumb && groupBreadcrumb[groupBreadcrumb.length - 1];
    onClick && onClick(lastGroup?.parentId || entityId);
  };

  return (
    <BreadcrumbsWrapper forLists>
      <Button rounded light onClick={() => onBackHandler()}>
        <Icon id="back-nav" />
        <FormattedMessage {...messages.exercisesAssignUserButtonBack} />
      </Button>
      <Icon id="breadcrumb" fillColor="color-neutral-steel" />
      <Breadcrumbs>
        {groupBreadcrumb &&
          groupBreadcrumb.map((group, i, arr) => {
            if (i === arr.length - 1) {
              return (
                <li
                  className="c-card-modal__body-head-breadcrumb"
                  key={group.id}
                >
                  <span className="c-organisation">{group.name}</span>
                </li>
              );
            } else {
              return (
                <Breadcrumb
                  key={group.id}
                  onClick={() => onBreadcrumbClickedHandler(group)}
                >
                  {group.name}
                </Breadcrumb>
              );
            }
          })}
      </Breadcrumbs>
    </BreadcrumbsWrapper>
  );
};

export default GroupsBreadCrumbs;
