import { useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { EntityTypes } from 'constants.js';
import { sortUsers } from 'utils/sort';
import Person from 'models/Person';
import { getFilteredUsers } from 'utils/entities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import { QUERY_GET_USERS_BY_ENTITY_ID } from 'services/aws/user-query';
import Entity from 'models/Entity';
import User from 'models/User';

function useFetchAndFilterUsers({ entityId }) {
  const entityData = useRef();
  const triggerFilter = useRef();

  const entity = useEntityContext(s => s.group);
  const setEntity = useEntityContext(s => s.setGroup);
  const setUsers = useEntityContext(s => s.setPersons);
  const filter = useEntityContext(s => s.filter);

  const [getUsers, { loading, error, data, refetch }] = useLazyQuery(
    QUERY_GET_USERS_BY_ENTITY_ID,
    {
      variables: { id: entityId },
      skip: !entityId
    }
  );

  useEffect(() => {
    if (data?.getEntity) {
      const entity = new Entity(data.getEntity);
      entityData.current = entity;
      if (entity?.users) {
        setUsers(
          entity.users.map(user => new User({ ...user, filtered: false }))
        );
      }
      setEntity(entity);

      triggerFilter.current = Date.now();
    }
  }, [data]);

  useEffect(() => {
    if (entity && entityData?.current?.id) {
      if (entity.id) {
        const filteredUsers =
          filter.type !== EntityTypes.GROUP
            ? getFilteredUsers(entity.users, filter.search ?? '').map(
                user => new User({ ...user, filtered: true })
              )
            : [];

        // get a list of all users but not the ones that are in filteredUsers
        const usersNotInFilteredUsers = entity.users
          .filter(
            client =>
              !filteredUsers.some(filteredUser => filteredUser.id === client.id)
          )
          .map(user => new Person({ ...user, filtered: false }));

        const allUsers = [
          ...sortUsers(filteredUsers),
          ...sortUsers(usersNotInFilteredUsers)
        ];

        setUsers(allUsers);
      }
    }
  }, [filter, entity.id, triggerFilter.current]);

  const fetchUsers = async entityId => {
    await getUsers({
      variables: { id: entityId },
      fetchPolicy: 'network-only'
    });
  };

  return {
    loading,
    error,
    refetch,
    fetchUsers
  };
}

export default useFetchAndFilterUsers;
