import { useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID } from 'services/aws/client-query';
import { EntityTypes } from 'constants.js';
import Group from 'models/Group';
import { sortEntities, sortUsers } from 'utils/sort';
import Person from 'models/Person';
import {
  filterEntitiesByYear,
  getFilteredGroups,
  getFilteredUsers
} from 'utils/entities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function useFetchAndFilterEntities({ entityId }) {
  const groupData = useRef();
  const triggerFilter = useRef();

  const group = useEntityContext(s => s.group);
  const setGroup = useEntityContext(s => s.setGroup);
  const setGroups = useEntityContext(s => s.setGroups);
  const setPersons = useEntityContext(s => s.setPersons);
  const filter = useEntityContext(s => s.filter);

  const [getEntities, { loading, error }] = useLazyQuery(
    S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID,
    {
      variables: { id: entityId },
      skip: !entityId,
      fetchPolicy: 'network-only'
    }
  );

  const fetchEntities = async entityId => {
    groupData.current = null;
    const { data } = await getEntities({
      variables: { id: entityId },
      fetchPolicy: 'network-only',
      skip: !entityId
    });

    if (data?.getEntity) {
      const group = new Group(data.getEntity);
      groupData.current = group;
      if (group?.subEntities) {
        const groups = group.subEntities
          .filter(se => se.type !== EntityTypes.ORGANISATION)
          .map(g => new Group({ ...g, filtered: false }));
        setGroups(groups);
        // setEntities(groups.map(g => new Group({ ...g, filtered: true })));
      }
      if (group?.clients && filter.type !== EntityTypes.GROUP) {
        const persons = group.clients.map(
          user => new Person({ ...user, filtered: false })
        );
        setPersons(persons);
      }
      setGroup(group);

      triggerFilter.current = Date.now();
    }
  };

  useEffect(() => {
    if (group && groupData?.current?.id) {
      if (group.id && group.subEntities) {
        let filteredGroups = getFilteredGroups(
          sortEntities(group.subEntities),
          filter.search ?? ''
        );
        if (filter.year) {
          filteredGroups = filterEntitiesByYear(filteredGroups, filter.year);
        }
        const allFilteredGroups = filteredGroups.map(
          g => new Group({ ...g, filtered: true })
        );
        // get a list of all groups but not the ones that are in filteredGroups
        const groupsNotInFilteredGroups = group.subEntities
          .filter(
            group =>
              !allFilteredGroups.some(
                filteredGroup => filteredGroup.id === group.id
              )
          )
          .map(group => new Group({ ...group, filtered: false }));

        const allGroups = [...allFilteredGroups, ...groupsNotInFilteredGroups];

        setGroups(allGroups);

        const filteredUsers =
          filter.type !== EntityTypes.GROUP && group.id !== entityId
            ? getFilteredUsers(group.clients, filter.search ?? '').map(
                user => new Person({ ...user, filtered: true })
              )
            : [];

        // get a list of all users but not the ones that are in filteredUsers
        const usersNotInFilteredUsers = group.clients
          .filter(
            client =>
              !filteredUsers.some(filteredUser => filteredUser.id === client.id)
          )
          .map(user => new Person({ ...user, filtered: false }));

        const allPersons = [
          ...sortUsers(filteredUsers),
          ...(group.id !== entityId ? sortUsers(usersNotInFilteredUsers) : [])
        ];
        setPersons(allPersons);
      }
    }
  }, [filter, JSON.stringify(group), triggerFilter.current]);

  return {
    loading,
    error,
    fetchEntities
  };
}

export default useFetchAndFilterEntities;
