import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import {
  PanelBody,
  PanelSubtitle,
  PanelTitleButtonsGroup
} from 'components/panel';
import { PanelTitle } from 'components/panel/panel-title';
import {
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTIONS,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_MY_COLLECTIONS,
  ROUTE_EXERCISES_COLLECTIONS_ENTITY_OTHER_COLLECTIONS
} from 'routes/RouteList';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import TemplatesListItem from 'containers/partials/list-items/TemplatesListItem';
import BreadcrumbPath from 'containers/partials/misc/BreadcrumbPath';
import useSelectable from 'hooks/utils/useSelectable';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { TabList, TabPanel, Tabs } from 'components/tabs';
import AddCollectionModal from 'containers/pages/exercises/collections/AddCollectionModal';
import TabPanelBody from 'components/tabs/TabPanelBody';
import useCollections from 'hooks/queries/useCollections';
import { useStore } from 'stores/Store';
import { TemplateType } from 'constants';
import AddButton from 'containers/partials/buttons/AddButton';
import TabLink from 'components/tabs/TabLink';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';

const Collections = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { entityId, tab, templateId } = useParams();
  const ability = useAbility(AbilityContext);
  const active = useRouteMatch(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTIONS);
  const [addModalData, setAddModalData] = useState(null);
  const { fetchCollections, filterCollections, loading } =
    useCollections(entityId);
  const collections = useStore(state => state.collections);
  const { selected, select } = useSelectable([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    fetchCollections({
      variables: { entityId, type: TemplateType.COLLECTION }
    });
  }, []);

  const onSearch = e => {
    filterCollections({ search: e.target.value });
  };

  const onClickHandler = collection => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES, {
        entityId,
        tab,
        templateId: collection.id
      })
    );
  };

  const onEditHandler = collection => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION, {
        entityId,
        tab,
        templateId: collection.id
      })
    );
  };

  const onDeleteHandler = templateId => {
    push(
      generatePath(ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTIONS, {
        entityId,
        tab,
        templateId
      })
    );
  };

  return (
    <Panel active={active.isExact}>
      <RefactoredPanelHeader>
        <RefactoredPanelTitleWrapper>
          <BreadcrumbPath crumbs={[]} />
          <PanelTitle>
            <FormattedMessage {...messages.collectionsTitle} />
          </PanelTitle>
          <PanelSubtitle>
            {tabIndex === 1 ? (
              <FormattedMessage {...messages.collectionsSubtitleOther} />
            ) : (
              <FormattedMessage {...messages.collectionsSubtitleMine} />
            )}
          </PanelSubtitle>
        </RefactoredPanelTitleWrapper>
        <PanelTitleButtonsGroup>
          {ability.can('create', 'Template') && (
            <AddButton
              expandable
              label={
                <FormattedMessage {...messages.exercisesAddCollectionButton} />
              }
              onClick={() => setAddModalData(true)}
            />
          )}
        </PanelTitleButtonsGroup>
      </RefactoredPanelHeader>
      <PanelBody>
        {loading && <Loader />}
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <TabLink
              to={generatePath(
                ROUTE_EXERCISES_COLLECTIONS_ENTITY_MY_COLLECTIONS,
                { entityId }
              )}
              key={`collections.my`}
            >
              <FormattedMessage {...messages.collectionsTabMyCollections} />
            </TabLink>
            <TabLink
              to={generatePath(
                ROUTE_EXERCISES_COLLECTIONS_ENTITY_OTHER_COLLECTIONS,
                { entityId }
              )}
              key={`collections.other`}
            >
              <FormattedMessage {...messages.collectionsTabAllCollections} />
            </TabLink>
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller withHeader>
              {/* TODO: fix!  */}
              {/* <FieldInput
                rounded
                icon="search-grey"
                iconLeft
                onChange={onSearch}
              /> */}

              {collections &&
              collections.length &&
              collections.filter(t => t.personId === user.id).length ? (
                <List>
                  {/* <ListHeader noTitleStatus>
                <ListColumnTitle
                // ascendent // in comment totdat het werkt:
                // TODO: rangschikking list by column title
                >
                  <FormattedMessage
                    {...messages.collectionsHeaderListCollections}
                  />
                </ListColumnTitle>
                <ListColumnTitle>
                  <FormattedMessage
                    {...messages.collectionsHeaderListExercisesQty}
                  />
                </ListColumnTitle>
              </ListHeader> */}
                  <ListBody>
                    {collections
                      .filter(t => t.personId === user.id)
                      .map(collection => (
                        <TemplatesListItem
                          checkbox={false}
                          icon="template"
                          mediumIcon
                          visible={selected.length > 0}
                          exercisesLength={collection.exercises.length}
                          id={collection.id}
                          key={collection.id}
                          template={collection}
                          entityId={entityId}
                          active={collection.id === templateId}
                          checked={selected.includes(collection.id)}
                          onChange={() => select(collection.id)}
                          onClick={() => {
                            return onClickHandler(collection);
                          }}
                          onEdit={() => onEditHandler(collection)}
                          onDeleted={() => onDeleteHandler(templateId)}
                          style={collection.id === templateId ? { top: 8 } : {}}
                        />
                      ))}
                  </ListBody>
                </List>
              ) : (
                <EmptyCollectionList />
              )}
            </TabPanelBody>
          </TabPanel>
          <TabPanel>
            <TabPanelBody withHeader>
              {/* TODO: fix!  */}
              {/* <FieldInput
                rounded
                icon="search-grey"
                iconLeft
                onChange={onSearch}
              /> */}

              {collections &&
              collections.length &&
              collections.filter(t => t.personId !== user.id).length ? (
                <List>
                  {/* <ListHeader noTitleStatus>
                <ListColumnTitle
                // ascendent // in comment totdat het werkt:
                // TODO: rangschikking list by column title
                >
                  <FormattedMessage
                    {...messages.collectionsHeaderListCollections}
                  />
                </ListColumnTitle>
                <ListColumnTitle>
                  <FormattedMessage
                    {...messages.collectionsHeaderListExercisesQty}
                  />
                </ListColumnTitle>
              </ListHeader> */}
                  <ListBody>
                    {collections
                      .filter(t => t.personId !== user.id)
                      .map(collection => (
                        <TemplatesListItem
                          checkbox={false}
                          icon="template"
                          mediumIcon
                          visible={selected.length > 0}
                          exercisesLength={collection.exercises.length}
                          id={collection.id}
                          key={collection.id}
                          template={collection}
                          entityId={entityId}
                          active={collection.id === templateId}
                          checked={selected.includes(collection.id)}
                          onChange={() => select(collection.id)}
                          onClick={() => {
                            return onClickHandler(collection);
                          }}
                          onEdit={() => onEditHandler(collection)}
                          onDeleted={() => onDeleteHandler(templateId)}
                        />
                      ))}
                  </ListBody>
                </List>
              ) : (
                <EmptyCollectionList />
              )}
            </TabPanelBody>
          </TabPanel>
        </Tabs>
      </PanelBody>

      {addModalData && (
        <AddCollectionModal
          entityId={entityId}
          onClose={() => setAddModalData(false)}
        />
      )}
    </Panel>
  );
};

export default Collections;

const EmptyCollectionList = () => (
  <Card centered>
    <CardBody empty>
      <Message emptyState={true} icon="browse">
        <MessageText>
          <FormattedMessage {...messages.templateListEmpty} />
        </MessageText>
      </Message>
    </CardBody>
  </Card>
);
