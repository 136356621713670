import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import CardBody from 'components/card/CardBody';
import CardFooter from 'components/card/CardFooter';
import messages from 'messages.js';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { StoreContext } from 'index';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import PersonForm from 'containers/pages/persons/PersonForm';
import useEdit from 'hooks/utils/useEdit';
import {
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_POOL,
  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT
} from 'routes/RouteList';
import { useMenuContext } from 'contexts/MenuContext';
import RemoveSporterFromGroupButton from 'containers/partials/buttons/RemoveSporterFromGroupButton';
import useUserGroups from 'hooks/queries/useUserGroups';
import Loader from 'components/loader/Loader';
import RemovePersonsFromOrgButton from 'containers/partials/buttons/RemovePersonsFromOrgButton';
import {
  MUTATE_RESET_LOGIN,
  QUERY_GET_USER_BY_ID,
  QUERY_GET_USERS_BY_ENTITY_ID
} from 'services/aws/user-query';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import PersonFormDetails from 'containers/pages/persons/PersonFormDetails';
import { useNotificationQueue } from 'components/notification';
import GenerateMagicURLModal from 'containers/partials/modals/GenerateMagicURLModal';

const PersonCard = ({ person, onCancel, isAdd = false }) => {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const {
    authStore: { user },
    uiState
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { entityId, groupId } = useParams();
  const { addMenuItems, removeAllMenuItems } = useMenuContext();
  const [openGenerateMagicURLModal, setOpenGenerateMagicURLModal] =
    useState(false);

  const { loading: groupsLoading, entities } = useUserGroups({
    entityId,
    personId: person?.id
  });

  const [edit, setEdit] = useEdit(
    !!useRouteMatch([
      ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL_EDIT
    ]) || isAdd,
    !isAdd
  );

  const personPoolPage =
    useRouteMatch(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL)?.isExact &&
    groupId === 'all';
  const personGroupPage =
    useRouteMatch(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL)?.isExact &&
    groupId !== 'all';

  useEffect(() => {
    return () => {
      removeAllMenuItems();
    };
  }, []);

  useEffect(() => {
    const menuItems = [];
    if (
      ability.can('delete', 'Athletes') &&
      person?.id &&
      personGroupPage &&
      !personPoolPage
    ) {
      menuItems.push(
        <RemoveSporterFromGroupButton
          key="deleteAthlete"
          menu
          entityId={entityId}
          groupId={groupId}
          personId={person.id}
        >
          <FormattedMessage {...messages.menuItemSporterRemoveSporter} />
        </RemoveSporterFromGroupButton>
      );
    }

    if (
      ability.can('delete', 'Athletes') &&
      person?.id &&
      (personGroupPage || personPoolPage)
    ) {
      menuItems.push(
        <RemovePersonsFromOrgButton
          key={'removeSporters'}
          entityId={entityId}
          groupId={groupId}
          persons={[person]}
          isPoolPage={personPoolPage}
          menu
          onComplete={onPersonsRemoved}
        >
          <FormattedMessage
            {...messages.menuItemSporterRemoveSporters}
            values={{ count: 1 }}
          />
        </RemovePersonsFromOrgButton>
      );
    }

    addMenuItems(menuItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId, person, user, entities]);

  const onPersonsRemoved = async () => {
    if (personPoolPage) {
      push(
        generatePath(ROUTE_SPORTERS_ENTITY_POOL, {
          entityId,
          groupId: 'all'
        })
      );
    } else {
      push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId }));
    }
  };

  const onPersonAdded = personId => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL, {
        entityId,
        sporterId: personId
      })
    );
  };

  const onPersonUpdated = () => {
    setEdit(false);
  };

  const onClickPersonStatusHandler = person => {
    // @ts-ignore
    uiState.showModal({
      title: intl.formatMessage(messages.modalConfirmResetPasswordTitle),
      message: messages.modalConfirmResetPasswordMessage,
      okLabel: intl.formatMessage(
        messages.modalConfirmResetPasswordAcceptButton
      ),
      dismissLabel: intl.formatMessage(messages.modalConfirmRemoveCancelButton),
      mutationData: {
        mutation: MUTATE_RESET_LOGIN,
        variables: {
          personId: person.id
        },
        refetchQueries: [
          {
            query: QUERY_GET_USERS_BY_ENTITY_ID,
            variables: { id: entityId }
          },
          {
            query: QUERY_GET_USER_BY_ID,
            variables: {
              id: person.id
            }
          }
        ],
        update: (cache, { data: { updatePersonEntityRoles } }) => {
          if (updatePersonEntityRoles) {
            // refetch();
            notification.add(`emailReset_${person.id}`, {
              message: intl.formatMessage(messages.messageUserEmailReset)
            });
          }
        }
      }
    });
  };

  if (!person || groupsLoading) return <Loader />;

  return (
    <>
      <PersonForm
        entityId={entityId}
        groupId={entityId}
        person={{ ...person }}
        onAdded={personId => onPersonAdded(personId)}
        onComplete={personId => onPersonUpdated(personId)}
        extraClassNames={'c-card c-card--secondary'}
      >
        {({ submitForm, resetForm }) => (
          <>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.cardTitleSporter} />
              </CardHeaderTitle>
              {!user.isAthlete() && (
                <FormEditHeaderButtons
                  edit={edit}
                  setEdit={edit => setEdit(edit)}
                  type={'button'}
                  onSubmit={submitForm}
                  onCancel={() => {
                    resetForm();
                    onCancel && onCancel();
                  }}
                />
              )}
            </CardHeader>
            <CardBody secondary>
              <PersonFormDetails
                entityId={entityId}
                person={person}
                edit={edit}
                onClickPersonStatus={onClickPersonStatusHandler}
              />
            </CardBody>
            {edit && (
              <CardFooter secondary>
                <FormEditFooterButtons
                  edit={edit}
                  setEdit={edit => setEdit(edit)}
                  onCancel={() => {
                    resetForm();
                    onCancel && onCancel();
                  }}
                  onSubmit={submitForm}
                  type={'button'}
                />
              </CardFooter>
            )}
          </>
        )}
      </PersonForm>
      {/*{user.superadmin && person && (
        <Card secondary>
          <CardHeader secondary>
            <CardHeaderTitle>
              Sporter QR code (only visible for super admins)
            </CardHeaderTitle>
          </CardHeader>
          <CardBody secondary>
            <div className="o-layout o-layout--tiny">
              <QRCodeSVG value={person?.id} />
            </div>
          </CardBody>
        </Card>
      )}*/}
      {openGenerateMagicURLModal && (
        <GenerateMagicURLModal
          entityId={entityId}
          person={person}
          onClose={() => setOpenGenerateMagicURLModal(false)}
        />
      )}
    </>
  );
};
export default PersonCard;
