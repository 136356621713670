import React, { Fragment, useContext, useEffect, useState } from 'react';
import { withRouter, generatePath, useHistory } from 'react-router-dom';
import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_BENCHMARKS,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST
} from 'routes/RouteList';
import { TabList, Tab, TabPanel, Tabs } from 'components/tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelTitleButton from 'components/panel/panel-title/PanelTitleButton';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import { MenuWrapper } from 'components/menu';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';

import BenchmarkModal, {
  BENCHMARK_UPDATE_TYPE
} from 'containers/partials/modals/BenchmarkModal';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import RemoveBenchmarkButton from 'containers/partials/buttons/RemoveBenchmarkButton';
import { QUERY_GET_BENCHMARK } from 'services/aws/benchmark-query';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import messages from 'messages';
import { QUERY_GET_BENCHMARKS } from 'services/aws/benchmark-query';
import PublishBenchmarkButton from 'containers/partials/buttons/PublishBenchmarkButton';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import { useQuery } from '@apollo/client';
import { useTestsContext } from 'contexts/TestsContext';
import { getUniqueTestItems } from 'utils/tests';
import TabPanelBody from 'components/tabs/TabPanelBody';
import { TestSetV2Type } from 'constants.js';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const Benchmark = ({ benchmarkId, benchmarkTestId }) => {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();

  const {
    testActions,
    testsState,
    loading: loadingTests,
    error: errorTests
  } = useTestsContext();

  const [benchmarks, setBenchmarks] = useState([]);
  const [benchmark, setBenchmark] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [canEditThings, setCanEditThings] = useState(false);
  const [testItems, setTestItems] = useState([]);

  const {
    loading: loadingBenchmarks,
    error: errorBenchmarks,
    data: dataBenchmarks
  } = useQuery(QUERY_GET_BENCHMARKS, {
    variables: { entityId: rootEntityId }
  });

  const {
    loading: loadingBenchmark,
    error: errorBenchmark,
    data: dataBenchmark
  } = useQuery(QUERY_GET_BENCHMARK, {
    variables: { benchmarkId, entityId: rootEntityId }
  });

  useEffect(() => {
    if (!testsState?.allTests) {
      testActions.getTests(TestSetV2Type.TALENT);
    }
  }, []);

  useEffect(() => {
    if (testsState?.allTests) {
      setTestItems(getUniqueTestItems(testsState.allTests));
    }
  }, [testsState.allTests]);

  useEffect(() => {
    if (dataBenchmarks?.getBenchmarks) {
      setBenchmarks(dataBenchmarks.getBenchmarks);
    }
  }, [dataBenchmarks]);

  useEffect(() => {
    if (dataBenchmark?.getBenchmark) {
      const benchmark = { ...dataBenchmark.getBenchmark, tests: [] };
      benchmark.tests = benchmark.benchmarkTestItems || [];
      setCanEditThings(
        ability.can('manage', 'Benchmarks') && benchmark.editable
      );
      setBenchmark(benchmark);
    }
  }, [dataBenchmark]);

  return (
    <Panel>
      {(loadingTests || loadingBenchmarks || loadingBenchmark) &&
        !benchmark && <Loader />}
      {(errorTests || errorBenchmarks || errorBenchmark) && <RetryPanel />}

      {benchmark && (
        <Fragment>
          {modalData && (
            <BenchmarkModal {...modalData} onClose={() => setModalData(null)} />
          )}
          <PanelHeader>
            <Breadcrumbs>
              <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
                <FormattedMessage {...messages.breadcrumbSettings} />
              </Breadcrumb>
              <Breadcrumb onClick={() => push(ROUTE_SETTINGS_BENCHMARKS)}>
                <FormattedMessage {...messages.breadcrumbBenchmarks} />
              </Breadcrumb>
            </Breadcrumbs>
            <PanelTitleWrapper>
              <PanelTitle>
                {benchmark.title}
                {benchmark.type === 'bioage'
                  ? ` (${intl.formatMessage(
                      messages.modalBenchmarkLabelTypeBioAge
                    )})`
                  : ''}
                {canEditThings && (
                  <MenuWrapper trigger={<PanelTitleButton />}>
                    {ability.can('update', 'Benchmarks') && (
                      <Button
                        menu
                        onClick={() =>
                          setModalData({
                            ...benchmark,
                            benchmarkType: benchmark.type,
                            type: BENCHMARK_UPDATE_TYPE.EDIT,
                            existingNames: benchmarks
                              .map(b => b.title.toLowerCase())
                              .filter(b => b !== benchmark.title.toLowerCase())
                          })
                        }
                      >
                        <Icon id="edit" />
                        <FormattedMessage
                          {...messages.benchmarksBenchmarkMenuItemEditName}
                        />
                      </Button>
                    )}
                    {ability.can('manage', 'Benchmarks') && (
                      <Button
                        menu
                        onClick={() =>
                          setModalData({
                            ...benchmark,
                            type: BENCHMARK_UPDATE_TYPE.COPY,
                            existingNames: benchmarks.map(b =>
                              b.title.toLowerCase()
                            )
                          })
                        }
                      >
                        <FormattedMessage
                          {...messages.benchmarksBenchmarkMenuItemCopy}
                        />
                        <Icon id="copy" />
                      </Button>
                    )}
                    {ability.can('manage', 'Benchmarks') && (
                      <PublishBenchmarkButton
                        menu
                        publish={!!benchmark.draft}
                        benchmarkId={benchmark.id}
                        entityId={rootEntityId}
                      >
                        {benchmark.draft ? (
                          <FormattedMessage
                            {...messages.benchmarksBenchmarkMenuItemPublish}
                          />
                        ) : (
                          <FormattedMessage
                            {...messages.benchmarksBenchmarkMenuItemUnPublish}
                          />
                        )}
                      </PublishBenchmarkButton>
                    )}
                    {ability.can('delete', 'Benchmarks') && (
                      <RemoveBenchmarkButton
                        menu
                        benchmarkId={benchmark.id}
                        entityId={rootEntityId}
                      >
                        <FormattedMessage
                          {...messages.benchmarksBenchmarkMenuItemRemove}
                        />
                      </RemoveBenchmarkButton>
                    )}
                  </MenuWrapper>
                )}
              </PanelTitle>
              <PanelSubtitle>
                {benchmark.note || (
                  <FormattedMessage
                    {...messages.benchmarksBenchmarkSubtitle}
                    values={{ name: benchmark.title }}
                  />
                )}
              </PanelSubtitle>
            </PanelTitleWrapper>
          </PanelHeader>
          <PanelBody>
            <Tabs defaultIndex={0}>
              <TabList>
                <Tab
                  onClick={() =>
                    push(
                      `${ROUTE_SETTINGS_BENCHMARKS_BENCHMARK.replace(
                        ':benchmarkId',
                        benchmarkId
                      )}`
                    )
                  }
                >
                  <FormattedMessage
                    {...messages.benchmarksBenchmarkTabBenchmarks}
                  />
                </Tab>
              </TabList>
              <TabPanel>
                <TabPanelBody withScroller>
                  <List>
                    <ListBody>
                      {testItems.map(testItem => {
                        const benchmarkTestItem = benchmark.tests.find(bt => {
                          if (bt?.testItem && bt.testItem.id === testItem.id) {
                            return bt;
                          }
                        });
                        // TODO @maarten, als er een benchmark item is en geen test item geeft dit een fout
                        return (
                          <BenchmarkTestListItem
                            key={testItem.id}
                            active={benchmarkTestId === testItem.id}
                            edited={!!benchmarkTestItem}
                            testItem={testItem}
                            onClick={() =>
                              push(
                                generatePath(
                                  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST,
                                  {
                                    benchmarkId,
                                    benchmarkTestId: testItem.id
                                  }
                                )
                              )
                            }
                          />
                        );
                      })}
                    </ListBody>
                  </List>
                </TabPanelBody>
              </TabPanel>
            </Tabs>
          </PanelBody>
        </Fragment>
      )}
    </Panel>
  );
};

export default withRouter(Benchmark);

const BenchmarkTestListItem = ({
  testItem: { title },
  edited,
  active,
  onClick
}) => (
  <ListItem group={!edited} active={active} onClick={onClick}>
    <ListItemStatus statusIcon="benchmarks">
      {/* {getTestNamePrefix(title)} */}
    </ListItemStatus>
    <ListItemLabelWrapper>
      <ListItemLabel label>{title}</ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
