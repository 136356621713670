import { useEffect, useRef } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import ListBody from 'components/list/ListBody';
import { ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT } from 'routes/RouteList';
import List from 'components/list/List';
import { useQuery } from '@apollo/client';
import { TestSetV2Type } from 'constants.js';
import { QUERY_GET_TESTSETS_WITH_SPORT } from 'services/aws/testsets-query';
import TestSet from 'models/TestSet';
import { SessionTemplateListItem } from 'containers/partials/list-items/SessionTemplateListItem';
import Session from 'models/Session';
import Loader from 'components/loader/Loader';
import FieldInput from 'components/input/FieldInput';
import { SORT_DATA_TYPES } from 'utils/sort';
import useTestsetSearch from 'hooks/utils/useTestsetSearch';
import MessageText from 'components/message/MessageText';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import classNames from 'classnames';

function PreventionTemplateList({ onUse }) {
  const { push } = useHistory();
  const { entityId } = useParams();
  const [state, setState] = useTestsetSearch({
    key: 'title'
  });
  const { data, loading } = useQuery(QUERY_GET_TESTSETS_WITH_SPORT, {
    variables: { entityId, type: TestSetV2Type.PREVENTION_TEMPLATE }
  });
  const inputSearchRef = useRef();

  useEffect(() => {
    if (data?.getTestSetsV2) {
      setState({
        items: data.getTestSetsV2.map(testSet => new TestSet(testSet)),
        sort: {
          key: 'title',
          desc: false,
          dataType: SORT_DATA_TYPES.STRING
        }
      });
    }
  }, [data]);

  // Loader is set in sessions
  // if (loading && sessionTemplates.length === 0) return <Loader />;

  const onUseTemplateHandler = async template => {
    const { id, ...sessionTemplate } = {
      ...template,
      name: template.title,
      notes: template.note,
      meta: template.meta
    };
    const session = new Session({
      ...sessionTemplate,
      testSetTemplateIds: [template.id],
      type: TestSetV2Type.PREVENTION
    });
    onUse(session);
  };

  const onEditTemplateHandler = id => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT, {
        entityId,
        sessionsTab: 'templates',
        testSetId: id
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="p-tabs__tab-header">
        <FieldInput
          rounded
          type="text"
          icon="search-grey"
          iconLeft
          onChange={e => setState({ search: e.target.value })}
          extraClassNames={classNames('u-margin-bottom-none c-input--search', {
            'c-input--search-filled': inputSearchRef?.current?.value.length > 0
          })}
          ref={inputSearchRef}
        />
        <Button
          mini
          light
          type="reset"
          onClick={() => {
            inputSearchRef.current.value = '';
            setState({ search: inputSearchRef.current.value });

            inputSearchRef.current.focus();
          }}
        >
          <Icon id="close" strokeColor="color-neutral-dark"></Icon>
          <span className="u-hidden-visually">Clear input</span>
        </Button>
      </div>
      {state.search.length > 0 && (
        <MessageText extraClassNames="c-list__filtering-message">
          <FormattedMessage
            {...messages.searchInputGeneralFeedback}
            values={{
              count: state.filteredCount,
              total: state.items.length,
              searchTerm: state.search,
              b: chunks => <b>{chunks}</b>
            }}
          />
        </MessageText>
      )}
      <List>
        <ListBody>
          {state.filteredItems.map(template => (
            <SessionTemplateListItem
              noMaxHeight
              statusIconListItem="template"
              mediumIcon
              entityId={entityId}
              id={template.id}
              key={template.id}
              template={template}
              filteringTerm={state.search}
              filtered={template.filtered}
              onUse={() => {
                onUseTemplateHandler(template);
              }}
              onEdit={() => {
                onEditTemplateHandler(template.id);
              }}
              onClick={() => {
                onEditTemplateHandler(template.id);
              }}
              outfaded={template.outfaded || !template.filtered}
            />
          ))}
        </ListBody>
      </List>
    </>
  );
}

export default PreventionTemplateList;
