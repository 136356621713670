import React, { Fragment, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { withRouter, generatePath, useHistory } from 'react-router-dom';
import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_BENCHMARKS,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import PanelTitleButton from 'components/panel/panel-title/PanelTitleButton';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { MenuWrapper } from 'components/menu';
import Icon from 'components/icon/Icon';
import { Button } from 'components/button';
import {
  QUERY_GET_BENCHMARK_DATA,
  QUERY_EXPORT_BENCHMARKED_DATA
} from 'services/aws/benchmark-query';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { SORT_DATA_TYPES, sort } from 'utils/sort';
import messages from 'messages';
import ResetBenchmarkDataButton from 'containers/partials/buttons/ResetBenchmarkDataButton';
import Modal from 'components/modal/Modal';
import ImportBenchmarkDataCard from 'containers/partials/cards/ImportBenchmarkDataCard';
import { getGQLResult } from 'services/aws/utils/get';
import { appSyncClient } from 'services/aws/app-sync';
import { navigateToUrl } from 'utils/browser';
import { BenchmarkSubTypes, EXPORT_FILES_URL } from 'constants.js';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import { useTestsContext } from 'contexts/TestsContext';
import { useQuery } from '@apollo/client';
import Select, { Option } from 'components/input/Select';
import BenchmarkDataTable from 'containers/pages/settings/benchmarks/BenchmarkDataTable';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const BenchmarkTest = ({ benchmarkId, benchmarkTestId, edit }) => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: {
      user: { rootEntityId }
    },
    uiState
  } = useContext(StoreContext);

  const { push } = useHistory();
  const {
    testActions,
    testsState,
    loading: loadingTests,
    error: errorTests
  } = useTestsContext();

  const [importModal, setImportModal] = useState(false);
  const [benchmark, setBenchmark] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [benchmarkTestItem, setBenchmarkTestItem] = useState(null);
  const [canResetBenchmark, setCanResetBenchmark] = useState(false);
  const [canEditBenchmark, setCanEditBenchmark] = useState(false);
  const [matches, setMatches] = useState([]);
  const [testItem, setTestItem] = useState(null);
  const [benchmarkTableType, setBenchmarkTableType] = useState('');

  useEffect(() => {
    if (!testsState?.allTests) {
      testActions.getTests();
    }
  }, []);

  useEffect(() => {
    if (testsState?.allTests) {
      const matches = testsState.allTests.reduce((ac, test) => {
        if (!test.testItems) {
          return ac.concat([]);
        }
        return ac.concat(
          test.testItems.filter(ti => {
            return ti.id === benchmarkTestId;
          })
        );
      }, []);
      setMatches(matches);
      setTestItem(matches[0]);
    }
  }, [testsState.allTests, benchmarkTestId]);

  const {
    loading: loadingBenchmarkData,
    data: dataBenchmarkData,
    refetch: refetchBenchmarkData
  } = useQuery(QUERY_GET_BENCHMARK_DATA, {
    variables: { benchmarkId, entityId: rootEntityId },
    skip: !benchmarkId
  });

  useEffect(() => {
    if (dataBenchmarkData?.getBenchmark && testItem) {
      const benchmark = dataBenchmarkData.getBenchmark;
      const benchmarkTestItem = getBenchmarkTestItem(benchmark, testItem);
      const benchmarkDataTable = getTableData(benchmarkTestItem);

      setDataTable(benchmarkDataTable);
      setCanResetBenchmark(
        !edit &&
          benchmarkTestItem &&
          ability.can('update', 'Benchmarks') &&
          benchmark.editable
      );
      setCanEditBenchmark(
        !edit && ability.can('update', 'Benchmarks') && benchmark.editable
      );

      setBenchmark(benchmark);
      setBenchmarkTestItem(benchmarkTestItem);

      setBenchmarkTableType(
        benchmarkTestItem?.type ?? BenchmarkSubTypes.STANDARD
      );
    }
  }, [benchmarkId, benchmarkTestId, dataBenchmarkData, testItem]);

  const onCloseImportModal = () => {
    setImportModal(false);
    refetchBenchmarkData();
  };

  const getBenchmarkTestItem = (benchmark, testItem) => {
    if (testItem) {
      const benchmarkTestItems = benchmark.benchmarkTestItems || [];
      return benchmarkTestItems.find(bt => bt.testItem.id === testItem.id);
    }
  };

  const getTableData = benchmarkTestItem => {
    let rawData = [];
    try {
      rawData = JSON.parse(benchmarkTestItem.data) || [];
    } catch (er) {}
    return sort(rawData, {
      keys: [{ key: 'age_min', dataType: SORT_DATA_TYPES.NUMBER }]
    });
  };

  const downloadBenchmarkItemData = async (benchmarkId, testItemId) => {
    try {
      uiState.increasePendingRequest();

      const result = await getGQLResult(
        appSyncClient,
        QUERY_EXPORT_BENCHMARKED_DATA,
        {
          benchmarkId,
          testItemId
        }
      );
      if (result) {
        const url = EXPORT_FILES_URL + result.data.exportBenchmarkItem;
        navigateToUrl(url);
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    uiState.decreasePendingRequest();
  };

  if (!benchmark) return <Loader />;

  return (
    <Panel>
      {loadingTests && <Loader />}
      {errorTests && <RetryPanel />}

      {!matches.length && <RetryPanel />}

      <Fragment>
        {importModal && (
          <Modal
            importBenchmarks
            cardSmall
            autoHeight
            isOpen={true}
            onClose={onCloseImportModal}
          >
            <ImportBenchmarkDataCard
              rootEntityId={rootEntityId}
              benchmarkTestId={benchmarkTestId}
              benchmarkId={benchmarkId}
              onClose={onCloseImportModal}
            />
          </Modal>
        )}
        <PanelHeader>
          <Breadcrumbs>
            <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
              <FormattedMessage {...messages.breadcrumbSettings} />
            </Breadcrumb>
            <Breadcrumb onClick={() => push(ROUTE_SETTINGS_BENCHMARKS)}>
              <FormattedMessage {...messages.breadcrumbBenchmarks} />
            </Breadcrumb>
            <Breadcrumb
              onClick={() =>
                push(
                  generatePath(ROUTE_SETTINGS_BENCHMARKS_BENCHMARK, {
                    benchmarkId
                  })
                )
              }
            >
              {benchmark.title}
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {testItem.title}
              {(canEditBenchmark || canResetBenchmark) && (
                <MenuWrapper trigger={<PanelTitleButton />}>
                  {canEditBenchmark && (
                    <Button
                      menu
                      onClick={() =>
                        push(
                          generatePath(
                            ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST_EDIT,
                            { benchmarkId, benchmarkTestId }
                          )
                        )
                      }
                    >
                      <Icon id="edit" />
                      {benchmarkTestItem ? (
                        <FormattedMessage
                          {...messages.benchmarksBenchmarkMenuItemEditData}
                        />
                      ) : (
                        <FormattedMessage
                          {...messages.benchmarksBenchmarkMenuItemCreateData}
                        />
                      )}
                    </Button>
                  )}
                  {canResetBenchmark && (
                    <ResetBenchmarkDataButton
                      entityId={rootEntityId}
                      benchmarkId={benchmarkId}
                      testItemId={benchmarkTestId}
                      menu
                    >
                      <FormattedMessage
                        {...messages.benchmarksBenchmarkMenuItemResetBenchmarkData}
                      />
                    </ResetBenchmarkDataButton>
                  )}
                  {canEditBenchmark && (
                    <Button menu onClick={() => setImportModal(true)}>
                      <FormattedMessage
                        {...messages.benchmarksBenchmarkMenuItemImportBenchmarkData}
                      />
                      <Icon id="upload" />
                    </Button>
                  )}
                  {canEditBenchmark && (
                    <Button
                      menu
                      onClick={() => {
                        downloadBenchmarkItemData(benchmarkId, benchmarkTestId);
                      }}
                    >
                      <FormattedMessage
                        {...messages.benchmarksBenchmarkMenuItemExportBenchmarkData}
                      />
                      <Icon id="download" />
                    </Button>
                  )}
                </MenuWrapper>
              )}
            </PanelTitle>
            <PanelSubtitle>
              <FormattedMessage
                {...messages.benchmarksBenchmarkTestSubtitle}
                values={{ name: testItem.title }}
              />
            </PanelSubtitle>
          </PanelTitleWrapper>
        </PanelHeader>
        {benchmarkTestItem || edit ? (
          <>
            {dataTable.length === 0 && (
              <Select
                standalone
                label="Select benchmark type"
                value={benchmarkTableType}
                onChange={select => {
                  setBenchmarkTableType(select.value);
                }}
              >
                <Option value="" label="Make a selection" />
                <Option
                  value={BenchmarkSubTypes.STANDARD}
                  label="Standard deviation"
                />
                <Option
                  value={BenchmarkSubTypes.RANGE}
                  label="Range benchmark"
                />
              </Select>
            )}
            <BenchmarkDataTable
              benchmarkId={benchmarkId}
              benchmarkTestId={benchmarkTestId}
              edit={edit}
              dataTable={dataTable}
              type={benchmarkTableType}
            />
          </>
        ) : (
          !loadingBenchmarkData && (
            <p>
              <FormattedMessage
                {...messages.benchmarksBenchmarkTestDefaultBenchmarkInfo}
              />
            </p>
          )
        )}
      </Fragment>
    </Panel>
  );
};

export default withRouter(BenchmarkTest);
