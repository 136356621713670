import { useState } from 'react';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import isSameDay from 'date-fns/isSameDay';
import endOfToday from 'date-fns/endOfToday';
import isToday from 'date-fns/isToday';

const useEditableSession = () => {
  const [isEditable, setIsEditable] = useState(false);

  const checkIfEditable = ({ session, testedDates, date, user }) => {
    if (user.hasAdminRole()) {
      setIsEditable(true);
      return;
    }

    const today = new Date();
    if (session.recurring) {
      // You can't add/edit a testdata if today is after the session enddate
      if (today > session.enddate) {
        setIsEditable(false);
        return;
      }
      // You can't add/edit a testdata if today is before the session startdate
      if (today < session.startdate) {
        setIsEditable(false);
        return;
      }

      // you can't add/edit testdata if the date is in the future (after today)
      if (date > endOfToday()) {
        setIsEditable(false);
        return;
      }

      if (session.interval === 'weekly') {
        const firstDayOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });

        if (date < firstDayOfThisWeek) {
          setIsEditable(false);
          return;
        }

        const firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 });
        const lastDayOfWeek = endOfWeek(date, { weekStartsOn: 1 });

        const testsThisWeek = Object.values(testedDates).filter(testedDate => {
          return testedDate >= firstDayOfWeek && testedDate <= lastDayOfWeek;
        });

        // you can edit testdata if the testdata is on the same day as the date (24h range)
        if (isSameDay(testsThisWeek[0], date)) {
          setIsEditable(true);
          return;
        }
        // you can add/edit testdata if there is no testdata in the week of the date, else you can't
        setIsEditable(testsThisWeek.length === 0);
        return;
      }
      if (session.interval === 'daily') {
        if (isToday(date)) {
          setIsEditable(true);
          return;
        }

        const hasTestOnDay = Object.values(testedDates).find(testedDate => {
          return isSameDay(testedDate, date);
        });
        setIsEditable(!!hasTestOnDay);
        return;
      }
      setIsEditable(false);
      return;
    }

    setIsEditable(true);
  };

  return [isEditable, checkIfEditable];
};

export default useEditableSession;
