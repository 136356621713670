import { PanelBody } from 'components/panel';
import GrowthTableWaiting from 'containers/pages/growthtracker/table/GrowthTableWaiting';
import { useGrowthPanelContext } from 'contexts/GrowthPanelContext';
import GrowthTable from 'containers/pages/growthtracker/table/GrowthTable';

const GrowthPanel = () => {
  const { loading } = useGrowthPanelContext();

  return (
    <PanelBody
      tabIndex="-1" // De scroller veroorzakt een focusable element 🤔
    >
      {loading ? <GrowthTableWaiting /> : <GrowthTable />}
    </PanelBody>
  );
};

export default GrowthPanel;
