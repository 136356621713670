import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

const PageContentWrapper = ({
  horizontalScroller,
  centeredContent,
  //isHomePage,
  isResults,
  isGrowthPrediction,
  isGrowthTracker,
  children
}) => {
  const scrollRef = useRef();

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      resizeHandler();
    }
  }, [scrollRef.current]);

  const resizeHandler = () => {
    const element = scrollRef.current;
    window.requestAnimationFrame(() => {
      element.scrollLeft = element.scrollWidth - element.clientWidth;
    });
  };

  return (
    <div
      className={classNames('c-page__content-wrapper', {
        'c-page__content-center': centeredContent,
        'c-page__content-horizontal-flow': horizontalScroller,
        //'c-page__content-home': isHomePage,
        'c-page__content-benchmark':
          isResults || isGrowthPrediction || isGrowthTracker
      })}
      ref={scrollRef}
    >
      {children}
    </div>
  );
};

export default PageContentWrapper;
