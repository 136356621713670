import classNames from 'classnames';

const RefactoredListItemLabelWrapper = props => {
  const { extraClassNames, children } = props;
  return (
    <div className={classNames('r-list__item-label', extraClassNames)}>
      {children}
    </div>
  );
};

export default RefactoredListItemLabelWrapper;
