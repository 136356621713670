import classNames from 'classnames';

const RefactoredListItemLabel = props => {
  const { extraClassNames, style, children, ...rest } = props;

  return (
    <div
      className={classNames('r-list__item-text', extraClassNames, {})}
      style={style}
      {...rest}
    >
      {children}
    </div>
  );
};

export default RefactoredListItemLabel;
