import { TableCell } from 'components/table';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import ProgressBarLabelBlock from 'components/progress-bar/ProgressBarLabelBlock';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { uid } from 'react-uid';
import { Lateralities, Laterality, ProgressBarValueTypes } from 'constants.js';
import messages from 'messages';
import { useIntl } from 'react-intl';
import {
  getBenchmarkResultLabel,
  getPercentageOfResult
} from 'utils/benchmark';
import ProgressBarLabelSide from 'components/progress-bar/ProgressBarLabelSide';
import { useContext } from 'react';
import { StoreContext } from 'index';

const AverageResultsTableCell = ({
  testItem,
  average,
  onClick,
  laterality
}) => {
  const intl = useIntl();
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  //const val = data.benchmarked ? data.benchmarked : data.values;

  if (!testItem?.id) return <TableCell />;
  if (!testItem?.result) return <TableCell />;

  return (
    <TableCell onClick={() => onClick(testItem, average)}>
      <div className="o-flex">
        {Object.keys(testItem.result).map(side => {
          // Only show 1 side
          if (side !== laterality) return null;

          let labelValue;
          const value = testItem?.result?.[side]?.[0];

          if (testItem.isOption) {
            if (testItem.result?.[side]?.[0]?.labels?.[locale]) {
              labelValue = testItem.result?.[side]?.[0]?.labels[locale];
            } else {
              labelValue = testItem.result?.[side]?.[0]?.label ?? '';
            }
          }
          if (!testItem.isOption && testItem?.result?.[side]?.[0]) {
            labelValue = intl.formatMessage(messages.labelUnit, {
              count: testItem.result[side][0],
              unit: testItem.unit
            });
          }

          return (
            <div
              className="c-table__content c-table__content--avg"
              key={uid(`${testItem.id}_${side}`)}
            >
              <ProgressBarGroup fullWidth clickable>
                <ProgressBarLabelBlock value={labelValue}>
                  {/* Don't show the Side label when Unilateral */}
                  {Number(side) !== Laterality.UNILATERAL &&
                    Lateralities[side] &&
                    labelValue && (
                      <ProgressBarLabelSide>
                        {Lateralities[side]}
                      </ProgressBarLabelSide>
                    )}

                  {value &&
                    average &&
                    getBenchmarkResultLabel(average.avg, testItem.unit).length >
                      0 && (
                      <span
                        style={{
                          float: 'right',
                          fontFamily: 'lato',
                          color: '#7a8198'
                        }}
                      >
                        {intl.formatMessage(
                          messages.benchmarksAverageLabelWithValue,
                          {
                            value: getBenchmarkResultLabel(
                              average.avg,
                              testItem.unit
                            )
                          }
                        )}
                      </span>
                    )}
                </ProgressBarLabelBlock>

                {value &&
                  average &&
                  getBenchmarkResultLabel(average.avg, testItem.unit).length >
                    0 && (
                    <>
                      <ProgressBar
                        benchmarksWithFrame
                        value={getPercentageOfResult(
                          value,
                          average.minScore,
                          average.maxScore
                        )}
                      />
                      <ProgressBar
                        benchmarksWithFrame
                        range={ProgressBarValueTypes.AVERAGE}
                        value={getPercentageOfResult(
                          average.avg,
                          average.minScore,
                          average.maxScore
                        )}
                      />
                    </>
                  )}
              </ProgressBarGroup>
            </div>
          );
        })}
      </div>
    </TableCell>
  );
};

export default AverageResultsTableCell;
