import { useEffect, useState } from 'react';
import { Card, CardBody } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import Select, { Option } from 'components/input/Select';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import TrainingAdviceCategoryCard from 'containers/pages/prevention/TrainingAdviceCategoryCard';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { useSessionContext } from 'contexts/SessionContext';
import { uid } from 'react-uid';
import { useTestDataContext } from 'contexts/TestDataContext';
import ExercisesFetcher from 'containers/pages/exercises/exercises/ExercisesFetcher';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import { useStore } from 'stores/Store';
import ExerciseFilterHeader from 'containers/pages/exercises/exercises/ExerciseFilterHeader';

const TrainingSelectTests = ({ entityId, sporterId, edit }) => {
  const { session, options } = useSessionContext();
  const { groupedResults, fetchTestData } = useTestDataContext();
  const [exerciseSelect, setExerciseSelect] = useState('result');
  const intl = useIntl();
  const { setModalData } = usePreviewModalContext();

  const exercises = useStore(state => state.exercises);
  const selectedProgramExercises = useStore(
    state => state.selectedProgramExercises
  );

  const selectProgramExercises = useStore(
    state => state.selectProgramExercises
  );

  // const { selected, select, setSelected } = useSelectableObject([]);

  useEffect(() => {
    if (session && sporterId) {
      session.getReportForPerson(sporterId);
    }
  }, [session, sporterId]);

  useEffect(() => {
    if (exerciseSelect === 'result') {
      const testData = session.getLastTestData(sporterId);
      if (session?.id && testData?.id) {
        fetchTestData(session.id, testData.id);
      }
    }
  }, [exerciseSelect]);

  return (
    <>
      <Card secondaryCollapsible>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.preventionTrainingFilter} />
          </CardHeaderTitle>
        </CardHeader>
        <CardBody secondary>
          <Select
            label={intl.formatMessage(
              messages.preventionTrainingSelectExercises
            )}
            name={'screening-name-field'}
            value={exerciseSelect}
            onChange={select => {
              setExerciseSelect(select.value);
            }}
          >
            <Option
              key={1}
              value={'result'}
              label={intl.formatMessage(
                messages.preventionTrainingResultsOption
              )}
            />
            <Option
              key={2}
              value={'exercises'}
              label={intl.formatMessage(
                messages.preventionTrainingExercisesOption
              )}
            />
          </Select>
        </CardBody>
      </Card>

      {exerciseSelect === 'exercises' && (
        <ExercisesFetcher entityId={entityId}>
          {({ ...fetcherProps }) => (
            <>
              <ExerciseFilterHeader />
              <ExercisesListVirtualized
                entityId={entityId}
                onModal
                exercises={exercises}
                checkable={edit}
                enablePreviewModal
                onPreview={exercise => {
                  setModalData({ type: 'exercise', data: exercise });
                }}
                onSelect={exercise => {
                  selectProgramExercises(exercise);
                }}
                selectedExercises={selectedProgramExercises}
                {...fetcherProps}
              />
            </>
          )}
        </ExercisesFetcher>
      )}

      {exerciseSelect === 'result' &&
        groupedResults &&
        Object.keys(groupedResults).map((category, i) => (
          <TrainingAdviceCategoryCard
            entityId={entityId}
            key={uid(category, i)}
            category={category}
            tests={groupedResults[category]}
            options={options}
            compressedTable={true}
            entity={entityId}
            onPreview={exercise =>
              setModalData({ type: 'exercise', data: exercise })
            }
            edit={edit}
            isPrev={session.isPrevention}
          />
        ))}
    </>
  );
};

export default TrainingSelectTests;
