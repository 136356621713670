import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Form, Formik } from 'formik';
import { Card, CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import { ROUTE_LOGIN, ROUTE_FORGOT_SUCCESS } from 'routes/RouteList';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import FieldInput from 'components/input/FieldInput';
import { StoreContext } from 'index';
import * as Yup from 'yup';

const ForgotLogin = () => {
  const { authStore } = useContext(StoreContext);
  const { push } = useHistory();

  const onSubmitHandler = async ({ email }) => {
    await authStore.resetPassword(email);
    push(ROUTE_FORGOT_SUCCESS);
  };

  const handleCancel = e => {
    e.preventDefault();

    push(ROUTE_LOGIN);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required()
  });

  return (
    <Card extraClassNames="c-card--primary">
      <CardHeader primary>
        <Button
          tiny
          extraClassNames="c-button--absolute-top-left c-button--large-spacing"
          onClick={e => handleCancel(e)}
        >
          <Icon id="back-nav" mediumIcon />
        </Button>
        <CardHeaderTitle>
          <FormattedMessage {...messages.loginForgotPasswordTitle} />
        </CardHeaderTitle>
        <CardHeaderSubtitle extraClassNames="u-margin-horizontal-auto">
          <FormattedMessage {...messages.loginForgotPasswordSubTitle} />
        </CardHeaderSubtitle>
      </CardHeader>
      <Formik
        initialValues={{ email: '' }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ touched, errors, isValid, dirty }) => {
          return (
            <Form noValidate>
              <CardBody primary>
                <FieldInput
                  id="email"
                  name="email"
                  type="email"
                  errors={errors}
                  touched={touched}
                >
                  <FormattedMessage {...messages.loginUsername} />
                </FieldInput>
              </CardBody>
              <CardFooter primary>
                <ButtonsGroup>
                  <Button secondary onClick={e => handleCancel(e)}>
                    <FormattedMessage {...messages.loginCancelButton} />
                  </Button>
                  <Button primary type="submit" disabled={!(isValid && dirty)}>
                    <FormattedMessage {...messages.loginResetPasswordButton} />
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default ForgotLogin;
