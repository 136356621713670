const findStringInEntity = (entity, str) => {
  const trimmedString = str.toLowerCase().trim();
  const entityName = entity.name.toLowerCase().trim();
  return entityName.indexOf(trimmedString) !== -1;
};

const findStringInClient = (client, str) => {
  const trimmedString = str.toLowerCase().trim();
  const splittedString = trimmedString.split(' ');
  if (splittedString.length === 1) {
    return findStringInClientName(client, trimmedString);
  } else {
    return splittedString.every(s => findStringInClientName(client, s));
  }
};

const findStringInClientName = (client, str) => {
  const clientFirstname = client.firstname.toLowerCase().trim();
  if (clientFirstname.indexOf(str) !== -1) return true;
  const clientLastname = client.lastname.toLowerCase().trim();
  return clientLastname.indexOf(str) !== -1;
};

export const filterEntitiesByYear = (entities, year) => {
  return entities.filter(entity => {
    if (!entity.meta) return false;
    const meta = JSON.parse(entity.meta);
    return meta?.year === year;
  });
};

export const getFilteredGroups = (entities, search) => {
  return !search
    ? entities
    : entities.filter(e => {
        return findStringInEntity(e, search);
      });
};

export const getFilteredUsers = (users, search) => {
  return !search
    ? users
    : users.filter(c => {
        return findStringInClient(c, search);
      });
};
