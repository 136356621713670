import classNames from 'classnames';
import Icon from '../icon/Icon';

const RefactoredListHeaderItem = props => {
  const {
    ascendent,
    descendent,

    onClick,
    children,
    style,
    extraClassNames
  } = props;

  return onClick ? (
    <button
      className={classNames('r-list__header-button', extraClassNames, {
        'r-list__header-button--asc': ascendent
      })}
      onClick={onClick}
      style={style}
      type="button"
    >
      {children}
      {(ascendent || descendent) && <Icon id={'desc'} />}
    </button>
  ) : (
    <div className="r-list__header-button" style={style}>
      {children}
    </div>
  );
};

export default RefactoredListHeaderItem;
