import { useContext } from 'react';
import { generatePath, useHistory, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Panel from 'components/panel/Panel';
import ListItem from 'components/list/list-item/ListItem';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import messages from 'messages';
import {
  ROUTE_SETTINGS_USERS,
  ROUTE_SETTINGS_ORGANISATIONS,
  ROUTE_SETTINGS_GLOBAL,
  ROUTE_SETTINGS_BENCHMARKS,
  ROUTE_SETTINGS_TESTSETS,
  ROUTE_SETTINGS_USAGE,
  ROUTE_SETTINGS_DEMODATA,
  ROUTE_SETTINGS_PROFILE,
  ROUTE_SETTINGS_PROFILE_MFA,
  ROUTE_SETTINGS_EXPORT_DATA
} from 'routes/RouteList';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelHeader,
  PanelBody
} from 'components/panel';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { StoreContext } from 'index';

const OverView = ({ setting }) => {
  const {
    authStore: {
      user,
      user: { config }
    }
  } = useContext(StoreContext);

  const { push } = useHistory();
  const ability = useAbility(AbilityContext);

  return (
    <Panel style={{ overflowX: 'hidden' }}>
      <PanelBody refactor>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.settingsTitle} />
          </PanelTitle>
        </PanelTitleWrapper>
        <List>
          <ListBody>
            <ListItem
              active={setting === 'global'}
              onClick={() => push(ROUTE_SETTINGS_GLOBAL)}
            >
              <ListItemStatus
                mediumIcon
                statusIcon="setup-preferences"
                strokeColor="color-neutral-x-light"
              />
              <ListItemLabelWrapper>
                <ListItemLabel label>
                  <FormattedMessage {...messages.settingsGlobalListItem} />
                </ListItemLabel>
              </ListItemLabelWrapper>
            </ListItem>
            <ListItem
              active={setting === 'users'}
              onClick={() => push(ROUTE_SETTINGS_USERS)}
            >
              <ListItemStatus
                mediumIcon
                statusIcon="users-settings"
                strokeColor="color-neutral-x-light"
              />
              <ListItemLabelWrapper>
                <ListItemLabel label>
                  <FormattedMessage {...messages.settingsUserListItem} />
                </ListItemLabel>
              </ListItemLabelWrapper>
            </ListItem>
            {ability.can('manage', 'Organisations') && (
              <ListItem
                active={setting === 'organisations'}
                onClick={() => push(ROUTE_SETTINGS_ORGANISATIONS)}
              >
                <ListItemStatus
                  mediumIcon
                  statusIcon="school-settings"
                  strokeColor="color-neutral-x-light"
                />
                <ListItemLabelWrapper>
                  <ListItemLabel label>
                    <FormattedMessage
                      {...messages.settingsOrganisationsListItem}
                    />
                  </ListItemLabel>
                </ListItemLabelWrapper>
              </ListItem>
            )}
          </ListBody>
        </List>

        {(ability.can('manage', 'Benchmarks') ||
          ability.can('manage', 'Testsets')) &&
          (config.benchmarksEnabled || config.testSetsEnabled) && (
            <List>
              <ListBody>
                {ability.can('manage', 'Benchmarks') &&
                  config.benchmarksEnabled && (
                    <ListItem
                      active={setting === 'benchmarks'}
                      onClick={() => push(ROUTE_SETTINGS_BENCHMARKS)}
                    >
                      <ListItemStatus statusIcon="benchmarks" />
                      <ListItemLabelWrapper>
                        <ListItemLabel label>
                          <FormattedMessage
                            {...messages.settingsBenchmarksListItem}
                          />
                        </ListItemLabel>
                      </ListItemLabelWrapper>
                    </ListItem>
                  )}
                {ability.can('manage', 'Testsets') &&
                  config.testSetsEnabled && (
                    <ListItem
                      active={setting === 'testsets'}
                      onClick={() =>
                        push(generatePath(ROUTE_SETTINGS_TESTSETS))
                      }
                    >
                      <ListItemStatus
                        mediumIcon
                        statusIcon="archive-drawer"
                        strokeColor="color-neutral-x-light"
                      />
                      <ListItemLabelWrapper>
                        <ListItemLabel label>
                          <FormattedMessage
                            {...messages.settingsTestSetsListItem}
                          />
                        </ListItemLabel>
                      </ListItemLabelWrapper>
                    </ListItem>
                  )}
              </ListBody>
            </List>
          )}
        {ability.can('manage', 'Organisation') && (
          <>
            <PanelTitleWrapper>
              <PanelTitle>
                <FormattedMessage
                  {...messages.settingsOrganisationsListTitle}
                />
              </PanelTitle>
            </PanelTitleWrapper>

            <List>
              <ListBody>
                <ListItem
                  active={setting === 'usage'}
                  onClick={() => push(ROUTE_SETTINGS_USAGE)}
                >
                  <ListItemStatus
                    statusIcon="usage"
                    strokeColor="color-neutral-x-light"
                    extraClassNamesIcon="u-padding-small"
                  />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>
                      <FormattedMessage {...messages.accountUsageLabel} />
                    </ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
                <ListItem
                  active={setting === 'export'}
                  onClick={() => push(ROUTE_SETTINGS_EXPORT_DATA)}
                >
                  <ListItemStatus
                    statusIcon="download-white"
                    strokeColor="color-neutral-x-light"
                    extraClassNamesIcon="u-padding-small"
                  />
                  <ListItemLabelWrapper>
                    <ListItemLabel label>
                      <FormattedMessage {...messages.exportDataLabel} />
                    </ListItemLabel>
                  </ListItemLabelWrapper>
                </ListItem>
                {user.superadmin && (
                  <ListItem
                    active={setting === 'demodata'}
                    onClick={() => push(ROUTE_SETTINGS_DEMODATA)}
                  >
                    <ListItemStatus
                      statusIcon="data-demo"
                      strokeColor="color-neutral-x-light"
                      extraClassNamesIcon="u-padding-small"
                    />
                    <ListItemLabelWrapper>
                      <ListItemLabel label>
                        <FormattedMessage {...messages.accountDemoDataLabel} />
                      </ListItemLabel>
                    </ListItemLabelWrapper>
                  </ListItem>
                )}
              </ListBody>
            </List>
          </>
        )}

        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.settingsProfileTitle} />
          </PanelTitle>
        </PanelTitleWrapper>

        <List>
          <ListBody>
            <ListItem
              active={
                setting === 'profile'
                //   ROUTE_SETTINGS_PROFILE === match.path ||
                //   ROUTE_SETTINGS_PROFILE_EDIT === match.path
              }
              onClick={() => push(ROUTE_SETTINGS_PROFILE)}
            >
              <ListItemStatus
                statusIcon="profile-card"
                strokeColor="color-neutral-x-light"
                extraClassNamesIcon="u-padding-small"
              />
              <ListItemLabelWrapper>
                <ListItemLabel label>
                  <FormattedMessage {...messages.settingsProfilePersonal} />
                </ListItemLabel>
              </ListItemLabelWrapper>
            </ListItem>
            <ListItem
              active={
                setting === 'mfa'
                // ROUTE_SETTINGS_PROFILE === match.path && !match.isExact
              }
              onClick={() => push(ROUTE_SETTINGS_PROFILE_MFA)}
            >
              <ListItemStatus
                statusIcon="unlock"
                strokeColor="color-neutral-x-light"
                extraClassNamesIcon="u-padding-small"
              />
              <ListItemLabelWrapper>
                <ListItemLabel label>
                  <FormattedMessage {...messages.settingsProfileMfa} />
                </ListItemLabel>
              </ListItemLabelWrapper>
            </ListItem>
          </ListBody>
        </List>
      </PanelBody>
    </Panel>
  );
};

export default withRouter(OverView);
