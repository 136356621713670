import React, { useEffect, useState } from 'react';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import TabLink from 'components/tabs/TabLink';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import {
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useTestDataContext } from 'contexts/TestDataContext';
import ReportCard from 'containers/partials/cards/ReportCard';
import EditTestResultCard from 'containers/partials/cards/EditTestResultCard';
import { TestsProvider } from 'contexts/TestsContext';
import { TestSetContextProvider } from 'contexts/TestSetContext';
import ScreeningDetailCard from 'containers/partials/cards/ScreeningDetailCard';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useMedicalContext } from 'contexts/MedicalContext';
import Loader from 'components/loader/Loader';
import useEdit from 'hooks/utils/useEdit';

const ScreeningTabs = () => {
  const { medical } = useMedicalContext();
  const { testDataLoading, hasResults } = useTestDataContext();
  const { entityId, sessionId, groupId, sporterId, injuryId, testDataId } =
    useParams();
  const [personInjury, setPersonInjury] = useState({});

  const [tabIndex, setTabIndex] = useTabIndex([
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA
    ],
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT
    ],
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT
    ]
  ]);

  const [edit] = useEdit(
    useRouteMatch([
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT
    ])?.isExact,
    true
  );

  useEffect(() => {
    if (medical?.injuries) {
      setPersonInjury(medical.injuries.find(i => i.id === injuryId));
    }
  }, [medical, injuryId]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <TabLink
          key="screening.report"
          to={
            testDataId
              ? generatePath(
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
                  {
                    entityId,
                    groupId,
                    sporterId,
                    medicalId: medical.id,
                    injuryId,
                    sessionId,
                    testDataId
                  }
                )
              : generatePath(
                  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
                  {
                    entityId,
                    groupId,
                    sporterId,
                    medicalId: medical.id,
                    injuryId,
                    sessionId
                  }
                )
          }
          disabled={!hasResults()}
        >
          <FormattedMessage {...messages.rehabScreeningReportTab} />
        </TabLink>
        <TabLink
          key="screening.results"
          to={generatePath(
            ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
            {
              entityId,
              groupId,
              sporterId,
              medicalId: medical.id,
              injuryId,
              sessionId
            }
          )}
        >
          <FormattedMessage {...messages.rehabScreeningResultsTab} />
        </TabLink>
        <TabLink
          key="screening.detail"
          to={generatePath(
            ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
            {
              entityId,
              groupId,
              sporterId,
              medicalId: medical.id,
              injuryId,
              sessionId
            }
          )}
        >
          <FormattedMessage {...messages.rehabScreeningDetailsTab} />
        </TabLink>
      </TabList>
      <TabPanel key="screening.report">
        <TabPanelBody withScroller>
          {testDataLoading ? (
            <Loader />
          ) : (
            <ReportCard personInjury={personInjury} compressed />
          )}
        </TabPanelBody>
      </TabPanel>
      <TabPanel key="screening.results">
        <TabPanelBody withScroller>
          {testDataLoading ? (
            <Loader />
          ) : (
            <EditTestResultCard
              entityId={entityId}
              sporterId={sporterId}
              personInjury={personInjury}
              edit={edit}
              rootPath={generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
                {
                  entityId,
                  groupId,
                  sporterId,
                  medicalId: medical.id,
                  injuryId,
                  sessionId
                }
              )}
            />
          )}
        </TabPanelBody>
      </TabPanel>
      <TabPanel key="screening.detail">
        <TabPanelBody withScroller>
          <TestsProvider entityId={entityId}>
            <TestSetContextProvider>
              <ScreeningDetailCard
                key={sessionId}
                personInjury={personInjury}
                edit={false}
              />
            </TestSetContextProvider>
          </TestsProvider>
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

export default ScreeningTabs;
