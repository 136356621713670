import React from 'react';
import classNames from 'classnames';

import './PageLayout.scss';

const PageLayout = props => {
  const { branding = 'hylyght', loginPage, children } = props;
  return (
    <div
      className={classNames(`c-page c-page--login-${branding.toLowerCase()}`, {
        'c-page--login': loginPage
      })}
      data-qa={`page-${loginPage ? 'public' : 'private'}`}
    >
      {children}
    </div>
  );
};

PageLayout.propTypes = {};

export default PageLayout;
