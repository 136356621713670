import { useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { useIntl } from 'react-intl';
import Panel from 'components/panel/Panel';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_POOL,
  ROUTE_SPORTERS_ENTITY_SPORTER
} from 'routes/RouteList';
import messages from 'messages';
import { PanelBody } from 'components/panel';
import useTabIndex from 'hooks/utils/useTabIndex';
import PersonsTabs from 'containers/pages/persons/PersonsTabs';
import OverviewHeader from 'containers/pages/persons/OverviewHeader';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const OverView = () => {
  const intl = useIntl();
  let location = useLocation();
  const { entityId, groupId, sporterId } = useParams();
  const { push } = useHistory();
  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_POOL,
    ROUTE_SPORTERS_ENTITY
  ]);

  const group = useEntityContext(s => s.group);
  const setGroup = useEntityContext(s => s.setGroup);
  const clearSelected = useEntityContext(s => s.clearSelected);

  const [tabIndex, setTabIndex] = useTabIndex(
    [ROUTE_SPORTERS_ENTITY, ROUTE_SPORTERS_ENTITY_POOL],
    false
  );

  const [panelTitle, setPanelTitle] = useState({ title: '', subTitle: '' });

  const isRootGroup = !groupId && groupId !== 'all' && entityId !== groupId;
  const isPool = active.path === ROUTE_SPORTERS_ENTITY_POOL;

  useEffect(() => {
    return () => {
      clearSelected();
    };
  }, []);

  useEffect(() => {
    setPanelTitle({
      title: isRootGroup
        ? intl.formatMessage(messages.titleGroups)
        : isPool
          ? intl.formatMessage(messages.titlePool)
          : intl.formatMessage(messages.titleSporters, {
              inGroup: true,
              group: group?.name
            }),
      subTitle: intl.formatMessage(messages.subtitleSporters, {
        inGroup: !isRootGroup && !isPool,
        root: isRootGroup,
        group: group?.name
      })
    });
  }, [group]);

  useEffect(() => {
    if ((!groupId || groupId === 'all') && sporterId && tabIndex > 0) {
      setGroup({ id: entityId });
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onPersonClickHandler = (item, type) => {
    if (type === 'group') {
      push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId: item.id }));
    } else {
      if (item.id !== sporterId) {
        push(
          generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
            entityId,
            groupId,
            sporterId: item.id
          })
        );
      }
    }
  };

  const onBreadcrumbClickedHandler = groupId => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY, {
        entityId,
        groupId: entityId === groupId ? null : groupId
      })
    );
  };

  const onTabClickHandler = (tab, index) => {
    setTabIndex(index);
    if (tab === 'athletes') {
      push(generatePath(ROUTE_SPORTERS_ENTITY, { entityId }));
    }
    if (tab === 'pool') {
      setGroup({ id: entityId });
      push(
        generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId,
          groupId: 'all'
        })
      );
    }
  };

  return (
    <>
      <Panel active={active.isExact}>
        <OverviewHeader panelTitle={panelTitle} activeTab={tabIndex} />

        <PanelBody>
          <PersonsTabs
            tabIndex={tabIndex}
            onClick={onPersonClickHandler}
            onBreadcrumbClicked={onBreadcrumbClickedHandler}
            onTabClick={onTabClickHandler}
            showPersonStatus={true}
            checkableGroups={tabIndex === 0}
          />
        </PanelBody>
      </Panel>
    </>
  );
};

export default OverView;
