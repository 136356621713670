import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import SessionForm from '../prevention/SessionForm';
import { TestsProvider } from 'contexts/TestsContext';
import Loader from 'components/loader/Loader';
import { generatePath, useHistory } from 'react-router-dom';
import {
  ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION,
  ROUTE_SESSIONS_ENTITY_SESSION
} from 'routes/RouteList';
import SessionDetailExtras from 'containers/pages/sessions/SessionDetailExtras';
import SessionDetailInput from 'containers/pages/sessions/SessionDetailInput';
import RecurringSessionDetailExtras from 'containers/pages/sessions/RecurringSessionDetailExtras';
import PreventionDetailExtras from 'containers/pages/prevention/PreventionDetailExtras';
import { useState, useEffect } from 'react';
import useTestSet from 'hooks/queries/useTestSet';
import Session from 'models/Session';
import { SessionTypes } from 'constants.js';

function AddSessionModal({ entityId, title, type, onClose, session, isCopy }) {
  const { fetchTestSetTests } = useTestSet({
    entityId
  });
  const { push } = useHistory();
  const [newSession, setNewSession] = useState(session);

  useEffect(() => {
    const fetch = async () => {
      const data = await fetchTestSetTests({
        testSetId: session.testSetTemplateIds[0]
      });
      setNewSession(new Session({ ...session, tests: data.tests }));
    };
    if (session?.testSetTemplateIds?.[0]) {
      fetch();
    }

    setNewSession(new Session({ ...session }));
  }, [session]);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const onCompleteHandler = id => {
    if (onClose) onClose();
    if (id) {
      if (type === SessionTypes.PREVENTION) {
        push(
          generatePath(ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION, {
            entityId,
            sessionId: id
          })
        );
      } else {
        push(
          generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
            entityId,
            sessionsTab: type === 'recurring' ? 'recurring' : 'sessions',
            sessionId: id
          })
        );
      }
    }
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <TestsProvider entityId={entityId}>
        <SessionForm
          entityId={entityId}
          session={newSession}
          onComplete={onCompleteHandler}
        >
          {({ isSubmitting }) => (
            <>
              {isSubmitting && <Loader />}
              <CardHeader modal>
                <CardHeaderTitle>{title}</CardHeaderTitle>
              </CardHeader>
              <CardBody modalWithOverflow>
                <SessionDetailInput edit type={type} />
              </CardBody>
              <CardBody modalWithOverflow>
                {type === SessionTypes.SESSION && (
                  <SessionDetailExtras entityId={entityId} edit />
                )}
                {type === SessionTypes.RECURRING && (
                  <RecurringSessionDetailExtras entityId={entityId} edit />
                )}
                {type === SessionTypes.PREVENTION && (
                  <PreventionDetailExtras entityId={entityId} edit={!isCopy} />
                )}
              </CardBody>
              <CardFooter modal extraClassNames="c-card__footer--modal-larger">
                <ButtonsGroup>
                  <Button secondary onClick={() => onCloseHandler()}>
                    <FormattedMessage {...messages.cancelButton} />
                  </Button>
                  <Button type="submit" primary disabled={isSubmitting}>
                    <FormattedMessage {...messages.saveButton} />
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </>
          )}
        </SessionForm>
      </TestsProvider>
    </Modal>
  );
}

export default AddSessionModal;
