import classNames from 'classnames';
import { forwardRef } from 'react';

const ReadOnlyInput = forwardRef((props, ref) => {
  const { label, children, value, style } = props;

  return (
    <div className={classNames('c-input__group c-input__group--readonly', {})}>
      {label && <div className="c-input__label">{label}</div>}
      <div className="c-input__wrapper">
        <p className="c-input" style={style} ref={ref}>
          {value}
        </p>
        {children}
      </div>
    </div>
  );
});
export default ReadOnlyInput;
