import classNames from 'classnames';

const RefactoredListHeader = props => {
  const { extraClassNames, children, sticky, style } = props;

  return (
    <div
      className={classNames('r-list__header', extraClassNames, {
        'r-list__header--sticky': sticky
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export default RefactoredListHeader;
