import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { MUTATION_ARCHIVE_PROGRAM } from 'services/aws/programs-query';
import usePrograms from 'hooks/queries/usePrograms';

const RemoveProgramButton = ({
  entityId,
  programId,
  children,
  onComplete,
  ...props
}) => {
  const { push } = useHistory();
  const { fetchPrograms } = usePrograms(entityId);

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_PROGRAM}
      confirmTitle={messages.modalConfirmRemoveProgramTitle}
      confirmMessage={messages.modalConfirmRemoveProgramMessage}
      variables={{
        programId
      }}
      update={async (cache, { data: { archiveExerciseProgram } }) => {
        if (archiveExerciseProgram) {
          await fetchPrograms({ fetchPolicy: 'network-only' });
          push(ROUTE_EXERCISES_PROGRAMS_ENTITY.replace(':entityId', entityId));
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveProgramButton;
