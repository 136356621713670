import React from 'react';
import Card from 'components/card/Card';
import CardBody from 'components/card/CardBody';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';

const EmptyReportCard = () => {
  return (
    <Card reports>
      <CardBody secondary>
        <p>
          <FormattedMessage {...messages.emptySessionForAthlete} />
        </p>
      </CardBody>
    </Card>
  );
};

export default EmptyReportCard;
