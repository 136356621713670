import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { CardBody, CardFooter } from 'components/card';
import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import { Button, ButtonsGroup } from 'components/button';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';

const GenerateQRCodeModal = ({ entityId, persons, session, type, onClose }) => {
  const printArea = useRef(null);
  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  const getQRObject = (type, sporter) => {
    if (type === 'scanner') {
      const code = `P${sporter.firstname} ${sporter.lastname} ${format(sporter.birthdate, 'ddLLyyyy')}`;
      // append 1 chars until string is 43 bytes long and append a new line
      const codeWithPadding = `${code} ${'1'.repeat(43 - code.length)}\n`;
      console.log(codeWithPadding);
      return codeWithPadding;
      // return `P${sporter.firstname} ${sporter.lastname} ${format(sporter.birthdate, 'ddLLyyyy')}`;
    } else {
      return JSON.stringify({
        e: entityId,
        p: sporter.id,
        s: session?.id || ''
      });
    }
  };

  const printQRs = () => {
    const printElement = printArea.current.innerHTML;
    const originalContents = document.body.innerHTML;

    // Create a style element for print
    const printStyle = `
                <style>
                    body {
                        margin: 0;
                        padding: 0;
                        font-family: Arial, sans-serif;
                    }
                    .qr-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                    .qr-code {
                        width: 30%; /* Adjust based on your needs */
                        margin-bottom: 20px; /* Space between rows */
                    }
                    @media print {
                        .qr-container {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;
                        }
                        .qr-code {
                            width: 30%; /* Maintain the same width during print */
                            margin: 0; /* Remove margin for printing */
                            page-break-inside: avoid; /* Prevent page breaks inside QR codes */
                        }
                        .no-print {
                            display: none; /* Hide non-printable content */
                        }
                    }
                </style>
            `;

    document.body.innerHTML = printStyle + printElement;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <Modal card largeBody isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.modalQRCodesTitle} />
        </CardHeaderTitle>
      </CardHeader>

      <CardBody modal>
        <div className="" ref={printArea}>
          <div className="o-layout o-layout--tiny qr-container">
            {persons.map(sporter => {
              return (
                <div
                  className="o-layout__item u-1-of-3-at-small qr-code"
                  key={sporter.id}
                  style={{ marginBottom: '80px' }}
                >
                  <div>
                    <h3>
                      {sporter.firstname} {sporter.lastname}
                    </h3>
                    <QRCodeSVG value={getQRObject(type, sporter)} size={180} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </CardBody>
      <CardFooter modal extraClassNames="c-card__footer--modal-larger">
        <ButtonsGroup>
          <Button type="button" primary onClick={printQRs}>
            <FormattedMessage {...messages.buttonPrint} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default GenerateQRCodeModal;
