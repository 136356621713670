import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import classNames from 'classnames';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemOrder,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import FieldInput from 'components/input/FieldInput';

const Cues = ({ label, readOnly, onChange, ...props }) => {
  const [cues, setCues] = useState();

  useEffect(() => {
    setCues(props?.cues || []);
  }, [props.cues]);

  const onChangeHandler = (cue, index, e) => {
    e.preventDefault();
    const cuesCopy = [...cues];
    cuesCopy[index] = cue;
    setCues([...cuesCopy]);
    onChange(cuesCopy);
  };

  const onDeleteHandler = (index, e) => {
    e.preventDefault();
    const cuesCopy = [...cues];
    cuesCopy.splice(index, 1);
    setCues([...cuesCopy]);
    onChange(cuesCopy);
  };

  const onAddHandler = e => {
    e.preventDefault();

    if (cues?.length > 0) {
      const cueInput = document.getElementById(`cue_${cues.length - 1}`);
      if (cueInput['value'].length > 0) {
        setCues([...(cues || []), '']);
        onChange(cues);

        setTimeout(() => {
          document.getElementById(`cue_${[...cues].length - 1}`).focus();
        }, 100);
      } else {
        cueInput.focus();
      }
    } else {
      const newCues = [...(cues || []), ''];
      setCues(newCues);
      onChange(newCues);
      setTimeout(() => {
        document.getElementById(`cue_${[...newCues].length - 1}`).focus();
      }, 100);
    }
  };

  const onEnterHandler = el => {
    const currentListItem = el.current;
    const currentInputValueLength =
      currentListItem.querySelector('input').value.length;

    const nextListItemsInputIsFilled = () => {
      return (
        currentListItem.nextElementSibling?.hasChildNodes('input') &&
        currentListItem.nextElementSibling?.querySelector('input')?.value
          ?.length !== 0
      );
    };

    if (currentInputValueLength > 0 && nextListItemsInputIsFilled()) {
      setCues([...(cues || []), '']);
      onChange(cues);

      let clearable = false;
      const findInput = () => {
        if (clearable) clearInterval(interval);

        if (el.current.nextElementSibling) {
          el.current.nextElementSibling.querySelector('input').focus();
          clearable = true;
        }
      };

      const interval = setInterval(findInput, 100, el, this);
    } else {
      if (
        el.current.nextElementSibling &&
        el.current.nextElementSibling.querySelector('input')
      ) {
        el.current.nextElementSibling.querySelector('input').focus();
      }
    }
  };

  const onBackspaceHandler = (el, index) => {
    const currentListItem = el.current;
    const currentInputValueLength =
      currentListItem.querySelector('input').value.length;

    if (currentInputValueLength <= 0 && index > 0) {
      const cuesCopy = [...cues];
      cuesCopy.splice(index, 1);
      setCues(cuesCopy);
      onChange(cuesCopy);

      setTimeout(() => {
        document.getElementById(`cue_${index - 1}`).focus();
      }, 100);
    }
  };

  return (
    ((cues && cues.length > 0) || !readOnly) && (
      <>
        {label && (
          <label className={classNames('c-input__label', {})}>{label}</label>
        )}
        <List cue>
          <ListBody>
            {cues &&
              cues.length > 0 &&
              cues.map((cue, index) => (
                <CueLine
                  cue={cue}
                  key={`cue_${index}`}
                  index={index}
                  readOnly={readOnly}
                  onChange={e => onChangeHandler(e.target.value, index, e)}
                  onDelete={e => onDeleteHandler(index, e)}
                  onKeyEnter={el => onEnterHandler(el)}
                  onKeyBackspace={el => onBackspaceHandler(el, index)}
                />
              ))}
            {!readOnly && (
              <Button small ghost onClick={onAddHandler}>
                <Icon id="add" />
                <FormattedMessage {...messages.buttonAddCue} />
              </Button>
            )}
          </ListBody>
        </List>
      </>
    )
  );
};

export default Cues;

const CueLine = ({
  cue = '',
  index,
  readOnly,
  onDelete,
  onChange,
  onKeyEnter,
  onKeyBackspace
}) => {
  const listItemRef = useRef();

  return (
    <ListItem
      ref={listItemRef}
      small={readOnly}
      smallEditable={!readOnly}
      key={`cue_${index}`}
    >
      <ListItemOrder>{index + 1}</ListItemOrder>
      <ListItemLabelWrapper>
        {readOnly ? (
          <ListItemLabel label>{cue}</ListItemLabel>
        ) : (
          <>
            <FieldInput
              useNative
              id={`cue_${index}`}
              value={cue}
              onChange={onChange}
              onKeyEnter={() => onKeyEnter(listItemRef)}
              onKeyBackspace={() => onKeyBackspace(listItemRef)}
            />
          </>
        )}
      </ListItemLabelWrapper>
      {!readOnly && (
        <Button tiny onClick={onDelete} role="button">
          <Icon id="delete" />
        </Button>
      )}
    </ListItem>
  );
};
