import { useContext, useEffect, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { TabList, Tab, TabPanel, Tabs, TabPanelBody } from 'components/tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import { Panel, PanelHeader, PanelSubtitle } from 'components/panel';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelTitleButton
} from 'components/panel/panel-title';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';

import {
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE,
  ROUTE_USER_ENTITY_PROGRAMS,
  ROUTE_USER_ENTITY_PROGRAMS_PROGRAM
} from 'routes/RouteList';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { MenuWrapper } from 'components/menu';
import RemoveExerciseButton from 'containers/partials/buttons/RemoveExerciseButton';
import { StoreContext } from 'index';
import Loader from 'components/loader/Loader';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import CardBody from 'components/card/CardBody';
import Cues from 'containers/partials/inputs/Cues';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import { sortByArray } from 'utils/sort';
import MediaViewer from 'containers/partials/media/MediaViewer';
import useExercise from 'hooks/queries/useExercise';
import { useStore } from 'stores/Store';
import { isMzhUserAgent } from 'utils/browser';

const getNote = (exercise, notes) => {
  if (notes && notes.length > 0) {
    return notes[0].note;
  }
  if (exercise?.note) {
    return exercise.note;
  }
  return '';
};

const ProgramExercise = ({ tabIndex }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId, exerciseId, programId } = useParams();
  const { push } = useHistory();
  const intl = useIntl();

  const program = useStore(state => state.program);
  const { loading, error, fetchExercise } = useExercise(entityId);
  const [exercise, setExercise] = useState(null);
  const [exerciseIndex, setExerciseIndex] = useState(null);

  const active = useRouteMatch([
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE
  ]);

  useEffect(() => {
    const getExercise = async () => {
      const exercise = await fetchExercise(exerciseId);
      setExercise(exercise);
    };
    getExercise();
  }, [exerciseId]);

  useEffect(() => {
    if (program?.exercises) {
      const index = program.exercises.findIndex(e => e.id === exerciseId);
      setExerciseIndex(index);
    }
  }, [exerciseId, program]);

  if (loading || !exercise)
    return (
      <Panel active={active?.isExact}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active?.isExact}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_USER_ENTITY_PROGRAMS, {
              entityId,
              programId
            })}
          >
            <FormattedMessage {...messages.breadcrumbPrograms} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, {
              entityId,
              programId
            })}
          >
            <FormattedMessage {...messages.breadcrumbProgram} />
          </Breadcrumb>
        </Breadcrumbs>

        {isMzhUserAgent() && (
          <div
            className="c-panel__title shown-at-tiny"
            style={{ width: '100%' }}
          >
            <Button
              tiny
              onClick={() =>
                push(
                  generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, {
                    entityId,
                    programId
                  })
                )
              }
            >
              <Icon mediumIcon id="arrow-right" mirrored />
              <span className="u-margin-right-small">Terug naar overzicht</span>
            </Button>
          </div>
        )}

        <PanelTitleWrapper>
          <PanelTitle>
            {isMzhUserAgent() && (
              <Button
                extraClassNames="shown-till-tiny"
                tiny
                onClick={() =>
                  push(
                    generatePath(ROUTE_USER_ENTITY_PROGRAMS_PROGRAM, {
                      entityId,
                      programId
                    })
                  )
                }
              >
                <Icon mediumIcon id="arrow-right" mirrored />
              </Button>
            )}
            <span title={exercise.title}>{exercise.title}</span>

            {user.canRemoveExercises(entityId) && exercise.editable && (
              <MenuWrapper trigger={<PanelTitleButton />}>
                <RemoveExerciseButton
                  entityId={entityId}
                  exerciseId={exerciseId}
                />
              </MenuWrapper>
            )}
          </PanelTitle>

          <PanelSubtitle>
            <FormattedMessage
              {...messages.exercisesLabelLevelSubtitle}
              values={{ level: exercise.level }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody withFixedButtonsBottom>
        <Tabs defaultIndex={tabIndex}>
          <TabList>
            <Tab>
              <FormattedMessage {...messages.breadcrumbExercise} />
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelBody withScroller>
              <Card secondary multiBody>
                <CardHeader extraClassNames="u-margin-bottom-none" secondary>
                  <CardHeaderText>
                    <CardHeaderTitle>
                      <FormattedMessage {...messages.exercisesLabelNotes} />
                    </CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>

                <CardBody secondary separatorBottom>
                  {/* NOTES */}
                  <div
                    className={
                      'c-input__group c-input--closed c-input--read-only'
                    }
                  >
                    <div className="c-input--textarea-readonly">
                      {getNote(exercise, program?.notes[exercise.id])}
                    </div>
                  </div>
                </CardBody>
                {/* MEDIA */}
                <CardBody
                  secondary
                  separatorBottom
                  extraClassNames={'c-card__body--media'}
                >
                  <div className="c-media c-media--main">
                    <MediaViewer
                      accentColor={isMzhUserAgent() && '#23387c'}
                      entityId={entityId}
                      id={exercise.id}
                      media={
                        exercise.media.length > 0
                          ? exercise.meta?.order
                            ? sortByArray(exercise.media, exercise.meta.order)
                            : exercise.media
                          : []
                      }
                    />
                  </div>
                </CardBody>
                <CardHeader extraClassNames="u-margin-bottom-none" secondary>
                  <CardHeaderText>
                    <CardHeaderTitle>
                      <FormattedMessage {...messages.exercisesLabelCues} />
                    </CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary separatorBottom>
                  {/* CUES */}
                  <Cues
                    entityId={entityId}
                    cues={exercise.cues}
                    readOnly={true}
                  />
                </CardBody>
              </Card>
            </TabPanelBody>
          </TabPanel>
        </Tabs>

        <ButtonsGroup extraClassNames="c-button__group-fixed-bottom">
          <Button
            data-label={isMzhUserAgent && intl.formatMessage(messages.previous)}
            circularLarge
            primaryDark
            disabled={exerciseIndex === 0}
            onClick={() => {
              const prevExercise = program.exercises[exerciseIndex - 1];
              push(
                generatePath(
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE,
                  {
                    entityId,
                    programId,
                    exerciseId: prevExercise.id
                  }
                )
              );
            }}
          >
            <Icon
              id="play"
              fillColor="color-neutral-x-light"
              hugeIcon
              mirrored
            />
          </Button>
          <Button
            data-label={isMzhUserAgent && intl.formatMessage(messages.next)}
            circularLarge
            primaryDark
            disabled={exerciseIndex === program.exercises.length - 1}
            onClick={() => {
              const nextExercise = program.exercises[exerciseIndex + 1];
              push(
                generatePath(
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE,
                  {
                    entityId,
                    programId,
                    exerciseId: nextExercise.id
                  }
                )
              );
            }}
          >
            <Icon id="play" fillColor="color-neutral-x-light" hugeIcon />
          </Button>
          {/* TODO:
            // 1. hoeveel keer is deze oefening gedaan: Teller. Eerste keer tonen we geen badge.
            // 2. `Done` in translations.
            */}
          {/*
          <Button primary>
            <FormattedMessage {...messages.markAsDone} />
          </Button>*/}
        </ButtonsGroup>
      </PanelBody>
    </Panel>
  );
};

export default ProgramExercise;
