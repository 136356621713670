import React, { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE } from 'routes/RouteList';
import { StoreContext } from 'index';

function ExerciseEditLanguagesButton({ entityId, exerciseId }) {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const { push } = useHistory();

  const onSelectLanguageHandler = () => {
    push(
      generatePath(ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE, {
        entityId,
        tab: 'all',
        exerciseId,
        lang: locale
      })
    );
  };

  return (
    <Button
      rounded
      primary
      onClick={() => onSelectLanguageHandler()}
      extraClassNames="u-margin-left-auto"
    >
      <Icon id="language" strokeColor="color-neutral-x-light" />
      Edit Translations
    </Button>
  );
}

export default ExerciseEditLanguagesButton;
