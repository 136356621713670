import { generatePath, useHistory, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS } from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import SporterTabsLegacy from 'containers/partials/cards/SporterReportCard';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useSessionContext } from 'contexts/SessionContext';

const SessionTalentV1 = () => {
  const { push } = useHistory();
  const { entityId, sessionId, sporterId, testDataId } = useParams();
  const { session } = useSessionContext();
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS
  ]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
      <TabList>
        <Tab
          key="session.sporter.data"
          onClick={() =>
            push(
              generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                {
                  entityId,
                  sporterId,
                  sessionId
                }
              )
            )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </Tab>
      </TabList>

      <TabPanel key="session.sporter.data">
        <TabPanelBody withScroller>
          <SporterTabsLegacy
            testDataId={testDataId}
            entityId={entityId}
            rootEntityId={entityId}
            session={session}
          />
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

export default SessionTalentV1;
