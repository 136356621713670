import { makeAutoObservable } from 'mobx';
import { ROUTE_NO_ACCESS, ROUTE_SIGNUP, ROUTE_TOTP } from 'routes/RouteList';
import * as api from '../services/aws/api';
import * as appSync from '../services/aws/app-sync';
import User from '../models/User';
import { GLOBAL_TESTER_LOGIN, GLOBAL_TESTER_PASSWORD } from 'constants.js';
import { appSyncClient } from 'services/aws/app-sync';
import { createMongoAbility } from '@casl/ability';
import * as Sentry from '@sentry/react';
import Entity from 'models/Entity';
import { getEntityFromUrl } from 'utils/url';
import { useStore } from 'stores/Store';

export default class AuthStore {
  isLoggedIn = false;
  entityId = '';
  tmpCognitoUser = null;
  initLoginStateChanged = null;
  user = null;
  passwordResetEmail = '';
  roles = null;
  locale = 'en';

  entity = null;

  abilities = null;

  constructor(routerStore, uiState) {
    this.routerStore = routerStore;
    this.uiState = uiState;

    // check if we can get an entity from the URL or localstorage
    let storage = localStorage.authStore;
    if (storage) {
      storage = JSON.parse(storage);
      this.isLoggedIn = storage.isLoggedIn ?? false;
      this.entityId = storage.entityId ?? null;
    }

    const urlEntity = getEntityFromUrl(window.location);
    if (urlEntity && urlEntity !== this.entityId) {
      this.entityId = urlEntity;
    }

    this.setAbilities();

    makeAutoObservable(this);
  }

  async connect() {
    await api.connect();
    return await api.setClient();
  }

  async setLoginState() {
    if (this.isLoggedIn) {
      this.uiState.increasePendingRequest();
      const user = await api.getCurrentUser();
      if (user && !user.error) {
        if (!user?.data?.getMe) {
          return this.loginAPIFailed();
        }
        await this.setUserData(user.data.getMe, true);
        this.redirectToHome();
      } else {
        if (user.error && user.error.message === 'api-error') {
          return this.loginAPIFailed();
        }
        this.initLoginFailed();
      }
      this.uiState.decreasePendingRequest();
    }
    this.setInitLoginStateChanged(true);
  }

  async doLogin(email, password, token) {
    try {
      const redirect = localStorage.getItem('redirect');
      localStorage.clear();
      if (redirect) {
        localStorage.setItem('redirect', redirect);
      }
    } catch (er) {}
    this.uiState.increasePendingRequest();
    let user;
    if (token) {
      user = await api.loginWithToken(email, token);
    } else {
      user = await api.login(email, password);
    }

    if (user && !user.error) {
      await this.authSuccess(user);
    } else {
      if (user.error && user.error.message === 'api-error') {
        this.loginAPIFailed();
      } else {
        this.loginFail();
      }
    }
    this.uiState.decreasePendingRequest();
    return user;
  }

  async doLoginWithTotpCode(totp) {
    this.uiState.increasePendingRequest();
    if (this.tmpCognitoUser) {
      const user = await api.loginWithTotpCode(this.tmpCognitoUser, totp);
      if (user && !user.error) {
        this.uiState.decreasePendingRequest();
        await this.authSuccess(user);
      } else {
        if (user.error && user.error.message === 'api-error') {
          this.loginAPIFailed();
        } else {
          this.loginFail();
        }
        this.uiState.decreasePendingRequest();
      }
      return user;
    }
  }

  async authSuccess(user) {
    if (user.newPasswordRequired) {
      this.uiState.decreasePendingRequest();
      this.setTmpCognitoUser(user.cognitoUser);
      this.routerStore.push(ROUTE_SIGNUP);
    } else if (user.mfaSetup) {
      // TODO: implement mfa setup
    } else if (user.mfaRequired) {
      this.uiState.decreasePendingRequest();
      this.setTmpCognitoUser(user.cognitoUser);
      this.routerStore.push(ROUTE_TOTP);
    } else {
      if (user?.data?.getMe) {
        await this.setUserData(user.data.getMe);
        this.redirectToHome();
      }
    }
  }

  async renewRoles() {
    const result = await api.getRoles(this.user.rootEntityId, this.user.id);

    if (!result.error) {
      this.roles = result;
      this.user.setRoles(this.roles);
    } else {
      this.roles = null;
    }
  }

  async setAbilities() {
    this.abilities = createMongoAbility([]);
  }

  async updateAbilities(user, entity) {
    const abilities = user.setAbilities(entity);

    this.abilities.update(abilities);
  }

  async renewEntities() {
    await api.getMe();
  }

  noEntities = async () => {
    this.uiState.decreasePendingRequest();
    await api.logout();

    this.isLoggedIn = false;
    this.user = null;
    this.routerStore.push(ROUTE_NO_ACCESS);

    try {
      localStorage.setItem('authStore', JSON.stringify({ isLoggedIn: false }));
    } catch (er) {}
  };

  async setUserData(user) {
    this.user = new User(user);

    this.uiState.increasePendingRequest();
    let roles;
    if (this.entityId) {
      roles = await api.getRoles(this.entityId, this.user.id);
    }

    if (!roles || roles.length === 0) {
      const rootOrg = await api.getRootOrganisation();
      this.entityId = rootOrg.id;
      roles = await api.getRoles(this.entityId, this.user.id);
    }

    if (!roles || roles.length === 0) {
      this.isLoggedIn = false;
      useStore.setState({ authorized: false });
      this.initLoginFailed();
      return false;
    }

    this.roles = roles;

    let entity = await api.getEntity(this.entityId);

    if (!entity) {
      this.initLoginFailed();
      useStore.setState({ authorized: false });
      return false;
    }

    this.entity = new Entity(entity);
    this.user.setEntityId(this.entity.id);
    this.user.setRoles(this.roles);

    // Set the language
    // first check on user, then on entity
    if (this.user?.baseLanguage) {
      this.locale = this.user.baseLanguage;
    } else if (this.entity.baseLanguage) {
      this.locale = this.entity.baseLanguage;
    }
    this.uiState.setLocale(this.locale);

    this.user.setConfig(this.entity);

    // Set the app translations based on the user's locale and type
    this.uiState.setTranslations(this.user.getUserLocale(this.locale));

    await this.updateAbilities(this.user, this.entity);

    this.user.setPersonReady(true);

    useStore.setState({ authorized: true });

    this.user.identifyHotjarUser();

    Sentry.setUser({
      id: this.user.id,
      email: this.user.email,
      username: this.user.fullName
    });

    this.isLoggedIn = true;
    this.persistState({ isLoggedIn: true, entityId: this.entityId });

    this.uiState.decreasePendingRequest();
    this.uiState.setHomeRoute(this.user.routes?.[0] || '/');
  }

  redirectToHome() {
    const redirect = localStorage.getItem('redirect');
    localStorage.removeItem('redirect');
    if (redirect) {
      if (redirect.match(/[a-z0-9-]{36}/).index === 0) {
        this.uiState.setRedirectRoute(
          `/sessions/${this.entityId}/${redirect}/tests`
        );
        this.routerStore.push(this.uiState.redirectRoute);
      } else {
        // check if redirect is a valid route and it doesn't start with a domain
        if (redirect.match(/^(http|https):\/\//) === null) {
          this.uiState.setRedirectRoute(redirect);
          this.routerStore.push(this.uiState.redirectRoute);
          return;
        }

        if (redirect.startsWith('/', redirect)) {
          localStorage.removeItem('redirect');
          this.uiState.setRedirectRoute(redirect);
          this.routerStore.push(this.uiState.redirectRoute);
          return;
        }
        console.log('Invalid redirect url', redirect);
        this.routerStore.push(this.uiState.homeRoute || '/');
      }
    } else {
      const homeRoute = this.user.routes?.[0] || '/';
      this.uiState.setHomeRoute(homeRoute);
      const path = this.routerStore.location.pathname;
      if (path === '/login') {
        this.routerStore.push(homeRoute);
      } else {
        this.routerStore.push(path);
      }
    }
  }
  loginFail() {
    this.clearAuthStorage();
  }

  initLoginFailed() {
    this.clearAuthStorage();
  }

  loginAPIFailed() {
    this.clearAuthStorage();
  }

  clearAuthStorage() {
    this.isLoggedIn = false;
    this.user = null;
    this.uiState.decreasePendingRequest();
    try {
      localStorage.setItem('authStore', JSON.stringify({}));
    } catch (er) {}
  }

  setTmpCognitoUser(cognitoUser) {
    this.tmpCognitoUser = cognitoUser;
  }

  async doLogout() {
    this.uiState.decreasePendingRequest();
    await api.logout();
    this.loggedOut();
    await appSyncClient.resetStore();
  }

  async clearCache() {
    await appSyncClient.resetStore();
    window.location.reload();
  }

  async resetPassword(email) {
    this.setPasswordResetEmail(email);
    this.uiState.increasePendingRequest();
    const response = await api.resetPassword(email);
    this.uiState.decreasePendingRequest();
    return response;
  }

  loggedOut() {
    this.abilities.update([]);
    this.isLoggedIn = false;
    this.user = null;

    try {
      localStorage.setItem(
        'authStore',
        JSON.stringify({ isLoggedIn: false, entityId: this.entityId })
      );
    } catch (er) {}
  }

  async doSignup(password) {
    let user;
    if (this.tmpCognitoUser) {
      this.uiState.increasePendingRequest();
      user = await api.loginWithNewPassword(this.tmpCognitoUser, password);
      this.uiState.decreasePendingRequest();
      if (user && !user.error) {
        await this.authSuccess(user);
      } else {
        this.loginFail();
      }
    }
    return user;
  }

  async loginAsTester() {
    this.uiState.increasePendingRequest();
    const user = await api.login(GLOBAL_TESTER_LOGIN, GLOBAL_TESTER_PASSWORD);
    this.uiState.decreasePendingRequest();
    // TODO needs other check to get language
    if (user && !user.error) {
      const splittedPath = this.routerStore.location.pathname.split('/');
      const entityId =
        splittedPath.length > 3 &&
        this.routerStore.location.pathname.split('/')[3];
      const userModel = new User(user.data.getMe, entityId);
      const rootOrg = await api.getRootOrganisation();
      const locale = rootOrg.baseLanguage || rootOrg.baseLanguage;
      this.uiState.setLocale(locale);
      this.setUser(userModel);
    }
  }

  switchEntityId(id) {
    this.persistState({ entityId: id });

    if (localStorage.getItem('redirect')) {
      this.routerStore.push(localStorage.getItem('redirect'));
      localStorage.removeItem('redirect');
    } else {
      window.location.href = '/';
    }
  }

  setPasswordResetEmail(email) {
    this.passwordResetEmail = email;
  }

  setUser(user) {
    this.user = user;
  }

  setInitLoginStateChanged(value) {
    this.initLoginStateChanged = value;
  }

  get client() {
    return appSync.appSyncClient;
  }

  persistState(data) {
    let storage = localStorage.getItem('authStore');
    storage = storage ? JSON.parse(storage) : {};
    storage = { ...storage, ...data };
    try {
      localStorage.setItem('authStore', JSON.stringify(storage));
    } catch (er) {}
  }
}
