import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelHeader } from 'components/panel';
import { PanelTitle, PanelTitleWrapper } from 'components/panel/panel-title';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES
} from 'routes/RouteList';
import { StoreContext } from 'index';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import AddProgramModal from './AddProgramModal';
import Loader from 'components/loader/Loader';
import ProgramsList from 'containers/partials/lists/ProgramsList';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import usePrograms from 'hooks/queries/usePrograms';
import { useStore } from 'stores/Store';
import AddButton from 'containers/partials/buttons/AddButton';

const Programs = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId } = useParams();
  const { push } = useHistory();
  const active = useRouteMatch(ROUTE_EXERCISES_PROGRAMS_ENTITY);

  const [modalData, setModalData] = useState(null);

  const { fetchPrograms, filterPrograms, loading } = usePrograms(entityId);
  const programs = useStore(state => state.programs);

  useEffect(() => {
    fetchPrograms();
  }, []);

  const onSearch = e => {
    filterPrograms({ search: e.target.value });
  };

  const onClickHandler = program => {
    push(
      generatePath(ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM, {
        entityId,
        programId: program.id
      })
    );
  };

  return (
    <Panel active={active.isExact}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
          >
            <FormattedMessage {...messages.breadcrumbOverview} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.programs} />
            {user.canAddPrograms(entityId) && ( // TODO canAddPrograms check
              <AddButton
                onClick={() => setModalData(true)}
                label={
                  <FormattedMessage {...messages.exercisesAddProgramButton} />
                }
              />
            )}
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      {loading && <Loader />}

      <ProgramsList
        entityId={entityId}
        programs={programs}
        onClick={program => onClickHandler(program)}
        onSearch={onSearch}
      />

      {modalData && (
        <AddProgramModal
          entityId={entityId}
          destiny={{
            path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
            variables: {}
          }}
          program={{
            entityId,
            title: '',
            startdate: '',
            duration: '',
            description: '',
            exerciseIds: [],
            exercises: []
          }}
          onClose={() => setModalData(null)}
        />
      )}
    </Panel>
  );
};

export default observer(Programs);
