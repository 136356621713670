import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import { Link } from 'react-router-dom';

const LinkAsButton = props => {
  const { external, extraClassNames, inline, children, to, target } = props;
  return (
    <Link
      to={to}
      target={external ? '_blank' : target ? target : '_self'}
      className={classNames(
        'c-button',
        { 'c-button--tiny u-margin-horizontal-tiny': inline },
        extraClassNames
      )}
    >
      {children}
      {external && (
        <Icon
          id="link-external-bold"
          tinyIcon
          extraClassNames="u-margin-left-tiny"
        />
      )}
    </Link>
  );
};

export default LinkAsButton;
