import { useContext } from 'react';
import { StoreContext } from 'index';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import RemoveExerciseTemplateButton from '../buttons/RemoveExerciseTemplateButton';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { TemplateType } from 'constants.js';

const TemplatesListItem = ({
  id,
  template,
  template: { title },
  checkbox,
  active,
  clickable,
  onClick,
  onChange,
  onEdit,
  onUse,
  onDeleted,
  entityId,
  exercisesLength,
  visible,
  icon,
  mediumIcon,
  style,
  checked,

  virtualRow
}) => {
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);

  return (
    <ListItem
      active={active}
      checkbox={checkbox}
      onClick={onClick}
      clickable={clickable}
      style={style}
    >
      {icon !== null && (
        <ListItemStatus
          itemId={id}
          visible={visible}
          checked={checked}
          onChange={onChange}
          mediumIcon={mediumIcon}
          statusIcon={icon ? icon : 'group'}
        />
      )}
      <ListItemLabelWrapper>
        <ListItemLabel>
          <span>{title}</span>
        </ListItemLabel>
        {user.isGSVOrg && !isNaN(exercisesLength) ? (
          <ListItemLabel info>
            <FormattedMessage
              {...messages.exercisesLengthInListItem}
              values={{
                this: exercisesLength
              }}
            />
          </ListItemLabel>
        ) : (
          <div className="c-list__item-small-editing-area">
            <ButtonsGroup>
              {onUse && (
                <Button
                  tiny
                  onClick={onUse}
                  ariaLabel="Use this template to create a new Program"
                  title="Use this template to create a new Program"
                >
                  <Icon
                    id="use-template"
                    smallMediumIcon
                    strokeColor="color-neutral-dark"
                  />
                </Button>
              )}
              {onEdit && (
                <Button
                  tiny
                  onClick={onEdit}
                  ariaLabel={intl.formatMessage(
                    messages.exercisesEditTemplateButton
                  )}
                  title={intl.formatMessage(
                    messages.exercisesEditTemplateButton
                  )}
                >
                  <Icon id="edit" strokeColor="color-neutral-dark" />
                </Button>
              )}
              {onDeleted && (
                <RemoveExerciseTemplateButton
                  tiny
                  templateId={template.id}
                  collectionType={TemplateType.TEMPLATE}
                  entityId={entityId}
                  onDeleted={onDeleted}
                  ariaLabel={intl.formatMessage(
                    messages.exercisesRemoveTemplateButton
                  )}
                  title={intl.formatMessage(
                    messages.exercisesRemoveTemplateButton
                  )}
                />
              )}
            </ButtonsGroup>
          </div>
        )}
      </ListItemLabelWrapper>
    </ListItem>
  );
};

export default TemplatesListItem;
