import { useEffect, useState } from 'react';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderTitle
} from 'components/card/card-header';
import { CardBody } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages.js';
import Checkbox from 'components/input/Checkbox';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import { useUIDSeed } from 'react-uid';
import TagMultiSelect from 'containers/partials/inputs/TagMultiSelect';

const FilterPanel = ({
  entityId,
  onSubmit,
  onCancel,
  filterByOrg = true,
  ...props
}) => {
  const uidSeed = useUIDSeed();
  const [filter, setFilter] = useState({ tags: [], myOrg: false });

  useEffect(() => {
    setFilter({ ...filter, ...props.filter });
  }, [props.filter]);

  const onTagChangeHandler = tags => {
    setFilter({ ...filter, tags });
  };

  const onSubmitFilter = () => {
    onSubmit && onSubmit(filter);
  };

  return (
    <>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.filterButton} />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage {...messages.filterButton} />
        </CardHeaderSubtitle>
      </CardHeader>
      <CardBody modalSecondary>
        <>
          {filterByOrg && (
            <div className="c-card__body-wrapper u-padding-top-medium">
              <fieldset className="c-input-group">
                <Checkbox
                  id={uidSeed('checkbox-only_my_org')}
                  name={`checkbox-only_my_org`}
                  secondary
                  checked={filter.myOrg}
                  onChange={e =>
                    setFilter({ ...filter, myOrg: e.target.checked })
                  }
                >
                  <FormattedMessage {...messages.exercisesFilterLabelMyOrg} />
                </Checkbox>
              </fieldset>
            </div>
          )}

          <div className="c-card__body-wrapper">
            <TagMultiSelect
              extraClassNames={'u-margin-top-large'}
              entityId={entityId}
              onChange={onTagChangeHandler}
              selectedTags={filter?.tags || []}
            />
          </div>
          <FormEditFooterButtons
            saveLabel={'filterButton'}
            edit
            onCancel={onCancel}
            onSubmit={onSubmitFilter}
            topMargin
          />
        </>
      </CardBody>
    </>
  );
};

export default FilterPanel;
