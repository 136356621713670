import classnames from 'classnames';

const TabPanelHead = props => {
  const { sticky, extraClassNames, children, ...rest } = props;
  return (
    <div
      className={classnames('p-tabs-head', extraClassNames, {
        'p-tabs-head--sticky': sticky
      })}
    >
      {children}
    </div>
  );
};

export default TabPanelHead;
