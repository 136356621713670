import { useContext } from 'react';
import { Redirect, Route, Switch, generatePath } from 'react-router-dom';
import {
  ROUTE_RESULTS_ENTITY,
  ROUTE_RESULTS_ENTITY_GROUP,
  ROUTE_RESULTS_ENTITY_POPUP,
  ROUTE_SPORTERS_ENTITY
} from 'routes/RouteList';
import ResultsMainModal from './modals/ResultsMainModal';
import GroupResults from 'containers/pages/results/GroupResults';
import { StoreContext } from 'index';
import { ResultsPanelContextProvider } from 'contexts/ResultsPanelContext';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import messages from 'messages';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useIntl } from 'react-intl';
import { EntityStoreProvider } from 'containers/pages/persons/store/EntityStoreContext';

const Results = () => {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const {
    authStore: { user }
  } = useContext(StoreContext);

  if (ability.cannot('read', 'Results')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <>
      <Route
        path={ROUTE_RESULTS_ENTITY_POPUP}
        render={props => (
          <ResultsMainModal master entityId={props.match.params.entityId} />
        )}
      />
      <Switch>
        <Route
          path={ROUTE_RESULTS_ENTITY_GROUP}
          render={props => (
            <EntityStoreProvider>
              <ResultsPanelContextProvider
                entityId={props.match.params.entityId}
                groupId={props.match.params.groupId}
                benchmarkId={props.match.params.benchmarkId}
              >
                <GroupResults
                  groupId={props.match.params.groupId}
                  benchmarkId={props.match.params.benchmarkId}
                />
              </ResultsPanelContextProvider>
            </EntityStoreProvider>
          )}
        />
        <Redirect
          to={generatePath(ROUTE_RESULTS_ENTITY, {
            entityId: user.rootEntityId,
            popup: 'popup'
          })}
        />
      </Switch>
    </>
  );
};

export default Results;
