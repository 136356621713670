import gql from 'graphql-tag';

export const QUERY_GET_LESSONS = gql`
  query getExerciseProgramTemplates($entityId: ID!) {
    getExerciseProgramTemplates(entityId: $entityId) {
      id
      entityId
      title
      exercises {
        id
        shares {
          id
        }
        annotations {
          id
        }
        person {
          id
          firstname
          lastname
        }
      }
      tags {
        id
        label
      }
      duration
      editable
      personId
      meta
    }
  }
`;

export const QUERY_GET_LESSON = gql`
  query getExerciseProgramTemplate($id: ID!) {
    getExerciseProgramTemplate(templateId: $id) {
      id
      entityId
      personId
      title
      exercises {
        id
        parentId
        title
        templateCount
        meta
        shares {
          id
        }
        annotations {
          id
        }
        media {
          id
        }
        person {
          id
          firstname
          lastname
        }
      }
      tags {
        id
        label
      }
      duration
      editable
      notes {
        id
        linkId
        linkId2
        linkId3
        note
        noteType
        date_created
      }
      meta
    }
  }
`;

export const MUTATION_ADD_LESSON = gql`
  mutation addExerciseProgramTemplate(
    $entityId: ID!
    $title: String!
    $exerciseIds: [ID]
    $tagIds: [ID]
    $duration: Int
    $meta: AWSJSON
  ) {
    addExerciseProgramTemplate(
      input: {
        entityId: $entityId
        title: $title
        exerciseIds: $exerciseIds
        tagIds: $tagIds
        duration: $duration
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_EDIT_LESSON = gql`
  mutation editExerciseProgramTemplate(
    $id: ID!
    $entityId: ID!
    $title: String!
    $exerciseIds: [ID]
    $tagIds: [ID]
    $duration: Int
    $meta: AWSJSON
  ) {
    editExerciseProgramTemplate(
      input: {
        id: $id
        entityId: $entityId
        title: $title
        exerciseIds: $exerciseIds
        tagIds: $tagIds
        duration: $duration
        meta: $meta
      }
    ) {
      id
      title
    }
  }
`;

export const MUTATION_ARCHIVE_LESSON = gql`
  mutation archiveExerciseProgramTemplate($templateId: ID!) {
    archiveExerciseProgramTemplate(exerciseTemplateId: $templateId)
  }
`;

export const MUTATION_ADD_VIDEO_TO_LESSONS = gql`
  mutation addExerciseToProgramTemplates(
    $exerciseId: ID!
    $programTemplateIds: [ID]
  ) {
    addExerciseToProgramTemplates(
      exerciseId: $exerciseId
      programTemplateIds: $programTemplateIds
    )
  }
`;

export const MUTATION_ADD_VIDEOS_TO_LESSONS = gql`
  mutation addExercisesToProgramTemplates(
    $exerciseIds: [ID]!
    $programTemplateIds: [ID]
  ) {
    addExercisesToProgramTemplates(
      exerciseIds: $exerciseIds
      programTemplateIds: $programTemplateIds
    )
  }
`;

export const MUTATION_REMOVE_VIDEOS_FROM_LESSONS = gql`
  mutation removeExercisesFromProgramTemplates(
    $exerciseIds: [ID]!
    $programTemplateIds: [ID]
  ) {
    removeExercisesFromProgramTemplates(
      exerciseIds: $exerciseIds
      programTemplateIds: $programTemplateIds
    )
  }
`;
