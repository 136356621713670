import React, { useEffect, useState } from 'react';
import Panel from 'components/panel/Panel';
import { PanelBody, PanelHeader, PanelTitle } from 'components/panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import { Card, CardBody } from 'components/card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { Button } from 'components/button';
import { testMethod } from './Tests';
import { exercises } from 'containers/pages/test/data';
import useTabIndex from 'hooks/utils/useTabIndex';
import { ROUTE_TEST_ENTITY_EXERCISES_EXERCISE } from 'routes/RouteList';
import CardHeaderButtons from 'components/card/card-header/CardHeaderButtons';
import { useParams } from 'react-router-dom';
import PythonVideoSelect from 'containers/pages/test/PythonVideoSelect';
import { QUERY_GET_VIDEOANALYSIS } from 'services/aws/videos-query';
import { useLazyQuery } from '@apollo/client';
import Textarea from 'components/input/Textarea';
import FieldRange from 'components/input/FieldRange';
import FieldInput from 'components/input/FieldInput';
import ButtonsGroup from 'components/button/ButtonsGroup';
import Loader from 'components/loader/Loader';

const emptyResult = {
  frames: [],
  values: [],
  message: ''
};

function AnalyseVideoPython() {
  const { entityId, exerciseId } = useParams();
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_TEST_ENTITY_EXERCISES_EXERCISE
  ]);
  const [analyseData, setAnalyseData] = useState(null);
  const [analyseFrame, setAnalyseFrame] = useState(0);
  const [analyseFrameSlider, setAnalyseFrameSlider] = useState(0);
  const [analyseFrameData, setAnalyseFrameData] = useState(null);
  const [testResults, setTestResults] = useState({ ...emptyResult });
  const [exercise, setExercise] = useState();

  useEffect(() => {
    setExercise(exercises.find(e => e.id === parseInt(exerciseId)));
  }, [exerciseId]);

  const [getVideoAnalyseData, { loading }] = useLazyQuery(
    QUERY_GET_VIDEOANALYSIS,
    { fetchPolicy: 'network-only' }
  );

  const preloadImage = src =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = resolve;
      image.onerror = reject;
      image.src = src;
    });

  const getAnalyseData = async file => {
    const { data } = await getVideoAnalyseData({
      variables: {
        key: file.id
      }
    });

    console.log('DATA', data);

    if (data?.getVideoAnalysis) {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base'
      });
      try {
        const analyseResultFiles = JSON.parse(data.getVideoAnalysis);
        const analyseResultImages = analyseResultFiles.sort(collator.compare);
        const analyseResultKeypoints = analyseResultImages.pop();
        //await Promise.all(analyseResultImages.map(i => preloadImage(i)));
        console.log(analyseResultKeypoints);

        const response = await fetch(analyseResultKeypoints);
        if (response.ok) {
          console.log('JSON data', await response);
          setAnalyseFrameData(await response.json());
        }

        setAnalyseData(analyseResultImages);
        setAnalyseFrame(0);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    setAnalyseFrameSlider(analyseFrame);
  }, [analyseFrame]);

  const changeUrl = file => {
    console.log(file);
    setTestResults({ ...emptyResult });
    getAnalyseData(file);
  };

  const showTestResults = () => {
    if (exercise) {
      const method = exercise.method;
      const result = testMethod[method]({
        poses: analyseFrameData,
        ...(exercise?.props || {})
      });

      setTestResults({ ...testResults, ...result });
    }
  };

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>{`Analyse ${exercise?.name}`}</PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Tabs
          fullWidth
          hrefWithin
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >
          <TabList>
            <Tab>Video</Tab>
          </TabList>
          <TabPanel>
            <Panel>
              <PanelBody>
                <Card secondary>
                  <CardHeader secondary extraClassNames="u-margin-bottom-none">
                    <CardHeaderText>
                      <CardHeaderTitle>Video source</CardHeaderTitle>
                    </CardHeaderText>
                  </CardHeader>
                  <CardBody secondary separatorBottom>
                    <PythonVideoSelect
                      entityId={entityId}
                      onChange={file => changeUrl(file)}
                      label={'Select uploaded video'}
                      placeholder="Select a video"
                    />
                  </CardBody>
                  <CardHeader secondary>
                    <CardHeaderTitle>Pose Detection</CardHeaderTitle>
                    <CardHeaderButtons></CardHeaderButtons>
                  </CardHeader>

                  <CardBody secondary>
                    {loading && <Loader />}
                    {analyseData && (
                      <div className={'o-layout o-layout--small'}>
                        <div className={'o-layout__item u-1-of-2'}>
                          <div>
                            <img
                              src={analyseData?.[analyseFrame + 1]}
                              alt="skeletonImage"
                            />
                          </div>
                          <div>
                            {/* TODO: maarten, check if dit oke is.
                            Hier werd vroeger een actie getriggerd op
                            `onChange` en dan nog een andere actie op
                            `onMouseUp` ?
                            Nu heb ik `onChange` en `onChangeEnd` gebruikt.
                             */}
                            <FieldRange
                              step={1}
                              min={0}
                              max={analyseData.length - 2}
                              value={analyseFrameSlider}
                              onChange={e =>
                                setAnalyseFrameSlider(e.target.value)
                              }
                              onChangeEnd={e =>
                                setAnalyseFrame(parseInt(e.target.value))
                              }
                            />
                          </div>
                          <div className={'o-layout o-layout--small'}>
                            <div className={'o-layout__item u-1-of-3'}>
                              <Button
                                primary
                                onClick={() =>
                                  setAnalyseFrame(analyseFrame - 1)
                                }
                                disabled={analyseFrame === 0}
                              >
                                &lt; Prev
                              </Button>
                            </div>
                            <div className={'o-layout__item u-1-of-3'}>
                              <FieldInput
                                value={String(analyseFrameSlider)}
                                useNative
                                readOnly
                              />
                            </div>
                            <div className={'o-layout__item u-1-of-3'}>
                              <Button
                                primary
                                onClick={() =>
                                  analyseFrame < analyseData.length - 2 &&
                                  setAnalyseFrame(analyseFrame + 1)
                                }
                                disabled={
                                  analyseFrame >= analyseData.length - 2
                                }
                              >
                                Next &gt;
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className={'o-layout__item u-1-of-2'}>
                          <Textarea
                            value={JSON.stringify(
                              analyseFrameData[analyseFrame],
                              null,
                              2
                            )}
                            rows={'30'}
                          ></Textarea>
                          <Button primary onClick={() => showTestResults()}>
                            Calculate test results
                          </Button>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </PanelBody>
            </Panel>
            <Panel>
              <PanelBody>
                <CardHeader secondary extraClassNames="u-margin-bottom-none">
                  <CardHeaderText>
                    <CardHeaderTitle>Analyse Results</CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary separatorBottom>
                  {testResults && (
                    <div
                      // code for dev purposes
                      dangerouslySetInnerHTML={{ __html: testResults.message }}
                    ></div>
                  )}
                </CardBody>
                <CardHeader secondary extraClassNames="u-margin-bottom-none">
                  <CardHeaderText>
                    <CardHeaderTitle>Frames of interest</CardHeaderTitle>
                  </CardHeaderText>
                </CardHeader>
                <CardBody secondary separatorBottom>
                  <ButtonsGroup style={{ width: '90%', flexWrap: 'wrap' }}>
                    {testResults.frames.length > 0 &&
                      testResults.frames.map((frame, index) => (
                        <Button
                          key={index}
                          primary
                          onClick={() => setAnalyseFrame(frame)}
                          style={{ margin: '5px' }}
                        >
                          {frame}
                        </Button>
                      ))}
                  </ButtonsGroup>
                </CardBody>
              </PanelBody>
            </Panel>
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
}

export default AnalyseVideoPython;
