import React, { useContext, useEffect, useState } from 'react';
import { DECRYPT_TOKEN_URL } from 'constants.js';
import { StoreContext } from 'index';
import useUrlQuery from 'hooks/utils/useUrlQuery';
import NoAccess from 'containers/pages/auth/NoAccess';
import { useHistory } from 'react-router-dom';
import { ROUTE_LOGIN } from 'routes/RouteList';

const MagicLogin = () => {
  const { authStore } = useContext(StoreContext);
  const { push } = useHistory();
  let query = useUrlQuery();
  const [loginError, setLoginError] = useState(null);

  const decryptToken = async token => {
    const response = await fetch(`${DECRYPT_TOKEN_URL}`, {
      method: 'POST',
      header: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: token
      })
    }).catch(err => {
      alert(`Failed to decrypt magic link: ${err.message}`);
    });

    const body = await response.json();

    if (body.email && body.token) {
      return body;
    }
  };

  useEffect(() => {
    const decryptLoginToken = async token => {
      await authStore.doLogout();
      authStore.clearAuthStorage();

      try {
        const body = await decryptToken(token);
        const result = await authStore.doLogin(body.email, null, body.token);
        if (result?.error) {
          setLoginError(result.error.message);
          console.log(result.error.message);
        }
      } catch (error) {
        setLoginError(error);
        console.log(error);
      }
    };

    if (query) {
      const redirect = query.get('redirect');
      if (redirect) {
        localStorage.setItem('redirect', redirect);
      }
      const token = query.get('token');
      if (!token) {
        push(ROUTE_LOGIN);
        return;
      }

      decryptLoginToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return <div>{loginError && <NoAccess />}</div>;
};

export default MagicLogin;
