import { useLazyQuery } from '@apollo/client';
import Template from 'models/Template';
import { QUERY_GET_TEMPLATE } from 'services/aws/templates-query';
import { useStore } from 'stores/Store';

const useCollection = () => {
  const setCollection = useStore(state => state.setCollection);

  const [getCollection, { loading, error }] = useLazyQuery(QUERY_GET_TEMPLATE);

  const fetchCollection = async options => {
    const { data } = await getCollection({
      ...options
    });
    if (data?.getExerciseProgramTemplate) {
      const collectionData = data.getExerciseProgramTemplate;
      const collection = new Template({ ...collectionData });
      setCollection(collection);
      return collection;
    }
  };

  return {
    loading,
    error,
    fetchCollection
  };
};

export default useCollection;
