import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { MenuWrapper } from 'components/menu';
import { inject } from 'mobx-react';
import MultiFileUploadWrapper from '../file-upload/MultiFileUploadWrapper';
import CardBody from 'components/card/CardBody';
import Card from 'components/card/Card';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle,
  CardHeaderButtons
} from 'components/card/card-header';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import RemoveAllFilesButton from '../buttons/RemoveAllFilesButton';
import messages from 'messages';
import { getAllFiles } from 'services/aws/file-upload';
import { MODAL_TYPES } from 'models/ModalData';

class MultiFileUploadCard extends Component {
  state = {
    loading: false
  };
  onDownloadAll = async () => {
    const { entityId, linkId } = this.props;
    this.setState({ loading: true });
    const response = await getAllFiles({ entityId, type: '*', linkId });
    this.setState({ loading: false });
    if (
      response &&
      response.data &&
      response.data.getAllFiles &&
      response.data.getAllFiles !== 'false'
    ) {
      this.showMailSuccess();
    } else {
      this.showMailFailed();
    }
  };
  showMailSuccess = () => {
    const { uiState, intl } = this.props;
    uiState.showModal({
      title: intl.formatMessage(messages.modalZipSendToUserSuccessTitle),
      message: messages.modalZipSendToUserSuccessMessage,
      dismissButton: false,
      type: MODAL_TYPES.WARNING
    });
  };

  showMailFailed = () => {
    const { uiState, intl } = this.props;
    uiState.showModal({
      title: intl.formatMessage(messages.modalZipSendToUserFailedTitle),
      message: messages.modalZipSendToUserFailedMessage,
      dismissButton: false,
      type: MODAL_TYPES.ALERT
    });
  };

  render() {
    const { entityId, linkId } = this.props;
    return (
      <Card secondary>
        <CardHeader secondary>
          <CardHeaderText>
            <CardHeaderTitle>
              <FormattedMessage {...messages.cardLabelUploadFilesTitle} />
            </CardHeaderTitle>
          </CardHeaderText>
          <CardHeaderButtons>
            <MenuWrapper
              trigger={
                <Button circular light>
                  <Icon id="three-dots" mediumIcon />
                </Button>
              }
            >
              <Button menu onClick={this.onDownloadAll}>
                <Icon id="download" />
                <FormattedMessage {...messages.cardMenuItemDownloadAllFiles} />
              </Button>
              <RemoveAllFilesButton
                menu
                entityId={entityId}
                linkId={linkId}
                // TODO: @fabian check what is this?
                type={'*'}
              >
                <FormattedMessage {...messages.cardMenuItemRemoveAllFiles} />
              </RemoveAllFilesButton>
            </MenuWrapper>
          </CardHeaderButtons>
        </CardHeader>
        <CardBody secondary>
          <MultiFileUploadWrapper
            parentLoading={this.state.loading}
            entityId={entityId}
            linkId={linkId}
            // TODO: @fabian check what is this?
            type={'*'}
          />
        </CardBody>
      </Card>
    );
  }
}

export default injectIntl(inject('uiState')(MultiFileUploadCard));
