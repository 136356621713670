// ContextMenu.js
import { cloneElement, useEffect } from 'react';
import { useContextMenu } from 'contexts/ContextMenuProvider';

const ContextMenu = props => {
  const { contextMenuItems } = useContextMenu();

  useEffect(() => {
    return () => {
      // cleanup
      // remove all menu items
    };
  }, [contextMenuItems]);

  return (
    <>
      {contextMenuItems &&
        contextMenuItems.map((component, index) =>
          cloneElement(component, { key: index, ...props })
        )}
    </>
  );
};

export default ContextMenu;
