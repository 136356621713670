import { useEffect } from 'react';
import useExercises from 'hooks/queries/useExercises';
import { useStore } from 'stores/Store';
import { useShallow } from 'zustand/react/shallow';

const ExercisesFetcher = ({ entityId, children }) => {
  const exercisesFilter = useStore(state => state.exercisesFilter);
  const { search, tags, myOrg } = useStore(
    useShallow(state => ({
      search: state.exercisesFilter.search,
      tags: state.exercisesFilter.tags,
      myOrg: state.exercisesFilter.myOrg
    }))
  );

  const {
    fetchExercises,
    filterExercises,
    fetchMoreExercises,
    hasNextPage,
    isFetchingNextPage
  } = useExercises(entityId);

  useEffect(() => {
    fetchExercises();
  }, []);

  useEffect(() => {
    filterExercises({ search, tags, myOrg });
  }, [search, tags, myOrg]);

  const fetch = async () => {
    await fetchExercises({ ...exercisesFilter });
  };

  const filter = async () => {
    await filterExercises({ ...exercisesFilter });
  };

  const fetchMore = async () => {
    await fetchMoreExercises({ ...exercisesFilter });
  };

  return children({
    hasNextPage,
    fetch,
    filter,
    fetchMore,
    isFetchingNextPage
  });
};

export default ExercisesFetcher;
