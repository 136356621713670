import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import {
  MUTATION_ADD_CLIENT,
  MUTATION_UPDATE_CLIENT
} from 'services/aws/client-query';
import { cleanNullUndefined } from 'utils/object';
import { format } from 'date-fns';
import { parseDateString } from 'utils/date';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import usePerson from 'hooks/queries/usePerson';
import useFetchAndFilterPersonsPool from 'hooks/queries/useFetchAndFilterPersonsPool';
import Loader from 'components/loader/Loader';

function PersonForm({
  entityId,
  groupId,
  person,
  children,
  extraClassNames,
  onCancel,
  onAdded,
  onComplete
}) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const notification = useNotificationQueue();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const { fetchEntities } = useFetchAndFilterEntities({ entityId });
  const { fetchPool } = useFetchAndFilterPersonsPool({
    entityId
  });
  const { fetchPerson } = usePerson({ personId: person.id });

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(
      intl.formatMessage(messages.firstnameRequired)
    ),
    lastname: Yup.string().required(
      intl.formatMessage(messages.lastnameRequired)
    ),
    gender: Yup.string().required(intl.formatMessage(messages.genderRequired)),
    birthdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.birthdateRequired))
      .required(intl.formatMessage(messages.birthdateRequired)),
    athleteEmail: Yup.string().email(intl.formatMessage(messages.emailNotValid))
  });

  const [editPerson] = useMutation(MUTATION_UPDATE_CLIENT);

  const [addPerson] = useMutation(MUTATION_ADD_CLIENT);

  const handleSubmit = async values => {
    setIsLoading(true);
    const meta = JSON.stringify({
      email: values.athleteEmail
        ? values.athleteEmail.trim().toLowerCase()
        : undefined
    });

    const personData = cleanNullUndefined({
      firstname: values.firstname,
      lastname: values.lastname,
      gender: values.gender,
      birthdate: format(values.birthdate, 'yyyy-LL-dd'),
      uid: values.uid,
      meta,
      notes: values.notes,
      dominantHand: values.dominantHand || null
    });

    if (!values.id) {
      const { data } = await addPerson({
        variables: {
          entityId: groupId,
          topLevelEntityId: user.rootEntityId,
          person: { ...personData }
        }
      });

      if (data?.addPersonToEntityReturnPerson) {
        setTimeout(async () => {
          await fetchPerson(data.addPersonToEntityReturnPerson.id);
          if (groupId) {
            await fetchEntities(groupId);
          }
          await fetchPool({ withEntity: false });
          setIsLoading(false);
          notification.add(data.addPersonToEntityReturnPerson.id, {
            message: intl.formatMessage(messages.personAdded)
          });
          onAdded && onAdded(data.addPersonToEntityReturnPerson.id);
        }, 100);
      }
    } else {
      const { data } = await editPerson({
        variables: { ...personData, id: person.id, entityId: groupId }
      });

      if (data?.editPerson) {
        await fetchPerson(data.editPerson.id);
        if (groupId) {
          await fetchEntities(groupId);
        }
        await fetchPool({ withEntity: false });
        setIsLoading(false);
        notification.add(data.editPerson.id, {
          message: intl.formatMessage(messages.personUpdated)
        });
        onComplete && onComplete(data.editPerson.id);
      }
    }
  };
  return (
    <Formik
      initialValues={person}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
      onReset={() => onCancel && onCancel()}
    >
      {props => {
        return (
          <Form className={classNames(extraClassNames)} noValidate>
            {isLoading && <Loader />}
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default PersonForm;
