import { useEffect } from 'react';
import { QUERY_GET_USER_BY_ID } from 'services/aws/user-query';
import { useLazyQuery } from '@apollo/client';
import Person from 'models/Person';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function usePerson({ personId }) {
  const setPerson = useEntityContext(s => s.setPerson);

  const [getPerson, { loading, error, data }] = useLazyQuery(
    QUERY_GET_USER_BY_ID,
    {
      variables: { id: personId },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (data?.getPerson) {
      setPerson(new Person(data.getPerson));
    }
  }, [data]);

  const fetchPerson = async personId => {
    const { data } = await getPerson({ variables: { id: personId } });

    if (data?.getPerson) {
      const personData = data.getPerson;
      const person = new Person({
        ...personData
      });
      setPerson(person);
      return person;
    }
  };

  return { fetchPerson, loading, error, getPerson };
}

export default usePerson;
