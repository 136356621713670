import React, { Fragment, useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import {
  withRouter,
  Redirect,
  generatePath,
  Route,
  Switch
} from 'react-router-dom';
import {
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY,
  ROUTE_EXERCISES_ENTITY_EXERCISE,
  ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT,
  ROUTE_EXERCISES_INDEX,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE,
  ROUTE_EXERCISES,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_EXERCISES_ENTITY_EXERCISES,
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL,
  ROUTE_EXERCISES_ENTITY_ALL_EXERCISES
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import Overview from './Overview';
import Exercises from './exercises/Exercises';
import messages from 'messages';
import Exercise from './exercises/Exercise';
import Programs from './programs/Programs';
import Templates from './templates/Templates';
import Template from './templates/Template';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import ExercisesLanguages from 'containers/pages/exercises/exercises/ExercisesLanguages';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import Program from 'containers/pages/exercises/programs/Program';
import PersonFeedback from 'containers/pages/exercises/programs/PersonFeedback';
import { MenuContextProvider } from 'contexts/MenuContext';
import ContextMenuProvider from 'contexts/ContextMenuProvider';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';

const ExercisesIndex = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const intl = useIntl();

  const entityStore = useRef(createEntityStore()).current;

  if (ability.cannot('read', 'Exercises')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <>
      <PreviewModalProvider entityId={user.rootEntityId}>
        <Switch>
          <Route
            path={[
              ROUTE_EXERCISES_INDEX,
              ROUTE_EXERCISES_ENTITY_EXERCISES,
              ROUTE_EXERCISES_PROGRAMS_ENTITY,
              ROUTE_EXERCISES_TEMPLATES_ENTITY
            ]}
          >
            <Overview entityId={user.rootEntityId} />
            <Switch>
              <Route
                path={[
                  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE,
                  ROUTE_EXERCISES_TEMPLATES_ENTITY
                ]}
              >
                <Templates />
                <Switch>
                  <Route
                    path={[
                      ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE,
                      ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE
                    ]}
                  >
                    <Template />
                    <Switch>
                      <Route
                        path={[
                          ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE
                        ]}
                      >
                        <Exercise
                          parentPath={
                            ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE
                          }
                        />
                      </Route>
                    </Switch>
                  </Route>
                </Switch>
              </Route>
              <Route
                path={[
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,
                  ROUTE_EXERCISES_PROGRAMS_ENTITY
                ]}
              >
                <Programs />
                <Switch>
                  <Route
                    path={[
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL,
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE,
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT,
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS,
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS,
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM
                    ]}
                  >
                    <ContextMenuProvider>
                      <Program />
                    </ContextMenuProvider>
                    <Switch>
                      <Route
                        path={[
                          ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE
                        ]}
                      >
                        <Exercise
                          parentPath={
                            ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE
                          }
                          crumbs={[
                            {
                              path: ROUTE_EXERCISES_INDEX,
                              label: intl.formatMessage(
                                messages.breadcrumbOverview
                              )
                            },
                            {
                              path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,
                              label: intl.formatMessage(
                                messages.breadcrumbProgram
                              )
                            }
                          ]}
                        />
                      </Route>
                      <Route
                        path={[
                          ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
                          ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL
                        ]}
                      >
                        <MenuContextProvider>
                          <EntityStoreProvider value={entityStore}>
                            <PersonFeedback />
                          </EntityStoreProvider>
                        </MenuContextProvider>
                      </Route>
                    </Switch>
                  </Route>
                </Switch>
              </Route>

              <Route
                path={[
                  ROUTE_EXERCISES_ENTITY_EXERCISE,
                  ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT,
                  ROUTE_EXERCISES_ENTITY_EXERCISES
                ]}
              >
                <Exercises />
                <Switch>
                  <Route
                    path={[
                      ROUTE_EXERCISES_ENTITY_EXERCISE,
                      ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT
                    ]}
                  >
                    <Exercise
                      parentPath={ROUTE_EXERCISES_ENTITY_EXERCISE}
                      crumbs={[
                        {
                          path: ROUTE_EXERCISES_INDEX,
                          label: intl.formatMessage(messages.breadcrumbOverview)
                        },
                        {
                          path: ROUTE_EXERCISES_ENTITY_EXERCISES,
                          label: intl.formatMessage(
                            messages.breadcrumbExercises
                          )
                        }
                      ]}
                    />
                  </Route>
                </Switch>
              </Route>
              <Route path={ROUTE_EXERCISES_INDEX}>
                <Panel
                  emptyState
                  emptyLabel={intl.formatMessage(messages.exercisesEmptyPanel)}
                />
              </Route>
            </Switch>
          </Route>

          {/*
            <Route
              exact
              path={ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE}
              render={props => (
                <ExercisesLanguages
                  entityId={props.match.params.entityId}
                  exerciseId={props.match.params.exerciseId}
                  lang={props.match.params.lang}
                />
              )}
            */}
          <Route
            path={[ROUTE_EXERCISES_ENTITY]}
            exact
            render={() => {
              return (
                <Redirect
                  to={generatePath(ROUTE_EXERCISES_ENTITY_ALL_EXERCISES, {
                    entityId: user.rootEntityId
                  })}
                />
              );
            }}
          />
          <Route
            path={ROUTE_EXERCISES}
            exact
            render={() => {
              return <Redirect to={ROUTE_EXERCISES_INDEX} />;
            }}
          />
        </Switch>
      </PreviewModalProvider>
    </>
  );
};

export default withRouter(observer(ExercisesIndex));
