import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { useUIDSeed } from 'react-uid';
import useSelectableObject from 'hooks/utils/useSelectableObject';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import VirtualListItem from 'containers/partials/list-items/VirtualListItem';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { getLocalDateFormat } from 'utils/date';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import FieldInput from 'components/input/FieldInput';
import SortableListHeader from 'components/list/SortableListHeader';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import classNames from 'classnames';
import slugify from 'slugify';

const VirtualizedSessionList = ({
  activeId,
  items = [],
  checkable,
  sortableListHeader,
  sortColumns,
  onClick,
  onSelect,
  selectedItems,
  disabledItems = [],
  excludedItems = [],
  extraClassNames = {},
  withFixedFooter,
  emptyMessage,
  reCalculateHeight = false,
  variableHeight = false,
  refactoring = false,
  onFilter,
  onSort,
  filteringTerm
}) => {
  const intl = useIntl();
  const parentRef = useRef();
  const activeStickyIndexRef = useRef(0);
  const [stickyIndex, setStickyIndex] = useState(null);
  const [filter, setFilter] = useState({ search: '' });
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectableObject([]);
  const [minHeightParent, setMinHeightParent] = useState(null);

  const inputSearchRef = useRef();

  useEffect(() => {
    setSelected(selectedItems ? selectedItems : []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedItems]);

  const isSticky = index => index === stickyIndex;

  const rowVirtualizer = useVirtualizer({
    count: items?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 56,

    overscan: 5,
    rangeExtractor: useCallback(
      range => {
        activeStickyIndexRef.current = stickyIndex;

        const next =
          activeStickyIndexRef.current !== null
            ? new Set([
                ...defaultRangeExtractor(range),
                activeStickyIndexRef.current
              ])
            : new Set([...defaultRangeExtractor(range)]);

        return [...next];
      },
      [stickyIndex]
    )
  });

  const onSearch = async e => {
    await setFilter({ search: e.target.value });

    onFilter && onFilter({ search: e.target.value });
  };

  const onSelectHandler = (item, checked) => {
    if (excludedItems.find(exI => exI.id === item.id)) return;
    const selectedItems = select(item);
    onSelect && onSelect(selectedItems, item, checked);
  };

  const checkAndSetHeight = theRef => {
    if (theRef?.current) {
      const roundedTop = theRef.current.getBoundingClientRect().top.toFixed(0);
      setMinHeightParent(`calc(100dvh - ${roundedTop}px)`);
    }
  };

  const reCalculate = (top = false) => {
    if (top !== false) {
      //
    } else {
      const roundedTop = parentRef?.current
        ?.getBoundingClientRect()
        .top.toFixed(0);

      const footerSpacing = document.querySelector(
        '.c-card__footer-bottom-fixed'
      )
        ? 70
        : 0;
      return `calc(100dvh - ${roundedTop}px - ${footerSpacing}px)`;
    }
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (parentRef) {
        checkAndSetHeight(parentRef);
        variableHeight && reCalculate();
        clearInterval(interval);
      }
    }, 100);
  }, [parentRef]);

  useLayoutEffect(() => {
    if (activeId && items.find(el => el.id === activeId)) {
      setStickyIndex(items.indexOf(items.find(el => el.id === activeId)));
    }
  }, [rowVirtualizer, items]);

  const getItemsPosition = (item, virtualizer) => {
    const itemOnStart =
      item.start === virtualizer.getOffsetForIndex(stickyIndex)[0] &&
      virtualizer.getOffsetForIndex(stickyIndex)[0] <= virtualizer.scrollOffset;
    const itemOnEnd =
      item.end - virtualizer.scrollRect.height >= virtualizer.scrollOffset - 12;

    return { onStart: itemOnStart, onEnd: itemOnEnd };
  };

  if (!Array.isArray(items)) return null;

  return (
    <>
      <div className="p-tabs__tab-header">
        <FieldInput
          placeholder={intl.formatMessage(messages.searchSessions)}
          title={intl.formatMessage(messages.searchSessions)}
          // onChange={e => setState({ search: e.target.value })}
          value={filter.search}
          onChange={onSearch}
          type="text"
          rounded
          icon="search-grey"
          iconLeft
          useNative
          extraClassNames={classNames('u-margin-bottom-none c-input--search', {
            'c-input--search-filled': inputSearchRef?.current?.value.length > 0
          })}
          ref={inputSearchRef}
          dataQa="search-in-list"
        />
        <Button
          mini
          light
          type="reset"
          onClick={() => {
            inputSearchRef.current.value = '';
            setFilter({ search: inputSearchRef.current.value });
            onFilter && onFilter({ search: inputSearchRef.current.value });

            inputSearchRef.current.focus();
          }}
        >
          <Icon id="close" strokeColor="color-neutral-dark"></Icon>
          <span className="u-hidden-visually">Clear input</span>
        </Button>
      </div>
      {filteringTerm.search.length > 0 && (
        <MessageText extraClassNames="c-list__filtering-message">
          <FormattedMessage
            {...messages.searchInputGeneralFeedback}
            values={{
              count: filteringTerm.filteredCount,
              total: filteringTerm.items.length,
              searchTerm: filteringTerm.search,
              b: chunks => <b>{chunks}</b>
            }}
          />
        </MessageText>
      )}
      {items.length > 0 ? (
        <>
          {sortableListHeader
            ? sortableListHeader
            : sortColumns && (
                <SortableListHeader
                  refactoring
                  columns={sortColumns}
                  onSort={activeSort => {
                    onSort && onSort(activeSort);
                  }}
                />
              )}
          <List
            ref={parentRef}
            virtualizedList
            style={{
              maxHeight: `${minHeightParent}`,
              height: withFixedFooter ? 'calc(100% - 64px)' : '100%'
            }}
            extraClassNames={extraClassNames}
          >
            <ListBody
              style={{
                height: reCalculateHeight
                  ? reCalculate()
                  : rowVirtualizer.getTotalSize(),
                position: 'relative',
                marginBottom: 0
              }}
            >
              {rowVirtualizer.getVirtualItems().map(virtualRow => {
                const item = items[virtualRow.index];

                let itemOnStart, itemOnEnd;
                if (virtualRow.index === stickyIndex && item.id === activeId) {
                  itemOnStart = getItemsPosition(
                    virtualRow,
                    rowVirtualizer
                  ).onStart;
                  itemOnEnd = getItemsPosition(
                    virtualRow,
                    rowVirtualizer
                  ).onEnd;
                }

                const disabled = disabledItems.find(exI => exI.id === item.id);
                const excluded = excludedItems.find(exI => exI.id === item.id);

                let listData = {
                  titleLabel: 'name',
                  statusLabel: `${item.finishedPercentage}%`,
                  checkbox: checkable && !excluded,
                  onClick: () => {
                    item.id === activeId && setStickyIndex(virtualRow.index);
                    return onClick && onClick(item, 'session');
                  },
                  checked: selected.find(s => s.id === item.id),
                  outfaded: excluded || !item.filtered,

                  filtered: item.filtered,
                  meta: (
                    <>
                      {/* TODO Temp solution */}
                      <ListItemLabel info>
                        {getLocalDateFormat(item.startdate)}
                      </ListItemLabel>
                    </>
                  )
                };

                if (item.recurring) {
                  listData = {
                    ...listData,
                    statusLabel: item.interval === 'daily' ? `D` : `W`,
                    outfaded: item.enddate < new Date(),
                    meta: (
                      <>
                        <ListItemLabel info>
                          {getLocalDateFormat(item.startdate)}
                          {item.enddate && (
                            <>{` - ${getLocalDateFormat(item.enddate)}`}</>
                          )}
                        </ListItemLabel>
                      </>
                    )
                  };
                }

                return (
                  <VirtualListItem
                    refactoring={refactoring}
                    uid={uidSeed(item.id)}
                    item={item}
                    key={virtualRow.index}
                    virtualRow={virtualRow}
                    ref={rowVirtualizer.measureElement}
                    dataIndex={virtualRow.index}
                    dataQa={slugify(item[listData.titleLabel])}
                    extraStyle={{
                      ...(isSticky(virtualRow.index)
                        ? {
                            zIndex: 199,

                            ...(itemOnStart
                              ? {
                                  position: 'sticky',
                                  top: -4,
                                  transform: `scale(1.004)`
                                }
                              : {}),
                            ...(itemOnEnd
                              ? {
                                  position: 'sticky',
                                  top:
                                    rowVirtualizer.scrollRect.height -
                                    virtualRow.size -
                                    12,
                                  bottom: 'auto',
                                  transform: `scale(1.004)`
                                }
                              : {}),
                            ...(!itemOnStart && !itemOnEnd
                              ? {
                                  position: 'absolute',
                                  transform: `translateY(${virtualRow.start}px) scale(1.005)`,
                                  top: 0,
                                  bottom: 'auto'
                                }
                              : {})
                          }
                        : {
                            position: 'absolute',
                            transform: `translateY(${virtualRow.start}px)`,
                            top: 0
                          }),

                      left: 0,
                      width: '100%',
                      paddingBottom: 8
                    }}
                    active={item.id === activeId}
                    supertitle={item.recurring ? item.interval : ''}
                    //   FOR DEBUG on REFACTORING
                    //   supertitle={
                    //     item.recurring
                    //       ? item.interval
                    //       : `${item.type}${
                    //           item.preventionType ? ' - ' + item.preventionType : ''
                    //         }`
                    //   }
                    clickable={!excluded && item.filtered}
                    outfaded={item.outfaded || !item.filtered}
                    disabled={disabled}
                    onChange={e => onSelectHandler(item, e)}
                    filteringTerm={filteringTerm}
                    filtered={item.filtered}
                    {...listData}
                  />
                );
              })}
            </ListBody>
          </List>
        </>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="cursor">
              <MessageText>
                <FormattedMessage
                  {...(emptyMessage
                    ? emptyMessage
                    : messages.sessionsEmptyPanel)}
                />
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default VirtualizedSessionList;
