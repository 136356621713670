import React, { useEffect, useState } from 'react';
import ThumbList from 'containers/partials/media/ThumbList';
import FileFull from 'containers/partials/media/FileFull';
import File from 'models/File';

const MediaViewer = ({ media, entityId, accentColor }) => {
  // TODO: juiste thumbnails genereren? Is dat hier nodig...
  // om naar de pdf te sturen [sc-7224]
  //   const meta = JSON.parse(media[1].meta);
  //   const playbackId = meta.mux.playback_ids[0].id;

  //   const muxThumb = `https://image.mux.com/${playbackId}/thumbnail.jpg?width=504&fit_mode=preserve&time=35`;

  const [selectedMedia, setSelectedMedia] = useState(null);
  const [publicMedia, setPublicMedia] = useState(null);

  useEffect(() => {
    // If there are 2 media,
    // and the first is an image
    // and the second a video,
    // we will assume the image is a thumbnail for the video.
    // SO we will show only the video,
    // and we'll add the image as a poster
    if (
      media &&
      media.length === 2 &&
      media[0].type === 'exercise_image' &&
      media[1].type === 'exercise_video'
    ) {
      setPublicMedia([new File(media[1])]);
      setSelectedMedia(new File(media[1]));
    } else {
      // otherwise, all good.
      setPublicMedia(media);

      if (media && media.length > 0) {
        setSelectedMedia(new File(media[0]));
      }
    }
  }, [media]);

  const onThumbClickHandler = file => {
    setSelectedMedia(new File(file));
  };

  return (
    <>
      {selectedMedia && (
        <FileFull
          file={selectedMedia}
          accentColor={accentColor}
          poster={
            media &&
            media.length === 2 &&
            media[0].type === 'exercise_image' &&
            new File(media[0])
          }
        />
      )}
      {publicMedia?.length > 1 && (
        <ThumbList
          selectedMedia={selectedMedia}
          entityId={entityId}
          editable={false}
          media={publicMedia}
          onClick={onThumbClickHandler}
        />
      )}
    </>
  );
};

export default MediaViewer;
