import React, { forwardRef } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { useNotificationQueue } from 'components/notification';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import { MUTATION_EDIT_PROGRAM } from 'services/aws/programs-query';
import messages from 'messages';
import { format } from 'date-fns';
import { parseDateString } from 'utils/date';
import useProgram from 'hooks/queries/useProgram';
import usePrograms from 'hooks/queries/usePrograms';

function ProgramForm(
  { entityId, program, children, extraClassNames, onComplete, style },
  ref
) {
  const intl = useIntl();
  const { fetchProgram } = useProgram();
  const { fetchPrograms } = usePrograms(entityId);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    startdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.startdateRequired)),
    duration: Yup.number().required(
      intl.formatMessage(messages.durationRequired)
    )
  });

  const notification = useNotificationQueue();

  const [editProgram] = useMutation(MUTATION_EDIT_PROGRAM);

  const onSubmitHandler = async program => {
    const { data } = await editProgram({
      variables: {
        ...program,
        startdate: format(program.startdate, 'yyyy-LL-dd'),
        exerciseIds: program.exerciseIds
      }
    });

    if (data.editExerciseProgram.id) {
      fetchProgram({
        variables: { id: program.id },
        fetchPolicy: 'network-only'
      });
      fetchPrograms({
        variables: { entityId },
        fetchPolicy: 'network-only'
      });

      notification.add(data.editExerciseProgram.id, {
        message: intl.formatMessage(messages.programSaved)
      });
      onComplete && onComplete(data.editExerciseProgram.id);
    }
  };

  return (
    <Formik
      innerRef={ref}
      initialValues={program}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => {
        return (
          <Form
            className={classNames(extraClassNames)}
            noValidate
            style={style}
          >
            {typeof children === 'function' ? children(props) : children}
          </Form>
        );
      }}
    </Formik>
  );
}

export default forwardRef(ProgramForm);
