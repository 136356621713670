import React, { useContext, useEffect } from 'react';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { FormattedMessage } from 'react-intl';
import { Panel, PanelHeader } from 'components/panel';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle,
  PanelTitleButton
} from 'components/panel/panel-title';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';
import { MenuWrapper } from 'components/menu';

import {
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL,
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL_EDIT
} from 'routes/RouteList';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { StoreContext } from 'index';
import { getDateInYears } from 'utils/date';
import Loader from 'components/loader/Loader';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import TabLink from 'components/tabs/TabLink';
import { useMenuContext } from 'contexts/MenuContext';
import ExerciseFeedbackPanel from 'containers/pages/person/programs/ExerciseFeedbackPanel';
import { useStore } from 'stores/Store';
import useProgram from 'hooks/queries/useProgram';
import usePerson from 'hooks/queries/usePerson';
import PersonCard from 'containers/partials/cards/PersonCard';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const PersonFeedback = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId, programId, personId } = useParams();
  const ability = useAbility(AbilityContext);

  const { menuItems } = useMenuContext();

  const { fetchPerson, loading, error } = usePerson({ personId });
  const person = useEntityContext(state => state.person);
  const { fetchProgram } = useProgram();
  const program = useStore(state => state.program);

  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
    [
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL,
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL_EDIT
    ]
  ]);
  const active = useRouteMatch([
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL
  ]);

  useEffect(() => {
    if (!person?.id || personId !== person?.id) {
      fetchPerson(personId);
    }
  }, [personId]);

  useEffect(() => {
    if (!program?.id || programId !== program?.id) {
      fetchProgram({ variables: { id: programId } });
    }
  }, [programId]);

  if (!program) return <Loader />;

  return (
    <>
      <Panel active={active.isExact}>
        {error && <RetryPanel />}
        {loading && <Loader />}
        {person && (
          <>
            <PanelHeader>
              <Breadcrumbs>
                <Breadcrumb
                  to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
                >
                  <FormattedMessage {...messages.breadcrumbOverview} />
                </Breadcrumb>
                <Breadcrumb
                  to={ROUTE_EXERCISES_PROGRAMS_ENTITY.replace(
                    ':entityId',
                    entityId
                  )}
                >
                  <FormattedMessage {...messages.breadcrumbPrograms} />
                </Breadcrumb>
              </Breadcrumbs>
              <PanelTitleWrapper>
                <PanelTitle>
                  {person.firstname} {person.lastname}
                  <MenuWrapper trigger={<PanelTitleButton />}>
                    {menuItems}
                  </MenuWrapper>
                </PanelTitle>
                {!user.isVitaleOrg && (
                  <PanelSubtitle>
                    {person.birthdate && (
                      <FormattedMessage
                        {...messages.labelSporterAge}
                        values={{ age: getDateInYears(person.birthdate) }}
                      />
                    )}
                  </PanelSubtitle>
                )}
              </PanelTitleWrapper>
            </PanelHeader>

            <PanelBody>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList>
                  <TabLink
                    to={generatePath(
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
                      {
                        entityId,
                        programId,
                        personId
                      }
                    )}
                  >
                    <FormattedMessage {...messages.tabFeedback} />
                  </TabLink>

                  {/* Sporter Global */}
                  <TabLink
                    to={generatePath(
                      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL,
                      {
                        entityId,
                        programId,
                        personId
                      }
                    )}
                  >
                    <FormattedMessage {...messages.tabSporterGlobal} />
                  </TabLink>
                </TabList>

                {ability.can('read', 'Programs') && (
                  <TabPanel>
                    <TabPanelBody>
                      <ExerciseFeedbackPanel
                        program={program}
                        personId={personId}
                      />
                    </TabPanelBody>
                  </TabPanel>
                )}

                {/* Sporter Global */}
                <TabPanel>
                  <TabPanelBody withScroller>
                    <PersonCard entityId={entityId} person={person} />
                  </TabPanelBody>
                </TabPanel>
              </Tabs>
            </PanelBody>
          </>
        )}
      </Panel>
    </>
  );
};

export default PersonFeedback;
