import { useContext } from 'react';
import InjurySelect from 'containers/partials/inputs/InjurySelect';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { StoreContext } from 'index';
import { uid } from 'react-uid';
import { FieldDatePicker } from 'components/input/FieldDatePicker';

function InjuryDetailFields({ edit }) {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const {
    values: personInjury,
    setFieldValue,
    touched,
    errors
  } = useFormikContext();

  return (
    <>
      <InjurySelect
        name="injury"
        entityId={rootEntityId}
        readOnly={!edit}
        value={personInjury?.injury.id}
        personInjury={personInjury}
        onChange={selectedItem => {
          if (!selectedItem?.id) {
            setFieldValue('injury.id', null);
            setFieldValue('injury.id', []);
            setFieldValue('injury.id', '');
            return;
          }
          setFieldValue('injury.id', selectedItem.id);
          if (!selectedItem?.sides || selectedItem.sides.length === 0) {
            setFieldValue('sides', { center: true });
          }
          if (selectedItem?.sides?.length > 0) {
            setFieldValue('sides', { left: false, right: false });
          }
        }}
        errors={errors}
        touched={touched}
      />

      <div className="o-layout__item u-1-of-2">
        <FieldDatePicker
          id={`injuryDate_${uid(personInjury)}`}
          name="injuryDate"
          icon="calendar"
          errors={errors}
          touched={touched}
          readOnly={!edit}
          disabled={!edit}
          required
          placeholder={'dd/mm/yyyy'}
        >
          <FormattedMessage {...messages.rehabInjuryDateLabel} />
        </FieldDatePicker>
      </div>
      <div className="o-layout__item u-1-of-2">
        <FieldDatePicker
          id={`injuryEndDate_${uid(personInjury)}`}
          name="injuryEndDate"
          icon="calendar"
          errors={errors}
          touched={touched}
          readOnly={!edit}
          disabled={!edit}
          required
          placeholder={'dd/mm/yyyy'}
        >
          <FormattedMessage {...messages.rehabInjuryEndDateLabel} />
        </FieldDatePicker>
      </div>
    </>
  );
}

export default InjuryDetailFields;
