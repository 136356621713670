import { useState, useContext } from 'react';
import Modal from 'components/modal/Modal';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { StoreContext } from 'index';
import { BenchmarkModalView } from 'containers/pages/benchmarks/modals/benchmark-modal-view';
import SelectGroupModal from 'containers/pages/growthtracker/modals/SelectGroupModal';
import BenchmarksChoiceView from 'containers/pages/benchmarks/modals/BenchmarksChoiceView';
import SelectSessionView from 'containers/pages/benchmarks/modals/SelectSessionView';
import SelectSporterView from 'containers/pages/benchmarks/modals/SelectSporterView';
import {
  ROUTE_GROWTH_ENTITY,
  ROUTE_GROWTH_ENTITY_GROUP,
  ROUTE_GROWTH_ENTITY_SESSION,
  ROUTE_GROWTH_ENTITY_SPORTER
} from 'routes/RouteList';

const GrowthMainModal = ({
  entityId,
  master,
  onSubmitHandler,
  onCloseHandler,
  ...props
}) => {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const [view, setView] = useState(
    props.view || BenchmarkModalView.BENCHMARK_GROUP
  );

  const { push, goBack } = useHistory();
  const { pathname } = useLocation();

  const onSubmitClientHandler = (id, extraId, name) => {
    if (onSubmitHandler && typeof onSubmitHandler === 'function') {
      onSubmitHandler(id, extraId, name);
    }
  };

  const onSubmitMasterHandler = (id, extraId) => {
    let route;
    switch (view) {
      case BenchmarkModalView.BENCHMARK_GROUP:
        route = ROUTE_GROWTH_ENTITY_GROUP.replace(':groupId', id);
        break;
      case BenchmarkModalView.BENCHMARK_SPORTER:
        route = ROUTE_GROWTH_ENTITY_SPORTER.replace(':sporterId', id).replace(
          ':groupId',
          extraId
        );
        break;
      case BenchmarkModalView.BENCHMARK_SESSION:
        route = ROUTE_GROWTH_ENTITY_SESSION.replace(':sessionId', id);
        break;
      default:
        route = ROUTE_GROWTH_ENTITY;
    }

    route = generatePath(route, {
      popup: 'default',
      entityId: rootEntityId,
      benchmarkId: 'default'
    });

    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    }
    if (route !== pathname) {
      push(route);
    }
  };

  const onCancelHandler = () => {
    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    } else {
      goBack();
    }
  };

  const getView = view => {
    switch (view) {
      case BenchmarkModalView.BENCHMARK_CHOICE:
        return BenchmarksChoiceView;
      case BenchmarkModalView.BENCHMARK_GROUP:
        return SelectGroupModal;
      case BenchmarkModalView.BENCHMARK_SESSION:
        return SelectSessionView;
      case BenchmarkModalView.BENCHMARK_SPORTER:
        return SelectSporterView;
      default:
        return SelectGroupModal;
    }
  };

  const Component = getView(view);

  return (
    <Modal
      card
      isOpen
      onClose={onCloseHandler}
      extraClassNames="c-modal__content-bottom-auto"
    >
      <Component
        entityId={entityId}
        onChangeView={view => setView(view)}
        onCancelHandler={onCancelHandler}
        onSubmitHandler={master ? onSubmitMasterHandler : onSubmitClientHandler}
      />
    </Modal>
  );
};

export default GrowthMainModal;
