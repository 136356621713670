import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ROUTE_SETTINGS_USERS } from 'routes/RouteList';
import {
  QUERY_GET_USERS_BY_ENTITY_ID,
  REMOVE_PERSON_FROM_ENTITY
} from 'services/aws/user-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';

const RemoveUserButton = ({
  entityId,
  userId,
  routing: { push },
  children,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={REMOVE_PERSON_FROM_ENTITY}
      variables={{
        personId: userId,
        entityId
      }}
      confirmTitle={messages.modalConfirmRemoveUserTitle}
      confirmMessage={messages.modalConfirmRemoveUserMessage}
      refetchQueries={[
        {
          query: QUERY_GET_USERS_BY_ENTITY_ID,
          variables: { id: entityId }
        }
      ]}
      update={(cache, { data: { removePersonFromEntity } }) => {
        if (removePersonFromEntity) {
          push(ROUTE_SETTINGS_USERS.replace(':setting', 'users'));
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default inject('routing')(withRouter(RemoveUserButton));
