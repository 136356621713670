import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from 'react';
import * as Sentry from '@sentry/react';
import { BenchmarkFilters, BenchmarkTypes, Lateralities } from 'constants.js';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TESTDATA_BY_GROUP } from 'services/aws/session-query';
import TestData from 'models/TestData';
import { StoreContext } from 'index';
import {
  sort,
  SORT_DATA_TYPES,
  SORT_TYPES,
  sortByArray,
  sortResultRowsOnCol
} from 'utils/sort';
import { ROUTE_RESULTS_ENTITY_GROUP } from 'routes/RouteList';
import { generatePath, useHistory } from 'react-router-dom';
import { groupBy } from 'utils/array';
import { getAverageOfGroup, getDataRows } from 'utils/results-benchmark';
import { format } from 'date-fns';
import { useStore } from 'stores/Store';

export const ResultsPanelContext = createContext({});

const getTestItemResultObj = (r, laterality, locale, testDataResult) => {
  return {
    key: `${r.id}_${laterality}`,
    id: `${r.id}_${laterality}`,
    value: `${r.id}_${laterality}`,
    label: `${r?.copy?.[locale]?.title ?? r.title} ${
      Number(laterality) !== 2 ? `- [${Lateralities[laterality]}]` : ''
    }`,
    hidden: r.hidden,
    testSetId: testDataResult.testSet?.id,
    testSetIds: [testDataResult.testSet?.id]
  };
};

const ResultsPanelContextProvider = ({ groupId, benchmarkId, children }) => {
  const {
    authStore: {
      user: { rootEntityId }
    },
    uiState: { locale }
  } = useContext(StoreContext);

  const { replace } = useHistory();
  const location = useHistory();
  const selectedHeaders = useStore.getState().selectedHeaders;
  const { setSelectedHeaders, resetSelectedHeaders } = useStore(state => state);

  const usersArray = useRef([]);
  const testItemsArray = useRef([]);
  const testSetsArray = useRef([]);

  const [athletes, setAthletes] = useState([]);
  const [athletesSortDirection, setAthletesSortDirection] = useState(false);
  const [testItems, setTestItems] = useState([]);
  const [testSets, setTestSets] = useState([]);
  const [type, setType] = useState(BenchmarkTypes.DEFAULT);
  const [benchmarkFilter, setBenchmarkFilter] = useState(BenchmarkFilters.LAST);
  const [rows, setRows] = useState([]);
  const [sidePanelData, setSidePanelData] = useState(null);
  const [sortIndex, setSortIndex] = useState(-1);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (groupId) {
      resetSelectedHeaders();
    }
  }, [groupId]);

  const [fetchBenchmarkedData, { loading, data, error: fetchError, refetch }] =
    useLazyQuery(QUERY_GET_TESTDATA_BY_GROUP);

  useEffect(() => {
    const fetchData = async () => {
      await fetchBenchmarkedData({
        variables: {
          entityId: groupId,
          benchmarkId: benchmarkId !== 'default' ? benchmarkId : null
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      });
    };

    if (groupId) {
      fetchData().catch(error => {
        Sentry.captureException(error);
      });
    }
  }, [groupId, fetchBenchmarkedData, benchmarkId, location]);

  useEffect(() => {
    const fetchS3File = async url => {
      const response = await fetch(url);
      return await response.json();
    };
    const parseTestData = async testData => {
      const athletes = [];
      const testItems = [];
      const testSets = [];

      for (const td of testData) {
        const testDataResult = new TestData(td);
        let athleteResults;

        athleteResults =
          testDataResult.result && Array.isArray(testDataResult.result)
            ? testDataResult.result.map(testItem => {
                testItem.title =
                  testItem?.copy?.[locale]?.title ?? testItem.title;

                testItem.testDate = testDataResult.testDataDate;

                return testItem;
              })
            : [];

        athletes.push({
          ...td.person,
          label: `${td.person.firstname} ${td.person.lastname}`,
          results: [...athleteResults]
        });

        if (Array.isArray(testDataResult.result)) {
          testDataResult.result.forEach(r => {
            if (!r.hidden) {
              if (r?.result) {
                Object.keys(r.result).forEach(laterality => {
                  testItems.push(
                    getTestItemResultObj(r, laterality, locale, testDataResult)
                  );
                });
              }
            }
            if (r?.result) {
              testSets.push({ ...td.testSet });
            }
          });
        }
      }

      const uniqueAthletes = athletes.reduce((newArray, item) => {
        const user = newArray.find(i => i.id === item.id);
        if (user) {
          user.value = user.id;
          user.results = [...user.results, ...item.results];
          return newArray;
        } else {
          return [...newArray, item];
        }
      }, []);
      const uniqueTestItems = testItems.reduce((newArray, item) => {
        const testItem = newArray.find(i => i.id === item.id);
        if (testItem) {
          testItem.testSetIds = Array.from(
            new Set([...testItem.testSetIds, ...item.testSetIds])
          );
          return newArray;
        } else {
          return [...newArray, item];
        }
      }, []);
      const uniqueTestSets = testSets.reduce((newArray, item) => {
        if (newArray.find(i => i.id === item.id)) {
          return newArray;
        } else {
          return [...newArray, item];
        }
      }, []);

      // group athletes test results for easy access
      uniqueAthletes.forEach(athlete => {
        athlete.results = groupBy(athlete.results, ['id']);
      });

      testItemsArray.current = sort(uniqueTestItems, {
        keys: [{ key: 'label' }]
      });

      usersArray.current = sort(uniqueAthletes, {
        keys: [{ key: 'label' }]
      });

      testSetsArray.current = sort(uniqueTestSets, {
        keys: [{ key: 'title' }]
      });

      setAthletes(usersArray.current);
      setTestItems(testItemsArray.current);
      setTestSets(testSetsArray.current);
    };

    if (data?.getTestDataV2OfEntity && data?.getTestDataV2OfEntity.length > 0) {
      const resultObj = data?.getTestDataV2OfEntity?.[0]?.result
        ? JSON.parse(data.getTestDataV2OfEntity[0].result)
        : null;

      if (resultObj?.s3) {
        fetchS3File(resultObj.s3).then(data => {
          parseTestData(data);
        });
      } else {
        parseTestData(data.getTestDataV2OfEntity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (fetchError) {
      if (
        fetchError.graphQLErrors.find(e => e.errorType === 'ExecutionTimeout')
      ) {
        refetch();
      } else {
        usersArray.current = [];
        testItemsArray.current = [];
        testSetsArray.current = [];
        setAthletes([]);
        setTestItems([]);
        setTestSets([]);

        setError(fetchError);
      }
    }
  }, [fetchError]);

  useEffect(() => {
    if (athletes.length > 0) {
      updateRowData({
        testCols: selectedHeaders,
        type,
        benchmarkFilter,
        sortIndex
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    athletes.length,
    testItems,
    type,
    benchmarkFilter,
    JSON.stringify(selectedHeaders),
    benchmarkId,
    sortIndex
  ]);

  const onChangeType = type => {
    setType(type);
  };

  const onChangeBenchmark = benchmarkId => {
    const route = generatePath(ROUTE_RESULTS_ENTITY_GROUP, {
      groupId,
      popup: 'default',
      entityId: rootEntityId,
      benchmarkId
    });
    replace(route);
  };

  const onChangeBenchmarkFilter = benchmarkFilter => {
    setBenchmarkFilter(benchmarkFilter);
  };

  const onChangeTestSet = testSetId => {
    if (!testSetId) {
      setTestItems(testItemsArray.current);
      return;
    }

    const filteredTestItems = [
      ...testItemsArray.current.filter(ti => ti.testSetIds.includes(testSetId))
    ];

    if (!filteredTestItems.length) {
      resetSelectedHeaders();
    }
    const newTestCols = selectedHeaders.map(col => {
      const testItem = filteredTestItems.find(ti => ti.id === col.id);
      if (!testItem || !testItem?.testSetIds.includes(testSetId)) {
        return { id: null, sortDirection: SORT_TYPES.DOWN };
      } else {
        return col;
      }
    });
    setSelectedHeaders([...newTestCols]);
    setTestItems(filteredTestItems);
  };

  const onChangeAthleteCol = athletes => {
    if (athletes.length > 0) {
      const filteredAthletes = [...usersArray.current].filter(u =>
        athletes.find(a => a.value === u.id)
      );
      setAthletes(filteredAthletes);
    } else {
      setAthletes([...usersArray.current]);
    }
  };

  const updateRowData = ({ testCols, type, benchmarkFilter, sortIndex }) => {
    const rows = getDataRows({
      athletes,
      testCols,
      benchmarkFilter
    });

    if (type === BenchmarkTypes.AVERAGE) {
      const allRows = getDataRows({
        athletes: usersArray.current,
        testCols,
        benchmarkFilter
      });
      testCols.forEach((col, index) => {
        if (col.id) {
          const [id, laterality] = col.id.split('_');
          col.average = getAverageOfGroup(allRows, id, index, laterality);
        }
      });
    }

    setRows(rows);

    if (sortIndex === -1 || !testCols?.[sortIndex]?.id) {
      setAthletes(
        sort([...athletes], {
          keys: [{ key: 'label', desc: athletesSortDirection }]
        })
      );
    } else {
      const sortedRows = sortResultRowsOnCol(
        rows,
        sortIndex,
        testCols?.[sortIndex],
        type
      );
      setAthletes(sortByArray(athletes, Object.keys(sortedRows)));
    }
  };

  const onClickCell = (
    colIndex,
    user,
    testItem,
    laterality,
    average = null
  ) => {
    const sessionResults = user.results?.[testItem.id];
    let data = [];

    const values = sessionResults?.reduce(
      (item, session) => {
        const percentage = session?.benchmarked?.[laterality]?.percentage;
        const result = session?.result?.[laterality]?.[0];

        if (result) {
          if (average) {
            item.avg = average;
          }

          if (result < item.min) item.min = Number(result);
          if (result > item.max) item.max = Number(result);

          if (session.testDate || session.sessionDate) {
            const date = session.testDate
              ? format(session.testDate, 'dd/LL/yyyy')
              : format(session.sessionDate, 'dd/LL/yyyy');
            const sortDate = session.testDate
              ? format(session.testDate, 'yyyy-LL-dd')
              : format(session.sessionDate, 'yyyy-LL-dd');

            //
            // testSessionId: ID
            // const url = `sessions/${rootEntityId}/${testSessionId}/sporter/${user.id}`;

            data.push({
              x: `${sortDate}`,
              y: testItem.input_type === 'select_with_options' ? 0 : result,
              result,
              date: date,
              sortDate,
              unit: session.unit,
              percentile: percentage,
              inputType: testItem.input_type
              //   url: url
            });
          }
        }
        return item;
      },
      {
        min: 0,
        max: 0,
        avg: null
      }
    );

    values.benchmarkType = type;

    data = sort(data, {
      keys: [{ key: 'sortDate', dataType: SORT_DATA_TYPES.DATE_STRING }]
    });

    /* Under normal circumstances we will never take 2 equal test on the same
     ** day and the use both of them. So we'll remove the first test
     ** for now: */
    data = data.filter((value, index, array) => {
      return (
        array.findLastIndex(value2 => {
          return value2.date === value.date;
        }) === index
      );
    });

    if (data.length === 0) return;

    const dataObj = {
      title: user.label,
      subTitle: testItem.title,
      laterality,
      chartData: {
        ...values,
        data: [
          {
            id: testItem.id,
            data: [...data]
          }
        ]
      }
    };

    setSidePanelData(dataObj);
  };

  const onCloseSidePanel = () => setSidePanelData(null);

  const onSort = (sortIndex, sortDirection) => {
    const newTestCols = [...selectedHeaders];
    newTestCols[sortIndex].sortDirection = sortDirection;

    setSelectedHeaders([...newTestCols]);
    setSortIndex(sortIndex);

    updateRowData({ testCols: selectedHeaders, benchmarkFilter, sortIndex });
  };

  const onSortAthletes = () => {
    const newAthletes = sort([...athletes], {
      keys: [{ key: 'label', desc: !athletesSortDirection }]
    });

    if (sortIndex > -1) {
      selectedHeaders[sortIndex].sortDirection = SORT_TYPES.DOWN;
      setSortIndex(-1);
    }
    setSelectedHeaders([...selectedHeaders]);
    setAthletesSortDirection(!athletesSortDirection);
    setAthletes(newAthletes);
  };

  return (
    <ResultsPanelContext.Provider
      value={{
        rows,
        athletes,
        usersArray,
        testItems,
        testSets,
        onChangeAthleteCol,
        onClickCell,
        onChangeBenchmark,
        onChangeType,
        onChangeBenchmarkFilter,
        onChangeTestSet,
        benchmarkId,
        type,
        benchmarkFilter,
        sidePanelData,
        onCloseSidePanel,
        onSort,
        onSortAthletes,
        sortIndex,
        athletesSortDirection,
        loading,
        error
      }}
    >
      {children}
    </ResultsPanelContext.Provider>
  );
};

function useResultsPanelContext() {
  const context = useContext(ResultsPanelContext);
  if (context === undefined) {
    throw new Error(
      'The ResultsPanelContext hook must be used within a ResultsPanelContext.Provider'
    );
  }
  return context;
}

export { ResultsPanelContextProvider, useResultsPanelContext };
