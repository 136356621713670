import classNames from 'classnames';
import Icon from '../icon/Icon';

const ListColumnTitle = props => {
  const {
    ascendent,
    descendent,
    sortableByProgress,
    middleColumn,
    unClickable,
    onClick,
    children,
    style,
    extraClassNames
  } = props;

  return (
    <button
      className={classNames(
        'c-list__col c-list__col-title c-button',
        extraClassNames,
        {
          'c-list__col-asc': ascendent,
          'c-list__col-by-progress': sortableByProgress,
          'c-list__col-middle': middleColumn,
          'c-button--read-only': unClickable
        }
      )}
      disabled={unClickable}
      onClick={onClick}
      style={style}
      type="button"
    >
      {children}
      {(ascendent || descendent) && <Icon id={'desc'} />}
    </button>
  );
};

export default ListColumnTitle;
