import { Fragment, useContext, useState } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_RECURRING,
  ROUTE_SESSIONS_ENTITY_SESSION,
  ROUTE_SESSIONS_ENTITY_SESSIONS,
  ROUTE_SESSIONS_ENTITY_TEMPLATES
} from 'routes/RouteList';
import Button from 'components/button/Button';
import Panel from 'components/panel/Panel';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import Icon from 'components/icon/Icon';
import { sortAndGroupSessions } from 'utils/sort';
import Modal from 'components/modal/Modal';
import ExportSessionsCard from 'containers/partials/cards/ExportSessionsCard';
import MenuWrapper from 'components/menu/MenuWrapper';
import {
  PanelBody,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel';
import messages from 'messages';
import CopyUrlModal from 'containers/partials/modals/CopyUrlModal';
import { getTestSessionURL } from 'utils/url';
import { PackageTypes, TestSetV2Type } from 'constants.js';
import { StoreContext } from 'index';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { ButtonExpandable } from 'components/button';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import useTabIndex from 'hooks/utils/useTabIndex';
import Loader from 'components/loader/Loader';
import PreventionTemplateList from 'containers/pages/prevention/PreventionTemplateList';
import AddTemplateModal from 'containers/pages/prevention/AddTemplateModal';
import Session from 'models/Session';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useSessionsContext } from 'contexts/SessionsContext';
import AddSessionModal from 'containers/pages/sessions/AddSessionModal';
import SessionsList from 'containers/pages/sessions/SessionsList';
import RecurringSessionsList from 'containers/pages/sessions/RecurringSessionsList';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';

const Overview = () => {
  const intl = useIntl();
  const {
    authStore: { user, entity },
    uiState: { locale }
  } = useContext(StoreContext);
  const { entityId } = useParams();
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);
  const [exportModal, setExportModal] = useState(false);
  const [setSelectedSessions] = useState([]); // TODO is this still necessary?
  const [copyUrlModal, setCopyUrlModal] = useState(false);
  const [urlModalContent, setUrlModalContent] = useState(null);
  const sessionTabs = [ROUTE_SESSIONS_ENTITY_SESSIONS];

  const active = useRouteMatch([ROUTE_SESSIONS_ENTITY]);

  if (ability.can('create', 'Prevention')) {
    sessionTabs.push(ROUTE_SESSIONS_ENTITY_TEMPLATES);
  }
  if (ability.can('create', 'RecurringSessions')) {
    sessionTabs.push(ROUTE_SESSIONS_ENTITY_RECURRING);
  }

  const [tabIndex, setTabIndex] = useTabIndex(sessionTabs, false);
  const [sessionModal, setSessionModal] = useState(null);
  const [templateModal, setTemplateModal] = useState(null);
  const { sessions, recurringSessions, loading, error } = useSessionsContext();

  const onCloseModal = () => {
    setExportModal(false);
  };

  const onSelectSessionsChangeHandler = selectedSessions => {
    setSelectedSessions(selectedSessions);
    setUrlModalContent(getModalContent(selectedSessions));
  };

  const getModalContent = selectedSessions => {
    if (!sessions) return null;

    const sessionsFiltered = selectedSessions.filter(
      s => !s.isDone && s.config.type !== PackageTypes.MTS
    );
    if (sessionsFiltered.length > 1) {
      const sortedSessions = sortAndGroupSessions(sessionsFiltered);
      const urls = sortedSessions.map(
        session =>
          session.name +
          ':\n' +
          getTestSessionURL({
            entityId,
            sessionId: session.id,
            packageType: session?.copy?.type ?? session.type,
            locale,
            sporterId: false,
            session,
            copyAble: true
          })
      );
      return urls.join('\n\n');
    } else if (sessionsFiltered.length === 1) {
      const session = sessionsFiltered[0];
      return getTestSessionURL({
        entityId,
        sessionId: session.id,
        packageType: session?.copy?.type ?? session.type,
        locale
      });
    }
    return null;
  };

  const onSessionsTabClick = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY, {
        entityId,
        sessionsTab: 'sessions'
      })
    );
  };

  const onRecurringSessionsTabClick = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_RECURRING, {
        entityId,
        sessionsTab: 'recurring'
      })
    );
  };

  const onTemplatesTabClick = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_TEMPLATES, {
        entityId,
        sessionsTab: 'templates'
      })
    );
  };

  const onSessionClickHandler = (session, tab) => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_SESSION, {
        entityId,
        sessionsTab: tab,
        sessionId: session.id
      })
    );
  };

  const onClickNewTalentSessionHandler = () => {
    setSessionModal({
      title: intl.formatMessage(messages.titleSessionsNewSession),
      type: 'session',
      session: new Session({
        type: TestSetV2Type.TALENT,
        recurring: false
      })
    });
  };

  const onClickNewPreventionSessionHandler = () => {
    setSessionModal({
      title: intl.formatMessage(messages.preventionModalAddNewTitle),
      type: 'prevention',
      session: new Session({
        type: TestSetV2Type.PREVENTION,
        recurring: false
      })
    });
  };

  const onClickNewRecurringSessionHandler = () => {
    setSessionModal({
      title: intl.formatMessage(messages.titleRecurringSessionsNewSession),
      type: 'recurring',
      session: new Session({
        recurring: true,
        type: TestSetV2Type.TALENT
      })
    });
  };

  return (
    <>
      <Panel active={active.isExact}>
        {loading && <Loader />}
        {error && <RetryPanel />}

        {sessions && (
          <Fragment>
            {exportModal && (
              <Modal card autoHeight isOpen={true} onClose={onCloseModal}>
                <ExportSessionsCard onClose={onCloseModal} />
              </Modal>
            )}
            {copyUrlModal && (
              <CopyUrlModal
                isOpen={copyUrlModal}
                content={urlModalContent}
                onClose={() => {
                  setCopyUrlModal(false);
                  setSelectedSessions([]);
                  setUrlModalContent(null);
                }}
              />
            )}

            <RefactoredPanelHeader>
              <RefactoredPanelTitleWrapper>
                <PanelTitle>
                  <FormattedMessage {...messages.titleSessions} />
                </PanelTitle>
                <PanelSubtitle>
                  <FormattedMessage
                    {...messages.subtitleSessions}
                    values={{
                      name: user.getEntityName(entity)
                    }}
                  />
                </PanelSubtitle>
              </RefactoredPanelTitleWrapper>

              <PanelTitleButtonsGroup>
                {ability.can('create', 'Sessions') && (
                  <CreateNewButtons
                    tabIndex={tabIndex}
                    ability={ability}
                    onClickNewTalentSessionHandler={
                      onClickNewTalentSessionHandler
                    }
                    onClickNewPreventionSessionHandler={
                      onClickNewPreventionSessionHandler
                    }
                    setTemplateModal={setTemplateModal}
                    onClickNewRecurringSessionHandler={
                      onClickNewRecurringSessionHandler
                    }
                  />
                )}

                {/* // Meatballs disabled, export and copy url modals doesn't work anymore */}
                {!user.isAthlete() && (
                  <MenuWrapper trigger={<PanelTitleButton />}>
                    {!urlModalContent && (
                      <Button menu onClick={() => setExportModal(true)}>
                        <FormattedMessage
                          {...messages.menuItemSessionExportSessions}
                        />
                        <Icon id="download" />
                      </Button>
                    )}
                    {urlModalContent && (
                      <Button menu onClick={() => setCopyUrlModal(true)}>
                        {urlModalContent.search('\n') !== -1 ? (
                          <FormattedMessage
                            {...messages.menuItemSessionCopyUrls}
                          />
                        ) : (
                          <FormattedMessage
                            {...messages.menuItemSessionOverviewCopyUrl}
                          />
                        )}
                        <Icon id="copy" />
                      </Button>
                    )}
                  </MenuWrapper>
                )}
              </PanelTitleButtonsGroup>
            </RefactoredPanelHeader>
            <PanelBody>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList>
                  <Tab key="session" onClick={onSessionsTabClick}>
                    <FormattedMessage {...messages.tabSessionsSessions} />
                  </Tab>
                  {ability.can('create', 'Prevention') && (
                    <Tab
                      key="prevention.templates"
                      onClick={onTemplatesTabClick}
                    >
                      <FormattedMessage {...messages.tabSessionsTemplates} />
                    </Tab>
                  )}
                  {ability.can('create', 'RecurringSessions') && (
                    <Tab
                      key="recurring.sessions"
                      onClick={onRecurringSessionsTabClick}
                    >
                      <FormattedMessage {...messages.tabRecurringSessions} />
                    </Tab>
                  )}
                </TabList>
                <TabPanel key="session">
                  <TabPanelBody>
                    {sessions.length > 0 && (
                      <SessionsList
                        onClick={session =>
                          onSessionClickHandler(session, 'sessions')
                        }
                        onSelected={onSelectSessionsChangeHandler}
                      />
                    )}
                  </TabPanelBody>
                </TabPanel>
                {ability.can('create', 'Prevention') && (
                  <TabPanel key="prevention.templates">
                    <TabPanelBody>
                      <PreventionTemplateList
                        onUse={template =>
                          setSessionModal({
                            title: intl.formatMessage(
                              messages.preventionModalAddTitle,
                              {
                                type: template.preventionType,
                                sessionExists: Boolean(template.name),
                                isTemplate: true,
                                session: template.name,
                                span: chunks => (
                                  <span className="c-card__header-highlight">
                                    {chunks}
                                  </span>
                                )
                              }
                            ),
                            type: 'prevention',
                            isCopy: true,
                            session: template
                          })
                        }
                      />
                    </TabPanelBody>
                  </TabPanel>
                )}
                {ability.can('create', 'RecurringSessions') && (
                  <TabPanel key="recurring.sessions">
                    <TabPanelBody>
                      {recurringSessions.length > 0 && (
                        <RecurringSessionsList
                          onClick={session =>
                            onSessionClickHandler(session, 'recurring')
                          }
                        />
                      )}
                    </TabPanelBody>
                  </TabPanel>
                )}
              </Tabs>
            </PanelBody>
          </Fragment>
        )}
      </Panel>

      {sessionModal && (
        <AddSessionModal
          entityId={entityId}
          title={sessionModal.title}
          type={sessionModal.type}
          isCopy={sessionModal.isCopy}
          session={sessionModal.session}
          onClose={() => {
            setSessionModal(false);
          }}
        />
      )}
      {templateModal && (
        <AddTemplateModal
          entityId={entityId}
          onClose={() => {
            setTemplateModal(false);
          }}
        />
      )}
    </>
  );
};

export default Overview;

const CreateNewButtons = props => {
  const {
    tabIndex,
    ability,
    onClickNewTalentSessionHandler,
    onClickNewPreventionSessionHandler,
    setTemplateModal,
    onClickNewRecurringSessionHandler
  } = props;

  const intl = useIntl();

  //   let component;
  switch (tabIndex) {
    case 0:
      return ability.can('create', 'Prevention') ? (
        <MenuWrapper
          extraClassNames={'c-menu__wrapper--expandable'}
          trigger={
            // TODO: maarten, ik zou hier eigenlijk geen dropdown zetten.
            // Gewoon een knop die een modal triggert, en in de modal kan
            // gekozen worden tussen prevention of talent (of rehab, evt)
            // <Button primary rounded>
            //   <Icon id="add-white" />
            //   <FormattedMessage {...messages.buttonSessionsAddSession} />
            //   <Icon id="dropdown-open" fillColor="color-neutral-x-light" />
            // </Button>
            <ButtonExpandable
              tabIndex={tabIndex}
              label={intl.formatMessage(messages.buttonSessionsAddSession)}
            >
              <Icon id="dropdown-open" fillColor="color-neutral-x-light" />
            </ButtonExpandable>
          }
        >
          <Button menu onClick={onClickNewTalentSessionHandler}>
            <FormattedMessage {...messages.preventionTalentButton} />
            <Icon id="test" />
          </Button>

          <Button menu onClick={onClickNewPreventionSessionHandler}>
            <FormattedMessage {...messages.preventionButton} />
            <Icon id="test" />
          </Button>
        </MenuWrapper>
      ) : (
        <ButtonExpandable
          tabIndex={tabIndex}
          onClick={onClickNewTalentSessionHandler}
          label={intl.formatMessage(messages.buttonSessionsAddSession)}
        />
      );
    case 1:
      return (
        <ButtonExpandable
          tabIndex={tabIndex}
          onClick={() => setTemplateModal(true)}
          label={intl.formatMessage(messages.buttonSessionsAddTemplate)}
        />
      );
    case 2:
      return (
        ability.can('create', 'RecurringSessions') && (
          <ButtonExpandable
            tabIndex={tabIndex}
            onClick={onClickNewRecurringSessionHandler}
            label={intl.formatMessage(
              messages.titleRecurringSessionsNewSession
            )}
          />
        )
      );

    default:
      break;
  }
};
