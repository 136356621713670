import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useEdit from 'hooks/utils/useEdit';
import { useParams, useRouteMatch } from 'react-router-dom';
import { ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT } from 'routes/RouteList';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import { CardBody } from 'components/card';
import CardFooter from 'components/card/CardFooter';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import FieldInput from 'components/input/FieldInput';
import Template from 'models/Template';
import { TemplateType } from 'constants.js';
import { useStore } from 'stores/Store';

const CollectionDetailsTab = ({ onCancel }) => {
  const intl = useIntl();
  const { entityId } = useParams();

  const [edit, setEdit] = useEdit(
    !!useRouteMatch([
      ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT
    ])
  );

  const collection = useStore(state => state.collection);

  return (
    <TemplateForm
      entityId={entityId}
      template={
        new Template({
          ...collection,
          type: TemplateType.COLLECTION
        })
      }
      type={TemplateType.COLLECTION}
      extraClassNames={'c-card c-card--secondary'}
      onComplete={() => setEdit(false)}
    >
      {({ errors, touched, resetForm, submitForm }) => {
        return (
          <>
            <CardHeader secondary>
              <CardHeaderTitle>
                <FormattedMessage {...messages.collectionDetailTitle} />
              </CardHeaderTitle>
              <FormEditHeaderButtons
                edit={edit}
                setEdit={setEdit}
                onCancel={resetForm}
                onSubmit={submitForm}
              />
            </CardHeader>
            <CardBody secondary>
              <FieldInput
                id="title"
                name="title"
                placeholder={intl.formatMessage(
                  messages.collectionNamePlaceholder
                )}
                errors={errors}
                touched={touched}
                readOnly={!edit}
                required
              >
                <FormattedMessage {...messages.collectionNameLabel} />
              </FieldInput>
            </CardBody>
            {edit && (
              <CardFooter secondary>
                <FormEditFooterButtons
                  edit={edit}
                  setEdit={edit => setEdit(edit)}
                  onCancel={() => {
                    resetForm();
                    onCancel && onCancel();
                  }}
                  onSubmit={submitForm}
                  type={'button'}
                />
              </CardFooter>
            )}
          </>
        );
      }}
    </TemplateForm>
  );
};

export default CollectionDetailsTab;
