import { useEffect, useState } from 'react';
import List from 'components/list/List';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import ListBody from 'components/list/ListBody';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useTestsContext } from 'contexts/TestsContext';
import { usePreviewModalContext } from 'contexts/PreviewModalContext';
import { TestListItem } from 'containers/partials/list-items/TestListItem';
import { groupTests } from 'utils/tests';
import { uid, useUIDSeed } from 'react-uid';
import useSelectable from 'hooks/utils/useSelectable';
import Loader from 'components/loader/Loader';

function TestsList({ type, edit, selectedTests, checkable, onSelect }) {
  const { testsState, testActions, loading } = useTestsContext();
  const { selected, select, setSelected } = useSelectable([]);
  const { setModalData } = usePreviewModalContext();
  const [tests, setTests] = useState([]);
  const uidSeed = useUIDSeed();

  useEffect(() => {
    testActions.getTests(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (testsState.allTests) {
      setTests(groupTests(testsState.allTests));
    }
  }, [testsState.allTests]);

  useEffect(() => {
    setSelected(selectedTests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTests]);

  const onSelectTestChangeHandler = (test, checked) => {
    const selectIds = select(test.id);
    if (test?.dependedTestIds) {
      test.dependedTestIds.forEach(id => {
        select(id);
      });
      if (checked) {
        selectIds.push(...test.dependedTestIds);
      }
    }
    onSelect && onSelect([...new Set(selectIds)], test, checked);
  };

  if (loading) return <Loader />;

  if (Object.keys(tests).length > 0) {
    return (
      <>
        {Object.keys(tests).map((category, index) => (
          <List
            // dragNDropWithPreview
            key={uid(category, index)}
            preview
            style={{
              minWidth: '0',
              margin: '0 0 24px 0',
              height: 'auto',
              padding: 0
            }}
          >
            <ListHeader oneTitle>
              <ListColumnTitle>{category}</ListColumnTitle>
            </ListHeader>
            <ListBody>
              {tests[category].length > 0 &&
                tests[category].map(
                  (test, index) =>
                    test.id && (
                      <TestListItem
                        draggableDisabled
                        uid={uidSeed(test)}
                        key={uid(test.id, index)}
                        test={test}
                        disabled={!edit}
                        checkbox={edit}
                        edit={edit}
                        checked={checkable && selected.includes(test.id)}
                        dependentEditable
                        onClick={() =>
                          setModalData({ type: 'testItem', data: test })
                        }
                        onChange={e => onSelectTestChangeHandler(test, e)}
                        clickable
                      />
                    )
                )}
            </ListBody>
          </List>
        ))}
      </>
    );
  }

  return (
    <div className="c-card__body-list-wrapper">
      <Message emptyState={true} icon="cursor">
        <MessageText>
          <FormattedMessage {...messages.testListEmpty} />
        </MessageText>
      </Message>
    </div>
  );
}

export default TestsList;
