import Entity from 'models/Entity';
import Person from 'models/Person';

export default class PersonEntity {
  id;
  entityId;
  entity;
  title;
  topLevelEntityId;
  personId;
  person;
  roles;
  roletype;
  testSessions;
  person_meta;
  baseLanguage;

  constructor({
    id,
    entityId,
    entity,
    title,
    topLevelEntityId,
    personId,
    person,
    roles,
    roletype,
    testSessions,
    person_meta,
    baseLanguage
  }) {
    this.id = id;
    this.entityId = entityId;
    this.entity = entity ? new Entity({ ...entity }) : null;
    this.title = this.entity ? this.entity.name : title || '';
    this.topLevelEntityId = topLevelEntityId;
    this.personId = personId;
    this.person = person ? new Person({ ...person }) : null;
    this.roles = roles || [];
    this.roletype = roletype;
    this.testSessions = testSessions;
    try {
      this.person_meta = JSON.parse(person_meta);
    } catch (e) {
      this.person_meta = {};
    }
    this.baseLanguage = baseLanguage;
  }
}
