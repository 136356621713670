import classNames from 'classnames';
import { useLayoutEffect, useRef } from 'react';
import { Tabs as ReactTabs } from 'react-tabs';
import { useWindowSize, useMeasure } from '@uidotdev/usehooks';

import './Tabs.scss';

const Tabs = ({
  fullWidth,
  fullWidthB,

  hrefWithin,
  marginNegativeTop = 0,
  onModal = false,
  ...props
}) => {
  //   TODO: Refactor deze, want het is ook gebruik bij CardBody.js,
  // en het zou wel in verschillende plaatsen gebruik worden.
  // zeker handig als een vaste height nodig is, om binnen
  // een virtualized-list in te steken.
  const size = useWindowSize();
  const tabRef = useRef(null);
  const [ref = tabRef, { width, height }] = useMeasure();

  useLayoutEffect(() => {
    if (ref) {
      const tabPanelsSelected = tabRef?.current.querySelectorAll(
        '.react-tabs__tab-panel--selected'
      );

      if (tabPanelsSelected.length > 0) {
        tabPanelsSelected.forEach(selected => {
          const tabTopPositionSelected = selected.getBoundingClientRect().top;

          if (onModal) {
            selected.style.height = `calc(100dvh - ${484}px)`; // + 24
            selected.style.overflowY = 'hidden';
            selected.style.overflowX = 'hidden';
          } else {
            selected.style.height = `calc(100dvh - ${marginNegativeTop}px - ${tabTopPositionSelected}px)`; // + 24
            selected.style.overflowY = 'hidden';
            selected.style.overflowX = 'hidden';
          }
        });
      }
    }
  }, [props.children, size, ref, height]);

  return (
    <div
      ref={tabRef}
      className={classNames('p-tabs', props.extraClassNames, {
        'p-tabs--full-width': fullWidth,
        'p-tabs--full-width p-tabs--full-width-b': fullWidthB,
        'p-tabs--click-on-link': hrefWithin
      })}
    >
      <ReactTabs {...props}>{props.children}</ReactTabs>
    </div>
  );
};

export default Tabs;
