import { generatePath, useParams } from 'react-router-dom';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Badge from 'components/badge/Badge';
import ReportCard from 'containers/partials/cards/ReportCard';
import EditTestResultCard from 'containers/partials/cards/EditTestResultCard';
import { useRouteMatch } from 'react-router-dom';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useTestDataContext } from 'contexts/TestDataContext';
import TabLink from 'components/tabs/TabLink';
import Loader from 'components/loader/Loader';
import React from 'react';

const SessionTabs = ({ edit }) => {
  const { entityId, sessionId, groupId, sporterId, testDataId, programId } =
    useParams();
  const { testDataLoading, hasResults } = useTestDataContext();
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA
    ]
  ]);
  const isAdviceView = useRouteMatch(
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  );

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
      fullWidth={isAdviceView}
    >
      <TabList>
        <TabLink
          key="session.sporter.report"
          to={
            testDataId &&
            generatePath(
              ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
              {
                entityId,
                groupId,
                sessionId,
                sporterId,
                testDataId
              }
            )
          }
          disabled={!hasResults()}
        >
          <FormattedMessage {...messages.tabSessionSporterReport} />
        </TabLink>
        <TabLink
          key="session.sporter.data"
          to={
            testDataId
              ? generatePath(
                  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
                  {
                    entityId,
                    groupId,
                    sessionId,
                    sporterId,
                    testDataId
                  }
                )
              : generatePath(
                  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                  {
                    entityId,
                    groupId,
                    sessionId,
                    sporterId
                  }
                )
          }
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </TabLink>

        {isAdviceView && (
          <TabLink
            key="session.sporter.advice"
            to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE, {
              entityId,
              sessionsTab: 'sessions',
              sessionId,
              sporterId,
              programId
            })}
          >
            <Badge>
              <FormattedMessage {...messages.badgeSessionSporterAdvice} />
            </Badge>
            <FormattedMessage {...messages.tabSessionSporterAdvice} />
          </TabLink>
        )}
      </TabList>

      {/* Tab > REPORT */}
      <TabPanel key="session.sporter.report">
        <TabPanelBody withScroller>
          {testDataLoading ? (
            <Loader />
          ) : (
            <ReportCard
              rootPath={generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
                {
                  entityId,
                  groupId,
                  sporterId,
                  sessionId
                }
              )}
              compressed
            />
          )}
        </TabPanelBody>
      </TabPanel>

      {/* Tab > DATA */}
      <TabPanel key="session.sporter.data">
        <TabPanelBody withScroller>
          {testDataLoading ? (
            <Loader />
          ) : (
            <EditTestResultCard
              entityId={entityId}
              sporterId={sporterId}
              edit={edit}
              rootPath={generatePath(
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
                {
                  entityId,
                  groupId,
                  sporterId,
                  sessionId
                }
              )}
            />
          )}
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

export default SessionTabs;
