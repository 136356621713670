import { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import { es, nl, fr, pt } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';
import './FieldDatePicker.scss';
import { StoreContext } from 'index';

registerLocale('es', es);
registerLocale('nl', nl);
registerLocale('fr', fr);
registerLocale('pt', pt);

export const HyDatePicker = ({
  selectedDate,
  onChange,
  children,
  ...props
}) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <>
      {props.label && (
        <label className={classNames('c-input__label', {})}>
          {props.label}
        </label>
      )}
      <DatePicker
        popperPlacement={props.popperPlacement}
        selected={selectedDate}
        locale={props.locale ?? locale}
        dateFormat="dd/MM/yyyy"
        showMonthDropdown
        showYearDropdown
        dateFormatCalendar=" "
        wrapperClassName="p-react-datepicker"
        minDate={props.min}
        maxDate={props.max}
        highlightDates={props.highlightDates}
        required={props.required}
        autoFocus={props.autoFocus}
        placeholderText={props.placeholder}
        readOnly={props.readOnly}
        disabled={props.disabled || props.readOnly}
        onChange={date => onChange(date)}
        customInput={
          props.customInput ? (
            props.customInput(selectedDate, props.onClick)
          ) : (
            <ExampleCustomInput />
          )
        }
        //   onBlur={() => {
        //     validateField(props.name);
        //   }}
        //   onCalendarClose={() => {
        //     validateField(props.name);
        //   }}
        //   onChangeRaw={e => {
        //     handleChange(e);
        //   }}
      />
    </>
  );
};
