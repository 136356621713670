import { generatePath, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Panel from 'components/panel/Panel';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import messages from 'messages';
import { PanelHeader } from 'components/panel';
import { PanelTitle, PanelTitleWrapper } from 'components/panel/panel-title';
import ListItem from 'components/list/list-item/ListItem';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import {
  ROUTE_EXERCISES_ENTITY_ALL_EXERCISES,
  ROUTE_EXERCISES_ENTITY_MY_EXERCISES,
  ROUTE_EXERCISES_INDEX,
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY
} from 'routes/RouteList';
import { useRouteMatch } from 'react-router-dom';

const Overview = ({ entityId }) => {
  const { push } = useHistory();
  const active = useRouteMatch(ROUTE_EXERCISES_INDEX)?.isExact;
  return (
    <Panel active={active}>
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.exercisesTitle} />
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>

      <List>
        <ListBody>
          <ListItem
            active={!!useRouteMatch(ROUTE_EXERCISES_PROGRAMS_ENTITY)}
            onClick={() =>
              push(generatePath(ROUTE_EXERCISES_PROGRAMS_ENTITY, { entityId }))
            }
          >
            <ListItemStatus statusIcon="program" mediumIcon />
            <ListItemLabelWrapper>
              <ListItemLabel>
                <FormattedMessage {...messages.programs} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          <ListItem
            active={!!useRouteMatch(ROUTE_EXERCISES_TEMPLATES_ENTITY)}
            onClick={() =>
              push(generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY, { entityId }))
            }
          >
            <ListItemStatus statusIcon="template" mediumIcon />
            <ListItemLabelWrapper>
              <ListItemLabel>
                <FormattedMessage {...messages.templates} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
          <ListItem
            active={
              !!useRouteMatch({
                path: [
                  ROUTE_EXERCISES_ENTITY_ALL_EXERCISES,
                  ROUTE_EXERCISES_ENTITY_MY_EXERCISES
                ],
                exact: true
              }) /*&& entityParam === entityId*/
            }
            onClick={() =>
              push(
                generatePath(ROUTE_EXERCISES_ENTITY_ALL_EXERCISES, { entityId })
              )
            }
          >
            <ListItemStatus statusIcon="exercise" mediumIcon />
            <ListItemLabelWrapper>
              <ListItemLabel>
                <FormattedMessage {...messages.exercisesTitle} />
              </ListItemLabel>
            </ListItemLabelWrapper>
          </ListItem>
        </ListBody>
      </List>
    </Panel>
  );
};

export default Overview;
