import { groupBy } from 'utils/array';
import { sort, SORT_DATA_TYPES, sortByArray } from 'utils/sort';
import { NoteTypes } from 'constants.js';
import Exercise from 'models/Exercise';
import { add, isFuture, isPast } from 'date-fns';
import differenceInDays from 'date-fns/differenceInDays';
import getUnixTime from 'date-fns/getUnixTime';
import Person from 'models/Person';

export default class Program {
  id;
  entityId;
  title;
  startdate;
  endDate;
  duration;
  persons = [];
  personEntities = [];
  description = '';
  exercises = [];
  exerciseIds = [];
  notes = [];
  feedback = [];

  constructor({
    id = null,
    entityId = null,
    title = '',
    startdate = '',
    duration = '',
    persons = [],
    personEntities = [],
    description = '',
    exercises = [],
    exerciseIds = [],
    notes = []
  }) {
    this.id = id;
    this.entityId = entityId;
    this.title = title;
    this.startdate = startdate ? new Date(startdate) : '';
    this.duration = duration;
    this.endDate = this.startdate
      ? add(this.startdate, { weeks: this.duration })
      : '';
    this.daysLeft = this.endDate
      ? differenceInDays(this.endDate, new Date())
      : 0;
    this.sortDate = this.endDate ? getUnixTime(this.endDate) : 0;
    this.personEntities = personEntities || [];
    this.description = description;
    this.exerciseIds = exerciseIds || [];

    if (!exerciseIds.length && exercises.length) {
      this.exerciseIds = exercises.map(e => e.id);
    }

    this.exercises = sortByArray(
      exercises.map(e => new Exercise(e)),
      this.exerciseIds
    );

    this.feedback = notes.filter(n => n.noteType === NoteTypes.FEEDBACK);

    this.persons = persons.map(p => new Person({ ...p })) || [];

    const groupedNotes = groupBy(
      notes.filter(n => n.noteType === NoteTypes.PROGRAM),
      ['linkId2']
    );
    Object.keys(groupedNotes).forEach(key => {
      groupedNotes[key] = sort(groupedNotes[key], {
        keys: [
          {
            key: 'date_created',
            desc: true,
            dataType: SORT_DATA_TYPES.NUMBER
          }
        ]
      });
    });
    this.notes = groupedNotes;

    this.sortDate = this.endDate ? getUnixTime(this.endDate) : 0;
    this.order = 1;
    this.status = '';
    /*this.dateString = `${format(this.startdate, 'dd/LL/yyyy')} > ${format(
      this.endDate,
      'dd/LL/yyyy'
    )}`;*/
    if (this.startdate && isFuture(this.startdate)) {
      this.order = 2;
      this.sortDate = getUnixTime(this.startdate);
    }
    if (
      this.startdate &&
      this.endDate &&
      isPast(this.startdate) &&
      isFuture(this.endDate)
    ) {
      if (this.daysLeft <= 14) {
        this.order = 0;
        this.status = 'inProgress';
      }
      /*dateString = intl.formatMessage(
          messages.exercisesProgramStatusDateInProgress,
          {
            time: formatDistanceToNow(endDate, {
              locale: DATE_LOCALE[locale]
            })
          }
      );*/
    }
    if (this.endDate && isPast(this.endDate)) {
      this.order = 3;
      this.status = 'done';
      // dateString = intl.formatMessage(messages.exercisesProgramStatusDateEnded);
    }
  }

  static get modelName() {
    return 'Program';
  }
}
