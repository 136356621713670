import { makeObservable, observable } from 'mobx';
import { FinishedState } from 'enums';
import { sortTestdataOnDateUpdated } from 'utils/sort';
import { PackageSubTypes, PackageTypes, TestSetV2Type } from 'constants.js';
import Person from 'models/Person';
import { format } from 'date-fns';

export default class Session {
  id = null;
  name = '';
  language = null;
  injuryIds;
  groupedTests = [];
  filtered = true;
  meta = null;

  constructor({
    id = null,
    name = '',
    location = '',
    notes = '',
    startdate = '',
    enddate = '',
    benchmark,
    testSetIds = [],
    testSetTemplateIds = [],
    personEntities = [],
    testData = [],
    finished = FinishedState.NOT_STARTED,
    finishedPercentage = 0,
    meta = null,
    type = '',
    preventionType = '',
    testSets = [],
    tests = [],
    sport = null,
    isTypeV2 = false,
    recurring = false,
    interval = null,
    language,
    templateId,
    filtered = true
  }) {
    makeObservable(this, {
      id: observable,
      name: observable
    });
    this.id = id;
    this.name = name;
    this.location = location || '';
    this.notes = notes || '';
    this.startdate = startdate ? new Date(startdate) : null;
    this.enddate = enddate ? new Date(enddate) : null;
    this.testSetIds = testSetIds || [];
    this.testSetId = this.testSetIds.length > 0 ? this.testSetIds[0] : null;
    this.testSetTemplateIds = testSetTemplateIds || [];
    this.milestoneTestSetId = testSetTemplateIds?.[0] || 0;
    //this.testSet.url = ''; // this.testSet.url || ''; //https://s.surveyanyplace.com/s/mejisnga/?cf1={UID}&cf2={FIRSTNAME}&cf3={sporterId}&cf4={sessionId}';
    this.personEntities = personEntities || [];
    this.testData = testData || [];
    this.finished = finished || FinishedState.NOT_STARTED;
    this.finishedPercentage =
      finishedPercentage === null ? 0 : finishedPercentage;
    this.meta = meta;
    this.benchmark = benchmark;
    this.type = type;
    this.preventionType = preventionType;
    this.testSets = testSets || [];
    this.tests = tests;
    this.sport = sport;
    this.isTypeV2 = isTypeV2;
    this.isTestSetV2 =
      testSets?.[0]?.testSetVersion && testSets?.[0]?.testSetVersion === 2;
    this.version =
      testSets?.[0]?.testSetVersion && testSets?.[0]?.testSetVersion === 2
        ? 2
        : 1;

    if (typeof meta === 'string') {
      try {
        this.meta = JSON.parse(meta);
      } catch (e) {
        this.meta = null;
      }
    }
    if (typeof meta === 'object') {
      this.meta = meta;
    }

    this.language = language || null;
    if (this.meta) {
      const { language: metaLanguage, ...injuryIds } = this.meta;
      this.injuryIds = injuryIds;
      this.language = metaLanguage;
    }

    this.recurring = recurring ?? false;
    this.interval = interval || null;

    this.templateId = templateId || null;

    this.filtered = filtered;

    // json parse config if it is a string
    if (typeof testSets?.[0]?.config === 'string') {
      this.config = JSON.parse(testSets?.[0]?.config) ?? {};
    } else if (testSets?.[0]?.config) {
      this.config = testSets?.[0]?.config;
    } else {
      this.config = {};
    }

    // workaround for Klusce tests
    if (!this.config?.type && this.id) {
      if (this.type === 'talent' && this.isTestSetV2) {
        this.config = { ...this.config, type: 'ido' };
      }
    }
  }

  get inProgress() {
    if (this.recurring) {
      return this.startdate < new Date() && this.enddate > new Date();
    } else {
      const finishedPercentage = this.finishedPercentage;
      return (
        finishedPercentage !== 0 &&
        finishedPercentage !== 100 &&
        this.finished !== FinishedState.FINISHED
      );
    }
  }

  get isDone() {
    if (this.recurring) {
      return this.enddate < new Date();
    } else {
      return (
        this.finishedPercentage === 100 ||
        this.finished === FinishedState.FINISHED
      );
    }
  }

  get isNotStarted() {
    if (this.recurring) {
      return this.startdate < new Date();
    } else {
      return (
        this.finishedPercentage === 0 &&
        this.finished !== FinishedState.FINISHED
      );
    }
  }

  get isTalentV2() {
    return (
      (this.type === TestSetV2Type.TALENT ||
        this.type === null ||
        this.type === 'undefined') &&
      this.isTestSetV2
    );
  }
  get isRehab() {
    return this.type === TestSetV2Type.REHAB;
  }
  get isRecurring() {
    return this.recurring;
  }
  get isPrevention() {
    return this.type === TestSetV2Type.PREVENTION;
  }
  get isCustom() {
    return this.type === TestSetV2Type.TALENT;
  }
  get isILike() {
    return this.config?.type === PackageTypes.ILIKE;
  }
  get isIDo() {
    return this.config?.type === PackageTypes.IDO && !this.isTestSetV2;
  }
  get isHanIDo() {
    return this?.testSets[0]?.customReport === 'han_ido';
  }
  get isSkIDo() {
    return this?.testSets[0]?.customReport === 'sk_ido';
  }
  get isIAm() {
    return this.config?.subtype === PackageSubTypes.IAM;
  }
  get isHan() {
    return this.config?.subtype === PackageSubTypes.HAN;
  }
  get isHanSisp() {
    return this.config?.subtype === PackageSubTypes.SISP;
  }
  get isDjas() {
    return (
      this.type === TestSetV2Type.TALENT &&
      this.config?.subtype === PackageSubTypes.DJAS
    );
  }
  get isSAP() {
    return this.config?.type === PackageTypes.SAP;
  }
  get isMTS() {
    return this.config?.type === PackageTypes.MTS;
  }
  get isKlusce() {
    return (
      (this.config?.type === PackageTypes.IDO ||
        (this.type === TestSetV2Type.TALENT &&
          this.config?.type === 'undefined')) &&
      this.isTestSetV2
    );
  }
  get isBenchmarked() {
    return !(
      this.isIAm ||
      this.isDjas ||
      this.isHan ||
      this.isHanSisp ||
      this.isIDo
    );
  }
  get hasBenchmarkSelect() {
    return (
      this.type === null || this.type === TestSetV2Type.TALENT
      /*this.type === TestSetV2Type.REHAB ||
      this.type === TestSetV2Type.PREVENTION*/
    );
  }

  getAccurateTestData(testDatas) {
    if (!this.testSets?.length) return testDatas[0];

    const testDatasFinished =
      this.testSets[0].type === PackageTypes.ILIKE
        ? testDatas.filter(td => td.finished === FinishedState.FINISHED)
        : [testDatas[0]];
    if (testDatasFinished.length === 1) return testDatasFinished[0];
    if (testDatasFinished.length > 1) {
      const sortedTestResults = sortTestdataOnDateUpdated(testDatasFinished);
      return sortedTestResults[0];
    }
  }

  getTestDataByPersonId(personId, withResult = false) {
    if (withResult) {
      return this.testData.filter(
        td => td.personId === personId && td.finishedPercentage > 0
      );
    } else {
      return this.testData.filter(td => td.personId === personId);
    }
  }

  getTestDataByPersonIdAndDate(personId, date) {
    return this.testData.find(
      tD =>
        tD.personId === personId &&
        tD.testDataDate === format(date, 'yyyy-LL-dd')
    );
  }

  getTestDataByTestdataId(testDataId) {
    return this.testData.find(pe => pe.id === testDataId);
  }

  getTestData(personId) {
    const testDatas = this.getTestDataByPersonId(personId);
    if (testDatas?.length) {
      const testData = this.getAccurateTestData(testDatas);
      return testData.finishedPercentage > 0 ? testData : false;
    }
    return false;
  }

  getLastTestData(personId, withResult) {
    const testDatas = this.getTestDataByPersonId(personId, withResult);
    if (testDatas?.length) {
      return testDatas[0];
    }
    return false;
  }

  getTestDataByDate(personId, date) {
    const testData = this.getTestDataByPersonIdAndDate(personId, date);
    if (testData) {
      return testData;
    }
    return false;
  }

  getTestedDates(personId, onlyResult = false) {
    const testDates = this.getTestDataByPersonId(personId, onlyResult);
    if (testDates?.length) {
      // divide into 2 arrays for finished and unfinished
      const finished = testDates
        .filter(td => td.finishedPercentage > 0)
        .reduce((acc, td) => {
          if (td.testDataDate) {
            acc[td.id] = new Date(td.testDataDate);
          }
          return acc;
        }, {});
      // .filter(td => td !== null);
      const unfinished = !onlyResult
        ? testDates
            .filter(td => td.finishedPercentage === 0)
            .reduce((acc, td) => {
              if (td.testDataDate) {
                acc[td.id] = new Date(td.testDataDate);
              }
              return acc;
            }, {})
        : [];

      return {
        finished: finished,
        unfinished: unfinished
      };
    }
    return {
      finished: {},
      unfinished: {}
    };
  }

  getPreviousTestedDate(date, personId, onlyResult = false) {
    if (!date) return;

    const testDates = this.getTestDataByPersonId(personId, onlyResult);
    if (testDates?.length) {
      testDates.sort((a, b) => {
        return new Date(a.testDataDate) - new Date(b.testDataDate);
      });
      const index = testDates.findIndex(
        td => td.testDataDate === format(date, 'yyyy-LL-dd')
      );
      if (index > 0) {
        return testDates[index - 1];
      }
      // search in the testDates array for the closest date before the given date

      if (index === -1) {
        const testDatesBefore = testDates.filter(
          td => new Date(td.testDataDate) < new Date(date)
        );
        if (testDatesBefore?.length) {
          return testDatesBefore[testDatesBefore.length - 1];
        }
      }
    }
  }

  getNextTestedDate(date, personId, onlyResult = false) {
    if (!date) return;
    const testDates = this.getTestDataByPersonId(personId, onlyResult);
    if (testDates?.length) {
      testDates.sort((a, b) => {
        return new Date(a.testDataDate) - new Date(b.testDataDate);
      });
      const index = testDates.findIndex(
        td => td.testDataDate === format(date, 'yyyy-LL-dd')
      );
      if (index !== -1 && index < testDates.length - 1) {
        return testDates[index + 1];
      }
      if (index === -1) {
        const testDatesAfter = testDates.filter(
          td => new Date(td.testDataDate) > new Date(date)
        );
        if (testDatesAfter?.length) {
          return testDatesAfter[0];
        }
      }
    }
  }

  /**
   * Gets the current report for a session.
   */
  getReportForPerson(personId) {
    const testDatas = this.getTestDataByPersonId(personId);
    if (testDatas.length) {
      return this.getAccurateTestData(testDatas);
    }
    return null;
  }

  getPersonTestPercentage(personId) {
    const testData = this.getPersonTestPercentageTestData(personId);
    if (testData) {
      return testData.finishedPercentage || 0;
    }
    return 0;
  }

  getPersonTestPercentageTestData(personId) {
    const testDatas = this.testData.filter(
      pe => pe.personId === personId && pe.data !== null
    );
    if (testDatas.length) {
      return this.getAccurateTestData(testDatas);
    }
    return null;
  }

  get sporters() {
    return this.personEntities.map(
      personEntity =>
        new Person({
          ...personEntity.person,
          finished: this.getPersonTestPercentage(personEntity.person.id)
        })
    );
  }

  getSporterById = id => {
    const personEntity = this.personEntities.find(pe => pe.person.id === id);
    if (personEntity) {
      return personEntity.person;
    }
    return null;
  };

  getFormData = () => {
    const formData = {};
    this.tests.forEach(test => {
      test.testItems.forEach(testItem => {
        formData[testItem.id] = testItem.sides;
      });
    });
    return formData;
  };
}
