import { cloneElement, Children } from 'react';
import { useUIDSeed } from 'react-uid';
import classNames from 'classnames';
import { ButtonsGroup } from 'components/button';

function MetaWrapper({ toggler = false, item, metaLabel, style, children }) {
  const uidSeed = useUIDSeed();
  return (
    <div
      className={classNames('c-list__item-wrapping-note-preview', {
        'c-list__item-small-editing-area': toggler && metaLabel.length > 0
      })}
      style={style}
    >
      {metaLabel && <p>{item[metaLabel]}</p>}
      {Children.count(children) > 1 ? (
        <ButtonsGroup>
          {Children.map(children, child =>
            cloneElement(child, {
              key: child.key ?? uidSeed(child),
              item
            })
          )}
        </ButtonsGroup>
      ) : (
        cloneElement(children, { item })
      )}
    </div>
  );
}

export default MetaWrapper;
