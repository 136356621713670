import { useContext } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { StoreContext } from 'index';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import Loader from 'components/loader/Loader';
import VirtualizedList from 'containers/partials/lists/VirtualizedList';
import useUserGroups from 'hooks/queries/useUserGroups';

const PersonGroups = () => {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { entityId, sporterId } = useParams();
  const { push } = useHistory();
  const { loading, entities } = useUserGroups({
    entityId: rootEntityId,
    personId: sporterId
  });

  const onClickHandler = group => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY, {
        entityId,
        groupId: group.entityId
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <VirtualizedList
      items={entities}
      entityId={entityId}
      onClick={group => onClickHandler(group)}
      icon={'group'}
      //   type={`user`}
      //   extraClassNames="testing-on-persongroups"
    />
  );
};

export default PersonGroups;
