import { useState, useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

// Only use this hook if you have tabs with routes
const useTabIndex = (routes, exact = true, strict = true) => {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(-1);

  useEffect(() => {
    let index = 0;
    routes.forEach((route, i) => {
      if (
        matchPath(history.location.pathname, {
          path: [route],
          exact,
          strict
        })
      ) {
        index = i;
      }
    });
    setTabIndex(index);
  }, [history, routes]);

  return [tabIndex, setTabIndex];
};

export default useTabIndex;
