import { PersonEntityRoles } from '../constants';
import Session from './Session';
import { defaultOrgFeatures } from 'rules';

export default class Entity {
  id = null;
  name = '';

  parentId = null;
  topLevelEntityId = null;
  subEntities = [];
  clients = [];
  users = [];

  testSessions = [];
  testSets = [];

  regioCode = 'nl';
  baseLanguage = 'nl';
  possibleLanguages = ['nl', 'en'];

  type = '';

  config = {};
  info = {};
  meta = {};

  featureFlags = {};
  apiKeys = [];

  constructor({
    id,
    name = '',
    parentId = null,
    topLevelEntityId = null,
    subEntities = [],
    clients = [],
    users = [],
    testSessions = [],
    testSets = [],

    regioCode = 'nl',
    baseLanguage = 'nl',
    possibleLanguages = ['nl', 'en'],

    type = '',

    config = {},
    info = {},
    meta = {},

    featureFlags = {},
    apiKeys = []
  }) {
    // root ids
    this.id = id;
    this.name = name;

    this.parentId = parentId;
    this.topLevelEntityId = topLevelEntityId;

    this.subEntities = subEntities || [];
    this.clients = clients || [];
    this.users = users || [];

    this.testSessions = testSessions
      ? parseTestSessionDataToModel(testSessions)
      : [];
    this.testSets = testSets || []; // TODO Still needed?

    this.regioCode = regioCode;
    this.baseLanguage = baseLanguage;
    this.possibleLanguages = possibleLanguages;

    this.type = type;

    try {
      this.config = JSON.parse(config);
    } catch (er) {
      this.config = {};
    }

    try {
      this.info = JSON.parse(info);
    } catch (er) {
      this.info = {};
    }

    try {
      this.meta = JSON.parse(meta);
    } catch (er) {
      this.meta = {};
    }

    try {
      this.featureFlags = JSON.parse(featureFlags);
    } catch (er) {
      this.featureFlags = defaultOrgFeatures;
    }

    this.apiKeys = apiKeys || [];
  }

  static get modelName() {
    return 'Entity';
  }
}

// TODO why is this here?
export const parseTestSessionDataToModel = (data, testSets) => {
  let sessions = [];
  if (data && data.length > 0) {
    sessions = data.map(session => {
      const sessionTestSets = [];
      if (testSets) {
        sessionTestSets.push(
          testSets.find(ts => ts.id === session.testSetIds?.[0])
        );
      }
      return new Session({ ...session, testSets: [...sessionTestSets] });
    });
  }
  return sessions;
};
