import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelTitleButtonsGroup } from 'components/panel';
import { PanelTitle } from 'components/panel/panel-title';
import {
  ROUTE_EXERCISES,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
  ROUTE_EXERCISES_TEMPLATES_ENTITY,
  ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE
} from 'routes/RouteList';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import AddTemplateModal from './AddTemplateModal';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import Loader from 'components/loader/Loader';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import useTemplates from 'hooks/queries/useTemplates';
import { useStore } from 'stores/Store';
import TemplateListVirtualized from 'containers/pages/exercises/templates/TemplateListVirtualized';
import AddButton from 'containers/partials/buttons/AddButton';
import { useNotificationQueue } from 'components/notification';
import { useShallow } from 'zustand/react/shallow';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import TemplatesFilterHeader from 'containers/pages/exercises/templates/TemplatesFilterHeader';

const Templates = () => {
  const intl = useIntl();
  const { push } = useHistory();
  const notification = useNotificationQueue();
  const { entityId, templateId } = useParams();
  const ability = useAbility(AbilityContext);
  const active = useRouteMatch(ROUTE_EXERCISES_TEMPLATES_ENTITY);
  const { fetchTemplates, filterTemplates, loading } = useTemplates(entityId);
  const templates = useStore(state => state.templates);
  const [modalData, setModalData] = useState(null);
  const [programModalData, setProgramModalData] = useState(null);
  const { search, tags } = useStore(
    useShallow(state => ({
      search: state.templatesFilter.search,
      tags: state.templatesFilter.tags
    }))
  );

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    filterTemplates({ search, tags });
  }, [search, tags]);

  const onClickHandler = templateId => {
    push(
      generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE, {
        entityId,
        templateId
      })
    );
  };

  const onUseHandler = template => {
    // show use template modal
    setProgramModalData({
      entityId,
      title: '',
      startdate: '',
      duration: '',
      description: '',
      exerciseIds: template.exercises.map(e => e.id),
      templateId: template.id
    });
  };

  const onDeleteHandler = () => {
    notification.add(templateId, {
      message: intl.formatMessage(messages.messageRemovedTemplateSuccess)
    });
    push(generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY, { entityId }));
  };

  return (
    <Panel active={active.isExact}>
      <RefactoredPanelHeader>
        <RefactoredPanelTitleWrapper>
          <Breadcrumbs>
            <Breadcrumb to={ROUTE_EXERCISES}>
              <FormattedMessage {...messages.breadcrumbOverview} />
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitle noSubtitle>
            <FormattedMessage {...messages.templates} />
          </PanelTitle>
        </RefactoredPanelTitleWrapper>
        <PanelTitleButtonsGroup>
          {ability.can('create', 'Exercise') && (
            // TODO canAddExercise check
            <AddButton
              expandable
              label={
                <FormattedMessage {...messages.exercisesAddTemplateButton} />
              }
              onClick={() =>
                setModalData({
                  template: {
                    entityId,
                    title: '',
                    exercises: [],
                    tags: []
                  }
                })
              }
            />
          )}
          {/* <MenuWrapper trigger={<PanelTitleButton />}>
            <Button menu disabled>
              Disabled
            </Button>
          </MenuWrapper> */}
        </PanelTitleButtonsGroup>
      </RefactoredPanelHeader>

      {loading && <Loader />}

      {templates && (
        <>
          <TemplatesFilterHeader />

          <TemplateListVirtualized
            activeId={templateId}
            entityId={entityId}
            templates={templates}
            enablePreviewModal={false}
            onClick={id => onClickHandler(id)}
            onUse={template => onUseHandler(template)}
            onDeleted={id => onDeleteHandler(id)}
          />
        </>
      )}
      {modalData && (
        <AddTemplateModal
          entityId={entityId}
          template={modalData.template}
          onClose={() => setModalData(null)}
        />
      )}
      {programModalData && (
        <AddProgramModal
          entityId={entityId}
          program={programModalData}
          destiny={{
            path: ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES,
            variables: {}
          }}
          onClose={() => setProgramModalData(null)}
          fromTemplate
        />
      )}
    </Panel>
  );
};

export default observer(Templates);
