import React from 'react';
import classNames from 'classnames';

const ButtonsGroup = props => {
  return (
    <div
      className={classNames('c-button__group', props.extraClassNames, {
        'c-button__group-half-button': props.halfButtons,
        'c-button__group-half-button c-button__group-half-button-no-space':
          props.halfButtonsMerged,
        'c-button__group-full-width': props.fullwidth,
        'c-button__group-vertical-center': props.verticalCenter
      })}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export default ButtonsGroup;
