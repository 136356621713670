import React from 'react';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { MUTATION_REMOVE_PERSONS_FROM_PROGRAM } from 'services/aws/programs-query';
import { useStore } from 'stores/Store';
import { useNotificationQueue } from 'components/notification';
import useProgram from 'hooks/queries/useProgram';
import usePrograms from 'hooks/queries/usePrograms';
import { FormattedMessage, useIntl } from 'react-intl';

const RemovePersonsFromProgramButton = ({
  program,
  entityId,
  onComplete,
  ...props
}) => {
  const intl = useIntl();
  const notification = useNotificationQueue();
  const selectedProgramPersons = useStore(
    state => state.selectedProgramPersons
  );
  const setSelectedProgramPersons = useStore(
    state => state.setSelectedProgramPersons
  );

  const { fetchProgram } = useProgram();
  const { fetchPrograms } = usePrograms(entityId);

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_REMOVE_PERSONS_FROM_PROGRAM}
      variables={{
        exerciseProgramId: program.id,
        personIds: selectedProgramPersons.map(p => p.id)
      }}
      disabled={selectedProgramPersons.length === 0}
      confirmTitle={messages.modalConfirmRemovePersonFromProgramTitle}
      confirmMessage={messages.modalConfirmRemovePersonFromProgramMessage}
      messageValues={{ count: selectedProgramPersons.length }}
      update={(cache, { data: { removePersonsFromExerciseProgram } }) => {
        if (removePersonsFromExerciseProgram) {
          notification.add(program.id, {
            message: intl.formatMessage(
              messages.messagePersonsRemovedFromProgram
            )
          });
          fetchProgram({
            variables: { id: program.id },
            fetchPolicy: 'network-only'
          });
          fetchPrograms({
            variables: { entityId },
            fetchPolicy: 'network-only'
          });

          setSelectedProgramPersons([]);

          if (onComplete) onComplete();
        }
      }}
    >
      <Icon
        id="delete"
        strokeColor={props.disabled ? 'color-neutral' : 'color-neutral-dark'}
      />
      {selectedProgramPersons.length > 0 ? (
        <FormattedMessage
          {...messages.buttonRemoveSelectedPersonsEnabled}
          values={{ count: selectedProgramPersons.length }}
        />
      ) : (
        <FormattedMessage {...messages.buttonRemoveSelectedPersonsDisabled} />
      )}
    </ConfirmMutationButton>
  );
};

export default RemovePersonsFromProgramButton;
