import React from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ROUTE_SETTINGS_BENCHMARKS } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import {
  MUTATION_ARCHIVE_BENCHMARK,
  QUERY_GET_BENCHMARKS
} from 'services/aws/benchmark-query';

const RemoveBenchmarkButton = ({
  benchmarkId,
  authStore,
  children,
  routing: { push },
  entityId,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_BENCHMARK}
      variables={{ benchmarkId }}
      confirmTitle={messages.modalConfirmRemoveBenchmarkTitle}
      confirmMessage={messages.modalConfirmRemoveBenchmarkMessage}
      refetchQueries={[
        {
          query: QUERY_GET_BENCHMARKS,
          variables: { entityId }
        }
      ]}
      update={(cache, { data: { archiveBenchmark } }) => {
        if (archiveBenchmark) {
          push(ROUTE_SETTINGS_BENCHMARKS);
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default inject(
  'routing',
  'authStore'
)(withRouter(RemoveBenchmarkButton));
