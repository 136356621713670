import React, { useContext, useState } from 'react';
import Panel from 'components/panel/Panel';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { getLocalDateFormat } from 'utils/date';
import { PanelHeader } from 'components/panel';
import { StoreContext } from 'index';
import { useHistory } from 'react-router-dom';
import PanelBody from 'components/panel/PanelBody';
import Card from 'components/card/Card';
import { CardHeaderText, CardHeaderTitle } from 'components/card/card-header';
import CardHeader from 'components/card/card-header/CardHeader';
import CardBody from 'components/card/CardBody';
import Button from 'components/button/Button';
import { useLazyQuery } from '@apollo/client';
import { QUERY_EXPORT_SUB_ORG_DATA } from 'services/aws/account-query';

import Loader from 'components/loader/Loader';
import * as Yup from 'yup';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { Form, Formik } from 'formik';
import MessageText from 'components/message/MessageText';
import Icon from 'components/icon/Icon';
import classNames from 'classnames';
import { DATE_LOCALE } from 'constants.js';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { format } from 'date-fns';
import TestsetSelect from 'containers/partials/inputs/TestsetSelect';

ExportData.propTypes = {};

function ExportData() {
  const {
    authStore: {
      user: { rootEntityId, baseLanguage }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const intl = useIntl();
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });
  const [exportDataMessage, setExportDataMessage] = useState(false);

  const [exportSubOrgData, { loading: loadingExportSubOrgData }] = useLazyQuery(
    QUERY_EXPORT_SUB_ORG_DATA
  );

  const limitDateInPast = new Date(1930, 0, 1);
  const today = new Date();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const validationSchema = Yup.object().shape({
    testSetId: Yup.string().required(),
    startdate: Yup.date().required(
      intl.formatMessage(messages.startdateRequired)
    ),
    enddate: Yup.date().required(intl.formatMessage(messages.enddateRequired))
  });

  const exportDataHandler = async formData => {
    setDateRange({
      startDate: formData.startdate,
      endDate: formData.enddate
    });

    const { data } = await exportSubOrgData({
      variables: {
        entityId: rootEntityId,
        startdate: format(formData.startdate, 'yyyy-LL-dd'),
        enddate: format(formData.enddate, 'yyyy-LL-dd'),
        testSetId: formData.testSetId
      }
    });

    if (data?.exportDataOfSubOrganisations) {
      if (data.exportDataOfSubOrganisations) {
        setExportDataMessage(data.exportDataOfSubOrganisations);
      } else {
        setExportDataMessage('error');
      }
    }
  };

  return (
    <Panel>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbExportData} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.exportDataTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.exportDataLabelSubTitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>
      <PanelBody>
        <Card secondary>
          <CardHeader secondary>
            <CardHeaderText extraClassNames={'u-margin-bottom-none'}>
              <CardHeaderTitle>
                <FormattedMessage {...messages.exportDataCardTitle} />
              </CardHeaderTitle>
            </CardHeaderText>
          </CardHeader>
          <CardBody secondary>
            {loadingExportSubOrgData && <Loader />}
            <p>
              <FormattedMessage {...messages.exportDataInfo} />
            </p>
            <Formik
              initialValues={{
                startdate: oneMonthAgo,
                enddate: today,
                testSetId: null
              }}
              validationSchema={validationSchema}
              onSubmit={exportDataHandler}
            >
              {({ values, errors, touched, handleChange, setFieldValue }) => (
                <Form noValidate>
                  <div className="o-layout o-layout--tiny">
                    <div className="o-layout__item">
                      <TestsetSelect
                        name={'testSetId'}
                        entityId={rootEntityId}
                        value={values.testSetId}
                        errors={errors}
                        touched={touched}
                        onChange={selectedItem => {
                          if (selectedItem) {
                            setFieldValue(
                              'testSetId',
                              selectedItem ? selectedItem.id : ''
                            );
                          }
                        }}
                      />
                    </div>
                    <div
                      className={classNames('o-layout__item u-1-of-2-at-small')}
                    >
                      <FieldDatePicker
                        id={'startdate'}
                        name="startdate"
                        min={limitDateInPast}
                        max={yesterday}
                        onChange={handleChange}
                        required
                        icon={'calendar'}
                        errors={errors}
                      >
                        <FormattedMessage {...messages.globalStartdateLabel} />
                      </FieldDatePicker>
                    </div>
                    <div
                      className={classNames('o-layout__item u-1-of-2-at-small')}
                    >
                      <FieldDatePicker
                        id="enddate"
                        name="enddate"
                        min={limitDateInPast}
                        max={today}
                        onChange={handleChange}
                        required
                        icon={'calendar'}
                        errors={errors}
                      >
                        <FormattedMessage {...messages.globalEnddateLabel} />
                      </FieldDatePicker>
                    </div>
                    <div className="o-layout__item">
                      <div className="o-flex o-flex--justify-start">
                        <Button type="submit" primary small>
                          <FormattedMessage {...messages.exportDataButton} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>

        {exportDataMessage === true && (
          <Card secondary extraClassNames="c-card--remarks u-padding-medium">
            <CardBody
              empty
              secondary
              //   remarks
              extraClassNames="u-bgcolor-neutral-xlight"
            >
              <Icon id="big-send" xHugeIcon />

              <MessageText
                success
                extraClassNames="u-margin-horizontal-auto u-margin-bottom-huge"
                style={{ maxWidth: '24rem' }}
              >
                <FormattedMessage
                  {...messages.exportDataSuccessAnswer}
                  values={{
                    startdate: getLocalDateFormat(
                      dateRange.startDate,
                      'yyyy-LL-dd',
                      'dd MMMM yyyy',
                      { locale: DATE_LOCALE[baseLanguage] }
                    ),
                    enddate: getLocalDateFormat(
                      dateRange.endDate,
                      'yyyy-LL-dd',
                      'dd MMMM yyyy',
                      { locale: DATE_LOCALE[baseLanguage] }
                    ),
                    b: chunks => (
                      <b style={{ display: 'inline-block', margin: '0 4px' }}>
                        {chunks}
                      </b>
                    )
                  }}
                />
              </MessageText>
            </CardBody>
          </Card>
        )}
        {exportDataMessage === 'error' && (
          <Card
            secondary
            extraClassNames="u-padding-large u-margin-auto"
            style={{ maxWidth: '32rem' }}
          >
            <CardBody empty secondary style={{ padding: 0 }}>
              <Icon
                id="database-error"
                smallMediumIcon
                extraClassNames="u-margin-top-large u-margin-horizontal-auto u-margin-bottom-medium"
              />

              <MessageText extraClassNames="u-margin-horizontal-auto u-margin-bottom-large">
                <FormattedMessage {...messages.exportDataFailedAnswer} />
              </MessageText>
            </CardBody>
          </Card>
        )}
      </PanelBody>
    </Panel>
  );
}

//
export default ExportData;
