import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import { TestSetV2Type } from 'constants.js';
import { ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT } from 'routes/RouteList';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_TESTSET,
  MUTATION_EDIT_TESTSET,
  QUERY_GET_TESTSET,
  QUERY_GET_TESTSETS_WITH_SPORT
} from 'services/aws/testsets-query';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { cleanFalsy } from 'utils/object';
import TestSet from 'models/TestSet';
import { useNotificationQueue } from 'components/notification';
import messages from 'messages';

function TestSetForm({
  entityId,
  testSet: initialTestSet,
  children,
  extraClassNames
}) {
  const { push } = useHistory();
  const notification = useNotificationQueue();

  const intl = useIntl();
  const [testSet, setTestSet] = useState(new TestSet({}));
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    preventionType: Yup.string().required(
      intl.formatMessage(messages.typeRequired)
    ),
    sport: Yup.object()
      .required(intl.formatMessage(messages.sportRequired))
      .typeError(intl.formatMessage(messages.sportSelectPlaceholder))
  });

  useEffect(() => {
    if (initialTestSet) {
      setTestSet(initialTestSet);
    }
  }, [initialTestSet]);

  const [addTestSet] = useMutation(MUTATION_ADD_TESTSET);
  const [editTestSet] = useMutation(MUTATION_EDIT_TESTSET);

  const onSubmitHandler = async (testSet, { resetForm }) => {
    const testSetData = cleanFalsy({
      entityId,
      title: testSet.title,
      note: testSet.note,
      defaultBenchmarkId: '188a14db-d276-4cc4-ac20-b55bc252d266', // session.benchmarkId,
      sportId: testSet?.sport ? testSet.sport.id : null,
      testIds: testSet.tests?.length ? testSet.tests.map(t => t.id) : [],
      type: TestSetV2Type.PREVENTION_TEMPLATE,
      preventionType: testSet.preventionType,
      meta: JSON.stringify(testSet.meta)
    });

    if (testSet.id) {
      await editTestSet({
        variables: { ...testSetData, id: testSet.id, forceUpdate: true },
        refetchQueries: [
          {
            query: QUERY_GET_TESTSETS_WITH_SPORT,
            variables: { entityId, type: TestSetV2Type.PREVENTION_TEMPLATE }
          },
          {
            query: QUERY_GET_TESTSET,
            variables: { entityId, testSetId: testSet.id }
          }
        ]
      }).then(res => {
        notification.add(res.data.editTestSetV2.id, {
          message: intl.formatMessage(messages.templateSaved)
        });
      });
    } else {
      // add

      await addTestSet({
        variables: { ...testSetData }
      }).then(res => {
        resetForm();
        notification.add(res.data.addTestSetV2.id, {
          message: intl.formatMessage(messages.templateSaved)
        });
        push(
          generatePath(ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT, {
            entityId,
            sessionsTab: 'templates',
            testSetId: res.data.addTestSetV2.id
          })
        );
      });
    }
  };

  return (
    <Formik
      initialValues={testSet}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitHandler}
    >
      {props => (
        <Form className={classNames(extraClassNames)} noValidate>
          {typeof children === 'function' ? children(props) : children}
        </Form>
      )}
    </Formik>
  );
}

export default TestSetForm;
