import React, { useEffect, useState } from 'react';
import Select, { Option } from 'components/input/Select';
import { useIntl } from 'react-intl';
import messages from 'messages';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import addYears from 'date-fns/addYears';
import classNames from 'classnames';

const generateYearOptions = startYear => {
  const years = differenceInCalendarYears(
    addYears(new Date(), 1),
    new Date(startYear, 0, 1)
  );
  return Array.from(Array(years).keys(), y => {
    const firstYear = addYears(new Date(startYear, 0, 1), y).getFullYear();
    const nextYear = addYears(new Date(startYear, 0, 1), y + 1).getFullYear();
    const yearKey = parseInt(`${firstYear}${nextYear}`);
    const yearLabel = `${firstYear}-${nextYear}`;
    return { value: yearKey, label: yearLabel };
  });
};

export const yearsList = generateYearOptions(2018);

function YearSelect({ onChange, value, label, disabled, extraClassNames }) {
  const intl = useIntl();

  return (
    <Select
      id="year"
      label={label ?? intl.formatMessage(messages.yearSelect)}
      name="sport"
      onChange={selectedValue => {
        onChange(selectedValue.value);
      }}
      extraClassNames={classNames(
        'c-input__group-select-year',
        extraClassNames
      )}
      value={value}
      disabledRefactored={disabled}
    >
      <Option value="" label={intl.formatMessage(messages.noResultsOption)} />
      {yearsList.map(year => (
        <Option key={year.value} value={year.value} label={year.label} />
      ))}
    </Select>
  );
}

export default YearSelect;
