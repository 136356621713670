import React from 'react';
import {
  QUERY_GET_SCANNER_BY_ID,
  MUTATION_REMOVE_CALIBRATION_FROM_SCANNER
} from 'services/aws/scanner-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';

const RemoveCalibrationFromScannerButton = ({
  scannerId,
  entityId,
  calibrationId,
  children,
  onComplete,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_REMOVE_CALIBRATION_FROM_SCANNER}
      variables={{ calibrationId }}
      confirmTitle={messages.modalConfirmRemoveCalibrationTitle}
      confirmMessage={messages.modalConfirmRemoveCalibrationMessage}
      refetchQueries={[
        {
          query: QUERY_GET_SCANNER_BY_ID,
          variables: { scannerId, entityId }
        }
      ]}
      update={(cache, { data: { archiveMtsCalibration } }) => {
        if (archiveMtsCalibration) {
          if (onComplete) onComplete();
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveCalibrationFromScannerButton;
