import { Fragment } from 'react';
import classNames from 'classnames';
import './Graphics.scss';

const Graphic = props => {
  const { maturity, pah, pahRefactored, position, value, extraClassNames } =
    props;
  return (
    <div
      className={classNames('c-graphic', extraClassNames, {
        'c-graphic--maturity': maturity,
        'c-graphic--pah': pah,
        'c-graphic--pah-refactored': pahRefactored
      })}
    >
      {maturity && (
        <Fragment>
          <div className="c-graphic__area c-graphic__lt-area">
            <span className="c-graphic__area-label c-graphic__lt-area-label">
              - 1yr
            </span>
          </div>
          <div className="c-graphic__area c-graphic__middle-area">
            <span className="c-graphic__area-label c-graphic__middle-area-label">
              APHV
            </span>
          </div>
          <div className="c-graphic__area c-graphic__gt-area">
            <span className="c-graphic__area-label c-graphic__gt-area-label">
              + 1yr
            </span>
          </div>
          <div
            className="c-graphic__position"
            style={{ left: `calc(calc(100% / 6) * calc(${position} + 3))` }}
          />
        </Fragment>
      )}

      {pah && (
        <Fragment>
          <div className="c-graphic__area c-graphic__lt-area">
            <span className="c-graphic__area-label c-graphic__lt-area-label">
              86%
            </span>
          </div>
          <div className="c-graphic__area c-graphic__middle-lt-area">
            <span className="c-graphic__area-label c-graphic__middle-lt-area-label">
              89%
            </span>
          </div>
          <div className="c-graphic__area c-graphic__middle-gt-area">
            <span className="c-graphic__area-label c-graphic__middle-gt-area-label">
              95%
            </span>
          </div>
          <div className="c-graphic__area c-graphic__gt-area">
            <span className="c-graphic__area-label c-graphic__gt-area-label">
              100%
            </span>
          </div>
          <div
            className="c-graphic__position"
            data-value={value}
            style={
              pahRefactored
                ? {
                    left: `${5 * ((position === 86 ? position - 0.15 : position === 89 ? position - 0.25 : position === 95 ? position - 0.38 : position) - 80)}%`
                  }
                : {
                    left: `${5 * ((position === 86 ? position - 0.15 : position === 89 ? position - 0.25 : position === 95 ? position - 0.38 : position) - 80)}%`
                  }
            }
          />
        </Fragment>
      )}
    </div>
  );
};

export default Graphic;
