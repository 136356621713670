import { forwardRef } from 'react';
import classNames from 'classnames';
import './Drawer.scss';

const Drawer = forwardRef((props, ref) => {
  const { open, children } = props;
  return (
    <>
      <div
        ref={ref}
        className={classNames('c-sidebar', {
          'c-sidebar--uncollapsed': open
        })}
      >
        <div className={classNames('c-sidebar__wrapper', {})}>{children}</div>
      </div>
      {open && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            background: '#0004',
            zIndex: 31
          }}
        ></div>
      )}
    </>
  );
});

export default Drawer;
