import React, { useContext } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY } from 'routes/RouteList';
import { MUTATION_ARCHIVE_ENTITY } from 'services/aws/entity-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID } from 'services/aws/client-query';
import { StoreContext } from 'index';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';

const RemoveGroupButton = ({
  group,
  entityId,
  children,
  parentId,
  ...props
}) => {
  const {
    authStore,
    authStore: { user },
    uiState
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });
  const clearSelected = useEntityContext(s => s.clearSelected);
  if (!user.canRemoveGroups(entityId)) return null;

  if (group.type !== 'group') return null;

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_ENTITY}
      variables={{ id: group.id }}
      confirmTitle={messages.modalConfirmRemoveGroupTitle}
      confirmMessage={messages.modalConfirmRemoveGroupMessage}
      messageValues={{
        group: group.name,
        b: chunks => (
          <b style={{ display: 'inline-block', margin: '0 4px' }}>{chunks}</b>
        )
      }}
      refetchQueries={[
        QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID,
        {
          variables: { entityId }
        }
      ]}
      update={async (cache, { data: { archiveEntity } }) => {
        if (archiveEntity) {
          uiState.decreasePendingRequest();
          await fetchEntities(parentId);
          await authStore.renewRoles();
          clearSelected();
          push(
            generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId: parentId })
          );
        }
      }}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveGroupButton;
