import React, { useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_SESSIONS_ENTITY_SESSION_SPORTER } from 'routes/RouteList';
import messages from 'messages';
import { useSessionContext } from 'contexts/SessionContext';
import { sortSportersOnFinishedAndDate } from 'utils/sort';
import useSelectableObject from 'hooks/utils/useSelectableObject';

import EntityListItem from 'containers/partials/list-items/EntityListItem';
import RecurringTestDataRow from 'containers/pages/sessions/RecurringTestDataRow';
import EntityList from 'containers/pages/persons/EntityList';
import { getLocalDateFormat } from 'utils/date';
import { ListItemLabel } from 'components/list/list-item';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import { differenceInCalendarDays } from 'date-fns';

const SessionPersonsTab = ({ personId, onSelectPersons }) => {
  const { push } = useHistory();
  const { entityId, sessionsTab, sessionId, sporterId } = useParams();
  const [sortedPersons, setSortedPersons] = useState([]);
  const { session } = useSessionContext();
  const { selected, select } = useSelectableObject();
  const [byIndex, setByIndex] = useState(false);

  useEffect(() => {
    return () => {
      onSelectPersons && onSelectPersons([]);
    };
  }, []);

  useEffect(() => {
    if (session?.sporters) {
      setSortedPersons(sortSportersOnFinishedAndDate(session.sporters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, sortSportersOnFinishedAndDate]);

  const onPersonClickHandler = person => {
    if (personId !== person.id)
      push(
        generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER, {
          entityId,
          sessionsTab,
          sessionId,
          sporterId: person.id
        })
      );
  };

  const onSelectPersonsHandler = person => {
    const selectedPersons = select(person);
    onSelectPersons && onSelectPersons(selectedPersons);
  };

  const findLastTestDate = sporterId => {
    const lastTestData = session.getLastTestData(sporterId, true);
    if (lastTestData) {
      return getLocalDateFormat(lastTestData.testDataDate);
    }
    return null;
  };

  const calculateTestProgress = sporterId => {
    let total = 0;
    if (session.interval === 'weekly') {
      total = differenceInWeeks(session.enddate, session.startdate, {
        roundingMethod: 'ceil'
      });
    }
    if (session.interval === 'daily') {
      total = differenceInCalendarDays(session.enddate, session.startdate) + 1;
    }
    const testData = session.getTestDataByPersonId(sporterId, true);
    if (testData) {
      return [testData.length, total];
    }
    return [0, total];
  };

  const openExtraRow = index => {
    setByIndex(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <EntityList
      activeId={sporterId}
      variableHeight
      reCalculateHeight={selected.length > 0}
      entityId={entityId}
      items={sortedPersons}
      selectedItems={selected}
      statusLabel={item => {
        if (session.recurring) {
          const progress = calculateTestProgress(item.id);
          return `${progress[0]}/${progress[1]}`;
        } else {
          return `${item.finished}%`;
        }
      }}
      checkable
      onClick={onPersonClickHandler}
      onSelect={onSelectPersonsHandler}
      emptyMessage={messages.labelSessionNoSporters}
      extraClassNames="c-list--medium-negative-margin"
    >
      {item => {
        let itemData = {
          done: item.finished === 100,
          inProgress: item.finished !== 0 && item.finished !== 100
        };
        if (session.isRecurring) {
          const progress = calculateTestProgress(item.id);
          itemData = {
            done: progress[0] >= progress[1],
            inProgress: progress[0] > 0 && progress[0] < progress[1],
            meta: () => (
              <>
                <ListItemLabel info>{findLastTestDate(item.id)}</ListItemLabel>
              </>
            )
          };
        }
        return (
          <EntityListItem
            id={item.id}
            active={sporterId === item.id}
            /*meta={props =>  // TODO @Maarten: recurring sessions lijst activeren
              session.recurring ? (
                <MetaWrapper style={{ bottom: 'calc(100% - 48px)' }} {...props}>
                  <Button onClick={() => openExtraRow(item.index)}>
                    {byIndex[item.index] ? 'close' : 'open'}
                  </Button>
                </MetaWrapper>
              ) : (
                false
              )
            }*/

            extraRow={() =>
              byIndex[item.index] ? <RecurringTestDataRow /> : false
            }
            {...itemData}
          />
        );
      }}
    </EntityList>
  );
};

export default SessionPersonsTab;
