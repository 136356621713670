import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useParams, useHistory } from 'react-router-dom';
import Button from 'components/button/Button';

import { ROUTE_SETTINGS, ROUTE_SETTINGS_TESTSETS } from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/icon/Icon';
import PanelTools from 'components/panel/PanelTools';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import TestSetModal, {
  TESTSET_UPDATE_TYPE
} from 'containers/partials/modals/TestSetModal';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import Loader from 'components/loader/Loader';
import { TestSetV2Type } from 'constants.js';
import { StoreContext } from 'index';
import TestSetTypeSelect from 'containers/partials/inputs/TestSetTypeSelect';
import { useTestSetContext } from 'contexts/TestSetContext';
import { PanelBody } from 'components/panel';
import RehabTemplateList from 'containers/pages/settings/testsets/RehabTemplateList';
import RehabList from 'containers/pages/settings/testsets/RehabList';
import TestsList from 'containers/pages/settings/testsets/TestSetList';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const TestSets = ({ entityId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { type } = useParams();

  const { actions, state, loading, error } = useTestSetContext();

  const [testSetType, setTestSetType] = useState(type ?? TestSetV2Type.TALENT);
  const [modalData, setModalData] = useState(false);

  useEffect(() => {
    actions.getTestSets([testSetType]);
  }, []);

  const onTestSetTypeChangeHandler = type => {
    setTestSetType(type);
    actions.getTestSets([type]);
    push(
      generatePath(ROUTE_SETTINGS_TESTSETS, {
        type: type === TestSetV2Type.TALENT ? undefined : type
      })
    );
  };

  return (
    <Panel>
      <PanelHeader borderBottom={testSetType !== TestSetV2Type.REHAB_TEMPLATE}>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettings} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.testSetsTitle} />
            {user.canAddTestSets() && (
              <Button
                rounded
                primary
                onClick={() => setModalData({ type: TESTSET_UPDATE_TYPE.ADD })}
              >
                <Icon id="add-white" />
                <FormattedMessage {...messages.testSetsAddTestsetButton} />
              </Button>
            )}
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.testSetsSubtitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools extraClassNames="u-margin-bottom-none">
          {(user.isHylyghtOrg || user.isVitaleOrg) &&
            (user.superadmin || user.hasAdminRole()) && (
              <TestSetTypeSelect
                onChange={onTestSetTypeChangeHandler}
                value={testSetType}
              />
            )}
        </PanelTools>
      </PanelHeader>

      <PanelBody>
        {loading && <Loader />}
        {error && <RetryPanel />}

        {!loading && state.testSets && state.testSets.length === 0 && (
          <Message emptyState icon="test">
            <MessageText>
              <FormattedMessage {...messages.noTestSetsMessage} />
            </MessageText>
          </Message>
        )}

        <TestSetsList type={testSetType} />
      </PanelBody>
      {modalData && (
        <TestSetModal
          entityId={entityId}
          {...modalData}
          existingNames={state.testSets.map(
            b => b?.title && b.title.toLowerCase()
          )}
          onClose={() => setModalData(null)}
        />
      )}
    </Panel>
  );
};

export default TestSets;

const TestSetsList = ({ type }) => {
  switch (type) {
    case TestSetV2Type.REHAB_TEMPLATE:
      return <RehabTemplateList />;
    case TestSetV2Type.REHAB:
      return <RehabList />;
    default:
      return <TestsList type={type} />;
  }
};
