import React, { useState, useEffect } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import {
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SESSIONS_ENTITY_TEMPLATES
} from 'routes/RouteList';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import { FormattedMessage } from 'react-intl';
import PreventionSelectTestsCard from './PreventionSelectTestsCard';
import PreventionDetailExtrasCard from 'containers/partials/cards/PreventionDetailExtrasCard';
import PreventionSelectedTestsCard from './PreventionSelectedTestsCard';
import PreventionTemplateDetailCard from 'containers/partials/cards/PreventionTemplateDetailCard';
import PreventionSelectInjuriesCard from './PreventionSelectInjuriesCard';
import Loader from 'components/loader/Loader';
import { useTestSetContext } from 'contexts/TestSetContext';
import TestSetForm from './TestSetForm';
import { MenuWrapper } from 'components/menu';
import messages from 'messages';
import RemoveSessionTemplateButton from 'containers/partials/buttons/RemoveSessionTemplateButton';
import TestSet from 'models/TestSet';
import { useTestsContext } from 'contexts/TestsContext';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

function EditPreventionTemplate({ edit: initialEdit }) {
  const ability = useAbility(AbilityContext);
  const { push } = useHistory();
  const { entityId, testSetId } = useParams();

  const { actions, state, loading } = useTestSetContext();
  const { testActions } = useTestsContext();
  const [testSet, setTestSet] = useState(new TestSet({}));
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    setEdit(initialEdit);
  }, [initialEdit]);

  useEffect(() => {
    actions.getTestSet(testSetId);
  }, [testSetId]);

  useEffect(() => {
    testActions.setSelectedTests(testSet.tests);
  }, [testSet.tests]);

  useEffect(() => {
    if (state?.testSet) {
      setTestSet(state.testSet);

      // Geeft een fout maar weet niet meer waarvoor dit een fix was
      /*} else {
      notification.add(testSetId, {
        message: intl.formatMessage(messages.testsetNotFound)
      });
      push(generatePath(ROUTE_SESSIONS_ENTITY_TEMPLATES, { entityId }));*/
    }
  }, [state]);

  if (loading) return <Loader />;

  return (
    <Panel secondary extraClassNames="o-flex o-flex--column">
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb
            to={generatePath(ROUTE_SESSIONS_ENTITY, {
              entityId,
              sessionsTab: 'templates'
            })}
          >
            <FormattedMessage {...messages.breadcrumbSessions} />
          </Breadcrumb>
          <Breadcrumb
            to={generatePath(ROUTE_SESSIONS_ENTITY_TEMPLATES, {
              entityId,
              sessionsTab: 'templates'
            })}
          >
            <FormattedMessage {...messages.breadcrumbTemplates} />
          </Breadcrumb>
          <Breadcrumb>
            <FormattedMessage {...messages.breadcrumbEditTemplate} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage
              {...messages.preventionTemplateEditSessionTitle}
              values={{
                testSetExists: Boolean(testSet.title),
                testSet: testSet.title
              }}
            />
            <PanelTitleButtonsGroup>
              <MenuWrapper trigger={<PanelTitleButton />}>
                {ability.can('delete', 'Sessions') && (
                  <RemoveSessionTemplateButton
                    menu
                    entityId={entityId}
                    testSetId={testSet.id}
                    onComplete={() =>
                      push(
                        ROUTE_SESSIONS_ENTITY_TEMPLATES.replace(
                          ':entityId',
                          entityId
                        )
                      )
                    }
                  >
                    <FormattedMessage
                      {...messages.preventionMenuItemRemoveTemplate}
                    />
                  </RemoveSessionTemplateButton>
                )}
                {/*<Button menu onClick={() => onUseTemplateHandler}>
                  <Icon id="download" />
                  <FormattedMessage
                    {...messages.menuItemSessionDownloadAllReports}
                  />
                </Button>*/}
              </MenuWrapper>
            </PanelTitleButtonsGroup>
          </PanelTitle>
        </PanelTitleWrapper>
      </PanelHeader>
      {testSet?.id && (
        <TestSetForm
          entityId={entityId}
          testSet={testSet}
          extraClassNames={'c-panel__body c-panel__body--full-width'}
        >
          {({ values }) => (
            <Tabs fullWidth>
              <TabList>
                <Tab key="session.template.global">
                  <FormattedMessage {...messages.preventionTemplateTabGlobal} />
                </Tab>
              </TabList>
              <TabPanel
                key="session.template.global"
                className="react-tabs__tab-panel u-padding-none"
              >
                <Panel
                  style={{
                    padding: '16px 8px 16px 16px',
                    overflowY: 'scroll'
                  }}
                >
                  <PanelBody style={{ minHeight: '100%' }}>
                    <PreventionTemplateDetailCard
                      edit={edit}
                      onEdit={edit => setEdit(edit)}
                    />
                    <PreventionDetailExtrasCard edit={false} />
                    {values.preventionType === 'full' && (
                      <PreventionSelectInjuriesCard
                        entityId={entityId}
                        edit={edit}
                        onEdit={edit => setEdit(edit)}
                      />
                    )}
                    <PreventionSelectTestsCard
                      edit={edit}
                      onEdit={edit => setEdit(edit)}
                    />
                  </PanelBody>
                </Panel>
                <Panel
                  style={{
                    padding: '16px 8px',
                    overflowY: 'scroll'
                  }}
                >
                  <PanelBody>
                    <PreventionSelectedTestsCard
                      tests={testSet.tests}
                      edit={edit}
                      onEdit={edit => setEdit(edit)}
                      editPanel={true}
                    />
                  </PanelBody>
                </Panel>
              </TabPanel>
            </Tabs>
          )}
        </TestSetForm>
      )}
    </Panel>
  );
}

export default EditPreventionTemplate;
