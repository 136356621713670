import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RefactoredListItem = forwardRef(
  (
    {
      active,
      readOnly,
      disabled,
      withSubList,
      done,
      extraClassNames,
      onClick,

      style,

      children,
      ...rest
    },
    ref
  ) => {
    const handleClick = e => {
      e.stopPropagation();
      if (onClick) onClick(e);
    };

    const handleKeyDown = e => {
      if (e.key === 'Enter') {
        handleClick(e);
      }
    };

    return (
      <li
        ref={ref}
        className={classNames('r-list__item', extraClassNames, {
          'r-list__item-with-sub-list': withSubList,
          'r-list__item-read-only': readOnly,
          'c-list__item-active': active,

          'r-list__item-done': done
        })}
        onClick={e => handleClick(e)}
        role={onClick ? 'button' : 'listitem'}
        onKeyDown={onClick && handleKeyDown}
        style={style}
        {...rest}
      >
        {children}
      </li>
    );
  }
);

RefactoredListItem.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  withSubList: PropTypes.bool,
  done: PropTypes.bool,
  extraClassNames: PropTypes.any,
  onClick: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.node
};

export default RefactoredListItem;
