import { useEffect, useState, useContext } from 'react';
import { Breadcrumbs, BreadcrumbsWrapper } from 'components/breadcrumbs';
import {
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleButtonsGroup,
  PanelTitleWrapper
} from 'components/panel';
import { MenuWrapper } from 'components/menu';
import { Card, CardBody } from 'components/card';
import { CardHeaderTitle } from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import ProgressBarScale from 'components/progress-bar/ProgressBarScale';
import { Button } from 'components/button';
import { openWindowWithLoader } from 'utils/browser';
import Icon from 'components/icon/Icon';
import {
  FIXED_RESULT_COLS,
  ProgressBarRange,
  ProgressBarValueTypes
} from 'constants.js';
import { downloadBenchmarkData } from 'services/aws/benchmark-query';
import { pollIsValidUrl } from 'utils/url';
import ResultsSettingsView from 'containers/pages/results/ResultsSettingsView';
import { StoreContext } from 'index';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function ResultsPanelHeader({ breadcrumbs, panelChoices }) {
  const {
    authStore: { user },
    uiState
  } = useContext(StoreContext);
  const intl = useIntl();
  const group = useEntityContext(s => s.group);
  const { benchmarkType } = useResultsPanelContext();

  const [testCols, setTestCols] = useState([]);

  useEffect(() => {
    setTestCols(
      Array(FIXED_RESULT_COLS)
        .fill()
        .map(() => ({}))
    );
  }, [group.id]);

  const helpValueLabels = [];
  ProgressBarRange.forEach((row, i) => {
    helpValueLabels.push({
      color: Object.values(ProgressBarValueTypes)[i],
      label: intl.formatMessage(messages[`benchmarksHelpValue${i + 1}Label`])
    });
  });

  // TODO export benchmark to printable format
  const onExportBenchmark = async newWindow => {
    const openWindow = newWindow;

    uiState.increasePendingRequest();
    const printData = JSON.parse(JSON.stringify(this.dataSet));
    printData.cols.forEach((r, i) => {
      if (i !== this.sortIndex) {
        r.sort = 'sort';
      } else {
        r.sort = `sort-${this.sortDirection}`;
      }
    });
    printData.cols = printData.cols.filter(c => c.id !== undefined);
    printData.type = benchmarkType;
    printData.group = group.name;
    const currentTs = []; // testSets.find(ts => ts.id === testSetId); TODO
    printData.testSet = currentTs.subtype;
    printData.organisation = user.getEntityName(user.entity);

    const response = await downloadBenchmarkData({
      data: JSON.stringify(printData)
    });
    if (
      !response.error &&
      response.data &&
      response.data.printBenchmarkResults
    ) {
      const url = response.data.printBenchmarkResults;
      const pollResult = await pollIsValidUrl(url, 15, 1000);
      uiState.decreasePendingRequest();

      if (pollResult) {
        openWindow.location = response.data.printBenchmarkResults;
        openWindow.focus();
        return;
      }
    }
    uiState.decreasePendingRequest();
  };

  return (
    <PanelHeader>
      <BreadcrumbsWrapper>
        <Breadcrumbs benchmarks>{breadcrumbs}</Breadcrumbs>
        <PanelTitleButtonsGroup>
          <MenuWrapper
            asModal
            trigger={<PanelTitleButton small icon="question" />}
          >
            <Card helper>
              <CardBody benchmarks>
                <CardHeaderTitle>
                  <FormattedMessage {...messages.benchmarksHelpLabel} />
                </CardHeaderTitle>
                <ProgressBarScale values={helpValueLabels} />
              </CardBody>
            </Card>
          </MenuWrapper>
          <MenuWrapper trigger={<PanelTitleButton />}>
            {testCols && testCols.find(tc => tc.id !== undefined) && (
              <Button
                menu
                onClick={() => {
                  const newWindow = openWindowWithLoader();
                  onExportBenchmark(newWindow);
                }}
              >
                <Icon id="print" />
                <FormattedMessage
                  {...messages.benchmarksMenuItemExportBenchmark}
                />
              </Button>
            )}
          </MenuWrapper>
        </PanelTitleButtonsGroup>
      </BreadcrumbsWrapper>

      <PanelTitleWrapper>
        <PanelTitle>
          {panelChoices ? (
            <MenuWrapper trigger={<span>{group.name}</span>}>
              {panelChoices}
            </MenuWrapper>
          ) : (
            `${group.name}`
          )}
        </PanelTitle>
        <PanelSubtitle>
          <FormattedMessage
            {...messages.benchmarksGroupPageSubtitle}
            values={{
              name: group.name
            }}
          />
        </PanelSubtitle>
      </PanelTitleWrapper>
      <div
        className="o-layout o-layout--small"
        style={{ width: 'calc(100% + 16px)' }}
      >
        <ResultsSettingsView />
      </div>
    </PanelHeader>
  );
}

export default ResultsPanelHeader;
