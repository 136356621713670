import { forwardRef } from 'react';
import classNames from 'classnames';

import './List.scss';

const RefactoredList = forwardRef(
  ({ children, style, extraClassNames }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('r-list', extraClassNames, {})}
        style={style}
      >
        {children}
      </div>
    );
  }
);

export default RefactoredList;
