import React from 'react';
import classNames from 'classnames';
import './Table.scss';

interface RefactoredTableProps {
  children?: React.ReactNode;
  /** We will start with one variant: `results` */
  variant?: 'fixed-width' | 'full-width';
  extraClassNames?: string;
  style?: object;
}

const RefactoredTable = ({
  children,
  variant,
  extraClassNames,
  style
}: RefactoredTableProps) => {
  return (
    <table
      className={classNames('r-table', extraClassNames, {
        'r-table--fixed-width': variant === 'fixed-width',
        'r-table--full-width': variant === 'full-width'
      })}
      style={style}
    >
      {children}
    </table>
  );
};

export default RefactoredTable;
