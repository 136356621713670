import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Icon from 'components/icon/Icon';
import { Button } from 'components/button';
import { useStore } from 'stores/Store';

function SortProgramExercisesButton({ ...props }) {
  const selectedProgramExercises = useStore(
    state => state.selectedProgramExercises
  );
  const programSortableActive = useStore(state => state.programSortableActive);
  const setProgramSortableActive = useStore(
    state => state.setProgramSortableActive
  );

  return (
    <Button
      {...props}
      onClick={() => {
        setProgramSortableActive(true);
        props.onClick && props.onClick();
      }}
      disabled={selectedProgramExercises.length > 0 || programSortableActive}
    >
      {programSortableActive ? (
        <FormattedMessage
          {...messages.templatesSortExercisesDisabledDragging}
        />
      ) : (
        <FormattedMessage {...messages.sortProgramButton} />
      )}
      <Icon id="arrange" strokeColor="color-neutral-dark" />
    </Button>
  );
}

export default SortProgramExercisesButton;
