import React, { useContext, useState } from 'react';
import {
  CardHeader,
  CardHeaderSubtitle,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { Card, CardBody, CardFooter } from 'components/card';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { format } from 'date-fns';
import { calculateInjuriesSubtitle } from 'utils/date';
import { useMedicalContext } from 'contexts/MedicalContext';
import { StoreContext } from 'index';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL } from 'routes/RouteList';
import AddIssueModal from 'containers/pages/rehab/issue/AddIssueModal';
import { DATE_LOCALE, PersonInjuryType } from 'constants.js';
import formatDistance from 'date-fns/formatDistance';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const SporterMedicalIssues = () => {
  const { entityId, groupId, issueId } = useParams();
  const intl = useIntl();
  const { push } = useHistory();
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const person = useEntityContext(s => s.person);
  const { medical } = useMedicalContext();
  const defaultListLength = 10;
  const [injuriesListCount, setInjuriesListCount] = useState(defaultListLength);
  const [issueModal, setIssueModal] = useState(false);

  const onClickIssueHandler = issue => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL, {
        entityId,
        groupId,
        sporterId: person.id,
        medicalId: medical.id,
        issueId: issue.id
      })
    );
  };

  return (
    <Card reports>
      <CardHeader secondary>
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleIssues} />
          </CardHeaderTitle>
          <CardHeaderSubtitle>
            {calculateInjuriesSubtitle(
              medical?.issues,
              locale,
              intl,
              PersonInjuryType.ISSUE
            )}
          </CardHeaderSubtitle>
        </CardHeaderText>
        <Button rounded primary onClick={() => setIssueModal(true)}>
          <Icon id="add-white" />
          <FormattedMessage {...messages.buttonAddIssue} />
        </Button>
      </CardHeader>
      <CardBody secondary>
        <List>
          <ListBody>
            {medical?.issues &&
              medical.issues.length > 0 &&
              medical.issues.slice(0, injuriesListCount).map(personIssue => (
                <ListItem
                  small
                  key={personIssue.id}
                  active={personIssue.id === issueId}
                  onClick={() => onClickIssueHandler(personIssue)}
                >
                  <ListItemLabelWrapper>
                    <ListItemLabel label>{personIssue.title}</ListItemLabel>
                    {personIssue.injuryEndDate ? (
                      <ListItemLabel label>
                        {formatDistance(
                          personIssue.injuryDate,
                          personIssue.injuryEndDate,
                          { locale: DATE_LOCALE[locale] }
                        )}{' '}
                        - {format(personIssue.injuryDate, 'yyyy')}
                      </ListItemLabel>
                    ) : (
                      <ListItemLabel label>
                        {format(personIssue.injuryDate, 'yyyy')}
                      </ListItemLabel>
                    )}
                  </ListItemLabelWrapper>
                </ListItem>
              ))}
          </ListBody>
        </List>
      </CardBody>
      <CardFooter>
        {medical?.issues && medical.issues.length > defaultListLength && (
          <Button
            rounded
            grey
            onClick={() => {
              setInjuriesListCount(
                injuriesListCount ? undefined : defaultListLength
              );
            }}
          >
            <Icon id="dropdown-closed" />
            <FormattedMessage
              {...messages.buttonShowInjuries}
              values={{
                showAll: Boolean(injuriesListCount)
              }}
            />
          </Button>
        )}
      </CardFooter>

      {issueModal && (
        <AddIssueModal
          entityId={entityId}
          sporterId={person.id}
          onClose={() => {
            setIssueModal(false);
          }}
        />
      )}
    </Card>
  );
};

export default SporterMedicalIssues;
