import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from 'react';
import * as Sentry from '@sentry/react';
import { GrowthTrackerEstimationMethods } from 'constants.js';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { useHistory } from 'react-router-dom';
import { differenceInWeeks, format } from 'date-fns';
import { QUERY_GET_GROWTH_DATA_OF_GROUP } from 'services/aws/growthtracker-query';
import { useLazyQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';

export const GrowthPanelContext = createContext({});

// TODO: Using this to determine width of columns
// aswell the labels
const GrowthTrackerBasicColumns = Object.freeze({
  PRECISE_AGE: {
    id: 'preciseAge',
    label: 'Age',
    width: 48,
    widthExpanded: 64
  },
  PREDICTED_ADULT_HEIGHT: {
    id: 'predictedAdultHeight',
    label: '% Predicted<br />Adult Height (K-R)',
    width: 96,
    widthExpanded: 120
  },
  HEIGHT_VELOCITY: {
    id: 'heightVelocity',
    label: 'Height<br />Velocity <span>(cm/year)</span>',
    width: 128,
    widthExpanded: 120
  },
  WEIGHT_VELOCITY: {
    id: 'weightVelocity',
    label: 'Weight<br />Velocity <span>(kg/year)</span>',
    width: 128,
    widthExpanded: 120,
    defaultView: true
  }
});

const GrowthTrackerFullColumns = Object.freeze({
  PRECISE_AGE: {
    id: 'preciseAge',
    label: 'Age',
    width: 48,
    widthExpanded: 64,
    isBasic: true
  },
  BODY_HEIGHT: {
    id: 'bodyHeight',
    label: 'Body Height',
    width: 64,
    widthExpanded: 64
  },
  BODY_WEIGHT: {
    id: 'bodyWeight',
    label: 'Body Weight',
    width: 64,
    widthExpanded: 64
  },
  ADULT_HEIGHT_PREDICTION: {
    id: 'adultHeightPrediction',
    label: 'A. H.<br />Predict.',
    width: 64,
    widthExpanded: 96
  },
  PAH_90_CI: {
    id: 'pah90CI',
    label: 'PAH 90% CI',
    width: 96,
    widthExpanded: 120
  },
  PREDICTED_ADULT_HEIGHT: {
    id: 'predictedAdultHeight',
    label: '% Pred. Ad.<br />Height (K-R)',
    width: 96,
    widthExpanded: 120,
    isBasic: true
  },
  GROWTH_PHASE: {
    id: 'growthPhase',
    label: 'Growth<br />phase',
    width: 96,
    widthExpanded: 120
  },
  DEVELOPMENT_ZSCORE: {
    id: 'devZScore',
    label: 'Development<br /><span>(Z-score)</span>',
    width: 96,
    widthExpanded: 120
  },
  HEIGHT_VELOCITY: {
    id: 'heightVelocity',
    label: 'Height<br />Velocity<br /><span>(cm/year)</span>',
    width: 96,
    widthExpanded: 120,
    isBasic: true
  },
  WEIGHT_VELOCITY: {
    id: 'weightVelocity',
    label: 'Weight<br />Velocity<br /><span>(kg/year)</span>',
    width: 96,
    widthExpanded: 120,
    isBasic: true
  },
  BIO_AGE: {
    id: 'bioAge',
    label: 'Bio-Age',
    width: 48,
    widthExpanded: 64
  },
  CHRONO_VS_AGE: {
    id: 'chronoVsBioAge',
    label: 'Chrono<br />vs Bio Age',
    width: 96
  }
});

const columnHelper = createColumnHelper();

const formatCell = data => {
  return data ? `${data.value} ${data.unit}` : '-';
};

const sortByValue = (rowA, rowB, _columnId) => {
  const valueA = rowA.original[_columnId].value;
  const valueB = rowB.original[_columnId].value;

  return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
};

const GrowthTrackerColumns = [
  columnHelper.accessor('person', {
    header: () => '',
    accessorKey: 'person',
    size: 200,
    minSize: 150,
    sortingFn: 'alphanumeric',
    enableHiding: false
  }),
  columnHelper.accessor('preciseAge', {
    header: () => 'Age',
    cell: props => formatCell(props),
    size: 48,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('bodyHeight', {
    header: () => 'Body Height',
    cell: props => formatCell(props),
    size: 64,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('bodyWeight', {
    id: 'bodyWeight',
    header: () => 'Body Weight',
    cell: props => formatCell(props),
    size: 64,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('adultHeightPrediction', {
    id: 'adultHeightPrediction',
    header: () => (
      <>
        A. H.
        <br />
        Predict.
      </>
    ),
    cell: props => formatCell(props),
    size: 64,
    widthExpanded: 96,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('pah90Ci', {
    id: 'pah90Ci',
    header: () => 'PAH 90% CI',
    cell: props => formatCell(props),
    size: 96,
    widthExpanded: 120,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('predictedAdultHeight', {
    id: 'predictedAdultHeight',
    header: () => (
      <>
        % Pred. Ad.
        <br />
        Height (K-R)
      </>
    ),
    cell: props => formatCell(props),
    size: 120,
    widthExpanded: 120,
    isBasic: true,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('growthPhase', {
    id: 'growthPhase',
    header: () => (
      <>
        Growth
        <br />
        phase
      </>
    ),
    cell: props => formatCell(props),
    size: 96,
    widthExpanded: 120
  }),
  columnHelper.accessor('developmentZScore', {
    id: 'developmentZScore',
    header: () => (
      <>
        Development
        <br />
        <span>(Z-score)</span>
      </>
    ),
    cell: props => formatCell(props),
    size: 96,
    widthExpanded: 120,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('heightVelocity', {
    id: 'heightVelocity',
    header: () => (
      <>
        Height
        <br />
        Velocity
        <br />
        <span>(cm/year)</span>
      </>
    ),
    cell: props => formatCell(props),
    size: 128,
    widthExpanded: 120,
    isBasic: true,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('weightVelocity', {
    id: 'weightVelocity',
    header: () => (
      <>
        Weight
        <br />
        Velocity
        <br />
        <span>(kg/year)</span>
      </>
    ),
    cell: props => formatCell(props),
    size: 128,
    widthExpanded: 120,
    isBasic: true,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('bioAge', {
    id: 'bioAge',
    header: () => <>Bio Age</>,
    cell: props => formatCell(props),
    size: 48,
    widthExpanded: 64,
    sortingFn: sortByValue
  }),
  columnHelper.accessor('chronoVsBio', {
    id: 'chronoVsBio',
    header: () => (
      <>
        Chrono
        <br />
        vs Bio Age
      </>
    ),
    cell: props => formatCell(props),
    size: 96,
    sortingFn: sortByValue
  })
];

const getAlertOldData = data => {
  return (
    data.heightEvolutionData.length > 0 &&
    (differenceInWeeks(
      new Date(),
      new Date(
        data.heightEvolutionData[
          data.heightEvolutionData.length - 1
        ].testDateTime
      )
    ) > 10 ||
      differenceInWeeks(
        new Date(),
        new Date(
          data.weightEvolutionData[
            data.weightEvolutionData.length - 1
          ].testDateTime
        )
      ) > 10)
  );
};

const getPAHLevel = growthPhase => {
  switch (true) {
    case growthPhase.toLowerCase().indexOf('pre') >= 0:
      return 3;

    case growthPhase.toLowerCase().indexOf('start') >= 0:
      return 1;

    case growthPhase.toLowerCase().indexOf('circa') >= 0:
      return 0;

    case growthPhase.toLowerCase().indexOf('post') >= 0:
      return 4;

    default:
      return 2;
  }
};

const getValue = testItemValues => {
  return testItemValues && Object.keys(testItemValues).length > 0
    ? testItemValues[0]
    : false;
};

const getRowData = data => {
  return {
    preciseAge: {
      value: data.preciseAge ? data.preciseAge : false,
      unit: 'yearShort'
    },
    bodyHeight: {
      value: getValue(
        data.testItemValues['19688d86-a4e8-4cfb-966d-69bdf035f6ab']
      ),
      unit: 'cm'
    },
    bodyWeight: {
      value: getValue(
        data.testItemValues['a2a243bc-8d3e-4eb8-9837-9a3bb814488d']
      ),
      unit: 'kg'
    },
    adultHeightPrediction: {
      value: getValue(
        data.testItemValues['5737716d-7eb3-4724-85d2-7baa4652ae93']
      ),
      unit: 'cm'
    },
    pah90Ci: {
      value: getValue(
        data.testItemValues['f7529cf9-ba05-4570-bedd-6213ee3b6ec8']
      ),
      unit: 'cm'
    },
    predictedAdultHeight: {
      value: getValue(
        data.testItemValues['face72bd-d260-44a3-b55c-3bae9e3d18dc']
      ),
      unit: '%',
      alertLevel: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          )
        : false,
      iconWarning: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          ) <= 1
        : false
    },
    growthPhase: {
      value: getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      ),
      unit: '',
      alertLevel: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          )
        : false,
      iconWarning: !!getValue(
        data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
      )
        ? getPAHLevel(
            data.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e'][0]
          ) <= 1
        : false
    },
    developmentZScore: {
      value: [
        getValue(data.testItemValues['0c58cad6-8a6f-402f-b30e-b00e06871f14']),
        !!getValue(data.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915'])
          ? getValue(
              data.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915']
            ).toFixed(2)
          : false
      ],

      unit: ''
    },
    heightVelocity: {
      value:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          .growthYear,
      unit: 'cmYearShort',
      alert:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        differenceInWeeks(
          new Date(),
          new Date(
            data.heightEvolutionData[
              data.heightEvolutionData.length - 1
            ].testDateTime
          )
        ) > 10,
      alertLevel:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          .alertLevel,
      iconWarning:
        data.heightEvolutionData &&
        data.heightEvolutionData.length > 0 &&
        data.heightEvolutionData[data.heightEvolutionData.length - 1]
          .alertLevel < 2
    },
    weightVelocity: {
      value:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          .growthYear,
      unit: 'kgYearShort',
      alert:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        differenceInWeeks(
          new Date(),
          new Date(
            data.weightEvolutionData[
              data.weightEvolutionData.length - 1
            ].testDateTime
          )
        ) > 10,
      alertLevel:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          .alertLevel,
      iconWarning:
        data.weightEvolutionData &&
        data.weightEvolutionData.length > 0 &&
        data.weightEvolutionData[data.weightEvolutionData.length - 1]
          .alertLevel < 2
    },
    bioAge: {
      value: getValue(
        data.testItemValues['5737716d-7eb3-4724-85d2-7baa4652XXXX']
      ),
      unit: 'yearShort'
    },
    chronoVsBio: {
      value: getValue(
        data.testItemValues['491e8645-9273-4ef4-be2d-5d09363ac088']
      ),
      unit: 'yearLong'
    }
  };
};

const GrowthPanelContextProvider = ({ groupId, children }) => {
  const location = useHistory();

  const usersArray = useRef([]);

  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sidePanelData, setSidePanelData] = useState(null);
  const [error, setError] = useState(null);
  const [method, setMethod] = useState(
    GrowthTrackerEstimationMethods.KHAMIS_ROCHE
  );
  const [showColumnVisibilityModal, setShowColumnVisibilityModal] =
    useState(false);

  const [fetchGrowthDataByGroup, { loading, error: fetchError, refetch }] =
    useLazyQuery(QUERY_GET_GROWTH_DATA_OF_GROUP);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchGrowthDataByGroup({
        variables: {
          entityId: groupId
        },
        fetchPolicy: 'network-only'
      });

      if (data?.getGrowthDataOfGroup2) {
        const growthData = data.getGrowthDataOfGroup2;

        usersArray.current = growthData.map(d => {
          return {
            id: d.person.id,
            person: `${d.person.firstname} ${d.person.lastname}`,
            alertOldData: getAlertOldData(d),
            ...getRowData(d)
          };
        });

        setRows(usersArray.current);
        setCols(GrowthTrackerColumns);
      }
    };

    if (groupId) {
      fetchData().catch(error => {
        Sentry.captureException(error);
      });
    }
  }, [groupId, location]);

  const onChangeMethod = method => {
    setMethod(method);
  };

  const onClickCell = (
    colIndex,
    user,
    testItem,
    laterality,
    average = null
  ) => {
    const sessionGrowth = user.growth?.[testItem.id];
    let data = [];

    const values = sessionGrowth?.reduce(
      (item, session) => {
        const percentage = session?.benchmarked?.[laterality]?.percentage;
        const result = session?.result?.[laterality]?.[0];

        if (result) {
          if (average) {
            item.avg = average;
          }

          if (result < item.min) item.min = Number(result);
          if (result > item.max) item.max = Number(result);

          if (session.testDate || session.sessionDate) {
            const date = session.testDate
              ? format(session.testDate, 'dd/LL/yyyy')
              : format(session.sessionDate, 'dd/LL/yyyy');
            const sortDate = session.testDate
              ? format(session.testDate, 'yyyy-LL-dd')
              : format(session.sessionDate, 'yyyy-LL-dd');

            //
            // testSessionId: ID
            // const url = `sessions/${rootEntityId}/${testSessionId}/sporter/${user.id}`;

            data.push({
              x: `${sortDate}`,
              y: testItem.input_type === 'select_with_options' ? 0 : result,
              result,
              date: date,
              sortDate,
              unit: session.unit,
              percentile: percentage,
              inputType: testItem.input_type
              //   url: url
            });
          }
        }
        return item;
      },
      {
        min: 0,
        max: 0,
        avg: null
      }
    );

    data = sort(data, {
      keys: [{ key: 'sortDate', dataType: SORT_DATA_TYPES.DATE_STRING }]
    });

    /* Under normal circumstances we will never take 2 equal test on the same
     ** day and the use both of them. So we'll remove the first test
     ** for now: */
    data = data.filter((value, index, array) => {
      return (
        array.findLastIndex(value2 => {
          return value2.date === value.date;
        }) === index
      );
      // tablerefactor
      /*
      // console.log('growthData', growthData);
      // return an object with all data rows with the user id as a key
      setRows(
        // @ts-ignore
        growthData.reduce((acc, d) => {
          acc[d.person.id] = {
            preciseAge: {
              value: d.preciseAge ? d.preciseAge : false,
              unit: 'yearShort'
            },
            bodyHeight: {
              value: getValue(
                d.testItemValues['19688d86-a4e8-4cfb-966d-69bdf035f6ab']
              ),
              unit: 'cm'
            },
            bodyWeight: {
              value: getValue(
                d.testItemValues['a2a243bc-8d3e-4eb8-9837-9a3bb814488d']
              ),
              unit: 'kg'
            },
            adultHeightPrediction: {
              value: getValue(
                d.testItemValues['5737716d-7eb3-4724-85d2-7baa4652ae93']
              ),
              unit: 'cm'
            },
            pah90Ci: {
              value: getValue(
                d.testItemValues['f7529cf9-ba05-4570-bedd-6213ee3b6ec8']
              ),
              unit: 'cm'
            },
            predictedAdultHeight: {
              value: getValue(
                d.testItemValues['face72bd-d260-44a3-b55c-3bae9e3d18dc']
              ),
              unit: '%',
              alertLevel: !!getValue(
                d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
              )
                ? getPAHLevel(
                    d.testItemValues[
                      '197bbf37-7db5-40c4-9675-0dec23339b8e'
                    ][0]
                  )
                : false,
              iconWarning: !!getValue(
                d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
              )
                ? getPAHLevel(
                    d.testItemValues[
                      '197bbf37-7db5-40c4-9675-0dec23339b8e'
                    ][0]
                  ) <= 1
                : false
            },
            growthPhase: {
              value: getValue(
                d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
              ),
              unit: '',
              alertLevel: !!getValue(
                d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
              )
                ? getPAHLevel(
                    d.testItemValues[
                      '197bbf37-7db5-40c4-9675-0dec23339b8e'
                    ][0]
                  )
                : false,
              iconWarning: !!getValue(
                d.testItemValues['197bbf37-7db5-40c4-9675-0dec23339b8e']
              )
                ? getPAHLevel(
                    d.testItemValues[
                      '197bbf37-7db5-40c4-9675-0dec23339b8e'
                    ][0]
                  ) <= 1
                : false
            },
            developmentZScore: {
              value: [
                getValue(
                  d.testItemValues['0c58cad6-8a6f-402f-b30e-b00e06871f14']
                ),
                !!getValue(
                  d.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915']
                )
                  ? getValue(
                      d.testItemValues['105b2d29-1469-4d27-bdc0-9d9b6c44b915']
                    ).toFixed(2)
                  : false
              ],

              unit: ''
            },
            heightVelocity: {
              value:
                d.heightEvolutionData &&
                d.heightEvolutionData.length > 0 &&
                d.heightEvolutionData[d.heightEvolutionData.length - 1]
                  .growthYear,
              unit: 'cmYearShort',
              alert:
                d.heightEvolutionData &&
                d.heightEvolutionData.length > 0 &&
                differenceInWeeks(
                  new Date(),
                  new Date(
                    d.heightEvolutionData[
                      d.heightEvolutionData.length - 1
                    ].testDateTime
                  )
                ) > 10,
              alertLevel:
                d.heightEvolutionData &&
                d.heightEvolutionData.length > 0 &&
                d.heightEvolutionData[d.heightEvolutionData.length - 1]
                  .alertLevel,
              iconWarning:
                d.heightEvolutionData &&
                d.heightEvolutionData.length > 0 &&
                d.heightEvolutionData[d.heightEvolutionData.length - 1]
                  .alertLevel < 2
            },
            weightVelocity: {
              value:
                d.weightEvolutionData &&
                d.weightEvolutionData.length > 0 &&
                d.weightEvolutionData[d.weightEvolutionData.length - 1]
                  .growthYear,
              unit: 'kgYearShort',
              alert:
                d.weightEvolutionData &&
                d.weightEvolutionData.length > 0 &&
                differenceInWeeks(
                  new Date(),
                  new Date(
                    d.weightEvolutionData[
                      d.weightEvolutionData.length - 1
                    ].testDateTime
                  )
                ) > 10,
              alertLevel:
                d.weightEvolutionData &&
                d.weightEvolutionData.length > 0 &&
                d.weightEvolutionData[d.weightEvolutionData.length - 1]
                  .alertLevel,
              iconWarning:
                d.weightEvolutionData &&
                d.weightEvolutionData.length > 0 &&
                d.weightEvolutionData[d.weightEvolutionData.length - 1]
                  .alertLevel < 2
            },
            bioAge: {
              value: getValue(
                d.testItemValues['5737716d-7eb3-4724-85d2-7baa4652XXXX']
              ),
              unit: 'yearShort'
            },
            chronoVsBio: {
              value: getValue(
                d.testItemValues['491e8645-9273-4ef4-be2d-5d09363ac088']
              ),
              unit: 'yearLong'
            }
          };
          return acc;
        }, {})
      );
       */
    });

    if (data.length === 0) return;

    const dataObj = {
      title: user.label,
      subTitle: testItem.title,
      laterality,
      chartData: {
        ...values,
        data: [
          {
            id: testItem.id,
            data: [...data]
          }
        ]
      }
    };

    setSidePanelData(dataObj);
  };

  const onCloseSidePanel = () => setSidePanelData(null);

  return (
    <GrowthPanelContext.Provider
      value={{
        loading,
        cols,
        rows,
        columnVisibility,
        setColumnVisibility,
        sorting,
        setSorting,
        onClickCell,
        sidePanelData,
        onCloseSidePanel,
        error,
        method,
        onChangeMethod,
        showColumnVisibilityModal,
        setShowColumnVisibilityModal
      }}
    >
      {children}
    </GrowthPanelContext.Provider>
  );
};

function useGrowthPanelContext() {
  const context = useContext(GrowthPanelContext);
  if (context === undefined) {
    throw new Error(
      'The GrowthPanelContext hook must be used within a GrowthPanelContext.Provider'
    );
  }
  return context;
}

export { GrowthPanelContextProvider, useGrowthPanelContext };
