import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  ROUTE_SCANNERS_ENTITY_SCANNER,
  ROUTE_SCANNERS_ENTITY_ADD
} from 'routes/RouteList';
import Button from 'components/button/Button';
import Panel from 'components/panel/Panel';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTools from 'components/panel/PanelTools';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { ScannerListItem } from 'containers/partials/list-items/ScannerListItem';
import { QUERY_GET_SCANNERS_BY_ENTITY_ID } from 'services/aws/scanner-query';
import { sortScannersByName } from 'utils/sort';
import Scanner from 'models/Scanner';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import Loader from 'components/loader/Loader';

class Overview extends React.Component {
  render() {
    const {
      intl,
      entityId,
      routing: { push },
      scannerId,
      active,
      authStore: { user, entity }
    } = this.props;

    return (
      <Panel active={active}>
        <Query
          query={QUERY_GET_SCANNERS_BY_ENTITY_ID}
          variables={{ entityId }}
          fetchPolicy="cache-and-network"
        >
          {result => {
            const { loading, error, data } = result;
            if (loading) return <Loader />;
            if (error) return <RetryPanel />;
            const scanners = data.getMtsScanners.map(sc => new Scanner(sc));
            const sortedScanners = sortScannersByName(scanners);
            return (
              <Fragment>
                <PanelHeader>
                  <PanelTitleWrapper>
                    <PanelTitle>
                      <FormattedMessage {...messages.scannerTitle} />
                    </PanelTitle>
                    <PanelSubtitle>
                      <FormattedMessage
                        {...messages.scannersSubtitle}
                        values={{
                          name: user.getEntityName(entity)
                        }}
                      />
                    </PanelSubtitle>
                  </PanelTitleWrapper>
                  <PanelTools>
                    <Button
                      rounded
                      primary
                      onClick={() =>
                        push(
                          ROUTE_SCANNERS_ENTITY_ADD.replace(
                            ':entityId',
                            entityId
                          )
                        )
                      }
                    >
                      <Icon id="add-white" />
                      <FormattedMessage {...messages.scannersAddButton} />
                    </Button>
                  </PanelTools>
                </PanelHeader>
                <List>
                  <ListBody>
                    {sortedScanners.map(scanner => (
                      <ScannerListItem
                        key={scanner.id}
                        scanner={scanner}
                        active={scannerId === scanner.id}
                        onClick={() =>
                          push(
                            `${ROUTE_SCANNERS_ENTITY_SCANNER.replace(
                              ':entityId',
                              entityId
                            ).replace(':scannerId', scanner.id)}`
                          )
                        }
                      />
                    ))}
                  </ListBody>
                </List>
              </Fragment>
            );
          }}
        </Query>
      </Panel>
    );
  }
}

export default withRouter(injectIntl(inject('routing', 'authStore')(Overview)));
