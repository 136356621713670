import React, { useContext } from 'react';
import FieldInput from 'components/input/FieldInput';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import Select, { Option } from 'components/input/Select';
import { GenderTypes } from 'enums';
import { LeftRight, PersonStatus } from 'constants.js';
import InputButtonWrapper from 'components/input/InputButtonWrapper';
import InviteSporterButton from 'containers/partials/buttons/InviteSporterButton';
import ResetLoginButton from 'containers/partials/buttons/ResetLoginButton';
import PersonStatusPopper from 'containers/partials/misc/PersonStatusPopper';
import { FieldTextarea } from 'components/input/FieldTextarea';
import { useFormikContext } from 'formik';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useLocation } from 'react-router-dom';
import { camelCaseify } from 'utils/string';
import RemoveLoginButton from 'containers/partials/buttons/RemoveLoginButton';
import SendMirrorInviteButton from 'containers/partials/buttons/SendMirrorInviteButton';
import OpenMirrorButton from 'containers/partials/buttons/OpenMirrorButton';
import MessageText from 'components/message/MessageText';

const PersonFormDetails = ({ edit, onClickPersonStatus }) => {
  const ability = useAbility(AbilityContext);
  const location = useLocation();
  const {
    authStore: {
      user,
      user: { rootEntityId }
    },
    uiState: { locale }
  } = useContext(StoreContext);
  const intl = useIntl();
  const {
    values: person,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    validateField
  } = useFormikContext();

  return (
    <>
      <div className="o-layout o-layout--tiny">
        <div className="o-layout__item u-1-of-2-at-small">
          <FieldInput
            id="firstname"
            name="firstname"
            readOnly={!edit}
            touched={touched}
            errors={errors}
            onBlur={handleBlur}
          >
            <FormattedMessage {...messages.cardLabelFirstname} />*
          </FieldInput>
        </div>
        <div className="o-layout__item u-1-of-2-at-small">
          <FieldInput
            id="lastname"
            name="lastname"
            readOnly={!edit}
            touched={touched}
            errors={errors}
            onBlur={handleBlur}
          >
            <FormattedMessage {...messages.cardLabelLastname} />*
          </FieldInput>
        </div>
      </div>
      <div className="o-layout o-layout--tiny">
        <div className="o-layout__item u-1-of-2-at-small">
          <FieldDatePicker
            id="birthdate"
            name="birthdate"
            icon="calendar"
            placeholder={'dd/mm/yyyy'}
            onChange={handleChange}
            readOnly={!edit}
            locale={locale}
            min={new Date('1930/01/01')}
            max={new Date()}
            errors={errors}
            required
            yearDropdownItemNumber={2}
            // scrollableYearDropdown
          >
            <FormattedMessage {...messages.cardLabelBirthdate} />*
          </FieldDatePicker>
        </div>
        <div className="o-layout__item u-1-of-2-at-small">
          <Select
            label={`${intl.formatMessage(messages.cardLabelGender)}*`}
            name="gender"
            value={person.gender}
            readOnly={!edit}
            onChange={selected => {
              setFieldValue('gender', selected.value);
              validateField('gender');
            }}
            touched={touched}
            errors={errors}
          >
            {Object.entries(GenderTypes).map(([key, value]) => {
              return (
                <Option
                  key={key}
                  value={value}
                  label={intl.formatMessage(
                    messages[`sex${camelCaseify(value)}`]
                  )}
                />
              );
            })}
          </Select>
        </div>

        {!user.isVitaleOrg &&
          (edit ||
            (person.uid !== ' ' &&
              person.uid !== '' &&
              person.uid !== undefined)) && (
            <div className="o-layout__item u-1-of-2-at-small">
              <FieldInput id="uid" name="uid" readOnly={!edit}>
                <FormattedMessage {...messages.cardLabelUid} />
              </FieldInput>
            </div>
          )}

        {!user.isMTSOrg &&
          !user.isVitaleOrg &&
          (edit || person.dominantHand) && (
            <div className="o-layout__item u-1-of-2-at-small">
              <Select
                label={`${intl.formatMessage(messages.cardLabelDominantHand)}`}
                name="dominantHand"
                value={person.dominantHand}
                readOnly={!edit}
                onChange={selected => {
                  setFieldValue('dominantHand', selected.value);
                }}
              >
                {Object.entries(LeftRight).map(([key, value]) => {
                  return (
                    <Option
                      key={key}
                      value={value}
                      label={intl.formatMessage(
                        messages[`side${camelCaseify(value)}`]
                      )}
                    />
                  );
                })}
              </Select>
            </div>
          )}
      </div>

      <div className="o-layout o-layout--tiny">
        <div className="o-layout__item u-1-of-2-at-large">
          <FieldInput
            id="athleteEmail"
            name="athleteEmail"
            readOnly={!edit}
            errors={errors}
            touched={touched}
          >
            <FormattedMessage {...messages.cardLabelEmail} />
          </FieldInput>
        </div>

        {ability.can('invite', 'Athletes') &&
          !edit &&
          person.id &&
          !person.email &&
          person.athleteEmail &&
          !errors['athleteEmail'] && (
            <div className="o-layout__item u-1-of-2-at-small">
              <InputButtonWrapper
                id="resetpassword-id"
                label={<FormattedMessage {...messages.labelUserInvite} />}
              >
                <InviteSporterButton
                  person={person}
                  entityId={rootEntityId}
                  target={location.pathname}
                  redirect={location.pathname}
                  extraClassNames={'c-button--ellipsed'}
                  title={intl.formatMessage(messages.buttonUserInvite)}
                >
                  <FormattedMessage {...messages.buttonUserInvite} />
                </InviteSporterButton>
              </InputButtonWrapper>
            </div>
          )}

        {ability.can('invite', 'Athletes') &&
          !edit &&
          person.id &&
          person.email &&
          !errors['athleteEmail'] && (
            <>
              <div className="o-layout__item u-1-of-2-at-large">
                <InputButtonWrapper
                  extraClassNamesWrapper="o-flex o-flex--align-center"
                  id="resetpassword-id"
                  label={
                    <FormattedMessage {...messages.labelUserResetPassword} />
                  }
                >
                  <ResetLoginButton
                    person={person}
                    entityId={rootEntityId}
                    target={location.pathname}
                    redirect={location.pathname}
                    extraClassNames={'c-button--ellipsed'}
                    title={intl.formatMessage(messages.buttonUserResetPassword)}
                  >
                    <FormattedMessage {...messages.buttonUserResetPassword} />
                  </ResetLoginButton>
                  {[PersonStatus.PENDING, PersonStatus.UNCONFIRMED].includes(
                    person.status
                  ) && (
                    <PersonStatusPopper
                      extraClassNames="u-margin-left-small"
                      person={person}
                      onClickStatus={onClickPersonStatus}
                    />
                  )}
                </InputButtonWrapper>
              </div>
              <div className="o-layout__item u-1-of-2-at-large">
                <InputButtonWrapper
                  id="remove-login-id"
                  label={
                    <FormattedMessage {...messages.labelUserRemoveLogin} />
                  }
                >
                  <RemoveLoginButton
                    personId={person.id}
                    entityId={rootEntityId}
                    target={location.pathname}
                    redirect={location.pathname}
                    extraClassNames={'c-button--ellipsed'}
                    title={intl.formatMessage(messages.buttonUserRemoveLogin)}
                  >
                    <FormattedMessage {...messages.buttonUserRemoveLogin} />
                  </RemoveLoginButton>
                </InputButtonWrapper>
              </div>
            </>
          )}
      </div>

      <div className="o-layout o-layout--tiny">
        <div className="o-layout__item u-2-of-2-at-small">
          {edit ? (
            <FieldTextarea
              id="notes-id"
              name="notes"
              rows="4"
              readOnly={!edit}
              extraClassNames={'u-margin-bottom-none'}
              resizeVertical
            >
              <FormattedMessage {...messages.cardLabelNotes} />
            </FieldTextarea>
          ) : (
            person.notes && (
              <>
                <p className={'c-input__label'}>
                  <FormattedMessage {...messages.cardLabelNotes} />
                </p>
                <p>{person.notes}</p>
              </>
            )
          )}
        </div>
      </div>

      {ability.can('manage', 'MirrorIntegration') && !edit && (
        <div className="o-layout o-layout--tiny">
          <div className="o-layout__item u-1-of-2-at-large">
            <InputButtonWrapper
              extraClassNamesWrapper="o-flex o-flex--align-center"
              id="resetpassword-id"
              label={<FormattedMessage {...messages.labelButtonStartMirror} />}
            >
              {person.integrations?.mirror ? (
                <OpenMirrorButton
                  small
                  light
                  entityId={rootEntityId}
                  person={person}
                >
                  <FormattedMessage {...messages.buttonOpenMirror} />
                </OpenMirrorButton>
              ) : (
                <>
                  {person.id && (
                    <SendMirrorInviteButton
                      person={person}
                      entityId={rootEntityId}
                      target={location.pathname}
                      redirect={location.pathname}
                      disabled={!person.email && !person.athleteEmail}
                      extraClassNames={'c-button--ellipsed'}
                      title={intl.formatMessage(messages.buttonStartMirror)}
                    >
                      <FormattedMessage {...messages.buttonStartMirror} />
                    </SendMirrorInviteButton>
                  )}
                </>
              )}
            </InputButtonWrapper>
            {person.id && !person.email && !person.athleteEmail && (
              <MessageText>
                <FormattedMessage {...messages.labelNoEmail} />
              </MessageText>
            )}
          </div>
        </div>
      )}
    </>
  );
};
PersonFormDetails.propTypes = {};

export default PersonFormDetails;
