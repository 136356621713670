import classNames from 'classnames';

const PanelSubtitle = props => {
  const { extraClassNames, children, ...rest } = props;
  return (
    <h3 className={classNames('c-panel__subtitle', extraClassNames)}>
      {children ? children : '\u00a0'}
    </h3>
  );
};

export default PanelSubtitle;
