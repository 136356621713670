import classNames from 'classnames';

const RefactoredPanelHeader = ({ ...props }) => {
  const { extraClassNames, children, ...rest } = props;
  return (
    <div className={classNames('r-panel__header', extraClassNames, {})}>
      {children}
    </div>
  );
};

export default RefactoredPanelHeader;
