import React, { useContext, useEffect, useState } from 'react';

import { inject } from 'mobx-react';
import { useHistory, withRouter } from 'react-router-dom';

import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItem from 'components/list/list-item/ListItem';
import Button from 'components/button/Button';

import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_ORGANISATIONS_ORG
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/icon/Icon';
import PanelTools from 'components/panel/PanelTools';
import { sortEntities } from 'utils/sort';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import messages from 'messages';
import { QUERY_GET_ORGANISATIONS_BY_ID } from 'services/aws/entity-query';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { EntityTypes } from 'constants.js';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import { useQuery } from '@apollo/client';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import AddOrganisationModal from 'containers/pages/settings/organisations/AddOrganisationModal';

const Organisations = ({ organisationId }) => {
  const { push } = useHistory();
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const ability = useAbility(AbilityContext);
  const [groups, setGroups] = useState([]);
  const [addOrganisationModal, setAddOrganisationModal] = useState(null);
  const { data, loading, error } = useQuery(QUERY_GET_ORGANISATIONS_BY_ID, {
    variables: {
      id: rootEntityId
    }
  });

  useEffect(() => {
    if (data?.getOrganisations) {
      const entities = data.getOrganisations.subEntities;
      const filteredEntities = entities.filter(
        e => e.type === EntityTypes.ORGANISATION
      );
      setGroups(sortEntities(filteredEntities));
    }
  }, [data]);

  return (
    <Panel>
      <PanelHeader borderBottom>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettings} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.organisationsTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.organisationsSubtitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools>
          {ability.can('create', 'Organisations') && (
            <Button
              rounded
              primary
              onClick={() => setAddOrganisationModal(true)}
            >
              <Icon id="add-white" />
              <FormattedMessage
                {...messages.organisationsAddOrganisationButton}
              />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      {loading && <Loader />}
      {error && <RetryPanel />}
      {groups.length > 0 && (
        <List>
          <ListBody>
            {groups.map(org => (
              <OrganisationListItem
                key={org.id}
                organisation={org}
                push={push}
                active={organisationId === org.id}
              />
            ))}
          </ListBody>
        </List>
      )}
      {addOrganisationModal && (
        <AddOrganisationModal onClose={() => setAddOrganisationModal(false)} />
      )}
    </Panel>
  );
};

export default withRouter(Organisations);

const OrganisationListItem = inject('routing')(
  ({ active, organisation: { id, name }, routing: { push } }) => (
    <ListItem
      onClick={() =>
        push(ROUTE_SETTINGS_ORGANISATIONS_ORG.replace(':organisationId', id))
      }
      active={active}
    >
      <ListItemStatus statusIcon="organisations-white" />
      <ListItemLabelWrapper>
        <ListItemLabel label>{name}</ListItemLabel>
      </ListItemLabelWrapper>
    </ListItem>
  )
);
