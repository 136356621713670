import * as Sentry from '@sentry/react';
import { generatePath, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import * as Yup from 'yup';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { Form, Formik } from 'formik';
import { Card, CardBody, CardFooter } from 'components/card';
import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { Button, ButtonsGroup } from 'components/button';
import {
  MUTATION_ADD_PERSONS_TO_PROGRAM,
  MUTATION_ADD_PROGRAM,
  QUERY_GET_PROGRAMS
} from 'services/aws/programs-query';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { NoteTypes } from 'constants.js';
import { MUTATION_ADD_NOTE } from 'services/aws/notes-query';
import { FieldDatePicker } from 'components/input/FieldDatePicker';
import { useNotificationQueue } from 'components/notification';
import useTemplate from 'hooks/queries/useTemplate';
import Loader from 'components/loader/Loader';
import { parseDateString } from 'utils/date';
import { format } from 'date-fns';
import { useStore } from 'stores/Store';
import { useEffect, useState } from 'react';
import { List } from 'components/list';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import Badge from 'components/badge/Badge';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const getNote = (exercise, notes) => {
  if (notes && notes.length > 0) {
    return notes[0].note;
  }
  if (exercise.note) {
    return exercise.note;
  }
  return '';
};

function AddProgramModal({
  entityId,
  destiny,
  program,
  persons,
  onClose,
  fromTemplate
}) {
  const { push } = useHistory();
  const intl = useIntl();
  const notification = useNotificationQueue();
  const [addProgram] = useMutation(MUTATION_ADD_PROGRAM);
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [addPersonsToProgram] = useMutation(MUTATION_ADD_PERSONS_TO_PROGRAM);
  const { fetchTemplate, loading } = useTemplate();
  const template = useStore(state => state.template);
  const [exercises, setExercises] = useState([]);

  const tabIndex = 0;
  const [tabsIndex, setTabsIndex] = useState(tabIndex);
  useEffect(() => {
    setTabsIndex(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    const getTemplate = async () => {
      const template = await fetchTemplate({
        variables: { templateId: program?.templateId }
      });
      if (template?.exercises) {
        setExercises(template.exercises);
      }
    };
    if (program?.templateId) {
      getTemplate();
    }
  }, [program?.templateId]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(intl.formatMessage(messages.titleRequired)),
    startdate: Yup.date()
      .transform(parseDateString)
      .typeError(intl.formatMessage(messages.dateRequired))
      .required(intl.formatMessage(messages.dateRequired)),
    duration: Yup.number().required(
      intl.formatMessage(messages.durationRequired)
    )
  });

  const onSubmitForm = async program => {
    let newProgramId = '';
    addProgram({
      variables: {
        ...program,
        startdate: format(program.startdate, 'yyyy-LL-dd')
      },
      refetchQueries: [{ query: QUERY_GET_PROGRAMS, variables: { entityId } }]
    })
      .then(res => {
        newProgramId = res.data.addExerciseProgram.id;
        Promise.all([
          template?.notes &&
            template.notes.length > 0 && {
              ...Object.values(template.notes).map(note => {
                return addNote({
                  variables: {
                    linkId: newProgramId,
                    linkId2: note[0].linkId2,
                    note: note[0].note,
                    noteType: NoteTypes.PROGRAM
                  }
                });
              })
            },
          persons &&
            addPersonsToProgram({
              variables: {
                exerciseProgramId: newProgramId,
                personIds: persons.map(p => p.id)
              }
            })
        ]).then(() => {
          push(
            generatePath(destiny.path, {
              entityId,
              sessionsTab: 'sessions',
              programId: newProgramId,
              ...destiny.variables
            })
          );
          onClose && onClose();
        });
      })
      .catch(error => {
        notification.add(`addProgramError${program.id}`, {
          message: intl.formatMessage(messages.messageAddProgramSaveError),
          level: 'error'
        });
        Sentry.captureException(error);

        onClose && onClose();
      });
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <Formik
        initialValues={program}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={values => onSubmitForm(values)}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form noValidate>
              <CardHeader modal>
                <CardHeaderTitle>
                  <FormattedMessage
                    {...messages.exercisesAddProgramTitle}
                    values={{
                      fromTemplate
                    }}
                  />
                </CardHeaderTitle>
              </CardHeader>
              <CardBody modal bodyWithTabs>
                <Tabs
                  onModal
                  selectedIndex={tabsIndex}
                  onSelect={index => setTabsIndex(index)}
                >
                  <TabList>
                    <Tab
                      tabIndex="0"
                      key="program-details"
                      data-qa="program-details-tab"
                    >
                      <FormattedMessage {...messages.tabSessionDetail} />
                    </Tab>
                    {fromTemplate && (
                      <Tab tabIndex="0" key="exercises" data-qa="exercises-tab">
                        <Badge>{template?.exercises?.length}</Badge>
                        <FormattedMessage {...messages.tabExercises} />
                      </Tab>
                    )}
                  </TabList>
                  <TabPanel key="program-details">
                    <TabPanelBody>
                      <CardHeader secondary>
                        <CardHeaderTitle>
                          <FormattedMessage {...messages.cardTitleExercise} />
                        </CardHeaderTitle>
                      </CardHeader>
                      <CardBody secondary>
                        <FieldInput
                          id="title"
                          name="title"
                          placeholder={intl.formatMessage(
                            messages.programPlaceholderName
                          )}
                          errors={errors}
                          touched={touched}
                          required
                        >
                          <FormattedMessage {...messages.programLabelName} />
                        </FieldInput>
                        <div className="o-layout o-layout--small">
                          <div className="o-layout__item u-1-of-2">
                            <FieldDatePicker
                              id="startdate"
                              name="startdate"
                              icon="calendar"
                              placeholder={intl.formatMessage(
                                messages.programPlaceholderStartDate
                              )}
                              errors={errors}
                              touched={touched}
                              required
                              yearDropdownItemNumber={2}
                            >
                              <FormattedMessage
                                {...messages.programLabelStartDate}
                              />
                            </FieldDatePicker>
                          </div>
                          <div className="o-layout__item u-1-of-2">
                            <FieldInput
                              id="duration"
                              name="duration"
                              placeholder={intl.formatMessage(
                                messages.programPlaceholderWeeks
                              )}
                              errors={errors}
                              touched={touched}
                              required
                            >
                              <FormattedMessage
                                {...messages.programLabelWeeks}
                              />
                            </FieldInput>
                          </div>
                        </div>
                      </CardBody>
                    </TabPanelBody>
                  </TabPanel>
                  {fromTemplate && (
                    <TabPanel key="exercises">
                      <TabPanelBody>
                        {exercises.length > 0 ? (
                          <>
                            {loading && <Loader />}
                            <List draggableDisabled onModal>
                              <ListBody extraClassNames="u-margin-bottom-large">
                                {exercises.map(exercise => (
                                  <ListItem key={exercise.id}>
                                    <ListItemLabelWrapper>
                                      <ListItemLabel label>
                                        {exercise.title}
                                      </ListItemLabel>
                                      <div className="c-list__item-small-editing-area">
                                        <p>
                                          {getNote(
                                            exercise,
                                            template.notes[exercise.id]
                                          )}
                                        </p>
                                      </div>
                                    </ListItemLabelWrapper>
                                  </ListItem>
                                ))}
                              </ListBody>
                            </List>
                          </>
                        ) : (
                          <Card centered extraClassNames="u-margin-none">
                            <CardBody empty>
                              <Message
                                iconFillColor="color-neutral-dark"
                                extraClassNames="u-margin-bottom-large"
                                empty
                                icon="cursor"
                              >
                                <MessageText>
                                  <FormattedMessage
                                    {...messages.exercisesListEmpty}
                                  />
                                </MessageText>
                              </Message>
                            </CardBody>
                          </Card>
                        )}
                      </TabPanelBody>
                    </TabPanel>
                  )}
                </Tabs>
              </CardBody>

              <CardFooter modal extraClassNames="c-card__footer--modal-larger">
                <ButtonsGroup>
                  <Button secondary onClick={() => onCloseHandler()}>
                    <FormattedMessage {...messages.buttonCancel} />
                  </Button>
                  <Button type="submit" primary disabled={isSubmitting}>
                    <FormattedMessage {...messages.buttonSave} />
                  </Button>
                </ButtonsGroup>
              </CardFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default AddProgramModal;
