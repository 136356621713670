import Program from 'models/Program';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_PROGRAM } from 'services/aws/programs-query';
import { useStore } from 'stores/Store';

const useProgram = () => {
  const setProgram = useStore(state => state.setProgram);
  const [getProgram, { loading, error }] = useLazyQuery(QUERY_GET_PROGRAM);

  const fetchProgram = async options => {
    const { data } = await getProgram({
      ...options
    });

    if (data?.getExerciseProgram) {
      const programData = data.getExerciseProgram;
      const program = new Program({
        ...programData
      });
      setProgram(program);
      return program;
    }
  };

  return { loading, error, fetchProgram };
};

export default useProgram;
