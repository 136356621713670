import React, { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import Person from 'models/Person';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY_SPORTER } from 'routes/RouteList';
import PersonFormDetails from 'containers/pages/persons/PersonFormDetails';
import PersonForm from 'containers/pages/persons/PersonForm';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function CreatePersonModal({ onClose, onDone }) {
  const { entityId, groupId } = useParams();
  const { push } = useHistory();
  const onCloseHandler = () => {
    onClose && onClose();
  };
  const [inGroup, setInGroup] = useState(groupId !== 'all');
  const group = useEntityContext(s => s.group);

  useEffect(() => {
    setInGroup(groupId !== 'all' && group.id !== entityId);
  }, [groupId]);

  const onPersonAddedHandler = async personId => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
        entityId,
        groupId: inGroup ? group.id : 'all',
        sporterId: personId
      })
    );

    onDone && onDone();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage
            {...messages.titleSportersAddSporter}
            values={{
              inGroup,
              group: group.name,
              span: chunks => (
                <span className="c-card__header-highlight">{chunks}</span>
              )
            }}
          />
        </CardHeaderTitle>
      </CardHeader>

      <PersonForm
        entityId={entityId}
        groupId={group.id}
        person={new Person({})}
        onAdded={onPersonAddedHandler}
      >
        {({ isSubmitting }) => (
          <>
            <CardBody modalWithOverflow extraClassNames="u-padding-bottom-huge">
              <PersonFormDetails entityId={entityId} edit={true} />
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button
                  type="button"
                  secondary
                  onClick={() => onCloseHandler()}
                >
                  <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.saveButton} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </PersonForm>
    </Modal>
  );
}

export default CreatePersonModal;
