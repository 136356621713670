import { useLazyQuery } from '@apollo/client';
import Template from 'models/Template';
import { QUERY_GET_TEMPLATES } from 'services/aws/templates-query';
import { getFilteredEntities } from 'utils/search';
import { useStore } from 'stores/Store';

const useCollections = entityId => {
  const setCollections = useStore(state => state.setCollections);
  const collectionsRef = useStore(state => state.collectionsRef);
  const setCollectionsRef = useStore(state => state.setCollectionsRef);
  const setCollectionsFilter = useStore(state => state.setCollectionsFilter);

  const [getCollections, { loading, error }] =
    useLazyQuery(QUERY_GET_TEMPLATES);

  const fetchCollections = async options => {
    const { data } = await getCollections({
      variables: { entityId },
      ...options
    });
    if (data?.getExerciseProgramTemplates) {
      const collections = data.getExerciseProgramTemplates.map(
        template => new Template({ ...template })
      );
      setCollectionsRef(collections);
      setCollections(collections);

      return collections;
    }
  };

  const filterCollections = async filter => {
    setCollectionsFilter(filter);
    const collections = [...collectionsRef];

    setCollections(
      getFilteredEntities(collections, filter?.search || '', filter.tags || [])
    );
  };

  return {
    loading,
    error,
    fetchCollections,
    filterCollections
  };
};

export default useCollections;
