import React, { useEffect, useState } from 'react';
import { useNotificationQueue } from 'components/notification/index';
import classNames from 'classnames';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';

function Notification({
  id,
  message,
  duration = 5000,
  level,
  position,
  closeable
}) {
  const notifications = useNotificationQueue();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(!show);
    const timeout = setTimeout(() => {
      if (duration > 0) {
        setShow(!show);

        setTimeout(() => {
          notifications.remove(id);
        }, 500);
      }
    }, duration);

    return () => {
      duration > 0 && clearTimeout(timeout);
    };
  }, [id, duration, notifications.remove]);

  const closeNotification = () => {
    setShow(!show);
    const triggeredTimeout = setTimeout(() => {
      notifications.remove(id);
      clearTimeout(triggeredTimeout);
    }, 500);
  };

  return (
    <>
      <div
        className={classNames('c-badge c-badge--notification', {
          'c-badge--error': level === 'error',
          'c-badge--show': show,
          'c-badge--closeable': duration <= 0 || closeable
        })}
        style={
          position && {
            width: 320,
            top: `calc(${position.y.toFixed(0)}px )`,
            left: `calc(${position.x.toFixed(0)}px - 320px)`,
            transform: 'none'
          }
        }
        key={id}
      >
        {message}
        {(duration <= 0 || closeable) && (
          <Button tiny onClick={() => closeNotification()}>
            <Icon id="close" strokeColor="color-neutral-x-light" />
            <span className="u-hidden-visually">close</span>
          </Button>
        )}
      </div>
    </>
  );
}

export default Notification;
