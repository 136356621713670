import gql from 'graphql-tag';
import { gqlMutation } from './utils/mutate';
import { appSyncClient } from './app-sync';
import { getGQLResult } from 'services/aws/utils/get';

export const QUERY_GET_GROUP_THREE_BY_ORGANISATION_ID = gql`
  query getEntityTree($entityId: ID!) {
    getEntityTree(entityId: $entityId)
  }
`;

export const getEntityTree = async entityId => {
  const query = gql`
    query getEntityTree($entityId: ID!) {
      getEntityTree(entityId: $entityId)
    }
  `;
  return await getGQLResult(appSyncClient, query, {
    entityId
  });
};

export const QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      parentId
      type
      subEntities {
        id
        name
        type
        clients {
          id
        }
      }
      clients {
        id
        firstname
        lastname
        birthdate
        gender
        email
        meta
        account_meta
        status @client
      }
    }
  }
`;

export const S_QUERY_GET_GROUPS_AND_CLIENTS_BY_ENTITY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      parentId
      type
      subEntities {
        id
        parentId
        name
        meta
        type
        clients {
          id
          firstname
          lastname
        }
        subEntities {
          id
          parentId
        }
      }
      clients {
        id
        firstname
        lastname
        birthdate
        gender
        account_meta
        email
        meta
        status @client
      }
      meta
    }
  }
`;

export const QUERY_GET_GROUP_AND_CLIENTS_BY_ENTITY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      parentId
      type
      clients {
        id
        firstname
        lastname
        birthdate
        gender
        email
        meta
        account_meta
        status @client
      }
    }
  }
`;

export const QUERY_GET_GROUPS_BY_ENTITY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      type
      parentId
      clients {
        id
      }
      subEntities {
        id
        parentId
        name
        type
        clients {
          id
        }
        subEntities {
          id
          parentId
        }
      }
    }
  }
`;

export const MUTATION_ADD_CLIENT = gql`
  mutation addUserToEntity(
    $entityId: ID!
    $topLevelEntityId: ID!
    $person: PersonInput!
  ) {
    addPersonToEntityReturnPerson(
      input: {
        entityId: $entityId
        topLevelEntityId: $topLevelEntityId
        person: $person
        roletype: client
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_CLIENT = gql`
  mutation editPerson(
    $entityId: ID!
    $id: ID!
    $firstname: String!
    $lastname: String!
    $gender: GenderTypes!
    $birthdate: AWSDate!
    $uid: String!
    $email: String
    $dominantHand: leftRight
    $notes: String
    $meta: AWSJSON
  ) {
    editPerson(
      entityId: $entityId
      input: {
        id: $id
        firstname: $firstname
        lastname: $lastname
        gender: $gender
        birthdate: $birthdate
        uid: $uid
        email: $email
        dominantHand: $dominantHand
        notes: $notes
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATE_SEND_MTS_REPORT_TO_SPORTER = gql`
  mutation sendMtsReport($testDataId: ID!) {
    sendMtsReport(testDataId: $testDataId)
  }
`;

export const copyPersonToOtherEntity = async variables => {
  const mutation = MUTATION_COPY_PERSON_TO_OTHER_ENTITY;
  return new Promise(async (resolve, reject) => {
    try {
      await gqlMutation(appSyncClient, {
        mutation,
        variables,
        update: (cache, { data, data: { copyPersonEntity } }) => {
          if (copyPersonEntity && copyPersonEntity.id) {
            resolve(copyPersonEntity.id);
          }
        }
      });
    } catch (error) {
      resolve({ error, ...variables });
    }
  });
};

export const MUTATION_COPY_PERSON_TO_OTHER_ENTITY = gql`
  mutation copyPersonEntity(
    $personId: ID!
    $oldEntityId: ID!
    $newEntityId: ID!
  ) {
    copyPersonEntity(
      personId: $personId
      oldEntityId: $oldEntityId
      newEntityId: $newEntityId
    ) {
      id
    }
  }
`;

export const movePersonToOtherEntity = async variables => {
  const mutation = MUTATION_MOVE_PERSON_TO_OTHER_ENTITY;
  return new Promise(async (resolve, reject) => {
    try {
      await gqlMutation(appSyncClient, {
        mutation,
        variables,
        update: (cache, { data, data: { movePersonEntity } }) => {
          if (movePersonEntity && movePersonEntity.id) {
            resolve(movePersonEntity.id);
          }
        }
      });
    } catch (error) {
      resolve({ error, ...variables });
    }
  });
};

export const MUTATION_MOVE_PERSON_TO_OTHER_ENTITY = gql`
  mutation movePersonEntity(
    $personId: ID!
    $oldEntityId: ID!
    $newEntityId: ID!
  ) {
    movePersonEntity(
      personId: $personId
      oldEntityId: $oldEntityId
      newEntityId: $newEntityId
    ) {
      id
    }
  }
`;

export const addPersonToEntityFromPool = async variables => {
  const mutation = MUTATION_ADD_PERSON_TO_GROUP_FROM_USERPOOL;
  return new Promise(async (resolve, reject) => {
    try {
      await gqlMutation(appSyncClient, {
        mutation,
        variables,
        update: (cache, { data, data: { addPersonToGroupFromUserpool } }) => {
          if (addPersonToGroupFromUserpool && addPersonToGroupFromUserpool.id) {
            resolve(addPersonToGroupFromUserpool.id);
          }
        }
      });
    } catch (error) {
      resolve({ error, ...variables });
    }
  });
};

export const MUTATION_ADD_PERSON_TO_GROUP_FROM_USERPOOL = gql`
  mutation addPersonToGroupFromUserpool(
    $newEntityId: ID!
    $personId: ID!
    $topLevelEntityId: ID!
  ) {
    addPersonToGroupFromUserpool(
      personId: $personId
      newEntityId: $newEntityId
      topLevelEntityId: $topLevelEntityId
    ) {
      id
    }
  }
`;
export const MUTATION_ADD_PERSONS_TO_GROUP_FROM_USERPOOL = gql`
  mutation addPersonsToGroupFromUserpool(
    $newEntityId: ID!
    $personIds: [ID]!
    $topLevelEntityId: ID!
  ) {
    addPersonsToGroupFromUserpool(
      personIds: $personIds
      newEntityId: $newEntityId
      topLevelEntityId: $topLevelEntityId
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_PERSON_LOGIN = gql`
  mutation removeLogin($personId: ID!, $entityId: ID!) {
    removeLogin(personId: $personId, entityId: $entityId)
  }
`;

export const removePersonLogin = async (variables, refetchQueries) => {
  const mutation = MUTATION_REMOVE_PERSON_LOGIN;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables,
      refetchQueries,
      update: (cache, { data: { removeLogin } }) => {
        if (removeLogin) {
          resolve(true);
        }
      }
    });
  });
};

export const MUTATION_REMOVE_PERSON_FROM_ORGANISATION = gql`
  mutation removePersonFromOrganisation($personId: ID!, $entityId: ID!) {
    removePersonFromOrganisation(personId: $personId, entityId: $entityId)
  }
`;

export const removePersonFromOrganisation = async (
  variables,
  refetchQueries
) => {
  const mutation = MUTATION_REMOVE_PERSON_FROM_ORGANISATION;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables,
      refetchQueries,
      update: (cache, { data: { removePersonFromOrganisation } }) => {
        if (removePersonFromOrganisation) {
          resolve(true);
        }
      }
    });
  });
};
