import React, { useState, useEffect, useRef, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Card } from 'components/card';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import FormEditHeaderButtons from '../buttons/FormEditHeaderButtons';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useSessionContext } from 'contexts/SessionContext';
import FormEditFooterButtons from '../buttons/FormEditFooterButtons';
import Button from 'components/button/Button';
import TestItemResultsList from 'containers/partials/lists/TestItemResultsList';
import { useTestDataContext } from 'contexts/TestDataContext';
import { useSetState, useUnmount } from 'react-use';
import { useIdle } from 'react-use';
import useEdit from 'hooks/utils/useEdit';
import { useBeforeunload } from 'react-beforeunload';
import { useEditableContext } from 'contexts/EditableContext';
import { StoreContext } from 'index';
import { TabPanelHead } from 'components/tabs';
import TimeNavigator from 'containers/partials/time-navigator/TimeNavigator';
import { format } from 'date-fns';
import useEditableSession from 'hooks/utils/useEditableSession';

function EditTestResultCard({ personInjury, rootPath, sporterId, ...props }) {
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);
  const { push, block } = useHistory();
  const { entityId, testDataId } = useParams();
  const blocker = useRef();
  const [nextLocation, setNextLocation] = useState();
  const { session, refetch } = useSessionContext();
  const {
    testData,
    submitData,
    fetchTestData,
    refetchTestData,
    createTestData,
    formIsUpdated,
    formIsDirty,
    hasResults,
    activeDate,
    setNewActiveDate
  } = useTestDataContext();
  const [isEditable, checkIfEditable] = useEditableSession();
  const isIdle = useIdle(5e3);
  const rootRef = useRef(null);
  const { setIsEditing } = useEditableContext();
  const [edit, setEdit] = useEdit(props.edit, true);
  const [state, setState] = useSetState({
    previousDate: undefined,
    nextDate: undefined,
    selectedDate: undefined,
    highlightDates: []
  });

  // prevent unloading the page when user has unsaved data
  useBeforeunload(event => {
    if (formIsDirty) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    setIsEditing(edit ? testDataId : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, testDataId]);

  useEffect(() => {
    if (session?.id) {
      if (session.recurring) {
        const testedDates = session.getTestedDates(sporterId);
        if (activeDate) {
          checkIfEditable({
            session,
            testedDates: testedDates.finished,
            date: activeDate,
            user
          });
          setState({
            selectedDate: testData.testDataDate ?? activeDate,
            highlightDates: testedDates,
            previousDate: session.getPreviousTestedDate(
              testData.testDataDate ?? activeDate,
              sporterId
            ),
            nextDate: session.getNextTestedDate(
              testData.testDataDate ?? activeDate,
              sporterId
            )
          });
        }
      } else {
        checkIfEditable({
          session,
          user
        });
      }
    }
  }, [session, testData, activeDate]);

  // Triggers warning "Warning: A history supports only one prompt at a time"
  // Needs to be fixed

  blocker.current = block(location => {
    if (edit && formIsDirty) {
      setTimeout(() => {
        setNextLocation(location);
      }, 500);
      return false;
    } else {
      return true;
    }
  });

  useEffect(() => {
    if (!formIsDirty && blocker.current && nextLocation) {
      blocker.current();
      push(nextLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, formIsDirty, nextLocation]);

  useEffect(() => {
    const submitTheForm = async () => {
      await submitData(testDataId);
      await getNewData();
    };
    if (isIdle && formIsDirty) {
      submitTheForm().catch(error => {
        Sentry.captureException(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  useUnmount(() => {
    setIsEditing(undefined);
    if (formIsUpdated) {
      getNewData();
    }
  });

  const getNewData = async () => {
    await refetch({ variables: { entityId, testSessionId: session.id } });
    if (testDataId) {
      if (!formIsUpdated) {
        await fetchTestData(session.id, testDataId);
      } else {
        await refetchTestData();
      }
    }
    return 'done';
  };

  const onSetEdit = async e => {
    if (!e) {
      endForm(e);
    } else {
      if (!testDataId) {
        let testDataDate = session.startdate;
        if (session.recurring) {
          testDataDate = activeDate ?? new Date();
        }
        const newTestDataId = await createTestData(
          format(testDataDate, 'yyyy-MM-dd')
        );
        push(`${rootPath}/tests/${newTestDataId}/edit`);
      } else {
        setEdit(true);
      }
    }
  };

  const endForm = async () => {
    uiState.increasePendingRequest();
    if (formIsDirty) {
      await submitData(testDataId);
    }
    await getNewData();
    uiState.decreasePendingRequest();
    setEdit(false);
  };

  const submitForm = async () => {
    uiState.increasePendingRequest();
    await submitData(testDataId);
    uiState.decreasePendingRequest();
  };

  const onChangeDate = async (date, testDataId) => {
    if (testDataId) {
      push(`${rootPath}/tests/${testDataId}`);
      return;
    } else {
      push(`${rootPath}/tests`);
    }
    setNewActiveDate(date);
  };

  // if (!testDataId || !groupedTests || (testDataLoading && !edit))
  //   return <Loader />;

  return (
    <>
      {session.isRecurring && (
        <TabPanelHead sticky>
          <TimeNavigator
            selectedDate={state.selectedDate}
            highlightDates={state.highlightDates}
            previousDate={state.previousDate}
            nextDate={state.nextDate}
            onChange={onChangeDate}
            path={`${rootPath}/tests`}
            minDate={session.startdate}
            maxDate={session.enddate}
          />
        </TabPanelHead>
      )}
      <Card editTestResult ref={rootRef}>
        <CardHeader secondary>
          <CardHeaderTitle>
            <FormattedMessage {...messages.titleTests} />
          </CardHeaderTitle>
          {Object.values(session.groupedTests).length > 0 && (
            <FormEditHeaderButtons
              edit={edit}
              editable={isEditable}
              setEdit={onSetEdit}
              cancelLabel={`finishButton`}
              saveLabel={`saveButton`}
              onSubmit={submitForm}
            />
          )}
        </CardHeader>

        <TestItemResultsList
          entityId={entityId}
          edit={edit}
          personInjury={personInjury}
        />

        {edit && (
          <FormEditFooterButtons
            edit={edit}
            setEdit={onSetEdit}
            cancelLabel={`finishButton`}
            saveLabel={`saveButton`}
            onSubmit={submitForm}
          />
        )}
        {hasResults() && !edit && (
          <div className="o-flex o-flex--justify-end u-margin-top-small">
            <Button
              primary
              onClick={() => {
                setTimeout(() => {
                  push(`${rootPath}/report/${testDataId}`);
                }, 500);
              }}
            >
              <FormattedMessage {...messages.seeReportButton} />
            </Button>
          </div>
        )}
      </Card>
    </>
  );
}

export default withRouter(EditTestResultCard);
