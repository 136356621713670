import React, { useState } from 'react';
import { useNotificationQueue } from 'components/notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import {
  MUTATION_ADD_NOTE,
  MUTATION_EDIT_NOTE
} from 'services/aws/notes-query';
import { NoteTypes } from 'constants.js';
import { QUERY_GET_EXERCISE_NOTES } from 'services/aws/exercises-query';
import messages from 'messages';
import Textarea from 'components/input/Textarea';
import { ButtonsGroup } from 'components/button';
import Button from 'components/button/Button';
import useProgram from 'hooks/queries/useProgram';
import useTemplate from 'hooks/queries/useTemplate';
import Loader from 'components/loader/Loader';

const EditableExerciseNote = ({
  exercise,
  exerciseGroup,
  noteType,
  onEdit,
  ...props
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();
  const [note, setNote] = useState(props.note?.note);
  const [isLoading, setIsLoading] = useState(false);

  const { fetchProgram } = useProgram();
  const { fetchTemplate } = useTemplate();

  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const [editNote] = useMutation(MUTATION_EDIT_NOTE);

  const onSaveNoteHandler = async exercise => {
    const refetchQueries = [
      {
        query: QUERY_GET_EXERCISE_NOTES,
        variables:
          noteType === NoteTypes.PROGRAM
            ? { programId: exerciseGroup.id }
            : { templateId: exerciseGroup.id }
      }
    ];

    setIsLoading(true);

    if (props.note?.id) {
      const existingNote = { ...props.note };

      if (note === existingNote.note) {
        onEdit(false);
        return;
      }

      const { data } = await editNote({
        variables: {
          id: existingNote.id,
          linkId: exerciseGroup.id,
          linkId2: exercise.id,
          note,
          noteType: noteType || NoteTypes.PROGRAM
        },
        refetchQueries
      });
      if (data?.editNote) {
        notification.add(data.editNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
      }
    } else {
      const { data } = await addNote({
        variables: {
          linkId: exerciseGroup.id,
          linkId2: exercise.id,
          note: note,
          noteType: noteType || NoteTypes.PROGRAM
        }
      });
      if (data?.addNote) {
        notification.add(data.addNote.id, {
          message: intl.formatMessage(messages.noteSaved)
        });
      }
    }
    if (noteType === NoteTypes.PROGRAM) {
      await fetchProgram({
        variables: { id: exerciseGroup.id },
        fetchPolicy: 'network-only'
      });
    } else {
      await fetchTemplate({
        variables: { templateId: exerciseGroup.id },
        fetchPolicy: 'network-only'
      });
    }
    onEdit(false);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Textarea
        id={exercise.id}
        rows="2"
        value={note}
        resizeVertical
        onChange={e => setNote(e.target.value)}
      />
      <ButtonsGroup>
        <Button tiny onClick={() => onEdit(false)}>
          <FormattedMessage {...messages.buttonCancel} />
        </Button>
        <Button tiny onClick={() => onSaveNoteHandler(exercise)}>
          <FormattedMessage {...messages.buttonSubmit} />
        </Button>
      </ButtonsGroup>
    </>
  );
};

export default EditableExerciseNote;
