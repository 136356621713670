import gql from 'graphql-tag';

export const QUERY_GET_USAGE = gql`
  query getUsage($entityId: ID!, $startdate: AWSDate!, $enddate: AWSDate!) {
    getUsage(entityId: $entityId, startdate: $startdate, enddate: $enddate)
  }
`;

export const QUERY_GET_USAGE_OF_SUBORG = gql`
  query getUsageOfSubOrganisations(
    $entityId: ID!
    $startdate: AWSDate!
    $enddate: AWSDate!
  ) {
    getUsageOfSubOrganisations(
      entityId: $entityId
      startdate: $startdate
      enddate: $enddate
    )
  }
`;

export const QUERY_EXPORT_SUB_ORG_DATA = gql`
  query exportDataOfSubOrganisations(
    $entityId: ID!
    $startdate: AWSDate!
    $enddate: AWSDate!
    $testSetId: ID!
  ) {
    exportDataOfSubOrganisations(
      entityId: $entityId
      startdate: $startdate
      enddate: $enddate
      testSetId: $testSetId
    )
  }
`;

export const QUERY_COPY_DATA = gql`
  mutation copyData($sourceEntityId: ID!, $destinationEntityId: ID!) {
    copyData(
      sourceEntityId: $sourceEntityId
      destinationEntityId: $destinationEntityId
    )
  }
`;
