export const findStringInEntity = (entity, str) => {
  if (!entity) return false;

  const trimmedString = str.toLowerCase().trim();
  const entityName = entity.title.toLowerCase().trim();

  if (entityName.indexOf(trimmedString) !== -1) {
    return true;
  }
};

export const filterByString = (entities, search) => {
  if (!search) return entities;
  return entities.filter(e => findStringInEntity(e, search));
};

export const filterByTags = (entities, tags) => {
  if (!tags || tags.length === 0) return entities;
  return entities.filter(
    entity =>
      entity.tags &&
      tags.every(tag => entity.tags.some(et => et.label === tag.label))
  );
};

export const getFilteredEntities = (
  entities,
  search,
  tags,
  personId,
  entityId
) => {
  const entitiesWithTags = filterByTags(entities, tags);
  let filteredEntities = filterByString(entitiesWithTags, search);
  if (entityId)
    filteredEntities = filteredEntities.filter(e => e.entity?.id === entityId);
  if (personId)
    filteredEntities = filteredEntities.filter(e => e.person?.id === personId);

  return filteredEntities;
};

export const highlightSearchedText = (label, value, attr = {}) => {
  if (!value) {
    return <span {...attr}>{label}</span>;
  }

  const labelStrings = label.trim().split(' ');
  const searchStrings = value.trim().split(' ');
  labelStrings.forEach((word, index) => {
    const searchIndex = searchStrings.findIndex(searchWord =>
      word.toLowerCase().includes(searchWord.toLowerCase())
    );
    if (searchIndex !== -1) {
      labelStrings[index] = word.replace(
        new RegExp(searchStrings[searchIndex], 'gi'),
        `<mark class="c-list__item-text--higlight">$&</mark>`
      );
    }
  });

  return (
    <span
      {...attr}
      dangerouslySetInnerHTML={{ __html: labelStrings.join(' ') }}
    ></span>
  );
};
