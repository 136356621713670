import { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { downloadExerciseProgramTemplate } from 'services/aws/exercises-query';
import { capitalize } from 'utils/string';

const DownloadExerciseProgramTemplateButton = ({
  templateId,
  collectionType = 'Program',
  disabled = false
}) => {
  const intl = useIntl();
  const { uiState } = useContext(StoreContext);

  const downloadReport = async newWindow => {
    const openWindow = newWindow;
    try {
      uiState.increasePendingRequest();
      const url = await downloadExerciseProgramTemplate(templateId);

      if (url?.data?.downloadExerciseProgramTemplate) {
        const response = await pollIsValidUrl(
          url.data.downloadExerciseProgramTemplate,
          25,
          1000
        );

        if (response) {
          openWindow.location = url.data.downloadExerciseProgramTemplate;
          openWindow.focus();
          //   setTimeout(() => {
          //     openWindow.close();
          //   }, 500);
        }
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(
          messages[`exercise${capitalize(collectionType)}TitleDownloadFailed`]
        ),
        message: messages.exerciseProgramMessageDownloadFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  return (
    <Button
      menu
      disabled={disabled}
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadReport(newWindow);
      }}
    >
      <Icon id="download" />
      <FormattedMessage
        {...messages[`menuItemExerciseDownload${capitalize(collectionType)}`]}
      />
    </Button>
  );
};

export default DownloadExerciseProgramTemplateButton;
