import { useHistory } from 'react-router-dom';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import { MUTATION_REMOVE_PERSON_LOGIN } from 'services/aws/client-query';
import useFetchAndFilterPersonsPool from 'hooks/queries/useFetchAndFilterPersonsPool';
import usePerson from 'hooks/queries/usePerson';
import { useContext, useEffect, useRef } from 'react';
import { StoreContext } from 'index';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import useFetchAndFilterUsers from 'hooks/queries/useFetchAndFilterUsers';

const RemoveLoginButton = ({
  personId,
  isUser = false,
  entityId,
  isPerson = false,
  target,
  children,
  extraClassNames,
  redirect,
  title = null
}) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const { push } = useHistory();
  const timeout = useRef();
  const { fetchPool } = useFetchAndFilterPersonsPool({ entityId });
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });
  const { fetchUsers } = useFetchAndFilterUsers({ entityId });
  const group = useEntityContext(s => s.group);
  const { getPerson } = usePerson({ personId: personId });

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
      uiState.decreasePendingRequest();
    };
  }, []);

  return (
    <ConfirmMutationButton
      title={title}
      small
      light
      extraClassNames={extraClassNames}
      confirmTitle={messages.modalConfirmRemoveLoginTitle}
      confirmMessage={messages.modalConfirmRemoveLoginMessage}
      confirmAcceptLabel={messages.modalConfirmRemoveLoginAcceptButton}
      mutation={MUTATION_REMOVE_PERSON_LOGIN}
      variables={{ personId, entityId }}
      update={(cache, { data: { removeLogin } }) => {
        if (removeLogin) {
          uiState.increasePendingRequest();
          timeout.current = setTimeout(
            async () => {
              if (!isUser) {
                await fetchPool();
                if (group.id) {
                  await fetchEntities(group.id);
                }
              } else {
                await fetchUsers(entityId);
              }
              await getPerson();
              uiState.decreasePendingRequest();
              notification.add(`loginRemove_${personId}`, {
                message: intl.formatMessage(messages.messageUserLoginRemoved)
              });
              if (redirect) {
                push(redirect);
                return;
              }
              push(target);
            },
            isPerson ? 2000 : 0
          ); // Delay from the backend
        }
      }}
      onError={error => {
        notification.add(`loginRemove_error_${personId}`, {
          message: error.message
        });
      }}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveLoginButton;
