import classNames from 'classnames';
import { Button } from '../button';
import Icon from '../icon/Icon';

const PageHeader = props => {
  const { fixed, showSidebar, children } = props;

  return (
    <div
      className={classNames('c-page__header', {
        'c-page__header--fixed': fixed
      })}
    >
      <Button
        onClick={showSidebar}
        tiny
        extraClassNames="c-button--sidebar-trigger"
      >
        <Icon id="hamburger" />
      </Button>
      {children}
    </div>
  );
};

export default PageHeader;
