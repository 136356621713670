import { useContext } from 'react';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { StoreContext } from 'index';
import { ROUTE_USER_ENTITY_PROGRAMS } from 'routes/RouteList';
import { Panel, PanelHeader } from 'components/panel';
import PanelBody from 'components/panel/PanelBody';
import messages from 'messages';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleWrapper
} from 'components/panel/panel-title';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import ProgramsPanel from 'containers/pages/person/programs/ProgramsPanel';

const OverView = () => {
  const {
    authStore: { user, entity }
  } = useContext(StoreContext);
  const { entityId } = useParams();
  const { push } = useHistory();

  const active = useRouteMatch([ROUTE_USER_ENTITY_PROGRAMS]);

  const programsTab = () => {
    push(generatePath(ROUTE_USER_ENTITY_PROGRAMS, { entityId }));
  };

  return (
    <Panel active={active?.isExact}>
      <PanelHeader>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.programs} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage
              {...messages.programsSubtitle}
              values={{
                name: user.getEntityName(entity)
              }}
            />
          </PanelSubtitle>
        </PanelTitleWrapper>
      </PanelHeader>

      <PanelBody>
        <Tabs selectedIndex={0} onSelect={() => {}}>
          <TabList>
            <Tab onClick={programsTab}>
              <FormattedMessage {...messages.tabSporterPrograms} />
            </Tab>
          </TabList>
          <TabPanel>
            {/* Spacing and scrolling moved into List component  */}
            <ProgramsPanel entityId={entityId} />
          </TabPanel>
        </Tabs>
      </PanelBody>
    </Panel>
  );
};

export default OverView;
