import { Fragment, useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { PageHeader } from 'components/page-layout';
import PageTitle from 'components/page-layout/PageTitle';
import PageHeaderUser from 'components/page-layout/PageHeaderUser';
import Button from 'components/button/Button';
import {
  MenuWrapper,
  MenuHeader,
  MenuItemsGroupItem,
  MenuItemsGroup
} from 'components/menu';
import Icon from 'components/icon/Icon';
import UpdateUserLanguageButton from '../buttons/UpdateUserLanguageButton';
import { Card } from 'components/card';
import messages from 'messages';
import OrganisationTree from 'containers/partials/header/OrganisationTree';
import { StoreContext } from 'index';
import { EntityTypes } from 'constants.js';
import { useHistory } from 'react-router-dom';
import { ROUTE_SETTINGS } from 'routes/RouteList';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import slugify from 'slugify';

const Header = props => {
  const {
    authStore,
    authStore: {
      user,
      user: { rootEntityId },
      entity
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);
  const [hasTree, setHasTree] = useState(false);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    if (entity) {
      if (entity.subEntities.length > 0) {
        // TODO what if only parentID
        const filteredOrganisations = entity.subEntities.filter(
          entity => entity.type === EntityTypes.ORGANISATION
        );
        // TODO check not correct, only groups in tree, hardcoded for now
        // setHasTree(filteredOrganisations.length > 1);
        setHasTree(true);
      }
      // TODO hardcoded for now
      setHasTree(true);

      setLanguages(entity.possibleLanguages);
    }
  }, [entity]);

  const logout = () => {
    authStore.doLogout();
  };

  const clearCache = () => {
    authStore.clearCache();
  };

  const onClickOpenDrawer = props.onClickOpenDrawer;
  const toggleMenu = () => {
    return onClickOpenDrawer();
  };

  return (
    <PageHeader fixed showSidebar={() => toggleMenu()}>
      {authStore.user && (
        <Fragment>
          {entity && (
            <PageTitle
              location={entity.info && entity.info.location}
              logo={entity.info && entity.info.logo}
            >
              {hasTree ? (
                <MenuWrapper
                  organisation
                  asModal
                  /*onStateChange={e => {
                    if (e.isOpen) {
                      setActiveTreeId(null);
                    }
                  }}*/
                  trigger={
                    <Button menuHeader dataQa={`header-org-menu`}>
                      {entity.name}
                      <Icon id="dropdown-open" />
                    </Button>
                  }
                >
                  <Card organisations={hasTree}>
                    <OrganisationTree entityId={rootEntityId} entity={entity} />
                  </Card>
                </MenuWrapper>
              ) : (
                <span className="c-page__title-title">{entity.name}</span>
              )}
            </PageTitle>
          )}

          <PageHeaderUser>
            <MenuWrapper
              trigger={
                <Button menuHeader dataQa={'header-user-menu'}>
                  {authStore.user.firstname}
                  <Icon id="dropdown-open" />
                </Button>
              }
              header={
                <MenuHeader
                  title={authStore.user.firstname}
                  subTitle={authStore.user.email}
                />
              }
            >
              <MenuItemsGroup>
                {languages.map(sp => {
                  return (
                    <MenuItemsGroupItem
                      key={sp.key}
                      isActive={sp.key === user.getLanguage()}
                    >
                      <UpdateUserLanguageButton
                        baseLanguage={sp.key}
                        currentLanguage={user.getLanguage()}
                        label={sp.language}
                      />
                    </MenuItemsGroupItem>
                  );
                })}
              </MenuItemsGroup>
              {ability.can('manage', 'Account') && (
                <Button onClick={() => push(ROUTE_SETTINGS)} menu>
                  <Icon id="preferences" />
                  <FormattedMessage {...messages.headerAccount} />
                </Button>
              )}

              {process.env.REACT_APP_ERASE_CACHE_ENABLED === 'true' && (
                <Button onClick={() => clearCache()} menu>
                  Clear cache
                </Button>
              )}
              <Button onClick={() => logout()} menu>
                <Icon id="signout" />
                <FormattedMessage {...messages.headerSignOut} />
              </Button>
            </MenuWrapper>
          </PageHeaderUser>

          {/* LangSwitcher for Mobile */}
          <PageHeaderUser langMobile>
            <MenuWrapper
              trigger={
                <Button menuHeader dataQa={'header-mobile-lang-menu'}>
                  {user.getLanguage()}
                  <Icon id="dropdown-open" />
                </Button>
              }
            >
              <MenuItemsGroup>
                {languages.map(sp => (
                  <MenuItemsGroupItem
                    key={sp.key}
                    isActive={sp.key === user.getLanguage()}
                  >
                    <UpdateUserLanguageButton
                      baseLanguage={sp.key}
                      currentLanguage={user.getLanguage()}
                      label={sp.language}
                    />
                  </MenuItemsGroupItem>
                ))}
              </MenuItemsGroup>
            </MenuWrapper>
          </PageHeaderUser>
        </Fragment>
      )}
    </PageHeader>
  );
};

export default observer(Header);
