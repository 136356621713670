import ExerciseForm from './ExerciseForm';
import ExerciseFormInput from 'containers/pages/exercises/exercises/ExerciseFormInput';
import ExerciseViewCard from 'containers/pages/exercises/exercises/ExerciseViewCard';
import Card from 'components/card/Card';

const ExercisePanel = ({
  entityId,
  exercise,
  edit,
  editable,
  readOnly,
  setEdit,
  onCancel,
  onComplete
}) => {
  if (edit && editable) {
    return (
      <ExerciseForm
        entityId={entityId}
        exercise={exercise}
        onComplete={() => onComplete && onComplete()}
      >
        <ExerciseFormInput
          exercise={exercise}
          entityId={entityId}
          edit={edit}
          readOnly={readOnly}
          setEdit={setEdit}
          onCancel={onCancel}
        />
      </ExerciseForm>
    );
  }

  return (
    <Card secondary multiBody extraClassNames={'u-margin-bottom-medium'}>
      <ExerciseViewCard
        entityId={entityId}
        exercise={exercise}
        setEdit={setEdit}
        editable={editable}
      />
    </Card>
  );
};

export default ExercisePanel;
