import Panel from 'components/panel/Panel';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Modal from 'components/modal/Modal';
import { CardBody } from 'components/card';
import Icon from 'components/icon/Icon';
import { Button, ButtonsGroup } from 'components/button';
import { useHistory } from 'react-router-dom';

const NoAccessPanel = ({ entityId, emptyLabel, redirectRoute }) => {
  const { push } = useHistory();
  const onClickRedirect = () => {
    redirectRoute && push(redirectRoute);
  };

  return (
    <>
      <Panel emptyState emptyLabel={emptyLabel} />
      <Modal
        card
        autoHeight
        alert
        isOpen
        onClose={() => entityId && onClickRedirect(entityId)}
      >
        <CardBody benchmarks extraClassNames="u-margin-bottom-none">
          <Icon id="database-error" mediumIcon />

          <p>
            <FormattedMessage {...messages.errorNoAccessTitle} />
          </p>
          <p>
            <FormattedMessage {...messages.errorNoAccessMessage} />
          </p>

          {entityId && (
            <ButtonsGroup>
              <Button small primary onClick={() => onClickRedirect(entityId)}>
                <FormattedMessage {...messages.buttonBack} />
              </Button>
            </ButtonsGroup>
          )}
        </CardBody>
      </Modal>
    </>
  );
};

export default NoAccessPanel;
