import classNames from 'classnames';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper,
  ListItemStatus
} from 'components/list/list-item';
import { useUIDSeed } from 'react-uid';

const EntityListItem = ({
  uid,
  virtualRef,
  virtualRow,
  titleLabel,
  metaLabel,
  statusLabel,
  item,
  checkbox,
  active,
  clickable,
  onClick,
  onChange,
  meta,
  extraRow,
  visible,
  lastItem,
  done,
  inProgress,
  outfaded,
  disabled, // not selectable

  icon,
  mediumIcon,
  checked = false,
  indeterminate,
  iconStrokeColor,
  supertitle
}) => {
  const uidSeed = useUIDSeed();

  const hasExtraRow = typeof extraRow() === 'object';
  return (
    <ListItem
      withSubList={extraRow()}
      ref={virtualRef}
      data-index={item.index}
      active={active || hasExtraRow}
      checkbox={checkbox}
      onClick={onClick}
      clickable={clickable}
      disabled={disabled}
      outfaded={outfaded}
      group={icon === 'group'}
      done={done}
      inProgress={inProgress}
      style={
        virtualRow && {
          position: 'absolute',
          top: 0,
          left: 0,
          height: extraRow() ? 'auto' : 48,
          maxHeight: extraRow() ? 148 : 56,
          transform: `translateY(${virtualRow.start + virtualRow.index * 8}px)`
        }
      }
      extraClassNames={classNames({ 'u-margin-bottom-small': lastItem })}
    >
      {icon !== null && (
        <ListItemStatus
          itemId={uid}
          visible={checked || visible || indeterminate}
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={onChange}
          mediumIcon={mediumIcon}
          statusIcon={icon && !statusLabel}
          strokeColor={iconStrokeColor && iconStrokeColor}
        >
          {statusLabel && statusLabel}
        </ListItemStatus>
      )}
      <ListItemLabelWrapper>
        <ListItemLabel labelNotePreview supertitle={supertitle}>
          <span
            className="c-list__item-title c-list__item-title-ellipsed"
            title={item[titleLabel]}
          >
            {item[titleLabel]}
          </span>
        </ListItemLabel>
        {meta &&
          meta({
            ...meta.props,
            key: uidSeed(meta),
            metaLabel
          })}
      </ListItemLabelWrapper>
      {extraRow && extraRow()}
    </ListItem>
  );
};

export default EntityListItem;
