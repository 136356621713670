import { TableCell } from 'components/table';
import ProgressBarGroup from 'components/progress-bar/ProgressBarGroup';
import ProgressBarLabelBlock from 'components/progress-bar/ProgressBarLabelBlock';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { getProgressBarColorByPercentile } from 'containers/pages/benchmarks/utils/benchmarkUtil';
import { uid } from 'react-uid';
import { Lateralities, Laterality } from 'constants.js';
import { isNumber } from 'utils/math';
import messages from 'messages';
import { useIntl } from 'react-intl';
import ProgressBarLabelSide from 'components/progress-bar/ProgressBarLabelSide';
import { useContext } from 'react';
import { StoreContext } from 'index';

const BenchmarkResultsTableCell = ({ testItem, onClick, laterality }) => {
  const intl = useIntl();
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  //const val = data.benchmarked ? data.benchmarked : data.values;

  if (!testItem?.id) return <TableCell />;
  if (!testItem?.benchmarked) return <TableCell />;

  return (
    <TableCell onClick={() => onClick(testItem)}>
      <div className="o-flex">
        {Object.keys(testItem.benchmarked).map(side => {
          // Only show 1 side
          if (side !== laterality) return null;

          // Copy from TestItemResultRow.js
          const benchmarkedPercentage =
            testItem?.benchmarked?.[side]?.percentage;
          let percentile =
            isNumber(benchmarkedPercentage) && benchmarkedPercentage >= 0
              ? testItem.benchmarked[side].percentage
              : null;
          percentile =
            isNumber(percentile) && percentile >= 0 && percentile <= 1
              ? Math.round(percentile * 100)
              : percentile;

          let labelValue;

          if (testItem.isOption) {
            if (testItem.result?.[side]?.[0]?.labels?.[locale]) {
              labelValue = testItem.result?.[side]?.[0]?.labels[locale];
            } else {
              labelValue = testItem.result?.[side]?.[0]?.label ?? '';
            }
          }
          if (!testItem.isOption && testItem?.result?.[side]?.[0]) {
            labelValue = intl.formatMessage(messages.labelUnit, {
              count: testItem.result[side][0],
              unit: testItem.unit
            });
          }

          return (
            <div
              className="c-table__content"
              key={uid(`${testItem.id}_${side}`)}
            >
              <ProgressBarGroup fullWidth clickable>
                {/* Don't show the Side label when Unilateral */}
                {Number(side) !== Laterality.UNILATERAL &&
                  Lateralities[side] &&
                  labelValue && (
                    <ProgressBarLabelBlock>
                      <ProgressBarLabelSide>
                        {Lateralities[side]}
                      </ProgressBarLabelSide>
                    </ProgressBarLabelBlock>
                  )}

                <ProgressBarLabelBlock value={labelValue} />

                {percentile !== null && (
                  <ProgressBar
                    showPercentage
                    benchmarksWithFrame
                    range={getProgressBarColorByPercentile(percentile)}
                    value={`${percentile}`}
                  >
                    {`${percentile} %`}
                  </ProgressBar>
                )}
              </ProgressBarGroup>
            </div>
          );
        })}
      </div>
    </TableCell>
  );
};

export default BenchmarkResultsTableCell;
