import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import AssignSportersToProgramModal from 'containers/pages/exercises/programs/AssignSportersToProgramModal';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import EntityList from 'containers/pages/persons/EntityList';
import { ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK } from 'routes/RouteList';
import { useStore } from 'stores/Store';
import {
  useAddActionButton,
  useSetMenuItems
} from 'contexts/ContextMenuProvider';
import RemovePersonsFromProgramButton from 'containers/partials/buttons/RemovePersonsFromProgramButton';
import AddButton from 'containers/partials/buttons/AddButton';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';

function ProgramPersonsTab() {
  const { push } = useHistory();
  const { entityId, personId } = useParams();
  const entityStore = useRef(createEntityStore()).current;

  const [modalData, setModalData] = useState(null);
  const setMenuItems = useSetMenuItems();
  const addActionButton = useAddActionButton();

  const program = useStore(state => state.program);
  const selectedProgramPersons = useStore(
    state => state.selectedProgramPersons
  );
  const selectProgramPersons = useStore(state => state.selectProgramPersons);
  const setSelectedProgramPersons = useStore(
    state => state.setSelectedProgramPersons
  );

  useEffect(() => {
    const contextButtons = [
      <Button menu onClick={() => setModalData(true)}>
        <Icon id="add" />
        <FormattedMessage {...messages.buttonAddUser} />
      </Button>,
      <RemovePersonsFromProgramButton
        menu
        entityId={entityId}
        program={program}
      />
    ];
    setMenuItems(contextButtons);

    addActionButton(
      <AddButton
        expandable
        onClick={() => setModalData(true)}
        label={<FormattedMessage {...messages.buttonAddUser} />}
      />
    );

    return () => {
      setMenuItems([]);
      addActionButton(null);
    };
  }, []);

  useEffect(() => {
    setSelectedProgramPersons([]);
  }, [program]);

  const onPersonClickHandler = person => {
    if (personId !== person.id)
      push(
        generatePath(
          ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK,
          {
            entityId,
            programId: program.id,
            personId: person.id
          }
        )
      );
  };
  const onSelectPersonsHandler = person => {
    selectProgramPersons(person);
  };

  return (
    <>
      <div
        className="o-flex o-flex--column u-padding-vertical-small"
        style={{
          flex: 1
        }}
      >
        <EntityList
          activeId={personId}
          variableHeight
          entityId={entityId}
          items={program.persons}
          selectedItems={selectedProgramPersons}
          checkable
          onClick={onPersonClickHandler}
          onSelect={onSelectPersonsHandler}
          emptyMessage={messages.labelSessionNoSporters}
          extraClassNames="c-list--medium-negative-margin"
        />
      </div>
      {modalData && (
        <EntityStoreProvider value={entityStore}>
          <AssignSportersToProgramModal
            entityId={entityId}
            program={program}
            onClose={() => setModalData(null)}
            onDone={() => setModalData(null)}
          />
        </EntityStoreProvider>
      )}
    </>
  );
}

export default ProgramPersonsTab;
