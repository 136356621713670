import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import {
  withRouter,
  Route,
  Switch,
  generatePath,
  Redirect
} from 'react-router-dom';
import Overview from './Overview';

import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
  ROUTE_SPORTERS_ENTITY_SPORTER_TRAININGS,
  ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
  ROUTE_SPORTERS_ENTITY_SPORTER_DOCS,
  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
  ROUTE_SPORTERS
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import Person from 'containers/pages/persons/Person';
import PersonSession from 'containers/pages/persons/PersonSession';
import messages from 'messages';
import Injury from 'containers/pages/rehab/injury/Injury';
import Screening from 'containers/pages/rehab/screening/Screening';
import { StoreContext } from 'index';
import ScheduleReTestScreening from 'containers/pages/rehab/screening/ScheduleReTestScreening';
import { MedicalContextProvider } from 'contexts/MedicalContext';
import { SessionContextProvider } from 'contexts/SessionContext';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import { EditableContextProvider } from 'contexts/EditableContext';
import PersonProgram from 'containers/pages/persons/PersonProgram';
import PersonProgramExercise from 'containers/pages/persons/PersonProgramExercise';
import { MenuContextProvider } from 'contexts/MenuContext';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import Illness from 'containers/pages/rehab/illness/Illness';
import Issue from 'containers/pages/rehab/issue/Issue';
import { TestDataProvider } from 'contexts/TestDataContext';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';

const Persons = () => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const intl = useIntl();

  const entityStore = useRef(createEntityStore()).current;

  if (ability.cannot('read', 'Athletes')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SESSIONS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <EntityStoreProvider value={entityStore}>
      <MedicalContextProvider rootEntityId={rootEntityId}>
        <EditableContextProvider>
          <Switch>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
                ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
                ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
                ROUTE_SPORTERS_ENTITY_SPORTER_TRAININGS,
                ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
                ROUTE_SPORTERS_ENTITY_SPORTER_DOCS,
                ROUTE_SPORTERS_ENTITY_SPORTER,
                ROUTE_SPORTERS_ENTITY
              ]}
            >
              <MenuContextProvider>
                <Overview />
              </MenuContextProvider>
            </Route>
            <Route
              path={ROUTE_SPORTERS}
              render={() => (
                <Redirect to={`${ROUTE_SPORTERS}/${user.rootEntityId}`} />
              )}
            />
          </Switch>
          <Route exact path={[ROUTE_SPORTERS_ENTITY]}>
            <Panel
              emptyState
              emptyLabel={intl.formatMessage(messages.panelSportersEmpty)}
            />
          </Route>
          <Switch>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT
              ]}
            >
              <Injury />
            </Route>

            <Route
              exact
              path={
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST
              }
            >
              <ScheduleReTestScreening />
            </Route>

            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
              ]}
              render={() => (
                <MenuContextProvider>
                  <Person />
                </MenuContextProvider>
              )}
            />
          </Switch>
          <Switch>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS
              ]}
            >
              <Injury />
            </Route>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT
              ]}
            >
              <Illness />
            </Route>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT
              ]}
            >
              <Issue />
            </Route>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT
              ]}
            >
              <PreviewModalProvider entityId={user.rootEntityId}>
                <SessionContextProvider>
                  <TestDataProvider>
                    <Screening />
                  </TestDataProvider>
                </SessionContextProvider>
              </PreviewModalProvider>
            </Route>
            <Route
              exact
              path={
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
              }
            >
              <MenuContextProvider>
                <PersonProgram />
              </MenuContextProvider>
            </Route>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER,
                ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
                ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
                ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
                ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
                ROUTE_SPORTERS_ENTITY_SPORTER_TRAININGS,
                ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
                ROUTE_SPORTERS_ENTITY_SPORTER_DOCS
              ]}
            >
              <MenuContextProvider>
                <SessionContextProvider>
                  <Person />
                </SessionContextProvider>
              </MenuContextProvider>
            </Route>
          </Switch>

          <Switch>
            <Route
              exact
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT,
                ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA
              ]}
            >
              <SessionContextProvider>
                <PreviewModalProvider entityId={user.rootEntityId}>
                  <TestDataProvider>
                    <PersonSession tab={'tests'} />
                  </TestDataProvider>
                </PreviewModalProvider>
              </SessionContextProvider>
            </Route>
            <Route
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
              ]}
              exact
            >
              <MenuContextProvider>
                <PersonProgram />
              </MenuContextProvider>
            </Route>
            <Route
              path={[
                ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE
              ]}
              exact
            >
              <MenuContextProvider>
                <PersonProgramExercise />
              </MenuContextProvider>
            </Route>
            {/*<Redirect
              to={ROUTE_SPORTERS_ENTITY.replace(':entityId', user.rootEntityId)}
            />*/}
          </Switch>
          {/*<Redirect to={`${ROUTE_SPORTERS}/${user.rootEntityId}`} />*/}
        </EditableContextProvider>
      </MedicalContextProvider>
    </EntityStoreProvider>
  );
};

export default withRouter(observer(Persons));
