import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages.js';
import { FormattedMessage } from 'react-intl';
import {
  MUTATION_ARCHIVE_ANNOTATION,
  QUERY_GET_ANNOTATIONS
} from 'services/aws/annotations-query';
import { QUERY_GET_LESSONS } from 'services/aws/lessons-query';

const RemoveAnnotationButton = ({
  entityId,
  annotationId,
  linkId,
  children,
  onComplete,
  tiny,
  extraClassNames,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      tiny={tiny}
      extraClassNames={extraClassNames}
      {...props}
      mutation={MUTATION_ARCHIVE_ANNOTATION}
      confirmTitle={messages.modalConfirmRemoveAnnotationTitle}
      confirmMessage={messages.modalConfirmRemoveAnnotationMessage}
      variables={{
        annotationId
      }}
      refetchQueries={[
        {
          query: QUERY_GET_ANNOTATIONS,
          variables: { linkId }
        },
        {
          query: QUERY_GET_LESSONS,
          variables: { entityId }
        }
      ]}
      update={(cache, { data: { archiveAnnotation } }) => {
        if (archiveAnnotation) {
          onComplete && onComplete();
        }
      }}
    >
      <Icon id="delete" />
      {tiny ? (
        <div className="u-hidden-visually">
          <FormattedMessage {...messages.annotationsRemoveAnnotationButton} />
        </div>
      ) : (
        <FormattedMessage {...messages.annotationsRemoveAnnotationButton} />
      )}
    </ConfirmMutationButton>
  );
};

export default RemoveAnnotationButton;
