import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import Panel from 'components/panel/Panel';
import messages from 'messages';
import { PanelBody, PanelHeader, PanelTitleButton } from 'components/panel';
import {
  PanelTitle,
  PanelTitleWrapper,
  PanelSubtitle
} from 'components/panel/panel-title';
import {
  ROUTE_EXERCISES_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT,
  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS
} from 'routes/RouteList';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tabs, TabPanelBody } from 'components/tabs';
import { TabList, TabPanel } from 'react-tabs';
import Loader from 'components/loader/Loader';
import { MenuWrapper } from 'components/menu';
import useProgram from 'hooks/queries/useProgram';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { useStore } from 'stores/Store';
import useTabIndex from 'hooks/utils/useTabIndex';
import TabLink from 'components/tabs/TabLink';
import ProgramExercisesTab from 'containers/pages/exercises/programs/ProgramExercisesTab';
import ProgramDetailsTab from 'containers/pages/exercises/programs/ProgramDetailsTab';
import { getLocalDateFormat } from 'utils/date';
import RemoveProgramButton from 'containers/partials/buttons/RemoveProgramButton';
import { StoreContext } from 'index';
import DownloadExerciseProgramButton from 'containers/partials/buttons/DownloadExerciseProgramButton';
import AddTemplateModal from 'containers/pages/exercises/templates/AddTemplateModal';
import AddExercisesModal from 'containers/pages/exercises/exercises/AddExercisesModal';
import ProgramForm from 'containers/pages/exercises/programs/ProgramForm';
import ProgramPersonsTab from 'containers/pages/exercises/programs/ProgramPersonsTab';
import ContextMenu from 'components/menu/ContextMenu';
import PanelTitleButtonsGroup from 'components/panel/panel-title/PanelTitleButtonsGroup';
import ActionButton from 'components/panel/panel-title/ActionButton';
import useScrollToTop from 'hooks/utils/useScrollToTop';

const Program = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { entityId, programId } = useParams();

  const [templateModalData, setTemplateModalData] = useState(null);
  const { fetchProgram, error, loading } = useProgram();
  const program = useStore(state => state.program);

  const [tabIndex, setTabIndex] = useTabIndex(
    [
      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM,

      ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS,
      [
        ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS,
        ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT
      ]
    ],
    false
  );
  const active = useRouteMatch([
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT,
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS,
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS,
    ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM
  ]);
  const [addExercisesModal, setAddExercisesModal] = useState(null);

  useScrollToTop(0);

  useEffect(() => {
    if (programId) {
      fetchProgram({ variables: { id: programId } });
    }
  }, [programId]);

  const onUseHandler = program => {
    // show use program modal
    setTemplateModalData({
      entityId,
      title: '',
      exerciseIds: program.exercises.map(e => e.id),
      exercises: program.exercises,
      tags: []
    });
  };

  const onAddedHandler = () => {
    setAddExercisesModal(false);
  };

  if (!program || loading)
    return (
      <Panel active={!!active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <>
      <Panel active={active.isExact}>
        <PanelHeader>
          <Breadcrumbs>
            <Breadcrumb
              to={ROUTE_EXERCISES_ENTITY.replace(':entityId', entityId)}
            >
              <FormattedMessage {...messages.breadcrumbOverview} />
            </Breadcrumb>
            <Breadcrumb
              to={ROUTE_EXERCISES_PROGRAMS_ENTITY.replace(
                ':entityId',
                entityId
              )}
            >
              <FormattedMessage {...messages.breadcrumbPrograms} />
            </Breadcrumb>
          </Breadcrumbs>
          <PanelTitleWrapper>
            <PanelTitle>
              {program.title}
              <PanelTitleButtonsGroup>
                <ActionButton />
                <MenuWrapper trigger={<PanelTitleButton />}>
                  <ContextMenu />
                  <DownloadExerciseProgramButton programId={program.id} />
                  {user.canRemoveExercises(entityId) && (
                    <RemoveProgramButton
                      menu
                      entityId={entityId}
                      programId={programId}
                    >
                      <FormattedMessage
                        {...messages.exercisesRemoveProgramButton}
                      />
                    </RemoveProgramButton>
                  )}
                </MenuWrapper>
              </PanelTitleButtonsGroup>
            </PanelTitle>
            <PanelSubtitle>
              <FormattedMessage
                {...messages.exercisesProgramSubTitle}
                values={{
                  startdate: getLocalDateFormat(program.startdate),
                  duration: program.duration
                }}
              />
            </PanelSubtitle>
          </PanelTitleWrapper>
        </PanelHeader>
        <PanelBody>
          <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
            <TabList>
              <TabLink
                to={generatePath(ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM, {
                  entityId,
                  programId
                })}
              >
                <FormattedMessage {...messages.exercisesTitle} />
              </TabLink>
              <TabLink
                to={generatePath(
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS,
                  {
                    entityId,
                    programId
                  }
                )}
              >
                <FormattedMessage {...messages.programPersons} />
              </TabLink>
              <TabLink
                to={generatePath(
                  ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS,
                  {
                    entityId,
                    programId
                  }
                )}
              >
                <FormattedMessage {...messages.details} />
              </TabLink>
            </TabList>
            <TabPanel>
              <TabPanelBody>
                <ProgramExercisesTab
                  onAdd={program => setAddExercisesModal(program)}
                  onUse={program => onUseHandler(program)}
                  editableNotes
                />
              </TabPanelBody>
            </TabPanel>
            <TabPanel>
              <TabPanelBody>
                <ProgramPersonsTab />
              </TabPanelBody>
            </TabPanel>
            <TabPanel>
              <TabPanelBody withScroller>
                <ProgramDetailsTab />
              </TabPanelBody>
            </TabPanel>
          </Tabs>
        </PanelBody>
        {templateModalData && (
          <AddTemplateModal
            entityId={entityId}
            template={templateModalData}
            onClose={() => setTemplateModalData(null)}
            fromProgram={true}
            program={program}
          />
        )}
      </Panel>
      {addExercisesModal && (
        <ProgramForm
          entityId={entityId}
          program={addExercisesModal}
          onComplete={() => setAddExercisesModal(false)}
        >
          {() => (
            <AddExercisesModal
              entityId={entityId}
              template={addExercisesModal}
              onClose={() => {
                setAddExercisesModal(false);
              }}
              onAdded={() => onAddedHandler()}
            />
          )}
        </ProgramForm>
      )}
    </>
  );
};

export default observer(Program);
