import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_TESTS } from 'services/aws/testsets-query';
import { TestSetV2Type } from 'constants.js';
import { useParams } from 'react-router-dom';

export const TestsContext = createContext();

const initialState = {
  tests: [],
  selectedTests: [],
  unselectedTests: [],
  extraTests: []
};

// Actions
export const SET_TESTS = 'SET_TESTS';
export const SET_ALL_TESTS = 'SET_ALL_TESTS';
export const SET_SELECTED_TESTS = 'SET_SELECTED_TESTS';
export const SET_UNSELECTED_TESTS = 'SET_UNSELECTED_TESTS';
export const SET_EXTRA_TESTS = 'SET_EXTRA_TESTS';

export const ADD_SELECTED_TESTS = 'ADD_SELECTED_TESTS';
export const REMOVE_SELECTED_TESTS = 'REMOVE_SELECTED_TESTS';
export const REMOVE_EXTRA_TEST = 'REMOVE_EXTRA_TEST';

const reducer = (state, action) => {
  let unDoubled = [];
  switch (action.type) {
    case SET_ALL_TESTS:
      return { ...state, allTests: [...action.payload] };
    case SET_TESTS:
      return { ...state, tests: [...action.payload] };
    case SET_SELECTED_TESTS:
      unDoubled = [];
      if (Array.isArray(action.payload)) {
        action.payload.forEach(test => {
          if (!unDoubled.find(t => t.id === test.id)) {
            unDoubled.push(test);
            if (test.dependedTestIds) {
              test.dependedTestIds.forEach(id => {
                const dependedTest =
                  state.allTests && state.allTests.find(at => at.id === id);
                if (dependedTest) {
                  if (
                    !unDoubled.find(t => t.id === dependedTest?.id) //&&
                    //!state.selectedTests.find(t => t.id === dependedTest?.id)
                  ) {
                    unDoubled.push({ ...dependedTest, isDependency: true });
                  }
                }
              });
            }
          }
        });
      }
      return { ...state, selectedTests: [...unDoubled] };
    case SET_UNSELECTED_TESTS:
      return { ...state, unselectedTests: [...action.payload] };
    case SET_EXTRA_TESTS:
      return { ...state, extraTests: [...action.payload] };

    case ADD_SELECTED_TESTS:
      const testsNotInSelected = action.payload.filter(
        test => !state.selectedTests.find(t => t.id === test.id)
      );
      unDoubled = [];
      testsNotInSelected.forEach(test => {
        if (!unDoubled.find(t => t.id === test.id)) {
          unDoubled.push(test);
          if (test.dependedTestIds) {
            test.dependedTestIds.forEach(id => {
              const dependedTest =
                state.allTests && state.allTests.find(at => at.id === id);
              if (dependedTest) {
                if (
                  !unDoubled.find(t => t.id === dependedTest?.id) &&
                  !state.selectedTests.find(t => t.id === dependedTest?.id)
                ) {
                  unDoubled.push({ ...dependedTest, isDependency: true });
                }
              }
            });
          }
        }
      });

      return {
        ...state,
        selectedTests: [...state.selectedTests, ...unDoubled]
      };
    case REMOVE_SELECTED_TESTS:
      const tests = state.selectedTests.filter(
        test => !action.payload.find(t => t.id === test.id)
      );
      return {
        ...state,
        selectedTests: [...tests]
      };
    case REMOVE_EXTRA_TEST:
      const remainingExtraTests = state.extraTests.filter(
        test => !action.payload.find(t => t.id === test.id)
      );
      return {
        ...state,
        extraTests: [...remainingExtraTests]
      };
    default:
      return state;
  }
};

const TestsProvider = ({ entityId: rootEntityId, children }) => {
  const { entityId } = useParams();
  const [testsState, dispatch] = useReducer(reducer, initialState);
  const [getTestsQuery, { loading, data }] = useLazyQuery(QUERY_GET_TESTS);

  useEffect(() => {
    if (data?.getTests) {
      dispatch({ type: SET_ALL_TESTS, payload: data.getTests });
    }
  }, [data]);

  useEffect(() => {
    if (testsState.tests.length > 0) {
      const unselectedTests = testsState.tests.filter(
        test => !testsState.selectedTests.find(t => t.id === test.id)
      );
      dispatch({ type: SET_UNSELECTED_TESTS, payload: unselectedTests });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testsState.selectedTests]);

  const testActions = {
    getTests: type => {
      let testType;
      switch (type) {
        case TestSetV2Type.TALENT:
        case TestSetV2Type.TALENT_TEMPLATE:
          testType = TestSetV2Type.TALENT;
          break;
        case TestSetV2Type.PREVENTION:
        case TestSetV2Type.PREVENTION_TEMPLATE:
          testType = TestSetV2Type.PREVENTION;
          break;
        case TestSetV2Type.REHAB:
        case TestSetV2Type.REHAB_TEMPLATE:
          testType = TestSetV2Type.REHAB;
          break;
        case TestSetV2Type.ANNOTATION:
          testType = TestSetV2Type.ANNOTATION;
          break;
        default:
          testType = null;
      }

      getTestsQuery({
        variables: { type: testType, entityId: entityId ?? rootEntityId }
      });
    },
    setTests: tests => {
      if (tests) {
        dispatch({ type: SET_TESTS, payload: tests });

        const extraTests = testsState.selectedTests.filter(
          test => !tests.find(t => t.id === test.id)
        );
        dispatch({ type: SET_EXTRA_TESTS, payload: extraTests });
        if (testsState.selectedTests.length > 0) {
          const unselectedTests = tests.filter(
            test => !testsState.selectedTests.find(t => t.id === test.id)
          );
          dispatch({ type: SET_UNSELECTED_TESTS, payload: unselectedTests });
        } else {
          dispatch({ type: SET_SELECTED_TESTS, payload: tests });
        }
      } else {
        dispatch({ type: SET_SELECTED_TESTS, payload: [] });
      }
    },
    // Update the tests with new test data
    updateTests: tests => {
      if (tests) {
        dispatch({ type: SET_TESTS, payload: tests });

        const unselectedTests = tests.filter(test =>
          testsState.unselectedTests.find(t => t.id === test.id)
        );
        dispatch({ type: SET_UNSELECTED_TESTS, payload: unselectedTests });

        const selectedTests = tests.filter(
          test => !testsState.unselectedTests.find(t => t.id === test.id)
        );

        dispatch({
          type: SET_SELECTED_TESTS,
          payload: [...selectedTests, ...testsState.extraTests]
        });
      } else {
        dispatch({ type: SET_SELECTED_TESTS, payload: [] });
      }
    },
    setSelectedTests: tests => {
      if (tests) {
        const unselectedTests = testsState.tests.filter(
          test => !tests.find(t => t.id === test.id)
        );
        // check which tests are in the unselected tests array and only add the others to the selected
        dispatch({ type: SET_UNSELECTED_TESTS, payload: unselectedTests });
      }
      dispatch({ type: SET_SELECTED_TESTS, payload: tests });
    },
    selectTests: tests => {
      if (tests) {
        dispatch({ type: ADD_SELECTED_TESTS, payload: tests });
      }
    },
    unselectTests: tests => {
      if (tests) {
        dispatch({ type: REMOVE_SELECTED_TESTS, payload: tests });
        // check if it's not an extra test
        dispatch({ type: REMOVE_EXTRA_TEST, payload: tests });
      }
    },
    addExtraTest: tests => {
      if (tests) {
        const removedExtraTests = testsState.extraTests.filter(
          test => !tests.find(t => t.id === test.id)
        );
        dispatch({ type: SET_EXTRA_TESTS, payload: tests });
        dispatch({ type: ADD_SELECTED_TESTS, payload: tests });
        dispatch({ type: REMOVE_SELECTED_TESTS, payload: removedExtraTests });
      }
    }
  };

  return (
    <TestsContext.Provider
      value={{
        loading,
        testsState,
        testActions
      }}
    >
      {children}
    </TestsContext.Provider>
  );
};

function useTestsContext() {
  const context = useContext(TestsContext);
  if (context === undefined) {
    throw new Error(
      'The TestsContext hook must be used within a TestsContext.Provider'
    );
  }
  return context;
}

export { TestsProvider, useTestsContext };
