import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Button, ButtonsGroup } from 'components/button';
import { CardBody, CardFooter, CardBodyTools } from 'components/card';

import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import Entity from 'models/Entity';
import { sortEntities } from 'utils/sort';
import {
  ListItem,
  ListItemStatus,
  ListItemLabelWrapper,
  ListItemLabel
} from 'components/list/list-item';
import Icon from 'components/icon/Icon';
import { QUERY_GET_GROUPS_BY_ENTITY_ID } from 'services/aws/client-query';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import { useQuery } from '@apollo/client';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import classNames from 'classnames';

const SelectGroupModal = ({ onCancelHandler, onSubmitHandler, ...props }) => {
  const { authStore } = useContext(StoreContext);

  const [entity, setEntity] = useState({});
  const [entityId, setEntityId] = useState(props.entityId);
  const [entities, setEntities] = useState([]);
  const [activeEntityId, setActiveEntityId] = useState(null);
  const [activeName, setActiveName] = useState();

  const { loading, error, data } = useQuery(QUERY_GET_GROUPS_BY_ENTITY_ID, {
    variables: { id: entityId },
    skip: !entityId
  });

  useEffect(() => {
    if (data?.getEntity) {
      const newEntity = new Entity(data.getEntity);
      setEntity(newEntity);
      setEntities(sortEntities([...newEntity.subEntities]));

      /*const filteredEntities = newEntity.subEntities.filter(
        se => se.type !== EntityTypes.ORGANISATION
      );
      const sortedEntities = sortEntities(filteredEntities);*/
    }
  }, [data]);

  const onBackHandler = () => {
    setEntityId(entity.parentId);
  };

  const onGroupClickedHandler = (id, name) => {
    setActiveEntityId(id);
    setActiveName(name);
  };

  const onGroupDoubleClickedHandler = id => {
    setEntityId(id);
    setActiveEntityId(null);
    setActiveName(null);
  };

  return (
    <>
      <CardHeader modal>
        <CardHeaderTitle>
          <FormattedMessage {...messages.benchmarksSubChoiceModalTitle} />
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          <FormattedMessage
            {...messages.benchmarksSubGroupChoiceModalSubtitle}
          />
        </CardHeaderSubtitle>
      </CardHeader>
      <CardBody
        modal
        bodyWithHeader
        extraClassNames={'u-padding-vertical-none u-padding-horizontal-small'}
      >
        {entityId !== authStore.user.rootEntityId && (
          <div
            className={classNames('c-card-modal__body-head', {
              // 'u-padding-bottom-none': filter.tags?.length > 0 || filter?.myOrg
            })}
          >
            <CardBodyTools style={{ flexWrap: 'wrap' }}>
              {/* Add a search field. And why the if? */}
              <Button rounded light onClick={e => onBackHandler(e)}>
                <Icon id="back-nav" />
                <FormattedMessage {...messages.benchmarksBackButton} />
              </Button>
            </CardBodyTools>
          </div>
        )}
        <List onModal>
          <ListBody>
            {error && <RetryPanel />}
            {loading && <Loader />}
            {entities.map(group => {
              return (
                <GroupListItem
                  id={group.id}
                  key={group.id}
                  group={group}
                  active={activeEntityId === group.id}
                  onDoubleClick={
                    group.subEntities && group.subEntities.length > 0
                      ? () => onGroupDoubleClickedHandler(group.id)
                      : () => false
                  }
                  onClick={() => onGroupClickedHandler(group.id, group.name)}
                />
              );
            })}
          </ListBody>
        </List>
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button secondary onClick={() => onCancelHandler()}>
            <FormattedMessage {...messages.benchmarksCancelButton} />
          </Button>
          <Button
            disabled={activeEntityId === null}
            primary
            onClick={() => onSubmitHandler(activeEntityId, null, activeName)}
          >
            <FormattedMessage {...messages.benchmarksViewButton} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </>
  );
};

export default SelectGroupModal;

const GroupListItem = ({
  group: { id, name, clients, subEntities },
  active,
  onClick,
  onDoubleClick
}) => (
  <ListItem
    group
    active={active}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <ListItemStatus itemId={id} statusIcon="group" />
    <ListItemLabelWrapper>
      <ListItemLabel label>{name}</ListItemLabel>
      <ListItemLabel info>
        <FormattedMessage
          {...messages.modalListItemSportersGroupsCount}
          values={{
            athletes: (clients && clients.length) || 0,
            groups: subEntities.length
          }}
        />
      </ListItemLabel>
    </ListItemLabelWrapper>
  </ListItem>
);
