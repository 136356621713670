import React from 'react';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { MUTATION_ARCHIVE_TEMPLATE } from 'services/aws/templates-query';
import { TemplateType } from 'constants.js';
import { capitalize } from 'utils/string';

const RemoveExerciseTemplateButton = ({
  entityId,
  templateId,
  collectionType = TemplateType.TEMPLATE,
  children,
  onDeleted,
  ...props
}) => {
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_ARCHIVE_TEMPLATE}
      confirmTitle={
        messages[`modalConfirmRemove${capitalize(collectionType)}Title`]
      }
      confirmMessage={
        messages[`modalConfirmRemove${capitalize(collectionType)}Message`]
      }
      variables={{
        templateId
      }}
      update={async (cache, { data: { archiveExerciseProgramTemplate } }) => {
        if (archiveExerciseProgramTemplate) {
          onDeleted && onDeleted(templateId);
        }
      }}
    >
      <Icon id="delete" strokeColor="color-neutral-dark" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveExerciseTemplateButton;
