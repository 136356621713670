import { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  generatePath,
  withRouter
} from 'react-router-dom';
import {
  ROUTE_EXERCISES_TRAININGS,
  ROUTE_EXERCISES_TRAININGS_ENTITY,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS_EDIT,
  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_EDIT
} from 'routes/RouteList';
import { StoreContext } from 'index';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import messages from 'messages';
import Panel from 'components/panel/Panel';
import { useIntl } from 'react-intl';
import Trainings from 'containers/pages/exercises/trainings/Trainings';
import Exercise from 'containers/pages/exercises/exercises/Exercise';
import Training from 'containers/pages/exercises/trainings/Training';

const GSVTrainings = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();

  return (
    <>
      <PreviewModalProvider entityId={user.rootEntityId}>
        <Switch>
          <Route
            path={[
              ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS,
              ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
              ROUTE_EXERCISES_TRAININGS_ENTITY
            ]}
          >
            <Trainings />
            <Switch>
              <Route
                path={[
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
                  ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING
                ]}
              >
                <Training
                  crumbs={[
                    {
                      path: ROUTE_EXERCISES_TRAININGS_ENTITY,
                      label: intl.formatMessage(messages.breadcrumbTrainings)
                    }
                  ]}
                />
                <Switch>
                  <Route
                    exact
                    path={[
                      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS_EDIT,
                      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS,
                      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_EDIT,
                      ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE
                    ]}
                  >
                    <Exercise
                      inTraining
                      parentPath={
                        ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE
                      }
                      crumbs={[
                        {
                          path: ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES,
                          label: intl.formatMessage(
                            messages.breadcrumbTrainings
                          )
                        },
                        {
                          path: ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE,
                          label: intl.formatMessage(messages.breadcrumbExercise)
                        }
                      ]}
                    />
                  </Route>
                </Switch>
              </Route>
              <Route path={ROUTE_EXERCISES_TRAININGS_ENTITY}>
                <Panel
                  emptyState
                  emptyLabel={intl.formatMessage(messages.templateEmptyPanel)}
                />
              </Route>
            </Switch>
          </Route>

          <Route
            path={ROUTE_EXERCISES_TRAININGS}
            exact
            render={() => (
              <Redirect
                to={generatePath(ROUTE_EXERCISES_TRAININGS_ENTITY, {
                  entityId: user.rootEntityId
                })}
              />
            )}
          />
        </Switch>
      </PreviewModalProvider>
    </>
  );
};

export default withRouter(GSVTrainings);
