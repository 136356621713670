import React, { useEffect } from 'react';
import SortableList from 'components/dndkit/SortableList';
import ExercisesListSortable from 'containers/partials/lists/ExercisesListSortable';
import { useParams } from 'react-router-dom';
import { Button, ButtonsGroup } from 'components/button';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { useFormikContext } from 'formik';
import { useStore } from 'stores/Store';
import useSelectableObject from 'hooks/utils/useSelectableObject';
import { NoteTypes } from 'constants.js';
import { isMzhUserAgent } from 'utils/browser';

const ProgramExercisesListSortable = ({
  exercises,
  selectedExercises,
  edit,
  editableNotes,
  onPreview,
  onClick,
  onCancel,
  onSelectedExercisesChange,
  checkable = false
}) => {
  const { exerciseId } = useParams();
  const programSortableActive = useStore(state => state.programSortableActive);
  const setProgramSortableActive = useStore(
    state => state.setProgramSortableActive
  );

  const { selected, select, setSelected } = useSelectableObject();

  const {
    submitForm,
    values: exerciseGroup,
    resetForm,
    setFieldValue
  } = useFormikContext();

  useEffect(() => {
    return () => {
      setProgramSortableActive(false);
    };
  }, []);

  useEffect(() => {
    if (selectedExercises) {
      setSelected([...selectedExercises]);
    }
  }, [selectedExercises]);

  const submitExercisesSorting = async () => {
    await submitForm();
    setProgramSortableActive(false);
  };

  const updateSortOrder = async exercises => {
    await setFieldValue('exerciseIds', [...exercises]);
  };

  const onCancelHandler = () => {
    resetForm();
    setProgramSortableActive(false);

    onCancel && onCancel();
  };

  if (exercises.length === 0) {
    return null;
  }

  return (
    <>
      <SortableList
        items={exercises}
        onSort={exercises => updateSortOrder(exercises)}
      >
        {({ items }) => (
          <ExercisesListSortable
            checkable={checkable}
            exercises={items}
            exerciseGroup={exerciseGroup}
            activeId={exerciseId}
            draggable={programSortableActive}
            enablePreviewModal={false}
            editableNotes={editableNotes}
            showProgramCount={false}
            showNote
            onPreview={onPreview}
            onClick={onClick}
            onSelect={exercise => {
              if (!edit) return;
              select(exercise);
              onSelectedExercisesChange(exercise);
            }}
            selectedExercises={selected}
            noteType={NoteTypes.PROGRAM}
          />
        )}
      </SortableList>
      {programSortableActive && (
        <ButtonsGroup
          extraClassNames="u-margin-left-auto u-margin-top-medium"
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            background: 'white',
            padding: '16px 32px',
            boxShadow: '0 -5px 5px -8px #ccc',
            justifyContent: 'flex-end'
          }}
        >
          <Button secondary onClick={() => onCancelHandler()}>
            <FormattedMessage {...messages.cancelButton} />
          </Button>
          <Button
            primary
            /*disabled={loading} //|| processing*/
            // onClick={() => submitExercisesSorting()}
            onClick={() => submitExercisesSorting()}
          >
            <FormattedMessage {...messages.saveSortButton} />
          </Button>
        </ButtonsGroup>
      )}
    </>
  );
};

export default ProgramExercisesListSortable;
