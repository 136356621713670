import React, { useEffect, useContext } from 'react';
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { StoreContext } from 'index';
import { FormattedMessage } from 'react-intl';
import { useSessionContext } from 'contexts/SessionContext';
import Panel from 'components/panel/Panel';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import PanelHeader from 'components/panel/PanelHeader';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle,
  PanelTitleButton,
  PanelTitleButtonsGroup
} from 'components/panel/panel-title';
import PanelBody from 'components/panel/PanelBody';
import { getLocalDateFormat } from 'utils/date';
import {
  ROUTE_USER_SESSIONS_ENTITY,
  ROUTE_USER_SESSIONS_ENTITY_SESSION,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA,
  ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT
} from 'routes/RouteList';
import MenuWrapper from 'components/menu/MenuWrapper';
import UserTabs from 'containers/pages/person/sessions/UserTabs';
import { useTestDataContext } from 'contexts/TestDataContext';
import Badge from 'components/badge/Badge';
import { useMenuContext } from 'contexts/MenuContext';
import DownloadReportsButton from 'containers/partials/session-report-buttons/DownloadReportsButton';

const Session = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  let location = useLocation();
  const { entityId, sessionId, testDataId } = useParams();
  const { session, sessionLoading, error, options } = useSessionContext();
  const {
    testData,
    fetchTestData,
    activeDate,
    unsetAllData,
    setNewActiveDate
  } = useTestDataContext();
  const { addMenuItems, removeAllMenuItems, menuItems } = useMenuContext();

  const isSessionRootView = useRouteMatch({
    path: ROUTE_USER_SESSIONS_ENTITY_SESSION,
    strict: true,
    exact: true
  });
  const isReportView = useRouteMatch({
    path: [
      ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
      ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA
    ],
    strict: true
  });
  const isTestsView = useRouteMatch({
    path: [
      ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
      ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT,
      ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA
    ],
    strict: true
  });

  const active = useRouteMatch([
    ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT,
    ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA,
    ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA,
    ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT,
    ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS,
    ROUTE_USER_SESSIONS_ENTITY_SESSION
  ]);

  useEffect(() => {
    if (!session.isRecurring) {
      addMenuItems(
        <DownloadReportsButton
          key={'DownloadSessionReportButton'}
          session={session}
          testData={testData}
          options={options}
          type={'group'}
        />
      );
    }
    return () => {
      removeAllMenuItems();
    };
  }, [session, testData, options]);

  useEffect(() => {
    if (session?.id !== sessionId) return;

    if (
      (!testDataId && session && (isReportView || isTestsView)) ||
      isSessionRootView
    ) {
      let testData;
      if (session.isRecurring) {
        if (!activeDate) {
          const today = new Date();
          setNewActiveDate(today);
          testData = session.getTestDataByDate(user.id, today);
        } else {
          testData = session.getTestDataByDate(user.id, activeDate);
        }
      } else {
        testData = session.getLastTestData(user.id);
      }
      if (testData?.id) {
        const selectedPath = !session.isRecurring
          ? ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA
          : testData.finishedPercentage > 0 && !isTestsView
            ? ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA
            : ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA;

        push(
          generatePath(selectedPath, {
            entityId,
            sessionId,
            testDataId: testData.id
          })
        );
      } else {
        if (isSessionRootView) {
          push(
            generatePath(ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS, {
              entityId,
              sessionId
            })
          );
        } else {
          unsetAllData();
        }
      }
    } else if (testDataId && session && (isReportView || isTestsView)) {
      if (!testData.id || testData.id !== testDataId) {
        fetchTestData(session.id, testDataId);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testDataId, session, location]);

  if (sessionLoading) {
    return (
      <Panel active={active?.isExact}>
        <Loader />
      </Panel>
    );
  }

  return (
    <Panel active={active?.isExact}>
      {error && <RetryPanel />}
      {session && (
        <div style={{ background: 'inherit' }}>
          <PanelHeader>
            <Breadcrumbs>
              <Breadcrumb
                to={generatePath(ROUTE_USER_SESSIONS_ENTITY, {
                  entityId
                })}
              >
                <FormattedMessage {...messages.breadcrumbSessions} />
              </Breadcrumb>
              <Breadcrumb>
                <FormattedMessage
                  {...messages.breadcrumbSessionReport}
                  values={{ session: session.name }}
                />
              </Breadcrumb>
            </Breadcrumbs>
            <PanelTitleWrapper>
              <PanelTitle>
                <div className="o-flex o-flex--align-center">
                  {session.recurring && session.enddate < new Date() && (
                    <Badge
                      variant="completed"
                      extraClassNames="u-margin-top-mini u-margin-right-small"
                    >
                      <FormattedMessage {...messages.badgeSessionEnded} />
                    </Badge>
                  )}
                  {session.name}
                </div>
                {testData && (
                  // Only show de button when there is a data
                  <PanelTitleButtonsGroup>
                    <MenuWrapper trigger={<PanelTitleButton />}>
                      {menuItems}
                    </MenuWrapper>
                  </PanelTitleButtonsGroup>
                )}
              </PanelTitle>
              <PanelSubtitle>
                {getLocalDateFormat(session.startdate)}
                {session?.enddate &&
                  ` - ${getLocalDateFormat(session.enddate)}`}
              </PanelSubtitle>
            </PanelTitleWrapper>
          </PanelHeader>
          <PanelBody>
            <UserTabs />
          </PanelBody>
        </div>
      )}
    </Panel>
  );
};

export default Session;
