import { useEffect } from 'react';

/**
 * A hook that scrolls the window to the top, to a specific offset,
 * or to the position of an element on load.
 *
 * @param {number | HTMLElement} target - The target to scroll to. If it's a number, it's treated as a vertical offset. If it's an HTMLElement, the window will scroll to this element.
 */
function useScrollToTop(target) {
  useEffect(() => {
    const handleScroll = () => {
      if (typeof target === 'number') {
        window.scrollTo({
          top: target,
          behavior: 'smooth'
        });
      } else if (target instanceof HTMLElement) {
        target.scrollIntoView({
          behavior: 'smooth'
        });
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };

    handleScroll();
  }, [target]);
}

export default useScrollToTop;
