import React from 'react';
import {
  MUTATION_REMOVE_PERSONS_FROM_SESSION,
  QUERY_GET_SESSION_BY_ID,
  QUERY_GET_SESSIONS_OF_SPORTER
} from 'services/aws/session-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';

const RemoveSporterFromSessionButton = ({
  sessionId,
  rootEntityId,
  entityId,
  sporterId,
  ids,
  children,
  onComplete,
  dataQa,
  ...props
}) => {
  const refetchQueries = [
    {
      query: QUERY_GET_SESSION_BY_ID,
      variables: { testSessionId: sessionId, entityId: rootEntityId }
    }
  ];
  if (sporterId) {
    refetchQueries.push({
      query: QUERY_GET_SESSIONS_OF_SPORTER,
      variables: { personId: sporterId, entityId }
    });
  }
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_REMOVE_PERSONS_FROM_SESSION}
      variables={{ testSessionId: sessionId, personIds: ids }}
      refetchQueries={refetchQueries}
      confirmTitle={messages.modalConfirmRemoveSporterFromSessionTitle}
      confirmMessage={messages.modalConfirmRemoveSporterFromSessionMessage}
      update={(cache, { data: { removePersonsFromTestSession } }) => {
        if (removePersonsFromTestSession) {
          if (onComplete) onComplete();
        }
      }}
      dataQa={dataQa}
    >
      <Icon
        id="delete"
        strokeColor={props.disabled ? 'color-neutral' : 'color-neutral-dark'}
      />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveSporterFromSessionButton;
