export const createCollectionSlice = set => ({
  collection: null,
  setCollection: value => set(() => ({ collection: value })),

  collections: [],
  setCollections: values => set(() => ({ collections: values })),

  collectionsRef: [],
  setCollectionsRef: values => set(() => ({ collectionsRef: values })),

  collectionsFilter: { search: '', tags: [] },
  setCollectionsFilter: value =>
    set(state => ({
      collectionsFilter: { ...state.collectionsFilter, ...value }
    })),
  resetCollectionsFilter: () =>
    set(() => ({ collectionsFilter: { search: '', tags: [] } })),

  selectedCollectionExercises: [],
  selectCollectionExercises: value =>
    set(state => {
      const selectedCollectionExercises = [
        ...state.selectedCollectionExercises
      ];
      if (!selectedCollectionExercises.find(s => s.id === value.id)) {
        selectedCollectionExercises.push(value);
      } else {
        const index = selectedCollectionExercises.findIndex(
          e => e.id === value.id
        );
        if (index >= 0) {
          selectedCollectionExercises.splice(index, 1);
        }
      }
      return {
        selectedCollectionExercises
      };
    }),
  setSelectedCollectionExercises: values =>
    set(() => ({
      selectedCollectionExercises: values || []
    })),
  unsetSelectedCollectionExercises: exercises =>
    set(state => {
      const selectedCollectionExercises = [
        ...state.selectedCollectionExercises
      ];
      exercises.forEach(exercise => {
        const index = selectedCollectionExercises.findIndex(
          e => e.id === exercise.id
        );
        if (index >= 0) {
          selectedCollectionExercises.splice(index, 1);
        }
      });
      return {
        selectedCollectionExercises
      };
    }),
  resetSelectedCollectionExercises: () =>
    set(() => ({ selectedCollectionExercises: [] })),

  collectionDraggable: false,
  setCollectionDraggable: value => set(() => ({ collectionDraggable: value }))
});
