import { TableCell, TableRow } from 'components/table';
import { useResultsPanelContext } from 'contexts/ResultsPanelContext';
import BenchmarkResultsTableCell from 'containers/pages/results/table/BenchmarkResultsTableCell';
import { BenchmarkTypes } from 'constants.js';
import AverageResultsTableCell from 'containers/pages/results/table/AverageResultsTableCell';
import { useStore } from 'stores/Store';

const ResultsTableRow = ({ user, row }) => {
  const selectedHeaders = useStore(state => state.selectedHeaders);
  const { type, onClickCell } = useResultsPanelContext();
  return (
    <TableRow>
      <TableCell mainCell id={user.label}>
        <div className="c-table__content">
          <span>{user.label}</span>
        </div>
      </TableCell>

      {selectedHeaders.map((data, i) => {
        if (!data?.id) return <TableCell key={i} />;
        const [id, laterality] = data.id.split('_');
        if (type === BenchmarkTypes.DEFAULT) {
          return (
            <BenchmarkResultsTableCell
              onClick={testItem => {
                if (row?.[i]) {
                  onClickCell(i, user, testItem, laterality);
                }
              }}
              key={i}
              testItem={row?.[i]}
              laterality={laterality}
            />
          );
        } else {
          return (
            <AverageResultsTableCell
              onClick={(testItem, average) => {
                if (row?.[i]) {
                  onClickCell(i, user, testItem, laterality, average);
                }
              }}
              key={i}
              testItem={row?.[i]}
              average={data.average}
              laterality={laterality}
            />
          );
        }
      })}
    </TableRow>
  );
};

export default ResultsTableRow;
