import { useContext, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Card, CardBody, CardFooter } from 'components/card';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelTools from 'components/panel/PanelTools';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ROUTE_LESSONS_ENTITY,
  ROUTE_LESSONS_ENTITY_LESSON,
  ROUTE_LESSONS_ENTITY_LESSON_EDIT,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS,
  ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO
} from 'routes/RouteList';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import messages from 'messages';
import PanelBody from 'components/panel/PanelBody';
import { StoreContext } from 'index';
import { Tab, TabList, TabPanel, Tabs } from 'components/tabs';
import LessonModel from 'models/Lesson';
import FormEditFooterButtons from 'containers/partials/buttons/FormEditFooterButtons';
import useTabIndex from 'hooks/utils/useTabIndex';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import { useNotificationQueue } from 'components/notification';
import Loader from 'components/loader/Loader';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import { PanelSubtitle, PanelTitleButton } from 'components/panel/panel-title';
import { MenuWrapper } from 'components/menu';
import useSelectable from 'hooks/utils/useSelectable';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import SortableList from 'components/dndkit/SortableList';
import { useMutation } from '@apollo/client';
import {
  MUTATION_EDIT_LESSON,
  QUERY_GET_LESSONS
} from 'services/aws/lessons-query';
import RemoveVideosLessonButton from 'containers/partials/buttons/RemoveVideosLessonButton';
import AddVideoModal from 'containers/pages/videos/AddVideoModal';
import VideoListSortable from 'containers/partials/lists/VideoListSortable';
import LessonForm from 'containers/pages/lessons/LessonForm';
import LessonFormInput from 'containers/pages/lessons/LessonFormInput';
import useLesson from 'hooks/queries/useLesson';
import FieldInput from 'components/input/FieldInput';

const Lesson = ({ entityId, active, lessonId, exerciseId }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const notification = useNotificationQueue();
  const [modalData, setModalData] = useState(null);
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_LESSONS_ENTITY_LESSON_VIDEOS,
    [ROUTE_LESSONS_ENTITY_LESSON, ROUTE_LESSONS_ENTITY_LESSON_EDIT]
  ]);
  const [draggable, setDraggable] = useState(false);
  const { lesson, videos, setFilter, loading, error } = useLesson(
    entityId,
    lessonId,
    user
  );
  const { selected, setSelected } = useSelectable([]);
  const [videosSortOrder, setVideosSortOrder] = useState(null);

  const [editTemplate] = useMutation(MUTATION_EDIT_LESSON);

  const onCompleteHandler = lessonId => {
    push(
      generatePath(ROUTE_LESSONS_ENTITY_LESSON, {
        entityId,
        lessonId
      })
    );
  };

  const videosTab = () => {
    push(
      generatePath(ROUTE_LESSONS_ENTITY_LESSON_VIDEOS, {
        entityId,
        lessonId
      })
    );
  };

  const detailTab = () => {
    push(
      generatePath(ROUTE_LESSONS_ENTITY_LESSON_EDIT, {
        entityId,
        lessonId
      })
    );
  };

  const onSearch = e => {
    setFilter({ search: e.target.value });
  };

  const onlessonDeleted = lessonId => {
    notification.add(`removed_${lessonId}`, {
      message: intl.formatMessage(messages.messageRemovedLessonSuccess)
    });
    push(
      generatePath(ROUTE_LESSONS_ENTITY, {
        entityId
      })
    );
  };

  const submitVideosSorting = async () => {
    const templateData = {
      id: lesson.id,
      entityId: entityId,
      title: lesson.title,
      exerciseIds: [...videosSortOrder]
    };

    await editTemplate({
      variables: { ...templateData },
      refetchQueries: [
        {
          query: QUERY_GET_LESSONS,
          variables: { entityId }
        }
      ]
    }).then(res => {
      setDraggable(false);
      notification.add(res.data.editExerciseProgramTemplate.id, {
        message: intl.formatMessage(messages.templateSaved)
      });
    });
  };

  const onCancelHandler = () => {
    setDraggable(false);
  };

  if (loading)
    return (
      <Panel active={active}>
        <Loader />
      </Panel>
    );
  if (error) return <RetryPanel />;

  return (
    <Panel active={active}>
      <PanelHeader>
        <Breadcrumbs>
          <Breadcrumb to={generatePath(ROUTE_LESSONS_ENTITY, { entityId })}>
            <FormattedMessage {...messages.breadcrumbLessons} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            {lesson.title}
            <MenuWrapper trigger={<PanelTitleButton />}>
              <RemoveVideosLessonButton
                entityId={entityId}
                lessonId={lessonId}
                menu
                onDeleted={lessonId => onlessonDeleted(lessonId)}
              >
                <FormattedMessage {...messages.removeLessonButton} />
              </RemoveVideosLessonButton>
              <Button
                menu
                onClick={() => setDraggable(true)}
                disabled={(selected && selected.length > 0) || draggable}
              >
                {draggable ? (
                  intl.formatMessage(
                    messages.lessonsRemoveVideoDisabledDragging
                  )
                ) : (
                  <FormattedMessage {...messages.sortLessonsButton} />
                )}
              </Button>
            </MenuWrapper>
          </PanelTitle>
          <PanelSubtitle>
            <div className="u-margin-bottom-small">
              <FormattedMessage
                {...messages.videosLengthInIt}
                values={{ this: videos.length }}
              />
            </div>
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools extraClassNames="u-margin-top-small">
          <FieldInput rounded icon="search-grey" iconLeft onChange={onSearch} />

          {ability.can('create', 'Video') && (
            <Button
              rounded
              primary
              disabled={!lessonId}
              onClick={() => setModalData(true)}
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.lessonsAddVideo} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>
      <PanelBody>
        <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
          <TabList>
            <Tab onClick={lessonId && videosTab}>
              <FormattedMessage {...messages.lessonVideosTab} />
            </Tab>
            <Tab onClick={lessonId && detailTab}>
              <FormattedMessage {...messages.lessonDetails} />
            </Tab>
          </TabList>
          <TabPanel>
            {videos.length > 0 ? (
              <>
                <SortableList
                  items={videos}
                  onSort={videos => setVideosSortOrder(videos)}
                >
                  {({ items }) => (
                    <VideoListSortable
                      exercises={items}
                      selectedExercises={selected}
                      activeId={exerciseId}
                      extraClassNames="u-padding-top-medium"
                      draggable={draggable}
                      enablePreviewModal={false}
                      showTemplateCount={false}
                      onClick={id => {
                        if (id !== exerciseId) {
                          push(
                            generatePath(
                              ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO,
                              {
                                entityId,
                                lessonId,
                                videoId: id
                              }
                            )
                          );
                        }
                      }}
                      onSelect={exerciseIds => {
                        setSelected([...exerciseIds]);
                      }}
                    />
                  )}
                </SortableList>
                {draggable && (
                  <ButtonsGroup>
                    <Button secondary onClick={() => onCancelHandler()}>
                      <FormattedMessage {...messages.cancelButton} />
                    </Button>
                    <Button
                      primary
                      disabled={loading} //|| processing
                      onClick={() => submitVideosSorting()}
                    >
                      <FormattedMessage {...messages.saveSortButton} />
                    </Button>
                  </ButtonsGroup>
                )}
              </>
            ) : (
              <Card empty extraClassNames="u-margin-auto">
                <CardBody empty>
                  <Message emptyState={true} icon="browse">
                    <MessageText>
                      <FormattedMessage {...messages.lessonEmptyVideos} />
                    </MessageText>
                    <Button primary rounded onClick={() => setModalData(true)}>
                      <Icon id="add-white" />
                      <FormattedMessage {...messages.lessonsAddVideo} />
                    </Button>
                  </Message>
                </CardBody>
              </Card>
            )}
          </TabPanel>
          <TabPanel>
            <LessonForm
              entityId={entityId}
              lesson={new LessonModel({ ...lesson })}
              onComplete={onCompleteHandler}
            >
              {({ isSubmitting, handleSubmit }) => (
                <Card secondary>
                  <CardBody secondary>
                    <LessonFormInput />
                  </CardBody>
                  <CardFooter secondary>
                    <FormEditFooterButtons
                      edit
                      disabledSubmit={isSubmitting}
                      onCancel={() =>
                        push(
                          generatePath(ROUTE_LESSONS_ENTITY, {
                            entityId
                          })
                        )
                      }
                      onSubmit={handleSubmit}
                    />
                  </CardFooter>
                </Card>
              )}
            </LessonForm>
          </TabPanel>
        </Tabs>
      </PanelBody>
      {modalData && (
        <AddVideoModal
          entityId={entityId}
          lessonId={lessonId}
          onClose={() => setModalData(false)}
        />
      )}
    </Panel>
  );
};

export default observer(Lesson);
