import { appSyncClient } from './app-sync';
import gql from 'graphql-tag';
import { getGQLResult } from './utils/get';
import { gqlMutation } from 'services/aws/utils/mutate';

export const S_QUERY_GET_ENTITY_BY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      meta
    }
  }
`;

export const QUERY_GET_ORGANISATIONS_BY_ID = gql`
  query getOrganisations($id: String!) {
    getOrganisations(id: $id) {
      id
      name
      info
      subEntities {
        id
        name
        type
      }
    }
  }
`;

export const QUERY_GET_ENTITY_BY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      subEntities {
        id
        name
      }
      users {
        id
        firstname
        lastname
      }
      regioCode
      baseLanguage
    }
  }
`;

export const getAllEntitiesQuery = async entityId => {
  const getEntities = gql`
    query entity($id: String!) {
      getEntity(id: $id) {
        id
        clients {
          id
          firstname
          lastname
          entities {
            entityId
            id
          }
        }
        subEntities {
          id
          name
        }
      }
    }
  `;
  return await getGQLResult(appSyncClient, getEntities, { id: entityId });
};

// Recursivly get all persons
export const getAllClientsPersonEntity = async id => {
  let personEntities = [];
  const entities = await getAllEntitiesQuery(id);
  const entity = entities.data.getEntity;
  if (entity.clients.length > 0) {
    const clientEntities = entity.clients.map(p => {
      const pe = p.entities.find(e => e.entityId === id);
      return { id: pe.id, personId: p.id };
    });
    personEntities.push(...clientEntities);
  }
  if (entity.subEntities.length > 0) {
    const result = await Promise.all(
      entity.subEntities.map(async e => {
        return await getAllClientsPersonEntity(e.id);
      })
    );
    personEntities = personEntities.concat(...result);
  }
  return personEntities;
};

export const QUERY_GET_ORGANISATION_TREE = gql`
  query getOrganisationTree {
    getOrganisationTree
  }
`;

export const QUERY_GET_ORGANISATION_BY_ID = gql`
  query entity($id: String!) {
    getEntity(id: $id) {
      id
      name
      parentId
      info
      meta
      config
      subEntities {
        id
        name
      }
      baseLanguage
      featureFlags
      apiKeys
    }
  }
`;

export const MUTATION_ADD_ORGANISATION = gql`
  mutation createEntity(
    $name: String!
    $parentId: ID!
    $info: AWSJSON!
    $baseLanguage: baseLanguage
    $featureFlags: AWSJSON
  ) {
    createEntity(
      input: {
        parentId: $parentId
        name: $name
        info: $info
        baseLanguage: $baseLanguage
        featureFlags: $featureFlags
      }
    ) {
      id
      name
    }
  }
`;

export const MUTATION_CONVERT_GROUP_TO_ENTITY = gql`
  mutation convertGroupToEntity($entityId: ID!) {
    convertGroupToEntity(entityId: $entityId) {
      id
    }
  }
`;

export const MUTATION_ADD_GROUP_ENTITY = gql`
  mutation createEntity($parentId: ID!, $name: String!, $meta: AWSJSON) {
    createEntity(input: { parentId: $parentId, name: $name, meta: $meta }) {
      id
      name
      meta
    }
  }
`;

export const MUTATION_UPDATE_ENTITY = gql`
  mutation editEntity($id: String!, $name: String!, $meta: AWSJSON) {
    editEntity(input: { id: $id, name: $name, meta: $meta }) {
      id
      name
      meta
    }
  }
`;

export const MUTATION_UPDATE_ORGANISATION = gql`
  mutation editEntity(
    $id: String!
    $name: String!
    $info: AWSJSON!
    $baseLanguage: baseLanguage
    $featureFlags: AWSJSON
  ) {
    editEntity(
      input: {
        id: $id
        name: $name
        info: $info
        baseLanguage: $baseLanguage
        featureFlags: $featureFlags
      }
    ) {
      id
      name
      info
      baseLanguage
      featureFlags
    }
  }
`;

export const MUTATION_ARCHIVE_ENTITY = gql`
  mutation archiveEntity($id: String!) {
    archiveEntity(id: $id)
  }
`;

export const archiveGroupMutation = async ({ variables, refetchQueries }) => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_ARCHIVE_ENTITY,
      variables,
      refetchQueries,
      update: (cache, { data: { archiveEntity } }) => {
        if (archiveEntity) {
          resolve();
        }
      }
    });
  });
};
