import { PackageTypes } from 'constants.js';

export default class TestSet {
  id;
  title = '';
  note = '';
  entityId;
  tests = [];
  originalId;
  version;
  config;
  editable;
  defaultBenchmark;
  customBenchmark;
  type;
  visible;
  preventionType;
  sport;
  meta;
  injury;
  injuryId;
  filtered;

  constructor({
    id,
    title = '',
    note = '',
    entityId = null,
    tests = [],
    originalId = null,
    version = '',
    config = '',
    editable = false,
    defaultBenchmark = '',
    customBenchmark = '',
    type = '',
    preventionType = '',
    visible = false,
    sport = null,
    meta,
    injury = '',
    filtered
  }) {
    this.id = id;
    this.title = title ?? '';
    this.note = note ?? '';
    this.entityId = entityId;
    this.tests = tests;
    this.originalId = originalId;
    this.version = version;
    this.config = config;
    this.editable = editable;
    this.defaultBenchmark = defaultBenchmark;
    this.customBenchmark = customBenchmark;
    this.type = type;
    this.visible = visible;
    this.preventionType = preventionType;
    this.sport = sport;
    this.meta = meta;
    this.injuryId = injury?.id;
    this.injury = injury;
    this.filtered = filtered;
  }

  get isILike() {
    return (
      this.meta?.type === PackageTypes.ILIKE ||
      this.config?.type === PackageTypes.ILIKE
    );
  }
  get isIDo() {
    return (
      this.meta?.type === PackageTypes.IDO ||
      this.config?.type === PackageTypes.IDO
    );
  }
}
