import { arrayToTree } from 'performant-array-to-tree';
import { sort } from 'utils/sort';
import Exercise from 'models/Exercise';

export const createExerciseSlice = (set, get) => ({
  loadingExercises: false,
  setLoadingExercises: value => set(() => ({ loadingExercises: value })),
  exercises: [],
  setExercises: value =>
    set(() => ({ exercises: value?.length > 0 ? [...value] : [] })),
  exercisePageToken: null,
  setExercisePageToken: value => set(() => ({ exercisePageToken: value })),
  getExercise: id => {
    const exercises = get().exercises;
    if (!exercises) return null;
    return exercises.find(exercise => exercise.id === id);
  },
  updateExercise: (id, obj) =>
    set(state => ({
      exercises: state.exercises.map(exercise => {
        if (exercise.id === id) {
          return new Exercise({ ...exercise, ...obj });
        }
        return new Exercise({ ...exercise });
      })
    })),

  addExercise: obj =>
    set(state => ({
      exercises: [...state.exercises, new Exercise({ ...obj })]
    })),

  removeExercise: id =>
    set(state => ({
      exercises: state.exercises.filter(exercise => exercise.id !== id)
    })),

  selectedExercises: [],
  selectExercises: value =>
    set(state => {
      const selectedExercises = [...state.selectedExercises];
      if (!selectedExercises.find(s => s.id === value.id)) {
        selectedExercises.push(value);
      } else {
        const index = selectedExercises.findIndex(e => e.id === value.id);
        if (index >= 0) {
          selectedExercises.splice(index, 1);
        }
      }
      return {
        selectedExercises
      };
    }),
  setSelectedExercises: value =>
    set(state => {
      // merge selectedExercises with value without duplicates
      const selectedExercises = [...state.selectedExercises];
      value.forEach(exercise => {
        if (!selectedExercises.find(e => e.id === exercise.id)) {
          selectedExercises.push(exercise);
        }
      });
      return {
        selectedExercises
      };
    }),
  unsetSelectedExercises: exercises =>
    set(state => {
      const selectedExercises = [...state.selectedExercises];
      exercises.forEach(exercise => {
        const index = selectedExercises.findIndex(e => e.id === exercise.id);
        if (index >= 0) {
          selectedExercises.splice(index, 1);
        }
      });
      return {
        selectedExercises
      };
    }),
  resetSelectedExercises: () => set(() => ({ selectedExercises: [] })),

  tags: [],
  setTags: value =>
    set(() => ({ tags: sort([...value], { keys: [{ key: 'order' }] }) })),
  getTagTree: () => {
    const tags = get().tags;
    if (!tags) return [];
    const tree = arrayToTree(tags, { dataField: null });
    if (tree.length === 1) {
      return tree[0].children;
    }
    return tree;
  },

  exercisesFilter: { search: '', tags: [], myOrg: false },
  setExercisesFilter: value =>
    set(() => ({
      exercisesFilter: {
        search: value.search,
        tags: value.tags,
        myOrg: value.myOrg
      }
    })),
  resetExerciseFilter: () => set(() => ({ exercisesFilter: {} }))
});
