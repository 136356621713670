import React, { useContext, useEffect, useRef } from 'react';
import { Button } from 'components/button';
import MessageText from 'components/message/MessageText';
import Message from 'components/message/Message';
import { Card, CardBody } from 'components/card';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE } from 'routes/RouteList';
import Icon from 'components/icon/Icon';
import { useStore } from 'stores/Store';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import TemplateExercisesListSortable from 'containers/pages/exercises/templates/TemplateExercisesListSortable';
import { sortByArray } from 'utils/sort';
import { TemplateType } from 'constants';
import { useClickAway } from '@uidotdev/usehooks';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import Template from 'models/Template';

const TemplateExercisesTab = ({ onAddExercises }) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const { entityId, templateId } = useParams();
  const { push } = useHistory();

  const templateFormRef = useRef();
  const defaultSort = useRef([]);

  const template = useStore(state => state.template);
  const setTemplate = useStore(state => state.setTemplate);
  const selectedTemplateExercises = useStore(
    state => state.selectedTemplateExercises
  );
  const setSelectedTemplateExercises = useStore(
    state => state.setSelectedTemplateExercises
  );
  const selectTemplateExercises = useStore(
    state => state.selectTemplateExercises
  );
  const templateSortableActive = useStore(
    state => state.templateSortableActive
  );
  const setTemplateSortableActive = useStore(
    state => state.setTemplateSortableActive
  );
  const sortableContainerRef = useClickAway(() => {
    if (templateSortableActive) {
      uiState.showModal({
        title: intl.formatMessage(
          messages.exercisesSortExercisesModalWarningTitle
        ),
        message: intl.formatMessage(
          messages.exercisesSortExercisesModalWarningMessage
        ),
        okLabel: intl.formatMessage(messages.saveButton),
        dismissLabel: intl.formatMessage(messages.buttonBack),
        type: MODAL_TYPES.WARNING,
        okHandler: () => {
          templateFormRef.current.submitForm();
        }
      });
    }
  });

  useEffect(() => {
    defaultSort.current = template.exercises.map(exercise => exercise.id);
    setSelectedTemplateExercises([]);
  }, [template]);

  const onClickHandler = exerciseId => {
    push(
      generatePath(
        ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE,
        {
          entityId,
          templateId,
          exerciseId
        }
      )
    );
  };

  const onCancel = () => {
    // sortByArray(template.exercises, defaultSort.current);
    setTemplateSortableActive(false);
    setTemplate(
      new Template({
        ...template,
        exercises: sortByArray(template.exercises, defaultSort.current)
      })
    );
  };

  return (
    <div
      ref={sortableContainerRef}
      className="o-flex o-flex--column u-padding-vertical-small"
      style={{
        // overflowY: 'scroll', // TODO @fabian: deze zorgt voor extra scroll bar
        flex: 1,
        marginBottom: templateSortableActive ? 72 : 0
      }}
    >
      {template.exercises.length > 0 ? (
        <TemplateForm
          ref={templateFormRef}
          entityId={entityId}
          template={template}
          type={TemplateType.TEMPLATE}
          onComplete={() => setTemplateSortableActive(false)}
        >
          <TemplateExercisesListSortable
            exercises={template.exercises}
            checkable={!templateSortableActive}
            showNote={true}
            edit={!templateSortableActive}
            onClick={onClickHandler}
            onCancel={onCancel}
            selectedExercises={selectedTemplateExercises}
            onSelectedExercisesChange={selectTemplateExercises}
          />
        </TemplateForm>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message emptyState={true} icon="browse">
              <MessageText>
                <FormattedMessage {...messages.templateEmptyExercises} />
              </MessageText>
              <Button primary rounded onClick={() => onAddExercises(template)}>
                <Icon id="add-white" />
                <FormattedMessage {...messages.exercisesAddExercisesButton} />
              </Button>
            </Message>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default TemplateExercisesTab;
