import React, { useContext, useState } from 'react';
import Modal from 'components/modal/Modal';
import {
  ROUTE_GROWTH_PREDICTION_ENTITY_SPORTER,
  ROUTE_GROWTH_PREDICTION_ENTITY_GROUP,
  ROUTE_GROWTH_PREDICTION_ENTITY_SESSION,
  ROUTE_GROWTH_PREDICTION_ENTITY
} from 'routes/RouteList';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import GrowthPredictionChoiceView from './GrowthPredictionChoiceView';
import SelectGroupView from './SelectGroupView';
import { GrowthPredictionModalView } from './growth-prediction-modal-view';
import SelectSessionView from './SelectSessionView';
import SelectSporterView from './SelectSporterView';
import { StoreContext } from 'index';
// import useTestSets from 'hooks/queries/useTestSets';

const GrowthPredictionMainModal = ({
  entityId,
  master,
  onCloseHandler,
  ...props
}) => {
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);

  const [view, setView] = useState(
    props.view || GrowthPredictionModalView.GROWTH_PREDICTION_GROUP
  );

  const { push, goBack } = useHistory();
  const { pathname } = useLocation();

  // const { fetchTestSets } = useTestSets({ entityId: rootEntityId });

  const onSubmitClientHandler = (id, extraId, name) => {
    // TODO: maarten dit is een error, wss?
    const { onSubmitHandler } = this.props;
    if (onSubmitHandler && typeof onSubmitHandler === 'function') {
      onSubmitHandler(id, extraId, name);
    }
  };

  const onSubmitMasterHandler = async (id, extraId) => {
    let route;
    switch (view) {
      case GrowthPredictionModalView.GROWTH_PREDICTION_GROUP:
        route = ROUTE_GROWTH_PREDICTION_ENTITY_GROUP.replace(':groupId', id);
        break;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SPORTER:
        route = ROUTE_GROWTH_PREDICTION_ENTITY_SPORTER.replace(
          ':sporterId',
          id
        ).replace(':groupId', extraId);
        break;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SESSION:
        route = ROUTE_GROWTH_PREDICTION_ENTITY_SESSION.replace(
          ':sessionId',
          id
        );
        break;
      default:
        route = ROUTE_GROWTH_PREDICTION_ENTITY;
    }

    /*const testSets = await fetchTestSets({
      types: [TestSetV2Type.TALENT, TestSetV2Type.PREVENTION]
    });

    console.log('testSets', testSets);

    let testSetId = testSets[0].id;
    if (testSets.length > 0) {
      const hasIdoSet = testSets.find(ts => ts.type === PackageSubTypes.IDO);

      if (hasIdoSet) {
        testSetId = hasIdoSet.id;
      } else {
        testSetId = testSets.find(ts => ts.type === PackageSubTypes.HAN);
      }
    }

    console.log('testSetId', testSetId);*/

    route = generatePath(route, {
      popup: 'default',
      entityId: rootEntityId,
      benchmarkId: 'default'
    });

    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    }
    if (route !== pathname) {
      push(route);
    }
  };

  const onCancelHandler = () => {
    if (onCloseHandler && typeof onCloseHandler === 'function') {
      onCloseHandler();
    } else {
      goBack();
    }
  };

  const getView = view => {
    switch (view) {
      case GrowthPredictionModalView.GROWTH_PREDICTION_CHOICE:
        return GrowthPredictionChoiceView;
      case GrowthPredictionModalView.GROWTH_PREDICTION_GROUP:
        return SelectGroupView;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SESSION:
        return SelectSessionView;
      case GrowthPredictionModalView.GROWTH_PREDICTION_SPORTER:
        return SelectSporterView;
      default:
        return SelectGroupView;
    }
  };

  const Component = getView(view);

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames="c-modal__content-bottom-auto"
    >
      <Component
        entityId={entityId}
        onChangeView={view => setView(view)}
        onCancelHandler={onCancelHandler}
        onSubmitHandler={master ? onSubmitMasterHandler : onSubmitClientHandler}
      />
    </Modal>
  );
};

export default GrowthPredictionMainModal;
