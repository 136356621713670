import gql from 'graphql-tag';

export const QUERY_GET_PERSON_MEDICALS = gql`
  query getMedicalsByPerson($personId: ID!, $entityId: ID!) {
    getMedicalsByPerson(entityId: $entityId, personId: $personId) {
      id
      person {
        id
        firstname
        lastname
      }
      description
      injuries {
        id
        injuryDate
        injuryEndDate
        sides
        title
        personInjuryType
        meta
        injury {
          id
          title
          sides
        }
        screenings {
          id
          name
          startdate
          finished
          finishedPercentage
          type
          preventionType
        }
      }
    }
  }
`;

export const MUTATION_ADD_PERSON_INJURY = gql`
  mutation addPersonInjury(
    $injuryId: ID
    $medicalId: ID
    $injuryDate: AWSDate
    $injuryEndDate: AWSDate
    $sides: [InjurySide]
    $title: String
    $personInjuryType: PersonInjuryType
    $meta: AWSJSON
  ) {
    addPersonInjury(
      input: {
        injuryId: $injuryId
        medicalId: $medicalId
        injuryDate: $injuryDate
        injuryEndDate: $injuryEndDate
        sides: $sides
        title: $title
        personInjuryType: $personInjuryType
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_EDIT_PERSON_INJURY = gql`
  mutation editPersonInjury(
    $id: ID
    $injuryId: ID
    $medicalId: ID
    $injuryDate: AWSDate
    $injuryEndDate: AWSDate
    $sides: [InjurySide]
    $title: String
    $personInjuryType: PersonInjuryType
    $meta: AWSJSON
  ) {
    editPersonInjury(
      input: {
        id: $id
        injuryId: $injuryId
        medicalId: $medicalId
        injuryDate: $injuryDate
        injuryEndDate: $injuryEndDate
        sides: $sides
        title: $title
        personInjuryType: $personInjuryType
        meta: $meta
      }
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_PERSON_INJURY = gql`
  mutation archivePersonInjury($personInjuryId: ID!) {
    archivePersonInjury(personInjuryId: $personInjuryId)
  }
`;

export const QUERY_GET_INJURIES = gql`
  query getInjuries($entityId: ID!, $types: [TestSetV2Type], $sportId: ID) {
    getInjuries(entityId: $entityId, types: $types, sportId: $sportId) {
      id
      title
      sides
      type
      region {
        title
        order
        copy
      }
    }
  }
`;

export const MUTATION_ADD_INJURY = gql`
  mutation addInjury($entityId: ID, $title: String, $sides: [InjurySide]) {
    addInjury(input: { entityId: $entityId, title: $title, sides: $sides }) {
      id
    }
  }
`;

export const MUTATION_ADD_MEDICAL = gql`
  mutation addMedical($entityId: ID, $personId: ID, $description: String) {
    addMedical(
      input: {
        entityId: $entityId
        personId: $personId
        description: $description
      }
    ) {
      id
      description
      person {
        id
        firstname
        lastname
      }
      injuries {
        id
        injuryDate
        sides
        injury {
          id
          title
          sides
        }
        screenings {
          id
          name
          startdate
        }
      }
    }
  }
`;

export const MUTATION_EDIT_MEDICAL = gql`
  mutation editMedical(
    $id: ID
    $entityId: ID
    $personId: ID
    $description: String
  ) {
    editMedical(
      input: {
        id: $id
        entityId: $entityId
        personId: $personId
        description: $description
      }
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_MEDICAL = gql`
  mutation archiveMedical($medicalId: ID!) {
    archiveMedical(medicalId: $medicalId)
  }
`;
