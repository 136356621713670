import React, { Fragment, useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ListBody from 'components/list/ListBody';
import ProgramsListItem from 'containers/partials/list-items/ProgramsListItem';
import { Button, ButtonsGroup } from 'components/button';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import List from 'components/list/List';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import FieldInput from 'components/input/FieldInput';
import { StoreContext } from 'index';

const ProgramsList = ({
  entityId,
  programs,
  onClick,
  onSearch,
  type,
  emptyMessage
}) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const intl = useIntl();
  const defaultVisibleItems = 8;
  const [showAll, setShowAll] = useState(false);
  const { programId } = useParams();
  const parentRef = useRef();

  if (!Array.isArray(programs)) return null;

  return (
    <>
      <div className="p-tabs__tab-header u-margin-top-small u-padding-bottom-none">
        <FieldInput
          style={{ marginBottom: 8 }}
          rounded
          icon="search-grey"
          iconLeft
          onChange={onSearch}
        />
      </div>
      {programs.length > 0 ? (
        <List
          ref={parentRef}
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            maxHeight: '100%',
            height: '100%',
            margin: '0 -16px',
            padding: '8px 16px 16px'
          }}
        >
          <ListBody>
            {programs.map((program, i) => (
              <Fragment key={program.id}>
                {(showAll ||
                  i < defaultVisibleItems ||
                  (programId && program.id === programId)) && (
                  <ProgramsListItem
                    id={program.id}
                    program={program}
                    entityId={entityId}
                    onClick={() => onClick && onClick(program)}
                    type={type}
                    active={programId && program.id === programId}
                    locale={locale}
                  />
                )}
              </Fragment>
            ))}
          </ListBody>
          <ButtonsGroup extraClassNames="o-flex--justify-center">
            {programs.length > defaultVisibleItems && (
              <Button rounded grey onClick={() => setShowAll(!showAll)}>
                <Icon id={!showAll ? 'dropdown-open' : 'dropdown-closed'} />
                {showAll
                  ? intl.formatMessage(messages.exercisesProgramsButtonShowLess)
                  : intl.formatMessage(messages.exercisesProgramsButtonShowAll)}
              </Button>
            )}
          </ButtonsGroup>
        </List>
      ) : (
        <Card centered>
          <CardBody empty>
            <Message iconFillColor="color-neutral-dark" empty icon="browse">
              <MessageText>
                {emptyMessage || (
                  <FormattedMessage {...messages.programsEmpty} />
                )}
              </MessageText>
            </Message>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ProgramsList;
