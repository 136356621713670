import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import RefactoredPanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import {
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton
} from 'components/panel/panel-title';
import { PanelTitleButtonsGroup } from 'components/panel';
import { ButtonExpandable } from 'components/button';
import messages from 'messages';
import {
  MenuItemsGroup,
  MenuItemsGroupItem,
  MenuWrapper
} from 'components/menu';
import RefactoredPanelHeader from 'components/panel/RefactoredPanelHeader';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import RemoveGroupButton from 'containers/partials/buttons/RemoveGroupButton';
import CopyPersonsModal, {
  PERSON_ACTION
} from 'containers/partials/modals/CopyPersonsModal';
import RemovePersonsFromGroupButton from 'containers/partials/buttons/RemovePersonsFromGroupButton';
import InviteSportersButton from 'containers/partials/buttons/InviteSportersButton';
import RemoveGroupsButton from 'containers/partials/buttons/RemoveGroupsButton';
import RemovePersonsFromOrgButton from 'containers/partials/buttons/RemovePersonsFromOrgButton';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ROUTE_SPORTERS_ENTITY_POOL } from 'routes/RouteList';
import { useMenuContext } from 'contexts/MenuContext';
import {
  createEntityStore,
  EntityStoreContext
} from 'containers/pages/persons/store/EntityStoreContext';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { StoreContext } from 'index';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import CreatePersonModal from 'containers/pages/persons/CreatePersonModal';
import GroupModal from 'containers/pages/persons/GroupModal';
import Group from 'models/Group';
import Modal from 'components/modal/Modal';
import ImportSportersCard, {
  IMPORT_SPORTERS_STATE
} from 'containers/partials/cards/ImportSportersCard';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import AddPersonsModal from 'containers/pages/persons/AddPersonsModal';
import GenerateQRCodeModal from 'containers/partials/modals/GenerateQRCodeModal';

const modals = {
  COPY: 'copy',
  MOVE: 'move',
  IMPORT: 'import',
  PERSON: 'person',
  ADD_PERSON: 'add_person',
  GROUP: 'group'
};

const OverviewHeader = ({ panelTitle, activeTab }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { entityId, groupId } = useParams();
  const { push } = useHistory();
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });
  const ability = useAbility(AbilityContext);

  const group = useEntityContext(s => s.group);
  const selected = useEntityContext(s => s.selectedPersons);
  const selectedGroups = useEntityContext(s => s.selectedGroups);
  const clearSelected = useEntityContext(s => s.clearSelected);

  const entityModalStore = useRef(createEntityStore()).current;

  const { addMenuItems, removeAllMenuItems, menuItems } = useMenuContext();

  const [activeModal, setActiveModal] = useState({ type: null, action: null });
  const [modalSize, setModalSize] = useState(true);
  const [showQrModal, setShowQrModal] = useState(false);

  const canCreateAthletes = ability.can('create', 'Athletes');
  const canManageAthletes = ability.can('edit', 'Groups');
  const canCreateGroups = ability.can('create', 'Groups');

  const isGroupTab = activeTab === 0;
  const isAthletesTab = activeTab === 1;

  const isRootGroup = !groupId && groupId !== 'all';

  useEffect(() => {
    updateContextMenu({ selected, selectedGroups });
  }, [selected, group, selectedGroups, activeTab]);

  const updateContextMenu = ({ selected }) => {
    removeAllMenuItems();

    const menuItems = [];

    if (isGroupTab) {
      if (isRootGroup) {
        menuItems.push(
          <EditSelectedGroup
            key={'editSelectedGroup'}
            onClick={() =>
              setActiveModal({
                type: modals.GROUP,
                action: 'edit',
                group: selectedGroups[0]
              })
            }
          />,
          <RemoveSelectedGroups
            key={'removeGroups'}
            entityId={entityId}
            group={group}
          />
        );
      } else {
        // Else it's a sub group
        menuItems.push(
          <MenuItemsGroup key={'menuItemsGroup'}>
            <AddGroup
              key={'addGroup'}
              onClick={() =>
                setActiveModal({
                  type: modals.GROUP,
                  action: 'add',
                  group: group
                })
              }
            />
            <EditGroup
              key={'editGroup'}
              onClick={() =>
                setActiveModal({
                  type: modals.GROUP,
                  action: 'edit',
                  group: group
                })
              }
            />
            <RemoveGroup
              key={'removeGroup'}
              entityId={entityId}
              group={group}
            />
            <ImportPersons
              key={'importPersons'}
              onClick={() => setActiveModal({ type: modals.IMPORT })}
              dataQa={'persons-group-context-import'}
            />
            {user.superadmin && (
              <Button
                menu
                onClick={() => setShowQrModal(true)}
                dataQa={'generate-qr-code'}
                disabled={selected.length <= 0}
                title={
                  selected.length <= 0
                    ? intl.formatMessage(
                        messages.menuItemNoSelectedPersonsTooltip
                      )
                    : null
                }
              >
                <FormattedMessage {...messages.generateQrCode} />
                <Icon id="qr-code" />
              </Button>
            )}
          </MenuItemsGroup>
        );
        menuItems.push(
          <MenuItemsGroup key={'menuItemsGroup2'}>
            <EditSelectedGroup
              key={'editSelectedGroup'}
              onClick={() =>
                setActiveModal({
                  type: modals.GROUP,
                  action: 'edit',
                  group: selectedGroups[0]
                })
              }
            />
            <RemoveSelectedGroups
              key={'removeGroups'}
              entityId={entityId}
              group={group}
            />
          </MenuItemsGroup>
        );
        menuItems.push(
          <CopyPersons
            key={'copyPersons'}
            onClick={() =>
              setActiveModal({ type: modals.COPY, action: PERSON_ACTION.COPY })
            }
          />,
          <MovePersons
            key={'movePersons'}
            onClick={() =>
              setActiveModal({
                type: modals.COPY,
                action: PERSON_ACTION.MOVE
              })
            }
          />,
          <RemovePersonsFromGroup
            key={'removepersons'}
            entityId={entityId}
            group={group}
          />,
          <InviteSporters key={'invitePersons'} entityId={entityId} />
        );
      }
    }
    if (isAthletesTab) {
      menuItems.push(
        <MenuItemsGroup key={'menuItemsGroup'}>
          <ImportPersons
            key={'importPersons'}
            onClick={() =>
              setActiveModal({ type: modals.IMPORT, action: 'add' })
            }
            dataQa={'persons-pool-context-import'}
          />
        </MenuItemsGroup>,
        <CopyPersonsFromPool
          key={'copySporters'}
          onClick={() =>
            setActiveModal({
              type: modals.COPY,
              action: PERSON_ACTION.COPY_POOL,
              selected
            })
          }
        />,
        <RemovePersonsFromOrg key={'removeSporters'} entityId={entityId} />,
        <InviteSporters key={'inviteSporters'} entityId={entityId} />
      );
    }

    addMenuItems(menuItems);
  };

  const onImportModalStateChange = state => {
    setModalSize(state !== IMPORT_SPORTERS_STATE.RESULT);
  };

  const onCloseModal = async () => {
    setActiveModal({ type: null, action: null });
  };

  const onDoneModal = async (entityIds, navigate) => {
    clearSelected();

    setActiveModal({ type: null, action: null });
    if (entityIds && entityIds?.length > 0) {
      for await (const id of entityIds) {
        await fetchEntities(id);
      }
    }

    if (navigate) {
      push(navigate);
    }
  };

  return (
    <>
      <RefactoredPanelHeader>
        <RefactoredPanelTitleWrapper>
          <PanelTitle>{panelTitle.title}</PanelTitle>
          <PanelSubtitle>{panelTitle.subTitle}</PanelSubtitle>
        </RefactoredPanelTitleWrapper>

        <PanelTitleButtonsGroup>
          <>
            {isAthletesTab && canCreateAthletes && (
              <ButtonExpandable
                tabIndex={activeTab}
                onClick={() =>
                  setActiveModal({ type: modals.PERSON, action: 'add' })
                }
                label={intl.formatMessage(messages.buttonSportersAddSporter)}
              />
            )}

            {isRootGroup && isGroupTab && canCreateGroups && (
              <ButtonExpandable
                tabIndex={activeTab}
                onClick={() =>
                  setActiveModal({ type: modals.GROUP, action: 'add' })
                }
                label={intl.formatMessage(messages.addGroupButton, {
                  root: isRootGroup && isGroupTab
                })}
              />
            )}

            {!isRootGroup && isGroupTab && canManageAthletes && (
              <ButtonExpandable
                tabIndex={activeTab}
                onClick={() => setActiveModal({ type: modals.ADD_PERSON })}
                label={intl.formatMessage(messages.addPersonsButton)}
              />
            )}
          </>
          <MenuWrapper trigger={<PanelTitleButton />}>{menuItems}</MenuWrapper>
        </PanelTitleButtonsGroup>
      </RefactoredPanelHeader>

      {/* Modals */}

      {activeModal.type === modals.PERSON && (
        <CreatePersonModal onClose={onCloseModal} onDone={onDoneModal} />
      )}
      {activeModal.type === modals.ADD_PERSON && (
        <EntityStoreContext.Provider value={entityModalStore}>
          <AddPersonsModal
            entityId={entityId}
            group={group}
            onClose={onCloseModal}
            onDone={onDoneModal}
          />
        </EntityStoreContext.Provider>
      )}
      {activeModal.type === modals.GROUP && (
        <GroupModal
          entityId={entityId}
          type={activeModal.action}
          group={
            activeModal.action === 'edit'
              ? activeModal.group
              : new Group({ parentId: groupId })
          }
          parentGroup={group}
          inGroup={group.id !== entityId}
          onClose={onCloseModal}
          onDone={onDoneModal}
        />
      )}
      {activeModal.type === modals.COPY && (
        <EntityStoreContext.Provider value={entityModalStore}>
          <CopyPersonsModal
            oldEntityId={groupId === 'all' ? entityId : groupId}
            selected={selected}
            excludedGroups={[groupId]}
            onClose={onCloseModal}
            onDone={onDoneModal}
            action={activeModal.action}
          />
        </EntityStoreContext.Provider>
      )}
      {activeModal.type === modals.IMPORT && (
        <Modal
          importSporters={!modalSize}
          cardSmall={modalSize}
          autoHeight={modalSize}
          isOpen={true}
          onClose={onCloseModal}
        >
          <ImportSportersCard
            rootEntityId={user.rootEntityId}
            entityId={groupId === 'all' ? entityId : groupId}
            entityName={group.name}
            onClose={reload => onCloseModal(reload)}
            onStateChange={onImportModalStateChange}
          />
        </Modal>
      )}
      {showQrModal && (
        <GenerateQRCodeModal
          onClose={() => setShowQrModal(false)}
          persons={selected}
          type="scanner"
          entityId={entityId}
        />
      )}
    </>
  );
};

export default OverviewHeader;

/*
 * Menu items
 */

const AddGroup = ({ onClick, isRootGroup, isGroupTab }) => {
  const ability = useAbility(AbilityContext);
  if (ability.cannot('create', 'Groups')) {
    return null;
  }
  return (
    <MenuItemsGroupItem>
      <Button menu onClick={onClick} dataQa={'add-group'}>
        <Icon id="add-group" />
        <FormattedMessage
          {...messages.addGroupButton}
          values={{
            root: isRootGroup && isGroupTab
          }}
        />
      </Button>
    </MenuItemsGroupItem>
  );
};

const EditGroup = ({ onClick }) => {
  return (
    <MenuItemsGroupItem>
      <Button menu onClick={onClick} dataQa={'edit-group'}>
        <Icon id="edit" />
        <FormattedMessage {...messages.menuItemSporterEditGroup} />
      </Button>
    </MenuItemsGroupItem>
  );
};

const EditSelectedGroup = ({ onClick }) => {
  const intl = useIntl();
  const selectedGroups = useEntityContext(s => s.selectedGroups);
  return (
    <Button
      disabled={selectedGroups.length !== 1}
      title={
        selectedGroups.length !== 1
          ? intl.formatMessage(messages.menuItemNoSelectedGroupTooltip)
          : null
      }
      menu
      onClick={onClick}
    >
      <Icon id="edit" />
      <FormattedMessage
        {...messages.menuItemSporterEditSelectedGroup}
        values={{
          count: 1
        }}
      />
    </Button>
  );
};

const RemoveSelectedGroups = ({ entityId }) => {
  const intl = useIntl();
  const selectedGroups = useEntityContext(s => s.selectedGroups);
  return (
    <RemoveGroupsButton
      disabled={selectedGroups.length <= 0}
      title={
        selectedGroups.length <= 0
          ? intl.formatMessage(messages.menuItemNoSelectedGroupTooltip)
          : null
      }
      menu
      entityId={entityId}
      groups={selectedGroups}
    >
      <FormattedMessage
        {...messages.menuItemSporterRemoveSelectedGroup}
        values={{
          count: selectedGroups.length === 0 ? 1 : selectedGroups.length
        }}
      />
    </RemoveGroupsButton>
  );
};

const RemoveGroup = ({ entityId, group }) => {
  return (
    <MenuItemsGroupItem>
      <RemoveGroupButton
        key={'removeGroup'}
        menu
        entityId={entityId}
        group={group}
        parentId={group.parentId}
      >
        <FormattedMessage {...messages.menuItemSporterRemoveGroup} />
      </RemoveGroupButton>
    </MenuItemsGroupItem>
  );
};

const ImportPersons = ({ onClick, dataQa }) => {
  return (
    <MenuItemsGroupItem>
      <Button key={'importPersons'} menu onClick={onClick} dataQa={dataQa}>
        <FormattedMessage {...messages.buttonSportersImportSporters} />
        <Icon id="add-user" />
      </Button>
    </MenuItemsGroupItem>
  );
};

const CopyPersons = ({ onClick }) => {
  const intl = useIntl();
  const selected = useEntityContext(s => s.selectedPersons);
  return (
    <Button
      disabled={selected.length <= 0}
      title={
        selected.length <= 0
          ? intl.formatMessage(messages.menuItemNoSelectedPersonsTooltip)
          : null
      }
      menu
      onClick={onClick}
    >
      <FormattedMessage
        {...messages.buttonSportersCopySporters}
        values={{
          count: selected.length === 0 ? 1 : selected.length
        }}
      />
      <Icon id="copy" />
    </Button>
  );
};

const CopyPersonsFromPool = ({ onClick }) => {
  const intl = useIntl();
  const selected = useEntityContext(s => s.selectedPersons);
  return (
    <Button
      disabled={selected.length <= 0}
      title={
        selected.length <= 0
          ? intl.formatMessage(messages.menuItemNoSelectedPersonsTooltip)
          : null
      }
      menu
      onClick={onClick}
    >
      <FormattedMessage
        {...messages.buttonSportersCopySportersFromPool}
        values={{
          count: selected.length === 0 ? 1 : selected.length
        }}
      />
      <Icon id="copy" />
    </Button>
  );
};

const MovePersons = ({ onClick }) => {
  const intl = useIntl();
  const selected = useEntityContext(s => s.selectedPersons);
  return (
    <Button
      disabled={selected.length <= 0}
      title={
        selected.length <= 0
          ? intl.formatMessage(messages.menuItemNoSelectedPersonsTooltip)
          : null
      }
      menu
      onClick={onClick}
    >
      <FormattedMessage
        {...messages.buttonSportersMoveSporters}
        values={{
          count: selected.length === 0 ? 1 : selected.length
        }}
      />
      <Icon id="move" />
    </Button>
  );
};

const RemovePersonsFromGroup = ({ entityId, group }) => {
  const intl = useIntl();
  const selected = useEntityContext(s => s.selectedPersons);
  const clearSelected = useEntityContext(s => s.clearSelected);

  const onPersonsRemovedFromGroup = async () => {
    clearSelected();
  };

  return (
    <RemovePersonsFromGroupButton
      disabled={selected.length <= 0}
      title={
        selected.length <= 0
          ? intl.formatMessage(messages.menuItemNoSelectedPersonsTooltip)
          : null
      }
      entityId={group.id}
      rootEntityId={entityId}
      persons={selected}
      menu
      group={group.name}
      onComplete={() => onPersonsRemovedFromGroup(group.id)}
    >
      <FormattedMessage
        {...messages.menuItemSporterArchiveSporters}
        values={{
          count: selected.length === 0 ? 1 : selected.length
        }}
      />
    </RemovePersonsFromGroupButton>
  );
};

const InviteSporters = ({ entityId }) => {
  const intl = useIntl();
  const selected = useEntityContext(s => s.selectedPersons);
  return (
    <InviteSportersButton
      disabled={selected.length <= 0}
      title={
        selected.length <= 0
          ? intl.formatMessage(messages.menuItemNoSelectedPersonsTooltip)
          : null
      }
      menu
      entityId={entityId}
      persons={selected}
    >
      <FormattedMessage
        {...messages.buttonSportersInvite}
        values={{
          count: selected.length === 0 ? 1 : selected.length
        }}
      />
    </InviteSportersButton>
  );
};

const RemovePersonsFromOrg = ({ entityId }) => {
  const intl = useIntl();
  const { replace } = useHistory();
  const selected = useEntityContext(s => s.selectedPersons);
  const clearSelected = useEntityContext(s => s.clearSelected);

  const onPersonsRemovedFromOrg = useCallback(async () => {
    /*await getPool({
      variables: { entityId },
      fetchPolicy: 'network-only'
    });*/
    clearSelected();
    replace(
      generatePath(ROUTE_SPORTERS_ENTITY_POOL, { entityId, groupId: 'all' })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSelected]);

  return (
    <RemovePersonsFromOrgButton
      disabled={selected.length <= 0}
      title={
        selected.length <= 0
          ? intl.formatMessage(messages.menuItemNoSelectedPersonsTooltip)
          : null
      }
      entityId={entityId}
      persons={selected}
      menu
      onComplete={onPersonsRemovedFromOrg}
    >
      <FormattedMessage
        {...messages.menuItemSporterRemoveSporters}
        values={{
          count: selected.length === 0 ? 1 : selected.length
        }}
      />
    </RemovePersonsFromOrgButton>
  );
};
