import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { createLogin, MUTATE_UPDATE_USER_ROLES } from 'services/aws/user-query';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';
import { PersonEntityRoles } from 'constants.js';
import { useIntl } from 'react-intl';
import { useNotificationQueue } from 'components/notification';
import useFetchAndFilterPersonsPool from 'hooks/queries/useFetchAndFilterPersonsPool';
import usePerson from 'hooks/queries/usePerson';
import { StoreContext } from 'index';
import useFetchAndFilterEntities from 'hooks/queries/useFetchAndFilterEntities';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import useFetchAndFilterUsers from 'hooks/queries/useFetchAndFilterUsers';

const InviteSporterButton = ({
  person,
  isUser = false,
  entityId,
  target,
  redirect,
  children,
  extraClassNames,
  title = null
}) => {
  const { uiState } = useContext(StoreContext);
  const { push } = useHistory();
  const intl = useIntl();
  const notification = useNotificationQueue();
  const timeout = useRef();

  const { fetchPool } = useFetchAndFilterPersonsPool({ entityId });
  const { fetchEntities } = useFetchAndFilterEntities({ entityId });
  const { fetchUsers } = useFetchAndFilterUsers({ entityId });
  const group = useEntityContext(s => s.group);
  const { getPerson } = usePerson({ personId: person.id });

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
      uiState.decreasePendingRequest();
    };
  }, []);

  return (
    <ConfirmMutationButton
      title={title}
      small
      light
      extraClassNames={extraClassNames}
      confirmTitle={messages.modalConfirmInviteSporterTitle}
      confirmMessage={messages.modalConfirmInviteSporterMessage}
      confirmAcceptLabel={messages.modalConfirmInviteAcceptButton}
      mutation={MUTATE_UPDATE_USER_ROLES}
      variables={{
        personId: person.id,
        entityId,
        roles: [PersonEntityRoles.ROLE_ATHLETE]
      }}
      update={(cache, { data: { updatePersonEntityRoles } }) => {
        if (updatePersonEntityRoles) {
          createLogin(person.id, entityId).then(() => {
            uiState.increasePendingRequest();
            timeout.current = setTimeout(async () => {
              if (!isUser) {
                await fetchPool();
                if (group.id) {
                  await fetchEntities(group.id);
                }
              } else {
                await fetchUsers(entityId);
              }
              await getPerson();
              uiState.decreasePendingRequest();
              notification.add(`personInvite_${person.id}`, {
                message: intl.formatMessage(messages.messageUserInvited)
              });

              if (redirect) {
                push(redirect);
                return;
              }
              push(target);
            }, 100); // Delay from the backend
          });
        }
      }}
    >
      {children}
    </ConfirmMutationButton>
  );
};

export default InviteSporterButton;
