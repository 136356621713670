import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Button } from 'components/button';
import { useStore } from 'stores/Store';
import { StoreContext } from 'index';

function AddExercisesToCollectionButton({ onAdd }) {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const selectedExercises = useStore(state => state.selectedExercises);
  return (
    <Button menu onClick={onAdd} disabled={selectedExercises.length <= 0}>
      {selectedExercises.length > 0 ? (
        <>
          <Icon
            id={user.isGSVOrg ? 'bookmark-outline' : 'template'}
            strokeColor="color-neutral-dark"
            style={{ top: 'auto' }}
          />

          <FormattedMessage
            {...messages.collectionAddExercisesToTemplateButton}
            values={{
              thisMany: selectedExercises.length,
              type: user.isGSVOrg ? 'collection' : 'template'
            }}
          />
        </>
      ) : (
        <>
          <Icon id="add" style={{ top: 'auto', opacity: 0.35 }} />

          <FormattedMessage
            {...messages.collectionAddExercisesToTemplateDisabled}
            values={{
              type: user.isGSVOrg ? 'collection' : 'template'
            }}
          />
        </>
      )}
    </Button>
  );
}

export default AddExercisesToCollectionButton;
