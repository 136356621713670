import gql from 'graphql-tag';
import { getGQLResult } from 'services/aws/utils/get';
import { appSyncClient } from './app-sync';

export const QUERY_GET_EXERCISES = gql`
  query getExercises(
    $entityId: ID!
    $titleContains: String
    $tags: [ID]
    $inheritFromParent: Boolean
    $limit: Int
    $nextToken: String
  ) {
    getExercises(
      entityId: $entityId
      titleContains: $titleContains
      tags: $tags
      inheritFromParent: $inheritFromParent
      limit: $limit
      nextToken: $nextToken
    ) {
      id
      parentId
      title
      level
      templateCount
      nextToken
      note
    }
  }
`;

export const QUERY_GET_EXERCISES_BY_PERSON = gql`
  query getExercisesByPersonId($entityId: ID!, $personId: ID) {
    getExercisesByPersonId(entityId: $entityId, personId: $personId) {
      id
      parentId
      title
      note
      remarks
      cues
      protocol
      level
      media {
        id
        signedDownloadUrl
        linkId
        meta
        status
      }
      tags {
        id
        label
      }
      editable
      templateCount
      access
    }
  }
`;

export const QUERY_GET_EXERCISES_SHARED_WITH = gql`
  query getExercisesSharedWith($personId: ID) {
    getExercisesSharedWith(personId: $personId) {
      id
      parentId
      title
      note
      remarks
      cues
      protocol
      level
      media {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      tags {
        id
        label
      }
      editable
      templateCount
      access
      person {
        id
        firstname
        lastname
      }
    }
  }
`;

export const QUERY_GET_EXERCISES_BY_TAGS = gql`
  query getExercisesByTags($entityId: ID!, $tags: [ID]!) {
    getExercisesByTags(entityId: $entityId, tags: $tags) {
      id
      parentId
      title
      note
      remarks
      cues
      protocol
      level
      tags {
        id
        label
      }
      editable
    }
  }
`;

export const QUERY_GET_EXERCISE = gql`
  query getExercise($id: ID!, $entityId: ID) {
    getExercise(exerciseId: $id, entityId: $entityId) {
      id
      parentId
      title
      cues
      protocol
      note
      remarks
      level
      entity {
        id
        name
        info
      }
      media {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      tags {
        id
        label
      }
      meta
      editable
      templateCount
      access
      date_created
      person {
        id
        firstname
        lastname
      }
    }
  }
`;

export const QUERY_GET_EXERCISE_NOTES = gql`
  query getExerciseNotes(
    $exerciseId: ID
    $programId: ID
    $templateId: ID
    $personId: ID
  ) {
    getExerciseNotes(
      exerciseId: $exerciseId
      programId: $programId
      templateId: $templateId
      personId: $personId
    ) {
      id
      linkId
      linkId2
      linkId3

      note
      noteType

      meta

      date_created
    }
  }
`;

/*export const QUERY_GET_EXERCISE_MEDIA = gql`
  query getExercise($id: ID!, $entityId: ID) {
    getExercise(exerciseId: $id, entityId: $entityId) {
      id
      parentId
      media {
        id
        filename
        signedDownloadUrl
        linkId
        status
        meta
        type
      }
      meta
    }
  }
`;*/

export const MUTATION_ADD_EXERCISE = gql`
  mutation addExercise(
    $entityId: ID!
    $title: String!
    $note: String
    $remarks: String
    $cues: [String]
    $protocol: String
    $level: Int
    $thumbFileId: ID
    $videoFileId: ID
    $tagIds: [ID]
  ) {
    addExercise(
      input: {
        entityId: $entityId
        title: $title
        note: $note
        remarks: $remarks
        cues: $cues
        protocol: $protocol
        level: $level
        thumbFileId: $thumbFileId
        videoFileId: $videoFileId
        tagIds: $tagIds
      }
    ) {
      id
      parentId
    }
  }
`;

export const MUTATION_EDIT_EXERCISE = gql`
  mutation editExercise(
    $id: ID!
    $entityId: ID!
    $title: String!
    $note: String
    $remarks: String
    $cues: [String]
    $protocol: String
    $level: Int
    $thumbFileId: ID
    $videoFileId: ID
    $tagIds: [ID]
    $meta: AWSJSON
  ) {
    editExercise(
      input: {
        id: $id
        entityId: $entityId
        title: $title
        note: $note
        remarks: $remarks
        cues: $cues
        protocol: $protocol
        level: $level
        thumbFileId: $thumbFileId
        videoFileId: $videoFileId
        tagIds: $tagIds
        meta: $meta
      }
    ) {
      id
      parentId
    }
  }
`;

export const MUTATION_ARCHIVE_EXERCISE = gql`
  mutation archiveExercise($exerciseId: ID!) {
    archiveExercise(exerciseId: $exerciseId)
  }
`;

export const downloadExerciseProgram = async programId => {
  return await getGQLResult(appSyncClient, QUERY_DOWNLOAD_EXERCISE_PROGRAM, {
    programId
  });
};

export const QUERY_DOWNLOAD_EXERCISE_PROGRAM = gql`
  query downloadExerciseProgram($programId: ID!) {
    downloadExerciseProgram(programId: $programId)
  }
`;

export const downloadExerciseProgramTemplate = async templateId => {
  return await getGQLResult(
    appSyncClient,
    QUERY_DOWNLOAD_EXERCISE_PROGRAM_TEMPLATE,
    {
      templateId
    }
  );
};

export const QUERY_DOWNLOAD_EXERCISE_PROGRAM_TEMPLATE = gql`
  query downloadExerciseProgramTemplate($templateId: ID!) {
    downloadExerciseProgramTemplate(templateId: $templateId)
  }
`;

export const QUERY_GET_EXERCISE_PROGRAM_TEMPLATE_JSON_LINK = gql`
  query getExerciseProgramTemplateJsonLink($templateId: ID!) {
    getExerciseProgramTemplateJsonLink(templateId: $templateId)
  }
`;

export const QUERY_GET_EXERCISE_COPY = gql`
  query getExercise($id: ID!, $entityId: ID) {
    getExercise(exerciseId: $id, entityId: $entityId) {
      id
      copy {
        language

        title
        note
        cues
        protocol

        remarks
      }
      cues
    }
  }
`;

export const MUTATION_ADDEDIT_TRANSLATION_EXERCISE = gql`
  mutation addEditExerciseCopy(
    $exerciseId: ID!
    $language: baseLanguage!
    $title: String!
    $note: String
    $remarks: String
    $cues: [String]
    $protocol: String
  ) {
    addEditExerciseCopy(
      input: {
        exerciseId: $exerciseId
        language: $language
        title: $title
        note: $note
        remarks: $remarks
        cues: $cues
        protocol: $protocol
      }
    ) {
      id
      parentId
    }
  }
`;
