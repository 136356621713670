import React, { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
  restrictToFirstScrollableAncestor
} from '@dnd-kit/modifiers';

const SortableList = ({ children, onSort, ...props }) => {
  const [items, setItems] = useState(props.items);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const handleDragEnd = e => {
    const { active, over } = e;
    if (active.id !== over.id) {
      const oldIndex = items.findIndex(f => f.id === active.id);
      const newIndex = items.findIndex(f => f.id === over.id);
      const sorted = arrayMove(items, oldIndex, newIndex);

      setItems(sorted);

      onSort && onSort(sorted.map(f => f.id));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[
        restrictToFirstScrollableAncestor,
        restrictToVerticalAxis,
        restrictToWindowEdges
      ]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map(i => i.id)}
        strategy={verticalListSortingStrategy}
      >
        {children({ items })}
        {/*<DragOverlay></DragOverlay>*/}
      </SortableContext>
    </DndContext>
  );
};

export default SortableList;
