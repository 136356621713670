import React from 'react';
import classNames from 'classnames';

interface RefactoredTableHeaderProps {
  children?: React.ReactNode;
  sticky?: boolean;
  extraClassNames?: string;
}

const RefactoredTableHeader = ({
  children,
  extraClassNames,
  sticky
}: RefactoredTableHeaderProps) => {
  return (
    <thead
      className={classNames('r-table__header', extraClassNames, {
        'r-table__header--sticky': sticky
      })}
    >
      {children}
    </thead>
  );
};

export default RefactoredTableHeader;
