import { createNotificationContext } from 'react-notification-provider';

interface Position {
  x: number;
  y: number;
}
// // You can customize the notification interface to include whatever props your notifications need to render.
interface Notification {
  message: string;
  duration?: number;
  level?: 'success' | 'error';
  position?: Position;
  closeable?: boolean;
}

// This function creates a React context and hooks for you so you'll want to export these.
const { NotificationProvider, useNotificationQueue } =
  createNotificationContext();
//   createNotificationContext<Notification>();

export { NotificationProvider, useNotificationQueue };
