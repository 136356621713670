import classNames from 'classnames';
import Icon from '../../icon/Icon';
import Checkbox from '../../input/Checkbox';

const ListItemStatus = props => {
  const {
    statusIcon,
    children,
    // Used once: add new (list-) item.
    iconPropagateOnClick,
    // Checkbox related props
    itemId,
    indeterminate,
    defaultChecked,
    visible,
    checked,
    onChange,
    readOnly = false,
    disabled = false,
    // Icon related props
    mediumIcon,
    fillColor,
    strokeColor,
    extraClassNamesIcon
  } = props;

  const onClickHandler = e => {
    if (!iconPropagateOnClick) {
      e.stopPropagation();
    }
    /*const { onChange } = this.props;
    if (onChange) onChange(e);*/
  };

  return (
    <div
      className={classNames('c-list__item-icon', {
        'c-list__item-icon-click-propagated': iconPropagateOnClick
      })}
      onClick={e => onClickHandler(e)}
    >
      <Checkbox
        id={itemId}
        inListStatus
        defaultChecked={defaultChecked}
        visible={visible}
        checked={checked ?? ''}
        readOnly={readOnly}
        disabled={disabled}
        indeterminate={indeterminate}
        onChange={e => {
          if (!(disabled || readOnly)) {
            onChange(e.target.checked);
          }
        }}
      />

      {statusIcon ? (
        <Icon
          id={statusIcon}
          fillColor={fillColor ? fillColor : 'color-neutral-x-light'}
          strokeColor={strokeColor ? strokeColor : ''}
          extraClassNames={classNames('', extraClassNamesIcon, {
            'c-icon--smalled':
              statusIcon === 'calibrate' ||
              statusIcon === 'scanner' ||
              mediumIcon
          })}
        />
      ) : (
        <span className={classNames('c-list__initials')}>{children}</span>
      )}
    </div>
  );
};

export default ListItemStatus;
