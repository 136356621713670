import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  withRouter,
  Redirect,
  Route,
  Switch,
  generatePath
} from 'react-router-dom';
import {
  ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION,
  ROUTE_SESSIONS_ENTITY,
  ROUTE_SPORTERS_ENTITY
} from 'routes/RouteList';
import { SessionContextProvider } from 'contexts/SessionContext';
import EditPrevention from './EditPrevention';
import { StoreContext } from 'index';
import { TestsProvider } from 'contexts/TestsContext';
import { AbilityContext } from 'Can';
import { useAbility } from '@casl/react';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import NoAccessPanel from 'containers/pages/auth/NoAccessPanel';
import { useIntl } from 'react-intl';
import messages from 'messages';
import {
  createEntityStore,
  EntityStoreProvider
} from 'containers/pages/persons/store/EntityStoreContext';

const PreventionIndex = () => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const ability = useAbility(AbilityContext);
  const entityStore = useRef(createEntityStore()).current;

  if (ability.cannot('read', 'Prevention')) {
    return (
      <NoAccessPanel
        entityId={user.rootEntityId}
        emptyLabel={intl.formatMessage(messages.noAccessEmptyPanel)}
        redirectRoute={generatePath(ROUTE_SPORTERS_ENTITY, {
          entityId: user.rootEntityId
        })}
      />
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION}
        render={props => {
          if (ability.cannot('read', 'Prevention'))
            return <Redirect to={`${ROUTE_SESSIONS_ENTITY}`} />;

          return (
            <SessionContextProvider>
              {/*<GroupsContextProvider entityId={user.rootEntityId}>*/}
              <EntityStoreProvider value={entityStore}>
                <TestsProvider entityId={props.match.params.entityId}>
                  <PreviewModalProvider entityId={props.match.params.entityId}>
                    <EditPrevention
                      entityId={props.match.params.entityId}
                      sessionId={props.match.params.sessionId}
                      active={
                        ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION ===
                        props.match.path
                      }
                    />
                  </PreviewModalProvider>
                </TestsProvider>
              </EntityStoreProvider>
              {/*</GroupsContextProvider>*/}
            </SessionContextProvider>
          );
        }}
      />
      <Redirect
        from="/prevention/:entityId/:sessionId"
        to="/sessions/:entityId/:sessionId"
      />
      <Redirect from="/prevention/:entityId" to="/sessions/:entityId" />
    </Switch>
  );
};

export default withRouter(observer(PreventionIndex));
