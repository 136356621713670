import React, { Fragment, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemLabelWrapper,
  ListItemLabel,
  ListItemOrder
} from './list-item';
import ProgressBar from '../progress-bar/ProgressBar';
import ListBody from './ListBody';
import List from './List';
import { Button } from '../button';
import Icon from '../icon/Icon';
import { round } from 'utils/math';
import { getTotalBestScoreString } from 'utils/calculation';
import Badge from 'components/badge/Badge';
import { getAppLocale } from 'utils/locale';
import { StoreContext } from 'index';

const renderResult = data => (
  <>
    {data.unit ? (
      <ListItemLabel label>
        {data.values && data.values[0] !== null && (
          <>
            {roundSecondsOrMinutes(data.values, data.unit)}
            {data.unit.toLowerCase() === 'minutes'
              ? ` mins`
              : data.unit !== '#' && data.unit.toLowerCase() !== 'none'
                ? ` ${data.unit}`
                : ``}
            {}
          </>
        )}
      </ListItemLabel>
    ) : data.result !== undefined ? (
      <ListItemLabel progress>
        <ProgressBar value={Number(round(data.result, 0))}>
          {round(data.result, 0)}
        </ProgressBar>
      </ListItemLabel>
    ) : (
      <ListItemLabel label>{data.value}</ListItemLabel>
    )}
  </>
);

const roundSecondsOrMinutes = (value, unit) => {
  const values = value.map(v => {
    // v = parseFloat(v.toFixed(2));
    if (unit === 'min' || unit === 's') {
      return ` ${Math.round(v * 100) / 100}`;
    }
    if (Number.isInteger(v)) {
      return ` ${v}`;
    } else {
      try {
        return ` ${v.toFixed(2)}`;
      } catch (error) {
        return ` ${v}`;
      }
    }
    // return v;//.toFixed(2);
  });
  values[0] = values[0].trim();

  return values.filter(v => v.trim() !== '').toString();
};

const ResultList = ({
  items = [],
  defaultVisibleItems = 10,
  labelShowMore = 'show More',
  labelShowLess = 'show Less',
  children,
  editable,
  itemEdit,
  onEdit,
  showTotalBest,
  bestScoreRealTime
}) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);
  const [showAll, setShowAll] = useState(false);

  const getTotalBestScore = result => {
    return bestScoreRealTime !== null &&
      bestScoreRealTime !== undefined &&
      editable &&
      itemEdit &&
      itemEdit.name === result.name
      ? bestScoreRealTime
      : getTotalBestScoreString(result);
  };

  return (
    <List>
      <ListBody>
        {items.map((result, i) => (
          <Fragment key={i}>
            {(showAll || i < defaultVisibleItems) && (
              <ListItem
                small
                smallEditable={editable}
                smallEditableOpen={
                  editable && itemEdit && itemEdit.name === result.name
                }
              >
                <ListItemOrder>{i + 1}</ListItemOrder>
                <ListItemLabelWrapper>
                  <ListItemLabel label>
                    {result.name ||
                      result.title?.[getAppLocale(locale)] ||
                      result.title?.code}
                    {/* TODO: is dit gebruikt? */}
                    {result.score > 0 && (
                      <Badge>
                        {showTotalBest ? getTotalBestScore(result) : null}
                      </Badge>
                    )}
                  </ListItemLabel>
                  {(!itemEdit || itemEdit.name !== result.name) &&
                    renderResult(result)}
                </ListItemLabelWrapper>
                {editable && (!itemEdit || itemEdit.name !== result.name) && (
                  <Button tiny onClick={() => onEdit(result)}>
                    <Icon id="edit" />
                  </Button>
                )}
                {itemEdit && itemEdit.name === result.name && children}
              </ListItem>
            )}
          </Fragment>
        ))}
      </ListBody>

      {items.length > defaultVisibleItems && (
        <Button rounded grey onClick={() => setShowAll(!showAll)}>
          <Icon id={!showAll && 'dropdown-open'} />
          {showAll ? labelShowLess : labelShowMore}
        </Button>
      )}
    </List>
  );
};

export default ResultList;

ResultList.propTypes = {
  defaultVisibleItems: PropTypes.number,
  /*items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        result: PropTypes.number.isRequired
      }),
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        values: PropTypes.arrayOf(PropTypes.number),
        unit: PropTypes.string.isRequired
      })
    ])
  ).isRequired,*/
  labelShowMore: PropTypes.string,
  labelShowLess: PropTypes.string
};
