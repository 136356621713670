import { useEffect } from 'react';
import { sort } from 'utils/sort';
import { getLocalDateFormat } from 'utils/date';
import { useSetState } from 'react-use';
import TestSet from 'models/TestSet';

const useTestsetSearch = ({ key = 'title' }) => {
  const [state, setState] = useSetState({
    search: '',
    sort: null,
    items: [],
    filteredItems: [],
    filteredCount: 0
  });

  useEffect(() => {
    const filteredItems = !state.search
      ? state.items
      : state.items.filter(i => {
          return findStringInItems(i, state.search);
        });

    const otherItems = state.items.filter(
      item => !filteredItems.some(filteredItem => filteredItem.id === item.id)
    );

    const sortedFilteredItems = state.sort
      ? sort(filteredItems, {
          keys: [state.sort]
        })
      : filteredItems;
    const sortedOtherItems = state.sort
      ? sort(otherItems, {
          keys: [state.sort]
        })
      : otherItems;

    setState({
      filteredItems: [
        ...sortedFilteredItems.map(i => new TestSet({ ...i, filtered: true })),
        ...sortedOtherItems.map(i => new TestSet({ ...i, filtered: false }))
      ],
      filteredCount: sortedFilteredItems.length
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.items, state.search, state.sort]);

  const findStringInItems = (item, str) => {
    const trimmedString = str.toLowerCase().trim();
    const itemName = item[key].toLowerCase().trim();
    if (itemName.indexOf(trimmedString) !== -1) return true;

    if (item?.startdate) {
      const dateName = getLocalDateFormat(item.startdate);
      if (dateName.indexOf(trimmedString) !== -1) return true;
    }
  };

  return [state, setState];
};

export default useTestsetSearch;
