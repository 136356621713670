import React, { forwardRef } from 'react';
import classNames from 'classnames';

import './List.scss';

const List = forwardRef(({ children, style, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames('c-list', props.extraClassNames, {
        'c-list--permissions': props.permissionsList,
        'c-list--dnd': props.dragNDrop,
        'c-list--dnd c-list--dnd-preview': props.dragNDropWithPreview,
        'c-list--dnd-preview': props.preview,
        'c-list--with-dropdown': props.threeColsAndDropdown,
        'c-list--draggable': props.draggable,
        'c-list--draggable c-list--draggable-disabled': props.draggableDisabled,
        'c-list--cue': props.cue,
        'c-list--sublist': props.subList,
        'c-list--virtualized': props.virtualizedList,
        'c-list--on-modal': props.onModal
      })}
      style={style}
    >
      {children}
    </div>
  );
});

export default List;
