import React, {
  cloneElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import { Card, CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { useUIDSeed } from 'react-uid';
import VirtualListItem from 'containers/partials/list-items/VirtualListItem';
import PersonStatusPopper from 'containers/partials/misc/PersonStatusPopper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import { PersonStatus } from 'constants.js';

import useSelectableObject from 'hooks/utils/useSelectableObject';

// TODO deze list mergen met VirtualizedEntityList

const EntityList = ({
  activeId,
  items = [],
  statusLabel,
  checkableGroups,
  checkable,
  showOnlyGroups,
  sortableListHeader,
  onClick,
  onSelect,
  selectedItems,
  disabledItems = [],
  excludedItems = [],
  extraClassNames = {},
  onModal,
  withFixedFooter,
  emptyMessage,
  reCalculateHeight = false,
  variableHeight = false,
  children
}) => {
  const parentRef = useRef();
  const uidSeed = useUIDSeed();
  const { selected, select, setSelected } = useSelectableObject([]);
  const [minHeight, setMinHeight] = useState(null);
  const [minHeightParent, setMinHeightParent] = useState(null);
  const [heightList, setHeightList] = useState(null);

  useEffect(() => {
    setSelected(selectedItems ? selectedItems : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, selectedItems]);

  const onSelectHandler = (item, checked) => {
    if (excludedItems.find(exI => exI.id === item.id)) return;
    select(item);
    onSelect && onSelect(item, checked);
  };

  const checkAndSetHeight = theRef => {
    if (theRef?.current) {
      const roundedTop = theRef.current.getBoundingClientRect().top.toFixed(0);

      setMinHeight(`calc(100dvh - 32px - ${roundedTop}px)`);
      setMinHeightParent(`calc(100dvh - ${roundedTop}px)`);

      //   reCalculateHeight && reCalculate(roundedTop);
    }
  };

  const reCalculate = (top = false) => {
    if (top !== false) {
      setHeightList(`calc(100dvh - ${top}px - 86px)`);
    } else {
      const roundedTop = parentRef?.current
        ?.getBoundingClientRect()
        .top.toFixed(0);

      const footerSpacing = document.querySelector(
        '.c-card__footer-bottom-fixed'
      )
        ? 70
        : 0;
      return `calc(100dvh - ${roundedTop}px - ${footerSpacing}px - 16px)`;
    }
  };

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (parentRef) {
        checkAndSetHeight(parentRef);
        variableHeight && reCalculate();
        clearInterval(interval);
      }
    }, 100);
  }, [parentRef]);

  if (!Array.isArray(items)) return null;

  const groupIsChecked = group => {
    return (
      selectedItems.find(s => s.id === group.id) ||
      (group.clients.length > 0 &&
        group.clients.every(client =>
          new Set([
            ...selected.map(s => s.id),
            ...excludedItems.map(exI => exI.id)
          ]).has(client.id)
        ))
    );
  };

  const isIndeterminateChecked = group => {
    return (
      group.clients.some(
        client =>
          selected.find(p => p.id === client.id) ||
          excludedItems.find(exI => exI.id === client.id)
      ) &&
      !group.clients.every(client =>
        [
          ...selected.map(s => s.id),
          ...excludedItems.map(exI => exI.id)
        ].includes(client.id)
      )
    );
  };

  const personIsChecked = person => {
    return (
      selected.find(p => p.id === person.id) ||
      excludedItems.find(exI => exI.id === person.id)
    );
  };

  if (items.length > 0) {
    return (
      <>
        {sortableListHeader && sortableListHeader}

        <List
          ref={parentRef}
          onModal={onModal}
          style={{
            maxHeight: `${minHeightParent}`,
            height: withFixedFooter ? 'calc(100% - 64px)' : '100%'
          }}
          extraClassNames={extraClassNames}
        >
          <ListBody
            style={{
              minHeight: !onModal && !reCalculateHeight && minHeight,
              height: reCalculate(),
              position: 'relative',
              marginBottom: 0
            }}
          >
            {items.map((item, index) => {
              item.index = index;
              const disabled = disabledItems.find(exI => exI.id === item.id);
              const excluded = excludedItems.find(exI => exI.id === item.id);

              let listData;

              if (item.constructor.modelName === 'Person') {
                listData = {
                  titleLabel: 'fullName',
                  index,
                  statusLabel:
                    statusLabel && typeof statusLabel === 'function'
                      ? statusLabel(item)
                      : statusLabel || item.namePrefix,
                  checkbox: checkable && !excluded,
                  onClick: () => onClick && onClick(item, 'person'),
                  checked: checkable && personIsChecked(item) && !excluded,
                  lastItem: item.index === items.length - 1

                  /*meta:
                  (showPersonStatus && (
                    <MetaWrapper>
                      <PersonStatusIcon item={item} onClick={onClickStatus} />
                    </MetaWrapper>
                  )) ||
                  (excluded && (
                    <MetaWrapper>
                      <Badge variant="dark">
                        <FormattedMessage {...messages.excludedEntityLabel} />
                      </Badge>
                    </MetaWrapper>
                  ))*/
                };
              } else if (item.constructor.modelName === 'Group') {
                listData = {
                  titleLabel: 'name',
                  checkbox: checkableGroups,
                  checked: checkableGroups && groupIsChecked(item),
                  disabled: !checkableGroups,
                  indeterminate: isIndeterminateChecked(item),
                  mediumIcon: false,
                  icon: 'group',
                  onClick: () =>
                    ((onClick &&
                      showOnlyGroups &&
                      item.subEntities.length > 0) ||
                      (onClick && !showOnlyGroups)) &&
                    onClick(item, 'group')
                  /*meta: (
                  <MetaWrapper>
                    <p className="c-list__meta">
                      <FormattedMessage
                        {...messages.modalListItemSportersGroupsCount}
                        values={{
                          athletes: (item.clients && item.clients.length) || 0,
                          groups: item.subEntities.length
                        }}
                      />
                    </p>
                  </MetaWrapper>
                )*/
                };
              }
              if (showOnlyGroups && item.constructor.modelName === 'Person')
                return null;

              if (children) {
                const ChildrenItems = children(item);
                return cloneElement(ChildrenItems, {
                  uid: uidSeed(item.id),
                  key: uidSeed(item.id),
                  item: item,
                  onChange: e => onSelectHandler(item, e),
                  ...listData
                });
              }
              return (
                <VirtualListItem
                  uid={uidSeed(item.id)}
                  item={item}
                  key={uidSeed(item.id)}
                  active={item.id === activeId}
                  statusLabel={statusLabel}
                  clickable={!excluded}
                  outfaded={excluded}
                  // clickable
                  disabled={disabled}
                  // excluded={excluded}
                  onChange={e => onSelectHandler(item, e)}
                  {...listData}
                />
              );
            })}
          </ListBody>
        </List>
      </>
    );
  }

  return (
    <Card centered>
      <CardBody empty>
        <Message emptyState={true} icon="cursor">
          <MessageText>
            <FormattedMessage
              {...(emptyMessage ? emptyMessage : messages.noAthletesOrGroups)}
            />
          </MessageText>
        </Message>
      </CardBody>
    </Card>
  );
};

export default EntityList;
