import { Fragment } from 'react';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import LineChart from '../charts/LineChart';
import InputErrorMessage from 'components/input/InputErrorMessage';
import { Lateralities } from 'constants.js';
import { capitalize } from 'utils/string';

const SidePanelChartCard = ({
  title,
  subTitle = '',
  laterality,
  chartData,
  errorMessage = ''
}) => {
  const lateralitySubtitle =
    laterality && Number(laterality) !== 2
      ? capitalize(Lateralities[laterality])
      : '';

  const subtitleCard = (
    <>
      {subTitle}
      {laterality && Number(laterality) !== 2 && (
        <>- [{<b>{lateralitySubtitle}</b>}]</>
      )}
    </>
  );

  return (
    <Fragment>
      <CardHeader modal>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        <CardHeaderSubtitle>{subtitleCard}</CardHeaderSubtitle>
        <InputErrorMessage>{errorMessage}</InputErrorMessage>
      </CardHeader>
      <div style={{ height: 'calc(100% - 156px)', padding: '8px 24px' }}>
        <LineChart
          chartData={chartData}
          title={title}
          tooltipSubTitle={subtitleCard}
        />
      </div>
    </Fragment>
  );
};

export default SidePanelChartCard;
