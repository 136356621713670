export const createPersonSlice = set => ({
  person: null,
  setPerson: value => set(() => ({ person: value })),

  persons: [],
  setPersons: values => set(() => ({ persons: values })),

  personsRef: [],
  setPersonsRef: values => set(() => ({ personsRef: values }))
});
