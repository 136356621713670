import React, { useContext, useEffect, useState } from 'react';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import Badge from 'components/badge/Badge';
import { StoreContext } from 'index';
import InjuriesDetailCard from 'containers/partials/cards/InjuriesDetailCard';
import InjuriesScreeningsCard from 'containers/partials/cards/InjuriesScreeningsCard';
// import InjuriesReportsCard from 'containers/partials/cards/InjuriesReportsCard';
import { useMedicalContext } from 'contexts/MedicalContext';
import { MenuWrapper } from 'components/menu';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import RemovePersonInjuryButton from 'containers/partials/buttons/RemoveInjuryButton';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_REPORTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT
} from 'routes/RouteList';
import useTabIndex from 'hooks/utils/useTabIndex';
import { getLocalDateFormat } from 'utils/date';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import PersonInjury from 'models/PersonInjury';
import TabLink from 'components/tabs/TabLink';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import usePerson from 'hooks/queries/usePerson';

function Injury() {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const { entityId, groupId, sporterId, medicalId, injuryId } = useParams();

  const { fetchPerson } = usePerson({ personId: sporterId });
  const person = useEntityContext(s => s.person);
  const { medical } = useMedicalContext();
  const [personInjury, setPersonInjury] = useState(new PersonInjury({}));
  const [sides, setSides] = useState({});
  const edit = useRouteMatch(
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT
  )?.isExact;

  const [tabIndex, setTabIndex] = useTabIndex([
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT
    ],
    [
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA
    ],
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_REPORTS
  ]);

  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL
  ]);

  useEffect(() => {
    const fetchPersonData = async () => {
      await fetchPerson(sporterId);
    };
    if(!person.id) {
      fetchPersonData();
    }
  }, [entityId, sporterId]);

  useEffect(() => {
    if (medical?.injuries) {
      const personInjury = medical.injuries.find(i => i.id === injuryId);

      if (personInjury) {
        setPersonInjury(personInjury);
      }

      if (personInjury?.screenings) {
        const sortedScreenings = sort(personInjury.screenings, {
          keys: [{ key: 'startdate', dataType: SORT_DATA_TYPES.DATE_STRING }]
        });
        setPersonInjury(
          new PersonInjury({ ...personInjury, screenings: sortedScreenings })
        );
      }

      if (personInjury?.sides) {
        setSides({
          left:
            (personInjury.sides.hasOwnProperty('left') &&
              personInjury.sides.left) ||
            false,
          right:
            (personInjury.sides.hasOwnProperty('right') &&
              personInjury.sides.right) ||
            false
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medical, injuryId]);

  return (
    <Panel active={active.isExact}>
      {person && personInjury?.injury && (
        <>
          <PanelHeader>
            <Breadcrumbs>
              <Breadcrumb
                to={generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId })}
              >
                <FormattedMessage {...messages.breadcrumbSporters} />
              </Breadcrumb>
              <Breadcrumb
                to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                  entityId,
                  groupId,
                  sporterId
                })}
              >
                {`${person.firstname} ${person.lastname}`}
              </Breadcrumb>
            </Breadcrumbs>
            <PanelTitleWrapper>
              <PanelTitle>
                {personInjury.injury.title}
                {sides &&
                  Object.entries(sides).length > 0 &&
                  Object.entries(sides).some(e => e[1]) && (
                    <Badge
                      lighterFont
                      extraClassNames="u-margin-left-small u-margin-right-auto"
                    >
                      <FormattedMessage
                        {...messages.leftRightBadge}
                        values={{
                          sides: Object.entries(sides).every(e => e[1])
                            ? 'both'
                            : Object.entries(sides).find(e => e[1])[0]
                        }}
                      />
                    </Badge>
                  )}
                {user.canRemovePersonInjury(entityId) && tabIndex === 0 && (
                  <MenuWrapper trigger={<PanelTitleButton />}>
                    <RemovePersonInjuryButton
                      menu
                      entityId={entityId}
                      personId={person.id}
                      groupId={groupId}
                      personInjuryId={personInjury.id}
                    >
                      <FormattedMessage
                        {...messages.rehabMenuItemRemovePersonInjury}
                      />
                    </RemovePersonInjuryButton>
                  </MenuWrapper>
                )}
              </PanelTitle>
              <PanelSubtitle>
                {getLocalDateFormat(personInjury.injuryDate)}
                {personInjury.injuryEndDate && (
                  <>
                    {' - '}
                    {getLocalDateFormat(personInjury.injuryEndDate)}
                  </>
                )}
              </PanelSubtitle>
            </PanelTitleWrapper>
          </PanelHeader>
          <PanelBody>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={index => setTabIndex(index)}
            >
              <TabList>
                <TabLink
                  to={generatePath(
                    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
                    {
                      entityId,
                      groupId,
                      sporterId,
                      medicalId,
                      injuryId
                    }
                  )}
                  key="injuries.detail"
                >
                  <FormattedMessage {...messages.rehabInjuryDetailsTab} />
                </TabLink>
                <TabLink
                  key="injuries.screenings"
                  to={generatePath(
                    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
                    {
                      entityId,
                      groupId,
                      sporterId,
                      medicalId,
                      injuryId
                    }
                  )}
                >
                  {personInjury?.screenings && (
                    <Badge>{personInjury.screenings.length}</Badge>
                  )}
                  <FormattedMessage {...messages.rehabInjuryScreeningsTab} />
                </TabLink>
                <Tab key="injuries.reports" disabled>
                  <FormattedMessage {...messages.rehabInjuryReportsTab} />
                </Tab>
              </TabList>
              <TabPanel key="injuries.detail">
                <TabPanelBody withScroller>
                  <InjuriesDetailCard
                    entityId={entityId}
                    groupId={groupId}
                    personInjury={personInjury}
                    edit={edit}
                    medical={medical}
                    sporterId={sporterId}
                  />
                </TabPanelBody>
              </TabPanel>
              <TabPanel key="injuries.screenings">
                <TabPanelBody withScroller>
                  <InjuriesScreeningsCard
                    entityId={entityId}
                    groupId={groupId}
                    personInjury={personInjury}
                    medical={medical}
                  />
                </TabPanelBody>
              </TabPanel>
              <TabPanel key="injuries.reports">
                <TabPanelBody withScroller>
                  {/* This is Never used, loaded with dummy data */}
                  {/* <InjuriesReportsCard
                    entityId={entityId}
                    groupId={groupId}
                    personInjury={personInjury}
                    medical={medical}
                  /> */}
                </TabPanelBody>
              </TabPanel>
            </Tabs>
          </PanelBody>
        </>
      )}
    </Panel>
  );
}

export default Injury;
