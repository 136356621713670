import { useIntl } from 'react-intl';
import messages from 'messages';
import InputWrapper from 'components/input/InputWrapper';
import { GrowthTrackerEstimationMethods } from 'constants.js';
import FieldSelect from 'components/input/FieldSelect';
import { useGrowthPanelContext } from 'contexts/GrowthPanelContext';

const GrowthSettingsView = () => {
  const intl = useIntl();
  const { onChangeMethod, method } = useGrowthPanelContext();

  const GrowthEstimationOptions = [
    {
      value: GrowthTrackerEstimationMethods.KHAMIS_ROCHE,
      label: intl.formatMessage(
        messages.growthTrackerEstimationMethodKhamisRoche
      )
    }
  ];

  return (
    <>
      <div className="o-layout__item u-1-of-4-at-large u-1-of-3-at-medium u-1-of-2-at-small u-1-of-1">
        <InputWrapper>
          <FieldSelect
            label={intl.formatMessage(
              messages.growthTrackerEstimationMethodOptions
            )}
            value={GrowthEstimationOptions.find(o => o.value === method)}
            options={GrowthEstimationOptions}
            onChange={bt => onChangeMethod(bt.value)}
          />
        </InputWrapper>
      </div>
    </>
  );
};

export default GrowthSettingsView;
