import React from 'react';
import classNames from 'classnames';
import './Badge.scss';

interface BadgeProps {
  children?: React.ReactNode;
  /** There are 2 types */
  variant?: 'dark' | 'completed';
  /** And a larger version: */
  larger?: boolean;
  lighterFont?: boolean;
  extraClassNames?: string;
  style?: object;
}

/** This is a Badge usually used for extra info:
 * - in `<Tabs />` is the default, without props,
 * - as `variant="dark"` (used when a list item is "already added" into something)
 * - as `variant="completed"` (used in a title, when a session is "ended")
 * - and there is also a default `larger` version within the Users' usage view, under Settings
 * - and there is also a default `lighterFont` version used (mostly) in list items.
 * */
const Badge = ({
  larger, // = false,
  lighterFont, // = false
  variant,

  children,
  extraClassNames,
  style
}: BadgeProps) => {
  return (
    <span
      className={classNames('c-badge', extraClassNames, {
        'c-badge--completed': variant === 'completed', // DONE
        'c-badge--dark': variant === 'dark', // DONE
        'c-badge--large': larger, // DONE
        'c-badge--light': lighterFont // DONE
      })}
      style={style}
    >
      {children}
    </span>
  );
};

export default Badge;
