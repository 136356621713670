import React, { forwardRef } from 'react';
import classNames from 'classnames';

const ListBody = forwardRef(
  ({ children, innerRef, style, extraClassNames, ...props }, ref) => {
    // TODO REFACTOR: Check this:
    // ref never used
    // innerRef on `ScreeningCustomDetailCard.js`
    return (
      <ul
        ref={innerRef}
        {...props}
        className={classNames('c-list__body o-list-bare', extraClassNames)}
        style={style}
      >
        {children}
      </ul>
    );
  }
);

export default ListBody;
