import React, { useEffect, useRef } from 'react';
import Modal from 'components/modal/Modal';
import { Button, ButtonsGroup } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import FieldInput from 'components/input/FieldInput';
import copy from 'copy-to-clipboard';
import { useNotificationQueue } from 'components/notification';
import {
  MUTATION_GENERATE_MAGIC_LINK,
  updateUserMutation
} from 'services/aws/user-query';
import { useMutation } from '@apollo/client';
import Loader from 'components/loader/Loader';
import { PersonEntityRoles } from 'constants.js';

const GenerateMagicURLModal = ({
  entityId,
  person,
  onClose,
  destiny = null
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();
  const inputRef = useRef(null);

  const [generateMagicLink, { loading }] = useMutation(
    MUTATION_GENERATE_MAGIC_LINK
  );

  useEffect(() => {
    const fetchMagicLink = async () => {
      if (!person.hasRole(PersonEntityRoles.ROLE_ATHLETE)) {
        await updateUserMutation({
          variables: {
            personId: person.id,
            entityId,
            roles: [PersonEntityRoles.ROLE_ATHLETE]
          },
          refetchQueries: ['getPerson']
        });
      }

      const magicLink = await generateMagicLink({
        variables: { personId: person.id }
      });
      if (magicLink?.data?.createMagicLink) {
        const link = magicLink.data.createMagicLink;
        if (destiny) {
          inputRef.current.value = `${link}&redirect=${destiny}`;
        } else {
          inputRef.current.value = link;
        }
      }
    };
    fetchMagicLink();
  }, []);

  const onCopyToClipboard = () => {
    copy(inputRef.current.value);
    const position = {
      y: inputRef?.current.getBoundingClientRect().top + 40,
      x: inputRef?.current.getBoundingClientRect().right
    };

    notification.add('link-copied', {
      message: intl.formatMessage(messages.modalGenerateMagicURLCopied),
      position: position,
      closeable: true
    });

    setTimeout(() => {
      if (onClose) onClose();
    }, 4000);
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card autoHeight isOpen={true} onClose={onCloseHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {intl.formatMessage(messages.modalGenerateMagicURLTitle)}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {intl.formatMessage(messages.modalGenerateMagicURLSubTitle)}
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal>
        {loading && <Loader />}
        <FieldInput ref={inputRef} url id="sessionurl-id" icon="copy">
          <FormattedMessage {...messages.modalGenerateMagicURLLabelUrl} />
        </FieldInput>
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button secondary onClick={onCloseHandler}>
            <FormattedMessage {...messages.buttonCancel} />
          </Button>
          <Button primary onClick={onCopyToClipboard}>
            <FormattedMessage {...messages.modalButtonCopyToClipboard} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default GenerateMagicURLModal;
