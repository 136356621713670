import React, { useContext, useEffect, useState } from 'react';
import {
  withRouter,
  generatePath,
  useParams,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { FormattedMessage } from 'react-intl';
import Badge from 'components/badge/Badge';
import { Panel, PanelHeader, PanelTitleButton } from 'components/panel';
import messages from 'messages';
import {
  PanelTitleWrapper,
  PanelTitle,
  PanelSubtitle
} from 'components/panel/panel-title';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import PanelBody from 'components/panel/PanelBody';

import {
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS,
  ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
  ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS_VIDEO,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
  ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK,
  ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
  ROUTE_SPORTERS_ENTITY_SPORTER_DOCS,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
  ROUTE_SPORTERS_ENTITY_SPORTER_TESTS
} from 'routes/RouteList';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import SporterMedicalCard from 'containers/pages/rehab/SporterMedicalCard';
import { StoreContext } from 'index';
import { getDateInYears } from 'utils/date';
import Loader from 'components/loader/Loader';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import PersonSessions from 'containers/pages/persons/PersonSessions';
import PersonVideos from 'containers/pages/persons/PersonVideos';
import TabLink from 'components/tabs/TabLink';
import PersonPrograms from 'containers/pages/persons/PersonPrograms';
import PersonGroups from 'containers/pages/persons/PersonGroups';
import PersonDocs from 'containers/pages/persons/PersonDocs';
import useSessionCount from 'hooks/queries/useSessionCount';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import AddProgramModal from 'containers/pages/exercises/programs/AddProgramModal';
import PersonCard from 'containers/partials/cards/PersonCard';
import { MenuWrapper } from 'components/menu';
import { useMenuContext } from 'contexts/MenuContext';
import usePerson from 'hooks/queries/usePerson';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';
import PersonTrackRecords from 'containers/pages/persons/PersonTrackRecords';

const Person = () => {
  const {
    authStore: {
      user,
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();
  const { entityId, groupId, sporterId } = useParams();
  const ability = useAbility(AbilityContext);
  const { fetchPerson, loading, error } = usePerson({ personId: sporterId });
  const person = useEntityContext(s => s.person);
  const { totalSessions, getSessionCount } = useSessionCount();
  const [modalData, setModalData] = useState(null);
  const { menuItems } = useMenuContext();

  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
    ROUTE_SPORTERS_ENTITY_SPORTER_TESTS,
    ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS,
    ROUTE_SPORTERS_ENTITY_SPORTER_DOCS,
    ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
    // ROUTE_SPORTERS_ENTITY_SPORTER_TRAININGS,
    ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL,
    ROUTE_SPORTERS_ENTITY_SPORTER_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER
  ]);

  // Define the tabs for the logged in user
  let routes = [];
  if (ability.can('read', 'Sessions')) {
    routes.push(ROUTE_SPORTERS_ENTITY_SPORTER);
  }

  if (ability.can('read', 'Rehab')) {
    routes.push([
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
      ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS
    ]);
  }

  if (ability.can('manage', 'Videos')) {
    routes = [
      [
        ROUTE_SPORTERS_ENTITY_SPORTER,
        ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS,
        ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS_VIDEO
      ]
    ];
  }

  if (ability.can('read', 'Programs')) {
    routes.push([
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
      ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
    ]);
  }

  // No trainings view for now
  /*if (ability.can('read', 'Trainings')) {
    routes.push([ROUTE_SPORTERS_ENTITY_SPORTER_TRAININGS]);
  }*/

  if (ability.can('manage', 'Athletes')) {
    routes.push([ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS]);
  }
  if (ability.can('read', 'Documents')) {
    routes.push([ROUTE_SPORTERS_ENTITY_SPORTER_DOCS]);
  }

  routes.push([
    ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL
  ]);

  const [tabIndex, setTabIndex] = useTabIndex(routes);

  const isProgramsTab = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS,
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
    ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK
  ]);

  useEffect(() => {
    const fetchPersonData = async () => {
      await fetchPerson(sporterId);
      await getSessionCount(entityId, sporterId);
    };
    fetchPersonData();
  }, [entityId, sporterId]);

  const onCancel = () => {
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL, {
        entityId,
        groupId,
        sporterId
      })
    );
  };

  return (
    <>
      <Panel active={active.isExact}>
        {error && <RetryPanel />}
        {loading && <Loader />}
        {person && (
          <>
            <PanelHeader>
              <Breadcrumbs>
                <Breadcrumb
                  onClick={() =>
                    generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId })
                  }
                >
                  <FormattedMessage {...messages.breadcrumbSporters} />
                </Breadcrumb>
              </Breadcrumbs>
              <PanelTitleWrapper>
                <PanelTitle>
                  {person.firstname} {person.lastname}
                  <MenuWrapper
                    trigger={<PanelTitleButton dataQa={'sporter'} />}
                  >
                    {menuItems}
                  </MenuWrapper>
                  {isProgramsTab &&
                    user.canAddPrograms(entityId) && ( // TODO canAddPrograms check
                      <Button
                        rounded
                        primary
                        onClick={() => setModalData(true)}
                      >
                        <Icon id="add-white" />
                        <FormattedMessage
                          {...messages.exercisesAddProgramButton}
                        />
                      </Button>
                    )}
                </PanelTitle>
                {!user.isVitaleOrg && (
                  <PanelSubtitle>
                    {person.birthdate && (
                      <FormattedMessage
                        {...messages.labelSporterAge}
                        values={{ age: getDateInYears(person.birthdate) }}
                      />
                    )}
                  </PanelSubtitle>
                )}
              </PanelTitleWrapper>
            </PanelHeader>

            <PanelBody>
              <Tabs
                selectedIndex={tabIndex}
                onSelect={index => setTabIndex(index)}
              >
                <TabList>
                  {ability.can('read', 'Sessions') && (
                    <TabLink
                      to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                        entityId,
                        groupId,
                        sporterId
                      })}
                      dataQa={'sporter-sessions'}
                    >
                      {totalSessions > 0 && <Badge>{totalSessions}</Badge>}
                      <FormattedMessage {...messages.tabSporterSession} />
                    </TabLink>
                  )}

                  {ability.can('read', 'Rehab') && (
                    <TabLink
                      to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL, {
                        entityId,
                        groupId,
                        sporterId
                      })}
                      dataQa={'sporter-medical'}
                    >
                      <FormattedMessage {...messages.tabSporterMedical} />
                    </TabLink>
                  )}

                  {ability.can('manage', 'Videos') && (
                    <TabLink
                      to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS, {
                        entityId,
                        groupId,
                        sporterId
                      })}
                      dataQa={'sporter-videos'}
                    >
                      <FormattedMessage {...messages.tabSporterVideos} />
                    </TabLink>
                  )}

                  {ability.can('read', 'Programs') && (
                    <TabLink
                      to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS, {
                        entityId,
                        groupId,
                        sporterId
                      })}
                      dataQa={'sporter-programs'}
                    >
                      <FormattedMessage {...messages.programs} />
                    </TabLink>
                  )}

                  {/*
                  // No trainings view for now
                  {ability.can('read', 'Trainings') && (
                    <TabLink
                      to={generatePath(
                        ROUTE_SPORTERS_ENTITY_SPORTER_TRAININGS,
                        {
                          entityId,
                          groupId,
                          sporterId
                        }
                      )}
                      dataQa={'sporter-trainings'}
                    >
                      <FormattedMessage {...messages.trainingsTitle} />
                    </TabLink>
                  )}*/}

                  {ability.can('manage', 'Athletes') && (
                    <TabLink
                      to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS, {
                        entityId,
                        groupId,
                        sporterId
                      })}
                    >
                      <FormattedMessage {...messages.athleteGroups} />
                    </TabLink>
                  )}

                  {ability.can('read', 'Documents') && (
                    <TabLink
                      to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_DOCS, {
                        entityId,
                        groupId,
                        sporterId
                      })}
                      dataQa={'sporter-docs'}
                    >
                      <FormattedMessage {...messages.athleteDocs} />
                    </TabLink>
                  )}

                  {/* Person Global */}
                  <TabLink
                    to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL, {
                      entityId,
                      groupId,
                      sporterId
                    })}
                    dataQa={'sporter-global'}
                  >
                    <FormattedMessage {...messages.tabSporterGlobal} />
                  </TabLink>
                </TabList>
                {ability.can('read', 'Sessions') && (
                  <TabPanel>
                    <TabPanelBody>
                      <PersonSessions />
                    </TabPanelBody>
                  </TabPanel>
                )}
                {ability.can('read', 'Rehab') && (
                  <TabPanel>
                    <TabPanelBody withScroller>
                      <SporterMedicalCard />
                    </TabPanelBody>
                  </TabPanel>
                )}
                {ability.can('manage', 'Videos') && (
                  <TabPanel>
                    <TabPanelBody withScroller>
                      <PersonVideos entityId={entityId} sporterId={sporterId} />
                    </TabPanelBody>
                  </TabPanel>
                )}
                {ability.can('read', 'Programs') && (
                  <TabPanel>
                    <TabPanelBody>
                      <PersonPrograms
                        entityId={rootEntityId}
                        personId={sporterId}
                      />
                    </TabPanelBody>
                  </TabPanel>
                )}
                {/*
                // No trainings view for now
                {ability.can('read', 'Trainings') && (
                  <TabPanel>
                    <TabPanelBody>
                      <PersonTrainings
                        entityId={rootEntityId}
                        personId={sporterId}
                      />
                    </TabPanelBody>
                  </TabPanel>
                )}*/}
                {ability.can('manage', 'Athletes') && (
                  <TabPanel>
                    <TabPanelBody>
                      <PersonGroups />
                    </TabPanelBody>
                  </TabPanel>
                )}
                {ability.can('read', 'Documents') && (
                  <TabPanel>
                    <TabPanelBody>
                      <PersonDocs
                        entityId={rootEntityId}
                        personId={sporterId}
                      />
                    </TabPanelBody>
                  </TabPanel>
                )}
                {/* Person Global */}
                <TabPanel>
                  <TabPanelBody withScroller>
                    <PersonCard
                      entityId={entityId}
                      person={person}
                      onCancel={onCancel}
                    />
                    {ability.can('read', 'GrowthTracker_v2') && (
                      <PersonTrackRecords />
                    )}
                  </TabPanelBody>
                </TabPanel>
              </Tabs>
            </PanelBody>
          </>
        )}
      </Panel>

      {modalData && (
        <AddProgramModal
          entityId={entityId}
          destiny={{
            path: ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM,
            variables: { entityId, groupId, sporterId }
          }}
          program={{
            entityId,
            title: '',
            startdate: '',
            duration: '',
            description: '',
            exerciseIds: [],
            exercises: []
          }}
          persons={[person]}
          onClose={() => setModalData(null)}
        />
      )}
    </>
  );
};

export default withRouter(Person);
