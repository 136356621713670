import { useEffect } from 'react';
import useFetchAndFilterPersonsPool from 'hooks/queries/useFetchAndFilterPersonsPool';
import { useParams } from 'react-router-dom';
import Loader from 'components/loader/Loader';
import VirtualizedEntityList from 'containers/pages/persons/VirtualizedEntityList';
import * as Sentry from '@sentry/react';
import usePersonStatus from 'hooks/utils/usePersonStatus';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function PersonsPoolTab({
  excludedPersons = [],
  onModal = false,
  onClick,
  showPersonStatus = false
}) {
  const { entityId, groupId, sporterId } = useParams();
  const persons = useEntityContext(s => s.persons);
  const resetFilter = useEntityContext(s => s.resetFilter);
  const clearSelected = useEntityContext(s => s.clearSelected);
  const clearGroups = useEntityContext(s => s.setGroups);

  const { loading, fetchPool } = useFetchAndFilterPersonsPool({
    entityId
  });
  const { personStatusHandler } = usePersonStatus(entityId, groupId);

  useEffect(() => {
    const loadPool = async () => {
      await fetchPool({ withEntity: true });
    };
    loadPool().catch(error => {
      Sentry.captureException(error);
    });
    clearGroups([]);
    return () => {
      resetFilter();
      clearSelected();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;

  return (
    <VirtualizedEntityList
      id={'pool'}
      onModal={onModal}
      items={[...persons]}
      checkable
      showPersonStatus={showPersonStatus}
      activeId={sporterId}
      onClick={person => {
        clearSelected();
        onClick && onClick(person, 'person');
      }}
      onClickPersonStatus={personStatusHandler}
      excludedItems={excludedPersons}
    />
  );
}

export default PersonsPoolTab;
