import classNames from 'classnames';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';
import Icon from '../icon/Icon';

import { Button } from 'components/button';

const MultiValueRemove = props => {
  return (
    <Button
      mini
      onClick={e => {
        props.innerProps.onClick();
      }}
      onKeyDown={e => {
        if (e.key === 8) {
          //   e.preventDefault();
          //   props.innerProps.click();
        }
        if (e.key === 0) {
          //   e.preventDefault();
          //   props.innerProps.click();
        }
        // console.log('keydown', e);
      }}
      extraClassNames={'p-select__multi-value__remove'}
    >
      <Icon
        id="close"
        smallIcon
        strokeColor="color-neutral-x-light"
        style={{ width: 12, height: 12 }}
      />
    </Button>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon id="dropdown-open" extraClassNames={classNames('c-icon--input')} />
    </components.DropdownIndicator>
  );
};

const Menu = props => {
  return <components.Menu {...props} />;
};

const Input = props => {
  return <components.Input {...props} />;
};

const emptyComponent = () => {
  return <></>;
};

const TagsSelect = ({ dropdownDisabled = false, ...props }) => {
  return (
    <div
      className={classNames(
        'c-input__group c-input__group-select',
        props.extraClassNames,
        {
          'c-input--read-only': props.readOnly,
          'c-input--disabled': props.disabled,
          'c-input--inline': props.inline,
          'c-input--dropdown-disabled': dropdownDisabled
        }
      )}
    >
      {props.label && (
        <label htmlFor={props.id} className={classNames('c-input__label', {})}>
          {props.label}
        </label>
      )}
      {props.creatable ? (
        <CreatableSelect
          value={props.value}
          options={props.options}
          isMulti={props.isMulti}
          isClearable={props.isClearable}
          onChange={props.onChange}
          onInputChange={props.onInputChange}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          isSearchable={!props.readOnly}
          isDisabled={props.disabled}
          isLoading={props.isLoading}
          noOptionsMessage={() => props.noOptionsMessage}
          className={classNames('p-select-container')}
          classNamePrefix={'p-select'}
          menuPlacement={props.menuPlacement ? props.menuPlacement : 'auto'}
          components={{
            Menu: dropdownDisabled
              ? emptyComponent
              : ({ children, ...props }) => (
                  <components.Menu {...props}>{children}</components.Menu>
                ),
            IndicatorsContainer: dropdownDisabled
              ? emptyComponent
              : ({ children, ...props }) => (
                  <components.IndicatorsContainer {...props}>
                    {children}
                  </components.IndicatorsContainer>
                ),
            MultiValueRemove,
            DropdownIndicator,
            IndicatorSeparator: emptyComponent
          }}
        />
      ) : (
        <Select
          value={props.value}
          options={props.options}
          isMulti={props.isMulti}
          isClearable={props.isClearable}
          onChange={props.onChange}
          placeholder={props.placeholder}
          defaultValue={props.defaultValue}
          isSearchable={!props.readOnly}
          isDisabled={props.disabled}
          isLoading={props.isLoading}
          noOptionsMessage={() => props.noOptionsMessage}
          className={classNames('p-select-container')}
          classNamePrefix={'p-select'}
          backspaceRemovesValue={!dropdownDisabled}
          openMenuOnClick={!dropdownDisabled}
          menuPlacement={props.menuPlacement ? props.menuPlacement : 'auto'}
          onKeyDown={e => {
            if (dropdownDisabled && e.key === ' ') {
              //   This avoid trying opening the menu
              e.preventDefault();
            }
          }}
          components={{
            Menu: dropdownDisabled ? emptyComponent : Menu,
            Input: dropdownDisabled ? emptyComponent : Input,
            MenuList: dropdownDisabled
              ? emptyComponent
              : ({ children, ...props }) => (
                  <components.MenuList {...props}>
                    {children}
                  </components.MenuList>
                ),
            IndicatorsContainer: dropdownDisabled
              ? emptyComponent
              : ({ children, ...props }) => (
                  <components.IndicatorsContainer {...props}>
                    {children}
                  </components.IndicatorsContainer>
                ),
            MultiValueRemove:
              dropdownDisabled && !props.isDisabled
                ? MultiValueRemove
                : props => (
                    <components.MultiValueRemove {...props}>
                      <Icon id="close" />
                    </components.MultiValueRemove>
                  ),
            DropdownIndicator,
            IndicatorSeparator: emptyComponent
          }}
        />
      )}
    </div>
  );
};

TagsSelect.propTypes = {
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  creatable: PropTypes.bool
};

export default TagsSelect;
