import React, { useEffect, useState } from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { Card, CardBody, CardFooter } from 'components/card';
import { Button, ButtonsGroup } from 'components/button';
import FieldInput from 'components/input/FieldInput';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import Template from 'models/Template';
import TemplateForm from 'containers/pages/exercises/templates/TemplateForm';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import {
  ListItem,
  ListItemLabel,
  ListItemLabelWrapper
} from 'components/list/list-item';
import { NoteTypes, TemplateType } from 'constants.js';
import { MUTATION_ADD_NOTE } from 'services/aws/notes-query';
import { generatePath, useHistory } from 'react-router-dom';
import { useStore } from 'stores/Store';
import TagMultiSelect from 'containers/partials/inputs/TagMultiSelect';
import { ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE } from 'routes/RouteList';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { Tabs, TabPanelBody } from 'components/tabs';
import Badge from 'components/badge/Badge';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';

const getNote = (exercise, notes) => {
  if (notes && notes.length > 0) {
    return notes[0].note;
  }
  if (exercise.note) {
    return exercise.note;
  }
  return '';
};

function AddTemplateModal({
  entityId,
  template,
  onClose,
  fromProgram,
  program
}) {
  const { push } = useHistory();
  const intl = useIntl();
  const [notes, setNotes] = useState(null);
  const [addNote] = useMutation(MUTATION_ADD_NOTE);
  const setSelectedTemplateExercises = useStore(
    state => state.setSelectedTemplateExercises
  );
  const tabIndex = 0;
  const [tabsIndex, setTabsIndex] = useState(tabIndex);

  useEffect(() => {
    const exerciseNotes = [];
    template.exercises.forEach(exercise => {
      exerciseNotes.push({
        note: getNote(exercise, program.notes[exercise.id]),
        exerciseId: exercise.id
      });
    });
    setNotes(exerciseNotes);
    setSelectedTemplateExercises(template.exercises);
  }, [program]);

  useEffect(() => {
    setTabsIndex(tabIndex);
  }, [tabIndex]);

  const onCompleteHandler = async templateId => {
    Promise.all([
      ...notes.map(note => {
        return addNote({
          variables: {
            linkId: templateId,
            linkId2: note.exerciseId,
            note: note.note,
            noteType: NoteTypes.TEMPLATE
          }
        });
      })
    ]).then(() => {
      push(
        generatePath(ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE, {
          entityId,
          templateId
        })
      );
      onClose && onClose();
    });
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal
      card
      isOpen={true}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <TemplateForm
        entityId={entityId}
        template={template ?? new Template({})}
        type={TemplateType.TEMPLATE}
        onComplete={onCompleteHandler}
      >
        {({ isSubmitting, setFieldValue, errors, touched }) => (
          <>
            <CardHeader modal>
              <CardHeaderTitle>
                <FormattedMessage
                  {...messages.addTemplateTitle}
                  values={{
                    fromProgram
                  }}
                />
              </CardHeaderTitle>
            </CardHeader>
            <CardBody modal bodyWithTabs>
              <Tabs
                onModal
                selectedIndex={tabsIndex}
                onSelect={index => setTabsIndex(index)}
              >
                <TabList>
                  <Tab
                    tabIndex="0"
                    key="template-details"
                    data-qa="template-details-tab"
                  >
                    <FormattedMessage {...messages.tabSessionDetail} />
                  </Tab>
                  {fromProgram && (
                    <Tab tabIndex="0" key="exercises" data-qa="exercises-tab">
                      <Badge>{template?.exercises?.length}</Badge>
                      <FormattedMessage {...messages.tabExercises} />
                    </Tab>
                  )}
                </TabList>
                <TabPanel key="template-details">
                  <TabPanelBody>
                    <CardHeader secondary>
                      <CardHeaderTitle>
                        <FormattedMessage {...messages.cardTitleExercise} />
                      </CardHeaderTitle>
                    </CardHeader>
                    <CardBody secondary separatorBottom>
                      <FieldInput
                        id="title"
                        name="title"
                        placeholder={intl.formatMessage(
                          messages.templateNamePlaceholder
                        )}
                        errors={errors}
                        touched={touched}
                        required
                      >
                        <FormattedMessage {...messages.templateNameLabel} />
                      </FieldInput>
                    </CardBody>

                    <CardHeader secondary>
                      <CardHeaderTitle>
                        <FormattedMessage {...messages.exercisesLabelTags} />
                      </CardHeaderTitle>
                    </CardHeader>

                    <TagMultiSelect
                      entityId={entityId}
                      onChange={tags => {
                        setFieldValue('tags', tags);
                      }}
                      selectedTags={template.tags}
                    />
                  </TabPanelBody>
                </TabPanel>
                {fromProgram && (
                  <TabPanel key="exercises">
                    <TabPanelBody>
                      {fromProgram && template?.exercises?.length > 0 ? (
                        <List draggableDisabled onModal>
                          <ListBody extraClassNames="u-margin-bottom-large">
                            {template?.exercises &&
                              template.exercises.map(exercise => (
                                <ListItem key={exercise.id}>
                                  <ListItemLabelWrapper>
                                    <ListItemLabel label>
                                      {exercise.title}
                                    </ListItemLabel>
                                    <div className="c-list__item-small-editing-area">
                                      <p>
                                        {getNote(
                                          exercise,
                                          program.notes[exercise.id]
                                        )}
                                      </p>
                                    </div>
                                  </ListItemLabelWrapper>
                                </ListItem>
                              ))}
                          </ListBody>
                        </List>
                      ) : (
                        <Card centered extraClassNames="u-margin-none">
                          <CardBody empty>
                            <Message
                              iconFillColor="color-neutral-dark"
                              extraClassNames="u-margin-bottom-large"
                              empty
                              icon="cursor"
                            >
                              <MessageText>
                                <FormattedMessage
                                  {...messages.exercisesListEmpty}
                                />
                              </MessageText>
                            </Message>
                          </CardBody>
                        </Card>
                      )}
                    </TabPanelBody>
                  </TabPanel>
                )}
              </Tabs>
            </CardBody>
            <CardFooter modal extraClassNames="c-card__footer--modal-larger">
              <ButtonsGroup>
                <Button secondary onClick={() => onCloseHandler()}>
                  <FormattedMessage {...messages.buttonCancel} />
                </Button>
                <Button type="submit" primary disabled={isSubmitting}>
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </ButtonsGroup>
            </CardFooter>
          </>
        )}
      </TemplateForm>
    </Modal>
  );
}

export default AddTemplateModal;
