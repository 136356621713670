import classNames from 'classnames';
import Icon from 'components/icon/Icon';
import Select, { components } from 'react-select';
import { ErrorMessage, getIn } from 'formik';
import InputErrorMessage from 'components/input/InputErrorMessage';
import React from 'react';

const selectStyles = {
  container: provided => ({
    ...provided
    // ...styles?.container, // overrule the default styles
  }),
  option: (provided, state) => ({
    ...provided,
    isDisabled: state.isSelected
    // ...styles?.option, // overrule the default styles
  }),
  control: provided => ({
    ...provided
  }),
  menu: provided => ({
    ...provided
  }),
  indicatorSeparator: () => null,
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        id="dropdown-open"
        extraClassNames={classNames('c-icon--input c-icon--input-left')}
      />
    </components.DropdownIndicator>
  );
};

// customMultiValue: gebruikt om persons uit te filteren in `ResultsTableHeader.js`
// TODO: Fabian, finetunen a11y.
const ValueContainer = ({ children, ...props }) => {
  return props.selectProps?.value?.length > 1 ? (
    <components.ValueContainer {...props}>
      {props.selectProps.value.length} filtered {children[1]}
    </components.ValueContainer>
  ) : (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  );
};

const MultiValueContainer = props => {
  return <>{props.selectProps.value.length} filtered</>;
};

const FieldSelect = props => {
  const {
    id,
    readOnly,
    disabled,
    outfaded,
    inline,
    standalone,
    extraClassNames,
    label,
    labelGroup,
    labelHiddenVisually,
    name,
    options,
    value,
    isSearchable,
    isClearable,
    isMulti,
    onChange,
    onBlur,
    customMultiValue,
    placeholder,
    defaultMenuIsOpen,
    errors,
    touched,
    maxMenuHeight
  } = props;
  return (
    <div
      className={classNames('c-input__group-select', extraClassNames, {
        'c-input--read-only c-input--disabled': readOnly || disabled,
        'c-input--outfaded': outfaded,
        'c-input--inline': inline,
        'c-input__group-select-standalone': standalone,
        'c-input-select--menu-250-width': customMultiValue
      })}
      onClick={e => e.stopPropagation()}
    >
      {label && (
        <label
          htmlFor={id}
          className={classNames('c-input__label', {
            'u-hidden-visually': labelHiddenVisually
          })}
        >
          {label}
        </label>
      )}
      <div
        className={classNames('c-input__wrapper c-menu__wrapper', {
          'c-input__wrapper--select': !readOnly && !disabled
        })}
      >
        {labelGroup && ( //selectedItem &&
          <div className={classNames('c-input__sup-value')}>{labelGroup}</div>
        )}
        {readOnly ? (
          <input
            value={value?.label ?? ''}
            className={classNames('c-input')}
            readOnly
          />
        ) : (
          <Select
            id={id}
            name={name}
            options={options}
            value={value}
            isDisabled={disabled}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isMulti={isMulti}
            onChange={onChange}
            onBlur={onBlur}
            className="c-input-select"
            classNamePrefix="c-input-select"
            styles={selectStyles}
            maxMenuHeight={maxMenuHeight ? maxMenuHeight : 150}
            minMenuHeight={120}
            components={
              customMultiValue
                ? { DropdownIndicator, MultiValueContainer, ValueContainer }
                : { DropdownIndicator }
            }
            menuPlacement={'auto'}
            hideSelectedOptions={false}
            placeholder={placeholder ?? ''}
            menuIsOpen={defaultMenuIsOpen}
          />
        )}
      </div>
      {errors?.[name] &&
        touched?.[name] &&
        typeof errors[name] === 'string' && (
          <ErrorMessage name={name} component={InputErrorMessage} />
        )}

      {errors?.[name] &&
        touched?.[name] &&
        typeof errors[name] !== 'string' &&
        getIn(errors, name) && (
          <InputErrorMessage>{getIn(errors, name)}</InputErrorMessage>
        )}
    </div>
  );
};

export default FieldSelect;
