import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import TabPanelBody from 'components/tabs/TabPanelBody';
import { Tabs } from 'components/tabs';
import useSelectableObject from 'hooks/utils/useSelectableObject';
import PersonsGroupsTab from 'containers/pages/persons/PersonsGroupsTab';
import PersonsPoolTab from 'containers/pages/persons/PersonsPoolTab';
import PropTypes from 'prop-types';

const PersonsTabs = ({
  tabIndex = 0,
  onClick,
  onBreadcrumbClicked,
  onTabClick,
  showPersonStatus = false,
  checkableGroups = false,
  showOnlyGroups = false,
  onModal = false,
  extraElement,
  ...props
}) => {
  const { entityId, groupId, sporterId } = useParams();
  const [tabsIndex, setTabsIndex] = useState(tabIndex);

  const { selected: selectedPersons, setSelected: setSelectedPersons } =
    useSelectableObject(props.selectedPersons || []);
  const { selected: excludedPersons, setSelected: setExcludedPersons } =
    useSelectableObject(props.excludedPersons || []);

  const onSelected = persons => {
    const selectedPersons = persons.filter(
      p => !excludedPersons.find(exp => exp.id === p.id)
    );
    setSelectedPersons(selectedPersons);
  };

  useEffect(() => {
    setTabsIndex(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    if (props.selectedPersons) {
      setSelectedPersons(props.selectedPersons);
    }
    if (props.excludedPersons) {
      setExcludedPersons(props.excludedPersons);
    }
  }, [props.excludedPersons, props.selectedPersons]);

  return (
    <Tabs
      onModal={onModal}
      selectedIndex={tabsIndex}
      onSelect={index => setTabsIndex(index)}
    >
      <TabList>
        <Tab
          key="athletes"
          onClick={() =>
            onTabClick && onTabClick('athletes', 0, selectedPersons)
          }
          data-qa="athletes-groups-tab"
        >
          <FormattedMessage {...messages.athletesGroupsTab} />
        </Tab>
        <Tab
          key="athletes.pool"
          onClick={() => onTabClick && onTabClick('pool', 1, selectedPersons)}
          data-qa="athletes-pool-tab"
        >
          <FormattedMessage {...messages.athletesPoolTab} />
        </Tab>
      </TabList>
      <TabPanel key="athletes">
        <TabPanelBody withHeader style={{ overflowY: 'hidden' }}>
          <PersonsGroupsTab
            activeId={sporterId || groupId || entityId}
            onClick={onClick}
            onBreadcrumbClicked={onBreadcrumbClicked}
            onModal={onModal}
            showOnlyGroups={showOnlyGroups}
            checkableGroups={checkableGroups}
            showPersonStatus={showPersonStatus}
            extraElement={!!extraElement && extraElement}
          />
        </TabPanelBody>
      </TabPanel>
      <TabPanel key="athletes.pool">
        <TabPanelBody withHeader style={{ overflowY: 'hidden' }}>
          <PersonsPoolTab
            showPersonStatus={showPersonStatus}
            onSelected={persons => onSelected(persons)}
            onModal={onModal}
            onClick={onClick}
            excludedPersons={excludedPersons}
          />
        </TabPanelBody>
      </TabPanel>
    </Tabs>
  );
};

PersonsTabs.propTypes = {
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  onBreadcrumbClicked: PropTypes.func,
  onTabClick: PropTypes.func,
  selectedPersons: PropTypes.array,
  excludedPersons: PropTypes.array,
  showPersonStatus: PropTypes.bool,
  checkableGroups: PropTypes.bool,
  showOnlyGroups: PropTypes.bool,
  onModal: PropTypes.bool
};

export default PersonsTabs;
